import { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Radio,
  Select,
  Table,
  Upload,
  message,
  Modal,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import type { UploadProps } from "antd";
import {
  PlusOutlined,
  SwapRightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./AnnouncementTabContent.css";
import { Colors } from "../../../../constants/Colors";
import { data } from "../../../../data/data";
import RegionTag from "../../../../components/tags/RegionTag";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import InputCustom from "../../../../components/custom/InputCustom";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";
import {
  FormItemPublishDate,
  FormItemStopDate,
} from "../../../trialMode/localEvent/components/FormItems";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import { apiCall } from "../../../../utils/apiCall";
import { constants } from "buffer";

interface DataType {
  id: string;
  image: string;
  market: string[];
  actionType: string;
  buttonLinksTo: string;
  startDate: string;
  endDate: string;
}
interface AnnounceBarDataType {
  endTime: string;
  imageUrl: string;
  link: string;
  linkType: string;
  regions: string[];
  seq: number;
  sort: number;
  startTime: string;
  textAlign: string;
  textContent: string;
  useFlg: string;
}
const {
  mockAnnouncements,
  notificationActionTypesForAnnouncementBar,
  allButtonLinksTo,
} = data;

const AnnouncementTabContent = () => {
  const dayjs = require("dayjs");
  var utc = require("dayjs/plugin/utc");
  var timezone = require("dayjs-timezone-iana-plugin");
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectAnnouncement, setSelectAnnouncement] = useState<
    AnnounceBarDataType | undefined
  >();
  const [selectNav, setSelectNav] = useState<string | undefined>();
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    ""
  );
  const [seqToBeDeleted, setSeqToBeDeleted] = useState<number | undefined>();
  const [modalOpen, setModalOpen] = useState(false);
  const [announceBarData, setAnnounceBarData] = useState([]);
  const [uploadFile] = useUploadFileMutation();
  const [form] = Form.useForm();
  const fetchAnnounceBarData = async () => {
    const header = {
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        "User-Code": "P725",
      },
    };
    try {
      const data = await apiCall({
        method: "GET",
        header: header,
        endPoint: process.env.REACT_APP_BANNER_BASE + "/list",
      });
      const combined = data?.inUseBanners.concat(data?.notUseBanners);
      setAnnounceBarData(combined);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    fetchAnnounceBarData();
  }, []);

  const onDelete = (seq: number, isOn: boolean) => {
    setSeqToBeDeleted(seq);
    if (!isOn) {
      directDelete(seq);
    } else {
      setModalOpen(true);
    }
  };

  const directDelete = (seq: number) => {
    const id = seq + "";
    const header = {
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        "User-Code": "P725",
      },
    };
    apiCall({
      method: "DELETE",
      header: header,
      endPoint: process.env.REACT_APP_BANNER_BASE + "/" + id,
    })
      .then(() => {
        fetchAnnounceBarData();
        setModalOpen(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const doDelete = () => {
    const id = seqToBeDeleted + "";
    const header = {
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        "User-Code": "P725",
      },
    };
    apiCall({
      method: "DELETE",
      header: header,
      endPoint: process.env.REACT_APP_BANNER_BASE + "/" + id,
    })
      .then(() => {
        fetchAnnounceBarData();
        setModalOpen(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onCreateNew = () => {
    setShowDrawer(true);
  };

  const props: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isImageOrMp4 =
        file.type.startsWith("image") ||
        file.type.split("/")[1] === "mp4" ||
        file.type.split("/")[1] === "MP4";
      if (!isImageOrMp4) {
        message.error(renderAntDMessageConfig("Wrong file format!"));
      }
      return isImageOrMp4 || Upload.LIST_IGNORE;
    },
    onChange(info) {
      setUploadedFileName(info.file.name);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
      uploadFile({
        variables: {
          blobs: [
            {
              file: info.file.originFileObj,
              path: AfBlobPath.AppHomeBanners,
            },
          ],
        },
      }).catch((e) => console.log(e));
    },
    maxCount: 1,
    listType: "picture",
    onRemove: () => setUploadedFileName(""),
  };

  const columns: ColumnsType<AnnounceBarDataType> = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "image",
      render: (value: string) => <img alt="announcement" src={value} />,
    },
    {
      title: "Market",
      dataIndex: "regions",
      key: "market",
      render: (value: any) => (
        <div style={{ display: "flex" }}>
          {value.map((market: any) => (
            <div style={{ marginRight: 4 }}>
              <RegionTag region={market.toUpperCase()} />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Navigation",
      dataIndex: "linkType",
      key: "linkType",
      width: 200,
      render: (value: string) => {
        if (!value) {
          return "No navigation";
        } else if (value === "webview-html" || value === "app-embed-url") {
          return "Embed URL";
        } else if (value === "browser") {
          return "Open browser";
        } else {
          return allButtonLinksTo.find((v) => v.value === value)?.label;
        }
      },
    },
    {
      title: "Date range",
      dataIndex: "startTime",
      key: "startTime",
      sorter: (a, b) =>
        dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf(),
      defaultSortOrder: "descend",
      width: 230,
      render: (_, record: AnnounceBarDataType) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p>{dayjs(record?.startTime).format("YYYY-MM-DD HH:mm")}</p>
          <SwapRightOutlined style={{ margin: "0 12px" }} />
          <p>{dayjs(record?.endTime).format("YYYY-MM-DD HH:mm")}</p>
        </div>
      ),
    },
    {
      title: "In use",
      render: (_, record: AnnounceBarDataType) => {
        if (
          dayjs().valueOf() >= dayjs(record?.startTime).valueOf() &&
          dayjs().valueOf() <= dayjs(record?.endTime).valueOf()
        ) {
          return (
            <div className="in-use-container">
              <div
                className="dot"
                style={{
                  backgroundColor: "#394CF6",
                }}
              ></div>
              <p>In use</p>
            </div>
          );
        } else {
          return (
            <div className="in-use-container">
              <div
                className="dot"
                style={{
                  backgroundColor: "#D9D9D9",
                }}
              ></div>
              <p>Not in use</p>
            </div>
          );
        }
      },
    },
    {
      title: "Action",
      render: (_, record: AnnounceBarDataType) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setSelectAnnouncement(record);
              setShowDrawer(true);
            }}
            style={{ padding: 0, marginRight: 16 }}
          >
            Edit
          </Button>
          <Button
            type="link"
            onClick={() => {
              onDelete(
                record.seq,
                !(
                  dayjs().valueOf() < dayjs(record?.startTime).valueOf() ||
                  dayjs().valueOf() > dayjs(record?.endTime).valueOf()
                )
              );
            }}
            style={{ padding: 0 }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleSubmit = (values: any) => {
    const replica = selectAnnouncement
      ? { ...selectAnnouncement }
      : {
          imageUrl: null,
          linkType: undefined,
          link: null,
          startTime: null,
          endTime: null,
          regions: null,
        };
    replica.startTime = values.publishDate
      .tz("Asia/Taipei")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    replica.endTime = values.stopDate
      .tz("Asia/Taipei")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    replica.regions = values.market;
    if (selectNav === "No navigation") {
      replica.linkType = "";
      replica.link = "";
    } else if (selectNav === "App Screen") {
      replica.linkType = values.buttonLinksTo;
      replica.link = "";
    } else if (selectNav === "Open browser") {
      replica.linkType = "browser";
      if (!values.buttonLinksTo.startsWith("https://")) {
        replica.link = "https://" + values.buttonLinksTo;
      } else {
        replica.link = values.buttonLinksTo;
      }
    } else if (selectNav === "Embed URL") {
      replica.linkType = "app-embed-url";
      if (!values.buttonLinksTo.startsWith("https://")) {
        replica.link = "https://" + values.buttonLinksTo;
      } else {
        replica.link = values.buttonLinksTo;
      }
    }
    if (uploadedFileName) {
      replica.imageUrl = uploadedFileName;
    }
    const header = {
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        "User-Code": "P725",
      },
    };
    let endPoint = process.env.REACT_APP_BANNER_BASE;
    if (selectAnnouncement) {
      const id = selectAnnouncement?.seq + "";
      endPoint += "/";
      endPoint += id;
    }
    apiCall({
      method: selectAnnouncement ? "PATCH" : "POST",
      endPoint: endPoint,
      header: header,
      payload: replica,
    })
      .then(() => {
        fetchAnnounceBarData();
        setShowDrawer(false);
        setSelectAnnouncement(undefined);
        form.resetFields();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (selectAnnouncement) {
      const parts = selectAnnouncement?.imageUrl.split("/");
      const fileName = parts[parts.length - 1];
      const processedFileName = fileName.substring(0, fileName.indexOf("?"));
      setUploadedFileName(processedFileName);
      let actionTypeValue;
      if (!selectAnnouncement.linkType) {
        actionTypeValue = "No navigation";
      } else if (
        selectAnnouncement.linkType === "webview-html" ||
        selectAnnouncement.linkType === "app-embed-url"
      ) {
        actionTypeValue = "Embed URL";
      } else if (selectAnnouncement.linkType === "browser") {
        actionTypeValue = "Open browser";
      } else {
        actionTypeValue = "App Screen";
      }
      setSelectNav(actionTypeValue);
      form.setFieldsValue({
        market: selectAnnouncement.regions,
        actionType: actionTypeValue,
        buttonLinksTo:
          actionTypeValue === "Embed URL" || actionTypeValue === "Open browser"
            ? selectAnnouncement.link.startsWith("https://")
              ? selectAnnouncement.link.replace("https://", "")
              : selectAnnouncement.link
            : allButtonLinksTo.find(
                (v) => v.value === selectAnnouncement.linkType
              )?.value,
        publishDate: dayjs(selectAnnouncement.startTime),
        stopDate: dayjs(selectAnnouncement.endTime),
        image: [
          {
            uid: "0",
            name: processedFileName,
            status: "done",
            url: selectAnnouncement.imageUrl,
          },
        ],
      });
    }
  }, [selectAnnouncement, form]);

  return (
    <>
      <div className="theme-tab-content-container">
        <div
          className="theme-tab-table-container"
          style={{ backgroundColor: Colors.white }}
        >
          <div className="theme-tab-header-container">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={onCreateNew}
            >
              Create New
            </Button>
          </div>
          <Table
            rowKey={(record: AnnounceBarDataType) => record.seq}
            columns={columns}
            dataSource={announceBarData}
            pagination={false}
            scroll={{
              x: 300,
              y: 500,
            }}
            className="announcement-table"
          />
        </div>
      </div>
      <Modal
        centered
        open={modalOpen}
        closeIcon={null}
        footer={[
          <div className="onDelete-modal-button-group">
            <Button
              onClick={() => {
                setModalOpen(false);
                setSeqToBeDeleted(undefined);
              }}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              No
            </Button>
            <Button
              danger
              onClick={doDelete}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              Delete
            </Button>
          </div>,
        ]}
        width={417}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 76,
            marginBottom: 24,
            marginRight: 8,
            marginLeft: 8,
            marginTop: 12,
          }}
        >
          <img
            alt="warning circle icon"
            src={require("../../../../assets/icons/warning-circle.png")}
            style={{ width: 22, height: 22, marginRight: 16 }}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <p style={{ fontSize: 16, fontWeight: 500 }}>
              Are you sure you want to delete the item?
            </p>
          </div>
        </div>
      </Modal>
      <Drawer
        title={
          selectAnnouncement
            ? "Edit announcement bar"
            : "Create new announcement bar"
        }
        open={showDrawer}
        onClose={() => {
          form.resetFields();
          setSelectAnnouncement(undefined);
          setShowDrawer(false);
        }}
        width={500}
      >
        <Form
          name="announcement-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Market"
            name="market"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              options={[
                { label: "HK", value: "hk" },
                { label: "KR", value: "kr" },
                { label: "TW", value: "tw" },
              ]}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            label="Navigation"
            name="actionType"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Radio.Group
              options={notificationActionTypesForAnnouncementBar.map(
                (type) => ({
                  label: type.typeName,
                  value: type.typeValue,
                })
              )}
              optionType="button"
              onChange={(e) => {
                setSelectNav(e.target.value);
                form.setFieldValue("buttonLinksTo", "");
              }}
            />
          </Form.Item>
          {selectNav && selectNav !== "No navigation" && (
            <>
              {selectNav === "App Screen" ? (
                <Form.Item
                  label="Links to"
                  name="buttonLinksTo"
                  rules={[
                    {
                      required: selectNav === "App Screen" ? true : false,
                      message: "This field is required!",
                    },
                  ]}
                >
                  <Select placeholder="- Select -">
                    {allButtonLinksTo.map((item) => (
                      <Select.Option key={item.id} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  label="Links to"
                  name="buttonLinksTo"
                  rules={[
                    {
                      required:
                        selectNav !== "No navigation" &&
                        selectNav !== "App Screen"
                          ? true
                          : false,
                      message: "This field is required!",
                    },
                  ]}
                >
                  <InputCustom addonBefore="https://" />
                </Form.Item>
              )}
            </>
          )}
          <Form.Item
            style={{ margin: 0 }}
            label="Image"
            name="image"
            rules={[{ required: true, message: "This field is required!" }]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload {...props} className="announcement-image-upload">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item className="form-reminder">
            <p>Formats: JPEG, JPG, PNG, GIF, MP4</p>
          </Form.Item>
          <FormItemPublishDate required showTime />
          <FormItemStopDate required showTime />
          <DrawerFooter
            handleCancel={() => {
              form.resetFields();
              setSelectAnnouncement(undefined);
              setShowDrawer(false);
            }}
          />
        </Form>
      </Drawer>
    </>
  );
};

export default AnnouncementTabContent;
