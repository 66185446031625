import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSingleUserQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type GetSingleUserQuery = { __typename?: 'Query', wf_adminFirst_afUser?: { __typename?: 'WF_AdminFirst_AfUserConnection', edges?: Array<{ __typename?: 'WF_AdminFirst_AfUserEdge', node: { __typename?: 'WF_AdminFirst_AfUser', id: string, userName?: string | null, userEmail?: string | null, isAdministrator?: boolean | null, avatarUrl?: string | null, afUserMarkets?: Array<{ __typename?: 'WF_AdminFirst_AfUserMarket', market?: string | null } | null> | null, afUserServices?: Array<{ __typename?: 'WF_AdminFirst_AfUserService', id: string, afServiceId?: number | null } | null> | null, afUserPrograms?: Array<{ __typename?: 'WF_AdminFirst_AfUserProgram', afProgramId?: number | null, afProgram?: { __typename?: 'WF_AdminFirst_AfProgram', programName?: string | null } | null } | null> | null } }> | null } | null };


export const GetSingleUserDocument = gql`
    query GetSingleUser($email: String!) {
  wf_adminFirst_afUser(filter: {userEmail: {eq: $email}}) {
    edges {
      node {
        id
        userName
        userEmail
        isAdministrator
        avatarUrl
        afUserMarkets {
          market
        }
        afUserServices {
          id
          afServiceId
        }
        afUserPrograms {
          afProgramId
          afProgram {
            programName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSingleUserQuery__
 *
 * To run a query within a React component, call `useGetSingleUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetSingleUserQuery(baseOptions: Apollo.QueryHookOptions<GetSingleUserQuery, GetSingleUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleUserQuery, GetSingleUserQueryVariables>(GetSingleUserDocument, options);
      }
export function useGetSingleUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleUserQuery, GetSingleUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleUserQuery, GetSingleUserQueryVariables>(GetSingleUserDocument, options);
        }
export type GetSingleUserQueryHookResult = ReturnType<typeof useGetSingleUserQuery>;
export type GetSingleUserLazyQueryHookResult = ReturnType<typeof useGetSingleUserLazyQuery>;
export type GetSingleUserQueryResult = Apollo.QueryResult<GetSingleUserQuery, GetSingleUserQueryVariables>;