import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductCategoryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProductCategoryQuery = { __typename?: 'Query', wf_trialModeLeads_productCategory?: { __typename?: 'WF_TrialModeLeads_ProductCategoryConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_ProductCategory', id: string, category?: string | null, categoryName?: string | null }> | null } | null };


export const GetProductCategoryDocument = gql`
    query GetProductCategory {
  wf_trialModeLeads_productCategory(first: 100) {
    nodes {
      id
      category
      categoryName
    }
  }
}
    `;

/**
 * __useGetProductCategoryQuery__
 *
 * To run a query within a React component, call `useGetProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoryQuery, GetProductCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductCategoryQuery, GetProductCategoryQueryVariables>(GetProductCategoryDocument, options);
      }
export function useGetProductCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoryQuery, GetProductCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductCategoryQuery, GetProductCategoryQueryVariables>(GetProductCategoryDocument, options);
        }
export type GetProductCategoryQueryHookResult = ReturnType<typeof useGetProductCategoryQuery>;
export type GetProductCategoryLazyQueryHookResult = ReturnType<typeof useGetProductCategoryLazyQuery>;
export type GetProductCategoryQueryResult = Apollo.QueryResult<GetProductCategoryQuery, GetProductCategoryQueryVariables>;