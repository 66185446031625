import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductSongsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProductSongsQuery = { __typename?: 'Query', wf_trialModeLeads_productSong?: { __typename?: 'WF_TrialModeLeads_ProductSongConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_ProductSong', id: string, productCategoryId?: number | null, sortId?: number | null, songTitle?: string | null, audioUrl?: string | null, productCategory?: { __typename?: 'WF_TrialModeLeads_ProductCategory', category?: string | null, categoryName?: string | null } | null, productSongAndMarkets?: Array<{ __typename?: 'WF_TrialModeLeads_ProductSongAndMarket', id: string, market?: string | null, segmentation?: string | null } | null> | null }> | null } | null };


export const GetProductSongsDocument = gql`
    query GetProductSongs {
  wf_trialModeLeads_productSong(first: 100, order: {sortId: ASC}) {
    nodes {
      id
      productCategoryId
      sortId
      songTitle
      audioUrl: audioFileName @wf_blobSignature(name: "TRIAL_MODE_ONLINE_EXPERIENCE_AUDIOS_SIGNATURE")
      productCategory {
        category
        categoryName
      }
      productSongAndMarkets {
        id
        market
        segmentation
      }
    }
  }
}
    `;

/**
 * __useGetProductSongsQuery__
 *
 * To run a query within a React component, call `useGetProductSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSongsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductSongsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductSongsQuery, GetProductSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductSongsQuery, GetProductSongsQueryVariables>(GetProductSongsDocument, options);
      }
export function useGetProductSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductSongsQuery, GetProductSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductSongsQuery, GetProductSongsQueryVariables>(GetProductSongsDocument, options);
        }
export type GetProductSongsQueryHookResult = ReturnType<typeof useGetProductSongsQuery>;
export type GetProductSongsLazyQueryHookResult = ReturnType<typeof useGetProductSongsLazyQuery>;
export type GetProductSongsQueryResult = Apollo.QueryResult<GetProductSongsQuery, GetProductSongsQueryVariables>;