export const Colors = {
  black: "#000000",
  white: "#FFFFFF",
  lightWhite: "#F5F7FA",
  transparent: "transparent",
  disabledBg: "#E0E0E0",
  disabledText: "#959191",
  extraDarkBlue: "#1E2640",
  darkBlue: "#2D389F",
  blue: "#394CF6",
  lightBlue: "#EAEBF5",
  red: "#EC1C24",
  lightRed: "#FDE8E9",
  purple: "#9A4AFF",
  lightPurple: "#F5EDFF",
  orange: "#F48120",
  lightOrange: "#FEF2E9",
  darkGrey: "#333333",
  grey: "#666666",
  lightGrey: "#959191",
  extraLightGrey: "#E0E0E0",
  indigo: "#0058B7",
  lightIndigo: "#DEEEFF",
  darkTheme: "#232D4A",
  darkBorder: "#5E74B5",
  menuSelectedOrange: "#FBCDA6",
  textPrimary: "rgba(0,0,0,0.85)",
  menuSubItemSelected: "#EBEDFE",
  textSecondary: "rgba(0,0,0,0.45)",
  tagBorder: "#A4ADFB",
  tagBackground: "#FAFAFA",
  tagBorder2: "#D9D9D9",
  redBorder: "#F7A4A7",
  green: "#00762B",
  lightGreen: "#D5F2DF",
  greenBorder: "#80D8A0",
  blueBorder: "#7CBBFF",
  notEditableText: "#071491",
  clubServiceBg: "#FDE6D2",
  demoAppBg: "#C7CDFC",
  sales188Bg: "#0069B9",
  mktBg: "#BEDDFF",
  checkInBorder: "#A1D3EF",
  checkInBg: "#E0F0FA",
  checkInText: "#3AA2DE",
  trialModeTheme: "#F2E5D4",
  trialModeBg: "#DDB790",
  trialModeSelectedBg: "#E8CEB2",
  trialModeTagText: "#C7894B",
};
