import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEvaluationMutationVariables = Types.Exact<{
  evaluationData?: Types.InputMaybe<Array<Types.Club_WorldFamilyClub_GraduationApplicationEvaluationInput> | Types.Club_WorldFamilyClub_GraduationApplicationEvaluationInput>;
}>;


export type DeleteEvaluationMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const DeleteEvaluationDocument = gql`
    mutation deleteEvaluation($evaluationData: [Club_WorldFamilyClub_GraduationApplicationEvaluationInput!]) {
  club_worldFamilyClub(
    removeRange: {graduationApplicationEvaluations: $evaluationData}
  ) {
    count
  }
}
    `;
export type DeleteEvaluationMutationFn = Apollo.MutationFunction<DeleteEvaluationMutation, DeleteEvaluationMutationVariables>;

/**
 * __useDeleteEvaluationMutation__
 *
 * To run a mutation, you first call `useDeleteEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEvaluationMutation, { data, loading, error }] = useDeleteEvaluationMutation({
 *   variables: {
 *      evaluationData: // value for 'evaluationData'
 *   },
 * });
 */
export function useDeleteEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEvaluationMutation, DeleteEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEvaluationMutation, DeleteEvaluationMutationVariables>(DeleteEvaluationDocument, options);
      }
export type DeleteEvaluationMutationHookResult = ReturnType<typeof useDeleteEvaluationMutation>;
export type DeleteEvaluationMutationResult = Apollo.MutationResult<DeleteEvaluationMutation>;
export type DeleteEvaluationMutationOptions = Apollo.BaseMutationOptions<DeleteEvaluationMutation, DeleteEvaluationMutationVariables>;