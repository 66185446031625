export const renderNotificationTypeName = (typeId: string) => {
  switch (typeId) {
    case "0001":
      return "Club News";
    case "0002":
      return "Go, Go Live!";
    case "0003":
      return "Graduation";
    default:
      break;
  }
};
