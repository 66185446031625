import { GetProductCategoryQuery } from "../../../../graphql/operations/trialMode/trialContentUpload/get-product-category";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { ProductCategoryOptionType } from "../types";

export default function convertToCategoryOptions(
  data: GetProductCategoryQuery
): ProductCategoryOptionType[] | undefined {
  return (
    data?.wf_trialModeLeads_productCategory?.nodes?.map(
      ({ id, categoryName }) => ({
        value: parseInt(renderDecodedId(id)),
        label: categoryName || "",
      })
    ) || undefined
  );
}
