import { Form, DatePicker, Upload, UploadProps, Button, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import InputCustom from "../../../../components/custom/InputCustom";
import publishDateRule from "../../../../utils/formRules/publishDateRule";
import requiredRule from "../../../../utils/formRules/requiredRule";
import imageValidation from "../../../../utils/formValidation/imageValidation";
import normFile from "../../utils/normFile";

const props: UploadProps = {
  name: "file",
  action: "https://httpbin.org/post",
  beforeUpload: imageValidation(100, "KB"),
  maxCount: 1,
  listType: "picture",
};

function FormItemUploadImage({ resolution }: { resolution: string }) {
  return (
    <Form.Item
      name="image"
      label="Image"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[requiredRule]}
      extra={`Recommended resolution is ${resolution} with file size less than 100KB. Formats: JPEG, JPG, PNG, GIF`}
    >
      <Upload {...props}>
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
}

function FormItemNavigationUrl() {
  return (
    <Form.Item
      name="navigationUrl"
      label="Navigation url"
      rules={[requiredRule]}
    >
      <InputCustom addonBefore="https://" placeholder="example" />
    </Form.Item>
  );
}

function FormItemPublishDate({
  required = false,
  showTime = false,
}: {
  required?: boolean;
  showTime?: boolean;
}) {
  return (
    <Form.Item
      name="publishDate"
      label="Publish date"
      rules={required ? [publishDateRule, requiredRule] : [publishDateRule]}
      dependencies={["stopDate"]}
    >
      <DatePicker
        style={{ width: "100%" }}
        showTime={showTime ? { format: "HH:mm" } : false}
        placeholder="Select date"
        format={showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
      />
    </Form.Item>
  );
}

function FormItemStopDate({
  required = false,
  showTime = false,
  reminder = "",
}: {
  required?: boolean;
  showTime?: boolean;
  reminder?: string;
}) {
  return (
    <Form.Item
      name="stopDate"
      label="Stop date"
      rules={required ? [requiredRule] : undefined}
      extra={
        required
          ? undefined
          : reminder
          ? reminder
          : "If no stop date is selected, this page will appear permanently"
      }
    >
      <DatePicker
        style={{ width: "100%" }}
        placeholder="Select date"
        format={showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
        showTime={showTime ? { format: "HH:mm" } : false}
        disabledDate={(current: any) =>
          current && current < dayjs().endOf("day")
        }
      />
    </Form.Item>
  );
}

function FormItemEventDescription() {
  return (
    <Form.Item name="description" label="Event description">
      <Input.TextArea
        showCount
        maxLength={40}
        placeholder="Autosize height based on content lines"
      />
    </Form.Item>
  );
}

export {
  FormItemUploadImage,
  FormItemNavigationUrl,
  FormItemPublishDate,
  FormItemStopDate,
  FormItemEventDescription,
};
