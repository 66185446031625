// @ts-nocheck
import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { Button, Table, message, Select, Space, Input } from "antd";
import { ServiceTypes } from "../../../types/types";
import { Colors } from "../../../constants/Colors";
import type { ColumnsType } from "antd/es/table";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserAllGraduationApplyQuery } from "../../../graphql/operations/clubApp/graduation/get-user-all-graduation-apply";
import { useGetUserAllGraduationApplyTwQuery } from "../../../graphql/operations/clubApp/graduation/get-user-all-graduation-apply-tw";
import { useGetAllGraduationApplyQuery } from "../../../graphql/operations/clubApp/graduation/get-all-graduation-apply";
import { useGetAllGraduationTaskNameAndDescriptionQuery } from "../../../graphql/operations/clubApp/graduation/get-graduation-all-task";
import { useGetAllGraduationProcessQuery } from "../../../graphql/operations/clubApp/graduation/get-all-graduation-process";
import { useGetAllGraduationProcessTwQuery } from "../../../graphql/operations/clubApp/graduation/get-all-graduation-process-tw";
import "./GraduationReviewed.scss";
import dayjs from "dayjs";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import GraduationDrawer from "./GraduationDrawer";
import { DataType, TaskDataType, ProcessDataType } from "./types";
import {
  currentYearStart,
  currentYearEnd,
  currentYear,
} from "./utils/yearPeriod";
import { sortUserApplications } from "./utils/sortUserApplications";
import { countStatus } from "./utils/countStatus";

interface GraduationReviewedProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const GraduationReviewed = ({
  setSelectedService,
  setSelectSubItem,
}: GraduationReviewedProps) => {
  const [displayData, setDisplayData] = useState<DataType[] | any>([]);
  const [temp, setTemp] = useState<DataType[] | any>([]);
  const [taskDataList, setTaskDataList] = useState<TaskDataType[] | any>([]);
  const [currentMarket, setCurrentMarket] = useState("");
  const [dateFilter, setDateFilter] = useState([]);
  const [evaluationDateFilter, setEvaluationDateFilter] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [evaluationDateArray, setEvaluationDateArray] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedGraduationData, setSelectedGraduationData] = useState<
    DataType | any
  >();
  const [taskProgress, setTaskProgress] = useState([0, 0]);
  const [taskProgressArray, setTaskProgressArray] = useState<number[]>([]);
  const [isExcellent, setIsExcellent] = useState(false);
  const [allProcessData, setAllProcessData] = useState<ProcessDataType[]>([]);
  const [userAllApplications, setUserAllApplications] = useState<
    DataType[] | any
  >([]);
  const [filteredAllApplications, setFilteredAllApplications] = useState<
    DataType[] | any
  >([]);
  const { Search } = Input;
  const handleSearchMemberNo = (memberNo: string) => {
    if (!memberNo && temp.length > 0) {
      setDisplayData(temp);
    }
    if (temp.length === 0) {
      setTemp(displayData);
      const replica = [...displayData];
      setDisplayData(
        replica.filter((item) => item.memberNo.includes(memberNo))
      );
    } else {
      const replica = [...temp];
      setDisplayData(
        replica.filter((item) => item.memberNo.includes(memberNo))
      );
    }
  };

  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Last applied",
      dataIndex: "applicationDate",
      key: "applicationDate",
      width: 200,
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD") : "",
      filters: dateFilter,
      onFilter: (value, record) =>
        dayjs(record.applicationDate).format("YYYY-MM-DD") === value,
    },
    {
      title: "Last evaluated",
      dataIndex: "lastEvaluatedDate",
      key: "lastEvaluatedDate",
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD") : "",
      width: 200,
    },
    {
      title: "Member ID",
      dataIndex: "memberNo",
      key: "memberNo",
      width: 150,
    },
    {
      title: "Kid (age)",
      dataIndex: "childName",
      key: "kidAge",
      width: 150,
      render: (text, record) => {
        const years = record.age.substring(0, record.age.indexOf("Y"));
        return (
          <span>
            {text} ({years})
          </span>
        );
      },
    },
    {
      title: "English name",
      dataIndex: "childEnglishName",
      key: "childEnglishName",
      width: 150,
    },
    {
      title: "Progress",
      key: "progress",
      width: 220,
      render: (_, record) => {
        const kidAll = allProcessData.filter(
          (data) =>
            data.memberNo === record.memberNo && data.childNo === record.childNo
        );
        const kid = kidAll.reduce((acc, obj) => {
          acc.approvedCount = (acc.approvedCount || 0) + obj.approvedCount;
          acc.rejectedCount = (acc.rejectedCount || 0) + obj.rejectedCount;
          acc.applicationCount =
            (acc.applicationCount || 0) + obj.applicationCount;
          return acc;
        }, {});
        const passCount = kid?.approvedCount;
        const failedCount = kid?.rejectedCount;
        const total = kid?.applicationCount;
        const inReviewCount = total - passCount - failedCount;
        const progressArray = [];
        for (let i = 0; i < passCount; i++) {
          progressArray.push(0);
        }
        for (let i = 0; i < inReviewCount; i++) {
          progressArray.push(1);
        }
        while (progressArray.length < 8) {
          progressArray.push(2);
        }

        return (
          <div className="progress-bar-container">
            <div className="progress-bar">
              {progressArray.map((item) => (
                <div
                  className="unit"
                  style={
                    item === 0
                      ? { backgroundColor: "#071491" }
                      : item === 1
                      ? { backgroundColor: "#C7CDFC" }
                      : { backgroundColor: "#F5F5F5" }
                  }
                ></div>
              ))}
              <p className="text-vertical-center content">{passCount}/8</p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Excellent",
      key: "excellent",
      width: 120,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => {
        const kid = allProcessData.find(
          (data) =>
            data.memberNo === record.memberNo && data.childNo === record.childNo
        );
        return kid.isExcellent === value;
      },
      render: (_, record) => {
        const kid = allProcessData.find(
          (data) =>
            data.memberNo === record.memberNo && data.childNo === record.childNo
        );
        return (
          <div>
            {kid?.isExcellent ? (
              <div className="excellent-container">
                <div>Yes</div>
                <img
                  alt="trophy icon"
                  className="trophy-icon"
                  src={require("../../../assets/icons/clubApp/graduation/trophy.png")}
                ></img>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            const kid = allProcessData.find(
              (data) =>
                data.memberNo === record.memberNo &&
                data.childNo === record.childNo
            );
            setIsExcellent(kid?.isExcellent);
            setSelectedGraduationData(record);
            setShowDrawer(true);
          }}
        >
          View
        </Button>
      ),
    },
  ];
  const { data, loading, refetch } = useGetAllGraduationApplyQuery({
    variables: {
      currentYearStart: currentYearStart,
      currentYearEnd: currentYearEnd,
    },
  });
  const { data: processData, refetch: processRefetch } =
    useGetAllGraduationProcessQuery({
      variables: {
        market: currentMarket,
      },
    });
  const { data: twProcessData, refetch: twProcessRefetch } =
    useGetAllGraduationProcessTwQuery({
      variables: {
        currentYear: currentYear,
        market: currentMarket,
      },
    });
  const { data: userData, refetch: userDataRefetch } =
    useGetUserAllGraduationApplyQuery({
      variables: {
        memberNo: selectedGraduationData?.memberNo,
        childNo: selectedGraduationData?.childNo,
      },
    });
  const { data: twUserData, refetch: twUserDataRefetch } =
    useGetUserAllGraduationApplyTwQuery({
      variables: {
        memberNo: selectedGraduationData?.memberNo,
        childNo: selectedGraduationData?.childNo,
        currentYearStart: currentYearStart,
        currentYearEnd: currentYearEnd,
      },
    });
  const { data: taskData } = useGetAllGraduationTaskNameAndDescriptionQuery();
  const handleChange = (value: string) => {
    setCurrentMarket(value);
  };
  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("All Applications");
  }, [setSelectedService, setSelectSubItem]);
  useEffect(() => {
    refetch();
    processRefetch();
    twProcessRefetch();
    userDataRefetch();
    twUserDataRefetch();
  }, [selectedGraduationData]);
  useEffect(() => {
    if (processData && currentMarket !== "TW") {
      setAllProcessData(processData?.afChildGraduationApplication);
    }
    if (twProcessData && currentMarket === "TW") {
      setAllProcessData(twProcessData?.afChildGraduationApplication);
    }
  }, [processData, twProcessData, currentMarket]);
  useEffect(() => {
    if (twUserData && currentMarket === "TW") {
      setUserAllApplications(
        twUserData.club_worldFamilyClub_graduationApplication?.nodes
      );
    }
    if (userData && currentMarket !== "TW") {
      setUserAllApplications(
        userData.club_worldFamilyClub_graduationApplication?.nodes
      );
    }
  }, [userData, twUserData, currentMarket]);
  useEffect(() => {
    const { passCount, underReviewCount } = countStatus(
      [...userAllApplications],
      0,
      0
    );
    setTaskProgress([passCount, underReviewCount]);
    const sorted = sortUserApplications([...userAllApplications]);
    setFilteredAllApplications(sorted);
  }, [userAllApplications]);
  useEffect(() => {
    const replica = [];
    for (let i = 1; i < 9; i++) {
      if (i <= taskProgress[0]) {
        replica.push(0);
      } else if (i <= taskProgress[0] + taskProgress[1]) {
        replica.push(1);
      } else {
        replica.push(2);
      }
    }
    setTaskProgressArray(replica);
  }, [taskProgress]);
  useEffect(() => {
    const uniqueSet1 = new Set(dateArray);
    const uniqueArray1 = Array.from(uniqueSet1);
    const filterFormArray1 = uniqueArray1.map((date) => ({
      text: date,
      value: date,
    }));
    const uniqueSet2 = new Set(evaluationDateArray);
    const uniqueArray2 = Array.from(uniqueSet2);
    const filterFormArray2 = uniqueArray2.map((date) => ({
      text: date,
      value: date,
    }));
    setDateFilter(filterFormArray1);
    setEvaluationDateFilter(filterFormArray2);
  }, [dateArray, evaluationDateArray]);
  useEffect(() => {
    setDateArray(
      displayData.map((data) =>
        dayjs(data.applicationDate).format("YYYY-MM-DD")
      )
    );
    setEvaluationDateArray(
      displayData.map((data) => dayjs(data.approvalDate).format("YYYY-MM-DD"))
    );
  }, [displayData]);
  useEffect(() => {
    if (data) {
      const temporalData = data.afGraduationApplication.filter(
        (data) => data.market === currentMarket
      );
      const filteredData = temporalData.reduce((result, current) => {
        const key = `${current.memberNo}_${current.childNo}`;
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(current);
        return result;
      }, {});
      const finalResult = Object.values(filteredData).map((group) => {
        group.sort(
          (a, b) => new Date(b.applicationDate) - new Date(a.applicationDate)
        );
        return group[0];
      });
      const lastEvaluatedDate = Object.values(filteredData).map((group) => {
        group.sort(
          (a, b) => new Date(b.approvalDate) - new Date(a.approvalDate)
        );
        return group[0];
      });
      if (finalResult && lastEvaluatedDate) {
        const resultWithLastEvaluatedDate = finalResult.map((kid) => {
          const recordOfLastEvaluatedDate = lastEvaluatedDate?.find(
            (item) =>
              item?.memberNo === kid?.memberNo && item?.childNo === kid?.childNo
          );
          return {
            ...kid,
            lastEvaluatedDate: recordOfLastEvaluatedDate?.approvalDate,
          };
        });
        setDisplayData(resultWithLastEvaluatedDate);
        setTemp(resultWithLastEvaluatedDate);
      }
    }
  }, [currentMarket, taskDataList, data]);

  useEffect(() => {
    setTotal(displayData.length);
  }, [displayData]);
  useEffect(() => {
    if (taskData) {
      setTaskDataList(
        taskData.club_worldFamilyClub_graduationTaskMarketDescription?.nodes
      );
    }
  }, [taskData]);
  useEffect(() => {
    if (currentUser?.afUserMarkets && currentUser?.afUserMarkets[0]?.market) {
      if (currentUser?.afUserMarkets.length === 1) {
        setCurrentMarket(currentUser?.afUserMarkets[0]?.market);
      } else {
        setCurrentMarket("TW");
      }
    }
  }, [currentUser]);
  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }
  if (loading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Graduation Reviewed"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="graduationReviewedWrapper">
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          All Applications
        </p>
      </div>
      <div className="table-container">
        <div className="select">
          <div className="total-container">Total {total} items</div>
          <div className="flex">
            <div
              style={
                currentUser?.afUserMarkets?.length === 1
                  ? { display: "none" }
                  : { width: 180 }
              }
            >
              <span>Market:</span>
              <Select
                onChange={handleChange}
                defaultValue="TW"
                options={[
                  {
                    value: "TW",
                    label: "TW",
                  },
                  {
                    value: "HK",
                    label: "HK",
                  },
                  {
                    value: "KR",
                    label: "KR",
                  },
                ]}
              ></Select>
            </div>
            <Search
              placeholder="Search member ID"
              width={265}
              allowClear
              onSearch={handleSearchMemberNo}
            />
          </div>
        </div>
        <Table
          className="table"
          columns={columns}
          dataSource={displayData}
          onChange={(pagination, filters, sorter, extra) => {
            setTotal(extra.currentDataSource.length);
          }}
          scroll={{
            x: true,
            y: 500,
          }}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </div>
      <GraduationDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        selectedGraduationData={selectedGraduationData}
        taskProgress={taskProgress}
        taskProgressArray={taskProgressArray}
        taskDataList={taskDataList}
        isExcellent={isExcellent}
        setIsExcellent={null}
        isExcellentEnabled={false}
        filteredAllApplications={filteredAllApplications}
      />
    </div>
  );
};

export default GraduationReviewed;
