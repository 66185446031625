import { Form, Radio } from "antd";

import { __currentUser__ } from "../../../graphql/policies";
import InputCustom from "../../../components/custom/InputCustom";
import requiredRule from "../../../utils/formRules/requiredRule";

const options = [
  { label: "HK", value: "HK" },
  { label: "KR", value: "KR" },
  { label: "TW", value: "TW" },
];

interface FormItemMarketProps {
  market?: string;
  modifiable?: boolean;
}

export function FormItemMarket({
  modifiable = true,
  market,
}: FormItemMarketProps) {
  const { afUserMarkets } = __currentUser__() || {};
  const authorizedMarkets = afUserMarkets?.map((item) => item?.market) || [];

  return (
    <Form.Item name="market" label="Market" rules={[requiredRule]}>
      {modifiable ? (
        <Radio.Group optionType="button">
          {options.map(({ label, value }) => (
            <Radio.Button
              key={label}
              value={value}
              disabled={!authorizedMarkets.includes(value)}
            >
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      ) : (
        <p style={{ color: "#071491" }}>{market}</p>
      )}
    </Form.Item>
  );
}

export function FormItemTitle({ name = "title" }: { name?: string }) {
  return (
    <Form.Item name={name} label="Title" rules={[requiredRule]}>
      <InputCustom placeholder="example" />
    </Form.Item>
  );
}
