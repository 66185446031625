import { FormInstance } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AudioFilled } from "@ant-design/icons";

import cn from "../../../../utils/cn";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { sortColumn } from "../../constants";
import { DrawerActionType } from "../../types";
import getCategoryFilters from "../utils/getCategoryFilters";
import TableActions from "../components/TableActions";
import StatusBadge from "../components/StatusBadge";
import { ShelfType, TrialContentUploadSongType } from "../types";
import setValuesOnEdit from "./setValuesOnEdit";

export function createSongColumns(
  data: TrialContentUploadSongType[],
  form: FormInstance<any>,
  setModalData: React.Dispatch<
    React.SetStateAction<TrialContentUploadSongType | undefined>
  >,
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>,
  setDrawerAction: React.Dispatch<React.SetStateAction<DrawerActionType>>,
  deleteSong: any,
  refetch: any
): ColumnsType<TrialContentUploadSongType> {
  return [
    sortColumn,
    {
      title: "Title",
      dataIndex: "songTitle",
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      width: 120,
      filters: getCategoryFilters(data),
      onFilter: (value, record) => record.categoryName === value,
    },
    {
      title: "Song",
      dataIndex: "audioUrl",
      render: (value: string, record: TrialContentUploadSongType) => (
        <div
          className={cn("flex items-center gap-2", value ? "" : "hidden")}
          onClick={() => setModalData(record)}
        >
          <AudioFilled className="text-lg" />
          <p>Listen</p>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "productSongAndMarkets",
      width: 120,
      render: (value: ShelfType[]) => (
        <StatusBadge isActive={value.length > 0} />
      ),
    },
    {
      title: "Actions",
      dataIndex: "productSongAndMarkets",
      width: 120,
      render: (value: ShelfType[], record: TrialContentUploadSongType) => (
        <TableActions
          isInUse={value.length > 0}
          onEdit={() => {
            setValuesOnEdit(record, form);
            setOpenDrawer(true);
            setDrawerAction("Edit");
          }}
          onDelete={() => {
            const id = parseInt(renderDecodedId(record.id));

            deleteSong({ variables: { productSongId: id } })
              .then(() => refetch())
              .catch((e: any) => console.log(e));
          }}
        />
      ),
    },
  ];
}
