import { GetUploadProductSongsQuery } from "../../../../graphql/operations/trialMode/trialContentUpload/get-upload-product-songs";
import { GetUploadProductVideosQuery } from "../../../../graphql/operations/trialMode/trialContentUpload/get-upload-product-videos";
import {
  ShelfType,
  TrialContentUploadSongType,
  TrialContentUploadVideoType,
} from "../types";

export function normalizeUploadSongData(
  data: GetUploadProductSongsQuery
): TrialContentUploadSongType[] {
  const inputData = data.wf_trialModeLeads_productSong?.nodes || [];

  return inputData.map((item, index) => {
    const {
      id,
      productCategoryId,
      sortId,
      songTitle,
      productCategory,
      productSongAndMarkets,
      audioFileName,
      audioUrl,
      lyricsFileName,
    } = item;

    return {
      key: String(index + 1),
      id,
      productCategoryId: productCategoryId as number,
      sortId: sortId as number,
      songTitle: songTitle || "",
      category: productCategory?.category || "",
      categoryName: productCategory?.categoryName || "",
      productSongAndMarkets: productSongAndMarkets as ShelfType[],
      audioFileName: audioFileName || "",
      audioUrl: audioUrl || "",
      lyricsFileName: lyricsFileName || "",
    };
  });
}

export function normalizeUploadVideoData(
  data: GetUploadProductVideosQuery
): TrialContentUploadVideoType[] {
  const inputData = data.wf_trialModeLeads_productVideo?.nodes || [];

  return inputData.map((item, index) => {
    const {
      id,
      productCategoryId,
      sortId,
      videoTitle,
      productCategory,
      productVideoAndMarkets,
      introVideoFileName,
      videoFileName,
      videoUrl,
      videoThumbnail,
      videoThumbnailUrl,
    } = item;

    return {
      key: String(index + 1),
      id,
      productCategoryId: productCategoryId as number,
      sortId: sortId as number,
      videoTitle: videoTitle || "",
      category: productCategory?.category || "",
      categoryName: productCategory?.categoryName || "",
      productVideoAndMarkets: productVideoAndMarkets as ShelfType[],
      introVideoFileName: introVideoFileName || "",
      videoFileName: videoFileName || "",
      videoUrl: videoUrl || "",
      videoThumbnail: videoThumbnail || "",
      videoThumbnailUrl: videoThumbnailUrl || "",
    };
  });
}
