import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductVideosQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProductVideosQuery = { __typename?: 'Query', wf_trialModeLeads_productVideo?: { __typename?: 'WF_TrialModeLeads_ProductVideoConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_ProductVideo', id: string, productCategoryId?: number | null, sortId?: number | null, videoTitle?: string | null, videoUrl?: string | null, productCategory?: { __typename?: 'WF_TrialModeLeads_ProductCategory', category?: string | null, categoryName?: string | null } | null, productVideoAndMarkets?: Array<{ __typename?: 'WF_TrialModeLeads_ProductVideoAndMarket', id: string, market?: string | null, segmentation?: string | null } | null> | null }> | null } | null };


export const GetProductVideosDocument = gql`
    query GetProductVideos {
  wf_trialModeLeads_productVideo(first: 100, order: {sortId: ASC}) {
    nodes {
      id
      productCategoryId
      sortId
      videoTitle
      videoUrl: videoFileName @wf_blobSignature(name: "TRIAL_MODE_ONLINE_EXPERIENCE_VIDEOS_SIGNATURE")
      productCategory {
        category
        categoryName
      }
      productVideoAndMarkets {
        id
        market
        segmentation
      }
    }
  }
}
    `;

/**
 * __useGetProductVideosQuery__
 *
 * To run a query within a React component, call `useGetProductVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductVideosQuery(baseOptions?: Apollo.QueryHookOptions<GetProductVideosQuery, GetProductVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductVideosQuery, GetProductVideosQueryVariables>(GetProductVideosDocument, options);
      }
export function useGetProductVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductVideosQuery, GetProductVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductVideosQuery, GetProductVideosQueryVariables>(GetProductVideosDocument, options);
        }
export type GetProductVideosQueryHookResult = ReturnType<typeof useGetProductVideosQuery>;
export type GetProductVideosLazyQueryHookResult = ReturnType<typeof useGetProductVideosLazyQuery>;
export type GetProductVideosQueryResult = Apollo.QueryResult<GetProductVideosQuery, GetProductVideosQueryVariables>;