import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationsByMarketQueryVariables = Types.Exact<{
  market: Types.Scalars['String']['input'];
}>;


export type GetNotificationsByMarketQuery = { __typename?: 'Query', wf_adminFirst_afNotification?: { __typename?: 'WF_AdminFirst_AfNotificationConnection', edges?: Array<{ __typename?: 'WF_AdminFirst_AfNotificationEdge', node: { __typename?: 'WF_AdminFirst_AfNotification', id: string, templateMessage?: string | null, title?: string | null, actionButton?: string | null, buttonLinksTo?: string | null, buttonText?: string | null, createdTime?: any | null, creator?: string | null, imageFileName?: string | null, isAllMembers?: boolean | null, isRegular?: boolean | null, market?: string | null, afNotificationServiceTypeId?: string | null, scheduleTime?: any | null, jobStatus?: string | null } }> | null } | null };


export const GetNotificationsByMarketDocument = gql`
    query GetNotificationsByMarket($market: String!) {
  wf_adminFirst_afNotification(filter: {market: {eq: $market}}, last: 100) {
    edges {
      node {
        id
        templateMessage
        title
        actionButton
        buttonLinksTo
        buttonText
        createdTime
        creator
        imageFileName
        isAllMembers
        isRegular
        market
        afNotificationServiceTypeId
        scheduleTime
        jobStatus
      }
    }
  }
}
    `;

/**
 * __useGetNotificationsByMarketQuery__
 *
 * To run a query within a React component, call `useGetNotificationsByMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsByMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsByMarketQuery({
 *   variables: {
 *      market: // value for 'market'
 *   },
 * });
 */
export function useGetNotificationsByMarketQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsByMarketQuery, GetNotificationsByMarketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsByMarketQuery, GetNotificationsByMarketQueryVariables>(GetNotificationsByMarketDocument, options);
      }
export function useGetNotificationsByMarketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsByMarketQuery, GetNotificationsByMarketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsByMarketQuery, GetNotificationsByMarketQueryVariables>(GetNotificationsByMarketDocument, options);
        }
export type GetNotificationsByMarketQueryHookResult = ReturnType<typeof useGetNotificationsByMarketQuery>;
export type GetNotificationsByMarketLazyQueryHookResult = ReturnType<typeof useGetNotificationsByMarketLazyQuery>;
export type GetNotificationsByMarketQueryResult = Apollo.QueryResult<GetNotificationsByMarketQuery, GetNotificationsByMarketQueryVariables>;