import { useEffect, useState, useRef } from "react";
import {
  Select,
  Button,
  Table,
  Drawer,
  Modal,
  Form,
  Input,
  Upload,
  message,
} from "antd";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import {
  PlusOutlined,
  PlayCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import requiredRule from "../../../utils/formRules/requiredRule";
import DrawerFooter from "../../../components/layout/drawer/DrawerFooter";
import { VideoDataType, TagDataType } from "./types";
import dayjs from "dayjs";
import "./VideoTab.scss";
import {
  FormItemPublishDate,
  FormItemStopDate,
} from "../../trialMode/localEvent/components/FormItems";
import { useUploadFileMutation } from "../../../graphql/operations/upload-file";
import { AfBlobPath } from "../../../graphql/operations/@types";
import { apiCall } from "../../../utils/apiCall";

interface VideoTabProps {
  videos: any;
  tags: any;
  market: any;
  setMarket: any;
  currentUser: any;
  refetch: any;
  setLoading: any;
  category: string;
}

const VideoTab = ({
  tags,
  videos,
  market,
  setMarket,
  currentUser,
  refetch,
  setLoading,
  category,
}: VideoTabProps) => {
  const [total, setTotal] = useState(0);
  const [displayData, setDisplayData] = useState<VideoDataType[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isWatchOpen, setIsWatchOpen] = useState(false);
  const [tagFilters, setTagFilters] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [toBeDeletedId, setToBeDeletedId] = useState<number | null>(null);
  const [isVideoOrImageUploading, setIsVideoOrImageUploading] = useState(false);
  const [imageFileExtension, setImageFileExtension] = useState<
    string | null | undefined
  >(null);
  const [videoFileExtension, setVideoFileExtension] = useState<
    string | null | undefined
  >(null);
  const [imageFileObject, setImageFileObject] = useState<any>(null);
  const [videoFileObject, setVideoFileObject] = useState<any>(null);
  const [selectedVideo, setSelectedVideo] = useState<VideoDataType | null>();
  const [previewVideoInfo, setPreviewVideoInfo] =
    useState<VideoDataType | null>();
  const videoRef = useRef<any>();
  const [form] = Form.useForm();
  const [uploadFile] = useUploadFileMutation();
  const imageUploadProps: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image");
      if (!isImage) {
        message.error(renderAntDMessageConfig("Wrong file format!"));
        return Upload.LIST_IGNORE;
      }

      const fileExtension = file.name.split(".").pop();
      if (fileExtension) {
        setImageFileExtension(fileExtension.toLowerCase());
      }

      return true;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (!isVideoOrImageUploading) {
          setIsVideoOrImageUploading(true);
        }
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
        setIsVideoOrImageUploading(false);
        setImageFileObject(info.file.originFileObj);
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
    },
    onRemove: () => {
      setImageFileObject(null);
    },
    maxCount: 1,
    listType: "picture",
  };
  const videoUploadProps: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isVideo =
        file.type === "video/mp4" || file.type === "video/quicktime";
      if (!isVideo) {
        message.error("Invalid file format! Only MP4 and MOV are allowed.");
        return Upload.LIST_IGNORE;
      }

      const isWithinSizeLimit = file.size / 1024 / 1024 <= 500;
      if (!isWithinSizeLimit) {
        message.error("File is too large! Maximum size is 500MB.");
        return Upload.LIST_IGNORE;
      }

      const fileExtension = file.name.split(".").pop();
      if (fileExtension) {
        setVideoFileExtension(fileExtension.toLowerCase());
      }

      return true;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (!isVideoOrImageUploading) {
          setIsVideoOrImageUploading(true);
        }
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setIsVideoOrImageUploading(false);
        setVideoFileObject(info.file.originFileObj);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove: () => {
      setVideoFileObject(null);
    },
    maxCount: 1,
    listType: "text",
  };

  useEffect(() => {
    setDisplayData(videos);
  }, [videos]);
  useEffect(() => {
    setTagFilters(
      tags.map((item: TagDataType) => {
        return { text: item.title, value: item.title };
      })
    );
  }, [tags]);
  useEffect(() => {
    setTotal(displayData.length);
  }, [displayData]);
  useEffect(() => {
    if (selectedVideo) {
      setImageFileExtension(
        selectedVideo.imageUrl
          .slice(
            selectedVideo.imageUrl.lastIndexOf("/") + 1,
            selectedVideo.imageUrl.indexOf("?")
          )
          .split(".")
          .pop()
      );
      setVideoFileExtension(
        selectedVideo.videoUrl
          .slice(
            selectedVideo.videoUrl.lastIndexOf("/") + 1,
            selectedVideo.videoUrl.indexOf("?")
          )
          .split(".")
          .pop()
      );
      form.setFieldsValue({
        title: selectedVideo.title,
        tag: selectedVideo.tags,
        video: [
          {
            uid: "0",
            name: selectedVideo.videoUrl.slice(
              selectedVideo.videoUrl.lastIndexOf("/") + 1,
              selectedVideo.videoUrl.indexOf("?")
            ),
            status: "done",
            url: selectedVideo.videoUrl,
          },
        ],
        image: [
          {
            uid: "0",
            name: selectedVideo.imageUrl.slice(
              selectedVideo.imageUrl.lastIndexOf("/") + 1,
              selectedVideo.imageUrl.indexOf("?")
            ),
            status: "done",
            url: selectedVideo.imageUrl,
          },
        ],
        publishDate: selectedVideo.publishedDate
          ? dayjs(selectedVideo.publishedDate)
          : null,
        stopDate: selectedVideo.stoppedDate
          ? dayjs(selectedVideo.stoppedDate)
          : null,
      });
    } else {
      form.resetFields();
      setImageFileObject(null);
      setVideoFileObject(null);
      setImageFileExtension(null);
      setVideoFileExtension(null);
    }
  }, [selectedVideo]);

  const handleMarketChange = (value: string) => {
    setMarket(value);
  };
  const handleWatchClick = (item: VideoDataType) => {
    setIsWatchOpen(true);
    setPreviewVideoInfo(item);
  };
  const onCancel = () => {
    setIsWatchOpen(false);
    setPreviewVideoInfo(null);
  };
  const renameFile = (
    file: any,
    newFileName: string,
    fileExtension: string
  ) => {
    if (!file) {
      return;
    }
    const fileBlob = file.originFileObj || file; // 確保傳入的是有效的檔案物件

    // 創建新的 File 物件
    return new File([fileBlob], `${newFileName}.${fileExtension}`, {
      type: fileBlob.type,
    });
  };
  const handleSubmit = async () => {
    if (isVideoOrImageUploading) {
      message.error("Video or Image still uploading, please wait!");
      return;
    }
    const { title, tag, publishDate, stopDate } = form.getFieldsValue();
    const payload = isEditMode
      ? {
          title: title,
          tags: tag.map(
            (v1: string) => tags.find((v2: any) => v2.title === v1).tagId
          ),
          videoFileExtension: videoFileExtension,
          imageFileExtension: imageFileExtension,
          published: publishDate ? dayjs(publishDate).toISOString() : "",
          stopped: stopDate ? dayjs(stopDate).toISOString() : "",
        }
      : {
          title: title,
          market: market,
          category: category,
          tags: tag.map(
            (v1: string) => tags.find((v2: any) => v2.title === v1).tagId
          ),
          videoFileExtension: videoFileExtension,
          imageFileExtension: imageFileExtension,
          published: publishDate ? dayjs(publishDate).toISOString() : "",
          stopped: stopDate ? dayjs(stopDate).toISOString() : "",
        };
    try {
      setLoading(true);
      const data = await apiCall({
        method: isEditMode ? "PATCH" : "POST",
        endPoint: isEditMode
          ? process.env.REACT_APP_DEMO_APP_VIDEO + `/${selectedVideo?.videoId}`
          : process.env.REACT_APP_DEMO_APP_VIDEO,
        payload: payload,
      });
      const { id } = data;
      if (id || imageFileObject || videoFileObject) {
        const promises = [];
        const renamedImageObject = renameFile(
          imageFileObject,
          id ? id.toString() : selectedVideo?.videoId.toString(),
          imageFileExtension || ""
        );
        const uploadImagePromise = uploadFile({
          variables: {
            blobs: [
              {
                file: renamedImageObject,
                path:
                  category === "club-services"
                    ? AfBlobPath.WfcDemoThumbnailsClubServices
                    : category === "testimonial"
                    ? AfBlobPath.WfcDemoThumbnailsTestimonial
                    : AfBlobPath.WfcDemoThumbnailsNews,
              },
            ],
          },
        });
        const renamedVideoObject = renameFile(
          videoFileObject,
          id ? id.toString() : selectedVideo?.videoId.toString(),
          videoFileExtension || ""
        );
        const uploadVideoPromise = uploadFile({
          variables: {
            blobs: [
              {
                file: renamedVideoObject,
                path:
                  category === "club-services"
                    ? AfBlobPath.WfcDemoVideosClubServices
                    : category === "testimonial"
                    ? AfBlobPath.WfcDemoVideosTestimonial
                    : AfBlobPath.WfcDemoVideosNews,
              },
            ],
          },
        });
        if (imageFileObject) {
          promises.push(uploadImagePromise);
        }

        if (videoFileObject) {
          promises.push(uploadVideoPromise);
        }
        Promise.all(promises).then(() => {
          refetch();
          setLoading(false);
          setDrawerOpen(false);
          setSelectedVideo(null);
          setIsEditMode(false);
        });
      } else {
        refetch();
        setLoading(false);
        setDrawerOpen(false);
        setSelectedVideo(null);
        setIsEditMode(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      throw error;
    }
  };

  const handleDelete = async () => {
    if (toBeDeletedId) {
      await apiCall({
        method: "DELETE",
        endPoint:
          process.env.REACT_APP_DEMO_APP_VIDEO + `/${toBeDeletedId.toString()}`,
      })
        .then(() => {
          setIsDeleteModalOpen(false);
          setToBeDeletedId(null);
          refetch();
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  };
  const columns: ColumnsType<VideoDataType> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 470,
    },
    {
      title: "Tag",
      dataIndex: "tags",
      key: "tags",
      width: 150,
      filters: tagFilters,
      onFilter: (value, record) => record.tags.includes(value as string),
      render: (tags) => {
        if (tags.length > 0) {
          return <p>{tags.join(" ,")}</p>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: "Video",
      dataIndex: "videoUrl",
      key: "videoUrl",
      width: 220,
      render: (_, item) => {
        return (
          <div className="video-box">
            <PlayCircleFilled
              className="play-icon"
              onClick={() => handleWatchClick(item)}
            />
            <p>Watch</p>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isInUse",
      key: "isInUse",
      width: 115,
      render: (bool, item) => {
        return bool ? (
          <div className="in-use-container">
            <div className="in-use dot" />
            <p>In use</p>
          </div>
        ) : (
          <div className="in-use-container">
            <div className="not-in-use dot" />
            <p>Not in use</p>
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 120,
      render: (_, item) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setIsEditMode(true);
                setDrawerOpen(true);
                setSelectedVideo(item);
              }}
              className="edit-button"
            >
              Edit
            </Button>
            <Button
              type="link"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setToBeDeletedId(item.videoId);
              }}
              className="delete-button"
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <div id="DemoAppVideoTabWrapper">
        <div className="table-container">
          <div className="select-total">
            <div className="total-container">Total {total} items</div>
            <div>
              <span
                style={
                  currentUser?.afUserMarkets?.length === 1
                    ? { display: "none" }
                    : {}
                }
              >
                Market:
              </span>
              <Select
                onChange={handleMarketChange}
                value={market}
                options={[
                  {
                    value: "TW",
                    label: "TW",
                  },
                  {
                    value: "HK",
                    label: "HK",
                  },
                  {
                    value: "KR",
                    label: "KR",
                  },
                ]}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="create-button"
                onClick={() => {
                  setDrawerOpen(true);
                }}
              >
                Create New
              </Button>
            </div>
          </div>
          <div className="table-container no-pt">
            <Table
              dataSource={displayData}
              columns={columns}
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
              }}
            />
          </div>
        </div>
      </div>
      <Drawer
        open={drawerOpen}
        width={500}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedVideo(null);
          setIsEditMode(false);
        }}
        title={isEditMode ? "Edit video" : "Create new video"}
        footer={
          <DrawerFooter
            handleSubmit={() => {
              form
                .validateFields()
                .then(() => {
                  handleSubmit();
                })
                .catch((errorInfo) => {
                  console.error("Validation Failed:", errorInfo);
                });
            }}
            handleCancel={() => {
              setDrawerOpen(false);
              setSelectedVideo(null);
              setIsEditMode(false);
            }}
          />
        }
      >
        <div id="videoDrawerWrapper">
          <div>
            <p className="title">Market</p>
            <p className="content">{market}</p>
          </div>
          <Form form={form} layout="vertical">
            <Form.Item
              className="form-item"
              label="Title"
              rules={[requiredRule]}
              name="title"
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Tag"
              rules={[requiredRule]}
              name="tag"
            >
              <Select
                placeholder="- Select -"
                options={tags.map((item: any) => {
                  return { label: item.title, value: item.title };
                })}
                mode="multiple"
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Video thumbnail"
              name="image"
              rules={[requiredRule]}
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList}
            >
              <Upload {...imageUploadProps}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <p className="reminder-text">
              Recommended resolution is 480px * 270px with file size less than
              300KB. Format: JPEG, JPG, PNG.
            </p>
            <Form.Item
              className="form-item"
              label="Video file"
              name="video"
              rules={[requiredRule]}
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList}
            >
              <Upload {...videoUploadProps}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <p className="reminder-text">
              Format: MP4, MOV. Max file size: 500MB
            </p>
            <FormItemPublishDate />
            <FormItemStopDate reminder="If no stop date is selected, this video will appear permanently" />
          </Form>
        </div>
      </Drawer>
      <Modal
        open={isWatchOpen}
        onCancel={onCancel}
        title={previewVideoInfo?.title}
        footer={null}
        width={410}
        forceRender
      >
        {previewVideoInfo && (
          <video
            ref={videoRef}
            src={previewVideoInfo.videoUrl}
            width="360"
            height="640"
            controls
            preload="metadata"
            title={previewVideoInfo.title}
          >
            Your browser does not support the video tag.
          </video>
        )}
      </Modal>
      <Modal
        centered
        open={isDeleteModalOpen}
        closeIcon={null}
        footer={[
          <div className="modal-button-group">
            <Button
              onClick={() => {
                setIsDeleteModalOpen(false);
                setToBeDeletedId(null);
              }}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              No
            </Button>
            <Button
              danger
              onClick={handleDelete}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              Delete
            </Button>
          </div>,
        ]}
        width={417}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 76,
            marginBottom: 24,
            marginRight: 8,
            marginLeft: 8,
            marginTop: 12,
          }}
        >
          <img
            alt="warning circle icon"
            src={require("../../../assets/icons/warning-circle.png")}
            style={{ width: 22, height: 22, marginRight: 16 }}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <p style={{ fontSize: 16, fontWeight: 500 }}>
              Are you sure you want to delete the item?
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VideoTab;
