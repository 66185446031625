import ContentTitle from "../../../components/ui/ContentTitle";
import { Tabs, TabsProps, message } from "antd";
import { Colors } from "../../../constants/Colors";
import { useState, useEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import { ServiceTypes } from "../../../types/types";
import VideoTab from "./VideoTab";
import TagTab from "./TagTab";
import { apiCall } from "../../../utils/apiCall";
import { useReactiveVar } from "@apollo/client";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { __currentUser__ } from "../../../graphql/policies";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";

interface DemoAppServiceTemplateProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
  category: string;
}

const mockData = [
  {
    title: "Talk Along Card Sentence Application",
    tag: ["CAP"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Talk Along Card Sentence Utilization",
    tag: ["Club Shows"],
    videoUrl: "https://player.vimeo.com/video/867811089",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-10-01",
  },
  {
    title: "Sing-along! 12 English musical part 2",
    tag: ["Graduation"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-10-01",
  },
  {
    title: "Sing-along! 12 English musical part 3",
    tag: ["Graduation"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-10-01",
  },
  {
    title: "Sing-along! 12 English musical part 4",
    tag: ["Graduation"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-10-01",
  },
  {
    title: "Sing-along! 12 English musical part 1",
    tag: ["CAP"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "HK",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Sing-along! 1 Pluto's song",
    tag: ["CAP", "Graduation"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "HK",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Play Along! Just like me",
    tag: ["Club Shows"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "HK",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Fun with Words with a magic pen",
    tag: ["Club Shows"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "HK",
    startDate: "2024-09-01",
    endDate: "2024-10-01",
  },
  {
    title: "A 2-year-old baby enjoying songs",
    tag: ["Telephone English"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "KR",
    startDate: "2024-09-01",
    endDate: "2024-10-01",
  },
  {
    title: "A 1-year-old baby enjoying songs",
    tag: ["Face Call"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "KR",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Test 1",
    tag: ["Go, Go Live!"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "KR",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Test 2",
    tag: ["Go, Go Live!", "Library"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Test 3",
    tag: ["Library"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Test 4",
    tag: ["Parents Support"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Test 5",
    tag: ["Parents Support", "Club App"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Test 6",
    tag: ["Videos"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-10-01",
  },
  {
    title: "Test 7",
    tag: ["Videos", "Telephone English"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Test 8",
    tag: ["CAP", "Face Call"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "TW",
    startDate: "2024-09-01",
    endDate: "2024-10-01",
  },
  {
    title: "Test 9 ",
    tag: ["Library"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "KR",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
  {
    title: "Talk Along Card Sentence Application",
    tag: ["CAP"],
    videoUrl: "https://player.vimeo.com/video/867623306",
    market: "HK",
    startDate: "2024-09-01",
    endDate: "2024-11-31",
  },
];

const ServiceTemplate = ({
  setSelectedService,
  setSelectSubItem,
  category,
}: DemoAppServiceTemplateProps) => {
  const [videos, setVideos] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [market, setMarket] = useState("");
  const categoryList = [
    { title: "Club Services", value: "club-services" },
    { title: "Testimonial", value: "testimonial" },
    { title: "Company & News", value: "news" },
  ];
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 9,
    },
  });
  useEffect(() => {
    if (currentUser?.afUserMarkets && currentUser?.afUserMarkets[0]?.market) {
      if (currentUser?.afUserMarkets.length === 1) {
        setMarket(currentUser?.afUserMarkets[0]?.market);
      } else {
        setMarket("KR");
      }
    }
  }, [currentUser]);
  useEffect(() => {
    setSelectedService(ServiceTypes.DEMO);
    setSelectSubItem(categoryList.find((v) => v.value === category)?.title);
  }, []);
  const fetchVideosAndTagsData = async () => {
    const data = await apiCall({
      method: "GET",
      endPoint: `${process.env.REACT_APP_DEMO_APP_VIDEO}/${market}/${category}`,
    });
    if (data.videos) {
      setVideos(data.videos);
    }
    if (data.tags) {
      setTags(data.tags);
    }
  };
  useEffect(() => {
    if (market) {
      fetchVideosAndTagsData();
    }
  }, [market, category]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Video",
      children: (
        <VideoTab
          category={category}
          tags={tags}
          videos={videos}
          market={market}
          setMarket={setMarket}
          currentUser={currentUser}
          refetch={fetchVideosAndTagsData}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: "2",
      label: "Tag",
      children: (
        <TagTab
          category={category}
          tags={tags}
          videos={videos}
          market={market}
          setMarket={setMarket}
          currentUser={currentUser}
          refetch={fetchVideosAndTagsData}
        />
      ),
    },
  ];
  if (loading) {
    return <Spinner />;
  }
  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }
  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Video management"
    )
  ) {
    return <NoPermission />;
  }
  return (
    <>
      <ContentTitle
        title={categoryList.find((v) => v.value === category)?.title || ""}
      />
      <Tabs
        defaultActiveKey="1"
        items={items}
        size="small"
        tabBarStyle={{
          paddingLeft: 24,
          backgroundColor: Colors.white,
          height: 50,
        }}
      />
    </>
  );
};

export default ServiceTemplate;
