import { Radio } from "antd";

import MarketDropdown from "../../../components/ui/MarketDropdown";
import { MarketType } from "../types";
import { ContentType } from "./types";

export default function TableMenu({
  isEditing,
  setSelectedContentType,
  selectedMarket,
  setSelectedMarket,
}: {
  isEditing: boolean;
  setSelectedContentType: (value: React.SetStateAction<ContentType>) => void;
  selectedMarket: MarketType;
  setSelectedMarket: (value: React.SetStateAction<MarketType>) => void;
}) {
  return (
    <div className="flex justify-between px-2 py-4">
      <Radio.Group
        optionType="button"
        options={[
          {
            value: "song",
            label: "Listen",
          },
          {
            value: "video",
            label: "Watch",
          },
          { value: "bedtime", label: "Bedtime songs" },
        ]}
        defaultValue="song"
        onChange={(e) => setSelectedContentType(e.target.value)}
        disabled={isEditing}
      />

      <MarketDropdown
        selectedMarket={selectedMarket}
        setSelectedMarket={setSelectedMarket}
        disabled={isEditing}
        alwaysShow={true}
      />
    </div>
  );
}
