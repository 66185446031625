import dashboardIcon from "../../../assets/icons/submenu/dashboard.png";
import contentIcon from "../../../assets/icons/submenu/content.png";
import designIcon from "../../../assets/icons/submenu/design.png";
import graduationIcon from "../../../assets/icons/submenu/graduation.png";
import pushNotificationIcon from "../../../assets/icons/submenu/push-notification.png";
import goGoLiveIcon from "../../../assets/icons/submenu/go-go-live.png";
import importantNoticeIcon from "../../../assets/icons/submenu/important-notice.png";
import faceCallIcon from "../../../assets/icons/submenu/face-call.png";
import versionIcon from "../../../assets/icons/submenu/version.png";
import {
  ServiceCategoryRouteTypes,
  ServiceCategoryTypes,
  ServiceRouteTypes,
  ServiceTypes,
} from "../../../types/types";
import { ServiceItemsType } from "./types";

export const subMenuWidth = 220;

export const serviceItems: ServiceItemsType[] = [
  {
    service: ServiceTypes.CLUB_APP,
    serviceRoute: ServiceRouteTypes.CLUB_APP,
    title: "WF Club App",
    items: [
      {
        category: ServiceCategoryTypes.DASHBOARD,
        categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
        icon: dashboardIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.DESIGN,
        categoryRoute: ServiceCategoryRouteTypes.DESIGN,
        icon: designIcon,
        subItems: [
          {
            subItemName: "Announcement Bar",
            routeName: "announcement-bar",
          },
          {
            subItemName: "Service Banner & Icon",
            routeName: "service-banner-and-icon",
          },
          {
            subItemName: "Event Banner",
            routeName: "event-banner",
          },
        ],
      },
      {
        category: ServiceCategoryTypes.CLUB_APP_PUSH_NOTIFICATION,
        categoryRoute: ServiceCategoryRouteTypes.CLUB_APP_PUSH_NOTIFICATION,
        icon: pushNotificationIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.CLUB_APP_FACE_CALL,
        categoryRoute: ServiceCategoryRouteTypes.CLUB_APP_FACE_CALL,
        icon: faceCallIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.GRADUATION,
        categoryRoute: ServiceCategoryRouteTypes.GRADUATION,
        icon: graduationIcon,
        subItems: [
          {
            subItemName: "Waiting for Review",
            routeName: "waiting-for-review",
          },
          {
            subItemName: "All Applications",
            routeName: "all-applications",
          },
          {
            subItemName: "Kids Info",
            routeName: "kids-info",
          },
        ],
      },
      {
        category: ServiceCategoryTypes.CLUB_APP_GO_GO_LIVE,
        categoryRoute: ServiceCategoryRouteTypes.CLUB_APP_GO_GO_LIVE,
        icon: goGoLiveIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.CLUB_APP_IMPORTANT_NOTICE,
        categoryRoute: ServiceCategoryRouteTypes.CLUB_APP_IMPORTANT_NOTICE,
        icon: importantNoticeIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.CLUB_APP_RELEASE_VERSION,
        categoryRoute: ServiceCategoryRouteTypes.CLUB_APP_RELEASE_VERSION,
        icon: versionIcon,
        subItems: [],
      },
    ],
  },
  {
    service: ServiceTypes.CHECK_IN,
    serviceRoute: ServiceRouteTypes.CHECK_IN,
    title: "Check-in App",
    items: [
      {
        category: ServiceCategoryTypes.DASHBOARD,
        categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
        icon: dashboardIcon,
        subItems: [],
      },
    ],
  },
  {
    service: ServiceTypes.CLUB_SERVICE,
    serviceRoute: ServiceRouteTypes.CLUB_SERVICE,
    title: "Club Service",
    items: [
      {
        category: ServiceCategoryTypes.DASHBOARD,
        categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
        icon: dashboardIcon,
        subItems: [],
      },
    ],
  },
  {
    service: ServiceTypes.SALES_188,
    serviceRoute: ServiceRouteTypes.SALES_188,
    title: "Sales 188 App",
    items: [
      {
        category: ServiceCategoryTypes.DASHBOARD,
        categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
        icon: dashboardIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.SALES_188_PUSH_NOTIFICATION,
        categoryRoute: ServiceCategoryRouteTypes.SALES_188_PUSH_NOTIFICATION,
        icon: pushNotificationIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.SALES_188_RELEASE_VERSION,
        categoryRoute: ServiceCategoryRouteTypes.SALES_188_RELEASE_VERSION,
        icon: versionIcon,
        subItems: [],
      },
    ],
  },
  {
    service: ServiceTypes.DEMO,
    serviceRoute: ServiceRouteTypes.DEMO,
    title: "WFE demo",
    items: [
      {
        category: ServiceCategoryTypes.DASHBOARD,
        categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
        icon: dashboardIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.VIDEO_MANAGEMENT,
        categoryRoute: ServiceCategoryRouteTypes.VIDEO_MANAGEMENT,
        icon: contentIcon,
        subItems: [
          {
            subItemName: "Testimonial",
            routeName: "testimonial",
          },
          {
            subItemName: "Club services",
            routeName: "club-services",
          },
          {
            subItemName: "Company & news",
            routeName: "company-news",
          },
        ],
      },
    ],
  },
  {
    service: ServiceTypes.TRIAL_MODE,
    serviceRoute: ServiceRouteTypes.TRIAL_MODE,
    title: "Trial Mode",
    items: [
      {
        category: ServiceCategoryTypes.DATA_ANALYSIS,
        categoryRoute: ServiceCategoryRouteTypes.DATA_ANALYSIS,
        icon: dashboardIcon,
        subItems: [
          { subItemName: "Dashboard", routeName: "dashboard" },
          {
            subItemName: "Leads Detail Download",
            routeName: "leads-detail-download",
          },
          { subItemName: "Campaign ID", routeName: "campaign-id" },
        ],
      },
      {
        category: ServiceCategoryTypes.CONTENT,
        categoryRoute: ServiceCategoryRouteTypes.CONTENT,
        icon: contentIcon,
        subItems: [
          {
            subItemName: "Local Event",
            routeName: "local-event",
          },
          {
            subItemName: "Caring CTA",
            routeName: "caring-cta",
          },
          {
            subItemName: "Journey Card",
            routeName: "journey-card",
          },
          {
            subItemName: "Trial Content",
            routeName: "trial-content",
          },
          {
            subItemName: "Trial Content Upload",
            routeName: "trial-content-upload",
          },
        ],
      },
      {
        category: ServiceCategoryTypes.TRIAL_MODE_PUSH_NOTIFICATION,
        categoryRoute: ServiceCategoryRouteTypes.TRIAL_MODE_PUSH_NOTIFICATION,
        icon: pushNotificationIcon,
        subItems: [],
      },
    ],
  },
  {
    service: ServiceTypes.MKT,
    serviceRoute: ServiceRouteTypes.MKT,
    title: "Marketing Service",
    items: [
      {
        category: ServiceCategoryTypes.DASHBOARD,
        categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
        icon: dashboardIcon,
        subItems: [],
      },
      {
        category: ServiceCategoryTypes.CONTENT,
        categoryRoute: ServiceCategoryRouteTypes.CONTENT,
        icon: contentIcon,
        subItems: [
          {
            subItemName: "Vendor Reports",
            routeName: "vendor-reports",
          },
          {
            subItemName: "Ad Exclusion List",
            routeName: "ad-exclusion-list",
          },
        ],
      },
    ],
  },
  {
    service: ServiceTypes.TECHPRESSO,
    serviceRoute: ServiceRouteTypes.TECHPRESSO,
    title: "Techpresso App",
    items: [
      {
        category: ServiceCategoryTypes.DASHBOARD,
        categoryRoute: ServiceCategoryRouteTypes.DASHBOARD,
        icon: dashboardIcon,
        subItems: [],
      },
    ],
  },
];
