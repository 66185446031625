import { Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AudioFilled, PlayCircleFilled } from "@ant-design/icons";

import getAuthorizedMarkets from "../utils/getAuthorizedMarkets";
import getSegmentationDesc from "../utils/getSegmentationDesc";
import { MarketType } from "../types";
import isChecked from "./utils/isChecked";
import isDisabled from "./utils/isDisabled";
import getSegmentationOrder from "./utils/getSegmentationOrder";
import TableActions from "./TableActions";
import { TrialContentSongType, TrialContentVideoType } from "./types";
import { handleSongSave, handleVideoSave } from "./handleSave";

const fixedWidth = 100;

export function createSongColumns(
  selectedMarket: MarketType,
  editingSong: TrialContentSongType | null,
  setEditingSong: React.Dispatch<
    React.SetStateAction<TrialContentSongType | null>
  >,
  editingSegmentation: number,
  setEditingSegmentation: React.Dispatch<React.SetStateAction<number>>,
  createSongAndMarkets: any,
  deleteSongAndMarkets: any,
  refetchSongData: any,
  setModalData: React.Dispatch<
    React.SetStateAction<TrialContentSongType | undefined>
  >
): ColumnsType<TrialContentSongType> {
  const isSelectedMarketAuthorized =
    getAuthorizedMarkets().includes(selectedMarket);

  return [
    {
      title: "#",
      width: 50,
      render: (_, record, index) => index + 1,
    },
    {
      title: "Song title",
      dataIndex: "songTitle",
      render: (value: string, record: TrialContentSongType) => (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => setModalData(record)}
        >
          <AudioFilled className="text-lg" />
          <p>{value}</p>
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: fixedWidth,
    },
    {
      title: getSegmentationDesc("Awareness"),
      width: fixedWidth,
      align: "center",
      render: (_, record: TrialContentSongType) => {
        const isEditing = record.id === editingSong?.id;

        return (
          <Checkbox
            disabled={isDisabled(0, isEditing, editingSegmentation)}
            checked={isChecked(
              selectedMarket,
              record.productSongAndMarkets,
              "Awareness",
              isEditing,
              editingSegmentation
            )}
            onChange={() =>
              setEditingSegmentation(editingSegmentation === 0 ? Infinity : 0)
            }
          />
        );
      },
    },
    {
      title: getSegmentationDesc("Interest"),
      width: fixedWidth,
      align: "center",
      render: (_, record: TrialContentSongType) => {
        const isEditing = record.id === editingSong?.id;

        return (
          <Checkbox
            disabled={isDisabled(1, isEditing, editingSegmentation)}
            checked={isChecked(
              selectedMarket,
              record.productSongAndMarkets,
              "Interest",
              isEditing,
              editingSegmentation
            )}
            onChange={() =>
              setEditingSegmentation(editingSegmentation === 1 ? Infinity : 1)
            }
          />
        );
      },
    },
    {
      title: getSegmentationDesc("Decision"),
      width: fixedWidth,
      align: "center",
      render: (_, record: TrialContentSongType) => {
        const isEditing = record.id === editingSong?.id;

        return (
          <Checkbox
            disabled={isDisabled(2, isEditing, editingSegmentation)}
            checked={isChecked(
              selectedMarket,
              record.productSongAndMarkets,
              "Decision",
              isEditing,
              editingSegmentation
            )}
            onChange={() =>
              setEditingSegmentation(editingSegmentation === 2 ? Infinity : 2)
            }
          />
        );
      },
    },
    ...(isSelectedMarketAuthorized
      ? [
          {
            title: "Actions",
            width: 150,
            render: (_: any, record: TrialContentSongType) => {
              const segmentationOrder = getSegmentationOrder(
                selectedMarket,
                record.productSongAndMarkets
              );

              return (
                <TableActions
                  record={record}
                  editingData={editingSong}
                  editingSegmentation={editingSegmentation}
                  segmentationOrder={segmentationOrder}
                  onEdit={() => {
                    setEditingSong(record);
                    setEditingSegmentation(segmentationOrder);
                  }}
                  onCancel={() => {
                    setEditingSong(null);
                    setEditingSegmentation(Infinity);
                  }}
                  onSave={() =>
                    handleSongSave(
                      record,
                      segmentationOrder,
                      selectedMarket,
                      setEditingSong,
                      editingSegmentation,
                      setEditingSegmentation,
                      createSongAndMarkets,
                      deleteSongAndMarkets,
                      refetchSongData
                    )
                  }
                />
              );
            },
          },
        ]
      : []),
  ];
}

export function createVideoColumns(
  selectedMarket: MarketType,
  editingVideo: TrialContentVideoType | null,
  setEditingVideo: React.Dispatch<
    React.SetStateAction<TrialContentVideoType | null>
  >,
  editingSegmentation: number,
  setEditingSegmentation: React.Dispatch<React.SetStateAction<number>>,
  createVideoAndMarkets: any,
  deleteVideoAndMarkets: any,
  refetchVideoData: any,
  setModalData: React.Dispatch<
    React.SetStateAction<TrialContentVideoType | undefined>
  >
): ColumnsType<TrialContentVideoType> {
  const isSelectedMarketAuthorized =
    getAuthorizedMarkets().includes(selectedMarket);

  return [
    {
      title: "#",
      dataIndex: "key",
      width: 50,
    },
    {
      title: "Video title",
      dataIndex: "videoTitle",
      render: (value: string, record: TrialContentVideoType) => (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => setModalData(record)}
        >
          <PlayCircleFilled className="text-lg" />
          <p>{value}</p>
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: fixedWidth,
    },
    {
      title: "Awareness",
      dataIndex: "productVideoAndMarkets",
      width: fixedWidth,
      align: "center",
      render: (_, record: TrialContentVideoType) => {
        const isEditing = record.id === editingVideo?.id;

        return (
          <Checkbox
            disabled={isDisabled(0, isEditing, editingSegmentation)}
            checked={isChecked(
              selectedMarket,
              record.productVideoAndMarkets,
              "Awareness",
              isEditing,
              editingSegmentation
            )}
            onChange={() =>
              setEditingSegmentation(editingSegmentation === 0 ? Infinity : 0)
            }
          />
        );
      },
    },
    {
      title: "Interest",
      dataIndex: "productVideoAndMarkets",
      width: fixedWidth,
      align: "center",
      render: (_, record: TrialContentVideoType) => {
        const isEditing = record.id === editingVideo?.id;

        return (
          <Checkbox
            disabled={isDisabled(1, isEditing, editingSegmentation)}
            checked={isChecked(
              selectedMarket,
              record.productVideoAndMarkets,
              "Interest",
              isEditing,
              editingSegmentation
            )}
            onChange={() =>
              setEditingSegmentation(editingSegmentation === 1 ? Infinity : 1)
            }
          />
        );
      },
    },
    {
      title: "Decision",
      dataIndex: "productVideoAndMarkets",
      width: fixedWidth,
      align: "center",
      render: (_, record: TrialContentVideoType) => {
        const isEditing = record.id === editingVideo?.id;

        return (
          <Checkbox
            disabled={isDisabled(2, isEditing, editingSegmentation)}
            checked={isChecked(
              selectedMarket,
              record.productVideoAndMarkets,
              "Decision",
              isEditing,
              editingSegmentation
            )}
            onChange={() =>
              setEditingSegmentation(editingSegmentation === 2 ? Infinity : 2)
            }
          />
        );
      },
    },
    ...(isSelectedMarketAuthorized
      ? [
          {
            title: "Actions",
            width: 150,
            render: (_: any, record: TrialContentVideoType) => {
              const segmentationOrder = getSegmentationOrder(
                selectedMarket,
                record.productVideoAndMarkets
              );

              return (
                <TableActions
                  record={record}
                  editingData={editingVideo}
                  editingSegmentation={editingSegmentation}
                  segmentationOrder={segmentationOrder}
                  onEdit={() => {
                    setEditingVideo(record);
                    setEditingSegmentation(segmentationOrder);
                  }}
                  onCancel={() => {
                    setEditingVideo(null);
                    setEditingSegmentation(Infinity);
                  }}
                  onSave={() =>
                    handleVideoSave(
                      record,
                      segmentationOrder,
                      selectedMarket,
                      setEditingVideo,
                      editingSegmentation,
                      setEditingSegmentation,
                      createVideoAndMarkets,
                      deleteVideoAndMarkets,
                      refetchVideoData
                    )
                  }
                />
              );
            },
          },
        ]
      : []),
  ];
}
