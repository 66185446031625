import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateGraduateChildMutationVariables = Types.Exact<{
  seq: Types.Scalars['Int']['input'];
  childWillingEnglishSpeech?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  childEnglishName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  childHeight?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  childWeight?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  parentWillingChineseSpeech?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  updatedTime?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  childPhotoFilename?: Types.InputMaybe<Types.Scalars['String']['input']>;
  updater?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateGraduateChildMutation = { __typename?: 'Mutation', afUpdateGraduationChild: { __typename?: 'Payload', count: number } };


export const UpdateGraduateChildDocument = gql`
    mutation updateGraduateChild($seq: Int!, $childWillingEnglishSpeech: Boolean, $childEnglishName: String, $childHeight: Int, $childWeight: Int, $parentWillingChineseSpeech: Boolean, $updatedTime: DateTime, $childPhotoFilename: String, $updater: String) {
  afUpdateGraduationChild(
    seq: $seq
    childWillingEnglishSpeech: $childWillingEnglishSpeech
    childEnglishName: $childEnglishName
    childHeight: $childHeight
    childWeight: $childWeight
    parentWillingChineseSpeech: $parentWillingChineseSpeech
    updatedTime: $updatedTime
    childPhotoFilename: $childPhotoFilename
    updater: $updater
  ) {
    count
  }
}
    `;
export type UpdateGraduateChildMutationFn = Apollo.MutationFunction<UpdateGraduateChildMutation, UpdateGraduateChildMutationVariables>;

/**
 * __useUpdateGraduateChildMutation__
 *
 * To run a mutation, you first call `useUpdateGraduateChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGraduateChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGraduateChildMutation, { data, loading, error }] = useUpdateGraduateChildMutation({
 *   variables: {
 *      seq: // value for 'seq'
 *      childWillingEnglishSpeech: // value for 'childWillingEnglishSpeech'
 *      childEnglishName: // value for 'childEnglishName'
 *      childHeight: // value for 'childHeight'
 *      childWeight: // value for 'childWeight'
 *      parentWillingChineseSpeech: // value for 'parentWillingChineseSpeech'
 *      updatedTime: // value for 'updatedTime'
 *      childPhotoFilename: // value for 'childPhotoFilename'
 *      updater: // value for 'updater'
 *   },
 * });
 */
export function useUpdateGraduateChildMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGraduateChildMutation, UpdateGraduateChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGraduateChildMutation, UpdateGraduateChildMutationVariables>(UpdateGraduateChildDocument, options);
      }
export type UpdateGraduateChildMutationHookResult = ReturnType<typeof useUpdateGraduateChildMutation>;
export type UpdateGraduateChildMutationResult = Apollo.MutationResult<UpdateGraduateChildMutation>;
export type UpdateGraduateChildMutationOptions = Apollo.BaseMutationOptions<UpdateGraduateChildMutation, UpdateGraduateChildMutationVariables>;