import { FormInstance } from "antd";

import { AfBlobPath } from "../../../../graphql/operations/@types";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { DrawerActionType } from "../../types";
import { denormalizeUploadSongData } from "../utils/denormalizeUploadData";
import {
  TrialContentUploadSongFormDataType,
  TrialContentUploadSongType,
} from "../types";
import sortIdUpdator from "../utils/sortIdUpdator";

export default function handleSubmit({
  values,
  form,
  songData,
  drawerAction,
  uploadFile,
  createSong,
  updateSong,
  updateSortId,
  refetch,
  setOpenDrawer,
}: {
  values: TrialContentUploadSongFormDataType;
  form: FormInstance<any>;
  songData: TrialContentUploadSongType[];
  drawerAction: DrawerActionType;
  uploadFile: any;
  createSong: any;
  updateSong: any;
  updateSortId: any;
  refetch: any;
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
}) {
  const id = form.getFieldValue("id");
  const audioFile = values.audioFile[0] || {};
  const lyricsFile = values.lyricsFile?.[0] || {};
  const blobs = [];

  if (audioFile.originFileObj)
    blobs.push({
      file: audioFile.originFileObj,
      path: AfBlobPath.TrialModeOnlineExperienceAudios,
    });
  if (lyricsFile.originFileObj)
    blobs.push({
      file: lyricsFile.originFileObj,
      path: AfBlobPath.TrialModeOnlineExperienceLyrics,
    });

  if (blobs.length > 0)
    uploadFile({
      variables: {
        blobs,
      },
    }).catch((e: any) => console.log(e));

  if (drawerAction === "Create")
    Promise.all([
      sortIdUpdator({
        contentType: "song",
        data: songData,
        updateSortId,
        increment: 1,
      }),
      createSong({ variables: denormalizeUploadSongData(values) }),
    ])
      .then(() => {
        refetch()
          .then(() => {
            form.resetFields();
            setOpenDrawer(false);
          })
          .catch((e: any) => console.log(e));
      })
      .catch((e: any) => console.log(e));

  if (drawerAction === "Edit")
    updateSong({
      variables: {
        productSongId: parseInt(renderDecodedId(id)),
        ...denormalizeUploadSongData(values),
      },
    })
      .then(() => {
        refetch();
        form.resetFields();
        setOpenDrawer(false);
      })
      .catch((e: any) => console.log(e));
}
