import { useEffect, useState, useRef } from "react";
import { Select, Button, Table, Drawer, Form, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import requiredRule from "../../../utils/formRules/requiredRule";
import DrawerFooter from "../../../components/layout/drawer/DrawerFooter";
import { TagDataType } from "./types";
import { PlusOutlined } from "@ant-design/icons";
import { apiCall } from "../../../utils/apiCall";
import "./TagTab.scss";
import { platform } from "os";

interface TagTabProps {
  videos: any;
  tags: any;
  market: any;
  setMarket: any;
  currentUser: any;
  refetch: any;
  category: string;
}
const TagTab = ({
  tags,
  videos,
  market,
  setMarket,
  currentUser,
  refetch,
  category,
}: TagTabProps) => {
  const [total, setTotal] = useState(0);
  const [displayData, setDisplayData] = useState<TagDataType[]>([]);
  const [selectedTag, setSelectedTag] = useState<TagDataType | null>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleMarketChange = (value: string) => {
    setMarket(value);
  };
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const { title } = form.getFieldsValue();
    if (!isEditMode) {
      const payload = {
        title: title,
        market: market,
        category: category,
      };
      await apiCall({
        method: "POST",
        endPoint: process.env.REACT_APP_DEMO_APP_VIDEO + "/tags",
        payload: payload,
      })
        .then(() => {
          setDrawerOpen(false);
          setIsEditMode(false);
          setSelectedTag(null);
          refetch();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const payload = {
        title: title,
      };
      await apiCall({
        method: "PATCH",
        endPoint:
          process.env.REACT_APP_DEMO_APP_VIDEO + `/tags/${selectedTag?.tagId}`,
        payload: payload,
      })
        .then(() => {
          setDrawerOpen(false);
          setIsEditMode(false);
          setSelectedTag(null);
          refetch();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleDelete = async (tagId: number) => {
    await apiCall({
      method: "DELETE",
      endPoint: process.env.REACT_APP_DEMO_APP_VIDEO + `/tags/${tagId}`,
    })
      .then(() => {
        refetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns: ColumnsType<TagDataType> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 600,
    },
    {
      title: "Number of videos",
      dataIndex: "numberOfVideos",
      key: "numberOfVideos",
      width: 160,
      render: (number, _) => {
        return number || 0;
      },
    },
    {
      title: "Status",
      dataIndex: "isInUse",
      key: "isInUse",
      width: 115,
      render: (bool, item) => {
        return bool ? (
          <div className="in-use-container">
            <div className="in-use dot" />
            <p>In use</p>
          </div>
        ) : (
          <div className="in-use-container">
            <div className="not-in-use dot" />
            <p>Not in use</p>
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 115,
      render: (_, item) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setIsEditMode(true);
                setDrawerOpen(true);
                setSelectedTag(item);
              }}
              className="edit-button"
            >
              Edit
            </Button>
            {(item.numberOfVideos === 0 || !item.numberOfVideos) && (
              <Button
                type="link"
                onClick={() => {
                  handleDelete(item.tagId);
                }}
                className="delete-button"
              >
                Delete
              </Button>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (tags) {
      setDisplayData(tags);
    }
  }, [tags]);
  useEffect(() => {
    setTotal(displayData.length);
  }, [displayData]);
  useEffect(() => {
    if (selectedTag) {
      form.setFieldValue("title", selectedTag.title);
    } else {
      form.resetFields();
    }
  }, [selectedTag]);
  return (
    <>
      <div id="DemoAppTagTabWrapper">
        <div className="table-container">
          <div className="select-total">
            <div className="total-container">Total {total} items</div>
            <div>
              <span
                style={
                  currentUser?.afUserMarkets?.length === 1
                    ? { display: "none" }
                    : {}
                }
              >
                Market:
              </span>
              <Select
                onChange={handleMarketChange}
                value={market}
                options={[
                  {
                    value: "TW",
                    label: "TW",
                  },
                  {
                    value: "HK",
                    label: "HK",
                  },
                  {
                    value: "KR",
                    label: "KR",
                  },
                ]}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="create-button"
                onClick={() => {
                  setDrawerOpen(true);
                }}
              >
                Create New
              </Button>
            </div>
          </div>
          <div className="table-container no-pt">
            <Table
              dataSource={displayData}
              columns={columns}
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
              }}
            />
          </div>
        </div>
      </div>
      <Drawer
        open={drawerOpen}
        width={500}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedTag(null);
          setIsEditMode(false);
        }}
        title={isEditMode ? "Edit tag" : "Create new tag"}
        footer={
          <DrawerFooter
            handleSubmit={() => {
              form
                .validateFields()
                .then(() => {
                  handleSubmit();
                })
                .catch((errorInfo) => {
                  console.error("Validation Failed:", errorInfo);
                });
            }}
            handleCancel={() => {
              setDrawerOpen(false);
              setSelectedTag(null);
              setIsEditMode(false);
            }}
          />
        }
      >
        <div id="tagDrawerWrapper">
          <div>
            <p className="title">Market</p>
            <p className="content">{market}</p>
          </div>
          <Form form={form} layout="vertical">
            <Form.Item
              className="form-item"
              label="Tag"
              rules={[requiredRule]}
              name="title"
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default TagTab;
