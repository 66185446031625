import { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Select,
  Table,
  Upload,
  message,
  Modal,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import type { UploadProps } from "antd";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import {
  PlusOutlined,
  SwapRightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import "./ServiceBannerTabContent.css";
import { Colors } from "../../../../constants/Colors";
import RegionTag from "../../../../components/tags/RegionTag";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";
import {
  FormItemPublishDate,
  FormItemStopDate,
} from "../../../trialMode/localEvent/components/FormItems";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import { useGetScheduledServiceBannerListQuery } from "../../../../graphql/operations/clubApp/design/get-scheduled-service-banner-list";
import { useGetServiceListOfServiceBannerQuery } from "../../../../graphql/operations/clubApp/design/get-service-list-of-service-banner";
import { useUpdateScheduledServiceBannerMutation } from "../../../../graphql/operations/clubApp/design/update-scheduled-service-banner";
import { useAddScheduledServiceBannerMutation } from "../../../../graphql/operations/clubApp/design/add-scheduled-service-banner";
import { useDeleteScheduledServiceBannerMutation } from "../../../../graphql/operations/clubApp/design/delete-scheduled-service-banner";
import Spinner from "../../../../components/layout/spinner/Spinner";

interface DataType {
  __typename: string;
  seqs: number[] | any;
  service: string;
  status: string;
  image: string;
  imageUrl: string;
  markets: string[];
  publishDate: string;
  stopDate: string;
}

const ServiceBannerTabContent = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectBanner, setSelectBanner] = useState<DataType | undefined>();
  const [displayData, setDisplayData] = useState<DataType[] | any>([]);
  const [serviceList, setServiceList] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    ""
  );
  const [seqToBeDeleted, setSeqToBeDeleted] = useState<number[] | undefined>(
    []
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [uploadFile] = useUploadFileMutation();

  const { data, loading, error, refetch } =
    useGetScheduledServiceBannerListQuery();
  const {
    data: serviceData,
    loading: serviceLoading,
    error: serviceError,
    refetch: serviceRefetch,
  } = useGetServiceListOfServiceBannerQuery();
  const [updateScheduledServiceBanner] =
    useUpdateScheduledServiceBannerMutation();
  const [addScheduledServiceBanner] = useAddScheduledServiceBannerMutation();
  const [deleteScheduledServiceBanner] =
    useDeleteScheduledServiceBannerMutation();
  const props: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isImageOrMp4 =
        file.type.startsWith("image") ||
        file.type.split("/")[1] === "mp4" ||
        file.type.split("/")[1] === "MP4";
      if (!isImageOrMp4) {
        message.error(renderAntDMessageConfig("Wrong file format!"));
      }
      return isImageOrMp4 || Upload.LIST_IGNORE;
    },
    onChange(info) {
      setUploadedFileName(info.file.name);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
      uploadFile({
        variables: {
          blobs: [
            {
              file: info.file.originFileObj,
              path: AfBlobPath.AppServiceBanners,
            },
          ],
        },
      }).catch((e) => console.log(e));
    },
    maxCount: 1,
    listType: "picture",
    onRemove: () => setUploadedFileName(""),
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (value: string, record) => {
        const isImage =
          record.image.endsWith(".jpg") ||
          record.image.endsWith(".jpeg") ||
          record.image.endsWith(".png") ||
          record.image.endsWith(".gif");
        const isVideo = record.image.endsWith(".mp4");

        if (isImage) {
          return (
            <img
              alt="media"
              src={value}
              style={{ height: 70, width: "auto" }}
            />
          );
        } else if (isVideo) {
          return (
            <video src={value} controls style={{ height: 70, width: "auto" }} />
          );
        } else {
          return <p>Unsupported format</p>;
        }
      },
    },
    {
      title: "Market",
      dataIndex: "markets",
      key: "markets",
      render: (value: any) => (
        <div style={{ display: "flex" }}>
          {value.map((market: any) => (
            <div style={{ marginRight: 4 }}>
              <RegionTag region={market} />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Date range",
      render: (_, record: DataType) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p>{dayjs(record.publishDate).format("YYYY-MM-DD HH:mm")}</p>
          <SwapRightOutlined style={{ margin: "0 12px" }} />
          <p>{dayjs(record.stopDate).format("YYYY-MM-DD HH:mm")}</p>
        </div>
      ),
    },
    {
      title: "In use",
      render: (_, record: DataType) => {
        if (
          dayjs().valueOf() >= dayjs(record.publishDate).valueOf() &&
          dayjs().valueOf() <= dayjs(record.stopDate).valueOf()
        ) {
          return (
            <div className="in-use-container">
              <div
                className="dot"
                style={{
                  backgroundColor: "#394CF6",
                }}
              ></div>
              <p>In use</p>
            </div>
          );
        } else {
          return (
            <div className="in-use-container">
              <div
                className="dot"
                style={{
                  backgroundColor: "#D9D9D9",
                }}
              ></div>
              <p>Not in use</p>
            </div>
          );
        }
      },
    },
    {
      title: "Action",
      render: (_, record: DataType) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setSelectBanner(record);
              setShowDrawer(true);
            }}
            style={{ padding: 0, marginRight: 16 }}
          >
            Edit
          </Button>

          <Button
            type="link"
            onClick={() => {
              handleDelete(
                record.seqs,
                !(
                  dayjs().valueOf() < dayjs(record.publishDate).valueOf() ||
                  dayjs().valueOf() > dayjs(record.stopDate).valueOf()
                )
              );
            }}
            style={{ padding: 0 }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleSubmit = () => {
    const { market, service, publishDate, stopDate, image } =
      form.getFieldsValue();
    if (isSubmitting) return;
    setIsSubmitting(true);
    const updatePromise = selectBanner
      ? updateScheduledServiceBanner({
          variables: {
            seqs: selectBanner.seqs,
            service: service,
            image: uploadedFileName ? uploadedFileName : image[0].name,
            markets: market,
            publishDate: dayjs(publishDate).format("YYYY-MM-DDTHH:mm:ss"),
            stopDate: dayjs(stopDate).format("YYYY-MM-DDTHH:mm:ss"),
          },
        })
      : addScheduledServiceBanner({
          variables: {
            service: service,
            image: uploadedFileName ? uploadedFileName : image[0].name,
            markets: market,
            publishDate: dayjs(publishDate).format("YYYY-MM-DDTHH:mm:ss"),
            stopDate: dayjs(stopDate).format("YYYY-MM-DDTHH:mm:ss"),
          },
        });
    updatePromise
      .then(() => {
        setShowDrawer(false);
        setSelectBanner(undefined);
        refetch();
        form.resetFields();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleDelete = (seqs: number[], isOn: boolean) => {
    if (isSubmitting) return;
    setSeqToBeDeleted(seqs);
    if (isOn) {
      setModalOpen(true);
    } else {
      doDelete();
    }
  };
  const doDelete = () => {
    const deletePromise = deleteScheduledServiceBanner({
      variables: {
        seqs: seqToBeDeleted ? seqToBeDeleted : [],
      },
    });
    deletePromise
      .then(() => {
        setShowDrawer(false);
        setSelectBanner(undefined);
        refetch();
        form.resetFields();
      })
      .finally(() => {
        setIsSubmitting(false);
        setModalOpen(false);
      });
  };
  useEffect(() => {
    if (selectBanner) {
      form.setFieldsValue({
        service: selectBanner.service,
        market: selectBanner.markets,
        publishDate: dayjs(selectBanner.publishDate),
        stopDate: dayjs(selectBanner.stopDate),
        image: [
          {
            uid: "0",
            name: selectBanner.image,
            status: "done",
            url: selectBanner.imageUrl,
          },
        ],
      });
      setUploadedFileName(selectBanner.image);
    }
  }, [selectBanner, form]);

  useEffect(() => {
    if (data) {
      setDisplayData(data.scheduledServiceBannerList);
    }
  }, [data]);
  useEffect(() => {
    if (serviceData) {
      setServiceList(serviceData.serviceListOfDefaultBanner);
    }
  }, [serviceData]);

  if (loading || serviceLoading) {
    return <Spinner />;
  }

  if (serviceError || error) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  return (
    <>
      <div className="theme-tab-content-container">
        <div
          className="theme-tab-table-container"
          style={{ backgroundColor: Colors.white }}
        >
          <div className="theme-tab-header-container">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowDrawer(true)}
            >
              Create New
            </Button>
          </div>
          <Table
            rowKey={(record: DataType) => record.seqs[0].toString()}
            columns={columns}
            dataSource={displayData}
            pagination={{ hideOnSinglePage: true }}
            className="announcement-table"
          />
        </div>
      </div>
      <Modal
        centered
        open={modalOpen}
        closeIcon={null}
        footer={[
          <div className="onDelete-modal-button-group">
            <Button
              onClick={() => {
                setModalOpen(false);
                setSeqToBeDeleted([]);
              }}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              No
            </Button>
            <Button
              danger
              onClick={doDelete}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              Delete
            </Button>
          </div>,
        ]}
        width={417}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 76,
            marginBottom: 24,
            marginRight: 8,
            marginLeft: 8,
            marginTop: 12,
          }}
        >
          <img
            alt="warning circle icon"
            src={require("../../../../assets/icons/warning-circle.png")}
            style={{ width: 22, height: 22, marginRight: 16 }}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <p style={{ fontSize: 16, fontWeight: 500 }}>
              Are you sure you want to delete the item?
            </p>
          </div>
        </div>
      </Modal>
      <Drawer
        title={
          selectBanner
            ? "Edit service banner & icon"
            : "Create new service banner & icon"
        }
        open={showDrawer}
        onClose={() => {
          form.resetFields();
          setSelectBanner(undefined);
          setShowDrawer(false);
        }}
        width={500}
      >
        <Form
          name="theme-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Service"
            name="service"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              placeholder="- Select -"
              options={serviceList.map((v: any) => {
                return { label: v.serviceName, value: v.serviceName };
              })}
            />
          </Form.Item>
          <Form.Item
            label="Market"
            name="market"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              placeholder="- Select -"
              options={[
                { label: "HK", value: "HK" },
                { label: "KR", value: "KR" },
                { label: "TW", value: "TW" },
              ]}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            label="Image"
            name="image"
            rules={[{ required: true, message: "This field is required!" }]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload {...props} className="service-banner-image-upload">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <p style={{ margin: "-16px 0 24px", color: "rgba(0, 0, 0, 0.45)" }}>
            Formats: JPEG, JPG, PNG, GIF, MP4
          </p>
          <FormItemPublishDate required showTime />
          <FormItemStopDate required showTime />
          <DrawerFooter
            handleSubmit={() => {
              handleSubmit();
            }}
            handleCancel={() => {
              form.resetFields();
              setSelectBanner(undefined);
              setShowDrawer(false);
            }}
          />
        </Form>
      </Drawer>
    </>
  );
};

export default ServiceBannerTabContent;
