import ContentTitle from "../../../components/ui/ContentTitle";
import { useGetGraduateChildQuery } from "../../../graphql/operations/clubApp/graduation/get-graduate-child";
import { currentYear } from "./utils/yearPeriod";
import {
  Button,
  Table,
  message,
  Select,
  Space,
  Input,
  Modal,
  Dropdown,
} from "antd";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import KidsInfoDrawer from "./KidsInfoDrawer";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ServiceTypes } from "../../../types/types";
import { KidListDataType } from "./types";
import "./KidsInfo.scss";
import dayjs from "dayjs";
import type { ColumnsType } from "antd/es/table";
import fileDownload from "js-file-download";
import { useReactiveVar } from "@apollo/client";
import {
  __currentUser__,
  __jwtToken__,
  __projectKey__,
} from "../../../graphql/policies";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import Tag from "../../../components/ui/Tag";
import { DownOutlined } from "@ant-design/icons";
import { apiCall } from "../../../utils/apiCall";
interface KidsInfoProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}
const KidsInfo = ({ setSelectedService, setSelectSubItem }: KidsInfoProps) => {
  const [kidList, setKidList] = useState<KidListDataType[] | any>([]);
  const [currentMarket, setCurrentMarket] = useState<string | any>("");
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<KidListDataType[] | any>(
    null
  );
  const [temp, setTemp] = useState<KidListDataType[] | any>([]);
  const currentUser = useReactiveVar(__currentUser__);
  const jwtToken = useReactiveVar(__jwtToken__);
  const projectKey = useReactiveVar(__projectKey__);
  const { Search } = Input;
  const { data, refetch } = useGetGraduateChildQuery({
    variables: {
      graduationYear: currentYear,
    },
  });
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });
  const handleChange = (value: string) => {
    setCurrentMarket(value);
    setTemp([]);
  };
  const handleSearch = (text: string) => {
    if (!text && temp.length > 0) {
      setKidList(temp);
    }
    if (temp.length === 0) {
      setTemp(kidList);
      const replica = [...kidList];
      setKidList(replica.filter((item) => item.memberNo.includes(text)));
    } else {
      const replica = [...temp];
      setKidList(replica.filter((item) => item.memberNo.includes(text)));
    }
  };
  const handleExportClick = async (e: any) => {
    setIsLoading(true);
    if (e.key === "3") {
      window.open(process.env.REACT_APP_GRADUATE_PHOTO_LINK, "_blank");
      setIsLoading(false);
      return;
    }
    try {
      const header = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "X-Project-Id": "AF",
          "X-Authorization-Provider": "Anonymous",
          "X-Project-Key": projectKey,
        },
        responseType: "blob",
      };
      const data = await apiCall({
        method: "GET",
        header: header,
        endPoint:
          e.key === "1"
            ? process.env.REACT_APP_UNSUBMITTED_GRADUATE?.replace(
                "fileName",
                "unsubmitted"
              )
                .replace("market", currentMarket)
                .replace("graduationYear", currentYear.toString())
            : process.env.REACT_APP_SUBMITTED_GRADUATE?.replace(
                "fileName",
                "submitted"
              )
                .replace("market", currentMarket)
                .replace("graduationYear", currentYear.toString()),
      });
      fileDownload(data, `${e.key === "1" ? "unsubmitted" : "submitted"}.xlsx`);
      setIsLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    if (data) {
      setKidList(
        data?.afGraduationChild.filter((v: any) => v.market === currentMarket)
      );
      setTemp(
        data?.afGraduationChild.filter((v: any) => v.market === currentMarket)
      );
    }
    console.log(data?.afGraduationChild);
  }, [data, currentMarket]);
  useEffect(() => {
    setTotal(kidList.length);
  }, [kidList]);
  useEffect(() => {
    if (currentUser?.afUserMarkets && currentUser?.afUserMarkets[0]?.market) {
      if (currentUser?.afUserMarkets.length === 1) {
        setCurrentMarket(currentUser?.afUserMarkets[0]?.market);
      } else {
        setCurrentMarket("TW");
      }
    }
  }, [currentUser]);

  const yesNoFilter = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];
  const columns: ColumnsType<KidListDataType> = [
    {
      title: "Attendance type",
      dataIndex: "type",
      key: "type",
      width: 210,
      render: (_, record) => {
        const isGraduate = record.isGraduate;
        const isCambridge = record.isCambridge;
        return (
          <div className="tag-group">
            {isGraduate && <Tag color="orange">Graduate</Tag>}
            {isCambridge && <Tag color="blue">Cambridge</Tag>}
          </div>
        );
      },
      filters: [
        { text: "Graduate", value: 1 },
        { text: "Cambridge", value: 2 },
      ],
      onFilter: (value, record) => {
        return value === 1
          ? record.isGraduate
          : value === 2
          ? record.isCambridge
          : true;
      },
    },
    {
      title: "Member ID",
      dataIndex: "memberNo",
      key: "memberNo",
      width: 110,
    },
    {
      title: "Name",
      dataIndex: "childName",
      key: "childName",
      width: 100,
    },
    {
      title: "English name",
      dataIndex: "childEnglishName",
      key: "childEnglishName",
      width: 140,
    },
    {
      title: "Last updated",
      dataIndex: "updatedTime",
      key: "updatedTime",
      width: 125,
      render: (date, record) => {
        return dayjs(date).format("YYYY/MM/DD");
      },
    },
    {
      title: "Child speech",
      dataIndex: "childWillingEnglishSpeech",
      key: "childWillingEnglishSpeech",
      width: 145,
      render: (bool, record) => {
        return (
          <p className={`${bool ? "" : "no-text"}`}>{bool ? "Yes" : "No"}</p>
        );
      },
      filters: yesNoFilter,
      onFilter: (value, record) => value === record.childWillingEnglishSpeech,
    },
    {
      title: "Parent speech",
      dataIndex: "parentWillingChineseSpeech",
      key: "parentWillingChineseSpeech",
      width: 145,
      render: (bool, record) => {
        return (
          <p className={`${bool ? "" : "no-text"}`}>{bool ? "Yes" : "No"}</p>
        );
      },
      filters: yesNoFilter,
      onFilter: (value, record) => value === record.parentWillingChineseSpeech,
    },
    {
      title: "Excellent",
      dataIndex: "isExcellent",
      key: "isExcellent",
      width: 120,
      render: (bool, record) => {
        return (
          <p className={`${bool ? "" : "no-text"}`}>
            {!record.isGraduate ? "N/A" : bool ? "Yes" : "No"}
          </p>
        );
      },
      filters: yesNoFilter,
      onFilter: (value, record) => value === record.isExcellent,
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (_, record) => (
        <div className="action-box">
          <Button
            type="link"
            onClick={() => {
              setShowDrawer(true);
              setSelectedRecord(record);
            }}
            className="p0"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  const items = [
    {
      label: "Unsubmitted kids info",
      key: "1",
    },
    {
      label: "Submitted kids info",
      key: "2",
    },
    {
      label: "Open the kids' photos",
      key: "3",
    },
  ];
  const menuProps = {
    items,
    onClick: handleExportClick,
  };
  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }
  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Kids Info"
    )
  ) {
    return <NoPermission />;
  }
  return (
    <div id="kidsInfoWrapper">
      <ContentTitle title="Kids Info" />
      <div className="table-container">
        <div className="select">
          <div className="total-container">Total {total} items</div>
          <div className="topright-container">
            <div
              style={
                currentUser?.afUserMarkets?.length === 1
                  ? { display: "none" }
                  : { display: "flex", alignItems: "center" }
              }
            >
              <p>Market:</p>
              <Select
                onChange={handleChange}
                defaultValue="TW"
                options={[
                  {
                    value: "TW",
                    label: "TW",
                  },
                  {
                    value: "HK",
                    label: "HK",
                  },
                  {
                    value: "KR",
                    label: "KR",
                  },
                ]}
              />
            </div>
            <Search
              placeholder="Search member ID"
              width={265}
              allowClear
              onSearch={handleSearch}
            />
            <Dropdown menu={menuProps}>
              <Button loading={isLoading}>
                <Space>
                  Export
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
        <Table
          className="table"
          columns={columns}
          dataSource={kidList}
          onChange={(pagination, filters, sorter, extra) => {
            setTotal(extra.currentDataSource.length);
          }}
          scroll={{ x: true, y: 500 }}
          pagination={{ pageSize: 10 }}
        />
      </div>
      <KidsInfoDrawer
        user={currentUser?.userName}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        setSelectedRecord={setSelectedRecord}
        record={selectedRecord}
        refetch={refetch}
      />
    </div>
  );
};
export default KidsInfo;
