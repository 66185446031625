import { useEffect, useState } from "react";
import { Button, message, Form, Radio, Input, Checkbox } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import AudioPlayer from "react-h5-audio-player";
import type { RadioChangeEvent } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { useGetUserAllGraduationApplyQuery } from "../../../graphql/operations/clubApp/graduation/get-user-all-graduation-apply";
import { useGetUserAllGraduationApplyTwQuery } from "../../../graphql/operations/clubApp/graduation/get-user-all-graduation-apply-tw";
import { useGetAllGraduationTaskNameAndDescriptionQuery } from "../../../graphql/operations/clubApp/graduation/get-graduation-all-task";
import { useGetEvaluationOptionsQuery } from "../../../graphql/operations/clubApp/graduation/get-evaluation-options";
import { useGetGraduationApplyByIdQuery } from "../../../graphql/operations/clubApp/graduation/get-graduation-apply-by-id";
import { Colors } from "../../../constants/Colors";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { __currentUser__ } from "../../../graphql/policies";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { useAddEvaluationMutation } from "../../../graphql/operations/clubApp/graduation/add-evaluation";
import { useDeleteEvaluationMutation } from "../../../graphql/operations/clubApp/graduation/delete-evaluation";
import { useUpdateGraduationApplyMutation } from "../../../graphql/operations/clubApp/graduation/update-graduation-apply";
import { useUpdateGraduationProcessMutation } from "../../../graphql/operations/clubApp/graduation/update-graduation-process";
import { useUpdateHkGraduationStatusMutation } from "../../../graphql/operations/clubApp/graduation/update-HK-graduation-status";
import { useUpdateTwGraduationStatusMutation } from "../../../graphql/operations/clubApp/graduation/update-TW-graduation-status";
import { useUpdateKrGraduationStatusMutation } from "../../../graphql/operations/clubApp/graduation/update-KR-graduation-status";
import { useGetGraduationProcessQuery } from "../../../graphql/operations/clubApp/graduation/get-graduation-process";
import { useGetGraduationProcessTwQuery } from "../../../graphql/operations/clubApp/graduation/get-graduation-process-tw";
import { useGetNotificationTemplateQuery } from "../../../graphql/operations/clubApp/graduation/get-notification-template";
import "./ReviewGraduationCap.scss";
import GraduationDrawer from "./GraduationDrawer";
import {
  DataType,
  TaskDataType,
  ProcessDataType,
  EvaluationItem,
} from "./types";
import { sortUserApplications } from "./utils/sortUserApplications";
import { countStatus } from "./utils/countStatus";
import {
  currentYearStart,
  currentYearEnd,
  currentYear,
} from "./utils/yearPeriod";
import { apiCall } from "../../../utils/apiCall";
import { getNotificationTemplate } from "./utils/getNotificationTemplate";
import { getNotificationMessage } from "./utils/getNotificationMessage";

type EvaluationList = EvaluationItem[];

const ReviewGraduationCap = () => {
  const dayjs = require("dayjs");
  var utc = require("dayjs/plugin/utc");
  var timezone = require("dayjs-timezone-iana-plugin");
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { id } = useParams();
  const { TextArea } = Input;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [userAllApplications, setUserAllApplications] = useState<
    DataType[] | any
  >([]);
  const [filteredAllApplications, setFilteredAllApplications] = useState<
    DataType[] | any
  >([]);
  const [selectedRecord, setSelectedRecord] = useState<DataType[] | any>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  //first number is already reviewed and passed, second number is waiting for reviewed
  const [taskProgress, setTaskProgress] = useState([0, 0]);
  const [taskDataList, setTaskDataList] = useState<TaskDataType[] | any>([]);
  const [taskProgressArray, setTaskProgressArray] = useState<number[]>([]);
  const [isExcellent, setIsExcellent] = useState(false);
  const [personalProgress, setPersonalProgress] = useState<
    ProcessDataType[] | any
  >([]);
  const [mostRecentProgress, setMostRecentProgress] = useState<
    ProcessDataType | any
  >();
  const [totalApproveCount, setTotalApproveCount] = useState<number>(0);
  const [evaluationResult, setEvaluationResult] = useState([2, [], ""]);
  const [evaluationOptions, setEvaluationOptions] = useState<
    EvaluationList | any
  >([]);
  const [isEvaluated, setIsEvaluated] = useState(false);
  const [isModifyingMode, setIsModifyingMode] = useState(false);
  const [isLatestTrial, setIsLatestTrial] = useState(false);
  const subtaskTextArray = selectedRecord[0]?.graduationSubApplications.map(
    (subtask: any) =>
      subtask.subTask.graduationSubTaskMarketDescriptions.find(
        (v: any) => v.market === selectedRecord[0]?.market
      )?.subTaskName
  );
  let subtaskText = subtaskTextArray?.map((text: any, index: number) => (
    <p key={index} className="right-panel start text-blue">
      {text}
    </p>
  ));
  const numberIconList = ["➊", "➋", "➌", "➍", "➎", "➏", "➐", "➑"];
  const task = taskDataList.find(
    (task: TaskDataType) =>
      task.taskId === selectedRecord[0]?.taskId &&
      task.market === selectedRecord[0]?.market
  );
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });
  const onChange = (e: CheckboxChangeEvent) => {
    setIsExcellent(!isExcellent);
  };
  const onIsPassedTicked = (e: RadioChangeEvent) => {
    setEvaluationResult([e.target.value, [], evaluationResult[2]]);
    form.setFieldsValue({ reasons: undefined });
  };
  const onReasonsChecked = (checkedValue: any) => {
    setEvaluationResult([
      evaluationResult[0],
      checkedValue,
      evaluationResult[2],
    ]);
  };
  const { data: notificationData } = useGetNotificationTemplateQuery();
  const handleDrawerSubmit = () => {
    const updateGraduationProcessPromise = updateGraduationProcess({
      variables: {
        childApplicationId: parseInt(renderDecodedId(mostRecentProgress.id)),
        market: mostRecentProgress.market,
        memberNo: mostRecentProgress.memberNo,
        childNo: mostRecentProgress.childNo,
        applicationYear: mostRecentProgress.applicationYear,
        approvedCount: mostRecentProgress.approvedCount,
        rejectedCount: mostRecentProgress.rejectedCount,
        lastApprovalDate: mostRecentProgress.lastApprovalDate,
        isExcellent: isExcellent,
        graduationDate: mostRecentProgress.graduationDate,
      },
    });
    updateGraduationProcessPromise
      .then(() => {
        refetch();
        processRefetch();
        selectedRefetch();
        setShowDrawer(false);
      })
      .catch((e) => console.log(e));
  };
  const onFinish = () => {
    if (isModifyingMode) {
      if (!selectedRecord[0].isPassed) {
        const evaluationIdArray =
          selectedRecord[0].graduationApplicationEvaluations.map((i: any) => ({
            applicationEvaluationId: parseInt(renderDecodedId(i.id)),
          }));
        deleteEvaluation({
          variables: {
            evaluationData: evaluationIdArray,
          },
        });
      }
    }
    if (Array.isArray(evaluationResult[1])) {
      const isPassed = evaluationResult[0] === 0;
      const notificationList =
        notificationData?.club_worldFamilyClub_memberMessageSetting?.nodes?.filter(
          (item) => item.market === selectedRecord[0]?.market
        );
      const month = dayjs(selectedRecord[0]?.applicationDate).month() + 1;
      const isLastMonth =
        (selectedRecord[0]?.market === "TW" && month === 9) ||
        (selectedRecord[0]?.market === "HK" &&
          selectedRecord[0]?.yearCount &&
          selectedRecord[0]?.yearCount.split("-")[1] === "3") ||
        (selectedRecord[0]?.market === "KR" && month === 11);
      const isLastApplication = totalApproveCount === 7;
      const hasComment = evaluationResult[2] !== "";
      const template = getNotificationTemplate(
        notificationList,
        isPassed,
        hasComment,
        isLastMonth,
        isLastApplication
      );
      const reasons = evaluationResult[1]
        .map((v) => {
          const raw = evaluationOptions.find(
            (option: EvaluationItem) => renderDecodedId(option.id) === v
          ).evaluation;
          if (!raw.includes("$")) {
            return raw;
          } else {
            return raw.split("$")[0];
          }
        })
        .filter((item) => item !== "其他" && item !== "기타");
      const reasonString = reasons.join("\r\n");
      if (template) {
        template.forEach((item: any) => {
          const taskInfo =
            selectedRecord[0]?.taskId >= 7
              ? task?.taskName + ": " + subtaskTextArray.join(" ,")
              : task?.taskName;
          const notificationMessage = getNotificationMessage(
            item,
            taskInfo,
            selectedRecord[0],
            reasonString,
            evaluationResult[2] as string
          );
          const header = {
            headers: {
              accept: "text/plain",
              "Content-Type": "application/json",
            },
          };
          const payload = {
            market: selectedRecord[0]?.market,
            member: selectedRecord[0]?.memberNo,
            message: notificationMessage,
            is_public: false,
            message_type:
              selectedRecord[0]?.market !== "HK" ? "Gra-01" : "news",
            additional_options: {
              title: "",
              notification_id: template[0].notificationId,
              scheduled_time: dayjs()
                .tz("Asia/Taipei")
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss[Z]"),
            },
          };
          apiCall({
            method: "POST",
            header: header,
            endPoint: process.env.REACT_APP_NOTIFICATIONAPI,
            payload: payload,
          });
        });
      }
      const evaluationVariablesArray: any = [];
      evaluationResult[1].forEach((option) => {
        evaluationVariablesArray.push({
          applicationId: parseInt(
            renderDecodedId(selectedRecord[0].id) as string
          ),
          evaluationId: parseInt(option as string),
        });
      });
      const addEvaluationPromises = addEvaluation({
        variables: {
          evaluationData: evaluationVariablesArray,
        },
      });
      const updateGraduationApplicationPromise = updateGraduationApply({
        variables: {
          applicationId: parseInt(
            renderDecodedId(selectedRecord[0].id) as string
          ),
          applicationDate: dayjs(selectedRecord[0]?.applicationDate).format(
            "YYYY-MM-DD"
          ),
          approvalDate: dayjs().format("YYYY-MM-DD"),
          isPassed: isPassed,
          overallComment: evaluationResult[2] as string,
          taskId: selectedRecord[0].taskId,
          updatedTime: dayjs().format("YYYY-MM-DD"),
          updater: currentUser?.userName,
          isExcellent: isExcellent,
        },
      });
      const updateGraduationProcessPromise = updateGraduationProcess({
        variables: {
          childApplicationId: parseInt(renderDecodedId(mostRecentProgress.id)),
          market: mostRecentProgress.market,
          memberNo: mostRecentProgress.memberNo,
          childNo: mostRecentProgress.childNo,
          applicationYear: mostRecentProgress.applicationYear,
          approvedCount: !isModifyingMode
            ? isPassed
              ? mostRecentProgress.approvedCount + 1
              : mostRecentProgress.approvedCount
            : isPassed
            ? selectedRecord[0].isPassed
              ? mostRecentProgress.approvedCount
              : mostRecentProgress.approvedCount + 1
            : selectedRecord[0].isPassed
            ? mostRecentProgress.approvedCount - 1
            : mostRecentProgress.approvedCount,
          rejectedCount: !isModifyingMode
            ? isPassed
              ? mostRecentProgress.rejectedCount
              : mostRecentProgress.rejectedCount + 1
            : isPassed
            ? selectedRecord[0].isPassed
              ? mostRecentProgress.rejectedCount
              : mostRecentProgress.rejectedCount - 1
            : selectedRecord[0].isPassed
            ? mostRecentProgress.rejectedCount + 1
            : mostRecentProgress.rejectedCount,
          lastApprovalDate: dayjs().format("YYYY-MM-DD"),
          isExcellent: isExcellent,
          graduationDate:
            totalApproveCount === 7 && isPassed
              ? dayjs().format("YYYY-MM-DD")
              : null,
        },
      });
      let updateGraduationStatusPromise;
      if (totalApproveCount === 7 && isPassed) {
        switch (selectedRecord[0]?.market) {
          case "TW":
            updateGraduationStatusPromise = updateTWGraduationStatus({
              variables: {
                memberNumber: selectedRecord[0]?.memberNo,
                childNumber: selectedRecord[0]?.childNo,
                registrationDate: selectedRecord[0]?.applicationDate,
                registrationAge: selectedRecord[0]?.age,
                creator: currentUser?.userName,
              },
            });
            break;
          case "KR":
            updateGraduationStatusPromise = updateKRGraduationStatus({
              variables: {
                memberNumber: selectedRecord[0]?.memberNo,
                childNumber: parseInt(selectedRecord[0]?.childNo),
                yearGraduated: dayjs().format("YYYY-MM-DD"),
              },
            });
            break;
          case "HK":
            updateGraduationStatusPromise = updateHKGraduationStatus({
              variables: {
                memberNumber: selectedRecord[0]?.memberNo,
                childNumber: parseInt(selectedRecord[0]?.childNo),
                graduationDate: dayjs().format("YYYY-MM-DD"),
              },
            });
            break;
          default:
            updateGraduationStatusPromise = null;
            break;
        }
      }
      Promise.all([
        addEvaluationPromises,
        updateGraduationApplicationPromise,
        updateGraduationProcessPromise,
        updateGraduationStatusPromise,
      ])
        .then(() => {
          refetch();
          processRefetch();
          selectedRefetch();
          navigate("/club-app/waiting-for-review");
        })
        .catch((e) => console.log(e));
    }
  };
  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEvaluationResult([
      evaluationResult[0],
      evaluationResult[1],
      e.target.value,
    ]);
  };
  let videoUrl;
  if (selectedRecord[0]?.videoUrl.includes("youtube")) {
    if (selectedRecord[0]?.videoUrl.includes("shorts")) {
      videoUrl = selectedRecord[0]?.videoUrl.replace("shorts", "embed");
    } else {
      videoUrl = selectedRecord[0]?.videoUrl
        .replace("watch?v=", "embed/")
        .replace(/&/, "?");
    }
  } else if (selectedRecord[0]?.videoUrl.includes("youtu.be")) {
    let temporaryUrl = selectedRecord[0]?.videoUrl
      .replace("youtu.be", "youtube.com")
      .replace(/\?.*$/, "");
    let index = temporaryUrl.indexOf("youtube.com");
    videoUrl =
      temporaryUrl.slice(0, index + 11) +
      "/embed" +
      temporaryUrl.slice(index + 11);
  } else if (selectedRecord[0]?.videoUrl.includes("vimeo")) {
    videoUrl = selectedRecord[0]?.videoUrl.replace(
      "vimeo.com",
      "player.vimeo.com/video"
    );
  }
  if (!selectedRecord[0]?.videoUrl.startsWith("https://")) {
    videoUrl = "https://" + videoUrl;
  }
  const [addEvaluation] = useAddEvaluationMutation();
  const [deleteEvaluation] = useDeleteEvaluationMutation();
  const [updateGraduationApply] = useUpdateGraduationApplyMutation();
  const [updateGraduationProcess] = useUpdateGraduationProcessMutation();
  const [updateTWGraduationStatus] = useUpdateTwGraduationStatusMutation();
  const [updateKRGraduationStatus] = useUpdateKrGraduationStatusMutation();
  const [updateHKGraduationStatus] = useUpdateHkGraduationStatusMutation();
  const {
    data: selectedData,
    loading: selectedLoading,
    refetch: selectedRefetch,
  } = useGetGraduationApplyByIdQuery({
    variables: {
      id: parseInt(id as string),
    },
  });
  const { data, refetch } = useGetUserAllGraduationApplyQuery({
    variables: {
      memberNo: selectedRecord[0]?.memberNo,
      childNo: selectedRecord[0]?.childNo,
    },
  });
  const { data: twData, refetch: twRefetch } =
    useGetUserAllGraduationApplyTwQuery({
      variables: {
        memberNo: selectedRecord[0]?.memberNo,
        childNo: selectedRecord[0]?.childNo,
        currentYearStart: currentYearStart,
        currentYearEnd: currentYearEnd,
      },
    });
  const {
    data: taskData,
    loading: taskLoading,
    refetch: taskRefetch,
  } = useGetAllGraduationTaskNameAndDescriptionQuery();
  const { data: processData, refetch: processRefetch } =
    useGetGraduationProcessQuery({
      variables: {
        childNo: selectedRecord[0]?.childNo,
        memberNo: selectedRecord[0]?.memberNo,
      },
    });
  const { data: twProcessData, refetch: twProcessRefetch } =
    useGetGraduationProcessTwQuery({
      variables: {
        childNo: selectedRecord[0]?.childNo,
        memberNo: selectedRecord[0]?.memberNo,
        applicationYear: currentYear,
      },
    });
  const { data: evaluationData, refetch: evaluationRefetch } =
    useGetEvaluationOptionsQuery({
      variables: {
        market: selectedRecord[0]?.market,
        taskId: selectedRecord[0]?.taskId,
      },
    });
  useEffect(() => {
    setIsEvaluated(false);
    taskRefetch();
    evaluationRefetch();
    refetch();
    processRefetch();
    selectedRefetch();
    twRefetch();
    twProcessRefetch();
  }, [selectedRecord]);
  useEffect(() => {
    if (processData && selectedRecord[0]?.market !== "TW") {
      setPersonalProgress(
        processData.club_worldFamilyClub_childGraduationApplication?.nodes
      );
    }
    if (twProcessData && selectedRecord[0]?.market === "TW") {
      setPersonalProgress(
        twProcessData.club_worldFamilyClub_childGraduationApplication?.nodes
      );
    }
  }, [processData, twProcessData, selectedRecord]);
  useEffect(() => {
    const replica = [...personalProgress];
    const sorted = replica.sort(
      (a: ProcessDataType, b: ProcessDataType) =>
        b.applicationYear - a.applicationYear
    );

    const totalPassCount = sorted.reduce(
      (acc: number, obj: ProcessDataType) => acc + obj.approvedCount,
      0
    );
    setMostRecentProgress(sorted[0]);
    setTotalApproveCount(totalPassCount);
  }, [personalProgress]);
  useEffect(() => {
    setIsExcellent(mostRecentProgress?.isExcellent);
  }, [mostRecentProgress]);
  useEffect(() => {
    if (selectedData) {
      setSelectedRecord(
        selectedData.club_worldFamilyClub_graduationApplication?.nodes
      );
    }
  }, [selectedData]);
  useEffect(() => {
    setIsEvaluated(false);
    if (selectedRecord[0]?.approvalDate) {
      setIsEvaluated(true);
    }
    setEvaluationResult([2, [], ""]);
  }, [selectedRecord]);
  useEffect(() => {
    if (selectedRecord[0] && filteredAllApplications) {
      const thisTaskRecord = filteredAllApplications.filter((item: any) => {
        return item.taskId === selectedRecord[0].taskId;
      });
      const sorted = thisTaskRecord.sort(
        (a: any, b: any) => a.applicationDate - b.applicationDate
      );
      setIsLatestTrial(sorted[0]?.id === selectedRecord[0]?.id);
    }
  }, [selectedRecord, filteredAllApplications]);

  useEffect(() => {
    const initialValues = {
      status: isEvaluated ? (selectedRecord[0]?.isPassed ? 0 : 1) : undefined,
      reasons: isEvaluated
        ? selectedRecord[0]?.graduationApplicationEvaluations.map(
            (v: any) => v.evaluationId + ""
          )
        : undefined,
      comment: isEvaluated ? selectedRecord[0]?.overallComment : "",
    };
    setTimeout(() => {
      form.setFieldsValue(initialValues);
    }, 500);
  }, [isEvaluated, selectedRecord]);
  useEffect(() => {
    if (evaluationData) {
      setEvaluationOptions(
        evaluationData.club_worldFamilyClub_graduationEvaluation?.nodes
      );
    }
  }, [evaluationData]);
  useEffect(() => {
    if (data && selectedRecord[0]?.market !== "TW") {
      setUserAllApplications(
        data.club_worldFamilyClub_graduationApplication?.nodes
      );
    }
    if (twData && selectedRecord[0]?.market === "TW") {
      setUserAllApplications(
        twData.club_worldFamilyClub_graduationApplication?.nodes
      );
    }
  }, [data, twData, selectedRecord]);
  useEffect(() => {
    if (taskData) {
      setTaskDataList(
        taskData.club_worldFamilyClub_graduationTaskMarketDescription?.nodes
      );
    }
  }, [taskData]);
  useEffect(() => {
    const { passCount, underReviewCount } = countStatus(
      [...userAllApplications],
      0,
      0
    );
    setTaskProgress([passCount, underReviewCount]);
    const sorted = sortUserApplications([...userAllApplications]);
    setFilteredAllApplications(sorted);
  }, [userAllApplications]);
  useEffect(() => {
    const replica = [];
    for (let i = 1; i < 9; i++) {
      if (i <= taskProgress[0]) {
        replica.push(0);
      } else if (i <= taskProgress[0] + taskProgress[1]) {
        replica.push(1);
      } else {
        replica.push(2);
      }
    }
    setTaskProgressArray(replica);
  }, [taskProgress]);
  if (selectedLoading || taskLoading) {
    return <Spinner />;
  }
  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }
  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Graduation Reviewed"
    )
  ) {
    return <NoPermission />;
  }
  const isViewerOnly =
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Waiting for Review"
    );
  return (
    <div id="reviewGraduationCapWrapper" style={{ position: "relative" }}>
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <ArrowLeftOutlined
          className="me-5"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Review Graduation CAP
        </p>
      </div>
      <div className="container">
        <div className="wrapper">
          <div className="column">
            <div className="left-panel" />
            <div className="infoBoxes right-panel">
              <div className="first-sub-box">
                <p className="title">Market</p>
                <p className="content">{selectedRecord[0]?.market}</p>
              </div>
              <div className="sub-box">
                <p className="title">Member ID </p>
                <p className="content">{selectedRecord[0]?.memberNo}</p>
              </div>
              <div className="sub-box">
                <p className="title">Kid(age)</p>
                <p className="content">
                  {selectedRecord[0]?.childName}(
                  {selectedRecord[0]?.age?.substring(
                    0,
                    selectedRecord[0]?.age.indexOf("Y")
                  )}
                  )
                </p>
              </div>
              <div className="sub-box">
                <p className="title">English name</p>
                <p className="content">{selectedRecord[0]?.childEnglishName}</p>
              </div>
            </div>
          </div>
          <div className="column">
            <p className="left-panel">Progress :</p>
            <div className="right-panel between">
              <div className="progress-bar-container">
                <div className="progress-bar">
                  {taskProgressArray.map((item) => (
                    <div
                      className="unit"
                      style={
                        item === 0
                          ? { backgroundColor: "#071491" }
                          : item === 1
                          ? { backgroundColor: "#C7CDFC" }
                          : { backgroundColor: "#F5F5F5" }
                      }
                    ></div>
                  ))}
                </div>
                <p className="text-vertical-center">{taskProgress[0]}/8</p>
                {taskProgress[0] >= 7 ? (
                  <div className="text-vertical-center">
                    <Checkbox
                      onChange={onChange}
                      checked={isExcellent}
                      disabled={isEvaluated}
                    />
                    <p className="text-vertical-center ms-2">
                      Mark as Excellent
                    </p>
                  </div>
                ) : null}
              </div>
              <Button
                type="link"
                onClick={() => {
                  setShowDrawer(true);
                }}
              >
                View all submissions
              </Button>
            </div>
          </div>
          <div className="column">
            <p className="left-panel mt-5">Main task :</p>
            <p className="right-panel start text-blue">
              {numberIconList[selectedRecord[0]?.taskId - 1]}
              {" " + task?.taskName}
            </p>
          </div>
          {selectedRecord[0]?.taskId >= 6 && (
            <div className="column">
              <p className="left-panel mt-5">Sub task :</p>
              <div>{subtaskText}</div>
            </div>
          )}
          <div className="column">
            <p className="left-panel mt-3">Applied date :</p>
            <p className="right-panel start text-blue">
              {dayjs(selectedRecord[0]?.applicationDate).format("YYYY-MM-DD")}
            </p>
          </div>
          {selectedRecord[0]?.approvalDate && (
            <div className="column">
              <p className="left-panel mt-3">Review date :</p>
              <p className="right-panel start text-blue">
                {dayjs(selectedRecord[0]?.approvalDate).format("YYYY-MM-DD")}
              </p>
            </div>
          )}
          <div className="column">
            <p className="left-panel mt-3 align-self-start">
              Submitted assignments :
            </p>
            {selectedRecord[0]?.videoUrl && (
              <div>
                <iframe
                  src={videoUrl}
                  title="test"
                  width="300"
                  height="168"
                  allowFullScreen
                ></iframe>
                <a
                  href={selectedRecord[0]?.videoUrl}
                  className="text-blue mt-3 link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Video link
                </a>
              </div>
            )}
            {selectedRecord[0]?.audioUrl &&
              selectedRecord[0]?.audioUrl !== "" && (
                <AudioPlayer
                  src={selectedRecord[0]?.audioUrl}
                  autoPlay={false}
                />
              )}
          </div>
          <div className="column">
            <div className="left-panel" />
            <div className="right-panel form">
              <Form
                form={form}
                onFinish={onFinish}
                name="evaluation"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
              >
                <Form.Item
                  label="Approve status: "
                  name="status"
                  className="mt-5"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={onIsPassedTicked}
                    className="ms-2"
                    disabled={isEvaluated || isViewerOnly}
                  >
                    <Radio.Button value={0}>Pass</Radio.Button>
                    <Radio.Button value={1}>Try again</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {((!isEvaluated && evaluationResult[0] === 1) ||
                  (isEvaluated && !selectedRecord[0]?.isPassed)) && (
                  <Form.Item
                    label="Reasons:"
                    name="reasons"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Checkbox.Group
                      style={{ display: "block" }}
                      className="ms-2"
                      onChange={onReasonsChecked}
                      disabled={isEvaluated}
                    >
                      {evaluationOptions.map((option: any, index: number) => (
                        <div key={index} className="mt-1">
                          <Checkbox value={renderDecodedId(option.id)}>
                            {option.evaluation.replace("$", " ")}
                          </Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                )}
                <Form.Item
                  label="Comment"
                  name="comment"
                  rules={[
                    {
                      required:
                        (!isEvaluated && evaluationResult[0] === 1) ||
                        (isEvaluated && !selectedRecord[0]?.isPassed),
                    },
                  ]}
                >
                  <TextArea
                    className="ms-2"
                    showCount
                    maxLength={300}
                    onChange={onTextChange}
                    disabled={isEvaluated || isViewerOnly}
                  ></TextArea>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {!isViewerOnly && !isEvaluated && (
        <div className="footer" style={{ backgroundColor: Colors.white }}>
          <Button
            size="large"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
            onClick={() => {
              if (isModifyingMode) {
                setIsEvaluated(true);
                setIsModifyingMode(false);
              } else {
                navigate(-1);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            style={{
              marginLeft: 8,
              textAlign: "center",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            htmlType="submit"
            onClick={() => {
              form
                .validateFields()
                .then(() => {
                  form.submit();
                })
                .catch((errorInfo) => {
                  console.log("Validation failed:", errorInfo);
                });
            }}
          >
            Submit
          </Button>
        </div>
      )}
      {!isViewerOnly && isEvaluated && isLatestTrial && (
        <div className="footer" style={{ backgroundColor: Colors.white }}>
          <Button
            size="large"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
            onClick={() => {
              setIsEvaluated(false);
              setIsModifyingMode(true);
            }}
          >
            Change the results
          </Button>
        </div>
      )}
      <GraduationDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        selectedGraduationData={selectedRecord[0]}
        taskProgress={taskProgress}
        taskProgressArray={taskProgressArray}
        taskDataList={taskDataList}
        isExcellent={isExcellent}
        setIsExcellent={setIsExcellent}
        isExcellentEnabled={true}
        filteredAllApplications={filteredAllApplications}
        shouldPass={evaluationResult[0] === 0}
        isViewerOnly={isViewerOnly}
        handleDrawerSubmit={handleDrawerSubmit}
      />
    </div>
  );
};

export default ReviewGraduationCap;
