import { useEffect, useState } from "react";
import { Form, Table } from "antd";

import Spinner from "../../../../components/layout/spinner/Spinner";
import DrawerCustom from "../../../../components/custom/DrawerCustom";
import getInitialMarket from "../../../../utils/getInitialMarket";

import { useGetRegularEventsQuery } from "../../../../graphql/operations/trialMode/localEvent/get-regular-events";
import { useCreateRegularEventMutation } from "../../../../graphql/operations/trialMode/localEvent/create-regular-event";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { useUpdateRegularEventMutation } from "../../../../graphql/operations/trialMode/localEvent/update-regular-event";
import { useUpdateRegularEventsSortIdMutation } from "../../../../graphql/operations/trialMode/localEvent/update-regular-events-sort-id";

import { FormItemMarket, FormItemTitle } from "../../components/FormItems";
import errorMessage from "../../utils/errorMessage";
import { DrawerActionType, MarketType } from "../../types";
import TableMenu from "../components/TableMenu";
import DraggableTable from "../components/DraggableTable";
import {
  FormItemUploadImage,
  FormItemNavigationUrl,
  FormItemPublishDate,
  FormItemStopDate,
  FormItemEventDescription,
} from "../components/FormItems";
import splitDataByIsInUse from "../utils/splitDataByIsInUse";
import { normalizeRegularEventsData } from "../utils/normalizeData";
import { RegularEventsDataType, RegularEventsFormDataType } from "../types";
import { createColumns } from "./columns";
import handleSubmit from "./handleSubmit";

export default function RegularEvents() {
  const initialMarket = getInitialMarket();

  const [displayType, setDisplayType] = useState<string>("In use");
  const [selectedMarket, setSelectedMarket] =
    useState<MarketType>(initialMarket);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerAction, setDrawerAction] = useState<DrawerActionType>("Create");
  const [dataSource, setDataSource] = useState<RegularEventsDataType[]>([]);
  const [marketData, setMarketData] = useState<RegularEventsDataType[]>([]);
  const [inUseData, setInUseData] = useState<RegularEventsDataType[]>([]);
  const [notInUseData, setNotInUseData] = useState<RegularEventsDataType[]>([]);

  const [uploadFile] = useUploadFileMutation();
  const {
    data,
    loading: loadingOfGet,
    error: errorOfGet,
    refetch,
  } = useGetRegularEventsQuery({
    fetchPolicy: "no-cache",
  });
  const [createRegularEvent, { loading: loadingOfCreate }] =
    useCreateRegularEventMutation();
  const [updateRegularEvent, { loading: loadingOfUpdate }] =
    useUpdateRegularEventMutation();
  const [updateSortId, { loading: loadingOfUpdateSortId }] =
    useUpdateRegularEventsSortIdMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) setDataSource(normalizeRegularEventsData(data));
  }, [data]);

  useEffect(() => {
    setMarketData(dataSource.filter(({ market }) => market === selectedMarket));
  }, [dataSource, selectedMarket]);

  useEffect(() => {
    const { data1, data2 } = splitDataByIsInUse(marketData);

    setInUseData(data1);
    setNotInUseData(data2);
  }, [marketData]);

  if (errorOfGet) errorMessage();
  if (loadingOfGet || loadingOfUpdateSortId) return <Spinner />;

  return (
    <div className="bg-white mx-5 my-6 px-6">
      <TableMenu
        form={form}
        setDisplayType={setDisplayType}
        selectedMarket={selectedMarket}
        setSelectedMarket={setSelectedMarket}
        setOpenDrawer={setOpenDrawer}
        setDrawerAction={setDrawerAction}
      />

      {displayType === "In use" ? (
        <DraggableTable
          eventType="RegularEvent"
          data={inUseData}
          setData={setInUseData}
          setDataSource={setDataSource}
          columns={createColumns(
            form,
            setOpenDrawer,
            setDrawerAction,
            refetch,
            true
          )}
          updateSortId={updateSortId}
          refetch={refetch}
        />
      ) : (
        <Table
          className="custom-table-style"
          dataSource={notInUseData}
          columns={createColumns(form, setOpenDrawer, setDrawerAction, refetch)}
          pagination={false}
        />
      )}

      <DrawerCustom
        title={`${
          drawerAction === "Create" ? "Create new" : "Edit"
        } regular event`}
        name="create-new-regular-event"
        form={form}
        onFinish={(values: RegularEventsFormDataType) =>
          handleSubmit({
            values,
            form,
            drawerAction,
            uploadFile,
            createRegularEvent,
            updateRegularEvent,
            refetch,
            setOpenDrawer,
          })
        }
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        isLoading={loadingOfCreate || loadingOfUpdate}
      >
        <FormItemMarket
          modifiable={false}
          market={form.getFieldValue("market")}
        />
        <FormItemTitle />
        <FormItemEventDescription />
        <FormItemUploadImage resolution="264px * 264px" />
        <FormItemNavigationUrl />
        <FormItemPublishDate />
        <FormItemStopDate />
      </DrawerCustom>
    </div>
  );
}
