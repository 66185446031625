import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetNotificationsQuery = { __typename?: 'Query', wf_adminFirst_afNotification?: { __typename?: 'WF_AdminFirst_AfNotificationConnection', edges?: Array<{ __typename?: 'WF_AdminFirst_AfNotificationEdge', node: { __typename?: 'WF_AdminFirst_AfNotification', id: string, templateMessage?: string | null, title?: string | null, actionButton?: string | null, buttonLinksTo?: string | null, buttonText?: string | null, createdTime?: any | null, creator?: string | null, imageFileName?: string | null, isAllMembers?: boolean | null, isRegular?: boolean | null, market?: string | null, afNotificationServiceTypeId?: string | null, scheduleTime?: any | null, jobStatus?: string | null } }> | null } | null };


export const GetNotificationsDocument = gql`
    query GetNotifications {
  wf_adminFirst_afNotification(last: 100) {
    edges {
      node {
        id
        templateMessage
        title
        actionButton
        buttonLinksTo
        buttonText
        createdTime
        creator
        imageFileName
        isAllMembers
        isRegular
        market
        afNotificationServiceTypeId
        scheduleTime
        jobStatus
      }
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;