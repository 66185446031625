import { TrialContentUploadType } from "../types";

export default function getCategoryFilters(
  contentData: TrialContentUploadType[]
) {
  const uniqueCategories = Array.from(
    new Set(contentData.map((item) => item.categoryName))
  ).sort();

  return uniqueCategories.map((value) => ({
    value: value,
    text: value,
  }));
}
