import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUploadProductVideosQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUploadProductVideosQuery = { __typename?: 'Query', wf_trialModeLeads_productVideo?: { __typename?: 'WF_TrialModeLeads_ProductVideoConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_ProductVideo', id: string, productCategoryId?: number | null, sortId?: number | null, videoTitle?: string | null, introVideoFileName?: string | null, videoFileName?: string | null, videoThumbnail?: string | null, videoUrl?: string | null, videoThumbnailUrl?: string | null, productCategory?: { __typename?: 'WF_TrialModeLeads_ProductCategory', category?: string | null, categoryName?: string | null } | null, productVideoAndMarkets?: Array<{ __typename?: 'WF_TrialModeLeads_ProductVideoAndMarket', id: string, market?: string | null, segmentation?: string | null } | null> | null }> | null } | null };


export const GetUploadProductVideosDocument = gql`
    query GetUploadProductVideos {
  wf_trialModeLeads_productVideo(first: 100, order: {sortId: ASC}) {
    nodes {
      id
      productCategoryId
      sortId
      videoTitle
      introVideoFileName
      videoFileName
      videoUrl: videoFileName @wf_blobSignature(name: "TRIAL_MODE_ONLINE_EXPERIENCE_VIDEOS_SIGNATURE")
      videoThumbnail
      videoThumbnailUrl: videoThumbnail @wf_blobSignature(name: "TRIAL_MODE_ONLINE_EXPERIENCE_VIDEOS_THUMBNAIL_SIGNATURE")
      productCategory {
        category
        categoryName
      }
      productVideoAndMarkets {
        id
        market
        segmentation
      }
    }
  }
}
    `;

/**
 * __useGetUploadProductVideosQuery__
 *
 * To run a query within a React component, call `useGetUploadProductVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadProductVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadProductVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUploadProductVideosQuery(baseOptions?: Apollo.QueryHookOptions<GetUploadProductVideosQuery, GetUploadProductVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadProductVideosQuery, GetUploadProductVideosQueryVariables>(GetUploadProductVideosDocument, options);
      }
export function useGetUploadProductVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadProductVideosQuery, GetUploadProductVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadProductVideosQuery, GetUploadProductVideosQueryVariables>(GetUploadProductVideosDocument, options);
        }
export type GetUploadProductVideosQueryHookResult = ReturnType<typeof useGetUploadProductVideosQuery>;
export type GetUploadProductVideosLazyQueryHookResult = ReturnType<typeof useGetUploadProductVideosLazyQuery>;
export type GetUploadProductVideosQueryResult = Apollo.QueryResult<GetUploadProductVideosQuery, GetUploadProductVideosQueryVariables>;