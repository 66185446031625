import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Table } from "antd";
import { RegionTypes } from "../../../types/types";
import RegionTag from "../../../components/tags/RegionTag";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { useGetUserProgramsByServiceLazyQuery } from "../../../graphql/operations/get-user-program-by-service";
import { useDeleteUserProgramMutation } from "../../../graphql/operations/delete-user-program";
import { useCreateUserProgramMutation } from "../../../graphql/operations/create-user-program";

interface UserListProps {
  users: DataType[];
}

interface DataType {
  id: string;
  name: string;
  market: RegionTypes[];
  notification: boolean;
  faceCall: boolean;
  announcementBar: boolean;
  serviceBanner: boolean;
  eventBanner: boolean;
  goGoLive: boolean;
  importantNotice: boolean;
  waitingForReview: boolean;
  graduationReviewed: boolean;
  releaseVersion: boolean;
  kidsInfo: boolean;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: DataType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ClubAppUserList = ({ users }: UserListProps) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(users);
  const [editingKey, setEditingKey] = useState<string | undefined>("");
  const [getUserPrograms] = useGetUserProgramsByServiceLazyQuery();
  const [deleteUserProgram] = useDeleteUserProgramMutation();
  const [createUserProgram] = useCreateUserProgramMutation();

  const isEditing = (record: DataType) => record.id === editingKey;

  const edit = (record: Partial<DataType>) => {
    form.setFieldsValue({
      notification: record.notification,
      faceCall: record.faceCall,
      announcementBar: record.announcementBar,
      serviceBanner: record.serviceBanner,
      eventBanner: record.eventBanner,
      goGoLive: record.goGoLive,
      importantNotice: record.importantNotice,
      waitingForReview: record.waitingForReview,
      graduationReviewed: record.graduationReviewed,
      releaseVersion: record.releaseVersion,
      kidsInfo: record.kidsInfo,
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as DataType;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
        getUserPrograms({
          variables: {
            userId: parseInt(renderDecodedId(newData[index].id)),
            serviceId: 2,
          },
        })
          .then((r) => {
            if (
              r.data?.wf_adminFirst_afUserProgram?.edges &&
              r.data?.wf_adminFirst_afUserProgram?.edges.length > 0
            ) {
              const deleteData = r.data.wf_adminFirst_afUserProgram.edges.map(
                (program) => ({
                  afUserId: program.node.afUserId,
                  afUserProgramId: parseInt(renderDecodedId(program.node.id)),
                })
              );
              console.log(deleteData);
              deleteUserProgram({ variables: { deleteData } }).catch((e) =>
                console.log(e)
              );
            }
          })
          .then(() => {
            const createData: any = [];
            if (newData[index].notification) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 2,
              });
            }
            if (newData[index].faceCall) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 3,
              });
            }
            if (newData[index].announcementBar) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 18,
              });
            }
            if (newData[index].serviceBanner) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 19,
              });
            }
            if (newData[index].eventBanner) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 6,
              });
            }
            if (newData[index].goGoLive) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 7,
              });
            }
            if (newData[index].importantNotice) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 13,
              });
            }
            if (newData[index].waitingForReview) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 14,
              });
            }
            if (newData[index].graduationReviewed) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 15,
              });
            }
            if (newData[index].kidsInfo) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 23,
              });
            }
            if (newData[index].releaseVersion) {
              createData.push({
                afUserId: parseInt(renderDecodedId(newData[index].id)),
                afProgramId: 21,
              });
            }
            console.log(createData);
            createUserProgram({ variables: { createData } }).catch((e) =>
              console.log(e)
            );
          })
          .catch((e) => console.log(e));
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 200,
    },
    {
      title: "Market",
      dataIndex: "market",
      key: "market",
      render: (value: RegionTypes[]) => (
        <div style={{ display: "flex" }}>
          {value.map((region) => (
            <div key={region} style={{ marginRight: 8 }}>
              <RegionTag key={region} region={region} />
            </div>
          ))}
        </div>
      ),
      width: 150,
    },
    {
      title: "Announcement Bar",
      dataIndex: "announcementBar",
      key: "announcementBar",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Service Banner",
      dataIndex: "serviceBanner",
      key: "serviceBanner",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Event Banner",
      dataIndex: "eventBanner",
      key: "eventBanner",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Go, Go Live!",
      dataIndex: "goGoLive",
      key: "goGoLive",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Notification",
      dataIndex: "notification",
      key: "notification",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Face Call",
      dataIndex: "faceCall",
      key: "faceCall",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Important Notice",
      dataIndex: "importantNotice",
      key: "importantNotice",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Waiting for Review",
      dataIndex: "waitingForReview",
      key: "waitingForReview",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Graduation Reviewed",
      dataIndex: "graduationReviewed",
      key: "graduationReviewed",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Kids Info",
      dataIndex: "kidsInfo",
      key: "kidsInfo",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Release Version",
      dataIndex: "releaseVersion",
      key: "releaseVersion",
      render: (value: boolean) => <Checkbox disabled defaultChecked={value} />,
      width: 120,
      editable: true,
    },
    {
      title: "Actions",
      render: (_: any, record: DataType) => {
        const editable = isEditing(record);
        return editable ? (
          <div style={{ display: "flex" }}>
            <Button
              type="link"
              style={{ padding: 0, marginRight: 8 }}
              onClick={() => save(record.id)}
            >
              Save
            </Button>
            <Button type="link" style={{ padding: 0 }} onClick={cancel}>
              Cancel
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => edit(record)}
            >
              Edit
            </Button>
          </div>
        );
      },
      fixed: "right",
      width: 120,
    },
  ];

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    if (users) {
      setData(users);
    }
  }, [users]);

  return (
    <div className="user-list-table-container">
      <Form form={form} component={false}>
        <Table
          rowKey={(record: DataType) => record.id}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={data}
          rowClassName="editable-row"
          pagination={false}
          scroll={{
            x: "calc(100% - 96px - 200px - 120px)",
            y: window.innerHeight * 0.7 - 48 - 55,
          }}
        />
      </Form>
    </div>
  );
};

export default ClubAppUserList;
