import {
  TrialContentUploadSongFormDataType,
  TrialContentUploadVideoFormDataType,
} from "../types";

export function denormalizeUploadSongData(
  data: TrialContentUploadSongFormDataType
): {
  songTitle: string;
  productCategoryId: number;
  audioFileName: string;
  lyricsFileName: string;
} {
  const { songTitle, productCategoryId, audioFile, lyricsFile } = data;
  const { name: audioFileName } = audioFile[0] || {};
  const { name: lyricsFileName = "" } = lyricsFile?.[0] || {};

  return {
    songTitle,
    productCategoryId,
    audioFileName,
    lyricsFileName,
  };
}

export function denormalizeUploadVideoData(
  data: TrialContentUploadVideoFormDataType
): {
  videoTitle: string;
  productCategoryId: number;
  introVideoFileName: string;
  videoFileName: string;
  videoThumbnail: string;
} {
  const {
    videoTitle,
    productCategoryId,
    introVideoFile,
    videoFile,
    videoThumbnailFile,
  } = data;
  const { name: introVideoFileName } = introVideoFile[0] || {};
  const { name: videoFileName } = videoFile[0] || {};
  const { name: videoThumbnail } = videoThumbnailFile[0] || {};

  return {
    videoTitle,
    productCategoryId,
    introVideoFileName,
    videoFileName,
    videoThumbnail,
  };
}
