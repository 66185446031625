export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Byte: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  Long: { input: any; output: any; }
  Short: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export enum AfBlobPath {
  AppEventBannerImage = 'APP_EVENT_BANNER_IMAGE',
  AppHomeBanners = 'APP_HOME_BANNERS',
  AppServiceBanners = 'APP_SERVICE_BANNERS',
  FaceCallImage = 'FACE_CALL_IMAGE',
  FaceCallPerformerAvatar = 'FACE_CALL_PERFORMER_AVATAR',
  FaceCallVideo = 'FACE_CALL_VIDEO',
  GoGoLiveImage = 'GO_GO_LIVE_IMAGE',
  GraduationAudio = 'GRADUATION_AUDIO',
  GraduationChildPhotos = 'GRADUATION_CHILD_PHOTOS',
  NotificationImage = 'NOTIFICATION_IMAGE',
  TrialModeCallToActionEncourageDesignedImages = 'TRIAL_MODE_CALL_TO_ACTION_ENCOURAGE_DESIGNED_IMAGES',
  TrialModeCallToActionEncouragePhotos = 'TRIAL_MODE_CALL_TO_ACTION_ENCOURAGE_PHOTOS',
  TrialModeDesignedCharacters = 'TRIAL_MODE_DESIGNED_CHARACTERS',
  TrialModeLocalEventFeaturedEventBanners = 'TRIAL_MODE_LOCAL_EVENT_FEATURED_EVENT_BANNERS',
  TrialModeLocalEventRegularEventBanners = 'TRIAL_MODE_LOCAL_EVENT_REGULAR_EVENT_BANNERS',
  TrialModeNotificationImages = 'TRIAL_MODE_NOTIFICATION_IMAGES',
  TrialModeOnlineExperienceAudios = 'TRIAL_MODE_ONLINE_EXPERIENCE_AUDIOS',
  TrialModeOnlineExperienceDweCategoryThumbnail = 'TRIAL_MODE_ONLINE_EXPERIENCE_DWE_CATEGORY_THUMBNAIL',
  TrialModeOnlineExperienceIntroVideos = 'TRIAL_MODE_ONLINE_EXPERIENCE_INTRO_VIDEOS',
  TrialModeOnlineExperienceLyrics = 'TRIAL_MODE_ONLINE_EXPERIENCE_LYRICS',
  TrialModeOnlineExperienceVideos = 'TRIAL_MODE_ONLINE_EXPERIENCE_VIDEOS',
  TrialModeOnlineExperienceVideosThumbnail = 'TRIAL_MODE_ONLINE_EXPERIENCE_VIDEOS_THUMBNAIL',
  VesPdf = 'VES_PDF',
  WfcDemoThumbnailsClubServices = 'WFC_DEMO_THUMBNAILS_CLUB_SERVICES',
  WfcDemoThumbnailsNews = 'WFC_DEMO_THUMBNAILS_NEWS',
  WfcDemoThumbnailsTestimonial = 'WFC_DEMO_THUMBNAILS_TESTIMONIAL',
  WfcDemoVideosClubServices = 'WFC_DEMO_VIDEOS_CLUB_SERVICES',
  WfcDemoVideosLearningPrograms = 'WFC_DEMO_VIDEOS_LEARNING_PROGRAMS',
  WfcDemoVideosNews = 'WFC_DEMO_VIDEOS_NEWS',
  WfcDemoVideosSalesBinders = 'WFC_DEMO_VIDEOS_SALES_BINDERS',
  WfcDemoVideosTestimonial = 'WFC_DEMO_VIDEOS_TESTIMONIAL'
}

export type AfGraduationChild = {
  __typename?: 'AfGraduationChild';
  childEnglishName?: Maybe<Scalars['String']['output']>;
  childHeight: Scalars['Int']['output'];
  childName?: Maybe<Scalars['String']['output']>;
  childNo?: Maybe<Scalars['String']['output']>;
  childPhotoFilename?: Maybe<Scalars['String']['output']>;
  childWeight: Scalars['Int']['output'];
  childWillingEnglishSpeech: Scalars['Boolean']['output'];
  isCambridge: Scalars['Boolean']['output'];
  isExcellent?: Maybe<Scalars['Boolean']['output']>;
  isGraduate: Scalars['Boolean']['output'];
  isSubmittedFromApp: Scalars['Boolean']['output'];
  market?: Maybe<Scalars['String']['output']>;
  memberNo?: Maybe<Scalars['String']['output']>;
  parentWillingChineseSpeech: Scalars['Boolean']['output'];
  seq: Scalars['Int']['output'];
  updatedTime: Scalars['DateTime']['output'];
  year: Scalars['Int']['output'];
};

export type AfGraduationChildFilterInput = {
  and?: InputMaybe<Array<AfGraduationChildFilterInput>>;
  childEnglishName?: InputMaybe<StringOperationFilterInput>;
  childHeight?: InputMaybe<IntOperationFilterInput>;
  childName?: InputMaybe<StringOperationFilterInput>;
  childNo?: InputMaybe<StringOperationFilterInput>;
  childPhotoFilename?: InputMaybe<StringOperationFilterInput>;
  childWeight?: InputMaybe<IntOperationFilterInput>;
  childWillingEnglishSpeech?: InputMaybe<BooleanOperationFilterInput>;
  isCambridge?: InputMaybe<BooleanOperationFilterInput>;
  isExcellent?: InputMaybe<BooleanOperationFilterInput>;
  isGraduate?: InputMaybe<BooleanOperationFilterInput>;
  isSubmittedFromApp?: InputMaybe<BooleanOperationFilterInput>;
  market?: InputMaybe<StringOperationFilterInput>;
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AfGraduationChildFilterInput>>;
  parentWillingChineseSpeech?: InputMaybe<BooleanOperationFilterInput>;
  seq?: InputMaybe<IntOperationFilterInput>;
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type AfGraduationChildSortInput = {
  childEnglishName?: InputMaybe<SortEnumType>;
  childHeight?: InputMaybe<SortEnumType>;
  childName?: InputMaybe<SortEnumType>;
  childNo?: InputMaybe<SortEnumType>;
  childPhotoFilename?: InputMaybe<SortEnumType>;
  childWeight?: InputMaybe<SortEnumType>;
  childWillingEnglishSpeech?: InputMaybe<SortEnumType>;
  isCambridge?: InputMaybe<SortEnumType>;
  isExcellent?: InputMaybe<SortEnumType>;
  isGraduate?: InputMaybe<SortEnumType>;
  isSubmittedFromApp?: InputMaybe<SortEnumType>;
  market?: InputMaybe<SortEnumType>;
  memberNo?: InputMaybe<SortEnumType>;
  parentWillingChineseSpeech?: InputMaybe<SortEnumType>;
  seq?: InputMaybe<SortEnumType>;
  updatedTime?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

export type AfMember = Node & {
  __typename?: 'AfMember';
  email?: Maybe<Scalars['String']['output']>;
  englishName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  market?: Maybe<Market>;
  memberNo: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type AfMemberConnection = {
  __typename?: 'AfMemberConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AfMemberEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AfMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AfMemberEdge = {
  __typename?: 'AfMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AfMember;
};

export type AfMemberFilterInput = {
  and?: InputMaybe<Array<AfMemberFilterInput>>;
  email?: InputMaybe<StringOperationFilterInput>;
  englishName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  market?: InputMaybe<NullableOfMarketOperationFilterInput>;
  memberNo?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AfMemberFilterInput>>;
};

export type AfMemberInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  englishName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  market?: InputMaybe<Market>;
  memberNo: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AfMemberSortInput = {
  email?: InputMaybe<SortEnumType>;
  englishName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  market?: InputMaybe<SortEnumType>;
  memberNo?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type AfNotificationInput = {
  actionButton?: InputMaybe<Scalars['String']['input']>;
  buttonLinksTo?: InputMaybe<Scalars['String']['input']>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  market: Market;
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  serviceTypeId?: InputMaybe<Scalars['String']['input']>;
  templateMessage?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AfUpdateChildGraduationOfHongKongInput = {
  childNumber: Scalars['Int']['input'];
  graduationDate: Scalars['DateTime']['input'];
  memberNumber: Scalars['String']['input'];
};

export type AfUpdateChildGraduationOfKoreaInput = {
  childNumber: Scalars['Int']['input'];
  memberNumber: Scalars['String']['input'];
  yearGraduated: Scalars['DateTime']['input'];
};

export type AfUpdateChildGraduationOfTaiwanInput = {
  childNumber: Scalars['String']['input'];
  creator?: InputMaybe<Scalars['String']['input']>;
  memberNumber: Scalars['String']['input'];
  registrationAge: Scalars['String']['input'];
  registrationDate: Scalars['DateTime']['input'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type BannerMarketInfo = {
  __typename?: 'BannerMarketInfo';
  marketName: Scalars['String']['output'];
};

export type BannerMarketInfoFilterInput = {
  and?: InputMaybe<Array<BannerMarketInfoFilterInput>>;
  marketName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BannerMarketInfoFilterInput>>;
};

export type BannerMarketInfoSortInput = {
  marketName?: InputMaybe<SortEnumType>;
};

export type BannerServiceInfo = {
  __typename?: 'BannerServiceInfo';
  serviceName: Scalars['String']['output'];
};

export type BannerServiceInfoFilterInput = {
  and?: InputMaybe<Array<BannerServiceInfoFilterInput>>;
  or?: InputMaybe<Array<BannerServiceInfoFilterInput>>;
  serviceName?: InputMaybe<StringOperationFilterInput>;
};

export type BannerServiceInfoSortInput = {
  serviceName?: InputMaybe<SortEnumType>;
};

export type BlobContainerSignatureOfAfBlobPath = {
  __typename?: 'BlobContainerSignatureOfAfBlobPath';
  error?: Maybe<Scalars['String']['output']>;
  path: AfBlobPath;
  token?: Maybe<Scalars['String']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type BlobOfAfBlobPathInput = {
  file: Scalars['Upload']['input'];
  path: AfBlobPath;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']['input']>;
  gt?: InputMaybe<Scalars['Byte']['input']>;
  gte?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  lt?: InputMaybe<Scalars['Byte']['input']>;
  lte?: InputMaybe<Scalars['Byte']['input']>;
  neq?: InputMaybe<Scalars['Byte']['input']>;
  ngt?: InputMaybe<Scalars['Byte']['input']>;
  ngte?: InputMaybe<Scalars['Byte']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  nlt?: InputMaybe<Scalars['Byte']['input']>;
  nlte?: InputMaybe<Scalars['Byte']['input']>;
};

/** AppAuth */
export type Club_WorldFamilyClub_AppAuth = Node & {
  __typename?: 'Club_WorldFamilyClub_AppAuth';
  /** DeviceId */
  deviceId?: Maybe<Scalars['String']['output']>;
  /** [ID] Project */
  id: Scalars['ID']['output'];
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** SmsCode */
  smsCode?: Maybe<Scalars['String']['output']>;
  /** UserId */
  userId?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
  /** Verified */
  verified?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppAuthConnection = {
  __typename?: 'Club_WorldFamilyClub_AppAuthConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppAuthEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppAuth>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppAuthEdge = {
  __typename?: 'Club_WorldFamilyClub_AppAuthEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppAuth;
};

/** AppAuth */
export type Club_WorldFamilyClub_AppAuthFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppAuthFilterInput>>;
  /** DeviceId */
  deviceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppAuthFilterInput>>;
  /** [ID] Project */
  project?: InputMaybe<StringOperationFilterInput>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** SmsCode */
  smsCode?: InputMaybe<StringOperationFilterInput>;
  /** UserId */
  userId?: InputMaybe<StringOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Verified */
  verified?: InputMaybe<BooleanOperationFilterInput>;
};

/** AppAuth */
export type Club_WorldFamilyClub_AppAuthInput = {
  /** DeviceId */
  deviceId?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Project */
  project?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** SmsCode */
  smsCode?: InputMaybe<Scalars['String']['input']>;
  /** UserId */
  userId?: InputMaybe<Scalars['String']['input']>;
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Verified */
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** AppAuth */
export type Club_WorldFamilyClub_AppAuthSortInput = {
  /** DeviceId */
  deviceId?: InputMaybe<SortEnumType>;
  /** [ID] Project */
  project?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** SmsCode */
  smsCode?: InputMaybe<SortEnumType>;
  /** UserId */
  userId?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
  /** Verified */
  verified?: InputMaybe<SortEnumType>;
};

/** AppBanner */
export type Club_WorldFamilyClub_AppBanner = Node & {
  __typename?: 'Club_WorldFamilyClub_AppBanner';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] EndTime */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** ImageUrl */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** LinkType */
  linkType?: Maybe<Scalars['String']['output']>;
  /** LinkUrl */
  linkUrl?: Maybe<Scalars['String']['output']>;
  /** Sort */
  sort?: Maybe<Scalars['Short']['output']>;
  /** [NOT NULL] StartTime */
  startTime?: Maybe<Scalars['DateTime']['output']>;
  /** TextAlign */
  textAlign?: Maybe<Scalars['String']['output']>;
  /** TextContent */
  textContent?: Maybe<Scalars['String']['output']>;
  /** UseFlg */
  useFlg?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppBannerConnection = {
  __typename?: 'Club_WorldFamilyClub_AppBannerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppBannerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppBanner>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppBannerEdge = {
  __typename?: 'Club_WorldFamilyClub_AppBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppBanner;
};

/** AppBanner */
export type Club_WorldFamilyClub_AppBannerFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppBannerFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] EndTime */
  endTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** ImageUrl */
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  /** LinkType */
  linkType?: InputMaybe<StringOperationFilterInput>;
  /** LinkUrl */
  linkUrl?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppBannerFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<ShortOperationFilterInput>;
  /** Sort */
  sort?: InputMaybe<ShortOperationFilterInput>;
  /** [NOT NULL] StartTime */
  startTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** TextAlign */
  textAlign?: InputMaybe<StringOperationFilterInput>;
  /** TextContent */
  textContent?: InputMaybe<StringOperationFilterInput>;
  /** UseFlg */
  useFlg?: InputMaybe<StringOperationFilterInput>;
};

/** AppBanner */
export type Club_WorldFamilyClub_AppBannerInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] EndTime */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** ImageUrl */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** LinkType */
  linkType?: InputMaybe<Scalars['String']['input']>;
  /** LinkUrl */
  linkUrl?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Short']['input'];
  /** Sort */
  sort?: InputMaybe<Scalars['Short']['input']>;
  /** [NOT NULL] StartTime */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** TextAlign */
  textAlign?: InputMaybe<Scalars['String']['input']>;
  /** TextContent */
  textContent?: InputMaybe<Scalars['String']['input']>;
  /** UseFlg */
  useFlg?: InputMaybe<Scalars['String']['input']>;
};

/** AppBanner */
export type Club_WorldFamilyClub_AppBannerSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** [NOT NULL] EndTime */
  endTime?: InputMaybe<SortEnumType>;
  /** ImageUrl */
  imageUrl?: InputMaybe<SortEnumType>;
  /** LinkType */
  linkType?: InputMaybe<SortEnumType>;
  /** LinkUrl */
  linkUrl?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Sort */
  sort?: InputMaybe<SortEnumType>;
  /** [NOT NULL] StartTime */
  startTime?: InputMaybe<SortEnumType>;
  /** TextAlign */
  textAlign?: InputMaybe<SortEnumType>;
  /** TextContent */
  textContent?: InputMaybe<SortEnumType>;
  /** UseFlg */
  useFlg?: InputMaybe<SortEnumType>;
};

/** AppDigitalMagazine */
export type Club_WorldFamilyClub_AppDigitalMagazine = Node & {
  __typename?: 'Club_WorldFamilyClub_AppDigitalMagazine';
  /** Cover */
  cover?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** LinkAddress */
  linkAddress?: Maybe<Scalars['String']['output']>;
  /** Period */
  period?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppDigitalMagazineConnection = {
  __typename?: 'Club_WorldFamilyClub_AppDigitalMagazineConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppDigitalMagazineEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppDigitalMagazine>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppDigitalMagazineEdge = {
  __typename?: 'Club_WorldFamilyClub_AppDigitalMagazineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppDigitalMagazine;
};

/** AppDigitalMagazine */
export type Club_WorldFamilyClub_AppDigitalMagazineFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppDigitalMagazineFilterInput>>;
  /** Cover */
  cover?: InputMaybe<StringOperationFilterInput>;
  /** LinkAddress */
  linkAddress?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppDigitalMagazineFilterInput>>;
  /** Period */
  period?: InputMaybe<StringOperationFilterInput>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<ShortOperationFilterInput>;
};

/** AppDigitalMagazine */
export type Club_WorldFamilyClub_AppDigitalMagazineInput = {
  /** Cover */
  cover?: InputMaybe<Scalars['String']['input']>;
  /** LinkAddress */
  linkAddress?: InputMaybe<Scalars['String']['input']>;
  /** Period */
  period?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Short']['input'];
};

/** AppDigitalMagazine */
export type Club_WorldFamilyClub_AppDigitalMagazineSortInput = {
  /** Cover */
  cover?: InputMaybe<SortEnumType>;
  /** LinkAddress */
  linkAddress?: InputMaybe<SortEnumType>;
  /** Period */
  period?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
};

/** AppMemberNotification */
export type Club_WorldFamilyClub_AppMemberNotification = Node & {
  __typename?: 'Club_WorldFamilyClub_AppMemberNotification';
  /** [NOT NULL] ChildNumber */
  childNumber?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] MemberNotificationId */
  id: Scalars['ID']['output'];
  /** LinkNo */
  linkNo?: Maybe<Scalars['String']['output']>;
  /** LookedDate */
  lookedDate?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: Maybe<Scalars['String']['output']>;
  /** Message */
  message?: Maybe<Scalars['String']['output']>;
  /** MessageDate */
  messageDate?: Maybe<Scalars['DateTime']['output']>;
  /** NotificationId */
  notificationId?: Maybe<Scalars['Int']['output']>;
  /** NotificationTypeId */
  notificationTypeId?: Maybe<Scalars['String']['output']>;
  /** PublicFlg */
  publicFlg?: Maybe<Scalars['Boolean']['output']>;
  /** PushFlg */
  pushFlg?: Maybe<Scalars['String']['output']>;
  /** ScheduleTime */
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  /** SendFlg */
  sendFlg?: Maybe<Scalars['String']['output']>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppMemberNotificationConnection = {
  __typename?: 'Club_WorldFamilyClub_AppMemberNotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppMemberNotificationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppMemberNotification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppMemberNotificationEdge = {
  __typename?: 'Club_WorldFamilyClub_AppMemberNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppMemberNotification;
};

/** AppMemberNotification */
export type Club_WorldFamilyClub_AppMemberNotificationFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppMemberNotificationFilterInput>>;
  /** [NOT NULL] ChildNumber */
  childNumber?: InputMaybe<StringOperationFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** LinkNo */
  linkNo?: InputMaybe<StringOperationFilterInput>;
  /** LookedDate */
  lookedDate?: InputMaybe<StringOperationFilterInput>;
  /** [ID] MemberNotificationId */
  memberNotificationId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<StringOperationFilterInput>;
  /** Message */
  message?: InputMaybe<StringOperationFilterInput>;
  /** MessageDate */
  messageDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  /** NotificationTypeId */
  notificationTypeId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppMemberNotificationFilterInput>>;
  /** PublicFlg */
  publicFlg?: InputMaybe<BooleanOperationFilterInput>;
  /** PushFlg */
  pushFlg?: InputMaybe<StringOperationFilterInput>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** SendFlg */
  sendFlg?: InputMaybe<StringOperationFilterInput>;
  /** Title */
  title?: InputMaybe<StringOperationFilterInput>;
};

/** AppMemberNotification */
export type Club_WorldFamilyClub_AppMemberNotificationInput = {
  /** [NOT NULL] ChildNumber */
  childNumber?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** LinkNo */
  linkNo?: InputMaybe<Scalars['String']['input']>;
  /** LookedDate */
  lookedDate?: InputMaybe<Scalars['String']['input']>;
  /** [ID] MemberNotificationId */
  memberNotificationId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** MessageDate */
  messageDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** NotificationTypeId */
  notificationTypeId?: InputMaybe<Scalars['String']['input']>;
  /** PublicFlg */
  publicFlg?: InputMaybe<Scalars['Boolean']['input']>;
  /** PushFlg */
  pushFlg?: InputMaybe<Scalars['String']['input']>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** SendFlg */
  sendFlg?: InputMaybe<Scalars['String']['input']>;
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** AppMemberNotification */
export type Club_WorldFamilyClub_AppMemberNotificationSortInput = {
  /** [NOT NULL] ChildNumber */
  childNumber?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** LinkNo */
  linkNo?: InputMaybe<SortEnumType>;
  /** LookedDate */
  lookedDate?: InputMaybe<SortEnumType>;
  /** [ID] MemberNotificationId */
  memberNotificationId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<SortEnumType>;
  /** Message */
  message?: InputMaybe<SortEnumType>;
  /** MessageDate */
  messageDate?: InputMaybe<SortEnumType>;
  /** NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** NotificationTypeId */
  notificationTypeId?: InputMaybe<SortEnumType>;
  /** PublicFlg */
  publicFlg?: InputMaybe<SortEnumType>;
  /** PushFlg */
  pushFlg?: InputMaybe<SortEnumType>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<SortEnumType>;
  /** SendFlg */
  sendFlg?: InputMaybe<SortEnumType>;
  /** Title */
  title?: InputMaybe<SortEnumType>;
};

/** AppNotification */
export type Club_WorldFamilyClub_AppNotification = Node & {
  __typename?: 'Club_WorldFamilyClub_AppNotification';
  /** ActionButton */
  actionButton?: Maybe<Scalars['String']['output']>;
  /** ButtonLinksTo */
  buttonLinksTo?: Maybe<Scalars['String']['output']>;
  /** ButtonText */
  buttonText?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** Error */
  error?: Maybe<Scalars['String']['output']>;
  /** [ID] NotificationId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** IsAllMember */
  isAllMember?: Maybe<Scalars['Boolean']['output']>;
  /** JobStatus */
  jobStatus?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** MutationOperation */
  mutationOperation?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: Maybe<Scalars['String']['output']>;
  /** RemoveNotificationId */
  removeNotificationId?: Maybe<Scalars['Int']['output']>;
  /** ScheduleTime */
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  /** TemplateMessage */
  templateMessage?: Maybe<Scalars['String']['output']>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppNotificationConnection = {
  __typename?: 'Club_WorldFamilyClub_AppNotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppNotificationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppNotification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppNotificationEdge = {
  __typename?: 'Club_WorldFamilyClub_AppNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppNotification;
};

/** AppNotification */
export type Club_WorldFamilyClub_AppNotificationFilterInput = {
  /** ActionButton */
  actionButton?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationFilterInput>>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<StringOperationFilterInput>;
  /** ButtonText */
  buttonText?: InputMaybe<StringOperationFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** Error */
  error?: InputMaybe<StringOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  /** IsAllMember */
  isAllMember?: InputMaybe<BooleanOperationFilterInput>;
  /** JobStatus */
  jobStatus?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<StringOperationFilterInput>;
  /** [ID] NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationFilterInput>>;
  /** RemoveNotificationId */
  removeNotificationId?: InputMaybe<IntOperationFilterInput>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<StringOperationFilterInput>;
  /** Title */
  title?: InputMaybe<StringOperationFilterInput>;
};

/** AppNotification */
export type Club_WorldFamilyClub_AppNotificationInput = {
  /** ActionButton */
  actionButton?: InputMaybe<Scalars['String']['input']>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<Scalars['String']['input']>;
  /** ButtonText */
  buttonText?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** Error */
  error?: InputMaybe<Scalars['String']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** IsAllMember */
  isAllMember?: InputMaybe<Scalars['Boolean']['input']>;
  /** JobStatus */
  jobStatus?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<Scalars['String']['input']>;
  /** [ID] NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<Scalars['String']['input']>;
  /** RemoveNotificationId */
  removeNotificationId?: InputMaybe<Scalars['Int']['input']>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<Scalars['String']['input']>;
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** AppNotificationMember */
export type Club_WorldFamilyClub_AppNotificationMember = Node & {
  __typename?: 'Club_WorldFamilyClub_AppNotificationMember';
  /** [NOT NULL] ChildNumber */
  childNumber?: Maybe<Scalars['String']['output']>;
  /** [ID] NotificationMemberId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNumber */
  memberNumber?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] NotificationId */
  notificationId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppNotificationMemberConnection = {
  __typename?: 'Club_WorldFamilyClub_AppNotificationMemberConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppNotificationMemberEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppNotificationMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppNotificationMemberEdge = {
  __typename?: 'Club_WorldFamilyClub_AppNotificationMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppNotificationMember;
};

/** AppNotificationMember */
export type Club_WorldFamilyClub_AppNotificationMemberFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationMemberFilterInput>>;
  /** [NOT NULL] ChildNumber */
  childNumber?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] NotificationMemberId */
  notificationMemberId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationMemberFilterInput>>;
};

/** AppNotificationMember */
export type Club_WorldFamilyClub_AppNotificationMemberInput = {
  /** [NOT NULL] ChildNumber */
  childNumber?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] NotificationMemberId */
  notificationMemberId?: InputMaybe<Scalars['Long']['input']>;
};

/** AppNotificationMember */
export type Club_WorldFamilyClub_AppNotificationMemberSortInput = {
  /** [NOT NULL] ChildNumber */
  childNumber?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<SortEnumType>;
  /** [NOT NULL] NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** [ID] NotificationMemberId */
  notificationMemberId?: InputMaybe<SortEnumType>;
};

/** AppNotification */
export type Club_WorldFamilyClub_AppNotificationSortInput = {
  /** ActionButton */
  actionButton?: InputMaybe<SortEnumType>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<SortEnumType>;
  /** ButtonText */
  buttonText?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** Error */
  error?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** IsAllMember */
  isAllMember?: InputMaybe<SortEnumType>;
  /** JobStatus */
  jobStatus?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<SortEnumType>;
  /** [ID] NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<SortEnumType>;
  /** RemoveNotificationId */
  removeNotificationId?: InputMaybe<SortEnumType>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<SortEnumType>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<SortEnumType>;
  /** Title */
  title?: InputMaybe<SortEnumType>;
};

/** AppNotificationType */
export type Club_WorldFamilyClub_AppNotificationType = Node & {
  __typename?: 'Club_WorldFamilyClub_AppNotificationType';
  /** [ID] NotificationTypeId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] IsPublic */
  isPublic?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppNotificationTypeConnection = {
  __typename?: 'Club_WorldFamilyClub_AppNotificationTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppNotificationTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppNotificationType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppNotificationTypeEdge = {
  __typename?: 'Club_WorldFamilyClub_AppNotificationTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppNotificationType;
};

/** AppNotificationType */
export type Club_WorldFamilyClub_AppNotificationTypeFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeFilterInput>>;
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<BooleanOperationFilterInput>;
  /** [ID] NotificationTypeId */
  notificationTypeId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeFilterInput>>;
};

/** AppNotificationType */
export type Club_WorldFamilyClub_AppNotificationTypeInput = {
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** [ID] NotificationTypeId */
  notificationTypeId?: InputMaybe<Scalars['String']['input']>;
};

/** AppNotificationTypeMarket */
export type Club_WorldFamilyClub_AppNotificationTypeMarket = Node & {
  __typename?: 'Club_WorldFamilyClub_AppNotificationTypeMarket';
  /** [ID] NotificationTypeMarketId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] TypeEnglishName */
  typeEnglishName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] TypeName */
  typeName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppNotificationTypeMarketConnection = {
  __typename?: 'Club_WorldFamilyClub_AppNotificationTypeMarketConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarketEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarket>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppNotificationTypeMarketEdge = {
  __typename?: 'Club_WorldFamilyClub_AppNotificationTypeMarketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppNotificationTypeMarket;
};

/** AppNotificationTypeMarket */
export type Club_WorldFamilyClub_AppNotificationTypeMarketFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarketFilterInput>>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<StringOperationFilterInput>;
  /** [ID] NotificationTypeMarketId */
  notificationTypeMarketId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarketFilterInput>>;
  /** [NOT NULL] TypeEnglishName */
  typeEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] TypeName */
  typeName?: InputMaybe<StringOperationFilterInput>;
};

/** AppNotificationTypeMarket */
export type Club_WorldFamilyClub_AppNotificationTypeMarketInput = {
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<Scalars['String']['input']>;
  /** [ID] NotificationTypeMarketId */
  notificationTypeMarketId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] TypeEnglishName */
  typeEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] TypeName */
  typeName?: InputMaybe<Scalars['String']['input']>;
};

/** AppNotificationTypeMarket */
export type Club_WorldFamilyClub_AppNotificationTypeMarketSortInput = {
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<SortEnumType>;
  /** [ID] NotificationTypeMarketId */
  notificationTypeMarketId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TypeEnglishName */
  typeEnglishName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TypeName */
  typeName?: InputMaybe<SortEnumType>;
};

/** AppNotificationType */
export type Club_WorldFamilyClub_AppNotificationTypeSortInput = {
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<SortEnumType>;
  /** [ID] NotificationTypeId */
  notificationTypeId?: InputMaybe<SortEnumType>;
};

/** AppRegionEmergency */
export type Club_WorldFamilyClub_AppRegionEmergency = Node & {
  __typename?: 'Club_WorldFamilyClub_AppRegionEmergency';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** Creater */
  creater?: Maybe<Scalars['String']['output']>;
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] EndTime */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Region */
  id: Scalars['ID']['output'];
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] StartTime */
  startTime?: Maybe<Scalars['DateTime']['output']>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppRegionEmergencyConnection = {
  __typename?: 'Club_WorldFamilyClub_AppRegionEmergencyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppRegionEmergencyEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppRegionEmergency>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppRegionEmergencyEdge = {
  __typename?: 'Club_WorldFamilyClub_AppRegionEmergencyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppRegionEmergency;
};

/** AppRegionEmergency */
export type Club_WorldFamilyClub_AppRegionEmergencyFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppRegionEmergencyFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creater */
  creater?: InputMaybe<StringOperationFilterInput>;
  /** Description */
  description?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] EndTime */
  endTime?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppRegionEmergencyFilterInput>>;
  /** [ID] Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] StartTime */
  startTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Title */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** AppRegionEmergency */
export type Club_WorldFamilyClub_AppRegionEmergencyInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creater */
  creater?: InputMaybe<Scalars['String']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] EndTime */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] StartTime */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AppRegionEmergency */
export type Club_WorldFamilyClub_AppRegionEmergencySortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** Creater */
  creater?: InputMaybe<SortEnumType>;
  /** Description */
  description?: InputMaybe<SortEnumType>;
  /** [NOT NULL] EndTime */
  endTime?: InputMaybe<SortEnumType>;
  /** [ID] Region */
  region?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** [NOT NULL] StartTime */
  startTime?: InputMaybe<SortEnumType>;
  /** Title */
  title?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
};

/** AppRelease */
export type Club_WorldFamilyClub_AppRelease = Node & {
  __typename?: 'Club_WorldFamilyClub_AppRelease';
  /** Appstore */
  appstore?: Maybe<Scalars['String']['output']>;
  /** Appversion */
  appversion?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** Osversion */
  osversion?: Maybe<Scalars['String']['output']>;
  /** ReleaseTime */
  releaseTime?: Maybe<Scalars['DateTime']['output']>;
  /** Version */
  version?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppReleaseConnection = {
  __typename?: 'Club_WorldFamilyClub_AppReleaseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppReleaseEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppRelease>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppReleaseEdge = {
  __typename?: 'Club_WorldFamilyClub_AppReleaseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppRelease;
};

/** AppRelease */
export type Club_WorldFamilyClub_AppReleaseFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppReleaseFilterInput>>;
  /** Appstore */
  appstore?: InputMaybe<StringOperationFilterInput>;
  /** Appversion */
  appversion?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppReleaseFilterInput>>;
  /** Osversion */
  osversion?: InputMaybe<StringOperationFilterInput>;
  /** ReleaseTime */
  releaseTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<ShortOperationFilterInput>;
  /** Version */
  version?: InputMaybe<StringOperationFilterInput>;
};

/** AppRelease */
export type Club_WorldFamilyClub_AppReleaseInput = {
  /** Appstore */
  appstore?: InputMaybe<Scalars['String']['input']>;
  /** Appversion */
  appversion?: InputMaybe<Scalars['String']['input']>;
  /** Osversion */
  osversion?: InputMaybe<Scalars['String']['input']>;
  /** ReleaseTime */
  releaseTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] Seq */
  seq: Scalars['Short']['input'];
  /** Version */
  version?: InputMaybe<Scalars['String']['input']>;
};

/** AppRelease */
export type Club_WorldFamilyClub_AppReleaseSortInput = {
  /** Appstore */
  appstore?: InputMaybe<SortEnumType>;
  /** Appversion */
  appversion?: InputMaybe<SortEnumType>;
  /** Osversion */
  osversion?: InputMaybe<SortEnumType>;
  /** ReleaseTime */
  releaseTime?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Version */
  version?: InputMaybe<SortEnumType>;
};

/** AppServiceRegionFestivalImage */
export type Club_WorldFamilyClub_AppServiceRegionFestivalImage = Node & {
  __typename?: 'Club_WorldFamilyClub_AppServiceRegionFestivalImage';
  /** [NOT NULL] EndDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] FestivalId */
  festivalId?: Maybe<Scalars['String']['output']>;
  /** [ID] RegionId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ServiceId */
  serviceId?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] StartDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppServiceRegionFestivalImageConnection = {
  __typename?: 'Club_WorldFamilyClub_AppServiceRegionFestivalImageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImageEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppServiceRegionFestivalImageEdge = {
  __typename?: 'Club_WorldFamilyClub_AppServiceRegionFestivalImageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppServiceRegionFestivalImage;
};

/** AppServiceRegionFestivalImage */
export type Club_WorldFamilyClub_AppServiceRegionFestivalImageFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImageFilterInput>>;
  /** [NOT NULL] EndDate */
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] FestivalId */
  festivalId?: InputMaybe<StringOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImageFilterInput>>;
  /** [ID] RegionId */
  regionId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ServiceId */
  serviceId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] StartDate */
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
};

/** AppServiceRegionFestivalImage */
export type Club_WorldFamilyClub_AppServiceRegionFestivalImageInput = {
  /** [NOT NULL] EndDate */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] FestivalId */
  festivalId?: InputMaybe<Scalars['String']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] RegionId */
  regionId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ServiceId */
  serviceId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] StartDate */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AppServiceRegionFestivalImage */
export type Club_WorldFamilyClub_AppServiceRegionFestivalImageSortInput = {
  /** [NOT NULL] EndDate */
  endDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] FestivalId */
  festivalId?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** [ID] RegionId */
  regionId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ServiceId */
  serviceId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] StartDate */
  startDate?: InputMaybe<SortEnumType>;
};

/** AppServiceRegionImage */
export type Club_WorldFamilyClub_AppServiceRegionImage = Node & {
  __typename?: 'Club_WorldFamilyClub_AppServiceRegionImage';
  /** [ID] RegionId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ServiceId */
  serviceId?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppServiceRegionImageConnection = {
  __typename?: 'Club_WorldFamilyClub_AppServiceRegionImageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppServiceRegionImageEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppServiceRegionImage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppServiceRegionImageEdge = {
  __typename?: 'Club_WorldFamilyClub_AppServiceRegionImageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppServiceRegionImage;
};

/** AppServiceRegionImage */
export type Club_WorldFamilyClub_AppServiceRegionImageFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionImageFilterInput>>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionImageFilterInput>>;
  /** [ID] RegionId */
  regionId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ServiceId */
  serviceId?: InputMaybe<StringOperationFilterInput>;
};

/** AppServiceRegionImage */
export type Club_WorldFamilyClub_AppServiceRegionImageInput = {
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] RegionId */
  regionId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ServiceId */
  serviceId?: InputMaybe<Scalars['String']['input']>;
};

/** AppServiceRegionImage */
export type Club_WorldFamilyClub_AppServiceRegionImageSortInput = {
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** [ID] RegionId */
  regionId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ServiceId */
  serviceId?: InputMaybe<SortEnumType>;
};

/** AppSurvey */
export type Club_WorldFamilyClub_AppSurvey = Node & {
  __typename?: 'Club_WorldFamilyClub_AppSurvey';
  /** [ID] SurveyId */
  id: Scalars['ID']['output'];
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** SurveyDescription */
  surveyDescription?: Maybe<Scalars['String']['output']>;
  /** SurveyName */
  surveyName?: Maybe<Scalars['String']['output']>;
  /** SurveyReminder */
  surveyReminder?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppSurveyConnection = {
  __typename?: 'Club_WorldFamilyClub_AppSurveyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppSurveyEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppSurvey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppSurveyEdge = {
  __typename?: 'Club_WorldFamilyClub_AppSurveyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppSurvey;
};

/** AppSurvey */
export type Club_WorldFamilyClub_AppSurveyFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyFilterInput>>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyFilterInput>>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** SurveyDescription */
  surveyDescription?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SurveyId */
  surveyId?: InputMaybe<StringOperationFilterInput>;
  /** SurveyName */
  surveyName?: InputMaybe<StringOperationFilterInput>;
  /** SurveyReminder */
  surveyReminder?: InputMaybe<StringOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** AppSurvey */
export type Club_WorldFamilyClub_AppSurveyInput = {
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** SurveyDescription */
  surveyDescription?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SurveyId */
  surveyId?: InputMaybe<Scalars['String']['input']>;
  /** SurveyName */
  surveyName?: InputMaybe<Scalars['String']['input']>;
  /** SurveyReminder */
  surveyReminder?: InputMaybe<Scalars['String']['input']>;
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AppSurveyMember */
export type Club_WorldFamilyClub_AppSurveyMember = Node & {
  __typename?: 'Club_WorldFamilyClub_AppSurveyMember';
  /** AppItem */
  appItem?: Maybe<Scalars['String']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] MemberNo */
  id: Scalars['ID']['output'];
  /** [NOT NULL] StarAnswer */
  starAnswer?: Maybe<Scalars['Short']['output']>;
  /** [NOT NULL] SurveySeq */
  surveySeq?: Maybe<Scalars['Short']['output']>;
  /** TextAnswer */
  textAnswer?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppSurveyMemberConnection = {
  __typename?: 'Club_WorldFamilyClub_AppSurveyMemberConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppSurveyMemberEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppSurveyMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppSurveyMemberEdge = {
  __typename?: 'Club_WorldFamilyClub_AppSurveyMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppSurveyMember;
};

/** AppSurveyMember */
export type Club_WorldFamilyClub_AppSurveyMemberFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyMemberFilterInput>>;
  /** AppItem */
  appItem?: InputMaybe<StringOperationFilterInput>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyMemberFilterInput>>;
  /** [NOT NULL] StarAnswer */
  starAnswer?: InputMaybe<ShortOperationFilterInput>;
  /** [NOT NULL] SurveySeq */
  surveySeq?: InputMaybe<ShortOperationFilterInput>;
  /** TextAnswer */
  textAnswer?: InputMaybe<StringOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** AppSurveyMember */
export type Club_WorldFamilyClub_AppSurveyMemberInput = {
  /** AppItem */
  appItem?: InputMaybe<Scalars['String']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] StarAnswer */
  starAnswer?: InputMaybe<Scalars['Short']['input']>;
  /** [NOT NULL] SurveySeq */
  surveySeq?: InputMaybe<Scalars['Short']['input']>;
  /** TextAnswer */
  textAnswer?: InputMaybe<Scalars['String']['input']>;
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AppSurveyMember */
export type Club_WorldFamilyClub_AppSurveyMemberSortInput = {
  /** AppItem */
  appItem?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** [ID] MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] StarAnswer */
  starAnswer?: InputMaybe<SortEnumType>;
  /** [NOT NULL] SurveySeq */
  surveySeq?: InputMaybe<SortEnumType>;
  /** TextAnswer */
  textAnswer?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
};

/** AppSurveyQa */
export type Club_WorldFamilyClub_AppSurveyQa = Node & {
  __typename?: 'Club_WorldFamilyClub_AppSurveyQa';
  /** AnswerType */
  answerType?: Maybe<Scalars['String']['output']>;
  /** [ID] SurveySeq */
  id: Scalars['ID']['output'];
  /** QuestionDetail */
  questionDetail?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] QuestionNo */
  questionNo?: Maybe<Scalars['Short']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** SurveyId */
  surveyId?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppSurveyQaConnection = {
  __typename?: 'Club_WorldFamilyClub_AppSurveyQaConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppSurveyQaEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppSurveyQa>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppSurveyQaEdge = {
  __typename?: 'Club_WorldFamilyClub_AppSurveyQaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppSurveyQa;
};

/** AppSurveyQa */
export type Club_WorldFamilyClub_AppSurveyQaFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyQaFilterInput>>;
  /** AnswerType */
  answerType?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyQaFilterInput>>;
  /** QuestionDetail */
  questionDetail?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] QuestionNo */
  questionNo?: InputMaybe<ShortOperationFilterInput>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** SurveyId */
  surveyId?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SurveySeq */
  surveySeq?: InputMaybe<ShortOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** AppSurveyQa */
export type Club_WorldFamilyClub_AppSurveyQaInput = {
  /** AnswerType */
  answerType?: InputMaybe<Scalars['String']['input']>;
  /** QuestionDetail */
  questionDetail?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] QuestionNo */
  questionNo?: InputMaybe<Scalars['Short']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** SurveyId */
  surveyId?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SurveySeq */
  surveySeq: Scalars['Short']['input'];
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AppSurveyQa */
export type Club_WorldFamilyClub_AppSurveyQaSortInput = {
  /** AnswerType */
  answerType?: InputMaybe<SortEnumType>;
  /** QuestionDetail */
  questionDetail?: InputMaybe<SortEnumType>;
  /** [NOT NULL] QuestionNo */
  questionNo?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** SurveyId */
  surveyId?: InputMaybe<SortEnumType>;
  /** [ID] SurveySeq */
  surveySeq?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
};

/** AppSurveyReminder */
export type Club_WorldFamilyClub_AppSurveyReminder = Node & {
  __typename?: 'Club_WorldFamilyClub_AppSurveyReminder';
  /** [ID] SurveyId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** ReminderDate */
  reminderDate?: Maybe<Scalars['DateTime']['output']>;
  /** UtcReminderDate */
  utcReminderDate?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AppSurveyReminderConnection = {
  __typename?: 'Club_WorldFamilyClub_AppSurveyReminderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AppSurveyReminderEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AppSurveyReminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AppSurveyReminderEdge = {
  __typename?: 'Club_WorldFamilyClub_AppSurveyReminderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AppSurveyReminder;
};

/** AppSurveyReminder */
export type Club_WorldFamilyClub_AppSurveyReminderFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyReminderFilterInput>>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyReminderFilterInput>>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** ReminderDate */
  reminderDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] SurveyId */
  surveyId?: InputMaybe<StringOperationFilterInput>;
  /** UtcReminderDate */
  utcReminderDate?: InputMaybe<DateTimeOperationFilterInput>;
};

/** AppSurveyReminder */
export type Club_WorldFamilyClub_AppSurveyReminderInput = {
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** ReminderDate */
  reminderDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] SurveyId */
  surveyId?: InputMaybe<Scalars['String']['input']>;
  /** UtcReminderDate */
  utcReminderDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AppSurveyReminder */
export type Club_WorldFamilyClub_AppSurveyReminderSortInput = {
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** ReminderDate */
  reminderDate?: InputMaybe<SortEnumType>;
  /** [ID] SurveyId */
  surveyId?: InputMaybe<SortEnumType>;
  /** UtcReminderDate */
  utcReminderDate?: InputMaybe<SortEnumType>;
};

/** AppSurvey */
export type Club_WorldFamilyClub_AppSurveySortInput = {
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** SurveyDescription */
  surveyDescription?: InputMaybe<SortEnumType>;
  /** [ID] SurveyId */
  surveyId?: InputMaybe<SortEnumType>;
  /** SurveyName */
  surveyName?: InputMaybe<SortEnumType>;
  /** SurveyReminder */
  surveyReminder?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
};

/** AzureFunction */
export type Club_WorldFamilyClub_AzureFunction = Node & {
  __typename?: 'Club_WorldFamilyClub_AzureFunction';
  /** [ID] AzureFunctionId */
  id: Scalars['ID']['output'];
  /** MinDate */
  minDate?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_AzureFunctionConnection = {
  __typename?: 'Club_WorldFamilyClub_AzureFunctionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_AzureFunctionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_AzureFunction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_AzureFunctionEdge = {
  __typename?: 'Club_WorldFamilyClub_AzureFunctionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_AzureFunction;
};

/** AzureFunction */
export type Club_WorldFamilyClub_AzureFunctionFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_AzureFunctionFilterInput>>;
  /** [ID] AzureFunctionId */
  azureFunctionId?: InputMaybe<StringOperationFilterInput>;
  /** MinDate */
  minDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_AzureFunctionFilterInput>>;
};

/** AzureFunction */
export type Club_WorldFamilyClub_AzureFunctionInput = {
  /** [ID] AzureFunctionId */
  azureFunctionId?: InputMaybe<Scalars['String']['input']>;
  /** MinDate */
  minDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AzureFunction */
export type Club_WorldFamilyClub_AzureFunctionSortInput = {
  /** [ID] AzureFunctionId */
  azureFunctionId?: InputMaybe<SortEnumType>;
  /** MinDate */
  minDate?: InputMaybe<SortEnumType>;
};

/** BroadcastPromotion */
export type Club_WorldFamilyClub_BroadcastPromotion = Node & {
  __typename?: 'Club_WorldFamilyClub_BroadcastPromotion';
  /** [NOT NULL] ContentId */
  contentId?: Maybe<Scalars['Int']['output']>;
  /** CreateTime */
  createTime?: Maybe<Scalars['DateTime']['output']>;
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** EventEndTime */
  eventEndTime?: Maybe<Scalars['DateTime']['output']>;
  /** EventStartTime */
  eventStartTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** ParentGuideUrl */
  parentGuideUrl?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
  /** VideoUrl */
  videoUrl?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_BroadcastPromotionConnection = {
  __typename?: 'Club_WorldFamilyClub_BroadcastPromotionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_BroadcastPromotionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_BroadcastPromotion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_BroadcastPromotionEdge = {
  __typename?: 'Club_WorldFamilyClub_BroadcastPromotionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_BroadcastPromotion;
};

/** BroadcastPromotion */
export type Club_WorldFamilyClub_BroadcastPromotionFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_BroadcastPromotionFilterInput>>;
  /** [NOT NULL] ContentId */
  contentId?: InputMaybe<IntOperationFilterInput>;
  /** CreateTime */
  createTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Description */
  description?: InputMaybe<StringOperationFilterInput>;
  /** EventEndTime */
  eventEndTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** EventStartTime */
  eventStartTime?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_BroadcastPromotionFilterInput>>;
  /** ParentGuideUrl */
  parentGuideUrl?: InputMaybe<StringOperationFilterInput>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** Title */
  title?: InputMaybe<StringOperationFilterInput>;
  /** VideoUrl */
  videoUrl?: InputMaybe<StringOperationFilterInput>;
};

/** BroadcastPromotion */
export type Club_WorldFamilyClub_BroadcastPromotionInput = {
  /** [NOT NULL] ContentId */
  contentId?: InputMaybe<Scalars['Int']['input']>;
  /** CreateTime */
  createTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** EventEndTime */
  eventEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** EventStartTime */
  eventStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** ParentGuideUrl */
  parentGuideUrl?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** VideoUrl */
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

/** BroadcastPromotion */
export type Club_WorldFamilyClub_BroadcastPromotionSortInput = {
  /** [NOT NULL] ContentId */
  contentId?: InputMaybe<SortEnumType>;
  /** CreateTime */
  createTime?: InputMaybe<SortEnumType>;
  /** Description */
  description?: InputMaybe<SortEnumType>;
  /** EventEndTime */
  eventEndTime?: InputMaybe<SortEnumType>;
  /** EventStartTime */
  eventStartTime?: InputMaybe<SortEnumType>;
  /** ParentGuideUrl */
  parentGuideUrl?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Title */
  title?: InputMaybe<SortEnumType>;
  /** VideoUrl */
  videoUrl?: InputMaybe<SortEnumType>;
};

/** ChildGraduationApplication */
export type Club_WorldFamilyClub_ChildGraduationApplication = Node & {
  __typename?: 'Club_WorldFamilyClub_ChildGraduationApplication';
  /** ApplicationCount */
  applicationCount?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] ApplicationYear */
  applicationYear?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] ApprovedCount */
  approvedCount?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] ChildNo */
  childNo?: Maybe<Scalars['String']['output']>;
  /** GraduationDate */
  graduationDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] ChildApplicationId */
  id: Scalars['ID']['output'];
  /** IsExcellent */
  isExcellent?: Maybe<Scalars['Boolean']['output']>;
  /** LastApprovalDate */
  lastApprovalDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RejectedCount */
  rejectedCount?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_ChildGraduationApplicationConnection = {
  __typename?: 'Club_WorldFamilyClub_ChildGraduationApplicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_ChildGraduationApplicationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_ChildGraduationApplication>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_ChildGraduationApplicationEdge = {
  __typename?: 'Club_WorldFamilyClub_ChildGraduationApplicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_ChildGraduationApplication;
};

/** ChildGraduationApplication */
export type Club_WorldFamilyClub_ChildGraduationApplicationFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_ChildGraduationApplicationFilterInput>>;
  /** ApplicationCount */
  applicationCount?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ApplicationYear */
  applicationYear?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ApprovedCount */
  approvedCount?: InputMaybe<IntOperationFilterInput>;
  /** [ID] ChildApplicationId */
  childApplicationId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<StringOperationFilterInput>;
  /** GraduationDate */
  graduationDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** IsExcellent */
  isExcellent?: InputMaybe<BooleanOperationFilterInput>;
  /** LastApprovalDate */
  lastApprovalDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_ChildGraduationApplicationFilterInput>>;
  /** [NOT NULL] RejectedCount */
  rejectedCount?: InputMaybe<IntOperationFilterInput>;
};

/** ChildGraduationApplication */
export type Club_WorldFamilyClub_ChildGraduationApplicationInput = {
  /** ApplicationCount */
  applicationCount?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ApplicationYear */
  applicationYear?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ApprovedCount */
  approvedCount?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] ChildApplicationId */
  childApplicationId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<Scalars['String']['input']>;
  /** GraduationDate */
  graduationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** IsExcellent */
  isExcellent?: InputMaybe<Scalars['Boolean']['input']>;
  /** LastApprovalDate */
  lastApprovalDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RejectedCount */
  rejectedCount?: InputMaybe<Scalars['Int']['input']>;
};

/** ChildGraduationApplication */
export type Club_WorldFamilyClub_ChildGraduationApplicationSortInput = {
  /** ApplicationCount */
  applicationCount?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ApplicationYear */
  applicationYear?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ApprovedCount */
  approvedCount?: InputMaybe<SortEnumType>;
  /** [ID] ChildApplicationId */
  childApplicationId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<SortEnumType>;
  /** GraduationDate */
  graduationDate?: InputMaybe<SortEnumType>;
  /** IsExcellent */
  isExcellent?: InputMaybe<SortEnumType>;
  /** LastApprovalDate */
  lastApprovalDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RejectedCount */
  rejectedCount?: InputMaybe<SortEnumType>;
};

/** DweProductPackage */
export type Club_WorldFamilyClub_DweProductPackage = Node & {
  __typename?: 'Club_WorldFamilyClub_DweProductPackage';
  /** [COLLECTION] DweProductSongs */
  dweProductSongs?: Maybe<Array<Maybe<Club_WorldFamilyClub_DweProductSong>>>;
  /** [ID] DweProductPackageId */
  id: Scalars['ID']['output'];
  /** ProductFileName */
  productFileName?: Maybe<Scalars['String']['output']>;
  /** ProductName */
  productName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_DweProductPackageConnection = {
  __typename?: 'Club_WorldFamilyClub_DweProductPackageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_DweProductPackageEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_DweProductPackage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_DweProductPackageEdge = {
  __typename?: 'Club_WorldFamilyClub_DweProductPackageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_DweProductPackage;
};

/** DweProductPackage */
export type Club_WorldFamilyClub_DweProductPackageFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_DweProductPackageFilterInput>>;
  /** [ID] DweProductPackageId */
  dweProductPackageId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] DweProductSongs */
  dweProductSongs?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_DweProductSongFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_DweProductPackageFilterInput>>;
  /** ProductFileName */
  productFileName?: InputMaybe<StringOperationFilterInput>;
  /** ProductName */
  productName?: InputMaybe<StringOperationFilterInput>;
};

/** DweProductPackage */
export type Club_WorldFamilyClub_DweProductPackageInput = {
  /** [ID] DweProductPackageId */
  dweProductPackageId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] DweProductSongs */
  dweProductSongs?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_DweProductSongInput>>>;
  /** ProductFileName */
  productFileName?: InputMaybe<Scalars['String']['input']>;
  /** ProductName */
  productName?: InputMaybe<Scalars['String']['input']>;
};

/** DweProductPackage */
export type Club_WorldFamilyClub_DweProductPackageSortInput = {
  /** [ID] DweProductPackageId */
  dweProductPackageId?: InputMaybe<SortEnumType>;
  /** ProductFileName */
  productFileName?: InputMaybe<SortEnumType>;
  /** ProductName */
  productName?: InputMaybe<SortEnumType>;
};

/** DweProductSong */
export type Club_WorldFamilyClub_DweProductSong = Node & {
  __typename?: 'Club_WorldFamilyClub_DweProductSong';
  /** [NAVIGATION] DweProductPackage */
  dweProductPackage?: Maybe<Club_WorldFamilyClub_DweProductPackage>;
  /** DweProductPackageId */
  dweProductPackageId?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] DweProductSongTools */
  dweProductSongTools?: Maybe<Array<Maybe<Club_WorldFamilyClub_DweProductSongTool>>>;
  /** [ID] DweProductSongId */
  id: Scalars['ID']['output'];
  /** Lyrics */
  lyrics?: Maybe<Scalars['String']['output']>;
  /** LyricsFileName */
  lyricsFileName?: Maybe<Scalars['String']['output']>;
  /** SongFileName */
  songFileName?: Maybe<Scalars['String']['output']>;
  /** SongName */
  songName?: Maybe<Scalars['String']['output']>;
  /** SongNumber */
  songNumber?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_DweProductSongConnection = {
  __typename?: 'Club_WorldFamilyClub_DweProductSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_DweProductSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_DweProductSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_DweProductSongEdge = {
  __typename?: 'Club_WorldFamilyClub_DweProductSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_DweProductSong;
};

/** DweProductSong */
export type Club_WorldFamilyClub_DweProductSongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongFilterInput>>;
  /** [NAVIGATION] DweProductPackage */
  dweProductPackage?: InputMaybe<Club_WorldFamilyClub_DweProductPackageFilterInput>;
  /** DweProductPackageId */
  dweProductPackageId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] DweProductSongId */
  dweProductSongId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] DweProductSongTools */
  dweProductSongTools?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_DweProductSongToolFilterInput>;
  /** Lyrics */
  lyrics?: InputMaybe<StringOperationFilterInput>;
  /** LyricsFileName */
  lyricsFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongFilterInput>>;
  /** SongFileName */
  songFileName?: InputMaybe<StringOperationFilterInput>;
  /** SongName */
  songName?: InputMaybe<StringOperationFilterInput>;
  /** SongNumber */
  songNumber?: InputMaybe<StringOperationFilterInput>;
};

/** DweProductSong */
export type Club_WorldFamilyClub_DweProductSongInput = {
  /** [NAVIGATION] DweProductPackage */
  dweProductPackage?: InputMaybe<Club_WorldFamilyClub_DweProductPackageInput>;
  /** DweProductPackageId */
  dweProductPackageId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] DweProductSongId */
  dweProductSongId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] DweProductSongTools */
  dweProductSongTools?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_DweProductSongToolInput>>>;
  /** Lyrics */
  lyrics?: InputMaybe<Scalars['String']['input']>;
  /** LyricsFileName */
  lyricsFileName?: InputMaybe<Scalars['String']['input']>;
  /** SongFileName */
  songFileName?: InputMaybe<Scalars['String']['input']>;
  /** SongName */
  songName?: InputMaybe<Scalars['String']['input']>;
  /** SongNumber */
  songNumber?: InputMaybe<Scalars['String']['input']>;
};

/** DweProductSong */
export type Club_WorldFamilyClub_DweProductSongSortInput = {
  /** [NAVIGATION] DweProductPackage */
  dweProductPackage?: InputMaybe<Club_WorldFamilyClub_DweProductPackageSortInput>;
  /** DweProductPackageId */
  dweProductPackageId?: InputMaybe<SortEnumType>;
  /** [ID] DweProductSongId */
  dweProductSongId?: InputMaybe<SortEnumType>;
  /** Lyrics */
  lyrics?: InputMaybe<SortEnumType>;
  /** LyricsFileName */
  lyricsFileName?: InputMaybe<SortEnumType>;
  /** SongFileName */
  songFileName?: InputMaybe<SortEnumType>;
  /** SongName */
  songName?: InputMaybe<SortEnumType>;
  /** SongNumber */
  songNumber?: InputMaybe<SortEnumType>;
};

/** DweProductSongTool */
export type Club_WorldFamilyClub_DweProductSongTool = Node & {
  __typename?: 'Club_WorldFamilyClub_DweProductSongTool';
  /** [NAVIGATION] DweProductSong */
  dweProductSong?: Maybe<Club_WorldFamilyClub_DweProductSong>;
  /** DweProductSongId */
  dweProductSongId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] DweProductTool */
  dweProductTool?: Maybe<Club_WorldFamilyClub_DweProductTool>;
  /** DweProductToolId */
  dweProductToolId?: Maybe<Scalars['Int']['output']>;
  /** [ID] DweProductSongToolId */
  id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_DweProductSongToolConnection = {
  __typename?: 'Club_WorldFamilyClub_DweProductSongToolConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_DweProductSongToolEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_DweProductSongTool>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_DweProductSongToolEdge = {
  __typename?: 'Club_WorldFamilyClub_DweProductSongToolEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_DweProductSongTool;
};

/** DweProductSongTool */
export type Club_WorldFamilyClub_DweProductSongToolFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongToolFilterInput>>;
  /** [NAVIGATION] DweProductSong */
  dweProductSong?: InputMaybe<Club_WorldFamilyClub_DweProductSongFilterInput>;
  /** DweProductSongId */
  dweProductSongId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] DweProductSongToolId */
  dweProductSongToolId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] DweProductTool */
  dweProductTool?: InputMaybe<Club_WorldFamilyClub_DweProductToolFilterInput>;
  /** DweProductToolId */
  dweProductToolId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongToolFilterInput>>;
};

/** DweProductSongTool */
export type Club_WorldFamilyClub_DweProductSongToolInput = {
  /** [NAVIGATION] DweProductSong */
  dweProductSong?: InputMaybe<Club_WorldFamilyClub_DweProductSongInput>;
  /** DweProductSongId */
  dweProductSongId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] DweProductSongToolId */
  dweProductSongToolId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] DweProductTool */
  dweProductTool?: InputMaybe<Club_WorldFamilyClub_DweProductToolInput>;
  /** DweProductToolId */
  dweProductToolId?: InputMaybe<Scalars['Int']['input']>;
};

/** DweProductSongTool */
export type Club_WorldFamilyClub_DweProductSongToolSortInput = {
  /** [NAVIGATION] DweProductSong */
  dweProductSong?: InputMaybe<Club_WorldFamilyClub_DweProductSongSortInput>;
  /** DweProductSongId */
  dweProductSongId?: InputMaybe<SortEnumType>;
  /** [ID] DweProductSongToolId */
  dweProductSongToolId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] DweProductTool */
  dweProductTool?: InputMaybe<Club_WorldFamilyClub_DweProductToolSortInput>;
  /** DweProductToolId */
  dweProductToolId?: InputMaybe<SortEnumType>;
};

/** DweProductTool */
export type Club_WorldFamilyClub_DweProductTool = Node & {
  __typename?: 'Club_WorldFamilyClub_DweProductTool';
  /** [COLLECTION] DweProductSongTools */
  dweProductSongTools?: Maybe<Array<Maybe<Club_WorldFamilyClub_DweProductSongTool>>>;
  /** [ID] DweProductToolId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** ToolName */
  toolName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_DweProductToolConnection = {
  __typename?: 'Club_WorldFamilyClub_DweProductToolConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_DweProductToolEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_DweProductTool>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_DweProductToolEdge = {
  __typename?: 'Club_WorldFamilyClub_DweProductToolEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_DweProductTool;
};

/** DweProductTool */
export type Club_WorldFamilyClub_DweProductToolFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_DweProductToolFilterInput>>;
  /** [COLLECTION] DweProductSongTools */
  dweProductSongTools?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_DweProductSongToolFilterInput>;
  /** [ID] DweProductToolId */
  dweProductToolId?: InputMaybe<IntOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_DweProductToolFilterInput>>;
  /** ToolName */
  toolName?: InputMaybe<StringOperationFilterInput>;
};

/** DweProductTool */
export type Club_WorldFamilyClub_DweProductToolInput = {
  /** [COLLECTION] DweProductSongTools */
  dweProductSongTools?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_DweProductSongToolInput>>>;
  /** [ID] DweProductToolId */
  dweProductToolId?: InputMaybe<Scalars['Int']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** ToolName */
  toolName?: InputMaybe<Scalars['String']['input']>;
};

/** DweProductTool */
export type Club_WorldFamilyClub_DweProductToolSortInput = {
  /** [ID] DweProductToolId */
  dweProductToolId?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** ToolName */
  toolName?: InputMaybe<SortEnumType>;
};

/** EventBanner */
export type Club_WorldFamilyClub_EventBanner = Node & {
  __typename?: 'Club_WorldFamilyClub_EventBanner';
  /** DisabledFlag */
  disabledFlag?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** TextContent */
  textContent?: Maybe<Scalars['String']['output']>;
  /** TypeNo */
  typeNo?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
  /** UtcUdt */
  utcUdt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_EventBannerConnection = {
  __typename?: 'Club_WorldFamilyClub_EventBannerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_EventBannerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_EventBanner>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_EventBannerEdge = {
  __typename?: 'Club_WorldFamilyClub_EventBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_EventBanner;
};

/** EventBanner */
export type Club_WorldFamilyClub_EventBannerFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerFilterInput>>;
  /** DisabledFlag */
  disabledFlag?: InputMaybe<StringOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** TextContent */
  textContent?: InputMaybe<StringOperationFilterInput>;
  /** TypeNo */
  typeNo?: InputMaybe<StringOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** UtcUdt */
  utcUdt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** EventBanner */
export type Club_WorldFamilyClub_EventBannerInput = {
  /** DisabledFlag */
  disabledFlag?: InputMaybe<Scalars['String']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** TextContent */
  textContent?: InputMaybe<Scalars['String']['input']>;
  /** TypeNo */
  typeNo?: InputMaybe<Scalars['String']['input']>;
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UtcUdt */
  utcUdt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** EventBannerRegion */
export type Club_WorldFamilyClub_EventBannerRegion = Node & {
  __typename?: 'Club_WorldFamilyClub_EventBannerRegion';
  /** [ID] Region */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** RegionMainNo */
  regionMainNo?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
  /** UtcUdt */
  utcUdt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_EventBannerRegionConnection = {
  __typename?: 'Club_WorldFamilyClub_EventBannerRegionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_EventBannerRegionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_EventBannerRegion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_EventBannerRegionEdge = {
  __typename?: 'Club_WorldFamilyClub_EventBannerRegionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_EventBannerRegion;
};

/** EventBannerRegion */
export type Club_WorldFamilyClub_EventBannerRegionFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerRegionFilterInput>>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerRegionFilterInput>>;
  /** [ID] Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** RegionMainNo */
  regionMainNo?: InputMaybe<StringOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** UtcUdt */
  utcUdt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** EventBannerRegion */
export type Club_WorldFamilyClub_EventBannerRegionInput = {
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** RegionMainNo */
  regionMainNo?: InputMaybe<Scalars['String']['input']>;
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UtcUdt */
  utcUdt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** EventBannerRegion */
export type Club_WorldFamilyClub_EventBannerRegionSortInput = {
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** [ID] Region */
  region?: InputMaybe<SortEnumType>;
  /** RegionMainNo */
  regionMainNo?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
  /** UtcUdt */
  utcUdt?: InputMaybe<SortEnumType>;
};

/** EventBanner */
export type Club_WorldFamilyClub_EventBannerSortInput = {
  /** DisabledFlag */
  disabledFlag?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** TextContent */
  textContent?: InputMaybe<SortEnumType>;
  /** TypeNo */
  typeNo?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
  /** UtcUdt */
  utcUdt?: InputMaybe<SortEnumType>;
};

/** EventCheckInAuth */
export type Club_WorldFamilyClub_EventCheckInAuth = Node & {
  __typename?: 'Club_WorldFamilyClub_EventCheckInAuth';
  /** DeviceId */
  deviceId?: Maybe<Scalars['String']['output']>;
  /** [ID] Region */
  id: Scalars['ID']['output'];
  /** SmsCode */
  smsCode?: Maybe<Scalars['String']['output']>;
  /** UserId */
  userId?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
  /** Verified */
  verified?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_EventCheckInAuthConnection = {
  __typename?: 'Club_WorldFamilyClub_EventCheckInAuthConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_EventCheckInAuthEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_EventCheckInAuth>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_EventCheckInAuthEdge = {
  __typename?: 'Club_WorldFamilyClub_EventCheckInAuthEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_EventCheckInAuth;
};

/** EventCheckInAuth */
export type Club_WorldFamilyClub_EventCheckInAuthFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_EventCheckInAuthFilterInput>>;
  /** DeviceId */
  deviceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_EventCheckInAuthFilterInput>>;
  /** [ID] Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** SmsCode */
  smsCode?: InputMaybe<StringOperationFilterInput>;
  /** UserId */
  userId?: InputMaybe<StringOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Verified */
  verified?: InputMaybe<BooleanOperationFilterInput>;
};

/** EventCheckInAuth */
export type Club_WorldFamilyClub_EventCheckInAuthInput = {
  /** DeviceId */
  deviceId?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** SmsCode */
  smsCode?: InputMaybe<Scalars['String']['input']>;
  /** UserId */
  userId?: InputMaybe<Scalars['String']['input']>;
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Verified */
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** EventCheckInAuth */
export type Club_WorldFamilyClub_EventCheckInAuthSortInput = {
  /** DeviceId */
  deviceId?: InputMaybe<SortEnumType>;
  /** [ID] Region */
  region?: InputMaybe<SortEnumType>;
  /** SmsCode */
  smsCode?: InputMaybe<SortEnumType>;
  /** UserId */
  userId?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
  /** Verified */
  verified?: InputMaybe<SortEnumType>;
};

/** EventNotificationLog */
export type Club_WorldFamilyClub_EventNotificationLog = Node & {
  __typename?: 'Club_WorldFamilyClub_EventNotificationLog';
  /** BookingId */
  bookingId?: Maybe<Scalars['String']['output']>;
  /** EventType */
  eventType?: Maybe<Scalars['String']['output']>;
  /** [ID] SeqNo */
  id: Scalars['ID']['output'];
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** NoticeType */
  noticeType?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_EventNotificationLogConnection = {
  __typename?: 'Club_WorldFamilyClub_EventNotificationLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_EventNotificationLogEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_EventNotificationLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_EventNotificationLogEdge = {
  __typename?: 'Club_WorldFamilyClub_EventNotificationLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_EventNotificationLog;
};

/** EventNotificationLog */
export type Club_WorldFamilyClub_EventNotificationLogFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_EventNotificationLogFilterInput>>;
  /** BookingId */
  bookingId?: InputMaybe<StringOperationFilterInput>;
  /** EventType */
  eventType?: InputMaybe<StringOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  /** NoticeType */
  noticeType?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_EventNotificationLogFilterInput>>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<IntOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** EventNotificationLog */
export type Club_WorldFamilyClub_EventNotificationLogInput = {
  /** BookingId */
  bookingId?: InputMaybe<Scalars['String']['input']>;
  /** EventType */
  eventType?: InputMaybe<Scalars['String']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** NoticeType */
  noticeType?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<Scalars['Int']['input']>;
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** EventNotificationLog */
export type Club_WorldFamilyClub_EventNotificationLogSortInput = {
  /** BookingId */
  bookingId?: InputMaybe<SortEnumType>;
  /** EventType */
  eventType?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** NoticeType */
  noticeType?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
};

/** EventSeat */
export type Club_WorldFamilyClub_EventSeat = Node & {
  __typename?: 'Club_WorldFamilyClub_EventSeat';
  /** Area */
  area?: Maybe<Scalars['String']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** Col */
  col?: Maybe<Scalars['Short']['output']>;
  /** DeviceId */
  deviceId?: Maybe<Scalars['String']['output']>;
  /** EventNo */
  eventNo?: Maybe<Scalars['String']['output']>;
  /** Floor */
  floor?: Maybe<Scalars['Short']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** Row */
  row?: Maybe<Scalars['Short']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_EventSeatConnection = {
  __typename?: 'Club_WorldFamilyClub_EventSeatConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_EventSeatEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_EventSeat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_EventSeatEdge = {
  __typename?: 'Club_WorldFamilyClub_EventSeatEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_EventSeat;
};

/** EventSeat */
export type Club_WorldFamilyClub_EventSeatFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_EventSeatFilterInput>>;
  /** Area */
  area?: InputMaybe<StringOperationFilterInput>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Col */
  col?: InputMaybe<ShortOperationFilterInput>;
  /** DeviceId */
  deviceId?: InputMaybe<StringOperationFilterInput>;
  /** EventNo */
  eventNo?: InputMaybe<StringOperationFilterInput>;
  /** Floor */
  floor?: InputMaybe<ShortOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_EventSeatFilterInput>>;
  /** Row */
  row?: InputMaybe<ShortOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
};

/** EventSeat */
export type Club_WorldFamilyClub_EventSeatInput = {
  /** Area */
  area?: InputMaybe<Scalars['String']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Col */
  col?: InputMaybe<Scalars['Short']['input']>;
  /** DeviceId */
  deviceId?: InputMaybe<Scalars['String']['input']>;
  /** EventNo */
  eventNo?: InputMaybe<Scalars['String']['input']>;
  /** Floor */
  floor?: InputMaybe<Scalars['Short']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** Row */
  row?: InputMaybe<Scalars['Short']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
};

/** EventSeat */
export type Club_WorldFamilyClub_EventSeatSortInput = {
  /** Area */
  area?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** Col */
  col?: InputMaybe<SortEnumType>;
  /** DeviceId */
  deviceId?: InputMaybe<SortEnumType>;
  /** EventNo */
  eventNo?: InputMaybe<SortEnumType>;
  /** Floor */
  floor?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** Row */
  row?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
};

/** EventType */
export type Club_WorldFamilyClub_EventType = Node & {
  __typename?: 'Club_WorldFamilyClub_EventType';
  /** [ID] TypeNo */
  id: Scalars['ID']['output'];
  /** SortBy */
  sortBy?: Maybe<Scalars['Short']['output']>;
  /** SubTypeCalendarColor */
  subTypeCalendarColor?: Maybe<Scalars['String']['output']>;
  /** SubTypes */
  subTypes?: Maybe<Scalars['String']['output']>;
  /** TypeChineseName */
  typeChineseName?: Maybe<Scalars['String']['output']>;
  /** TypeEnglishName */
  typeEnglishName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_EventTypeConnection = {
  __typename?: 'Club_WorldFamilyClub_EventTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_EventTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_EventType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_EventTypeEdge = {
  __typename?: 'Club_WorldFamilyClub_EventTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_EventType;
};

/** EventType */
export type Club_WorldFamilyClub_EventTypeFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_EventTypeFilterInput>>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_EventTypeFilterInput>>;
  /** SortBy */
  sortBy?: InputMaybe<ShortOperationFilterInput>;
  /** SubTypeCalendarColor */
  subTypeCalendarColor?: InputMaybe<StringOperationFilterInput>;
  /** SubTypes */
  subTypes?: InputMaybe<StringOperationFilterInput>;
  /** TypeChineseName */
  typeChineseName?: InputMaybe<StringOperationFilterInput>;
  /** TypeEnglishName */
  typeEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TypeNo */
  typeNo?: InputMaybe<StringOperationFilterInput>;
};

/** EventType */
export type Club_WorldFamilyClub_EventTypeInput = {
  /** SortBy */
  sortBy?: InputMaybe<Scalars['Short']['input']>;
  /** SubTypeCalendarColor */
  subTypeCalendarColor?: InputMaybe<Scalars['String']['input']>;
  /** SubTypes */
  subTypes?: InputMaybe<Scalars['String']['input']>;
  /** TypeChineseName */
  typeChineseName?: InputMaybe<Scalars['String']['input']>;
  /** TypeEnglishName */
  typeEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TypeNo */
  typeNo?: InputMaybe<Scalars['String']['input']>;
};

/** EventType */
export type Club_WorldFamilyClub_EventTypeSortInput = {
  /** SortBy */
  sortBy?: InputMaybe<SortEnumType>;
  /** SubTypeCalendarColor */
  subTypeCalendarColor?: InputMaybe<SortEnumType>;
  /** SubTypes */
  subTypes?: InputMaybe<SortEnumType>;
  /** TypeChineseName */
  typeChineseName?: InputMaybe<SortEnumType>;
  /** TypeEnglishName */
  typeEnglishName?: InputMaybe<SortEnumType>;
  /** [ID] TypeNo */
  typeNo?: InputMaybe<SortEnumType>;
};

/** FaceCallAndTelephoneEnglish */
export type Club_WorldFamilyClub_FaceCallAndTelephoneEnglish = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallAndTelephoneEnglish';
  /** [NOT NULL] ActiveMembers */
  activeMembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] BroadCastMembers */
  broadCastMembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] CapMembers */
  capMembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] ClubAppUsers */
  clubAppUsers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] DisneyLibraryMembers */
  disneyLibraryMembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] EventMembers */
  eventMembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] FacecallMembers */
  facecallMembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] FacecallTypeMembers */
  facecallTypeMembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] Gglmembers */
  gglmembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] HighlightsLibraryMembers */
  highlightsLibraryMembers?: Maybe<Scalars['Int']['output']>;
  /** [ID] YearMonth */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Region */
  region?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Temembers */
  temembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] TetypeMembers */
  tetypeMembers?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] VideoMembers */
  videoMembers?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallAndTelephoneEnglishConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallAndTelephoneEnglishConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglish>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallAndTelephoneEnglishEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallAndTelephoneEnglishEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallAndTelephoneEnglish;
};

/** FaceCallAndTelephoneEnglish */
export type Club_WorldFamilyClub_FaceCallAndTelephoneEnglishFilterInput = {
  /** [NOT NULL] ActiveMembers */
  activeMembers?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishFilterInput>>;
  /** [NOT NULL] BroadCastMembers */
  broadCastMembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] CapMembers */
  capMembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ClubAppUsers */
  clubAppUsers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] DisneyLibraryMembers */
  disneyLibraryMembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] EventMembers */
  eventMembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] FacecallMembers */
  facecallMembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] FacecallTypeMembers */
  facecallTypeMembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Gglmembers */
  gglmembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] HighlightsLibraryMembers */
  highlightsLibraryMembers?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishFilterInput>>;
  /** [NOT NULL] Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Temembers */
  temembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] TetypeMembers */
  tetypeMembers?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] VideoMembers */
  videoMembers?: InputMaybe<IntOperationFilterInput>;
  /** [ID] YearMonth */
  yearMonth?: InputMaybe<StringOperationFilterInput>;
};

/** FaceCallAndTelephoneEnglish */
export type Club_WorldFamilyClub_FaceCallAndTelephoneEnglishInput = {
  /** [NOT NULL] ActiveMembers */
  activeMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] BroadCastMembers */
  broadCastMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] CapMembers */
  capMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ClubAppUsers */
  clubAppUsers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] DisneyLibraryMembers */
  disneyLibraryMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] EventMembers */
  eventMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] FacecallMembers */
  facecallMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] FacecallTypeMembers */
  facecallTypeMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Gglmembers */
  gglmembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] HighlightsLibraryMembers */
  highlightsLibraryMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Temembers */
  temembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] TetypeMembers */
  tetypeMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] VideoMembers */
  videoMembers?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] YearMonth */
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};

/** FaceCallAndTelephoneEnglish */
export type Club_WorldFamilyClub_FaceCallAndTelephoneEnglishSortInput = {
  /** [NOT NULL] ActiveMembers */
  activeMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] BroadCastMembers */
  broadCastMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] CapMembers */
  capMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ClubAppUsers */
  clubAppUsers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] DisneyLibraryMembers */
  disneyLibraryMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] EventMembers */
  eventMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] FacecallMembers */
  facecallMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] FacecallTypeMembers */
  facecallTypeMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Gglmembers */
  gglmembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] HighlightsLibraryMembers */
  highlightsLibraryMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Region */
  region?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Temembers */
  temembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TetypeMembers */
  tetypeMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] VideoMembers */
  videoMembers?: InputMaybe<SortEnumType>;
  /** [ID] YearMonth */
  yearMonth?: InputMaybe<SortEnumType>;
};

/** FaceCallForTrialmode */
export type Club_WorldFamilyClub_FaceCallForTrialmode = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallForTrialmode';
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: Maybe<Scalars['Int']['output']>;
  /** [ID] FaceCallForTrialmodeId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] SortId */
  sortId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallForTrialmodeConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallForTrialmodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallForTrialmodeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallForTrialmode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallForTrialmodeEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallForTrialmodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallForTrialmode;
};

/** FaceCallForTrialmode */
export type Club_WorldFamilyClub_FaceCallForTrialmodeFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallForTrialmodeFilterInput>>;
  /** [ID] FaceCallForTrialmodeId */
  faceCallForTrialmodeId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<IntOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallForTrialmodeFilterInput>>;
  /** Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] SortId */
  sortId?: InputMaybe<IntOperationFilterInput>;
};

/** FaceCallForTrialmode */
export type Club_WorldFamilyClub_FaceCallForTrialmodeInput = {
  /** [ID] FaceCallForTrialmodeId */
  faceCallForTrialmodeId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<Scalars['Int']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] SortId */
  sortId?: InputMaybe<Scalars['Int']['input']>;
};

/** FaceCallForTrialmode */
export type Club_WorldFamilyClub_FaceCallForTrialmodeSortInput = {
  /** [ID] FaceCallForTrialmodeId */
  faceCallForTrialmodeId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
  /** [NOT NULL] SortId */
  sortId?: InputMaybe<SortEnumType>;
};

/** FaceCallHist */
export type Club_WorldFamilyClub_FaceCallHist = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallHist';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [NAVIGATION] FaceCallHistCount */
  faceCallHistCount?: Maybe<Club_WorldFamilyClub_FaceCallHistCount>;
  /** FaceCallHistCountId */
  faceCallHistCountId?: Maybe<Scalars['Long']['output']>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: Maybe<Club_WorldFamilyClub_FaceCallSong>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: Maybe<Club_WorldFamilyClub_FaceCallSongPublic>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: Maybe<Scalars['Int']['output']>;
  /** [ID] FaceCallHistId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNumber */
  memberNumber?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallHistConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallHistConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallHistEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallHist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** FaceCallHistCount */
export type Club_WorldFamilyClub_FaceCallHistCount = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallHistCount';
  /** [NOT NULL] Count */
  count?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] Date */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallHist>>>;
  /** [ID] FaceCallHistCountId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNumber */
  memberNumber?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallHistCountConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallHistCountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallHistCountEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallHistCount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallHistCountEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallHistCountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallHistCount;
};

/** FaceCallHistCount */
export type Club_WorldFamilyClub_FaceCallHistCountFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistCountFilterInput>>;
  /** [NOT NULL] Count */
  count?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Date */
  date?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] FaceCallHistCountId */
  faceCallHistCountId?: InputMaybe<LongOperationFilterInput>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallHistFilterInput>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistCountFilterInput>>;
};

/** FaceCallHistCount */
export type Club_WorldFamilyClub_FaceCallHistCountInput = {
  /** [NOT NULL] Count */
  count?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Date */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] FaceCallHistCountId */
  faceCallHistCountId?: InputMaybe<Scalars['Long']['input']>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallHistInput>>>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
};

/** FaceCallHistCount */
export type Club_WorldFamilyClub_FaceCallHistCountSortInput = {
  /** [NOT NULL] Count */
  count?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Date */
  date?: InputMaybe<SortEnumType>;
  /** [ID] FaceCallHistCountId */
  faceCallHistCountId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<SortEnumType>;
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallHistEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallHistEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallHist;
};

/** FaceCallHist */
export type Club_WorldFamilyClub_FaceCallHistFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NAVIGATION] FaceCallHistCount */
  faceCallHistCount?: InputMaybe<Club_WorldFamilyClub_FaceCallHistCountFilterInput>;
  /** FaceCallHistCountId */
  faceCallHistCountId?: InputMaybe<LongOperationFilterInput>;
  /** [ID] FaceCallHistId */
  faceCallHistId?: InputMaybe<LongOperationFilterInput>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistFilterInput>>;
};

/** FaceCallHist */
export type Club_WorldFamilyClub_FaceCallHistInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NAVIGATION] FaceCallHistCount */
  faceCallHistCount?: InputMaybe<Club_WorldFamilyClub_FaceCallHistCountInput>;
  /** FaceCallHistCountId */
  faceCallHistCountId?: InputMaybe<Scalars['Long']['input']>;
  /** [ID] FaceCallHistId */
  faceCallHistId?: InputMaybe<Scalars['Long']['input']>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
};

/** FaceCallHist */
export type Club_WorldFamilyClub_FaceCallHistSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallHistCount */
  faceCallHistCount?: InputMaybe<Club_WorldFamilyClub_FaceCallHistCountSortInput>;
  /** FaceCallHistCountId */
  faceCallHistCountId?: InputMaybe<SortEnumType>;
  /** [ID] FaceCallHistId */
  faceCallHistId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongSortInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicSortInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<SortEnumType>;
};

/** FaceCallMonthlyRecommend */
export type Club_WorldFamilyClub_FaceCallMonthlyRecommend = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallMonthlyRecommend';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: Maybe<Club_WorldFamilyClub_FaceCallSong>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: Maybe<Club_WorldFamilyClub_FaceCallSongPublic>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: Maybe<Scalars['Int']['output']>;
  /** [ID] FaceCallMonthlyRecommendId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Month */
  month?: Maybe<Scalars['Byte']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Year */
  year?: Maybe<Scalars['Short']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallMonthlyRecommendConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallMonthlyRecommendConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommendEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommend>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallMonthlyRecommendEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallMonthlyRecommendEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallMonthlyRecommend;
};

/** FaceCallMonthlyRecommend */
export type Club_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** [ID] FaceCallMonthlyRecommendId */
  faceCallMonthlyRecommendId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Month */
  month?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput>>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Year */
  year?: InputMaybe<ShortOperationFilterInput>;
};

/** FaceCallMonthlyRecommend */
export type Club_WorldFamilyClub_FaceCallMonthlyRecommendInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** [ID] FaceCallMonthlyRecommendId */
  faceCallMonthlyRecommendId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Month */
  month?: InputMaybe<Scalars['Byte']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Year */
  year?: InputMaybe<Scalars['Short']['input']>;
};

/** FaceCallMonthlyRecommend */
export type Club_WorldFamilyClub_FaceCallMonthlyRecommendSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** [ID] FaceCallMonthlyRecommendId */
  faceCallMonthlyRecommendId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongSortInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicSortInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Month */
  month?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Year */
  year?: InputMaybe<SortEnumType>;
};

/** FaceCallPerformer */
export type Club_WorldFamilyClub_FaceCallPerformer = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallPerformer';
  /** [NOT NULL] AvatarFileName */
  avatarFileName?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallSongAndPerformer>>>;
  /** HkFaceCallPerformerSeq */
  hkFaceCallPerformerSeq?: Maybe<Scalars['Int']['output']>;
  /** [ID] FaceCallPerformerId */
  id: Scalars['ID']['output'];
  /** KrFaceCallPerformerSeq */
  krFaceCallPerformerSeq?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] PerformerName */
  performerName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] PublishdSongs */
  publishdSongs?: Maybe<Scalars['Int']['output']>;
  /** TwFaceCallPerformerSeq */
  twFaceCallPerformerSeq?: Maybe<Scalars['Int']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ZippyFlag */
  zippyFlag?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallPerformerConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallPerformerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallPerformerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallPerformer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallPerformerEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallPerformerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallPerformer;
};

/** FaceCallPerformer */
export type Club_WorldFamilyClub_FaceCallPerformerFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallPerformerFilterInput>>;
  /** [NOT NULL] AvatarFileName */
  avatarFileName?: InputMaybe<StringOperationFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** [ID] FaceCallPerformerId */
  faceCallPerformerId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>;
  /** HkFaceCallPerformerSeq */
  hkFaceCallPerformerSeq?: InputMaybe<IntOperationFilterInput>;
  /** KrFaceCallPerformerSeq */
  krFaceCallPerformerSeq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallPerformerFilterInput>>;
  /** [NOT NULL] PerformerName */
  performerName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] PublishdSongs */
  publishdSongs?: InputMaybe<IntOperationFilterInput>;
  /** TwFaceCallPerformerSeq */
  twFaceCallPerformerSeq?: InputMaybe<IntOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ZippyFlag */
  zippyFlag?: InputMaybe<BooleanOperationFilterInput>;
};

/** FaceCallPerformer */
export type Club_WorldFamilyClub_FaceCallPerformerInput = {
  /** [NOT NULL] AvatarFileName */
  avatarFileName?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** [ID] FaceCallPerformerId */
  faceCallPerformerId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>>>;
  /** HkFaceCallPerformerSeq */
  hkFaceCallPerformerSeq?: InputMaybe<Scalars['Int']['input']>;
  /** KrFaceCallPerformerSeq */
  krFaceCallPerformerSeq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] PerformerName */
  performerName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] PublishdSongs */
  publishdSongs?: InputMaybe<Scalars['Int']['input']>;
  /** TwFaceCallPerformerSeq */
  twFaceCallPerformerSeq?: InputMaybe<Scalars['Int']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ZippyFlag */
  zippyFlag?: InputMaybe<Scalars['Boolean']['input']>;
};

/** FaceCallPerformer */
export type Club_WorldFamilyClub_FaceCallPerformerSortInput = {
  /** [NOT NULL] AvatarFileName */
  avatarFileName?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** [ID] FaceCallPerformerId */
  faceCallPerformerId?: InputMaybe<SortEnumType>;
  /** HkFaceCallPerformerSeq */
  hkFaceCallPerformerSeq?: InputMaybe<SortEnumType>;
  /** KrFaceCallPerformerSeq */
  krFaceCallPerformerSeq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NOT NULL] PerformerName */
  performerName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] PublishdSongs */
  publishdSongs?: InputMaybe<SortEnumType>;
  /** TwFaceCallPerformerSeq */
  twFaceCallPerformerSeq?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ZippyFlag */
  zippyFlag?: InputMaybe<SortEnumType>;
};

/** FaceCallSeasonHoliday */
export type Club_WorldFamilyClub_FaceCallSeasonHoliday = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallSeasonHoliday';
  /** DateFrom */
  dateFrom?: Maybe<Scalars['DateTime']['output']>;
  /** DateTo */
  dateTo?: Maybe<Scalars['DateTime']['output']>;
  /** [COLLECTION] FaceCallSongPublics */
  faceCallSongPublics?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallSongPublic>>>;
  /** [COLLECTION] FaceCallSongs */
  faceCallSongs?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallSong>>>;
  /** IconFileName */
  iconFileName?: Maybe<Scalars['String']['output']>;
  /** [ID] FaceCallSeasonHolidayId */
  id: Scalars['ID']['output'];
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallSeasonHolidayConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallSeasonHolidayConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallSeasonHolidayEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallSeasonHoliday>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallSeasonHolidayEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallSeasonHolidayEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallSeasonHoliday;
};

/** FaceCallSeasonHoliday */
export type Club_WorldFamilyClub_FaceCallSeasonHolidayFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSeasonHolidayFilterInput>>;
  /** DateFrom */
  dateFrom?: InputMaybe<DateTimeOperationFilterInput>;
  /** DateTo */
  dateTo?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] FaceCallSongPublics */
  faceCallSongPublics?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  /** [COLLECTION] FaceCallSongs */
  faceCallSongs?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongFilterInput>;
  /** IconFileName */
  iconFileName?: InputMaybe<StringOperationFilterInput>;
  /** Name */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSeasonHolidayFilterInput>>;
};

/** FaceCallSeasonHoliday */
export type Club_WorldFamilyClub_FaceCallSeasonHolidayInput = {
  /** DateFrom */
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** DateTo */
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] FaceCallSongPublics */
  faceCallSongPublics?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>>>;
  /** [COLLECTION] FaceCallSongs */
  faceCallSongs?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>>>;
  /** IconFileName */
  iconFileName?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** FaceCallSeasonHoliday */
export type Club_WorldFamilyClub_FaceCallSeasonHolidaySortInput = {
  /** DateFrom */
  dateFrom?: InputMaybe<SortEnumType>;
  /** DateTo */
  dateTo?: InputMaybe<SortEnumType>;
  /** [ID] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<SortEnumType>;
  /** IconFileName */
  iconFileName?: InputMaybe<SortEnumType>;
  /** Name */
  name?: InputMaybe<SortEnumType>;
};

/** FaceCallSong */
export type Club_WorldFamilyClub_FaceCallSong = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallSong';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** EndDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallHist>>>;
  /** [COLLECTION] FaceCallMonthlyRecommends */
  faceCallMonthlyRecommends?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallMonthlyRecommend>>>;
  /** [NAVIGATION] FaceCallSeasonHoliday */
  faceCallSeasonHoliday?: Maybe<Club_WorldFamilyClub_FaceCallSeasonHoliday>;
  /** [NOT NULL] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallSongAndPerformer>>>;
  /** HkFaceCallSongSeq */
  hkFaceCallSongSeq?: Maybe<Scalars['Int']['output']>;
  /** [ID] FaceCallSongId */
  id: Scalars['ID']['output'];
  /** JpFaceCallSongSeq */
  jpFaceCallSongSeq?: Maybe<Scalars['Int']['output']>;
  /** KrFaceCallSongSeq */
  krFaceCallSongSeq?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] MyFavoriteFaceCalls */
  myFavoriteFaceCalls?: Maybe<Array<Maybe<Club_WorldFamilyClub_MyFavoriteFaceCall>>>;
  /** StartDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NAVIGATION] TeSong */
  teSong?: Maybe<Club_WorldFamilyClub_TeSong>;
  /** [NOT NULL] TeSongId */
  teSongId?: Maybe<Scalars['Int']['output']>;
  /** TwFaceCallSongSeq */
  twFaceCallSongSeq?: Maybe<Scalars['Int']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] VimeoVideoId */
  vimeoVideoId?: Maybe<Scalars['String']['output']>;
  /** VimeoVideoThumbnail */
  vimeoVideoThumbnail?: Maybe<Scalars['String']['output']>;
};

/** FaceCallSongAndPerformer */
export type Club_WorldFamilyClub_FaceCallSongAndPerformer = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallSongAndPerformer';
  /** [NAVIGATION] FaceCallPerformer */
  faceCallPerformer?: Maybe<Club_WorldFamilyClub_FaceCallPerformer>;
  /** [NOT NULL] FaceCallPerformerId */
  faceCallPerformerId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: Maybe<Club_WorldFamilyClub_FaceCallSong>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: Maybe<Club_WorldFamilyClub_FaceCallSongPublic>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: Maybe<Scalars['Int']['output']>;
  /** [ID] FaceCallSongAndPerformerId */
  id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallSongAndPerformerConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallSongAndPerformerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallSongAndPerformerEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallSongAndPerformerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallSongAndPerformer;
};

/** FaceCallSongAndPerformer */
export type Club_WorldFamilyClub_FaceCallSongAndPerformerFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>>;
  /** [NAVIGATION] FaceCallPerformer */
  faceCallPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallPerformerFilterInput>;
  /** [NOT NULL] FaceCallPerformerId */
  faceCallPerformerId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
  /** [ID] FaceCallSongAndPerformerId */
  faceCallSongAndPerformerId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>>;
};

/** FaceCallSongAndPerformer */
export type Club_WorldFamilyClub_FaceCallSongAndPerformerInput = {
  /** [NAVIGATION] FaceCallPerformer */
  faceCallPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallPerformerInput>;
  /** [NOT NULL] FaceCallPerformerId */
  faceCallPerformerId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>;
  /** [ID] FaceCallSongAndPerformerId */
  faceCallSongAndPerformerId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<Scalars['Int']['input']>;
};

/** FaceCallSongAndPerformer */
export type Club_WorldFamilyClub_FaceCallSongAndPerformerSortInput = {
  /** [NAVIGATION] FaceCallPerformer */
  faceCallPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallPerformerSortInput>;
  /** [NOT NULL] FaceCallPerformerId */
  faceCallPerformerId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongSortInput>;
  /** [ID] FaceCallSongAndPerformerId */
  faceCallSongAndPerformerId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicSortInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallSongConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallSongEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallSong;
};

/** FaceCallSong */
export type Club_WorldFamilyClub_FaceCallSongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** EndDate */
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallHistFilterInput>;
  /** [COLLECTION] FaceCallMonthlyRecommends */
  faceCallMonthlyRecommends?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput>;
  /** [NAVIGATION] FaceCallSeasonHoliday */
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidayFilterInput>;
  /** [NOT NULL] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>;
  /** [ID] FaceCallSongId */
  faceCallSongId?: InputMaybe<IntOperationFilterInput>;
  /** HkFaceCallSongSeq */
  hkFaceCallSongSeq?: InputMaybe<IntOperationFilterInput>;
  /** JpFaceCallSongSeq */
  jpFaceCallSongSeq?: InputMaybe<IntOperationFilterInput>;
  /** KrFaceCallSongSeq */
  krFaceCallSongSeq?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] MyFavoriteFaceCalls */
  myFavoriteFaceCalls?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_MyFavoriteFaceCallFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongFilterInput>>;
  /** StartDate */
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongFilterInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<IntOperationFilterInput>;
  /** TwFaceCallSongSeq */
  twFaceCallSongSeq?: InputMaybe<IntOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] VimeoVideoId */
  vimeoVideoId?: InputMaybe<StringOperationFilterInput>;
  /** VimeoVideoThumbnail */
  vimeoVideoThumbnail?: InputMaybe<StringOperationFilterInput>;
};

/** FaceCallSong */
export type Club_WorldFamilyClub_FaceCallSongInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** EndDate */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallHistInput>>>;
  /** [COLLECTION] FaceCallMonthlyRecommends */
  faceCallMonthlyRecommends?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendInput>>>;
  /** [NAVIGATION] FaceCallSeasonHoliday */
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidayInput>;
  /** [NOT NULL] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>>>;
  /** [ID] FaceCallSongId */
  faceCallSongId?: InputMaybe<Scalars['Int']['input']>;
  /** HkFaceCallSongSeq */
  hkFaceCallSongSeq?: InputMaybe<Scalars['Int']['input']>;
  /** JpFaceCallSongSeq */
  jpFaceCallSongSeq?: InputMaybe<Scalars['Int']['input']>;
  /** KrFaceCallSongSeq */
  krFaceCallSongSeq?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] MyFavoriteFaceCalls */
  myFavoriteFaceCalls?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallInput>>>;
  /** StartDate */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<Scalars['Int']['input']>;
  /** TwFaceCallSongSeq */
  twFaceCallSongSeq?: InputMaybe<Scalars['Int']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] VimeoVideoId */
  vimeoVideoId?: InputMaybe<Scalars['String']['input']>;
  /** VimeoVideoThumbnail */
  vimeoVideoThumbnail?: InputMaybe<Scalars['String']['input']>;
};

/** FaceCallSongPublic */
export type Club_WorldFamilyClub_FaceCallSongPublic = Node & {
  __typename?: 'Club_WorldFamilyClub_FaceCallSongPublic';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** EndDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallHist>>>;
  /** [COLLECTION] FaceCallMonthlyRecommends */
  faceCallMonthlyRecommends?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallMonthlyRecommend>>>;
  /** [NAVIGATION] FaceCallSeasonHoliday */
  faceCallSeasonHoliday?: Maybe<Club_WorldFamilyClub_FaceCallSeasonHoliday>;
  /** [NOT NULL] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallSongAndPerformer>>>;
  /** [ID] FaceCallSongPublicId */
  id: Scalars['ID']['output'];
  /** [COLLECTION] MyFavoriteFaceCalls */
  myFavoriteFaceCalls?: Maybe<Array<Maybe<Club_WorldFamilyClub_MyFavoriteFaceCall>>>;
  /** StartDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NAVIGATION] TeSong */
  teSong?: Maybe<Club_WorldFamilyClub_TeSong>;
  /** [NOT NULL] TeSongId */
  teSongId?: Maybe<Scalars['Int']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] VimeoVideoId */
  vimeoVideoId?: Maybe<Scalars['String']['output']>;
  /** VimeoVideoThumbnail */
  vimeoVideoThumbnail?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_FaceCallSongPublicConnection = {
  __typename?: 'Club_WorldFamilyClub_FaceCallSongPublicConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongPublicEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongPublic>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_FaceCallSongPublicEdge = {
  __typename?: 'Club_WorldFamilyClub_FaceCallSongPublicEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_FaceCallSongPublic;
};

/** FaceCallSongPublic */
export type Club_WorldFamilyClub_FaceCallSongPublicFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** EndDate */
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallHistFilterInput>;
  /** [COLLECTION] FaceCallMonthlyRecommends */
  faceCallMonthlyRecommends?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput>;
  /** [NAVIGATION] FaceCallSeasonHoliday */
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidayFilterInput>;
  /** [NOT NULL] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>;
  /** [ID] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] MyFavoriteFaceCalls */
  myFavoriteFaceCalls?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_MyFavoriteFaceCallFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>>;
  /** StartDate */
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongFilterInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<IntOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] VimeoVideoId */
  vimeoVideoId?: InputMaybe<StringOperationFilterInput>;
  /** VimeoVideoThumbnail */
  vimeoVideoThumbnail?: InputMaybe<StringOperationFilterInput>;
};

/** FaceCallSongPublic */
export type Club_WorldFamilyClub_FaceCallSongPublicInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** EndDate */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [COLLECTION] FaceCallHists */
  faceCallHists?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallHistInput>>>;
  /** [COLLECTION] FaceCallMonthlyRecommends */
  faceCallMonthlyRecommends?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendInput>>>;
  /** [NAVIGATION] FaceCallSeasonHoliday */
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidayInput>;
  /** [NOT NULL] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] FaceCallSongAndPerformers */
  faceCallSongAndPerformers?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>>>;
  /** [ID] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] MyFavoriteFaceCalls */
  myFavoriteFaceCalls?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallInput>>>;
  /** StartDate */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<Scalars['Int']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] VimeoVideoId */
  vimeoVideoId?: InputMaybe<Scalars['String']['input']>;
  /** VimeoVideoThumbnail */
  vimeoVideoThumbnail?: InputMaybe<Scalars['String']['input']>;
};

/** FaceCallSongPublic */
export type Club_WorldFamilyClub_FaceCallSongPublicSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** EndDate */
  endDate?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSeasonHoliday */
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidaySortInput>;
  /** [NOT NULL] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<SortEnumType>;
  /** [ID] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<SortEnumType>;
  /** StartDate */
  startDate?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongSortInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
  /** [NOT NULL] VimeoVideoId */
  vimeoVideoId?: InputMaybe<SortEnumType>;
  /** VimeoVideoThumbnail */
  vimeoVideoThumbnail?: InputMaybe<SortEnumType>;
};

/** FaceCallSong */
export type Club_WorldFamilyClub_FaceCallSongSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** EndDate */
  endDate?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSeasonHoliday */
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidaySortInput>;
  /** [NOT NULL] FaceCallSeasonHolidayId */
  faceCallSeasonHolidayId?: InputMaybe<SortEnumType>;
  /** [ID] FaceCallSongId */
  faceCallSongId?: InputMaybe<SortEnumType>;
  /** HkFaceCallSongSeq */
  hkFaceCallSongSeq?: InputMaybe<SortEnumType>;
  /** JpFaceCallSongSeq */
  jpFaceCallSongSeq?: InputMaybe<SortEnumType>;
  /** KrFaceCallSongSeq */
  krFaceCallSongSeq?: InputMaybe<SortEnumType>;
  /** StartDate */
  startDate?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongSortInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<SortEnumType>;
  /** TwFaceCallSongSeq */
  twFaceCallSongSeq?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
  /** [NOT NULL] VimeoVideoId */
  vimeoVideoId?: InputMaybe<SortEnumType>;
  /** VimeoVideoThumbnail */
  vimeoVideoThumbnail?: InputMaybe<SortEnumType>;
};

/** GoGoLiveDesktopQrcode */
export type Club_WorldFamilyClub_GoGoLiveDesktopQrcode = Node & {
  __typename?: 'Club_WorldFamilyClub_GoGoLiveDesktopQrcode';
  /** ChildName */
  childName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildSeq */
  childSeq?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] CreateTime */
  createTime?: Maybe<Scalars['DateTime']['output']>;
  /** ExpiredTime */
  expiredTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Qrcode */
  qrcode?: Maybe<Scalars['UUID']['output']>;
  /** [NOT NULL] UpdateTime */
  updateTime?: Maybe<Scalars['DateTime']['output']>;
  /** ZoomLink */
  zoomLink?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GoGoLiveDesktopQrcodeConnection = {
  __typename?: 'Club_WorldFamilyClub_GoGoLiveDesktopQrcodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GoGoLiveDesktopQrcodeEdge = {
  __typename?: 'Club_WorldFamilyClub_GoGoLiveDesktopQrcodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GoGoLiveDesktopQrcode;
};

/** GoGoLiveDesktopQrcode */
export type Club_WorldFamilyClub_GoGoLiveDesktopQrcodeFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeFilterInput>>;
  /** ChildName */
  childName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildSeq */
  childSeq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] CreateTime */
  createTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** ExpiredTime */
  expiredTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeFilterInput>>;
  /** [NOT NULL] Qrcode */
  qrcode?: InputMaybe<UuidOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<LongOperationFilterInput>;
  /** [NOT NULL] UpdateTime */
  updateTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** ZoomLink */
  zoomLink?: InputMaybe<StringOperationFilterInput>;
};

/** GoGoLiveDesktopQrcode */
export type Club_WorldFamilyClub_GoGoLiveDesktopQrcodeInput = {
  /** ChildName */
  childName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildSeq */
  childSeq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] CreateTime */
  createTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** ExpiredTime */
  expiredTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Qrcode */
  qrcode?: InputMaybe<Scalars['UUID']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Long']['input']>;
  /** [NOT NULL] UpdateTime */
  updateTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** ZoomLink */
  zoomLink?: InputMaybe<Scalars['String']['input']>;
};

/** GoGoLiveDesktopQrcode */
export type Club_WorldFamilyClub_GoGoLiveDesktopQrcodeSortInput = {
  /** ChildName */
  childName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildSeq */
  childSeq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] CreateTime */
  createTime?: InputMaybe<SortEnumType>;
  /** ExpiredTime */
  expiredTime?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Qrcode */
  qrcode?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] UpdateTime */
  updateTime?: InputMaybe<SortEnumType>;
  /** ZoomLink */
  zoomLink?: InputMaybe<SortEnumType>;
};

/** GoGoLiveOpenDate */
export type Club_WorldFamilyClub_GoGoLiveOpenDate = Node & {
  __typename?: 'Club_WorldFamilyClub_GoGoLiveOpenDate';
  /** [ID] Region */
  id: Scalars['ID']['output'];
  /** OpenDate */
  openDate?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GoGoLiveOpenDateConnection = {
  __typename?: 'Club_WorldFamilyClub_GoGoLiveOpenDateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDateEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GoGoLiveOpenDateEdge = {
  __typename?: 'Club_WorldFamilyClub_GoGoLiveOpenDateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GoGoLiveOpenDate;
};

/** GoGoLiveOpenDate */
export type Club_WorldFamilyClub_GoGoLiveOpenDateFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDateFilterInput>>;
  /** OpenDate */
  openDate?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDateFilterInput>>;
  /** [ID] Region */
  region?: InputMaybe<StringOperationFilterInput>;
};

/** GoGoLiveOpenDate */
export type Club_WorldFamilyClub_GoGoLiveOpenDateInput = {
  /** OpenDate */
  openDate?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Region */
  region?: InputMaybe<Scalars['String']['input']>;
};

/** GoGoLiveOpenDate */
export type Club_WorldFamilyClub_GoGoLiveOpenDateSortInput = {
  /** OpenDate */
  openDate?: InputMaybe<SortEnumType>;
  /** [ID] Region */
  region?: InputMaybe<SortEnumType>;
};

/** GoGoLivePromotion */
export type Club_WorldFamilyClub_GoGoLivePromotion = Node & {
  __typename?: 'Club_WorldFamilyClub_GoGoLivePromotion';
  /** CapLevel */
  capLevel?: Maybe<Scalars['String']['output']>;
  /** CreateTime */
  createTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** ParentGuideUrl */
  parentGuideUrl?: Maybe<Scalars['String']['output']>;
  /** PriceInformation */
  priceInformation?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** ReviewPosterFileName */
  reviewPosterFileName?: Maybe<Scalars['String']['output']>;
  /** ServiceType */
  serviceType?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] TopicYearMonth */
  topicYearMonth?: Maybe<Scalars['DateTime']['output']>;
  /** VideoUrl */
  videoUrl?: Maybe<Scalars['String']['output']>;
  /** YearMonth */
  yearMonth?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GoGoLivePromotionConnection = {
  __typename?: 'Club_WorldFamilyClub_GoGoLivePromotionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GoGoLivePromotionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GoGoLivePromotion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GoGoLivePromotionEdge = {
  __typename?: 'Club_WorldFamilyClub_GoGoLivePromotionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GoGoLivePromotion;
};

/** GoGoLivePromotion */
export type Club_WorldFamilyClub_GoGoLivePromotionFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLivePromotionFilterInput>>;
  /** CapLevel */
  capLevel?: InputMaybe<StringOperationFilterInput>;
  /** CreateTime */
  createTime?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLivePromotionFilterInput>>;
  /** ParentGuideUrl */
  parentGuideUrl?: InputMaybe<StringOperationFilterInput>;
  /** PriceInformation */
  priceInformation?: InputMaybe<StringOperationFilterInput>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** ReviewPosterFileName */
  reviewPosterFileName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** ServiceType */
  serviceType?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] TopicYearMonth */
  topicYearMonth?: InputMaybe<DateTimeOperationFilterInput>;
  /** VideoUrl */
  videoUrl?: InputMaybe<StringOperationFilterInput>;
  /** YearMonth */
  yearMonth?: InputMaybe<StringOperationFilterInput>;
};

/** GoGoLivePromotion */
export type Club_WorldFamilyClub_GoGoLivePromotionInput = {
  /** CapLevel */
  capLevel?: InputMaybe<Scalars['String']['input']>;
  /** CreateTime */
  createTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** ParentGuideUrl */
  parentGuideUrl?: InputMaybe<Scalars['String']['input']>;
  /** PriceInformation */
  priceInformation?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** ReviewPosterFileName */
  reviewPosterFileName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** ServiceType */
  serviceType?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] TopicYearMonth */
  topicYearMonth?: InputMaybe<Scalars['DateTime']['input']>;
  /** VideoUrl */
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  /** YearMonth */
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};

/** GoGoLivePromotion */
export type Club_WorldFamilyClub_GoGoLivePromotionSortInput = {
  /** CapLevel */
  capLevel?: InputMaybe<SortEnumType>;
  /** CreateTime */
  createTime?: InputMaybe<SortEnumType>;
  /** ParentGuideUrl */
  parentGuideUrl?: InputMaybe<SortEnumType>;
  /** PriceInformation */
  priceInformation?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** ReviewPosterFileName */
  reviewPosterFileName?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** ServiceType */
  serviceType?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TopicYearMonth */
  topicYearMonth?: InputMaybe<SortEnumType>;
  /** VideoUrl */
  videoUrl?: InputMaybe<SortEnumType>;
  /** YearMonth */
  yearMonth?: InputMaybe<SortEnumType>;
};

/** GraduationApplication */
export type Club_WorldFamilyClub_GraduationApplication = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationApplication';
  /** Age */
  age?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ApplicationDate */
  applicationDate?: Maybe<Scalars['DateTime']['output']>;
  /** ApprovalDate */
  approvalDate?: Maybe<Scalars['DateTime']['output']>;
  /** AudioFile */
  audioFile?: Maybe<Scalars['String']['output']>;
  /** ChildEnglishName */
  childEnglishName?: Maybe<Scalars['String']['output']>;
  /** ChildName */
  childName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildNo */
  childNo?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** FinishDate */
  finishDate?: Maybe<Scalars['DateTime']['output']>;
  /** [COLLECTION] GraduationApplicationEvaluations */
  graduationApplicationEvaluations?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationApplicationEvaluation>>>;
  /** [COLLECTION] GraduationSubApplications */
  graduationSubApplications?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationSubApplication>>>;
  /** [ID] ApplicationId */
  id: Scalars['ID']['output'];
  /** IsExcellent */
  isExcellent?: Maybe<Scalars['Boolean']['output']>;
  /** IsPassed */
  isPassed?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** OverallComment */
  overallComment?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] Task */
  task?: Maybe<Club_WorldFamilyClub_GraduationApplicationTask>;
  /** [NOT NULL] TaskId */
  taskId?: Maybe<Scalars['Int']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** VideoUrl */
  videoUrl?: Maybe<Scalars['String']['output']>;
  /** YearCount */
  yearCount?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationApplicationConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationApplication>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationApplicationEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationApplication;
};

/** GraduationApplicationEvaluation */
export type Club_WorldFamilyClub_GraduationApplicationEvaluation = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationEvaluation';
  /** [NAVIGATION] Application */
  application?: Maybe<Club_WorldFamilyClub_GraduationApplication>;
  /** [NOT NULL] ApplicationId */
  applicationId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] Evaluation */
  evaluation?: Maybe<Club_WorldFamilyClub_GraduationEvaluation>;
  /** [NOT NULL] EvaluationId */
  evaluationId?: Maybe<Scalars['Int']['output']>;
  /** [ID] ApplicationEvaluationId */
  id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationApplicationEvaluationConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationEvaluationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationApplicationEvaluationEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationEvaluationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationApplicationEvaluation;
};

/** GraduationApplicationEvaluation */
export type Club_WorldFamilyClub_GraduationApplicationEvaluationFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluationFilterInput>>;
  /** [NAVIGATION] Application */
  application?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationFilterInput>;
  /** [ID] ApplicationEvaluationId */
  applicationEvaluationId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ApplicationId */
  applicationId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] Evaluation */
  evaluation?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationFilterInput>;
  /** [NOT NULL] EvaluationId */
  evaluationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluationFilterInput>>;
};

/** GraduationApplicationEvaluation */
export type Club_WorldFamilyClub_GraduationApplicationEvaluationInput = {
  /** [NAVIGATION] Application */
  application?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationInput>;
  /** [ID] ApplicationEvaluationId */
  applicationEvaluationId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ApplicationId */
  applicationId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] Evaluation */
  evaluation?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationInput>;
  /** [NOT NULL] EvaluationId */
  evaluationId?: InputMaybe<Scalars['Int']['input']>;
};

/** GraduationApplicationEvaluation */
export type Club_WorldFamilyClub_GraduationApplicationEvaluationSortInput = {
  /** [NAVIGATION] Application */
  application?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSortInput>;
  /** [ID] ApplicationEvaluationId */
  applicationEvaluationId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ApplicationId */
  applicationId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] Evaluation */
  evaluation?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationSortInput>;
  /** [NOT NULL] EvaluationId */
  evaluationId?: InputMaybe<SortEnumType>;
};

/** GraduationApplication */
export type Club_WorldFamilyClub_GraduationApplicationFilterInput = {
  /** Age */
  age?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationFilterInput>>;
  /** [NOT NULL] ApplicationDate */
  applicationDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] ApplicationId */
  applicationId?: InputMaybe<IntOperationFilterInput>;
  /** ApprovalDate */
  approvalDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** AudioFile */
  audioFile?: InputMaybe<StringOperationFilterInput>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** ChildName */
  childName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<StringOperationFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** FinishDate */
  finishDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [COLLECTION] GraduationApplicationEvaluations */
  graduationApplicationEvaluations?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationApplicationEvaluationFilterInput>;
  /** [COLLECTION] GraduationSubApplications */
  graduationSubApplications?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationSubApplicationFilterInput>;
  /** IsExcellent */
  isExcellent?: InputMaybe<BooleanOperationFilterInput>;
  /** IsPassed */
  isPassed?: InputMaybe<BooleanOperationFilterInput>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationFilterInput>>;
  /** OverallComment */
  overallComment?: InputMaybe<StringOperationFilterInput>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskFilterInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<IntOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** VideoUrl */
  videoUrl?: InputMaybe<StringOperationFilterInput>;
  /** YearCount */
  yearCount?: InputMaybe<StringOperationFilterInput>;
};

/** GraduationApplication */
export type Club_WorldFamilyClub_GraduationApplicationInput = {
  /** Age */
  age?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ApplicationDate */
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] ApplicationId */
  applicationId?: InputMaybe<Scalars['Int']['input']>;
  /** ApprovalDate */
  approvalDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** AudioFile */
  audioFile?: InputMaybe<Scalars['String']['input']>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** ChildName */
  childName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** FinishDate */
  finishDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [COLLECTION] GraduationApplicationEvaluations */
  graduationApplicationEvaluations?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationInput>>>;
  /** [COLLECTION] GraduationSubApplications */
  graduationSubApplications?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationInput>>>;
  /** IsExcellent */
  isExcellent?: InputMaybe<Scalars['Boolean']['input']>;
  /** IsPassed */
  isPassed?: InputMaybe<Scalars['Boolean']['input']>;
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** OverallComment */
  overallComment?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<Scalars['Int']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** VideoUrl */
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  /** YearCount */
  yearCount?: InputMaybe<Scalars['String']['input']>;
};

/** GraduationApplication */
export type Club_WorldFamilyClub_GraduationApplicationSortInput = {
  /** Age */
  age?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ApplicationDate */
  applicationDate?: InputMaybe<SortEnumType>;
  /** [ID] ApplicationId */
  applicationId?: InputMaybe<SortEnumType>;
  /** ApprovalDate */
  approvalDate?: InputMaybe<SortEnumType>;
  /** AudioFile */
  audioFile?: InputMaybe<SortEnumType>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<SortEnumType>;
  /** ChildName */
  childName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** FinishDate */
  finishDate?: InputMaybe<SortEnumType>;
  /** IsExcellent */
  isExcellent?: InputMaybe<SortEnumType>;
  /** IsPassed */
  isPassed?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** OverallComment */
  overallComment?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskSortInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
  /** VideoUrl */
  videoUrl?: InputMaybe<SortEnumType>;
  /** YearCount */
  yearCount?: InputMaybe<SortEnumType>;
};

/** GraduationApplicationSubTask */
export type Club_WorldFamilyClub_GraduationApplicationSubTask = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationSubTask';
  /** [COLLECTION] GraduationSubApplications */
  graduationSubApplications?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationSubApplication>>>;
  /** [COLLECTION] GraduationSubTaskMarketDescriptions */
  graduationSubTaskMarketDescriptions?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescription>>>;
  /** [ID] SubTaskId */
  id: Scalars['ID']['output'];
  /** SortId */
  sortId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] Task */
  task?: Maybe<Club_WorldFamilyClub_GraduationApplicationTask>;
  /** [NOT NULL] TaskId */
  taskId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationApplicationSubTaskConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationSubTaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTaskEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationApplicationSubTaskEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationSubTaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationApplicationSubTask;
};

/** GraduationApplicationSubTask */
export type Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>>;
  /** [COLLECTION] GraduationSubApplications */
  graduationSubApplications?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationSubApplicationFilterInput>;
  /** [COLLECTION] GraduationSubTaskMarketDescriptions */
  graduationSubTaskMarketDescriptions?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>>;
  /** SortId */
  sortId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] SubTaskId */
  subTaskId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskFilterInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<IntOperationFilterInput>;
};

/** GraduationApplicationSubTask */
export type Club_WorldFamilyClub_GraduationApplicationSubTaskInput = {
  /** [COLLECTION] GraduationSubApplications */
  graduationSubApplications?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationInput>>>;
  /** [COLLECTION] GraduationSubTaskMarketDescriptions */
  graduationSubTaskMarketDescriptions?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionInput>>>;
  /** SortId */
  sortId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] SubTaskId */
  subTaskId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<Scalars['Int']['input']>;
};

/** GraduationApplicationSubTask */
export type Club_WorldFamilyClub_GraduationApplicationSubTaskSortInput = {
  /** SortId */
  sortId?: InputMaybe<SortEnumType>;
  /** [ID] SubTaskId */
  subTaskId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskSortInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<SortEnumType>;
};

/** GraduationApplicationTask */
export type Club_WorldFamilyClub_GraduationApplicationTask = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationTask';
  /** [COLLECTION] GraduationApplicationSubTasks */
  graduationApplicationSubTasks?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationApplicationSubTask>>>;
  /** [COLLECTION] GraduationApplications */
  graduationApplications?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationApplication>>>;
  /** [COLLECTION] GraduationEvaluations */
  graduationEvaluations?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationEvaluation>>>;
  /** [COLLECTION] GraduationTaskMarketDescriptions */
  graduationTaskMarketDescriptions?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationTaskMarketDescription>>>;
  /** [ID] TaskId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MaxSubmissionCount */
  maxSubmissionCount?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] MinSubmissionCount */
  minSubmissionCount?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationApplicationTaskConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationTaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationTaskEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationApplicationTaskEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationApplicationTaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationApplicationTask;
};

/** GraduationApplicationTask */
export type Club_WorldFamilyClub_GraduationApplicationTaskFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationTaskFilterInput>>;
  /** [COLLECTION] GraduationApplicationSubTasks */
  graduationApplicationSubTasks?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>;
  /** [COLLECTION] GraduationApplications */
  graduationApplications?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationApplicationFilterInput>;
  /** [COLLECTION] GraduationEvaluations */
  graduationEvaluations?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationEvaluationFilterInput>;
  /** [COLLECTION] GraduationTaskMarketDescriptions */
  graduationTaskMarketDescriptions?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput>;
  /** [NOT NULL] MaxSubmissionCount */
  maxSubmissionCount?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] MinSubmissionCount */
  minSubmissionCount?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationTaskFilterInput>>;
  /** [ID] TaskId */
  taskId?: InputMaybe<IntOperationFilterInput>;
};

/** GraduationApplicationTask */
export type Club_WorldFamilyClub_GraduationApplicationTaskInput = {
  /** [COLLECTION] GraduationApplicationSubTasks */
  graduationApplicationSubTasks?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>>>;
  /** [COLLECTION] GraduationApplications */
  graduationApplications?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationApplicationInput>>>;
  /** [COLLECTION] GraduationEvaluations */
  graduationEvaluations?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationEvaluationInput>>>;
  /** [COLLECTION] GraduationTaskMarketDescriptions */
  graduationTaskMarketDescriptions?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionInput>>>;
  /** [NOT NULL] MaxSubmissionCount */
  maxSubmissionCount?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] MinSubmissionCount */
  minSubmissionCount?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] TaskId */
  taskId?: InputMaybe<Scalars['Int']['input']>;
};

/** GraduationApplicationTask */
export type Club_WorldFamilyClub_GraduationApplicationTaskSortInput = {
  /** [NOT NULL] MaxSubmissionCount */
  maxSubmissionCount?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MinSubmissionCount */
  minSubmissionCount?: InputMaybe<SortEnumType>;
  /** [ID] TaskId */
  taskId?: InputMaybe<SortEnumType>;
};

/** GraduationChild */
export type Club_WorldFamilyClub_GraduationChild = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationChild';
  /** [NOT NULL] ChildEnglishName */
  childEnglishName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildHeight */
  childHeight?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] ChildName */
  childName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildNo */
  childNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildPhotoFilename */
  childPhotoFilename?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildWeight */
  childWeight?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] ChildWillingEnglishSpeech */
  childWillingEnglishSpeech?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] IsCambridge */
  isCambridge?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] IsGraduate */
  isGraduate?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] IsSubmittedFromApp */
  isSubmittedFromApp?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ParentWillingChineseSpeech */
  parentWillingChineseSpeech?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Year */
  year?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationChildConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationChildConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationChildEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationChild>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationChildEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationChildEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationChild;
};

/** GraduationChild */
export type Club_WorldFamilyClub_GraduationChildFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildFilterInput>>;
  /** [NOT NULL] ChildEnglishName */
  childEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildHeight */
  childHeight?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ChildName */
  childName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildPhotoFilename */
  childPhotoFilename?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildWeight */
  childWeight?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ChildWillingEnglishSpeech */
  childWillingEnglishSpeech?: InputMaybe<BooleanOperationFilterInput>;
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] IsCambridge */
  isCambridge?: InputMaybe<BooleanOperationFilterInput>;
  /** [NOT NULL] IsGraduate */
  isGraduate?: InputMaybe<BooleanOperationFilterInput>;
  /** [NOT NULL] IsSubmittedFromApp */
  isSubmittedFromApp?: InputMaybe<BooleanOperationFilterInput>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildFilterInput>>;
  /** [NOT NULL] ParentWillingChineseSpeech */
  parentWillingChineseSpeech?: InputMaybe<BooleanOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Year */
  year?: InputMaybe<IntOperationFilterInput>;
};

/** GraduationChild */
export type Club_WorldFamilyClub_GraduationChildInput = {
  /** [NOT NULL] ChildEnglishName */
  childEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildHeight */
  childHeight?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ChildName */
  childName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildPhotoFilename */
  childPhotoFilename?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildWeight */
  childWeight?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ChildWillingEnglishSpeech */
  childWillingEnglishSpeech?: InputMaybe<Scalars['Boolean']['input']>;
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] IsCambridge */
  isCambridge?: InputMaybe<Scalars['Boolean']['input']>;
  /** [NOT NULL] IsGraduate */
  isGraduate?: InputMaybe<Scalars['Boolean']['input']>;
  /** [NOT NULL] IsSubmittedFromApp */
  isSubmittedFromApp?: InputMaybe<Scalars['Boolean']['input']>;
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ParentWillingChineseSpeech */
  parentWillingChineseSpeech?: InputMaybe<Scalars['Boolean']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Year */
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** GraduationChildSetting */
export type Club_WorldFamilyClub_GraduationChildSetting = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationChildSetting';
  /** [NOT NULL] Deadline */
  deadline?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] StartDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] Year */
  year?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationChildSettingConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationChildSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationChildSettingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationChildSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationChildSettingEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationChildSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationChildSetting;
};

/** GraduationChildSetting */
export type Club_WorldFamilyClub_GraduationChildSettingFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildSettingFilterInput>>;
  /** [NOT NULL] Deadline */
  deadline?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildSettingFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] StartDate */
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Year */
  year?: InputMaybe<IntOperationFilterInput>;
};

/** GraduationChildSetting */
export type Club_WorldFamilyClub_GraduationChildSettingInput = {
  /** [NOT NULL] Deadline */
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] StartDate */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Year */
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** GraduationChildSetting */
export type Club_WorldFamilyClub_GraduationChildSettingSortInput = {
  /** [NOT NULL] Deadline */
  deadline?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] StartDate */
  startDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Year */
  year?: InputMaybe<SortEnumType>;
};

/** GraduationChild */
export type Club_WorldFamilyClub_GraduationChildSortInput = {
  /** [NOT NULL] ChildEnglishName */
  childEnglishName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildHeight */
  childHeight?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildName */
  childName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildPhotoFilename */
  childPhotoFilename?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildWeight */
  childWeight?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildWillingEnglishSpeech */
  childWillingEnglishSpeech?: InputMaybe<SortEnumType>;
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Creator */
  creator?: InputMaybe<SortEnumType>;
  /** [NOT NULL] IsCambridge */
  isCambridge?: InputMaybe<SortEnumType>;
  /** [NOT NULL] IsGraduate */
  isGraduate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] IsSubmittedFromApp */
  isSubmittedFromApp?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ParentWillingChineseSpeech */
  parentWillingChineseSpeech?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Updater */
  updater?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Year */
  year?: InputMaybe<SortEnumType>;
};

/** GraduationEvaluation */
export type Club_WorldFamilyClub_GraduationEvaluation = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationEvaluation';
  /** Evaluation */
  evaluation?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] GraduationApplicationEvaluations */
  graduationApplicationEvaluations?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraduationApplicationEvaluation>>>;
  /** [ID] EvaluationId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] Task */
  task?: Maybe<Club_WorldFamilyClub_GraduationApplicationTask>;
  /** [NOT NULL] TaskId */
  taskId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationEvaluationConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationEvaluationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationEvaluationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationEvaluation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationEvaluationEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationEvaluationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationEvaluation;
};

/** GraduationEvaluation */
export type Club_WorldFamilyClub_GraduationEvaluationFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationEvaluationFilterInput>>;
  /** Evaluation */
  evaluation?: InputMaybe<StringOperationFilterInput>;
  /** [ID] EvaluationId */
  evaluationId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] GraduationApplicationEvaluations */
  graduationApplicationEvaluations?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraduationApplicationEvaluationFilterInput>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationEvaluationFilterInput>>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskFilterInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<IntOperationFilterInput>;
};

/** GraduationEvaluation */
export type Club_WorldFamilyClub_GraduationEvaluationInput = {
  /** Evaluation */
  evaluation?: InputMaybe<Scalars['String']['input']>;
  /** [ID] EvaluationId */
  evaluationId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] GraduationApplicationEvaluations */
  graduationApplicationEvaluations?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationInput>>>;
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<Scalars['Int']['input']>;
};

/** GraduationEvaluation */
export type Club_WorldFamilyClub_GraduationEvaluationSortInput = {
  /** Evaluation */
  evaluation?: InputMaybe<SortEnumType>;
  /** [ID] EvaluationId */
  evaluationId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskSortInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<SortEnumType>;
};

/** GraduationNotification */
export type Club_WorldFamilyClub_GraduationNotification = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationNotification';
  /** [NOT NULL] AfNotificationId */
  afNotificationId?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] ChildNo */
  childNo?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Message */
  message?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Title */
  title?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationNotificationConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationNotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationNotificationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationNotification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationNotificationEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationNotification;
};

/** GraduationNotification */
export type Club_WorldFamilyClub_GraduationNotificationFilterInput = {
  /** [NOT NULL] AfNotificationId */
  afNotificationId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationNotificationFilterInput>>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Message */
  message?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationNotificationFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Title */
  title?: InputMaybe<StringOperationFilterInput>;
};

/** GraduationNotification */
export type Club_WorldFamilyClub_GraduationNotificationInput = {
  /** [NOT NULL] AfNotificationId */
  afNotificationId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Title */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** GraduationNotification */
export type Club_WorldFamilyClub_GraduationNotificationSortInput = {
  /** [NOT NULL] AfNotificationId */
  afNotificationId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Message */
  message?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Title */
  title?: InputMaybe<SortEnumType>;
};

/** GraduationSubApplication */
export type Club_WorldFamilyClub_GraduationSubApplication = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationSubApplication';
  /** [NAVIGATION] Application */
  application?: Maybe<Club_WorldFamilyClub_GraduationApplication>;
  /** [NOT NULL] ApplicationId */
  applicationId?: Maybe<Scalars['Int']['output']>;
  /** [ID] SubApplicationId */
  id: Scalars['ID']['output'];
  /** [NAVIGATION] SubTask */
  subTask?: Maybe<Club_WorldFamilyClub_GraduationApplicationSubTask>;
  /** [NOT NULL] SubTaskId */
  subTaskId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationSubApplicationConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationSubApplicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationSubApplicationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationSubApplication>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationSubApplicationEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationSubApplicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationSubApplication;
};

/** GraduationSubApplication */
export type Club_WorldFamilyClub_GraduationSubApplicationFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubApplicationFilterInput>>;
  /** [NAVIGATION] Application */
  application?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationFilterInput>;
  /** [NOT NULL] ApplicationId */
  applicationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubApplicationFilterInput>>;
  /** [ID] SubApplicationId */
  subApplicationId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] SubTask */
  subTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>;
  /** [NOT NULL] SubTaskId */
  subTaskId?: InputMaybe<IntOperationFilterInput>;
};

/** GraduationSubApplication */
export type Club_WorldFamilyClub_GraduationSubApplicationInput = {
  /** [NAVIGATION] Application */
  application?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationInput>;
  /** [NOT NULL] ApplicationId */
  applicationId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] SubApplicationId */
  subApplicationId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] SubTask */
  subTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>;
  /** [NOT NULL] SubTaskId */
  subTaskId?: InputMaybe<Scalars['Int']['input']>;
};

/** GraduationSubApplication */
export type Club_WorldFamilyClub_GraduationSubApplicationSortInput = {
  /** [NAVIGATION] Application */
  application?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSortInput>;
  /** [NOT NULL] ApplicationId */
  applicationId?: InputMaybe<SortEnumType>;
  /** [ID] SubApplicationId */
  subApplicationId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] SubTask */
  subTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskSortInput>;
  /** [NOT NULL] SubTaskId */
  subTaskId?: InputMaybe<SortEnumType>;
};

/** GraduationSubTaskMarketDescription */
export type Club_WorldFamilyClub_GraduationSubTaskMarketDescription = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationSubTaskMarketDescription';
  /** [ID] SubTaskMarketId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] SubTask */
  subTask?: Maybe<Club_WorldFamilyClub_GraduationApplicationSubTask>;
  /** [NOT NULL] SubTaskId */
  subTaskId?: Maybe<Scalars['Int']['output']>;
  /** SubTaskName */
  subTaskName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationSubTaskMarketDescription;
};

/** GraduationSubTaskMarketDescription */
export type Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput>>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput>>;
  /** [NAVIGATION] SubTask */
  subTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>;
  /** [NOT NULL] SubTaskId */
  subTaskId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] SubTaskMarketId */
  subTaskMarketId?: InputMaybe<IntOperationFilterInput>;
  /** SubTaskName */
  subTaskName?: InputMaybe<StringOperationFilterInput>;
};

/** GraduationSubTaskMarketDescription */
export type Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionInput = {
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] SubTask */
  subTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>;
  /** [NOT NULL] SubTaskId */
  subTaskId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] SubTaskMarketId */
  subTaskMarketId?: InputMaybe<Scalars['Int']['input']>;
  /** SubTaskName */
  subTaskName?: InputMaybe<Scalars['String']['input']>;
};

/** GraduationSubTaskMarketDescription */
export type Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionSortInput = {
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] SubTask */
  subTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskSortInput>;
  /** [NOT NULL] SubTaskId */
  subTaskId?: InputMaybe<SortEnumType>;
  /** [ID] SubTaskMarketId */
  subTaskMarketId?: InputMaybe<SortEnumType>;
  /** SubTaskName */
  subTaskName?: InputMaybe<SortEnumType>;
};

/** GraduationTaskMarketDescription */
export type Club_WorldFamilyClub_GraduationTaskMarketDescription = Node & {
  __typename?: 'Club_WorldFamilyClub_GraduationTaskMarketDescription';
  /** [ID] TaskMarketId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] Task */
  task?: Maybe<Club_WorldFamilyClub_GraduationApplicationTask>;
  /** TaskDescription */
  taskDescription?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] TaskId */
  taskId?: Maybe<Scalars['Int']['output']>;
  /** TaskName */
  taskName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraduationTaskMarketDescriptionConnection = {
  __typename?: 'Club_WorldFamilyClub_GraduationTaskMarketDescriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescriptionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraduationTaskMarketDescriptionEdge = {
  __typename?: 'Club_WorldFamilyClub_GraduationTaskMarketDescriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraduationTaskMarketDescription;
};

/** GraduationTaskMarketDescription */
export type Club_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput>>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput>>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskFilterInput>;
  /** TaskDescription */
  taskDescription?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] TaskMarketId */
  taskMarketId?: InputMaybe<IntOperationFilterInput>;
  /** TaskName */
  taskName?: InputMaybe<StringOperationFilterInput>;
};

/** GraduationTaskMarketDescription */
export type Club_WorldFamilyClub_GraduationTaskMarketDescriptionInput = {
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskInput>;
  /** TaskDescription */
  taskDescription?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] TaskMarketId */
  taskMarketId?: InputMaybe<Scalars['Int']['input']>;
  /** TaskName */
  taskName?: InputMaybe<Scalars['String']['input']>;
};

/** GraduationTaskMarketDescription */
export type Club_WorldFamilyClub_GraduationTaskMarketDescriptionSortInput = {
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] Task */
  task?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskSortInput>;
  /** TaskDescription */
  taskDescription?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TaskId */
  taskId?: InputMaybe<SortEnumType>;
  /** [ID] TaskMarketId */
  taskMarketId?: InputMaybe<SortEnumType>;
  /** TaskName */
  taskName?: InputMaybe<SortEnumType>;
};

/** GraphqlAccess */
export type Club_WorldFamilyClub_GraphqlAccess = Node & {
  __typename?: 'Club_WorldFamilyClub_GraphqlAccess';
  /** AccessSchema */
  accessSchema?: Maybe<Scalars['String']['output']>;
  /** AccessSchemaHash */
  accessSchemaHash?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Database */
  database?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: Maybe<Club_WorldFamilyClub_GraphqlSchema>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: Maybe<Scalars['UUID']['output']>;
  /** [ID] GraphqlAccessId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] ProjectId */
  projectId?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Server */
  server?: Maybe<Scalars['String']['output']>;
  /** UpdateTime */
  updateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraphqlAccessConnection = {
  __typename?: 'Club_WorldFamilyClub_GraphqlAccessConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraphqlAccessEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraphqlAccess>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraphqlAccessEdge = {
  __typename?: 'Club_WorldFamilyClub_GraphqlAccessEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraphqlAccess;
};

/** GraphqlAccess */
export type Club_WorldFamilyClub_GraphqlAccessFilterInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<StringOperationFilterInput>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlAccessFilterInput>>;
  /** [NOT NULL] Database */
  database?: InputMaybe<StringOperationFilterInput>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<UuidOperationFilterInput>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Club_WorldFamilyClub_GraphqlSchemaFilterInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlAccessFilterInput>>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Server */
  server?: InputMaybe<StringOperationFilterInput>;
  /** UpdateTime */
  updateTime?: InputMaybe<DateTimeOperationFilterInput>;
};

/** GraphqlAccess */
export type Club_WorldFamilyClub_GraphqlAccessInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<Scalars['String']['input']>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Database */
  database?: InputMaybe<Scalars['String']['input']>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<Scalars['UUID']['input']>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Club_WorldFamilyClub_GraphqlSchemaInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<Scalars['UUID']['input']>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Server */
  server?: InputMaybe<Scalars['String']['input']>;
  /** UpdateTime */
  updateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** GraphqlAccess */
export type Club_WorldFamilyClub_GraphqlAccessSortInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<SortEnumType>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Database */
  database?: InputMaybe<SortEnumType>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Club_WorldFamilyClub_GraphqlSchemaSortInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Server */
  server?: InputMaybe<SortEnumType>;
  /** UpdateTime */
  updateTime?: InputMaybe<SortEnumType>;
};

/** GraphqlSchema */
export type Club_WorldFamilyClub_GraphqlSchema = Node & {
  __typename?: 'Club_WorldFamilyClub_GraphqlSchema';
  /** [NOT NULL] Database */
  database?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: Maybe<Array<Maybe<Club_WorldFamilyClub_GraphqlAccess>>>;
  /** [ID] GraphqlSchemaId */
  id: Scalars['ID']['output'];
  /** Schema */
  schema?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Server */
  server?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_GraphqlSchemaConnection = {
  __typename?: 'Club_WorldFamilyClub_GraphqlSchemaConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_GraphqlSchemaEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_GraphqlSchema>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_GraphqlSchemaEdge = {
  __typename?: 'Club_WorldFamilyClub_GraphqlSchemaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_GraphqlSchema;
};

/** GraphqlSchema */
export type Club_WorldFamilyClub_GraphqlSchemaFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlSchemaFilterInput>>;
  /** [NOT NULL] Database */
  database?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_GraphqlAccessFilterInput>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlSchemaFilterInput>>;
  /** Schema */
  schema?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Server */
  server?: InputMaybe<StringOperationFilterInput>;
};

/** GraphqlSchema */
export type Club_WorldFamilyClub_GraphqlSchemaInput = {
  /** [NOT NULL] Database */
  database?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_GraphqlAccessInput>>>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<Scalars['UUID']['input']>;
  /** Schema */
  schema?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Server */
  server?: InputMaybe<Scalars['String']['input']>;
};

/** GraphqlSchema */
export type Club_WorldFamilyClub_GraphqlSchemaSortInput = {
  /** [NOT NULL] Database */
  database?: InputMaybe<SortEnumType>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<SortEnumType>;
  /** Schema */
  schema?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Server */
  server?: InputMaybe<SortEnumType>;
};

/** MemberMessageSetting */
export type Club_WorldFamilyClub_MemberMessageSetting = Node & {
  __typename?: 'Club_WorldFamilyClub_MemberMessageSetting';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] MemberMessageSettingId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** MessageType */
  messageType?: Maybe<Scalars['String']['output']>;
  /** NotificationId */
  notificationId?: Maybe<Scalars['Int']['output']>;
  /** PushMessage */
  pushMessage?: Maybe<Scalars['String']['output']>;
  /** SmsMessage */
  smsMessage?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_MemberMessageSettingConnection = {
  __typename?: 'Club_WorldFamilyClub_MemberMessageSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_MemberMessageSettingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_MemberMessageSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_MemberMessageSettingEdge = {
  __typename?: 'Club_WorldFamilyClub_MemberMessageSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_MemberMessageSetting;
};

/** MemberMessageSetting */
export type Club_WorldFamilyClub_MemberMessageSettingFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_MemberMessageSettingFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** [ID] MemberMessageSettingId */
  memberMessageSettingId?: InputMaybe<IntOperationFilterInput>;
  /** MessageType */
  messageType?: InputMaybe<StringOperationFilterInput>;
  /** NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_MemberMessageSettingFilterInput>>;
  /** PushMessage */
  pushMessage?: InputMaybe<StringOperationFilterInput>;
  /** SmsMessage */
  smsMessage?: InputMaybe<StringOperationFilterInput>;
};

/** MemberMessageSetting */
export type Club_WorldFamilyClub_MemberMessageSettingInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [ID] MemberMessageSettingId */
  memberMessageSettingId?: InputMaybe<Scalars['Int']['input']>;
  /** MessageType */
  messageType?: InputMaybe<Scalars['String']['input']>;
  /** NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** PushMessage */
  pushMessage?: InputMaybe<Scalars['String']['input']>;
  /** SmsMessage */
  smsMessage?: InputMaybe<Scalars['String']['input']>;
};

/** MemberMessageSetting */
export type Club_WorldFamilyClub_MemberMessageSettingSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** [ID] MemberMessageSettingId */
  memberMessageSettingId?: InputMaybe<SortEnumType>;
  /** MessageType */
  messageType?: InputMaybe<SortEnumType>;
  /** NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** PushMessage */
  pushMessage?: InputMaybe<SortEnumType>;
  /** SmsMessage */
  smsMessage?: InputMaybe<SortEnumType>;
};

export type Club_WorldFamilyClub_Mutation_Add = {
  __typename?: 'Club_WorldFamilyClub_Mutation_Add';
  appAuth?: Maybe<Club_WorldFamilyClub_AppAuth>;
  appBanner?: Maybe<Club_WorldFamilyClub_AppBanner>;
  appDigitalMagazine?: Maybe<Club_WorldFamilyClub_AppDigitalMagazine>;
  appMemberNotification?: Maybe<Club_WorldFamilyClub_AppMemberNotification>;
  appNotification?: Maybe<Club_WorldFamilyClub_AppNotification>;
  appNotificationMember?: Maybe<Club_WorldFamilyClub_AppNotificationMember>;
  appNotificationType?: Maybe<Club_WorldFamilyClub_AppNotificationType>;
  appNotificationTypeMarket?: Maybe<Club_WorldFamilyClub_AppNotificationTypeMarket>;
  appRegionEmergency?: Maybe<Club_WorldFamilyClub_AppRegionEmergency>;
  appRelease?: Maybe<Club_WorldFamilyClub_AppRelease>;
  appServiceRegionFestivalImage?: Maybe<Club_WorldFamilyClub_AppServiceRegionFestivalImage>;
  appServiceRegionImage?: Maybe<Club_WorldFamilyClub_AppServiceRegionImage>;
  appSurvey?: Maybe<Club_WorldFamilyClub_AppSurvey>;
  appSurveyMember?: Maybe<Club_WorldFamilyClub_AppSurveyMember>;
  appSurveyQa?: Maybe<Club_WorldFamilyClub_AppSurveyQa>;
  appSurveyReminder?: Maybe<Club_WorldFamilyClub_AppSurveyReminder>;
  azureFunction?: Maybe<Club_WorldFamilyClub_AzureFunction>;
  broadcastPromotion?: Maybe<Club_WorldFamilyClub_BroadcastPromotion>;
  childGraduationApplication?: Maybe<Club_WorldFamilyClub_ChildGraduationApplication>;
  dweProductPackage?: Maybe<Club_WorldFamilyClub_DweProductPackage>;
  dweProductSong?: Maybe<Club_WorldFamilyClub_DweProductSong>;
  dweProductSongTool?: Maybe<Club_WorldFamilyClub_DweProductSongTool>;
  dweProductTool?: Maybe<Club_WorldFamilyClub_DweProductTool>;
  eventBanner?: Maybe<Club_WorldFamilyClub_EventBanner>;
  eventBannerRegion?: Maybe<Club_WorldFamilyClub_EventBannerRegion>;
  eventCheckInAuth?: Maybe<Club_WorldFamilyClub_EventCheckInAuth>;
  eventNotificationLog?: Maybe<Club_WorldFamilyClub_EventNotificationLog>;
  eventSeat?: Maybe<Club_WorldFamilyClub_EventSeat>;
  eventType?: Maybe<Club_WorldFamilyClub_EventType>;
  faceCallAndTelephoneEnglish?: Maybe<Club_WorldFamilyClub_FaceCallAndTelephoneEnglish>;
  faceCallForTrialmode?: Maybe<Club_WorldFamilyClub_FaceCallForTrialmode>;
  faceCallHist?: Maybe<Club_WorldFamilyClub_FaceCallHist>;
  faceCallHistCount?: Maybe<Club_WorldFamilyClub_FaceCallHistCount>;
  faceCallMonthlyRecommend?: Maybe<Club_WorldFamilyClub_FaceCallMonthlyRecommend>;
  faceCallPerformer?: Maybe<Club_WorldFamilyClub_FaceCallPerformer>;
  faceCallSeasonHoliday?: Maybe<Club_WorldFamilyClub_FaceCallSeasonHoliday>;
  faceCallSong?: Maybe<Club_WorldFamilyClub_FaceCallSong>;
  faceCallSongAndPerformer?: Maybe<Club_WorldFamilyClub_FaceCallSongAndPerformer>;
  faceCallSongPublic?: Maybe<Club_WorldFamilyClub_FaceCallSongPublic>;
  goGoLiveDesktopQrcode?: Maybe<Club_WorldFamilyClub_GoGoLiveDesktopQrcode>;
  goGoLiveOpenDate?: Maybe<Club_WorldFamilyClub_GoGoLiveOpenDate>;
  goGoLivePromotion?: Maybe<Club_WorldFamilyClub_GoGoLivePromotion>;
  graduationApplication?: Maybe<Club_WorldFamilyClub_GraduationApplication>;
  graduationApplicationEvaluation?: Maybe<Club_WorldFamilyClub_GraduationApplicationEvaluation>;
  graduationApplicationSubTask?: Maybe<Club_WorldFamilyClub_GraduationApplicationSubTask>;
  graduationApplicationTask?: Maybe<Club_WorldFamilyClub_GraduationApplicationTask>;
  graduationChild?: Maybe<Club_WorldFamilyClub_GraduationChild>;
  graduationChildSetting?: Maybe<Club_WorldFamilyClub_GraduationChildSetting>;
  graduationEvaluation?: Maybe<Club_WorldFamilyClub_GraduationEvaluation>;
  graduationNotification?: Maybe<Club_WorldFamilyClub_GraduationNotification>;
  graduationSubApplication?: Maybe<Club_WorldFamilyClub_GraduationSubApplication>;
  graduationSubTaskMarketDescription?: Maybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescription>;
  graduationTaskMarketDescription?: Maybe<Club_WorldFamilyClub_GraduationTaskMarketDescription>;
  graphqlAccess?: Maybe<Club_WorldFamilyClub_GraphqlAccess>;
  graphqlSchema?: Maybe<Club_WorldFamilyClub_GraphqlSchema>;
  memberMessageSetting?: Maybe<Club_WorldFamilyClub_MemberMessageSetting>;
  myFavoriteFaceCall?: Maybe<Club_WorldFamilyClub_MyFavoriteFaceCall>;
  myFavoriteFaceCallSong?: Maybe<Club_WorldFamilyClub_MyFavoriteFaceCallSong>;
  notification?: Maybe<Club_WorldFamilyClub_Notification>;
  notificationIcon?: Maybe<Club_WorldFamilyClub_NotificationIcon>;
  oneTimeToken?: Maybe<Club_WorldFamilyClub_OneTimeToken>;
  onlineEventLog?: Maybe<Club_WorldFamilyClub_OnlineEventLog>;
  onlineShow?: Maybe<Club_WorldFamilyClub_OnlineShow>;
  parameter?: Maybe<Club_WorldFamilyClub_Parameter>;
  regionBanner?: Maybe<Club_WorldFamilyClub_RegionBanner>;
  searchKeyword?: Maybe<Club_WorldFamilyClub_SearchKeyword>;
  service?: Maybe<Club_WorldFamilyClub_Service>;
  serviceBannerDefault?: Maybe<Club_WorldFamilyClub_ServiceBannerDefault>;
  serviceBannerScheduled?: Maybe<Club_WorldFamilyClub_ServiceBannerScheduled>;
  serviceSearch?: Maybe<Club_WorldFamilyClub_ServiceSearch>;
  smsCertification?: Maybe<Club_WorldFamilyClub_SmsCertification>;
  smsVerificationCode?: Maybe<Club_WorldFamilyClub_SmsVerificationCode>;
  surveyTypeForm?: Maybe<Club_WorldFamilyClub_SurveyTypeForm>;
  surveyTypeFormList?: Maybe<Club_WorldFamilyClub_SurveyTypeFormList>;
  teSong?: Maybe<Club_WorldFamilyClub_TeSong>;
  temp20240408Graduation?: Maybe<Club_WorldFamilyClub_Temp20240408Graduation>;
  temp20240408Graduation1?: Maybe<Club_WorldFamilyClub_Temp20240408Graduation1>;
  tempFaceCall?: Maybe<Club_WorldFamilyClub_TempFaceCall>;
  tempFaceCallHk?: Maybe<Club_WorldFamilyClub_TempFaceCallHk>;
  tempFaceCallKr?: Maybe<Club_WorldFamilyClub_TempFaceCallKr>;
  tempHkAppMyFavorite?: Maybe<Club_WorldFamilyClub_TempHkAppMyFavorite>;
  tempHkClubappsFacecallCrew?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallCrew>;
  tempHkClubappsFacecallHistory?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallHistory>;
  tempHkClubappsFacecallSong?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallSong>;
  tempHkDmemChild?: Maybe<Club_WorldFamilyClub_TempHkDmemChild>;
  tempHkTeSong?: Maybe<Club_WorldFamilyClub_TempHkTeSong>;
  tempHkTematerial?: Maybe<Club_WorldFamilyClub_TempHkTematerial>;
  tempKrFaceCallHist?: Maybe<Club_WorldFamilyClub_TempKrFaceCallHist>;
  tempKrFcCrewM?: Maybe<Club_WorldFamilyClub_TempKrFcCrewM>;
  tempKrFcsongCrew?: Maybe<Club_WorldFamilyClub_TempKrFcsongCrew>;
  tempKrMyFavorite?: Maybe<Club_WorldFamilyClub_TempKrMyFavorite>;
  tempKrTeSong?: Maybe<Club_WorldFamilyClub_TempKrTeSong>;
  tempTwCambridge?: Maybe<Club_WorldFamilyClub_TempTwCambridge>;
  tempTwFaceCallHist?: Maybe<Club_WorldFamilyClub_TempTwFaceCallHist>;
  tempTwFcCrewM?: Maybe<Club_WorldFamilyClub_TempTwFcCrewM>;
  tempTwFcsongCrew?: Maybe<Club_WorldFamilyClub_TempTwFcsongCrew>;
  tempTwFcsongMonthlyRecommend?: Maybe<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommend>;
  tempTwMyFavorite?: Maybe<Club_WorldFamilyClub_TempTwMyFavorite>;
  tempTwTeSong?: Maybe<Club_WorldFamilyClub_TempTwTeSong>;
  tempWfcGraC?: Maybe<Club_WorldFamilyClub_TempWfcGraC>;
  tempWfcGraCOrganized?: Maybe<Club_WorldFamilyClub_TempWfcGraCOrganized>;
  testUserAccount?: Maybe<Club_WorldFamilyClub_TestUserAccount>;
  testWater?: Maybe<Club_WorldFamilyClub_TestWater>;
  testWaterSub?: Maybe<Club_WorldFamilyClub_TestWaterSub>;
  tev2MonthlySong?: Maybe<Club_WorldFamilyClub_Tev2MonthlySong>;
  user?: Maybe<Club_WorldFamilyClub_User>;
  videoForTrialmode?: Maybe<Club_WorldFamilyClub_VideoForTrialmode>;
};

export type Club_WorldFamilyClub_Mutation_AddInput = {
  appAuth?: InputMaybe<Club_WorldFamilyClub_AppAuthInput>;
  appBanner?: InputMaybe<Club_WorldFamilyClub_AppBannerInput>;
  appDigitalMagazine?: InputMaybe<Club_WorldFamilyClub_AppDigitalMagazineInput>;
  appMemberNotification?: InputMaybe<Club_WorldFamilyClub_AppMemberNotificationInput>;
  appNotification?: InputMaybe<Club_WorldFamilyClub_AppNotificationInput>;
  appNotificationMember?: InputMaybe<Club_WorldFamilyClub_AppNotificationMemberInput>;
  appNotificationType?: InputMaybe<Club_WorldFamilyClub_AppNotificationTypeInput>;
  appNotificationTypeMarket?: InputMaybe<Club_WorldFamilyClub_AppNotificationTypeMarketInput>;
  appRegionEmergency?: InputMaybe<Club_WorldFamilyClub_AppRegionEmergencyInput>;
  appRelease?: InputMaybe<Club_WorldFamilyClub_AppReleaseInput>;
  appServiceRegionFestivalImage?: InputMaybe<Club_WorldFamilyClub_AppServiceRegionFestivalImageInput>;
  appServiceRegionImage?: InputMaybe<Club_WorldFamilyClub_AppServiceRegionImageInput>;
  appSurvey?: InputMaybe<Club_WorldFamilyClub_AppSurveyInput>;
  appSurveyMember?: InputMaybe<Club_WorldFamilyClub_AppSurveyMemberInput>;
  appSurveyQa?: InputMaybe<Club_WorldFamilyClub_AppSurveyQaInput>;
  appSurveyReminder?: InputMaybe<Club_WorldFamilyClub_AppSurveyReminderInput>;
  azureFunction?: InputMaybe<Club_WorldFamilyClub_AzureFunctionInput>;
  broadcastPromotion?: InputMaybe<Club_WorldFamilyClub_BroadcastPromotionInput>;
  childGraduationApplication?: InputMaybe<Club_WorldFamilyClub_ChildGraduationApplicationInput>;
  dweProductPackage?: InputMaybe<Club_WorldFamilyClub_DweProductPackageInput>;
  dweProductSong?: InputMaybe<Club_WorldFamilyClub_DweProductSongInput>;
  dweProductSongTool?: InputMaybe<Club_WorldFamilyClub_DweProductSongToolInput>;
  dweProductTool?: InputMaybe<Club_WorldFamilyClub_DweProductToolInput>;
  eventBanner?: InputMaybe<Club_WorldFamilyClub_EventBannerInput>;
  eventBannerRegion?: InputMaybe<Club_WorldFamilyClub_EventBannerRegionInput>;
  eventCheckInAuth?: InputMaybe<Club_WorldFamilyClub_EventCheckInAuthInput>;
  eventNotificationLog?: InputMaybe<Club_WorldFamilyClub_EventNotificationLogInput>;
  eventSeat?: InputMaybe<Club_WorldFamilyClub_EventSeatInput>;
  eventType?: InputMaybe<Club_WorldFamilyClub_EventTypeInput>;
  faceCallAndTelephoneEnglish?: InputMaybe<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishInput>;
  faceCallForTrialmode?: InputMaybe<Club_WorldFamilyClub_FaceCallForTrialmodeInput>;
  faceCallHist?: InputMaybe<Club_WorldFamilyClub_FaceCallHistInput>;
  faceCallHistCount?: InputMaybe<Club_WorldFamilyClub_FaceCallHistCountInput>;
  faceCallMonthlyRecommend?: InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendInput>;
  faceCallPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallPerformerInput>;
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidayInput>;
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>;
  faceCallSongAndPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>;
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>;
  goGoLiveDesktopQrcode?: InputMaybe<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeInput>;
  goGoLiveOpenDate?: InputMaybe<Club_WorldFamilyClub_GoGoLiveOpenDateInput>;
  goGoLivePromotion?: InputMaybe<Club_WorldFamilyClub_GoGoLivePromotionInput>;
  graduationApplication?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationInput>;
  graduationApplicationEvaluation?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationInput>;
  graduationApplicationSubTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>;
  graduationApplicationTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskInput>;
  graduationChild?: InputMaybe<Club_WorldFamilyClub_GraduationChildInput>;
  graduationChildSetting?: InputMaybe<Club_WorldFamilyClub_GraduationChildSettingInput>;
  graduationEvaluation?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationInput>;
  graduationNotification?: InputMaybe<Club_WorldFamilyClub_GraduationNotificationInput>;
  graduationSubApplication?: InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationInput>;
  graduationSubTaskMarketDescription?: InputMaybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionInput>;
  graduationTaskMarketDescription?: InputMaybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionInput>;
  graphqlAccess?: InputMaybe<Club_WorldFamilyClub_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Club_WorldFamilyClub_GraphqlSchemaInput>;
  memberMessageSetting?: InputMaybe<Club_WorldFamilyClub_MemberMessageSettingInput>;
  myFavoriteFaceCall?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallInput>;
  myFavoriteFaceCallSong?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongInput>;
  notification?: InputMaybe<Club_WorldFamilyClub_NotificationInput>;
  notificationIcon?: InputMaybe<Club_WorldFamilyClub_NotificationIconInput>;
  oneTimeToken?: InputMaybe<Club_WorldFamilyClub_OneTimeTokenInput>;
  onlineEventLog?: InputMaybe<Club_WorldFamilyClub_OnlineEventLogInput>;
  onlineShow?: InputMaybe<Club_WorldFamilyClub_OnlineShowInput>;
  parameter?: InputMaybe<Club_WorldFamilyClub_ParameterInput>;
  regionBanner?: InputMaybe<Club_WorldFamilyClub_RegionBannerInput>;
  searchKeyword?: InputMaybe<Club_WorldFamilyClub_SearchKeywordInput>;
  service?: InputMaybe<Club_WorldFamilyClub_ServiceInput>;
  serviceBannerDefault?: InputMaybe<Club_WorldFamilyClub_ServiceBannerDefaultInput>;
  serviceBannerScheduled?: InputMaybe<Club_WorldFamilyClub_ServiceBannerScheduledInput>;
  serviceSearch?: InputMaybe<Club_WorldFamilyClub_ServiceSearchInput>;
  smsCertification?: InputMaybe<Club_WorldFamilyClub_SmsCertificationInput>;
  smsVerificationCode?: InputMaybe<Club_WorldFamilyClub_SmsVerificationCodeInput>;
  surveyTypeForm?: InputMaybe<Club_WorldFamilyClub_SurveyTypeFormInput>;
  surveyTypeFormList?: InputMaybe<Club_WorldFamilyClub_SurveyTypeFormListInput>;
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongInput>;
  temp20240408Graduation?: InputMaybe<Club_WorldFamilyClub_Temp20240408GraduationInput>;
  temp20240408Graduation1?: InputMaybe<Club_WorldFamilyClub_Temp20240408Graduation1Input>;
  tempFaceCall?: InputMaybe<Club_WorldFamilyClub_TempFaceCallInput>;
  tempFaceCallHk?: InputMaybe<Club_WorldFamilyClub_TempFaceCallHkInput>;
  tempFaceCallKr?: InputMaybe<Club_WorldFamilyClub_TempFaceCallKrInput>;
  tempHkAppMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempHkAppMyFavoriteInput>;
  tempHkClubappsFacecallCrew?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallCrewInput>;
  tempHkClubappsFacecallHistory?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryInput>;
  tempHkClubappsFacecallSong?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallSongInput>;
  tempHkDmemChild?: InputMaybe<Club_WorldFamilyClub_TempHkDmemChildInput>;
  tempHkTeSong?: InputMaybe<Club_WorldFamilyClub_TempHkTeSongInput>;
  tempHkTematerial?: InputMaybe<Club_WorldFamilyClub_TempHkTematerialInput>;
  tempKrFaceCallHist?: InputMaybe<Club_WorldFamilyClub_TempKrFaceCallHistInput>;
  tempKrFcCrewM?: InputMaybe<Club_WorldFamilyClub_TempKrFcCrewMInput>;
  tempKrFcsongCrew?: InputMaybe<Club_WorldFamilyClub_TempKrFcsongCrewInput>;
  tempKrMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempKrMyFavoriteInput>;
  tempKrTeSong?: InputMaybe<Club_WorldFamilyClub_TempKrTeSongInput>;
  tempTwCambridge?: InputMaybe<Club_WorldFamilyClub_TempTwCambridgeInput>;
  tempTwFaceCallHist?: InputMaybe<Club_WorldFamilyClub_TempTwFaceCallHistInput>;
  tempTwFcCrewM?: InputMaybe<Club_WorldFamilyClub_TempTwFcCrewMInput>;
  tempTwFcsongCrew?: InputMaybe<Club_WorldFamilyClub_TempTwFcsongCrewInput>;
  tempTwFcsongMonthlyRecommend?: InputMaybe<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendInput>;
  tempTwMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempTwMyFavoriteInput>;
  tempTwTeSong?: InputMaybe<Club_WorldFamilyClub_TempTwTeSongInput>;
  tempWfcGraC?: InputMaybe<Club_WorldFamilyClub_TempWfcGraCInput>;
  tempWfcGraCOrganized?: InputMaybe<Club_WorldFamilyClub_TempWfcGraCOrganizedInput>;
  testUserAccount?: InputMaybe<Club_WorldFamilyClub_TestUserAccountInput>;
  testWater?: InputMaybe<Club_WorldFamilyClub_TestWaterInput>;
  testWaterSub?: InputMaybe<Club_WorldFamilyClub_TestWaterSubInput>;
  tev2MonthlySong?: InputMaybe<Club_WorldFamilyClub_Tev2MonthlySongInput>;
  user?: InputMaybe<Club_WorldFamilyClub_UserInput>;
  videoForTrialmode?: InputMaybe<Club_WorldFamilyClub_VideoForTrialmodeInput>;
};

export type Club_WorldFamilyClub_Mutation_AddRange = {
  __typename?: 'Club_WorldFamilyClub_Mutation_AddRange';
  appAuths?: Maybe<Array<Club_WorldFamilyClub_AppAuth>>;
  appBanners?: Maybe<Array<Club_WorldFamilyClub_AppBanner>>;
  appDigitalMagazines?: Maybe<Array<Club_WorldFamilyClub_AppDigitalMagazine>>;
  appMemberNotifications?: Maybe<Array<Club_WorldFamilyClub_AppMemberNotification>>;
  appNotificationMembers?: Maybe<Array<Club_WorldFamilyClub_AppNotificationMember>>;
  appNotificationTypeMarkets?: Maybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarket>>;
  appNotificationTypes?: Maybe<Array<Club_WorldFamilyClub_AppNotificationType>>;
  appNotifications?: Maybe<Array<Club_WorldFamilyClub_AppNotification>>;
  appRegionEmergencies?: Maybe<Array<Club_WorldFamilyClub_AppRegionEmergency>>;
  appReleases?: Maybe<Array<Club_WorldFamilyClub_AppRelease>>;
  appServiceRegionFestivalImages?: Maybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImage>>;
  appServiceRegionImages?: Maybe<Array<Club_WorldFamilyClub_AppServiceRegionImage>>;
  appSurveyMembers?: Maybe<Array<Club_WorldFamilyClub_AppSurveyMember>>;
  appSurveyQas?: Maybe<Array<Club_WorldFamilyClub_AppSurveyQa>>;
  appSurveyReminders?: Maybe<Array<Club_WorldFamilyClub_AppSurveyReminder>>;
  appSurveys?: Maybe<Array<Club_WorldFamilyClub_AppSurvey>>;
  azureFunctions?: Maybe<Array<Club_WorldFamilyClub_AzureFunction>>;
  broadcastPromotions?: Maybe<Array<Club_WorldFamilyClub_BroadcastPromotion>>;
  childGraduationApplications?: Maybe<Array<Club_WorldFamilyClub_ChildGraduationApplication>>;
  dweProductPackages?: Maybe<Array<Club_WorldFamilyClub_DweProductPackage>>;
  dweProductSongTools?: Maybe<Array<Club_WorldFamilyClub_DweProductSongTool>>;
  dweProductSongs?: Maybe<Array<Club_WorldFamilyClub_DweProductSong>>;
  dweProductTools?: Maybe<Array<Club_WorldFamilyClub_DweProductTool>>;
  eventBannerRegions?: Maybe<Array<Club_WorldFamilyClub_EventBannerRegion>>;
  eventBanners?: Maybe<Array<Club_WorldFamilyClub_EventBanner>>;
  eventCheckInAuths?: Maybe<Array<Club_WorldFamilyClub_EventCheckInAuth>>;
  eventNotificationLogs?: Maybe<Array<Club_WorldFamilyClub_EventNotificationLog>>;
  eventSeats?: Maybe<Array<Club_WorldFamilyClub_EventSeat>>;
  eventTypes?: Maybe<Array<Club_WorldFamilyClub_EventType>>;
  faceCallAndTelephoneEnglishes?: Maybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglish>>;
  faceCallForTrialmodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallForTrialmode>>;
  faceCallHistCounts?: Maybe<Array<Club_WorldFamilyClub_FaceCallHistCount>>;
  faceCallHists?: Maybe<Array<Club_WorldFamilyClub_FaceCallHist>>;
  faceCallMonthlyRecommends?: Maybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommend>>;
  faceCallPerformers?: Maybe<Array<Club_WorldFamilyClub_FaceCallPerformer>>;
  faceCallSeasonHolidays?: Maybe<Array<Club_WorldFamilyClub_FaceCallSeasonHoliday>>;
  faceCallSongAndPerformers?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformer>>;
  faceCallSongPublics?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongPublic>>;
  faceCallSongs?: Maybe<Array<Club_WorldFamilyClub_FaceCallSong>>;
  goGoLiveDesktopQrcodes?: Maybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcode>>;
  goGoLiveOpenDates?: Maybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDate>>;
  goGoLivePromotions?: Maybe<Array<Club_WorldFamilyClub_GoGoLivePromotion>>;
  graduationApplicationEvaluations?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluation>>;
  graduationApplicationSubTasks?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTask>>;
  graduationApplicationTasks?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationTask>>;
  graduationApplications?: Maybe<Array<Club_WorldFamilyClub_GraduationApplication>>;
  graduationChildSettings?: Maybe<Array<Club_WorldFamilyClub_GraduationChildSetting>>;
  graduationChildren?: Maybe<Array<Club_WorldFamilyClub_GraduationChild>>;
  graduationEvaluations?: Maybe<Array<Club_WorldFamilyClub_GraduationEvaluation>>;
  graduationNotifications?: Maybe<Array<Club_WorldFamilyClub_GraduationNotification>>;
  graduationSubApplications?: Maybe<Array<Club_WorldFamilyClub_GraduationSubApplication>>;
  graduationSubTaskMarketDescriptions?: Maybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescription>>;
  graduationTaskMarketDescriptions?: Maybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescription>>;
  graphqlAccesses?: Maybe<Array<Club_WorldFamilyClub_GraphqlAccess>>;
  graphqlSchemas?: Maybe<Array<Club_WorldFamilyClub_GraphqlSchema>>;
  memberMessageSettings?: Maybe<Array<Club_WorldFamilyClub_MemberMessageSetting>>;
  myFavoriteFaceCallSongs?: Maybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSong>>;
  myFavoriteFaceCalls?: Maybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCall>>;
  notificationIcons?: Maybe<Array<Club_WorldFamilyClub_NotificationIcon>>;
  notifications?: Maybe<Array<Club_WorldFamilyClub_Notification>>;
  oneTimeTokens?: Maybe<Array<Club_WorldFamilyClub_OneTimeToken>>;
  onlineEventLogs?: Maybe<Array<Club_WorldFamilyClub_OnlineEventLog>>;
  onlineShows?: Maybe<Array<Club_WorldFamilyClub_OnlineShow>>;
  parameters?: Maybe<Array<Club_WorldFamilyClub_Parameter>>;
  regionBanners?: Maybe<Array<Club_WorldFamilyClub_RegionBanner>>;
  searchKeywords?: Maybe<Array<Club_WorldFamilyClub_SearchKeyword>>;
  serviceBannerDefaults?: Maybe<Array<Club_WorldFamilyClub_ServiceBannerDefault>>;
  serviceBannerScheduleds?: Maybe<Array<Club_WorldFamilyClub_ServiceBannerScheduled>>;
  serviceSearches?: Maybe<Array<Club_WorldFamilyClub_ServiceSearch>>;
  services?: Maybe<Array<Club_WorldFamilyClub_Service>>;
  smsCertifications?: Maybe<Array<Club_WorldFamilyClub_SmsCertification>>;
  smsVerificationCodes?: Maybe<Array<Club_WorldFamilyClub_SmsVerificationCode>>;
  surveyTypeFormLists?: Maybe<Array<Club_WorldFamilyClub_SurveyTypeFormList>>;
  surveyTypeForms?: Maybe<Array<Club_WorldFamilyClub_SurveyTypeForm>>;
  teSongs?: Maybe<Array<Club_WorldFamilyClub_TeSong>>;
  temp20240408Graduation1s?: Maybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1>>;
  temp20240408Graduations?: Maybe<Array<Club_WorldFamilyClub_Temp20240408Graduation>>;
  tempFaceCallHks?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallHk>>;
  tempFaceCallKrs?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallKr>>;
  tempFaceCalls?: Maybe<Array<Club_WorldFamilyClub_TempFaceCall>>;
  tempHkAppMyFavorites?: Maybe<Array<Club_WorldFamilyClub_TempHkAppMyFavorite>>;
  tempHkClubappsFacecallCrews?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrew>>;
  tempHkClubappsFacecallHistories?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistory>>;
  tempHkClubappsFacecallSongs?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSong>>;
  tempHkDmemChildren?: Maybe<Array<Club_WorldFamilyClub_TempHkDmemChild>>;
  tempHkTeSongs?: Maybe<Array<Club_WorldFamilyClub_TempHkTeSong>>;
  tempHkTematerials?: Maybe<Array<Club_WorldFamilyClub_TempHkTematerial>>;
  tempKrFaceCallHists?: Maybe<Array<Club_WorldFamilyClub_TempKrFaceCallHist>>;
  tempKrFcCrewMs?: Maybe<Array<Club_WorldFamilyClub_TempKrFcCrewM>>;
  tempKrFcsongCrews?: Maybe<Array<Club_WorldFamilyClub_TempKrFcsongCrew>>;
  tempKrMyFavorites?: Maybe<Array<Club_WorldFamilyClub_TempKrMyFavorite>>;
  tempKrTeSongs?: Maybe<Array<Club_WorldFamilyClub_TempKrTeSong>>;
  tempTwCambridges?: Maybe<Array<Club_WorldFamilyClub_TempTwCambridge>>;
  tempTwFaceCallHists?: Maybe<Array<Club_WorldFamilyClub_TempTwFaceCallHist>>;
  tempTwFcCrewMs?: Maybe<Array<Club_WorldFamilyClub_TempTwFcCrewM>>;
  tempTwFcsongCrews?: Maybe<Array<Club_WorldFamilyClub_TempTwFcsongCrew>>;
  tempTwFcsongMonthlyRecommends?: Maybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommend>>;
  tempTwMyFavorites?: Maybe<Array<Club_WorldFamilyClub_TempTwMyFavorite>>;
  tempTwTeSongs?: Maybe<Array<Club_WorldFamilyClub_TempTwTeSong>>;
  tempWfcGraCOrganizeds?: Maybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganized>>;
  tempWfcGraCs?: Maybe<Array<Club_WorldFamilyClub_TempWfcGraC>>;
  testUserAccounts?: Maybe<Array<Club_WorldFamilyClub_TestUserAccount>>;
  testWaterSubs?: Maybe<Array<Club_WorldFamilyClub_TestWaterSub>>;
  testWaters?: Maybe<Array<Club_WorldFamilyClub_TestWater>>;
  tev2MonthlySongs?: Maybe<Array<Club_WorldFamilyClub_Tev2MonthlySong>>;
  users?: Maybe<Array<Club_WorldFamilyClub_User>>;
  videoForTrialmodes?: Maybe<Array<Club_WorldFamilyClub_VideoForTrialmode>>;
};

export type Club_WorldFamilyClub_Mutation_AddRangeInput = {
  appAuths?: InputMaybe<Array<Club_WorldFamilyClub_AppAuthInput>>;
  appBanners?: InputMaybe<Array<Club_WorldFamilyClub_AppBannerInput>>;
  appDigitalMagazines?: InputMaybe<Array<Club_WorldFamilyClub_AppDigitalMagazineInput>>;
  appMemberNotifications?: InputMaybe<Array<Club_WorldFamilyClub_AppMemberNotificationInput>>;
  appNotificationMembers?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationMemberInput>>;
  appNotificationTypeMarkets?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarketInput>>;
  appNotificationTypes?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeInput>>;
  appNotifications?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationInput>>;
  appRegionEmergencies?: InputMaybe<Array<Club_WorldFamilyClub_AppRegionEmergencyInput>>;
  appReleases?: InputMaybe<Array<Club_WorldFamilyClub_AppReleaseInput>>;
  appServiceRegionFestivalImages?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImageInput>>;
  appServiceRegionImages?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionImageInput>>;
  appSurveyMembers?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyMemberInput>>;
  appSurveyQas?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyQaInput>>;
  appSurveyReminders?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyReminderInput>>;
  appSurveys?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyInput>>;
  azureFunctions?: InputMaybe<Array<Club_WorldFamilyClub_AzureFunctionInput>>;
  broadcastPromotions?: InputMaybe<Array<Club_WorldFamilyClub_BroadcastPromotionInput>>;
  childGraduationApplications?: InputMaybe<Array<Club_WorldFamilyClub_ChildGraduationApplicationInput>>;
  dweProductPackages?: InputMaybe<Array<Club_WorldFamilyClub_DweProductPackageInput>>;
  dweProductSongTools?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongToolInput>>;
  dweProductSongs?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongInput>>;
  dweProductTools?: InputMaybe<Array<Club_WorldFamilyClub_DweProductToolInput>>;
  eventBannerRegions?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerRegionInput>>;
  eventBanners?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerInput>>;
  eventCheckInAuths?: InputMaybe<Array<Club_WorldFamilyClub_EventCheckInAuthInput>>;
  eventNotificationLogs?: InputMaybe<Array<Club_WorldFamilyClub_EventNotificationLogInput>>;
  eventSeats?: InputMaybe<Array<Club_WorldFamilyClub_EventSeatInput>>;
  eventTypes?: InputMaybe<Array<Club_WorldFamilyClub_EventTypeInput>>;
  faceCallAndTelephoneEnglishes?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishInput>>;
  faceCallForTrialmodes?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallForTrialmodeInput>>;
  faceCallHistCounts?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistCountInput>>;
  faceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistInput>>;
  faceCallMonthlyRecommends?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommendInput>>;
  faceCallPerformers?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallPerformerInput>>;
  faceCallSeasonHolidays?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSeasonHolidayInput>>;
  faceCallSongAndPerformers?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>>;
  faceCallSongPublics?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongPublicInput>>;
  faceCallSongs?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongInput>>;
  goGoLiveDesktopQrcodes?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeInput>>;
  goGoLiveOpenDates?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDateInput>>;
  goGoLivePromotions?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLivePromotionInput>>;
  graduationApplicationEvaluations?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluationInput>>;
  graduationApplicationSubTasks?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>>;
  graduationApplicationTasks?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationTaskInput>>;
  graduationApplications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationInput>>;
  graduationChildSettings?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildSettingInput>>;
  graduationChildren?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildInput>>;
  graduationEvaluations?: InputMaybe<Array<Club_WorldFamilyClub_GraduationEvaluationInput>>;
  graduationNotifications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationNotificationInput>>;
  graduationSubApplications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubApplicationInput>>;
  graduationSubTaskMarketDescriptions?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionInput>>;
  graduationTaskMarketDescriptions?: InputMaybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescriptionInput>>;
  graphqlAccesses?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlSchemaInput>>;
  memberMessageSettings?: InputMaybe<Array<Club_WorldFamilyClub_MemberMessageSettingInput>>;
  myFavoriteFaceCallSongs?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSongInput>>;
  myFavoriteFaceCalls?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallInput>>;
  notificationIcons?: InputMaybe<Array<Club_WorldFamilyClub_NotificationIconInput>>;
  notifications?: InputMaybe<Array<Club_WorldFamilyClub_NotificationInput>>;
  oneTimeTokens?: InputMaybe<Array<Club_WorldFamilyClub_OneTimeTokenInput>>;
  onlineEventLogs?: InputMaybe<Array<Club_WorldFamilyClub_OnlineEventLogInput>>;
  onlineShows?: InputMaybe<Array<Club_WorldFamilyClub_OnlineShowInput>>;
  parameters?: InputMaybe<Array<Club_WorldFamilyClub_ParameterInput>>;
  regionBanners?: InputMaybe<Array<Club_WorldFamilyClub_RegionBannerInput>>;
  searchKeywords?: InputMaybe<Array<Club_WorldFamilyClub_SearchKeywordInput>>;
  serviceBannerDefaults?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerDefaultInput>>;
  serviceBannerScheduleds?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerScheduledInput>>;
  serviceSearches?: InputMaybe<Array<Club_WorldFamilyClub_ServiceSearchInput>>;
  services?: InputMaybe<Array<Club_WorldFamilyClub_ServiceInput>>;
  smsCertifications?: InputMaybe<Array<Club_WorldFamilyClub_SmsCertificationInput>>;
  smsVerificationCodes?: InputMaybe<Array<Club_WorldFamilyClub_SmsVerificationCodeInput>>;
  surveyTypeFormLists?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormListInput>>;
  surveyTypeForms?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormInput>>;
  teSongs?: InputMaybe<Array<Club_WorldFamilyClub_TeSongInput>>;
  temp20240408Graduation1s?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1Input>>;
  temp20240408Graduations?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408GraduationInput>>;
  tempFaceCallHks?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallHkInput>>;
  tempFaceCallKrs?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallKrInput>>;
  tempFaceCalls?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallInput>>;
  tempHkAppMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempHkAppMyFavoriteInput>>;
  tempHkClubappsFacecallCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrewInput>>;
  tempHkClubappsFacecallHistories?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryInput>>;
  tempHkClubappsFacecallSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSongInput>>;
  tempHkDmemChildren?: InputMaybe<Array<Club_WorldFamilyClub_TempHkDmemChildInput>>;
  tempHkTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTeSongInput>>;
  tempHkTematerials?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTematerialInput>>;
  tempKrFaceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFaceCallHistInput>>;
  tempKrFcCrewMs?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcCrewMInput>>;
  tempKrFcsongCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcsongCrewInput>>;
  tempKrMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempKrMyFavoriteInput>>;
  tempKrTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempKrTeSongInput>>;
  tempTwCambridges?: InputMaybe<Array<Club_WorldFamilyClub_TempTwCambridgeInput>>;
  tempTwFaceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFaceCallHistInput>>;
  tempTwFcCrewMs?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcCrewMInput>>;
  tempTwFcsongCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongCrewInput>>;
  tempTwFcsongMonthlyRecommends?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendInput>>;
  tempTwMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempTwMyFavoriteInput>>;
  tempTwTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempTwTeSongInput>>;
  tempWfcGraCOrganizeds?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganizedInput>>;
  tempWfcGraCs?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCInput>>;
  testUserAccounts?: InputMaybe<Array<Club_WorldFamilyClub_TestUserAccountInput>>;
  testWaterSubs?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterSubInput>>;
  testWaters?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterInput>>;
  tev2MonthlySongs?: InputMaybe<Array<Club_WorldFamilyClub_Tev2MonthlySongInput>>;
  users?: InputMaybe<Array<Club_WorldFamilyClub_UserInput>>;
  videoForTrialmodes?: InputMaybe<Array<Club_WorldFamilyClub_VideoForTrialmodeInput>>;
};

export type Club_WorldFamilyClub_Mutation_Payload = {
  __typename?: 'Club_WorldFamilyClub_Mutation_Payload';
  addContext?: Maybe<Club_WorldFamilyClub_Mutation_Add>;
  addRangeContext?: Maybe<Club_WorldFamilyClub_Mutation_AddRange>;
  count: Scalars['Int']['output'];
  query: Query;
  updateContext?: Maybe<Club_WorldFamilyClub_Mutation_Update>;
  updateRangeContext?: Maybe<Club_WorldFamilyClub_Mutation_UpdateRange>;
};

export type Club_WorldFamilyClub_Mutation_RemoveInput = {
  appAuth?: InputMaybe<Club_WorldFamilyClub_AppAuthInput>;
  appBanner?: InputMaybe<Club_WorldFamilyClub_AppBannerInput>;
  appDigitalMagazine?: InputMaybe<Club_WorldFamilyClub_AppDigitalMagazineInput>;
  appMemberNotification?: InputMaybe<Club_WorldFamilyClub_AppMemberNotificationInput>;
  appNotification?: InputMaybe<Club_WorldFamilyClub_AppNotificationInput>;
  appNotificationMember?: InputMaybe<Club_WorldFamilyClub_AppNotificationMemberInput>;
  appNotificationType?: InputMaybe<Club_WorldFamilyClub_AppNotificationTypeInput>;
  appNotificationTypeMarket?: InputMaybe<Club_WorldFamilyClub_AppNotificationTypeMarketInput>;
  appRegionEmergency?: InputMaybe<Club_WorldFamilyClub_AppRegionEmergencyInput>;
  appRelease?: InputMaybe<Club_WorldFamilyClub_AppReleaseInput>;
  appServiceRegionFestivalImage?: InputMaybe<Club_WorldFamilyClub_AppServiceRegionFestivalImageInput>;
  appServiceRegionImage?: InputMaybe<Club_WorldFamilyClub_AppServiceRegionImageInput>;
  appSurvey?: InputMaybe<Club_WorldFamilyClub_AppSurveyInput>;
  appSurveyMember?: InputMaybe<Club_WorldFamilyClub_AppSurveyMemberInput>;
  appSurveyQa?: InputMaybe<Club_WorldFamilyClub_AppSurveyQaInput>;
  appSurveyReminder?: InputMaybe<Club_WorldFamilyClub_AppSurveyReminderInput>;
  azureFunction?: InputMaybe<Club_WorldFamilyClub_AzureFunctionInput>;
  broadcastPromotion?: InputMaybe<Club_WorldFamilyClub_BroadcastPromotionInput>;
  childGraduationApplication?: InputMaybe<Club_WorldFamilyClub_ChildGraduationApplicationInput>;
  dweProductPackage?: InputMaybe<Club_WorldFamilyClub_DweProductPackageInput>;
  dweProductSong?: InputMaybe<Club_WorldFamilyClub_DweProductSongInput>;
  dweProductSongTool?: InputMaybe<Club_WorldFamilyClub_DweProductSongToolInput>;
  dweProductTool?: InputMaybe<Club_WorldFamilyClub_DweProductToolInput>;
  eventBanner?: InputMaybe<Club_WorldFamilyClub_EventBannerInput>;
  eventBannerRegion?: InputMaybe<Club_WorldFamilyClub_EventBannerRegionInput>;
  eventCheckInAuth?: InputMaybe<Club_WorldFamilyClub_EventCheckInAuthInput>;
  eventNotificationLog?: InputMaybe<Club_WorldFamilyClub_EventNotificationLogInput>;
  eventSeat?: InputMaybe<Club_WorldFamilyClub_EventSeatInput>;
  eventType?: InputMaybe<Club_WorldFamilyClub_EventTypeInput>;
  faceCallAndTelephoneEnglish?: InputMaybe<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishInput>;
  faceCallForTrialmode?: InputMaybe<Club_WorldFamilyClub_FaceCallForTrialmodeInput>;
  faceCallHist?: InputMaybe<Club_WorldFamilyClub_FaceCallHistInput>;
  faceCallHistCount?: InputMaybe<Club_WorldFamilyClub_FaceCallHistCountInput>;
  faceCallMonthlyRecommend?: InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendInput>;
  faceCallPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallPerformerInput>;
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidayInput>;
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>;
  faceCallSongAndPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>;
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>;
  goGoLiveDesktopQrcode?: InputMaybe<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeInput>;
  goGoLiveOpenDate?: InputMaybe<Club_WorldFamilyClub_GoGoLiveOpenDateInput>;
  goGoLivePromotion?: InputMaybe<Club_WorldFamilyClub_GoGoLivePromotionInput>;
  graduationApplication?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationInput>;
  graduationApplicationEvaluation?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationInput>;
  graduationApplicationSubTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>;
  graduationApplicationTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskInput>;
  graduationChild?: InputMaybe<Club_WorldFamilyClub_GraduationChildInput>;
  graduationChildSetting?: InputMaybe<Club_WorldFamilyClub_GraduationChildSettingInput>;
  graduationEvaluation?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationInput>;
  graduationNotification?: InputMaybe<Club_WorldFamilyClub_GraduationNotificationInput>;
  graduationSubApplication?: InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationInput>;
  graduationSubTaskMarketDescription?: InputMaybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionInput>;
  graduationTaskMarketDescription?: InputMaybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionInput>;
  graphqlAccess?: InputMaybe<Club_WorldFamilyClub_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Club_WorldFamilyClub_GraphqlSchemaInput>;
  memberMessageSetting?: InputMaybe<Club_WorldFamilyClub_MemberMessageSettingInput>;
  myFavoriteFaceCall?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallInput>;
  myFavoriteFaceCallSong?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongInput>;
  notification?: InputMaybe<Club_WorldFamilyClub_NotificationInput>;
  notificationIcon?: InputMaybe<Club_WorldFamilyClub_NotificationIconInput>;
  oneTimeToken?: InputMaybe<Club_WorldFamilyClub_OneTimeTokenInput>;
  onlineEventLog?: InputMaybe<Club_WorldFamilyClub_OnlineEventLogInput>;
  onlineShow?: InputMaybe<Club_WorldFamilyClub_OnlineShowInput>;
  parameter?: InputMaybe<Club_WorldFamilyClub_ParameterInput>;
  regionBanner?: InputMaybe<Club_WorldFamilyClub_RegionBannerInput>;
  searchKeyword?: InputMaybe<Club_WorldFamilyClub_SearchKeywordInput>;
  service?: InputMaybe<Club_WorldFamilyClub_ServiceInput>;
  serviceBannerDefault?: InputMaybe<Club_WorldFamilyClub_ServiceBannerDefaultInput>;
  serviceBannerScheduled?: InputMaybe<Club_WorldFamilyClub_ServiceBannerScheduledInput>;
  serviceSearch?: InputMaybe<Club_WorldFamilyClub_ServiceSearchInput>;
  smsCertification?: InputMaybe<Club_WorldFamilyClub_SmsCertificationInput>;
  smsVerificationCode?: InputMaybe<Club_WorldFamilyClub_SmsVerificationCodeInput>;
  surveyTypeForm?: InputMaybe<Club_WorldFamilyClub_SurveyTypeFormInput>;
  surveyTypeFormList?: InputMaybe<Club_WorldFamilyClub_SurveyTypeFormListInput>;
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongInput>;
  temp20240408Graduation?: InputMaybe<Club_WorldFamilyClub_Temp20240408GraduationInput>;
  temp20240408Graduation1?: InputMaybe<Club_WorldFamilyClub_Temp20240408Graduation1Input>;
  tempFaceCall?: InputMaybe<Club_WorldFamilyClub_TempFaceCallInput>;
  tempFaceCallHk?: InputMaybe<Club_WorldFamilyClub_TempFaceCallHkInput>;
  tempFaceCallKr?: InputMaybe<Club_WorldFamilyClub_TempFaceCallKrInput>;
  tempHkAppMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempHkAppMyFavoriteInput>;
  tempHkClubappsFacecallCrew?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallCrewInput>;
  tempHkClubappsFacecallHistory?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryInput>;
  tempHkClubappsFacecallSong?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallSongInput>;
  tempHkDmemChild?: InputMaybe<Club_WorldFamilyClub_TempHkDmemChildInput>;
  tempHkTeSong?: InputMaybe<Club_WorldFamilyClub_TempHkTeSongInput>;
  tempHkTematerial?: InputMaybe<Club_WorldFamilyClub_TempHkTematerialInput>;
  tempKrFaceCallHist?: InputMaybe<Club_WorldFamilyClub_TempKrFaceCallHistInput>;
  tempKrFcCrewM?: InputMaybe<Club_WorldFamilyClub_TempKrFcCrewMInput>;
  tempKrFcsongCrew?: InputMaybe<Club_WorldFamilyClub_TempKrFcsongCrewInput>;
  tempKrMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempKrMyFavoriteInput>;
  tempKrTeSong?: InputMaybe<Club_WorldFamilyClub_TempKrTeSongInput>;
  tempTwCambridge?: InputMaybe<Club_WorldFamilyClub_TempTwCambridgeInput>;
  tempTwFaceCallHist?: InputMaybe<Club_WorldFamilyClub_TempTwFaceCallHistInput>;
  tempTwFcCrewM?: InputMaybe<Club_WorldFamilyClub_TempTwFcCrewMInput>;
  tempTwFcsongCrew?: InputMaybe<Club_WorldFamilyClub_TempTwFcsongCrewInput>;
  tempTwFcsongMonthlyRecommend?: InputMaybe<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendInput>;
  tempTwMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempTwMyFavoriteInput>;
  tempTwTeSong?: InputMaybe<Club_WorldFamilyClub_TempTwTeSongInput>;
  tempWfcGraC?: InputMaybe<Club_WorldFamilyClub_TempWfcGraCInput>;
  tempWfcGraCOrganized?: InputMaybe<Club_WorldFamilyClub_TempWfcGraCOrganizedInput>;
  testUserAccount?: InputMaybe<Club_WorldFamilyClub_TestUserAccountInput>;
  testWater?: InputMaybe<Club_WorldFamilyClub_TestWaterInput>;
  testWaterSub?: InputMaybe<Club_WorldFamilyClub_TestWaterSubInput>;
  tev2MonthlySong?: InputMaybe<Club_WorldFamilyClub_Tev2MonthlySongInput>;
  user?: InputMaybe<Club_WorldFamilyClub_UserInput>;
  videoForTrialmode?: InputMaybe<Club_WorldFamilyClub_VideoForTrialmodeInput>;
};

export type Club_WorldFamilyClub_Mutation_RemoveRangeInput = {
  appAuths?: InputMaybe<Array<Club_WorldFamilyClub_AppAuthInput>>;
  appBanners?: InputMaybe<Array<Club_WorldFamilyClub_AppBannerInput>>;
  appDigitalMagazines?: InputMaybe<Array<Club_WorldFamilyClub_AppDigitalMagazineInput>>;
  appMemberNotifications?: InputMaybe<Array<Club_WorldFamilyClub_AppMemberNotificationInput>>;
  appNotificationMembers?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationMemberInput>>;
  appNotificationTypeMarkets?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarketInput>>;
  appNotificationTypes?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeInput>>;
  appNotifications?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationInput>>;
  appRegionEmergencies?: InputMaybe<Array<Club_WorldFamilyClub_AppRegionEmergencyInput>>;
  appReleases?: InputMaybe<Array<Club_WorldFamilyClub_AppReleaseInput>>;
  appServiceRegionFestivalImages?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImageInput>>;
  appServiceRegionImages?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionImageInput>>;
  appSurveyMembers?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyMemberInput>>;
  appSurveyQas?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyQaInput>>;
  appSurveyReminders?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyReminderInput>>;
  appSurveys?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyInput>>;
  azureFunctions?: InputMaybe<Array<Club_WorldFamilyClub_AzureFunctionInput>>;
  broadcastPromotions?: InputMaybe<Array<Club_WorldFamilyClub_BroadcastPromotionInput>>;
  childGraduationApplications?: InputMaybe<Array<Club_WorldFamilyClub_ChildGraduationApplicationInput>>;
  dweProductPackages?: InputMaybe<Array<Club_WorldFamilyClub_DweProductPackageInput>>;
  dweProductSongTools?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongToolInput>>;
  dweProductSongs?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongInput>>;
  dweProductTools?: InputMaybe<Array<Club_WorldFamilyClub_DweProductToolInput>>;
  eventBannerRegions?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerRegionInput>>;
  eventBanners?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerInput>>;
  eventCheckInAuths?: InputMaybe<Array<Club_WorldFamilyClub_EventCheckInAuthInput>>;
  eventNotificationLogs?: InputMaybe<Array<Club_WorldFamilyClub_EventNotificationLogInput>>;
  eventSeats?: InputMaybe<Array<Club_WorldFamilyClub_EventSeatInput>>;
  eventTypes?: InputMaybe<Array<Club_WorldFamilyClub_EventTypeInput>>;
  faceCallAndTelephoneEnglishes?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishInput>>;
  faceCallForTrialmodes?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallForTrialmodeInput>>;
  faceCallHistCounts?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistCountInput>>;
  faceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistInput>>;
  faceCallMonthlyRecommends?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommendInput>>;
  faceCallPerformers?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallPerformerInput>>;
  faceCallSeasonHolidays?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSeasonHolidayInput>>;
  faceCallSongAndPerformers?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>>;
  faceCallSongPublics?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongPublicInput>>;
  faceCallSongs?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongInput>>;
  goGoLiveDesktopQrcodes?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeInput>>;
  goGoLiveOpenDates?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDateInput>>;
  goGoLivePromotions?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLivePromotionInput>>;
  graduationApplicationEvaluations?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluationInput>>;
  graduationApplicationSubTasks?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>>;
  graduationApplicationTasks?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationTaskInput>>;
  graduationApplications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationInput>>;
  graduationChildSettings?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildSettingInput>>;
  graduationChildren?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildInput>>;
  graduationEvaluations?: InputMaybe<Array<Club_WorldFamilyClub_GraduationEvaluationInput>>;
  graduationNotifications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationNotificationInput>>;
  graduationSubApplications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubApplicationInput>>;
  graduationSubTaskMarketDescriptions?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionInput>>;
  graduationTaskMarketDescriptions?: InputMaybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescriptionInput>>;
  graphqlAccesses?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlSchemaInput>>;
  memberMessageSettings?: InputMaybe<Array<Club_WorldFamilyClub_MemberMessageSettingInput>>;
  myFavoriteFaceCallSongs?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSongInput>>;
  myFavoriteFaceCalls?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallInput>>;
  notificationIcons?: InputMaybe<Array<Club_WorldFamilyClub_NotificationIconInput>>;
  notifications?: InputMaybe<Array<Club_WorldFamilyClub_NotificationInput>>;
  oneTimeTokens?: InputMaybe<Array<Club_WorldFamilyClub_OneTimeTokenInput>>;
  onlineEventLogs?: InputMaybe<Array<Club_WorldFamilyClub_OnlineEventLogInput>>;
  onlineShows?: InputMaybe<Array<Club_WorldFamilyClub_OnlineShowInput>>;
  parameters?: InputMaybe<Array<Club_WorldFamilyClub_ParameterInput>>;
  regionBanners?: InputMaybe<Array<Club_WorldFamilyClub_RegionBannerInput>>;
  searchKeywords?: InputMaybe<Array<Club_WorldFamilyClub_SearchKeywordInput>>;
  serviceBannerDefaults?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerDefaultInput>>;
  serviceBannerScheduleds?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerScheduledInput>>;
  serviceSearches?: InputMaybe<Array<Club_WorldFamilyClub_ServiceSearchInput>>;
  services?: InputMaybe<Array<Club_WorldFamilyClub_ServiceInput>>;
  smsCertifications?: InputMaybe<Array<Club_WorldFamilyClub_SmsCertificationInput>>;
  smsVerificationCodes?: InputMaybe<Array<Club_WorldFamilyClub_SmsVerificationCodeInput>>;
  surveyTypeFormLists?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormListInput>>;
  surveyTypeForms?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormInput>>;
  teSongs?: InputMaybe<Array<Club_WorldFamilyClub_TeSongInput>>;
  temp20240408Graduation1s?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1Input>>;
  temp20240408Graduations?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408GraduationInput>>;
  tempFaceCallHks?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallHkInput>>;
  tempFaceCallKrs?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallKrInput>>;
  tempFaceCalls?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallInput>>;
  tempHkAppMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempHkAppMyFavoriteInput>>;
  tempHkClubappsFacecallCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrewInput>>;
  tempHkClubappsFacecallHistories?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryInput>>;
  tempHkClubappsFacecallSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSongInput>>;
  tempHkDmemChildren?: InputMaybe<Array<Club_WorldFamilyClub_TempHkDmemChildInput>>;
  tempHkTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTeSongInput>>;
  tempHkTematerials?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTematerialInput>>;
  tempKrFaceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFaceCallHistInput>>;
  tempKrFcCrewMs?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcCrewMInput>>;
  tempKrFcsongCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcsongCrewInput>>;
  tempKrMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempKrMyFavoriteInput>>;
  tempKrTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempKrTeSongInput>>;
  tempTwCambridges?: InputMaybe<Array<Club_WorldFamilyClub_TempTwCambridgeInput>>;
  tempTwFaceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFaceCallHistInput>>;
  tempTwFcCrewMs?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcCrewMInput>>;
  tempTwFcsongCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongCrewInput>>;
  tempTwFcsongMonthlyRecommends?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendInput>>;
  tempTwMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempTwMyFavoriteInput>>;
  tempTwTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempTwTeSongInput>>;
  tempWfcGraCOrganizeds?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganizedInput>>;
  tempWfcGraCs?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCInput>>;
  testUserAccounts?: InputMaybe<Array<Club_WorldFamilyClub_TestUserAccountInput>>;
  testWaterSubs?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterSubInput>>;
  testWaters?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterInput>>;
  tev2MonthlySongs?: InputMaybe<Array<Club_WorldFamilyClub_Tev2MonthlySongInput>>;
  users?: InputMaybe<Array<Club_WorldFamilyClub_UserInput>>;
  videoForTrialmodes?: InputMaybe<Array<Club_WorldFamilyClub_VideoForTrialmodeInput>>;
};

export type Club_WorldFamilyClub_Mutation_Update = {
  __typename?: 'Club_WorldFamilyClub_Mutation_Update';
  appAuth?: Maybe<Club_WorldFamilyClub_AppAuth>;
  appBanner?: Maybe<Club_WorldFamilyClub_AppBanner>;
  appDigitalMagazine?: Maybe<Club_WorldFamilyClub_AppDigitalMagazine>;
  appMemberNotification?: Maybe<Club_WorldFamilyClub_AppMemberNotification>;
  appNotification?: Maybe<Club_WorldFamilyClub_AppNotification>;
  appNotificationMember?: Maybe<Club_WorldFamilyClub_AppNotificationMember>;
  appNotificationType?: Maybe<Club_WorldFamilyClub_AppNotificationType>;
  appNotificationTypeMarket?: Maybe<Club_WorldFamilyClub_AppNotificationTypeMarket>;
  appRegionEmergency?: Maybe<Club_WorldFamilyClub_AppRegionEmergency>;
  appRelease?: Maybe<Club_WorldFamilyClub_AppRelease>;
  appServiceRegionFestivalImage?: Maybe<Club_WorldFamilyClub_AppServiceRegionFestivalImage>;
  appServiceRegionImage?: Maybe<Club_WorldFamilyClub_AppServiceRegionImage>;
  appSurvey?: Maybe<Club_WorldFamilyClub_AppSurvey>;
  appSurveyMember?: Maybe<Club_WorldFamilyClub_AppSurveyMember>;
  appSurveyQa?: Maybe<Club_WorldFamilyClub_AppSurveyQa>;
  appSurveyReminder?: Maybe<Club_WorldFamilyClub_AppSurveyReminder>;
  azureFunction?: Maybe<Club_WorldFamilyClub_AzureFunction>;
  broadcastPromotion?: Maybe<Club_WorldFamilyClub_BroadcastPromotion>;
  childGraduationApplication?: Maybe<Club_WorldFamilyClub_ChildGraduationApplication>;
  dweProductPackage?: Maybe<Club_WorldFamilyClub_DweProductPackage>;
  dweProductSong?: Maybe<Club_WorldFamilyClub_DweProductSong>;
  dweProductSongTool?: Maybe<Club_WorldFamilyClub_DweProductSongTool>;
  dweProductTool?: Maybe<Club_WorldFamilyClub_DweProductTool>;
  eventBanner?: Maybe<Club_WorldFamilyClub_EventBanner>;
  eventBannerRegion?: Maybe<Club_WorldFamilyClub_EventBannerRegion>;
  eventCheckInAuth?: Maybe<Club_WorldFamilyClub_EventCheckInAuth>;
  eventNotificationLog?: Maybe<Club_WorldFamilyClub_EventNotificationLog>;
  eventSeat?: Maybe<Club_WorldFamilyClub_EventSeat>;
  eventType?: Maybe<Club_WorldFamilyClub_EventType>;
  faceCallAndTelephoneEnglish?: Maybe<Club_WorldFamilyClub_FaceCallAndTelephoneEnglish>;
  faceCallForTrialmode?: Maybe<Club_WorldFamilyClub_FaceCallForTrialmode>;
  faceCallHist?: Maybe<Club_WorldFamilyClub_FaceCallHist>;
  faceCallHistCount?: Maybe<Club_WorldFamilyClub_FaceCallHistCount>;
  faceCallMonthlyRecommend?: Maybe<Club_WorldFamilyClub_FaceCallMonthlyRecommend>;
  faceCallPerformer?: Maybe<Club_WorldFamilyClub_FaceCallPerformer>;
  faceCallSeasonHoliday?: Maybe<Club_WorldFamilyClub_FaceCallSeasonHoliday>;
  faceCallSong?: Maybe<Club_WorldFamilyClub_FaceCallSong>;
  faceCallSongAndPerformer?: Maybe<Club_WorldFamilyClub_FaceCallSongAndPerformer>;
  faceCallSongPublic?: Maybe<Club_WorldFamilyClub_FaceCallSongPublic>;
  goGoLiveDesktopQrcode?: Maybe<Club_WorldFamilyClub_GoGoLiveDesktopQrcode>;
  goGoLiveOpenDate?: Maybe<Club_WorldFamilyClub_GoGoLiveOpenDate>;
  goGoLivePromotion?: Maybe<Club_WorldFamilyClub_GoGoLivePromotion>;
  graduationApplication?: Maybe<Club_WorldFamilyClub_GraduationApplication>;
  graduationApplicationEvaluation?: Maybe<Club_WorldFamilyClub_GraduationApplicationEvaluation>;
  graduationApplicationSubTask?: Maybe<Club_WorldFamilyClub_GraduationApplicationSubTask>;
  graduationApplicationTask?: Maybe<Club_WorldFamilyClub_GraduationApplicationTask>;
  graduationChild?: Maybe<Club_WorldFamilyClub_GraduationChild>;
  graduationChildSetting?: Maybe<Club_WorldFamilyClub_GraduationChildSetting>;
  graduationEvaluation?: Maybe<Club_WorldFamilyClub_GraduationEvaluation>;
  graduationNotification?: Maybe<Club_WorldFamilyClub_GraduationNotification>;
  graduationSubApplication?: Maybe<Club_WorldFamilyClub_GraduationSubApplication>;
  graduationSubTaskMarketDescription?: Maybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescription>;
  graduationTaskMarketDescription?: Maybe<Club_WorldFamilyClub_GraduationTaskMarketDescription>;
  graphqlAccess?: Maybe<Club_WorldFamilyClub_GraphqlAccess>;
  graphqlSchema?: Maybe<Club_WorldFamilyClub_GraphqlSchema>;
  memberMessageSetting?: Maybe<Club_WorldFamilyClub_MemberMessageSetting>;
  myFavoriteFaceCall?: Maybe<Club_WorldFamilyClub_MyFavoriteFaceCall>;
  myFavoriteFaceCallSong?: Maybe<Club_WorldFamilyClub_MyFavoriteFaceCallSong>;
  notification?: Maybe<Club_WorldFamilyClub_Notification>;
  notificationIcon?: Maybe<Club_WorldFamilyClub_NotificationIcon>;
  oneTimeToken?: Maybe<Club_WorldFamilyClub_OneTimeToken>;
  onlineEventLog?: Maybe<Club_WorldFamilyClub_OnlineEventLog>;
  onlineShow?: Maybe<Club_WorldFamilyClub_OnlineShow>;
  parameter?: Maybe<Club_WorldFamilyClub_Parameter>;
  regionBanner?: Maybe<Club_WorldFamilyClub_RegionBanner>;
  searchKeyword?: Maybe<Club_WorldFamilyClub_SearchKeyword>;
  service?: Maybe<Club_WorldFamilyClub_Service>;
  serviceBannerDefault?: Maybe<Club_WorldFamilyClub_ServiceBannerDefault>;
  serviceBannerScheduled?: Maybe<Club_WorldFamilyClub_ServiceBannerScheduled>;
  serviceSearch?: Maybe<Club_WorldFamilyClub_ServiceSearch>;
  smsCertification?: Maybe<Club_WorldFamilyClub_SmsCertification>;
  smsVerificationCode?: Maybe<Club_WorldFamilyClub_SmsVerificationCode>;
  surveyTypeForm?: Maybe<Club_WorldFamilyClub_SurveyTypeForm>;
  surveyTypeFormList?: Maybe<Club_WorldFamilyClub_SurveyTypeFormList>;
  teSong?: Maybe<Club_WorldFamilyClub_TeSong>;
  temp20240408Graduation?: Maybe<Club_WorldFamilyClub_Temp20240408Graduation>;
  temp20240408Graduation1?: Maybe<Club_WorldFamilyClub_Temp20240408Graduation1>;
  tempFaceCall?: Maybe<Club_WorldFamilyClub_TempFaceCall>;
  tempFaceCallHk?: Maybe<Club_WorldFamilyClub_TempFaceCallHk>;
  tempFaceCallKr?: Maybe<Club_WorldFamilyClub_TempFaceCallKr>;
  tempHkAppMyFavorite?: Maybe<Club_WorldFamilyClub_TempHkAppMyFavorite>;
  tempHkClubappsFacecallCrew?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallCrew>;
  tempHkClubappsFacecallHistory?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallHistory>;
  tempHkClubappsFacecallSong?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallSong>;
  tempHkDmemChild?: Maybe<Club_WorldFamilyClub_TempHkDmemChild>;
  tempHkTeSong?: Maybe<Club_WorldFamilyClub_TempHkTeSong>;
  tempHkTematerial?: Maybe<Club_WorldFamilyClub_TempHkTematerial>;
  tempKrFaceCallHist?: Maybe<Club_WorldFamilyClub_TempKrFaceCallHist>;
  tempKrFcCrewM?: Maybe<Club_WorldFamilyClub_TempKrFcCrewM>;
  tempKrFcsongCrew?: Maybe<Club_WorldFamilyClub_TempKrFcsongCrew>;
  tempKrMyFavorite?: Maybe<Club_WorldFamilyClub_TempKrMyFavorite>;
  tempKrTeSong?: Maybe<Club_WorldFamilyClub_TempKrTeSong>;
  tempTwCambridge?: Maybe<Club_WorldFamilyClub_TempTwCambridge>;
  tempTwFaceCallHist?: Maybe<Club_WorldFamilyClub_TempTwFaceCallHist>;
  tempTwFcCrewM?: Maybe<Club_WorldFamilyClub_TempTwFcCrewM>;
  tempTwFcsongCrew?: Maybe<Club_WorldFamilyClub_TempTwFcsongCrew>;
  tempTwFcsongMonthlyRecommend?: Maybe<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommend>;
  tempTwMyFavorite?: Maybe<Club_WorldFamilyClub_TempTwMyFavorite>;
  tempTwTeSong?: Maybe<Club_WorldFamilyClub_TempTwTeSong>;
  tempWfcGraC?: Maybe<Club_WorldFamilyClub_TempWfcGraC>;
  tempWfcGraCOrganized?: Maybe<Club_WorldFamilyClub_TempWfcGraCOrganized>;
  testUserAccount?: Maybe<Club_WorldFamilyClub_TestUserAccount>;
  testWater?: Maybe<Club_WorldFamilyClub_TestWater>;
  testWaterSub?: Maybe<Club_WorldFamilyClub_TestWaterSub>;
  tev2MonthlySong?: Maybe<Club_WorldFamilyClub_Tev2MonthlySong>;
  user?: Maybe<Club_WorldFamilyClub_User>;
  videoForTrialmode?: Maybe<Club_WorldFamilyClub_VideoForTrialmode>;
};

export type Club_WorldFamilyClub_Mutation_UpdateInput = {
  appAuth?: InputMaybe<Club_WorldFamilyClub_AppAuthInput>;
  appBanner?: InputMaybe<Club_WorldFamilyClub_AppBannerInput>;
  appDigitalMagazine?: InputMaybe<Club_WorldFamilyClub_AppDigitalMagazineInput>;
  appMemberNotification?: InputMaybe<Club_WorldFamilyClub_AppMemberNotificationInput>;
  appNotification?: InputMaybe<Club_WorldFamilyClub_AppNotificationInput>;
  appNotificationMember?: InputMaybe<Club_WorldFamilyClub_AppNotificationMemberInput>;
  appNotificationType?: InputMaybe<Club_WorldFamilyClub_AppNotificationTypeInput>;
  appNotificationTypeMarket?: InputMaybe<Club_WorldFamilyClub_AppNotificationTypeMarketInput>;
  appRegionEmergency?: InputMaybe<Club_WorldFamilyClub_AppRegionEmergencyInput>;
  appRelease?: InputMaybe<Club_WorldFamilyClub_AppReleaseInput>;
  appServiceRegionFestivalImage?: InputMaybe<Club_WorldFamilyClub_AppServiceRegionFestivalImageInput>;
  appServiceRegionImage?: InputMaybe<Club_WorldFamilyClub_AppServiceRegionImageInput>;
  appSurvey?: InputMaybe<Club_WorldFamilyClub_AppSurveyInput>;
  appSurveyMember?: InputMaybe<Club_WorldFamilyClub_AppSurveyMemberInput>;
  appSurveyQa?: InputMaybe<Club_WorldFamilyClub_AppSurveyQaInput>;
  appSurveyReminder?: InputMaybe<Club_WorldFamilyClub_AppSurveyReminderInput>;
  azureFunction?: InputMaybe<Club_WorldFamilyClub_AzureFunctionInput>;
  broadcastPromotion?: InputMaybe<Club_WorldFamilyClub_BroadcastPromotionInput>;
  childGraduationApplication?: InputMaybe<Club_WorldFamilyClub_ChildGraduationApplicationInput>;
  dweProductPackage?: InputMaybe<Club_WorldFamilyClub_DweProductPackageInput>;
  dweProductSong?: InputMaybe<Club_WorldFamilyClub_DweProductSongInput>;
  dweProductSongTool?: InputMaybe<Club_WorldFamilyClub_DweProductSongToolInput>;
  dweProductTool?: InputMaybe<Club_WorldFamilyClub_DweProductToolInput>;
  eventBanner?: InputMaybe<Club_WorldFamilyClub_EventBannerInput>;
  eventBannerRegion?: InputMaybe<Club_WorldFamilyClub_EventBannerRegionInput>;
  eventCheckInAuth?: InputMaybe<Club_WorldFamilyClub_EventCheckInAuthInput>;
  eventNotificationLog?: InputMaybe<Club_WorldFamilyClub_EventNotificationLogInput>;
  eventSeat?: InputMaybe<Club_WorldFamilyClub_EventSeatInput>;
  eventType?: InputMaybe<Club_WorldFamilyClub_EventTypeInput>;
  faceCallAndTelephoneEnglish?: InputMaybe<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishInput>;
  faceCallForTrialmode?: InputMaybe<Club_WorldFamilyClub_FaceCallForTrialmodeInput>;
  faceCallHist?: InputMaybe<Club_WorldFamilyClub_FaceCallHistInput>;
  faceCallHistCount?: InputMaybe<Club_WorldFamilyClub_FaceCallHistCountInput>;
  faceCallMonthlyRecommend?: InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendInput>;
  faceCallPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallPerformerInput>;
  faceCallSeasonHoliday?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidayInput>;
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>;
  faceCallSongAndPerformer?: InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>;
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>;
  goGoLiveDesktopQrcode?: InputMaybe<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeInput>;
  goGoLiveOpenDate?: InputMaybe<Club_WorldFamilyClub_GoGoLiveOpenDateInput>;
  goGoLivePromotion?: InputMaybe<Club_WorldFamilyClub_GoGoLivePromotionInput>;
  graduationApplication?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationInput>;
  graduationApplicationEvaluation?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationInput>;
  graduationApplicationSubTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>;
  graduationApplicationTask?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskInput>;
  graduationChild?: InputMaybe<Club_WorldFamilyClub_GraduationChildInput>;
  graduationChildSetting?: InputMaybe<Club_WorldFamilyClub_GraduationChildSettingInput>;
  graduationEvaluation?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationInput>;
  graduationNotification?: InputMaybe<Club_WorldFamilyClub_GraduationNotificationInput>;
  graduationSubApplication?: InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationInput>;
  graduationSubTaskMarketDescription?: InputMaybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionInput>;
  graduationTaskMarketDescription?: InputMaybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionInput>;
  graphqlAccess?: InputMaybe<Club_WorldFamilyClub_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Club_WorldFamilyClub_GraphqlSchemaInput>;
  memberMessageSetting?: InputMaybe<Club_WorldFamilyClub_MemberMessageSettingInput>;
  myFavoriteFaceCall?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallInput>;
  myFavoriteFaceCallSong?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongInput>;
  notification?: InputMaybe<Club_WorldFamilyClub_NotificationInput>;
  notificationIcon?: InputMaybe<Club_WorldFamilyClub_NotificationIconInput>;
  oneTimeToken?: InputMaybe<Club_WorldFamilyClub_OneTimeTokenInput>;
  onlineEventLog?: InputMaybe<Club_WorldFamilyClub_OnlineEventLogInput>;
  onlineShow?: InputMaybe<Club_WorldFamilyClub_OnlineShowInput>;
  parameter?: InputMaybe<Club_WorldFamilyClub_ParameterInput>;
  regionBanner?: InputMaybe<Club_WorldFamilyClub_RegionBannerInput>;
  searchKeyword?: InputMaybe<Club_WorldFamilyClub_SearchKeywordInput>;
  service?: InputMaybe<Club_WorldFamilyClub_ServiceInput>;
  serviceBannerDefault?: InputMaybe<Club_WorldFamilyClub_ServiceBannerDefaultInput>;
  serviceBannerScheduled?: InputMaybe<Club_WorldFamilyClub_ServiceBannerScheduledInput>;
  serviceSearch?: InputMaybe<Club_WorldFamilyClub_ServiceSearchInput>;
  smsCertification?: InputMaybe<Club_WorldFamilyClub_SmsCertificationInput>;
  smsVerificationCode?: InputMaybe<Club_WorldFamilyClub_SmsVerificationCodeInput>;
  surveyTypeForm?: InputMaybe<Club_WorldFamilyClub_SurveyTypeFormInput>;
  surveyTypeFormList?: InputMaybe<Club_WorldFamilyClub_SurveyTypeFormListInput>;
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongInput>;
  temp20240408Graduation?: InputMaybe<Club_WorldFamilyClub_Temp20240408GraduationInput>;
  temp20240408Graduation1?: InputMaybe<Club_WorldFamilyClub_Temp20240408Graduation1Input>;
  tempFaceCall?: InputMaybe<Club_WorldFamilyClub_TempFaceCallInput>;
  tempFaceCallHk?: InputMaybe<Club_WorldFamilyClub_TempFaceCallHkInput>;
  tempFaceCallKr?: InputMaybe<Club_WorldFamilyClub_TempFaceCallKrInput>;
  tempHkAppMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempHkAppMyFavoriteInput>;
  tempHkClubappsFacecallCrew?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallCrewInput>;
  tempHkClubappsFacecallHistory?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryInput>;
  tempHkClubappsFacecallSong?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallSongInput>;
  tempHkDmemChild?: InputMaybe<Club_WorldFamilyClub_TempHkDmemChildInput>;
  tempHkTeSong?: InputMaybe<Club_WorldFamilyClub_TempHkTeSongInput>;
  tempHkTematerial?: InputMaybe<Club_WorldFamilyClub_TempHkTematerialInput>;
  tempKrFaceCallHist?: InputMaybe<Club_WorldFamilyClub_TempKrFaceCallHistInput>;
  tempKrFcCrewM?: InputMaybe<Club_WorldFamilyClub_TempKrFcCrewMInput>;
  tempKrFcsongCrew?: InputMaybe<Club_WorldFamilyClub_TempKrFcsongCrewInput>;
  tempKrMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempKrMyFavoriteInput>;
  tempKrTeSong?: InputMaybe<Club_WorldFamilyClub_TempKrTeSongInput>;
  tempTwCambridge?: InputMaybe<Club_WorldFamilyClub_TempTwCambridgeInput>;
  tempTwFaceCallHist?: InputMaybe<Club_WorldFamilyClub_TempTwFaceCallHistInput>;
  tempTwFcCrewM?: InputMaybe<Club_WorldFamilyClub_TempTwFcCrewMInput>;
  tempTwFcsongCrew?: InputMaybe<Club_WorldFamilyClub_TempTwFcsongCrewInput>;
  tempTwFcsongMonthlyRecommend?: InputMaybe<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendInput>;
  tempTwMyFavorite?: InputMaybe<Club_WorldFamilyClub_TempTwMyFavoriteInput>;
  tempTwTeSong?: InputMaybe<Club_WorldFamilyClub_TempTwTeSongInput>;
  tempWfcGraC?: InputMaybe<Club_WorldFamilyClub_TempWfcGraCInput>;
  tempWfcGraCOrganized?: InputMaybe<Club_WorldFamilyClub_TempWfcGraCOrganizedInput>;
  testUserAccount?: InputMaybe<Club_WorldFamilyClub_TestUserAccountInput>;
  testWater?: InputMaybe<Club_WorldFamilyClub_TestWaterInput>;
  testWaterSub?: InputMaybe<Club_WorldFamilyClub_TestWaterSubInput>;
  tev2MonthlySong?: InputMaybe<Club_WorldFamilyClub_Tev2MonthlySongInput>;
  user?: InputMaybe<Club_WorldFamilyClub_UserInput>;
  videoForTrialmode?: InputMaybe<Club_WorldFamilyClub_VideoForTrialmodeInput>;
};

export type Club_WorldFamilyClub_Mutation_UpdateRange = {
  __typename?: 'Club_WorldFamilyClub_Mutation_UpdateRange';
  appAuths?: Maybe<Array<Club_WorldFamilyClub_AppAuth>>;
  appBanners?: Maybe<Array<Club_WorldFamilyClub_AppBanner>>;
  appDigitalMagazines?: Maybe<Array<Club_WorldFamilyClub_AppDigitalMagazine>>;
  appMemberNotifications?: Maybe<Array<Club_WorldFamilyClub_AppMemberNotification>>;
  appNotificationMembers?: Maybe<Array<Club_WorldFamilyClub_AppNotificationMember>>;
  appNotificationTypeMarkets?: Maybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarket>>;
  appNotificationTypes?: Maybe<Array<Club_WorldFamilyClub_AppNotificationType>>;
  appNotifications?: Maybe<Array<Club_WorldFamilyClub_AppNotification>>;
  appRegionEmergencies?: Maybe<Array<Club_WorldFamilyClub_AppRegionEmergency>>;
  appReleases?: Maybe<Array<Club_WorldFamilyClub_AppRelease>>;
  appServiceRegionFestivalImages?: Maybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImage>>;
  appServiceRegionImages?: Maybe<Array<Club_WorldFamilyClub_AppServiceRegionImage>>;
  appSurveyMembers?: Maybe<Array<Club_WorldFamilyClub_AppSurveyMember>>;
  appSurveyQas?: Maybe<Array<Club_WorldFamilyClub_AppSurveyQa>>;
  appSurveyReminders?: Maybe<Array<Club_WorldFamilyClub_AppSurveyReminder>>;
  appSurveys?: Maybe<Array<Club_WorldFamilyClub_AppSurvey>>;
  azureFunctions?: Maybe<Array<Club_WorldFamilyClub_AzureFunction>>;
  broadcastPromotions?: Maybe<Array<Club_WorldFamilyClub_BroadcastPromotion>>;
  childGraduationApplications?: Maybe<Array<Club_WorldFamilyClub_ChildGraduationApplication>>;
  dweProductPackages?: Maybe<Array<Club_WorldFamilyClub_DweProductPackage>>;
  dweProductSongTools?: Maybe<Array<Club_WorldFamilyClub_DweProductSongTool>>;
  dweProductSongs?: Maybe<Array<Club_WorldFamilyClub_DweProductSong>>;
  dweProductTools?: Maybe<Array<Club_WorldFamilyClub_DweProductTool>>;
  eventBannerRegions?: Maybe<Array<Club_WorldFamilyClub_EventBannerRegion>>;
  eventBanners?: Maybe<Array<Club_WorldFamilyClub_EventBanner>>;
  eventCheckInAuths?: Maybe<Array<Club_WorldFamilyClub_EventCheckInAuth>>;
  eventNotificationLogs?: Maybe<Array<Club_WorldFamilyClub_EventNotificationLog>>;
  eventSeats?: Maybe<Array<Club_WorldFamilyClub_EventSeat>>;
  eventTypes?: Maybe<Array<Club_WorldFamilyClub_EventType>>;
  faceCallAndTelephoneEnglishes?: Maybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglish>>;
  faceCallForTrialmodes?: Maybe<Array<Club_WorldFamilyClub_FaceCallForTrialmode>>;
  faceCallHistCounts?: Maybe<Array<Club_WorldFamilyClub_FaceCallHistCount>>;
  faceCallHists?: Maybe<Array<Club_WorldFamilyClub_FaceCallHist>>;
  faceCallMonthlyRecommends?: Maybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommend>>;
  faceCallPerformers?: Maybe<Array<Club_WorldFamilyClub_FaceCallPerformer>>;
  faceCallSeasonHolidays?: Maybe<Array<Club_WorldFamilyClub_FaceCallSeasonHoliday>>;
  faceCallSongAndPerformers?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformer>>;
  faceCallSongPublics?: Maybe<Array<Club_WorldFamilyClub_FaceCallSongPublic>>;
  faceCallSongs?: Maybe<Array<Club_WorldFamilyClub_FaceCallSong>>;
  goGoLiveDesktopQrcodes?: Maybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcode>>;
  goGoLiveOpenDates?: Maybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDate>>;
  goGoLivePromotions?: Maybe<Array<Club_WorldFamilyClub_GoGoLivePromotion>>;
  graduationApplicationEvaluations?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluation>>;
  graduationApplicationSubTasks?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTask>>;
  graduationApplicationTasks?: Maybe<Array<Club_WorldFamilyClub_GraduationApplicationTask>>;
  graduationApplications?: Maybe<Array<Club_WorldFamilyClub_GraduationApplication>>;
  graduationChildSettings?: Maybe<Array<Club_WorldFamilyClub_GraduationChildSetting>>;
  graduationChildren?: Maybe<Array<Club_WorldFamilyClub_GraduationChild>>;
  graduationEvaluations?: Maybe<Array<Club_WorldFamilyClub_GraduationEvaluation>>;
  graduationNotifications?: Maybe<Array<Club_WorldFamilyClub_GraduationNotification>>;
  graduationSubApplications?: Maybe<Array<Club_WorldFamilyClub_GraduationSubApplication>>;
  graduationSubTaskMarketDescriptions?: Maybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescription>>;
  graduationTaskMarketDescriptions?: Maybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescription>>;
  graphqlAccesses?: Maybe<Array<Club_WorldFamilyClub_GraphqlAccess>>;
  graphqlSchemas?: Maybe<Array<Club_WorldFamilyClub_GraphqlSchema>>;
  memberMessageSettings?: Maybe<Array<Club_WorldFamilyClub_MemberMessageSetting>>;
  myFavoriteFaceCallSongs?: Maybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSong>>;
  myFavoriteFaceCalls?: Maybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCall>>;
  notificationIcons?: Maybe<Array<Club_WorldFamilyClub_NotificationIcon>>;
  notifications?: Maybe<Array<Club_WorldFamilyClub_Notification>>;
  oneTimeTokens?: Maybe<Array<Club_WorldFamilyClub_OneTimeToken>>;
  onlineEventLogs?: Maybe<Array<Club_WorldFamilyClub_OnlineEventLog>>;
  onlineShows?: Maybe<Array<Club_WorldFamilyClub_OnlineShow>>;
  parameters?: Maybe<Array<Club_WorldFamilyClub_Parameter>>;
  regionBanners?: Maybe<Array<Club_WorldFamilyClub_RegionBanner>>;
  searchKeywords?: Maybe<Array<Club_WorldFamilyClub_SearchKeyword>>;
  serviceBannerDefaults?: Maybe<Array<Club_WorldFamilyClub_ServiceBannerDefault>>;
  serviceBannerScheduleds?: Maybe<Array<Club_WorldFamilyClub_ServiceBannerScheduled>>;
  serviceSearches?: Maybe<Array<Club_WorldFamilyClub_ServiceSearch>>;
  services?: Maybe<Array<Club_WorldFamilyClub_Service>>;
  smsCertifications?: Maybe<Array<Club_WorldFamilyClub_SmsCertification>>;
  smsVerificationCodes?: Maybe<Array<Club_WorldFamilyClub_SmsVerificationCode>>;
  surveyTypeFormLists?: Maybe<Array<Club_WorldFamilyClub_SurveyTypeFormList>>;
  surveyTypeForms?: Maybe<Array<Club_WorldFamilyClub_SurveyTypeForm>>;
  teSongs?: Maybe<Array<Club_WorldFamilyClub_TeSong>>;
  temp20240408Graduation1s?: Maybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1>>;
  temp20240408Graduations?: Maybe<Array<Club_WorldFamilyClub_Temp20240408Graduation>>;
  tempFaceCallHks?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallHk>>;
  tempFaceCallKrs?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallKr>>;
  tempFaceCalls?: Maybe<Array<Club_WorldFamilyClub_TempFaceCall>>;
  tempHkAppMyFavorites?: Maybe<Array<Club_WorldFamilyClub_TempHkAppMyFavorite>>;
  tempHkClubappsFacecallCrews?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrew>>;
  tempHkClubappsFacecallHistories?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistory>>;
  tempHkClubappsFacecallSongs?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSong>>;
  tempHkDmemChildren?: Maybe<Array<Club_WorldFamilyClub_TempHkDmemChild>>;
  tempHkTeSongs?: Maybe<Array<Club_WorldFamilyClub_TempHkTeSong>>;
  tempHkTematerials?: Maybe<Array<Club_WorldFamilyClub_TempHkTematerial>>;
  tempKrFaceCallHists?: Maybe<Array<Club_WorldFamilyClub_TempKrFaceCallHist>>;
  tempKrFcCrewMs?: Maybe<Array<Club_WorldFamilyClub_TempKrFcCrewM>>;
  tempKrFcsongCrews?: Maybe<Array<Club_WorldFamilyClub_TempKrFcsongCrew>>;
  tempKrMyFavorites?: Maybe<Array<Club_WorldFamilyClub_TempKrMyFavorite>>;
  tempKrTeSongs?: Maybe<Array<Club_WorldFamilyClub_TempKrTeSong>>;
  tempTwCambridges?: Maybe<Array<Club_WorldFamilyClub_TempTwCambridge>>;
  tempTwFaceCallHists?: Maybe<Array<Club_WorldFamilyClub_TempTwFaceCallHist>>;
  tempTwFcCrewMs?: Maybe<Array<Club_WorldFamilyClub_TempTwFcCrewM>>;
  tempTwFcsongCrews?: Maybe<Array<Club_WorldFamilyClub_TempTwFcsongCrew>>;
  tempTwFcsongMonthlyRecommends?: Maybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommend>>;
  tempTwMyFavorites?: Maybe<Array<Club_WorldFamilyClub_TempTwMyFavorite>>;
  tempTwTeSongs?: Maybe<Array<Club_WorldFamilyClub_TempTwTeSong>>;
  tempWfcGraCOrganizeds?: Maybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganized>>;
  tempWfcGraCs?: Maybe<Array<Club_WorldFamilyClub_TempWfcGraC>>;
  testUserAccounts?: Maybe<Array<Club_WorldFamilyClub_TestUserAccount>>;
  testWaterSubs?: Maybe<Array<Club_WorldFamilyClub_TestWaterSub>>;
  testWaters?: Maybe<Array<Club_WorldFamilyClub_TestWater>>;
  tev2MonthlySongs?: Maybe<Array<Club_WorldFamilyClub_Tev2MonthlySong>>;
  users?: Maybe<Array<Club_WorldFamilyClub_User>>;
  videoForTrialmodes?: Maybe<Array<Club_WorldFamilyClub_VideoForTrialmode>>;
};

export type Club_WorldFamilyClub_Mutation_UpdateRangeInput = {
  appAuths?: InputMaybe<Array<Club_WorldFamilyClub_AppAuthInput>>;
  appBanners?: InputMaybe<Array<Club_WorldFamilyClub_AppBannerInput>>;
  appDigitalMagazines?: InputMaybe<Array<Club_WorldFamilyClub_AppDigitalMagazineInput>>;
  appMemberNotifications?: InputMaybe<Array<Club_WorldFamilyClub_AppMemberNotificationInput>>;
  appNotificationMembers?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationMemberInput>>;
  appNotificationTypeMarkets?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarketInput>>;
  appNotificationTypes?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeInput>>;
  appNotifications?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationInput>>;
  appRegionEmergencies?: InputMaybe<Array<Club_WorldFamilyClub_AppRegionEmergencyInput>>;
  appReleases?: InputMaybe<Array<Club_WorldFamilyClub_AppReleaseInput>>;
  appServiceRegionFestivalImages?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImageInput>>;
  appServiceRegionImages?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionImageInput>>;
  appSurveyMembers?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyMemberInput>>;
  appSurveyQas?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyQaInput>>;
  appSurveyReminders?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyReminderInput>>;
  appSurveys?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyInput>>;
  azureFunctions?: InputMaybe<Array<Club_WorldFamilyClub_AzureFunctionInput>>;
  broadcastPromotions?: InputMaybe<Array<Club_WorldFamilyClub_BroadcastPromotionInput>>;
  childGraduationApplications?: InputMaybe<Array<Club_WorldFamilyClub_ChildGraduationApplicationInput>>;
  dweProductPackages?: InputMaybe<Array<Club_WorldFamilyClub_DweProductPackageInput>>;
  dweProductSongTools?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongToolInput>>;
  dweProductSongs?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongInput>>;
  dweProductTools?: InputMaybe<Array<Club_WorldFamilyClub_DweProductToolInput>>;
  eventBannerRegions?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerRegionInput>>;
  eventBanners?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerInput>>;
  eventCheckInAuths?: InputMaybe<Array<Club_WorldFamilyClub_EventCheckInAuthInput>>;
  eventNotificationLogs?: InputMaybe<Array<Club_WorldFamilyClub_EventNotificationLogInput>>;
  eventSeats?: InputMaybe<Array<Club_WorldFamilyClub_EventSeatInput>>;
  eventTypes?: InputMaybe<Array<Club_WorldFamilyClub_EventTypeInput>>;
  faceCallAndTelephoneEnglishes?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishInput>>;
  faceCallForTrialmodes?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallForTrialmodeInput>>;
  faceCallHistCounts?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistCountInput>>;
  faceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistInput>>;
  faceCallMonthlyRecommends?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommendInput>>;
  faceCallPerformers?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallPerformerInput>>;
  faceCallSeasonHolidays?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSeasonHolidayInput>>;
  faceCallSongAndPerformers?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformerInput>>;
  faceCallSongPublics?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongPublicInput>>;
  faceCallSongs?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongInput>>;
  goGoLiveDesktopQrcodes?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeInput>>;
  goGoLiveOpenDates?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDateInput>>;
  goGoLivePromotions?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLivePromotionInput>>;
  graduationApplicationEvaluations?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluationInput>>;
  graduationApplicationSubTasks?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTaskInput>>;
  graduationApplicationTasks?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationTaskInput>>;
  graduationApplications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationInput>>;
  graduationChildSettings?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildSettingInput>>;
  graduationChildren?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildInput>>;
  graduationEvaluations?: InputMaybe<Array<Club_WorldFamilyClub_GraduationEvaluationInput>>;
  graduationNotifications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationNotificationInput>>;
  graduationSubApplications?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubApplicationInput>>;
  graduationSubTaskMarketDescriptions?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionInput>>;
  graduationTaskMarketDescriptions?: InputMaybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescriptionInput>>;
  graphqlAccesses?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlSchemaInput>>;
  memberMessageSettings?: InputMaybe<Array<Club_WorldFamilyClub_MemberMessageSettingInput>>;
  myFavoriteFaceCallSongs?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSongInput>>;
  myFavoriteFaceCalls?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallInput>>;
  notificationIcons?: InputMaybe<Array<Club_WorldFamilyClub_NotificationIconInput>>;
  notifications?: InputMaybe<Array<Club_WorldFamilyClub_NotificationInput>>;
  oneTimeTokens?: InputMaybe<Array<Club_WorldFamilyClub_OneTimeTokenInput>>;
  onlineEventLogs?: InputMaybe<Array<Club_WorldFamilyClub_OnlineEventLogInput>>;
  onlineShows?: InputMaybe<Array<Club_WorldFamilyClub_OnlineShowInput>>;
  parameters?: InputMaybe<Array<Club_WorldFamilyClub_ParameterInput>>;
  regionBanners?: InputMaybe<Array<Club_WorldFamilyClub_RegionBannerInput>>;
  searchKeywords?: InputMaybe<Array<Club_WorldFamilyClub_SearchKeywordInput>>;
  serviceBannerDefaults?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerDefaultInput>>;
  serviceBannerScheduleds?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerScheduledInput>>;
  serviceSearches?: InputMaybe<Array<Club_WorldFamilyClub_ServiceSearchInput>>;
  services?: InputMaybe<Array<Club_WorldFamilyClub_ServiceInput>>;
  smsCertifications?: InputMaybe<Array<Club_WorldFamilyClub_SmsCertificationInput>>;
  smsVerificationCodes?: InputMaybe<Array<Club_WorldFamilyClub_SmsVerificationCodeInput>>;
  surveyTypeFormLists?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormListInput>>;
  surveyTypeForms?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormInput>>;
  teSongs?: InputMaybe<Array<Club_WorldFamilyClub_TeSongInput>>;
  temp20240408Graduation1s?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1Input>>;
  temp20240408Graduations?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408GraduationInput>>;
  tempFaceCallHks?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallHkInput>>;
  tempFaceCallKrs?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallKrInput>>;
  tempFaceCalls?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallInput>>;
  tempHkAppMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempHkAppMyFavoriteInput>>;
  tempHkClubappsFacecallCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrewInput>>;
  tempHkClubappsFacecallHistories?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryInput>>;
  tempHkClubappsFacecallSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSongInput>>;
  tempHkDmemChildren?: InputMaybe<Array<Club_WorldFamilyClub_TempHkDmemChildInput>>;
  tempHkTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTeSongInput>>;
  tempHkTematerials?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTematerialInput>>;
  tempKrFaceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFaceCallHistInput>>;
  tempKrFcCrewMs?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcCrewMInput>>;
  tempKrFcsongCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcsongCrewInput>>;
  tempKrMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempKrMyFavoriteInput>>;
  tempKrTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempKrTeSongInput>>;
  tempTwCambridges?: InputMaybe<Array<Club_WorldFamilyClub_TempTwCambridgeInput>>;
  tempTwFaceCallHists?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFaceCallHistInput>>;
  tempTwFcCrewMs?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcCrewMInput>>;
  tempTwFcsongCrews?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongCrewInput>>;
  tempTwFcsongMonthlyRecommends?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendInput>>;
  tempTwMyFavorites?: InputMaybe<Array<Club_WorldFamilyClub_TempTwMyFavoriteInput>>;
  tempTwTeSongs?: InputMaybe<Array<Club_WorldFamilyClub_TempTwTeSongInput>>;
  tempWfcGraCOrganizeds?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganizedInput>>;
  tempWfcGraCs?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCInput>>;
  testUserAccounts?: InputMaybe<Array<Club_WorldFamilyClub_TestUserAccountInput>>;
  testWaterSubs?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterSubInput>>;
  testWaters?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterInput>>;
  tev2MonthlySongs?: InputMaybe<Array<Club_WorldFamilyClub_Tev2MonthlySongInput>>;
  users?: InputMaybe<Array<Club_WorldFamilyClub_UserInput>>;
  videoForTrialmodes?: InputMaybe<Array<Club_WorldFamilyClub_VideoForTrialmodeInput>>;
};

/** MyFavoriteFaceCall */
export type Club_WorldFamilyClub_MyFavoriteFaceCall = Node & {
  __typename?: 'Club_WorldFamilyClub_MyFavoriteFaceCall';
  /** [NOT NULL] CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: Maybe<Club_WorldFamilyClub_FaceCallSong>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: Maybe<Club_WorldFamilyClub_FaceCallSongPublic>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: Maybe<Scalars['Int']['output']>;
  /** [ID] MyFavoriteFaceCallId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNumber */
  memberNumber?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_MyFavoriteFaceCallConnection = {
  __typename?: 'Club_WorldFamilyClub_MyFavoriteFaceCallConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCall>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_MyFavoriteFaceCallEdge = {
  __typename?: 'Club_WorldFamilyClub_MyFavoriteFaceCallEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_MyFavoriteFaceCall;
};

/** MyFavoriteFaceCall */
export type Club_WorldFamilyClub_MyFavoriteFaceCallFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallFilterInput>>;
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<StringOperationFilterInput>;
  /** [ID] MyFavoriteFaceCallId */
  myFavoriteFaceCallId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallFilterInput>>;
};

/** MyFavoriteFaceCall */
export type Club_WorldFamilyClub_MyFavoriteFaceCallInput = {
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  /** [ID] MyFavoriteFaceCallId */
  myFavoriteFaceCallId?: InputMaybe<Scalars['Long']['input']>;
};

/** MyFavoriteFaceCallSong */
export type Club_WorldFamilyClub_MyFavoriteFaceCallSong = Node & {
  __typename?: 'Club_WorldFamilyClub_MyFavoriteFaceCallSong';
  /** [NOT NULL] CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] MyFavoriteFaceCallSongId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNumber */
  memberNumber?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] TeSong */
  teSong?: Maybe<Club_WorldFamilyClub_TeSong>;
  /** [NOT NULL] TeSongId */
  teSongId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_MyFavoriteFaceCallSongConnection = {
  __typename?: 'Club_WorldFamilyClub_MyFavoriteFaceCallSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_MyFavoriteFaceCallSongEdge = {
  __typename?: 'Club_WorldFamilyClub_MyFavoriteFaceCallSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_MyFavoriteFaceCallSong;
};

/** MyFavoriteFaceCallSong */
export type Club_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput>>;
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<StringOperationFilterInput>;
  /** [ID] MyFavoriteFaceCallSongId */
  myFavoriteFaceCallSongId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput>>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongFilterInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<IntOperationFilterInput>;
};

/** MyFavoriteFaceCallSong */
export type Club_WorldFamilyClub_MyFavoriteFaceCallSongInput = {
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  /** [ID] MyFavoriteFaceCallSongId */
  myFavoriteFaceCallSongId?: InputMaybe<Scalars['Long']['input']>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<Scalars['Int']['input']>;
};

/** MyFavoriteFaceCallSong */
export type Club_WorldFamilyClub_MyFavoriteFaceCallSongSortInput = {
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<SortEnumType>;
  /** [ID] MyFavoriteFaceCallSongId */
  myFavoriteFaceCallSongId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TeSong */
  teSong?: InputMaybe<Club_WorldFamilyClub_TeSongSortInput>;
  /** [NOT NULL] TeSongId */
  teSongId?: InputMaybe<SortEnumType>;
};

/** MyFavoriteFaceCall */
export type Club_WorldFamilyClub_MyFavoriteFaceCallSortInput = {
  /** [NOT NULL] CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSong */
  faceCallSong?: InputMaybe<Club_WorldFamilyClub_FaceCallSongSortInput>;
  /** [NOT NULL] FaceCallSongId */
  faceCallSongId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] FaceCallSongPublic */
  faceCallSongPublic?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicSortInput>;
  /** [NOT NULL] FaceCallSongPublicId */
  faceCallSongPublicId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<SortEnumType>;
  /** [ID] MyFavoriteFaceCallId */
  myFavoriteFaceCallId?: InputMaybe<SortEnumType>;
};

/** Notification */
export type Club_WorldFamilyClub_Notification = Node & {
  __typename?: 'Club_WorldFamilyClub_Notification';
  /** ConditionNo */
  conditionNo?: Maybe<Scalars['String']['output']>;
  /** CreateTimeUtc */
  createTimeUtc?: Maybe<Scalars['DateTime']['output']>;
  /** Creater */
  creater?: Maybe<Scalars['String']['output']>;
  /** Editor */
  editor?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** ImageName */
  imageName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] IsSmsonly */
  isSmsonly?: Maybe<Scalars['Boolean']['output']>;
  /** KindNo */
  kindNo?: Maybe<Scalars['String']['output']>;
  /** LinkApp */
  linkApp?: Maybe<Scalars['String']['output']>;
  /** LinkWebUrl */
  linkWebUrl?: Maybe<Scalars['String']['output']>;
  /** Message */
  message?: Maybe<Scalars['String']['output']>;
  /** RecipientNo */
  recipientNo?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** ScheduleTimeLocal */
  scheduleTimeLocal?: Maybe<Scalars['DateTime']['output']>;
  /** SentDateLocal */
  sentDateLocal?: Maybe<Scalars['DateTime']['output']>;
  /** Subject */
  subject?: Maybe<Scalars['String']['output']>;
  /** TypeNo */
  typeNo?: Maybe<Scalars['String']['output']>;
  /** UpdateTimeUtc */
  updateTimeUtc?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_NotificationConnection = {
  __typename?: 'Club_WorldFamilyClub_NotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_NotificationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_Notification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_NotificationEdge = {
  __typename?: 'Club_WorldFamilyClub_NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_Notification;
};

/** Notification */
export type Club_WorldFamilyClub_NotificationFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_NotificationFilterInput>>;
  /** ConditionNo */
  conditionNo?: InputMaybe<StringOperationFilterInput>;
  /** CreateTimeUtc */
  createTimeUtc?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creater */
  creater?: InputMaybe<StringOperationFilterInput>;
  /** Editor */
  editor?: InputMaybe<StringOperationFilterInput>;
  /** ImageName */
  imageName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] IsSmsonly */
  isSmsonly?: InputMaybe<BooleanOperationFilterInput>;
  /** KindNo */
  kindNo?: InputMaybe<StringOperationFilterInput>;
  /** LinkApp */
  linkApp?: InputMaybe<StringOperationFilterInput>;
  /** LinkWebUrl */
  linkWebUrl?: InputMaybe<StringOperationFilterInput>;
  /** Message */
  message?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_NotificationFilterInput>>;
  /** RecipientNo */
  recipientNo?: InputMaybe<StringOperationFilterInput>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** ScheduleTimeLocal */
  scheduleTimeLocal?: InputMaybe<DateTimeOperationFilterInput>;
  /** SentDateLocal */
  sentDateLocal?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** Subject */
  subject?: InputMaybe<StringOperationFilterInput>;
  /** TypeNo */
  typeNo?: InputMaybe<StringOperationFilterInput>;
  /** UpdateTimeUtc */
  updateTimeUtc?: InputMaybe<DateTimeOperationFilterInput>;
};

/** NotificationIcon */
export type Club_WorldFamilyClub_NotificationIcon = Node & {
  __typename?: 'Club_WorldFamilyClub_NotificationIcon';
  /** IconKey */
  iconKey?: Maybe<Scalars['String']['output']>;
  /** [ID] NotificationIconId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** TagEnglishName */
  tagEnglishName?: Maybe<Scalars['String']['output']>;
  /** TagName */
  tagName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_NotificationIconConnection = {
  __typename?: 'Club_WorldFamilyClub_NotificationIconConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_NotificationIconEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_NotificationIcon>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_NotificationIconEdge = {
  __typename?: 'Club_WorldFamilyClub_NotificationIconEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_NotificationIcon;
};

/** NotificationIcon */
export type Club_WorldFamilyClub_NotificationIconFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_NotificationIconFilterInput>>;
  /** IconKey */
  iconKey?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** [ID] NotificationIconId */
  notificationIconId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_NotificationIconFilterInput>>;
  /** TagEnglishName */
  tagEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** TagName */
  tagName?: InputMaybe<StringOperationFilterInput>;
};

/** NotificationIcon */
export type Club_WorldFamilyClub_NotificationIconInput = {
  /** IconKey */
  iconKey?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [ID] NotificationIconId */
  notificationIconId?: InputMaybe<Scalars['Int']['input']>;
  /** TagEnglishName */
  tagEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** TagName */
  tagName?: InputMaybe<Scalars['String']['input']>;
};

/** NotificationIcon */
export type Club_WorldFamilyClub_NotificationIconSortInput = {
  /** IconKey */
  iconKey?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** [ID] NotificationIconId */
  notificationIconId?: InputMaybe<SortEnumType>;
  /** TagEnglishName */
  tagEnglishName?: InputMaybe<SortEnumType>;
  /** TagName */
  tagName?: InputMaybe<SortEnumType>;
};

/** Notification */
export type Club_WorldFamilyClub_NotificationInput = {
  /** ConditionNo */
  conditionNo?: InputMaybe<Scalars['String']['input']>;
  /** CreateTimeUtc */
  createTimeUtc?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creater */
  creater?: InputMaybe<Scalars['String']['input']>;
  /** Editor */
  editor?: InputMaybe<Scalars['String']['input']>;
  /** ImageName */
  imageName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] IsSmsonly */
  isSmsonly?: InputMaybe<Scalars['Boolean']['input']>;
  /** KindNo */
  kindNo?: InputMaybe<Scalars['String']['input']>;
  /** LinkApp */
  linkApp?: InputMaybe<Scalars['String']['input']>;
  /** LinkWebUrl */
  linkWebUrl?: InputMaybe<Scalars['String']['input']>;
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** RecipientNo */
  recipientNo?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** ScheduleTimeLocal */
  scheduleTimeLocal?: InputMaybe<Scalars['DateTime']['input']>;
  /** SentDateLocal */
  sentDateLocal?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** Subject */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** TypeNo */
  typeNo?: InputMaybe<Scalars['String']['input']>;
  /** UpdateTimeUtc */
  updateTimeUtc?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Notification */
export type Club_WorldFamilyClub_NotificationSortInput = {
  /** ConditionNo */
  conditionNo?: InputMaybe<SortEnumType>;
  /** CreateTimeUtc */
  createTimeUtc?: InputMaybe<SortEnumType>;
  /** Creater */
  creater?: InputMaybe<SortEnumType>;
  /** Editor */
  editor?: InputMaybe<SortEnumType>;
  /** ImageName */
  imageName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] IsSmsonly */
  isSmsonly?: InputMaybe<SortEnumType>;
  /** KindNo */
  kindNo?: InputMaybe<SortEnumType>;
  /** LinkApp */
  linkApp?: InputMaybe<SortEnumType>;
  /** LinkWebUrl */
  linkWebUrl?: InputMaybe<SortEnumType>;
  /** Message */
  message?: InputMaybe<SortEnumType>;
  /** RecipientNo */
  recipientNo?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** ScheduleTimeLocal */
  scheduleTimeLocal?: InputMaybe<SortEnumType>;
  /** SentDateLocal */
  sentDateLocal?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Subject */
  subject?: InputMaybe<SortEnumType>;
  /** TypeNo */
  typeNo?: InputMaybe<SortEnumType>;
  /** UpdateTimeUtc */
  updateTimeUtc?: InputMaybe<SortEnumType>;
};

/** OneTimeToken */
export type Club_WorldFamilyClub_OneTimeToken = Node & {
  __typename?: 'Club_WorldFamilyClub_OneTimeToken';
  /** CreateTime */
  createTime?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] DeviceId */
  deviceId?: Maybe<Scalars['String']['output']>;
  /** [ID] Token */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Seq */
  seq?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_OneTimeTokenConnection = {
  __typename?: 'Club_WorldFamilyClub_OneTimeTokenConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_OneTimeTokenEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_OneTimeToken>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_OneTimeTokenEdge = {
  __typename?: 'Club_WorldFamilyClub_OneTimeTokenEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_OneTimeToken;
};

/** OneTimeToken */
export type Club_WorldFamilyClub_OneTimeTokenFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_OneTimeTokenFilterInput>>;
  /** CreateTime */
  createTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] DeviceId */
  deviceId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_OneTimeTokenFilterInput>>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** [ID] Token */
  token?: InputMaybe<UuidOperationFilterInput>;
};

/** OneTimeToken */
export type Club_WorldFamilyClub_OneTimeTokenInput = {
  /** CreateTime */
  createTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] DeviceId */
  deviceId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] Token */
  token?: InputMaybe<Scalars['UUID']['input']>;
};

/** OneTimeToken */
export type Club_WorldFamilyClub_OneTimeTokenSortInput = {
  /** CreateTime */
  createTime?: InputMaybe<SortEnumType>;
  /** [NOT NULL] DeviceId */
  deviceId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [ID] Token */
  token?: InputMaybe<SortEnumType>;
};

/** OnlineEventLog */
export type Club_WorldFamilyClub_OnlineEventLog = Node & {
  __typename?: 'Club_WorldFamilyClub_OnlineEventLog';
  /** Clicktime */
  clicktime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** Memno */
  memno?: Maybe<Scalars['String']['output']>;
  /** VideoSeq */
  videoSeq?: Maybe<Scalars['Short']['output']>;
  /** VideoType */
  videoType?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_OnlineEventLogConnection = {
  __typename?: 'Club_WorldFamilyClub_OnlineEventLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_OnlineEventLogEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_OnlineEventLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_OnlineEventLogEdge = {
  __typename?: 'Club_WorldFamilyClub_OnlineEventLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_OnlineEventLog;
};

/** OnlineEventLog */
export type Club_WorldFamilyClub_OnlineEventLogFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_OnlineEventLogFilterInput>>;
  /** Clicktime */
  clicktime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Memno */
  memno?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_OnlineEventLogFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** VideoSeq */
  videoSeq?: InputMaybe<ShortOperationFilterInput>;
  /** VideoType */
  videoType?: InputMaybe<StringOperationFilterInput>;
};

/** OnlineEventLog */
export type Club_WorldFamilyClub_OnlineEventLogInput = {
  /** Clicktime */
  clicktime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Memno */
  memno?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** VideoSeq */
  videoSeq?: InputMaybe<Scalars['Short']['input']>;
  /** VideoType */
  videoType?: InputMaybe<Scalars['String']['input']>;
};

/** OnlineEventLog */
export type Club_WorldFamilyClub_OnlineEventLogSortInput = {
  /** Clicktime */
  clicktime?: InputMaybe<SortEnumType>;
  /** Memno */
  memno?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** VideoSeq */
  videoSeq?: InputMaybe<SortEnumType>;
  /** VideoType */
  videoType?: InputMaybe<SortEnumType>;
};

/** OnlineShow */
export type Club_WorldFamilyClub_OnlineShow = Node & {
  __typename?: 'Club_WorldFamilyClub_OnlineShow';
  /** Category */
  category?: Maybe<Scalars['String']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Library */
  library?: Maybe<Scalars['String']['output']>;
  /** Opendate */
  opendate?: Maybe<Scalars['String']['output']>;
  /** PicName */
  picName?: Maybe<Scalars['String']['output']>;
  /** PushMsg */
  pushMsg?: Maybe<Scalars['String']['output']>;
  /** SmsMsg */
  smsMsg?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Sort */
  sort?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] Status */
  status?: Maybe<Scalars['Int']['output']>;
  /** TitleEn */
  titleEn?: Maybe<Scalars['String']['output']>;
  /** TitleJp */
  titleJp?: Maybe<Scalars['String']['output']>;
  /** TitleTw */
  titleTw?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] TypeId */
  typeId?: Maybe<Scalars['String']['output']>;
  /** Url */
  url?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_OnlineShowConnection = {
  __typename?: 'Club_WorldFamilyClub_OnlineShowConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_OnlineShowEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_OnlineShow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_OnlineShowEdge = {
  __typename?: 'Club_WorldFamilyClub_OnlineShowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_OnlineShow;
};

/** OnlineShow */
export type Club_WorldFamilyClub_OnlineShowFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_OnlineShowFilterInput>>;
  /** Category */
  category?: InputMaybe<StringOperationFilterInput>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Library */
  library?: InputMaybe<StringOperationFilterInput>;
  /** Opendate */
  opendate?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_OnlineShowFilterInput>>;
  /** PicName */
  picName?: InputMaybe<StringOperationFilterInput>;
  /** PushMsg */
  pushMsg?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** SmsMsg */
  smsMsg?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Sort */
  sort?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Status */
  status?: InputMaybe<IntOperationFilterInput>;
  /** TitleEn */
  titleEn?: InputMaybe<StringOperationFilterInput>;
  /** TitleJp */
  titleJp?: InputMaybe<StringOperationFilterInput>;
  /** TitleTw */
  titleTw?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] TypeId */
  typeId?: InputMaybe<StringOperationFilterInput>;
  /** Url */
  url?: InputMaybe<StringOperationFilterInput>;
};

/** OnlineShow */
export type Club_WorldFamilyClub_OnlineShowInput = {
  /** Category */
  category?: InputMaybe<Scalars['String']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Library */
  library?: InputMaybe<Scalars['String']['input']>;
  /** Opendate */
  opendate?: InputMaybe<Scalars['String']['input']>;
  /** PicName */
  picName?: InputMaybe<Scalars['String']['input']>;
  /** PushMsg */
  pushMsg?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** SmsMsg */
  smsMsg?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Sort */
  sort?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Status */
  status?: InputMaybe<Scalars['Int']['input']>;
  /** TitleEn */
  titleEn?: InputMaybe<Scalars['String']['input']>;
  /** TitleJp */
  titleJp?: InputMaybe<Scalars['String']['input']>;
  /** TitleTw */
  titleTw?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] TypeId */
  typeId?: InputMaybe<Scalars['String']['input']>;
  /** Url */
  url?: InputMaybe<Scalars['String']['input']>;
};

/** OnlineShow */
export type Club_WorldFamilyClub_OnlineShowSortInput = {
  /** Category */
  category?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Library */
  library?: InputMaybe<SortEnumType>;
  /** Opendate */
  opendate?: InputMaybe<SortEnumType>;
  /** PicName */
  picName?: InputMaybe<SortEnumType>;
  /** PushMsg */
  pushMsg?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** SmsMsg */
  smsMsg?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Sort */
  sort?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Status */
  status?: InputMaybe<SortEnumType>;
  /** TitleEn */
  titleEn?: InputMaybe<SortEnumType>;
  /** TitleJp */
  titleJp?: InputMaybe<SortEnumType>;
  /** TitleTw */
  titleTw?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TypeId */
  typeId?: InputMaybe<SortEnumType>;
  /** Url */
  url?: InputMaybe<SortEnumType>;
};

/** Parameter */
export type Club_WorldFamilyClub_Parameter = Node & {
  __typename?: 'Club_WorldFamilyClub_Parameter';
  /** [ID] ParmGroup */
  id: Scalars['ID']['output'];
  /** ParmDescription */
  parmDescription?: Maybe<Scalars['String']['output']>;
  /** ParmId */
  parmId?: Maybe<Scalars['String']['output']>;
  /** ParmSort */
  parmSort?: Maybe<Scalars['String']['output']>;
  /** ParmValue */
  parmValue?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_ParameterConnection = {
  __typename?: 'Club_WorldFamilyClub_ParameterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_ParameterEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_Parameter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_ParameterEdge = {
  __typename?: 'Club_WorldFamilyClub_ParameterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_Parameter;
};

/** Parameter */
export type Club_WorldFamilyClub_ParameterFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_ParameterFilterInput>>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_ParameterFilterInput>>;
  /** ParmDescription */
  parmDescription?: InputMaybe<StringOperationFilterInput>;
  /** [ID] ParmGroup */
  parmGroup?: InputMaybe<StringOperationFilterInput>;
  /** ParmId */
  parmId?: InputMaybe<StringOperationFilterInput>;
  /** ParmSort */
  parmSort?: InputMaybe<StringOperationFilterInput>;
  /** ParmValue */
  parmValue?: InputMaybe<StringOperationFilterInput>;
};

/** Parameter */
export type Club_WorldFamilyClub_ParameterInput = {
  /** ParmDescription */
  parmDescription?: InputMaybe<Scalars['String']['input']>;
  /** [ID] ParmGroup */
  parmGroup?: InputMaybe<Scalars['String']['input']>;
  /** ParmId */
  parmId?: InputMaybe<Scalars['String']['input']>;
  /** ParmSort */
  parmSort?: InputMaybe<Scalars['String']['input']>;
  /** ParmValue */
  parmValue?: InputMaybe<Scalars['String']['input']>;
};

/** Parameter */
export type Club_WorldFamilyClub_ParameterSortInput = {
  /** ParmDescription */
  parmDescription?: InputMaybe<SortEnumType>;
  /** [ID] ParmGroup */
  parmGroup?: InputMaybe<SortEnumType>;
  /** ParmId */
  parmId?: InputMaybe<SortEnumType>;
  /** ParmSort */
  parmSort?: InputMaybe<SortEnumType>;
  /** ParmValue */
  parmValue?: InputMaybe<SortEnumType>;
};

/** RegionBanner */
export type Club_WorldFamilyClub_RegionBanner = Node & {
  __typename?: 'Club_WorldFamilyClub_RegionBanner';
  /** [NOT NULL] BannerSeq */
  bannerSeq?: Maybe<Scalars['Short']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Region */
  id: Scalars['ID']['output'];
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_RegionBannerConnection = {
  __typename?: 'Club_WorldFamilyClub_RegionBannerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_RegionBannerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_RegionBanner>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_RegionBannerEdge = {
  __typename?: 'Club_WorldFamilyClub_RegionBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_RegionBanner;
};

/** RegionBanner */
export type Club_WorldFamilyClub_RegionBannerFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_RegionBannerFilterInput>>;
  /** [NOT NULL] BannerSeq */
  bannerSeq?: InputMaybe<ShortOperationFilterInput>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_RegionBannerFilterInput>>;
  /** [ID] Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** RegionBanner */
export type Club_WorldFamilyClub_RegionBannerInput = {
  /** [NOT NULL] BannerSeq */
  bannerSeq?: InputMaybe<Scalars['Short']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** RegionBanner */
export type Club_WorldFamilyClub_RegionBannerSortInput = {
  /** [NOT NULL] BannerSeq */
  bannerSeq?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** [ID] Region */
  region?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
};

/** SearchKeyword */
export type Club_WorldFamilyClub_SearchKeyword = Node & {
  __typename?: 'Club_WorldFamilyClub_SearchKeyword';
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** KeyWord */
  keyWord?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_SearchKeywordConnection = {
  __typename?: 'Club_WorldFamilyClub_SearchKeywordConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_SearchKeywordEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_SearchKeyword>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_SearchKeywordEdge = {
  __typename?: 'Club_WorldFamilyClub_SearchKeywordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_SearchKeyword;
};

/** SearchKeyword */
export type Club_WorldFamilyClub_SearchKeywordFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_SearchKeywordFilterInput>>;
  /** KeyWord */
  keyWord?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_SearchKeywordFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<ShortOperationFilterInput>;
};

/** SearchKeyword */
export type Club_WorldFamilyClub_SearchKeywordInput = {
  /** KeyWord */
  keyWord?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Short']['input'];
};

/** SearchKeyword */
export type Club_WorldFamilyClub_SearchKeywordSortInput = {
  /** KeyWord */
  keyWord?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
};

/** Service */
export type Club_WorldFamilyClub_Service = Node & {
  __typename?: 'Club_WorldFamilyClub_Service';
  /** [ID] ServiceType */
  id: Scalars['ID']['output'];
  /** ServiceName */
  serviceName?: Maybe<Scalars['String']['output']>;
};

/** ServiceBannerDefault */
export type Club_WorldFamilyClub_ServiceBannerDefault = Node & {
  __typename?: 'Club_WorldFamilyClub_ServiceBannerDefault';
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** ImageFilename */
  imageFilename?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] PublishTime */
  publishTime?: Maybe<Scalars['DateTime']['output']>;
  /** Service */
  service?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] StopTime */
  stopTime?: Maybe<Scalars['DateTime']['output']>;
  /** Theme */
  theme?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_ServiceBannerDefaultConnection = {
  __typename?: 'Club_WorldFamilyClub_ServiceBannerDefaultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_ServiceBannerDefaultEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_ServiceBannerDefault>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_ServiceBannerDefaultEdge = {
  __typename?: 'Club_WorldFamilyClub_ServiceBannerDefaultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_ServiceBannerDefault;
};

/** ServiceBannerDefault */
export type Club_WorldFamilyClub_ServiceBannerDefaultFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerDefaultFilterInput>>;
  /** ImageFilename */
  imageFilename?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerDefaultFilterInput>>;
  /** [NOT NULL] PublishTime */
  publishTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** Service */
  service?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] StopTime */
  stopTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Theme */
  theme?: InputMaybe<StringOperationFilterInput>;
};

/** ServiceBannerDefault */
export type Club_WorldFamilyClub_ServiceBannerDefaultInput = {
  /** ImageFilename */
  imageFilename?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] PublishTime */
  publishTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** Service */
  service?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] StopTime */
  stopTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Theme */
  theme?: InputMaybe<Scalars['String']['input']>;
};

/** ServiceBannerDefault */
export type Club_WorldFamilyClub_ServiceBannerDefaultSortInput = {
  /** ImageFilename */
  imageFilename?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** [NOT NULL] PublishTime */
  publishTime?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Service */
  service?: InputMaybe<SortEnumType>;
  /** [NOT NULL] StopTime */
  stopTime?: InputMaybe<SortEnumType>;
  /** Theme */
  theme?: InputMaybe<SortEnumType>;
};

/** ServiceBannerScheduled */
export type Club_WorldFamilyClub_ServiceBannerScheduled = Node & {
  __typename?: 'Club_WorldFamilyClub_ServiceBannerScheduled';
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** ImageFilename */
  imageFilename?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] PublishTime */
  publishTime?: Maybe<Scalars['DateTime']['output']>;
  /** Service */
  service?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] StopTime */
  stopTime?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_ServiceBannerScheduledConnection = {
  __typename?: 'Club_WorldFamilyClub_ServiceBannerScheduledConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_ServiceBannerScheduledEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_ServiceBannerScheduled>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_ServiceBannerScheduledEdge = {
  __typename?: 'Club_WorldFamilyClub_ServiceBannerScheduledEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_ServiceBannerScheduled;
};

/** ServiceBannerScheduled */
export type Club_WorldFamilyClub_ServiceBannerScheduledFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerScheduledFilterInput>>;
  /** ImageFilename */
  imageFilename?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerScheduledFilterInput>>;
  /** [NOT NULL] PublishTime */
  publishTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** Service */
  service?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] StopTime */
  stopTime?: InputMaybe<DateTimeOperationFilterInput>;
};

/** ServiceBannerScheduled */
export type Club_WorldFamilyClub_ServiceBannerScheduledInput = {
  /** ImageFilename */
  imageFilename?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] PublishTime */
  publishTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** Service */
  service?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] StopTime */
  stopTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** ServiceBannerScheduled */
export type Club_WorldFamilyClub_ServiceBannerScheduledSortInput = {
  /** ImageFilename */
  imageFilename?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** [NOT NULL] PublishTime */
  publishTime?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Service */
  service?: InputMaybe<SortEnumType>;
  /** [NOT NULL] StopTime */
  stopTime?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_ServiceConnection = {
  __typename?: 'Club_WorldFamilyClub_ServiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_ServiceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_Service>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_ServiceEdge = {
  __typename?: 'Club_WorldFamilyClub_ServiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_Service;
};

/** Service */
export type Club_WorldFamilyClub_ServiceFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_ServiceFilterInput>>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_ServiceFilterInput>>;
  /** ServiceName */
  serviceName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] ServiceType */
  serviceType?: InputMaybe<StringOperationFilterInput>;
};

/** Service */
export type Club_WorldFamilyClub_ServiceInput = {
  /** ServiceName */
  serviceName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] ServiceType */
  serviceType?: InputMaybe<Scalars['String']['input']>;
};

/** ServiceSearch */
export type Club_WorldFamilyClub_ServiceSearch = Node & {
  __typename?: 'Club_WorldFamilyClub_ServiceSearch';
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** SearchDate */
  searchDate?: Maybe<Scalars['DateTime']['output']>;
  /** SearchText */
  searchText?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_ServiceSearchConnection = {
  __typename?: 'Club_WorldFamilyClub_ServiceSearchConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_ServiceSearchEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_ServiceSearch>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_ServiceSearchEdge = {
  __typename?: 'Club_WorldFamilyClub_ServiceSearchEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_ServiceSearch;
};

/** ServiceSearch */
export type Club_WorldFamilyClub_ServiceSearchFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_ServiceSearchFilterInput>>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_ServiceSearchFilterInput>>;
  /** SearchDate */
  searchDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** SearchText */
  searchText?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<ShortOperationFilterInput>;
};

/** ServiceSearch */
export type Club_WorldFamilyClub_ServiceSearchInput = {
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** SearchDate */
  searchDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** SearchText */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Short']['input'];
};

/** ServiceSearch */
export type Club_WorldFamilyClub_ServiceSearchSortInput = {
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** SearchDate */
  searchDate?: InputMaybe<SortEnumType>;
  /** SearchText */
  searchText?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
};

/** Service */
export type Club_WorldFamilyClub_ServiceSortInput = {
  /** ServiceName */
  serviceName?: InputMaybe<SortEnumType>;
  /** [ID] ServiceType */
  serviceType?: InputMaybe<SortEnumType>;
};

/** SmsCertification */
export type Club_WorldFamilyClub_SmsCertification = Node & {
  __typename?: 'Club_WorldFamilyClub_SmsCertification';
  /** CreationDate */
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] ExpireDate */
  expireDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] No */
  id: Scalars['ID']['output'];
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** Number */
  number?: Maybe<Scalars['String']['output']>;
  /** SmsType */
  smsType?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_SmsCertificationConnection = {
  __typename?: 'Club_WorldFamilyClub_SmsCertificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_SmsCertificationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_SmsCertification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_SmsCertificationEdge = {
  __typename?: 'Club_WorldFamilyClub_SmsCertificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_SmsCertification;
};

/** SmsCertification */
export type Club_WorldFamilyClub_SmsCertificationFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_SmsCertificationFilterInput>>;
  /** CreationDate */
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] ExpireDate */
  expireDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  /** [ID] No */
  no?: InputMaybe<IntOperationFilterInput>;
  /** Number */
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_SmsCertificationFilterInput>>;
  /** SmsType */
  smsType?: InputMaybe<StringOperationFilterInput>;
};

/** SmsCertification */
export type Club_WorldFamilyClub_SmsCertificationInput = {
  /** CreationDate */
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] ExpireDate */
  expireDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [ID] No */
  no?: InputMaybe<Scalars['Int']['input']>;
  /** Number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** SmsType */
  smsType?: InputMaybe<Scalars['String']['input']>;
};

/** SmsCertification */
export type Club_WorldFamilyClub_SmsCertificationSortInput = {
  /** CreationDate */
  creationDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ExpireDate */
  expireDate?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [ID] No */
  no?: InputMaybe<SortEnumType>;
  /** Number */
  number?: InputMaybe<SortEnumType>;
  /** SmsType */
  smsType?: InputMaybe<SortEnumType>;
};

/** SmsVerificationCode */
export type Club_WorldFamilyClub_SmsVerificationCode = Node & {
  __typename?: 'Club_WorldFamilyClub_SmsVerificationCode';
  /** [NOT NULL] CreateTime */
  createTime?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] ExpireTime */
  expireTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] IsUsed */
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] PhoneNumber */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] VerificationCode */
  verificationCode?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] VerificationCodeType */
  verificationCodeType?: Maybe<Scalars['Byte']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_SmsVerificationCodeConnection = {
  __typename?: 'Club_WorldFamilyClub_SmsVerificationCodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_SmsVerificationCodeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_SmsVerificationCode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_SmsVerificationCodeEdge = {
  __typename?: 'Club_WorldFamilyClub_SmsVerificationCodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_SmsVerificationCode;
};

/** SmsVerificationCode */
export type Club_WorldFamilyClub_SmsVerificationCodeFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_SmsVerificationCodeFilterInput>>;
  /** [NOT NULL] CreateTime */
  createTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] ExpireTime */
  expireTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] IsUsed */
  isUsed?: InputMaybe<BooleanOperationFilterInput>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_SmsVerificationCodeFilterInput>>;
  /** [NOT NULL] PhoneNumber */
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<LongOperationFilterInput>;
  /** [NOT NULL] VerificationCode */
  verificationCode?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] VerificationCodeType */
  verificationCodeType?: InputMaybe<ByteOperationFilterInput>;
};

/** SmsVerificationCode */
export type Club_WorldFamilyClub_SmsVerificationCodeInput = {
  /** [NOT NULL] CreateTime */
  createTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] ExpireTime */
  expireTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] IsUsed */
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] PhoneNumber */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Long']['input']>;
  /** [NOT NULL] VerificationCode */
  verificationCode?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] VerificationCodeType */
  verificationCodeType?: InputMaybe<Scalars['Byte']['input']>;
};

/** SmsVerificationCode */
export type Club_WorldFamilyClub_SmsVerificationCodeSortInput = {
  /** [NOT NULL] CreateTime */
  createTime?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ExpireTime */
  expireTime?: InputMaybe<SortEnumType>;
  /** [NOT NULL] IsUsed */
  isUsed?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<SortEnumType>;
  /** [NOT NULL] PhoneNumber */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] VerificationCode */
  verificationCode?: InputMaybe<SortEnumType>;
  /** [NOT NULL] VerificationCodeType */
  verificationCodeType?: InputMaybe<SortEnumType>;
};

/** SurveyTypeForm */
export type Club_WorldFamilyClub_SurveyTypeForm = Node & {
  __typename?: 'Club_WorldFamilyClub_SurveyTypeForm';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** DeadlineHtml */
  deadlineHtml?: Maybe<Scalars['String']['output']>;
  /** FormTitle */
  formTitle?: Maybe<Scalars['String']['output']>;
  /** [ID] FormId */
  id: Scalars['ID']['output'];
  /** PointsTypeNo */
  pointsTypeNo?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** ResultHtml */
  resultHtml?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RewardPoints */
  rewardPoints?: Maybe<Scalars['Int']['output']>;
  /** SurveyDeadline */
  surveyDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** SurveyHtml */
  surveyHtml?: Maybe<Scalars['String']['output']>;
  /** SurveyType */
  surveyType?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_SurveyTypeFormConnection = {
  __typename?: 'Club_WorldFamilyClub_SurveyTypeFormConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_SurveyTypeFormEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_SurveyTypeForm>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_SurveyTypeFormEdge = {
  __typename?: 'Club_WorldFamilyClub_SurveyTypeFormEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_SurveyTypeForm;
};

/** SurveyTypeForm */
export type Club_WorldFamilyClub_SurveyTypeFormFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** DeadlineHtml */
  deadlineHtml?: InputMaybe<StringOperationFilterInput>;
  /** [ID] FormId */
  formId?: InputMaybe<StringOperationFilterInput>;
  /** FormTitle */
  formTitle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormFilterInput>>;
  /** PointsTypeNo */
  pointsTypeNo?: InputMaybe<StringOperationFilterInput>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** ResultHtml */
  resultHtml?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RewardPoints */
  rewardPoints?: InputMaybe<IntOperationFilterInput>;
  /** SurveyDeadline */
  surveyDeadline?: InputMaybe<DateTimeOperationFilterInput>;
  /** SurveyHtml */
  surveyHtml?: InputMaybe<StringOperationFilterInput>;
  /** SurveyType */
  surveyType?: InputMaybe<StringOperationFilterInput>;
};

/** SurveyTypeForm */
export type Club_WorldFamilyClub_SurveyTypeFormInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** DeadlineHtml */
  deadlineHtml?: InputMaybe<Scalars['String']['input']>;
  /** [ID] FormId */
  formId?: InputMaybe<Scalars['String']['input']>;
  /** FormTitle */
  formTitle?: InputMaybe<Scalars['String']['input']>;
  /** PointsTypeNo */
  pointsTypeNo?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** ResultHtml */
  resultHtml?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RewardPoints */
  rewardPoints?: InputMaybe<Scalars['Int']['input']>;
  /** SurveyDeadline */
  surveyDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  /** SurveyHtml */
  surveyHtml?: InputMaybe<Scalars['String']['input']>;
  /** SurveyType */
  surveyType?: InputMaybe<Scalars['String']['input']>;
};

/** SurveyTypeFormList */
export type Club_WorldFamilyClub_SurveyTypeFormList = Node & {
  __typename?: 'Club_WorldFamilyClub_SurveyTypeFormList';
  /** ActionDate */
  actionDate?: Maybe<Scalars['String']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** ChildNumber */
  childNumber?: Maybe<Scalars['String']['output']>;
  /** Deadline */
  deadline?: Maybe<Scalars['DateTime']['output']>;
  /** FormId */
  formId?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** LandedAtUtc */
  landedAtUtc?: Maybe<Scalars['DateTime']['output']>;
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** RandomType */
  randomType?: Maybe<Scalars['String']['output']>;
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** ResponseToken */
  responseToken?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RewardFlag */
  rewardFlag?: Maybe<Scalars['Boolean']['output']>;
  /** SubmittedAtUtc */
  submittedAtUtc?: Maybe<Scalars['DateTime']['output']>;
  /** UniqueId */
  uniqueId?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_SurveyTypeFormListConnection = {
  __typename?: 'Club_WorldFamilyClub_SurveyTypeFormListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_SurveyTypeFormListEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_SurveyTypeFormList>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_SurveyTypeFormListEdge = {
  __typename?: 'Club_WorldFamilyClub_SurveyTypeFormListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_SurveyTypeFormList;
};

/** SurveyTypeFormList */
export type Club_WorldFamilyClub_SurveyTypeFormListFilterInput = {
  /** ActionDate */
  actionDate?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormListFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** ChildNumber */
  childNumber?: InputMaybe<StringOperationFilterInput>;
  /** Deadline */
  deadline?: InputMaybe<DateTimeOperationFilterInput>;
  /** FormId */
  formId?: InputMaybe<StringOperationFilterInput>;
  /** LandedAtUtc */
  landedAtUtc?: InputMaybe<DateTimeOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormListFilterInput>>;
  /** RandomType */
  randomType?: InputMaybe<StringOperationFilterInput>;
  /** Region */
  region?: InputMaybe<StringOperationFilterInput>;
  /** ResponseToken */
  responseToken?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RewardFlag */
  rewardFlag?: InputMaybe<BooleanOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<LongOperationFilterInput>;
  /** SubmittedAtUtc */
  submittedAtUtc?: InputMaybe<DateTimeOperationFilterInput>;
  /** UniqueId */
  uniqueId?: InputMaybe<StringOperationFilterInput>;
};

/** SurveyTypeFormList */
export type Club_WorldFamilyClub_SurveyTypeFormListInput = {
  /** ActionDate */
  actionDate?: InputMaybe<Scalars['String']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** ChildNumber */
  childNumber?: InputMaybe<Scalars['String']['input']>;
  /** Deadline */
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  /** FormId */
  formId?: InputMaybe<Scalars['String']['input']>;
  /** LandedAtUtc */
  landedAtUtc?: InputMaybe<Scalars['DateTime']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** RandomType */
  randomType?: InputMaybe<Scalars['String']['input']>;
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** ResponseToken */
  responseToken?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RewardFlag */
  rewardFlag?: InputMaybe<Scalars['Boolean']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Long']['input']>;
  /** SubmittedAtUtc */
  submittedAtUtc?: InputMaybe<Scalars['DateTime']['input']>;
  /** UniqueId */
  uniqueId?: InputMaybe<Scalars['String']['input']>;
};

/** SurveyTypeFormList */
export type Club_WorldFamilyClub_SurveyTypeFormListSortInput = {
  /** ActionDate */
  actionDate?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** ChildNumber */
  childNumber?: InputMaybe<SortEnumType>;
  /** Deadline */
  deadline?: InputMaybe<SortEnumType>;
  /** FormId */
  formId?: InputMaybe<SortEnumType>;
  /** LandedAtUtc */
  landedAtUtc?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** RandomType */
  randomType?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** ResponseToken */
  responseToken?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RewardFlag */
  rewardFlag?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** SubmittedAtUtc */
  submittedAtUtc?: InputMaybe<SortEnumType>;
  /** UniqueId */
  uniqueId?: InputMaybe<SortEnumType>;
};

/** SurveyTypeForm */
export type Club_WorldFamilyClub_SurveyTypeFormSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** DeadlineHtml */
  deadlineHtml?: InputMaybe<SortEnumType>;
  /** [ID] FormId */
  formId?: InputMaybe<SortEnumType>;
  /** FormTitle */
  formTitle?: InputMaybe<SortEnumType>;
  /** PointsTypeNo */
  pointsTypeNo?: InputMaybe<SortEnumType>;
  /** Region */
  region?: InputMaybe<SortEnumType>;
  /** ResultHtml */
  resultHtml?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RewardPoints */
  rewardPoints?: InputMaybe<SortEnumType>;
  /** SurveyDeadline */
  surveyDeadline?: InputMaybe<SortEnumType>;
  /** SurveyHtml */
  surveyHtml?: InputMaybe<SortEnumType>;
  /** SurveyType */
  surveyType?: InputMaybe<SortEnumType>;
};

/** TeSong */
export type Club_WorldFamilyClub_TeSong = Node & {
  __typename?: 'Club_WorldFamilyClub_TeSong';
  /** [COLLECTION] FaceCallSongPublics */
  faceCallSongPublics?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallSongPublic>>>;
  /** [COLLECTION] FaceCallSongs */
  faceCallSongs?: Maybe<Array<Maybe<Club_WorldFamilyClub_FaceCallSong>>>;
  /** HkTeSongNumber */
  hkTeSongNumber?: Maybe<Scalars['String']['output']>;
  /** [ID] TeSongId */
  id: Scalars['ID']['output'];
  /** KrTeSongNumber */
  krTeSongNumber?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] MyFavoriteFaceCallSongs */
  myFavoriteFaceCallSongs?: Maybe<Array<Maybe<Club_WorldFamilyClub_MyFavoriteFaceCallSong>>>;
  /** [NOT NULL] Product */
  product?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] PublishdSongs */
  publishdSongs?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] SongName */
  songName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] TeSongNumber */
  teSongNumber?: Maybe<Scalars['String']['output']>;
  /** TwTeSongNumber */
  twTeSongNumber?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TeSongConnection = {
  __typename?: 'Club_WorldFamilyClub_TeSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TeSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TeSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TeSongEdge = {
  __typename?: 'Club_WorldFamilyClub_TeSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TeSong;
};

/** TeSong */
export type Club_WorldFamilyClub_TeSongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TeSongFilterInput>>;
  /** [COLLECTION] FaceCallSongPublics */
  faceCallSongPublics?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  /** [COLLECTION] FaceCallSongs */
  faceCallSongs?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongFilterInput>;
  /** HkTeSongNumber */
  hkTeSongNumber?: InputMaybe<StringOperationFilterInput>;
  /** KrTeSongNumber */
  krTeSongNumber?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] MyFavoriteFaceCallSongs */
  myFavoriteFaceCallSongs?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TeSongFilterInput>>;
  /** [NOT NULL] Product */
  product?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] PublishdSongs */
  publishdSongs?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] SongName */
  songName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TeSongId */
  teSongId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] TeSongNumber */
  teSongNumber?: InputMaybe<StringOperationFilterInput>;
  /** TwTeSongNumber */
  twTeSongNumber?: InputMaybe<StringOperationFilterInput>;
};

/** TeSong */
export type Club_WorldFamilyClub_TeSongInput = {
  /** [COLLECTION] FaceCallSongPublics */
  faceCallSongPublics?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicInput>>>;
  /** [COLLECTION] FaceCallSongs */
  faceCallSongs?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_FaceCallSongInput>>>;
  /** HkTeSongNumber */
  hkTeSongNumber?: InputMaybe<Scalars['String']['input']>;
  /** KrTeSongNumber */
  krTeSongNumber?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] MyFavoriteFaceCallSongs */
  myFavoriteFaceCallSongs?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongInput>>>;
  /** [NOT NULL] Product */
  product?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] PublishdSongs */
  publishdSongs?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] SongName */
  songName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TeSongId */
  teSongId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] TeSongNumber */
  teSongNumber?: InputMaybe<Scalars['String']['input']>;
  /** TwTeSongNumber */
  twTeSongNumber?: InputMaybe<Scalars['String']['input']>;
};

/** TeSong */
export type Club_WorldFamilyClub_TeSongSortInput = {
  /** HkTeSongNumber */
  hkTeSongNumber?: InputMaybe<SortEnumType>;
  /** KrTeSongNumber */
  krTeSongNumber?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Product */
  product?: InputMaybe<SortEnumType>;
  /** [NOT NULL] PublishdSongs */
  publishdSongs?: InputMaybe<SortEnumType>;
  /** [NOT NULL] SongName */
  songName?: InputMaybe<SortEnumType>;
  /** [ID] TeSongId */
  teSongId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TeSongNumber */
  teSongNumber?: InputMaybe<SortEnumType>;
  /** TwTeSongNumber */
  twTeSongNumber?: InputMaybe<SortEnumType>;
};

/** Temp20240408Graduation */
export type Club_WorldFamilyClub_Temp20240408Graduation = Node & {
  __typename?: 'Club_WorldFamilyClub_Temp20240408Graduation';
  /** Age */
  age?: Maybe<Scalars['String']['output']>;
  /** ChildEnglishName */
  childEnglishName?: Maybe<Scalars['String']['output']>;
  /** ChildId */
  childId?: Maybe<Scalars['String']['output']>;
  /** ChildName */
  childName?: Maybe<Scalars['String']['output']>;
  /** Excellent */
  excellent?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** MemNo */
  memNo?: Maybe<Scalars['String']['output']>;
  /** PassDate */
  passDate?: Maybe<Scalars['DateTime']['output']>;
  /** RegDate */
  regDate?: Maybe<Scalars['DateTime']['output']>;
  /** Result */
  result?: Maybe<Scalars['String']['output']>;
  /** Task1 */
  task1?: Maybe<Scalars['String']['output']>;
  /** Task2 */
  task2?: Maybe<Scalars['String']['output']>;
  /** Task3 */
  task3?: Maybe<Scalars['String']['output']>;
  /** Task4 */
  task4?: Maybe<Scalars['String']['output']>;
  /** Task5 */
  task5?: Maybe<Scalars['String']['output']>;
  /** Task6 */
  task6?: Maybe<Scalars['String']['output']>;
  /** Task7 */
  task7?: Maybe<Scalars['String']['output']>;
  /** Task8 */
  task8?: Maybe<Scalars['String']['output']>;
};

/** Temp20240408Graduation1 */
export type Club_WorldFamilyClub_Temp20240408Graduation1 = Node & {
  __typename?: 'Club_WorldFamilyClub_Temp20240408Graduation1';
  /** [NOT NULL] Age */
  age?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildEnglishName */
  childEnglishName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildName */
  childName?: Maybe<Scalars['String']['output']>;
  /** ChildSeq */
  childSeq?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Excellent */
  excellent?: Maybe<Scalars['String']['output']>;
  /** [ID] MemNo */
  id: Scalars['ID']['output'];
  /** PassDate */
  passDate?: Maybe<Scalars['String']['output']>;
  /** RegDate */
  regDate?: Maybe<Scalars['String']['output']>;
  /** Result */
  result?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Task1 */
  task1?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Task2 */
  task2?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Task3 */
  task3?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Task4 */
  task4?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Task5 */
  task5?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Task6 */
  task6?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Task7 */
  task7?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Task8 */
  task8?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_Temp20240408Graduation1Connection = {
  __typename?: 'Club_WorldFamilyClub_Temp20240408Graduation1Connection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1Edge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_Temp20240408Graduation1Edge = {
  __typename?: 'Club_WorldFamilyClub_Temp20240408Graduation1Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_Temp20240408Graduation1;
};

/** Temp20240408Graduation1 */
export type Club_WorldFamilyClub_Temp20240408Graduation1FilterInput = {
  /** [NOT NULL] Age */
  age?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1FilterInput>>;
  /** [NOT NULL] ChildEnglishName */
  childEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildName */
  childName?: InputMaybe<StringOperationFilterInput>;
  /** ChildSeq */
  childSeq?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Excellent */
  excellent?: InputMaybe<StringOperationFilterInput>;
  /** [ID] MemNo */
  memNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1FilterInput>>;
  /** PassDate */
  passDate?: InputMaybe<StringOperationFilterInput>;
  /** RegDate */
  regDate?: InputMaybe<StringOperationFilterInput>;
  /** Result */
  result?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Task1 */
  task1?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Task2 */
  task2?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Task3 */
  task3?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Task4 */
  task4?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Task5 */
  task5?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Task6 */
  task6?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Task7 */
  task7?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Task8 */
  task8?: InputMaybe<StringOperationFilterInput>;
};

/** Temp20240408Graduation1 */
export type Club_WorldFamilyClub_Temp20240408Graduation1Input = {
  /** [NOT NULL] Age */
  age?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildEnglishName */
  childEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildName */
  childName?: InputMaybe<Scalars['String']['input']>;
  /** ChildSeq */
  childSeq?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Excellent */
  excellent?: InputMaybe<Scalars['String']['input']>;
  /** [ID] MemNo */
  memNo: Scalars['String']['input'];
  /** PassDate */
  passDate?: InputMaybe<Scalars['String']['input']>;
  /** RegDate */
  regDate?: InputMaybe<Scalars['String']['input']>;
  /** Result */
  result?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Task1 */
  task1?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Task2 */
  task2?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Task3 */
  task3?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Task4 */
  task4?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Task5 */
  task5?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Task6 */
  task6?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Task7 */
  task7?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Task8 */
  task8?: InputMaybe<Scalars['String']['input']>;
};

/** Temp20240408Graduation1 */
export type Club_WorldFamilyClub_Temp20240408Graduation1SortInput = {
  /** [NOT NULL] Age */
  age?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildEnglishName */
  childEnglishName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildName */
  childName?: InputMaybe<SortEnumType>;
  /** ChildSeq */
  childSeq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Excellent */
  excellent?: InputMaybe<SortEnumType>;
  /** [ID] MemNo */
  memNo?: InputMaybe<SortEnumType>;
  /** PassDate */
  passDate?: InputMaybe<SortEnumType>;
  /** RegDate */
  regDate?: InputMaybe<SortEnumType>;
  /** Result */
  result?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Task1 */
  task1?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Task2 */
  task2?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Task3 */
  task3?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Task4 */
  task4?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Task5 */
  task5?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Task6 */
  task6?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Task7 */
  task7?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Task8 */
  task8?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_Temp20240408GraduationConnection = {
  __typename?: 'Club_WorldFamilyClub_Temp20240408GraduationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_Temp20240408GraduationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_Temp20240408Graduation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_Temp20240408GraduationEdge = {
  __typename?: 'Club_WorldFamilyClub_Temp20240408GraduationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_Temp20240408Graduation;
};

/** Temp20240408Graduation */
export type Club_WorldFamilyClub_Temp20240408GraduationFilterInput = {
  /** Age */
  age?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408GraduationFilterInput>>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** ChildId */
  childId?: InputMaybe<StringOperationFilterInput>;
  /** ChildName */
  childName?: InputMaybe<StringOperationFilterInput>;
  /** Excellent */
  excellent?: InputMaybe<StringOperationFilterInput>;
  /** MemNo */
  memNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408GraduationFilterInput>>;
  /** PassDate */
  passDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** RegDate */
  regDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Result */
  result?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<StringOperationFilterInput>;
  /** Task1 */
  task1?: InputMaybe<StringOperationFilterInput>;
  /** Task2 */
  task2?: InputMaybe<StringOperationFilterInput>;
  /** Task3 */
  task3?: InputMaybe<StringOperationFilterInput>;
  /** Task4 */
  task4?: InputMaybe<StringOperationFilterInput>;
  /** Task5 */
  task5?: InputMaybe<StringOperationFilterInput>;
  /** Task6 */
  task6?: InputMaybe<StringOperationFilterInput>;
  /** Task7 */
  task7?: InputMaybe<StringOperationFilterInput>;
  /** Task8 */
  task8?: InputMaybe<StringOperationFilterInput>;
};

/** Temp20240408Graduation */
export type Club_WorldFamilyClub_Temp20240408GraduationInput = {
  /** Age */
  age?: InputMaybe<Scalars['String']['input']>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** ChildId */
  childId?: InputMaybe<Scalars['String']['input']>;
  /** ChildName */
  childName?: InputMaybe<Scalars['String']['input']>;
  /** Excellent */
  excellent?: InputMaybe<Scalars['String']['input']>;
  /** MemNo */
  memNo?: InputMaybe<Scalars['String']['input']>;
  /** PassDate */
  passDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** RegDate */
  regDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Result */
  result?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['String']['input'];
  /** Task1 */
  task1?: InputMaybe<Scalars['String']['input']>;
  /** Task2 */
  task2?: InputMaybe<Scalars['String']['input']>;
  /** Task3 */
  task3?: InputMaybe<Scalars['String']['input']>;
  /** Task4 */
  task4?: InputMaybe<Scalars['String']['input']>;
  /** Task5 */
  task5?: InputMaybe<Scalars['String']['input']>;
  /** Task6 */
  task6?: InputMaybe<Scalars['String']['input']>;
  /** Task7 */
  task7?: InputMaybe<Scalars['String']['input']>;
  /** Task8 */
  task8?: InputMaybe<Scalars['String']['input']>;
};

/** Temp20240408Graduation */
export type Club_WorldFamilyClub_Temp20240408GraduationSortInput = {
  /** Age */
  age?: InputMaybe<SortEnumType>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<SortEnumType>;
  /** ChildId */
  childId?: InputMaybe<SortEnumType>;
  /** ChildName */
  childName?: InputMaybe<SortEnumType>;
  /** Excellent */
  excellent?: InputMaybe<SortEnumType>;
  /** MemNo */
  memNo?: InputMaybe<SortEnumType>;
  /** PassDate */
  passDate?: InputMaybe<SortEnumType>;
  /** RegDate */
  regDate?: InputMaybe<SortEnumType>;
  /** Result */
  result?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Task1 */
  task1?: InputMaybe<SortEnumType>;
  /** Task2 */
  task2?: InputMaybe<SortEnumType>;
  /** Task3 */
  task3?: InputMaybe<SortEnumType>;
  /** Task4 */
  task4?: InputMaybe<SortEnumType>;
  /** Task5 */
  task5?: InputMaybe<SortEnumType>;
  /** Task6 */
  task6?: InputMaybe<SortEnumType>;
  /** Task7 */
  task7?: InputMaybe<SortEnumType>;
  /** Task8 */
  task8?: InputMaybe<SortEnumType>;
};

/** TempFaceCall */
export type Club_WorldFamilyClub_TempFaceCall = Node & {
  __typename?: 'Club_WorldFamilyClub_TempFaceCall';
  /** CreatedDate */
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** PerformerName */
  performerName?: Maybe<Scalars['String']['output']>;
  /** SongName */
  songName?: Maybe<Scalars['String']['output']>;
  /** Theme */
  theme?: Maybe<Scalars['String']['output']>;
  /** VimeoIdJp */
  vimeoIdJp?: Maybe<Scalars['String']['output']>;
  /** VimeoIdTw */
  vimeoIdTw?: Maybe<Scalars['String']['output']>;
  /** VimeoLink */
  vimeoLink?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempFaceCallConnection = {
  __typename?: 'Club_WorldFamilyClub_TempFaceCallConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempFaceCall>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempFaceCallEdge = {
  __typename?: 'Club_WorldFamilyClub_TempFaceCallEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempFaceCall;
};

/** TempFaceCall */
export type Club_WorldFamilyClub_TempFaceCallFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallFilterInput>>;
  /** CreatedDate */
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallFilterInput>>;
  /** PerformerName */
  performerName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<FloatOperationFilterInput>;
  /** SongName */
  songName?: InputMaybe<StringOperationFilterInput>;
  /** Theme */
  theme?: InputMaybe<StringOperationFilterInput>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<StringOperationFilterInput>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<StringOperationFilterInput>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<StringOperationFilterInput>;
};

/** TempFaceCallHk */
export type Club_WorldFamilyClub_TempFaceCallHk = Node & {
  __typename?: 'Club_WorldFamilyClub_TempFaceCallHk';
  /** CreatedDate */
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** PerformerName */
  performerName?: Maybe<Scalars['String']['output']>;
  /** SongName */
  songName?: Maybe<Scalars['String']['output']>;
  /** Theme */
  theme?: Maybe<Scalars['String']['output']>;
  /** VimeoIdJp */
  vimeoIdJp?: Maybe<Scalars['String']['output']>;
  /** VimeoIdTw */
  vimeoIdTw?: Maybe<Scalars['String']['output']>;
  /** VimeoLink */
  vimeoLink?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempFaceCallHkConnection = {
  __typename?: 'Club_WorldFamilyClub_TempFaceCallHkConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallHkEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallHk>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempFaceCallHkEdge = {
  __typename?: 'Club_WorldFamilyClub_TempFaceCallHkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempFaceCallHk;
};

/** TempFaceCallHk */
export type Club_WorldFamilyClub_TempFaceCallHkFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallHkFilterInput>>;
  /** CreatedDate */
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallHkFilterInput>>;
  /** PerformerName */
  performerName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<FloatOperationFilterInput>;
  /** SongName */
  songName?: InputMaybe<StringOperationFilterInput>;
  /** Theme */
  theme?: InputMaybe<StringOperationFilterInput>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<StringOperationFilterInput>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<StringOperationFilterInput>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<StringOperationFilterInput>;
};

/** TempFaceCallHk */
export type Club_WorldFamilyClub_TempFaceCallHkInput = {
  /** CreatedDate */
  createdDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** PerformerName */
  performerName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Float']['input'];
  /** SongName */
  songName?: InputMaybe<Scalars['String']['input']>;
  /** Theme */
  theme?: InputMaybe<Scalars['String']['input']>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<Scalars['String']['input']>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<Scalars['String']['input']>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<Scalars['String']['input']>;
};

/** TempFaceCallHk */
export type Club_WorldFamilyClub_TempFaceCallHkSortInput = {
  /** CreatedDate */
  createdDate?: InputMaybe<SortEnumType>;
  /** PerformerName */
  performerName?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** SongName */
  songName?: InputMaybe<SortEnumType>;
  /** Theme */
  theme?: InputMaybe<SortEnumType>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<SortEnumType>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<SortEnumType>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<SortEnumType>;
};

/** TempFaceCall */
export type Club_WorldFamilyClub_TempFaceCallInput = {
  /** CreatedDate */
  createdDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** PerformerName */
  performerName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Float']['input'];
  /** SongName */
  songName?: InputMaybe<Scalars['String']['input']>;
  /** Theme */
  theme?: InputMaybe<Scalars['String']['input']>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<Scalars['String']['input']>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<Scalars['String']['input']>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<Scalars['String']['input']>;
};

/** TempFaceCallKr */
export type Club_WorldFamilyClub_TempFaceCallKr = Node & {
  __typename?: 'Club_WorldFamilyClub_TempFaceCallKr';
  /** CreatedDate */
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** PerformerName */
  performerName?: Maybe<Scalars['String']['output']>;
  /** SongName */
  songName?: Maybe<Scalars['String']['output']>;
  /** Theme */
  theme?: Maybe<Scalars['String']['output']>;
  /** VimeoIdJp */
  vimeoIdJp?: Maybe<Scalars['String']['output']>;
  /** VimeoIdTw */
  vimeoIdTw?: Maybe<Scalars['String']['output']>;
  /** VimeoLink */
  vimeoLink?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempFaceCallKrConnection = {
  __typename?: 'Club_WorldFamilyClub_TempFaceCallKrConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallKrEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempFaceCallKr>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempFaceCallKrEdge = {
  __typename?: 'Club_WorldFamilyClub_TempFaceCallKrEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempFaceCallKr;
};

/** TempFaceCallKr */
export type Club_WorldFamilyClub_TempFaceCallKrFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallKrFilterInput>>;
  /** CreatedDate */
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallKrFilterInput>>;
  /** PerformerName */
  performerName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<FloatOperationFilterInput>;
  /** SongName */
  songName?: InputMaybe<StringOperationFilterInput>;
  /** Theme */
  theme?: InputMaybe<StringOperationFilterInput>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<StringOperationFilterInput>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<StringOperationFilterInput>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<StringOperationFilterInput>;
};

/** TempFaceCallKr */
export type Club_WorldFamilyClub_TempFaceCallKrInput = {
  /** CreatedDate */
  createdDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** PerformerName */
  performerName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Float']['input'];
  /** SongName */
  songName?: InputMaybe<Scalars['String']['input']>;
  /** Theme */
  theme?: InputMaybe<Scalars['String']['input']>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<Scalars['String']['input']>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<Scalars['String']['input']>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<Scalars['String']['input']>;
};

/** TempFaceCallKr */
export type Club_WorldFamilyClub_TempFaceCallKrSortInput = {
  /** CreatedDate */
  createdDate?: InputMaybe<SortEnumType>;
  /** PerformerName */
  performerName?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** SongName */
  songName?: InputMaybe<SortEnumType>;
  /** Theme */
  theme?: InputMaybe<SortEnumType>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<SortEnumType>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<SortEnumType>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<SortEnumType>;
};

/** TempFaceCall */
export type Club_WorldFamilyClub_TempFaceCallSortInput = {
  /** CreatedDate */
  createdDate?: InputMaybe<SortEnumType>;
  /** PerformerName */
  performerName?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** SongName */
  songName?: InputMaybe<SortEnumType>;
  /** Theme */
  theme?: InputMaybe<SortEnumType>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<SortEnumType>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<SortEnumType>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<SortEnumType>;
};

/** TempHkAppMyFavorite */
export type Club_WorldFamilyClub_TempHkAppMyFavorite = Node & {
  __typename?: 'Club_WorldFamilyClub_TempHkAppMyFavorite';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** FavouriteType */
  favouriteType?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** LinkNo */
  linkNo?: Maybe<Scalars['String']['output']>;
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempHkAppMyFavoriteConnection = {
  __typename?: 'Club_WorldFamilyClub_TempHkAppMyFavoriteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempHkAppMyFavoriteEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempHkAppMyFavorite>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempHkAppMyFavoriteEdge = {
  __typename?: 'Club_WorldFamilyClub_TempHkAppMyFavoriteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempHkAppMyFavorite;
};

/** TempHkAppMyFavorite */
export type Club_WorldFamilyClub_TempHkAppMyFavoriteFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempHkAppMyFavoriteFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** FavouriteType */
  favouriteType?: InputMaybe<StringOperationFilterInput>;
  /** LinkNo */
  linkNo?: InputMaybe<StringOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempHkAppMyFavoriteFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
};

/** TempHkAppMyFavorite */
export type Club_WorldFamilyClub_TempHkAppMyFavoriteInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** FavouriteType */
  favouriteType?: InputMaybe<Scalars['String']['input']>;
  /** LinkNo */
  linkNo?: InputMaybe<Scalars['String']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
};

/** TempHkAppMyFavorite */
export type Club_WorldFamilyClub_TempHkAppMyFavoriteSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** FavouriteType */
  favouriteType?: InputMaybe<SortEnumType>;
  /** LinkNo */
  linkNo?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
};

/** TempHkClubappsFacecallCrew */
export type Club_WorldFamilyClub_TempHkClubappsFacecallCrew = Node & {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallCrew';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** CrewName */
  crewName?: Maybe<Scalars['String']['output']>;
  /** CrewPhoto */
  crewPhoto?: Maybe<Scalars['String']['output']>;
  /** CrewPhotoC */
  crewPhotoC?: Maybe<Scalars['String']['output']>;
  /** CrewPhotoR */
  crewPhotoR?: Maybe<Scalars['String']['output']>;
  /** CrewShowName */
  crewShowName?: Maybe<Scalars['String']['output']>;
  /** Enable */
  enable?: Maybe<Scalars['Int']['output']>;
  /** [ID] Rid */
  id: Scalars['ID']['output'];
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempHkClubappsFacecallCrewConnection = {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallCrewConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrewEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrew>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempHkClubappsFacecallCrewEdge = {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallCrewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempHkClubappsFacecallCrew;
};

/** TempHkClubappsFacecallCrew */
export type Club_WorldFamilyClub_TempHkClubappsFacecallCrewFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrewFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** CrewName */
  crewName?: InputMaybe<StringOperationFilterInput>;
  /** CrewPhoto */
  crewPhoto?: InputMaybe<StringOperationFilterInput>;
  /** CrewPhotoC */
  crewPhotoC?: InputMaybe<StringOperationFilterInput>;
  /** CrewPhotoR */
  crewPhotoR?: InputMaybe<StringOperationFilterInput>;
  /** CrewShowName */
  crewShowName?: InputMaybe<StringOperationFilterInput>;
  /** Enable */
  enable?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrewFilterInput>>;
  /** [ID] Rid */
  rid?: InputMaybe<IntOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** TempHkClubappsFacecallCrew */
export type Club_WorldFamilyClub_TempHkClubappsFacecallCrewInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** CrewName */
  crewName?: InputMaybe<Scalars['String']['input']>;
  /** CrewPhoto */
  crewPhoto?: InputMaybe<Scalars['String']['input']>;
  /** CrewPhotoC */
  crewPhotoC?: InputMaybe<Scalars['String']['input']>;
  /** CrewPhotoR */
  crewPhotoR?: InputMaybe<Scalars['String']['input']>;
  /** CrewShowName */
  crewShowName?: InputMaybe<Scalars['String']['input']>;
  /** Enable */
  enable?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] Rid */
  rid?: InputMaybe<Scalars['Int']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** TempHkClubappsFacecallCrew */
export type Club_WorldFamilyClub_TempHkClubappsFacecallCrewSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** CrewName */
  crewName?: InputMaybe<SortEnumType>;
  /** CrewPhoto */
  crewPhoto?: InputMaybe<SortEnumType>;
  /** CrewPhotoC */
  crewPhotoC?: InputMaybe<SortEnumType>;
  /** CrewPhotoR */
  crewPhotoR?: InputMaybe<SortEnumType>;
  /** CrewShowName */
  crewShowName?: InputMaybe<SortEnumType>;
  /** Enable */
  enable?: InputMaybe<SortEnumType>;
  /** [ID] Rid */
  rid?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
};

/** TempHkClubappsFacecallHistory */
export type Club_WorldFamilyClub_TempHkClubappsFacecallHistory = Node & {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallHistory';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** CrewName */
  crewName?: Maybe<Scalars['String']['output']>;
  /** [ID] Rid */
  id: Scalars['ID']['output'];
  /** Memno */
  memno?: Maybe<Scalars['String']['output']>;
  /** SongName */
  songName?: Maybe<Scalars['String']['output']>;
  /** SongRid */
  songRid?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempHkClubappsFacecallHistoryConnection = {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallHistoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempHkClubappsFacecallHistoryEdge = {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempHkClubappsFacecallHistory;
};

/** TempHkClubappsFacecallHistory */
export type Club_WorldFamilyClub_TempHkClubappsFacecallHistoryFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** CrewName */
  crewName?: InputMaybe<StringOperationFilterInput>;
  /** Memno */
  memno?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryFilterInput>>;
  /** [ID] Rid */
  rid?: InputMaybe<IntOperationFilterInput>;
  /** SongName */
  songName?: InputMaybe<StringOperationFilterInput>;
  /** SongRid */
  songRid?: InputMaybe<IntOperationFilterInput>;
};

/** TempHkClubappsFacecallHistory */
export type Club_WorldFamilyClub_TempHkClubappsFacecallHistoryInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** CrewName */
  crewName?: InputMaybe<Scalars['String']['input']>;
  /** Memno */
  memno?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Rid */
  rid?: InputMaybe<Scalars['Int']['input']>;
  /** SongName */
  songName?: InputMaybe<Scalars['String']['input']>;
  /** SongRid */
  songRid?: InputMaybe<Scalars['Int']['input']>;
};

/** TempHkClubappsFacecallHistory */
export type Club_WorldFamilyClub_TempHkClubappsFacecallHistorySortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** CrewName */
  crewName?: InputMaybe<SortEnumType>;
  /** Memno */
  memno?: InputMaybe<SortEnumType>;
  /** [ID] Rid */
  rid?: InputMaybe<SortEnumType>;
  /** SongName */
  songName?: InputMaybe<SortEnumType>;
  /** SongRid */
  songRid?: InputMaybe<SortEnumType>;
};

/** TempHkClubappsFacecallSong */
export type Club_WorldFamilyClub_TempHkClubappsFacecallSong = Node & {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallSong';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** CrewName */
  crewName?: Maybe<Scalars['String']['output']>;
  /** Cuser */
  cuser?: Maybe<Scalars['String']['output']>;
  /** [ID] Rid */
  id: Scalars['ID']['output'];
  /** Seasonholiday */
  seasonholiday?: Maybe<Scalars['String']['output']>;
  /** ShowDate */
  showDate?: Maybe<Scalars['String']['output']>;
  /** SongFrom */
  songFrom?: Maybe<Scalars['String']['output']>;
  /** SongName */
  songName?: Maybe<Scalars['String']['output']>;
  /** SongNo */
  songNo?: Maybe<Scalars['String']['output']>;
  /** TematerialCode */
  tematerialCode?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** Uuser */
  uuser?: Maybe<Scalars['String']['output']>;
  /** VideoLink */
  videoLink?: Maybe<Scalars['String']['output']>;
  /** VimeoId */
  vimeoId?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempHkClubappsFacecallSongConnection = {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempHkClubappsFacecallSongEdge = {
  __typename?: 'Club_WorldFamilyClub_TempHkClubappsFacecallSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempHkClubappsFacecallSong;
};

/** TempHkClubappsFacecallSong */
export type Club_WorldFamilyClub_TempHkClubappsFacecallSongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSongFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** CrewName */
  crewName?: InputMaybe<StringOperationFilterInput>;
  /** Cuser */
  cuser?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSongFilterInput>>;
  /** [ID] Rid */
  rid?: InputMaybe<IntOperationFilterInput>;
  /** Seasonholiday */
  seasonholiday?: InputMaybe<StringOperationFilterInput>;
  /** ShowDate */
  showDate?: InputMaybe<StringOperationFilterInput>;
  /** SongFrom */
  songFrom?: InputMaybe<StringOperationFilterInput>;
  /** SongName */
  songName?: InputMaybe<StringOperationFilterInput>;
  /** SongNo */
  songNo?: InputMaybe<StringOperationFilterInput>;
  /** TematerialCode */
  tematerialCode?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Uuser */
  uuser?: InputMaybe<StringOperationFilterInput>;
  /** VideoLink */
  videoLink?: InputMaybe<StringOperationFilterInput>;
  /** VimeoId */
  vimeoId?: InputMaybe<StringOperationFilterInput>;
};

/** TempHkClubappsFacecallSong */
export type Club_WorldFamilyClub_TempHkClubappsFacecallSongInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** CrewName */
  crewName?: InputMaybe<Scalars['String']['input']>;
  /** Cuser */
  cuser?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Rid */
  rid?: InputMaybe<Scalars['Int']['input']>;
  /** Seasonholiday */
  seasonholiday?: InputMaybe<Scalars['String']['input']>;
  /** ShowDate */
  showDate?: InputMaybe<Scalars['String']['input']>;
  /** SongFrom */
  songFrom?: InputMaybe<Scalars['String']['input']>;
  /** SongName */
  songName?: InputMaybe<Scalars['String']['input']>;
  /** SongNo */
  songNo?: InputMaybe<Scalars['String']['input']>;
  /** TematerialCode */
  tematerialCode?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Uuser */
  uuser?: InputMaybe<Scalars['String']['input']>;
  /** VideoLink */
  videoLink?: InputMaybe<Scalars['String']['input']>;
  /** VimeoId */
  vimeoId?: InputMaybe<Scalars['String']['input']>;
};

/** TempHkClubappsFacecallSong */
export type Club_WorldFamilyClub_TempHkClubappsFacecallSongSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** CrewName */
  crewName?: InputMaybe<SortEnumType>;
  /** Cuser */
  cuser?: InputMaybe<SortEnumType>;
  /** [ID] Rid */
  rid?: InputMaybe<SortEnumType>;
  /** Seasonholiday */
  seasonholiday?: InputMaybe<SortEnumType>;
  /** ShowDate */
  showDate?: InputMaybe<SortEnumType>;
  /** SongFrom */
  songFrom?: InputMaybe<SortEnumType>;
  /** SongName */
  songName?: InputMaybe<SortEnumType>;
  /** SongNo */
  songNo?: InputMaybe<SortEnumType>;
  /** TematerialCode */
  tematerialCode?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** Uuser */
  uuser?: InputMaybe<SortEnumType>;
  /** VideoLink */
  videoLink?: InputMaybe<SortEnumType>;
  /** VimeoId */
  vimeoId?: InputMaybe<SortEnumType>;
};

/** TempHkDmemChild */
export type Club_WorldFamilyClub_TempHkDmemChild = Node & {
  __typename?: 'Club_WorldFamilyClub_TempHkDmemChild';
  /** Achievement */
  achievement?: Maybe<Scalars['String']['output']>;
  /** ActDtl1 */
  actDtl1?: Maybe<Scalars['String']['output']>;
  /** ActDtl2 */
  actDtl2?: Maybe<Scalars['String']['output']>;
  /** ActDtl3 */
  actDtl3?: Maybe<Scalars['String']['output']>;
  /** Birthday */
  birthday?: Maybe<Scalars['String']['output']>;
  /** CapLvl */
  capLvl?: Maybe<Scalars['String']['output']>;
  /** CapPf */
  capPf?: Maybe<Scalars['Int']['output']>;
  /** CardMadeDt */
  cardMadeDt?: Maybe<Scalars['DateTime']['output']>;
  /** CardPhoto */
  cardPhoto?: Maybe<Scalars['String']['output']>;
  /** CardPrint */
  cardPrint?: Maybe<Scalars['Int']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** CompRec */
  compRec?: Maybe<Scalars['String']['output']>;
  /** CompRecDtl */
  compRecDtl?: Maybe<Scalars['String']['output']>;
  /** EmpNameO */
  empNameO?: Maybe<Scalars['String']['output']>;
  /** EmpNameR */
  empNameR?: Maybe<Scalars['String']['output']>;
  /** Gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** GradP */
  gradP?: Maybe<Scalars['Int']['output']>;
  /** GradTkDt */
  gradTkDt?: Maybe<Scalars['String']['output']>;
  /** GraduationDate */
  graduationDate?: Maybe<Scalars['String']['output']>;
  /** Guest */
  guest?: Maybe<Scalars['String']['output']>;
  /** [ID] SeqNo */
  id: Scalars['ID']['output'];
  /** Interest */
  interest?: Maybe<Scalars['String']['output']>;
  /** KinSch */
  kinSch?: Maybe<Scalars['String']['output']>;
  /** MemNo */
  memNo?: Maybe<Scalars['String']['output']>;
  /** MorePhoto */
  morePhoto?: Maybe<Scalars['String']['output']>;
  /** MorePhotoCdt */
  morePhotoCdt?: Maybe<Scalars['DateTime']['output']>;
  /** NameC */
  nameC?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] NameE */
  nameE?: Maybe<Scalars['String']['output']>;
  /** Organizer */
  organizer?: Maybe<Scalars['String']['output']>;
  /** Photo */
  photo?: Maybe<Scalars['String']['output']>;
  /** PriSch */
  priSch?: Maybe<Scalars['String']['output']>;
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** SecSch */
  secSch?: Maybe<Scalars['String']['output']>;
  /** ShowDtl1 */
  showDtl1?: Maybe<Scalars['String']['output']>;
  /** ShowDtl2 */
  showDtl2?: Maybe<Scalars['String']['output']>;
  /** ShowDtl3 */
  showDtl3?: Maybe<Scalars['String']['output']>;
  /** Strength */
  strength?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** WfLibraryInuse */
  wfLibraryInuse?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempHkDmemChildConnection = {
  __typename?: 'Club_WorldFamilyClub_TempHkDmemChildConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempHkDmemChildEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempHkDmemChild>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempHkDmemChildEdge = {
  __typename?: 'Club_WorldFamilyClub_TempHkDmemChildEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempHkDmemChild;
};

/** TempHkDmemChild */
export type Club_WorldFamilyClub_TempHkDmemChildFilterInput = {
  /** Achievement */
  achievement?: InputMaybe<StringOperationFilterInput>;
  /** ActDtl1 */
  actDtl1?: InputMaybe<StringOperationFilterInput>;
  /** ActDtl2 */
  actDtl2?: InputMaybe<StringOperationFilterInput>;
  /** ActDtl3 */
  actDtl3?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempHkDmemChildFilterInput>>;
  /** Birthday */
  birthday?: InputMaybe<StringOperationFilterInput>;
  /** CapLvl */
  capLvl?: InputMaybe<StringOperationFilterInput>;
  /** CapPf */
  capPf?: InputMaybe<IntOperationFilterInput>;
  /** CardMadeDt */
  cardMadeDt?: InputMaybe<DateTimeOperationFilterInput>;
  /** CardPhoto */
  cardPhoto?: InputMaybe<StringOperationFilterInput>;
  /** CardPrint */
  cardPrint?: InputMaybe<IntOperationFilterInput>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** CompRec */
  compRec?: InputMaybe<StringOperationFilterInput>;
  /** CompRecDtl */
  compRecDtl?: InputMaybe<StringOperationFilterInput>;
  /** EmpNameO */
  empNameO?: InputMaybe<StringOperationFilterInput>;
  /** EmpNameR */
  empNameR?: InputMaybe<StringOperationFilterInput>;
  /** Gender */
  gender?: InputMaybe<StringOperationFilterInput>;
  /** GradP */
  gradP?: InputMaybe<IntOperationFilterInput>;
  /** GradTkDt */
  gradTkDt?: InputMaybe<StringOperationFilterInput>;
  /** GraduationDate */
  graduationDate?: InputMaybe<StringOperationFilterInput>;
  /** Guest */
  guest?: InputMaybe<StringOperationFilterInput>;
  /** Interest */
  interest?: InputMaybe<StringOperationFilterInput>;
  /** KinSch */
  kinSch?: InputMaybe<StringOperationFilterInput>;
  /** MemNo */
  memNo?: InputMaybe<StringOperationFilterInput>;
  /** MorePhoto */
  morePhoto?: InputMaybe<StringOperationFilterInput>;
  /** MorePhotoCdt */
  morePhotoCdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** NameC */
  nameC?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] NameE */
  nameE?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempHkDmemChildFilterInput>>;
  /** Organizer */
  organizer?: InputMaybe<StringOperationFilterInput>;
  /** Photo */
  photo?: InputMaybe<StringOperationFilterInput>;
  /** PriSch */
  priSch?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** SecSch */
  secSch?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<DecimalOperationFilterInput>;
  /** ShowDtl1 */
  showDtl1?: InputMaybe<StringOperationFilterInput>;
  /** ShowDtl2 */
  showDtl2?: InputMaybe<StringOperationFilterInput>;
  /** ShowDtl3 */
  showDtl3?: InputMaybe<StringOperationFilterInput>;
  /** Strength */
  strength?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** WfLibraryInuse */
  wfLibraryInuse?: InputMaybe<StringOperationFilterInput>;
};

/** TempHkDmemChild */
export type Club_WorldFamilyClub_TempHkDmemChildInput = {
  /** Achievement */
  achievement?: InputMaybe<Scalars['String']['input']>;
  /** ActDtl1 */
  actDtl1?: InputMaybe<Scalars['String']['input']>;
  /** ActDtl2 */
  actDtl2?: InputMaybe<Scalars['String']['input']>;
  /** ActDtl3 */
  actDtl3?: InputMaybe<Scalars['String']['input']>;
  /** Birthday */
  birthday?: InputMaybe<Scalars['String']['input']>;
  /** CapLvl */
  capLvl?: InputMaybe<Scalars['String']['input']>;
  /** CapPf */
  capPf?: InputMaybe<Scalars['Int']['input']>;
  /** CardMadeDt */
  cardMadeDt?: InputMaybe<Scalars['DateTime']['input']>;
  /** CardPhoto */
  cardPhoto?: InputMaybe<Scalars['String']['input']>;
  /** CardPrint */
  cardPrint?: InputMaybe<Scalars['Int']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** CompRec */
  compRec?: InputMaybe<Scalars['String']['input']>;
  /** CompRecDtl */
  compRecDtl?: InputMaybe<Scalars['String']['input']>;
  /** EmpNameO */
  empNameO?: InputMaybe<Scalars['String']['input']>;
  /** EmpNameR */
  empNameR?: InputMaybe<Scalars['String']['input']>;
  /** Gender */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** GradP */
  gradP?: InputMaybe<Scalars['Int']['input']>;
  /** GradTkDt */
  gradTkDt?: InputMaybe<Scalars['String']['input']>;
  /** GraduationDate */
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  /** Guest */
  guest?: InputMaybe<Scalars['String']['input']>;
  /** Interest */
  interest?: InputMaybe<Scalars['String']['input']>;
  /** KinSch */
  kinSch?: InputMaybe<Scalars['String']['input']>;
  /** MemNo */
  memNo?: InputMaybe<Scalars['String']['input']>;
  /** MorePhoto */
  morePhoto?: InputMaybe<Scalars['String']['input']>;
  /** MorePhotoCdt */
  morePhotoCdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** NameC */
  nameC?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] NameE */
  nameE?: InputMaybe<Scalars['String']['input']>;
  /** Organizer */
  organizer?: InputMaybe<Scalars['String']['input']>;
  /** Photo */
  photo?: InputMaybe<Scalars['String']['input']>;
  /** PriSch */
  priSch?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** SecSch */
  secSch?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SeqNo */
  seqNo: Scalars['Decimal']['input'];
  /** ShowDtl1 */
  showDtl1?: InputMaybe<Scalars['String']['input']>;
  /** ShowDtl2 */
  showDtl2?: InputMaybe<Scalars['String']['input']>;
  /** ShowDtl3 */
  showDtl3?: InputMaybe<Scalars['String']['input']>;
  /** Strength */
  strength?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** WfLibraryInuse */
  wfLibraryInuse?: InputMaybe<Scalars['String']['input']>;
};

/** TempHkDmemChild */
export type Club_WorldFamilyClub_TempHkDmemChildSortInput = {
  /** Achievement */
  achievement?: InputMaybe<SortEnumType>;
  /** ActDtl1 */
  actDtl1?: InputMaybe<SortEnumType>;
  /** ActDtl2 */
  actDtl2?: InputMaybe<SortEnumType>;
  /** ActDtl3 */
  actDtl3?: InputMaybe<SortEnumType>;
  /** Birthday */
  birthday?: InputMaybe<SortEnumType>;
  /** CapLvl */
  capLvl?: InputMaybe<SortEnumType>;
  /** CapPf */
  capPf?: InputMaybe<SortEnumType>;
  /** CardMadeDt */
  cardMadeDt?: InputMaybe<SortEnumType>;
  /** CardPhoto */
  cardPhoto?: InputMaybe<SortEnumType>;
  /** CardPrint */
  cardPrint?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** CompRec */
  compRec?: InputMaybe<SortEnumType>;
  /** CompRecDtl */
  compRecDtl?: InputMaybe<SortEnumType>;
  /** EmpNameO */
  empNameO?: InputMaybe<SortEnumType>;
  /** EmpNameR */
  empNameR?: InputMaybe<SortEnumType>;
  /** Gender */
  gender?: InputMaybe<SortEnumType>;
  /** GradP */
  gradP?: InputMaybe<SortEnumType>;
  /** GradTkDt */
  gradTkDt?: InputMaybe<SortEnumType>;
  /** GraduationDate */
  graduationDate?: InputMaybe<SortEnumType>;
  /** Guest */
  guest?: InputMaybe<SortEnumType>;
  /** Interest */
  interest?: InputMaybe<SortEnumType>;
  /** KinSch */
  kinSch?: InputMaybe<SortEnumType>;
  /** MemNo */
  memNo?: InputMaybe<SortEnumType>;
  /** MorePhoto */
  morePhoto?: InputMaybe<SortEnumType>;
  /** MorePhotoCdt */
  morePhotoCdt?: InputMaybe<SortEnumType>;
  /** NameC */
  nameC?: InputMaybe<SortEnumType>;
  /** [NOT NULL] NameE */
  nameE?: InputMaybe<SortEnumType>;
  /** Organizer */
  organizer?: InputMaybe<SortEnumType>;
  /** Photo */
  photo?: InputMaybe<SortEnumType>;
  /** PriSch */
  priSch?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** SecSch */
  secSch?: InputMaybe<SortEnumType>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<SortEnumType>;
  /** ShowDtl1 */
  showDtl1?: InputMaybe<SortEnumType>;
  /** ShowDtl2 */
  showDtl2?: InputMaybe<SortEnumType>;
  /** ShowDtl3 */
  showDtl3?: InputMaybe<SortEnumType>;
  /** Strength */
  strength?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** WfLibraryInuse */
  wfLibraryInuse?: InputMaybe<SortEnumType>;
};

/** TempHkTeSong */
export type Club_WorldFamilyClub_TempHkTeSong = Node & {
  __typename?: 'Club_WorldFamilyClub_TempHkTeSong';
  /** CapDataNo */
  capDataNo?: Maybe<Scalars['String']['output']>;
  /** CapItemNo */
  capItemNo?: Maybe<Scalars['String']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** Color */
  color?: Maybe<Scalars['String']['output']>;
  /** Content */
  content?: Maybe<Scalars['String']['output']>;
  /** ContentNew */
  contentNew?: Maybe<Scalars['String']['output']>;
  /** Ctifg */
  ctifg?: Maybe<Scalars['Boolean']['output']>;
  /** [ID] Sno */
  id: Scalars['ID']['output'];
  /** Mp3File */
  mp3File?: Maybe<Scalars['String']['output']>;
  /** Organizer */
  organizer?: Maybe<Scalars['String']['output']>;
  /** Product */
  product?: Maybe<Scalars['String']['output']>;
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Seq */
  seq?: Maybe<Scalars['Int']['output']>;
  /** Sname */
  sname?: Maybe<Scalars['String']['output']>;
  /** StopFg */
  stopFg?: Maybe<Scalars['String']['output']>;
  /** Tecategory */
  tecategory?: Maybe<Scalars['String']['output']>;
  /** TmpName */
  tmpName?: Maybe<Scalars['String']['output']>;
  /** Topic */
  topic?: Maybe<Scalars['String']['output']>;
  /** Type */
  type?: Maybe<Scalars['String']['output']>;
  /** TypeId */
  typeId?: Maybe<Scalars['String']['output']>;
  /** TypeValue */
  typeValue?: Maybe<Scalars['String']['output']>;
  /** TypeValue1 */
  typeValue1?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** UseFg */
  useFg?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempHkTeSongConnection = {
  __typename?: 'Club_WorldFamilyClub_TempHkTeSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempHkTeSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempHkTeSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempHkTeSongEdge = {
  __typename?: 'Club_WorldFamilyClub_TempHkTeSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempHkTeSong;
};

/** TempHkTeSong */
export type Club_WorldFamilyClub_TempHkTeSongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTeSongFilterInput>>;
  /** CapDataNo */
  capDataNo?: InputMaybe<StringOperationFilterInput>;
  /** CapItemNo */
  capItemNo?: InputMaybe<StringOperationFilterInput>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Color */
  color?: InputMaybe<StringOperationFilterInput>;
  /** Content */
  content?: InputMaybe<StringOperationFilterInput>;
  /** ContentNew */
  contentNew?: InputMaybe<StringOperationFilterInput>;
  /** Ctifg */
  ctifg?: InputMaybe<BooleanOperationFilterInput>;
  /** Mp3File */
  mp3File?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTeSongFilterInput>>;
  /** Organizer */
  organizer?: InputMaybe<StringOperationFilterInput>;
  /** Product */
  product?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** Sname */
  sname?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Sno */
  sno?: InputMaybe<StringOperationFilterInput>;
  /** StopFg */
  stopFg?: InputMaybe<StringOperationFilterInput>;
  /** Tecategory */
  tecategory?: InputMaybe<StringOperationFilterInput>;
  /** TmpName */
  tmpName?: InputMaybe<StringOperationFilterInput>;
  /** Topic */
  topic?: InputMaybe<StringOperationFilterInput>;
  /** Type */
  type?: InputMaybe<StringOperationFilterInput>;
  /** TypeId */
  typeId?: InputMaybe<StringOperationFilterInput>;
  /** TypeValue */
  typeValue?: InputMaybe<StringOperationFilterInput>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** UseFg */
  useFg?: InputMaybe<BooleanOperationFilterInput>;
};

/** TempHkTeSong */
export type Club_WorldFamilyClub_TempHkTeSongInput = {
  /** CapDataNo */
  capDataNo?: InputMaybe<Scalars['String']['input']>;
  /** CapItemNo */
  capItemNo?: InputMaybe<Scalars['String']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Content */
  content?: InputMaybe<Scalars['String']['input']>;
  /** ContentNew */
  contentNew?: InputMaybe<Scalars['String']['input']>;
  /** Ctifg */
  ctifg?: InputMaybe<Scalars['Boolean']['input']>;
  /** Mp3File */
  mp3File?: InputMaybe<Scalars['String']['input']>;
  /** Organizer */
  organizer?: InputMaybe<Scalars['String']['input']>;
  /** Product */
  product?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** Sname */
  sname?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Sno */
  sno?: InputMaybe<Scalars['String']['input']>;
  /** StopFg */
  stopFg?: InputMaybe<Scalars['String']['input']>;
  /** Tecategory */
  tecategory?: InputMaybe<Scalars['String']['input']>;
  /** TmpName */
  tmpName?: InputMaybe<Scalars['String']['input']>;
  /** Topic */
  topic?: InputMaybe<Scalars['String']['input']>;
  /** Type */
  type?: InputMaybe<Scalars['String']['input']>;
  /** TypeId */
  typeId?: InputMaybe<Scalars['String']['input']>;
  /** TypeValue */
  typeValue?: InputMaybe<Scalars['String']['input']>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UseFg */
  useFg?: InputMaybe<Scalars['Boolean']['input']>;
};

/** TempHkTeSong */
export type Club_WorldFamilyClub_TempHkTeSongSortInput = {
  /** CapDataNo */
  capDataNo?: InputMaybe<SortEnumType>;
  /** CapItemNo */
  capItemNo?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** Color */
  color?: InputMaybe<SortEnumType>;
  /** Content */
  content?: InputMaybe<SortEnumType>;
  /** ContentNew */
  contentNew?: InputMaybe<SortEnumType>;
  /** Ctifg */
  ctifg?: InputMaybe<SortEnumType>;
  /** Mp3File */
  mp3File?: InputMaybe<SortEnumType>;
  /** Organizer */
  organizer?: InputMaybe<SortEnumType>;
  /** Product */
  product?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Sname */
  sname?: InputMaybe<SortEnumType>;
  /** [ID] Sno */
  sno?: InputMaybe<SortEnumType>;
  /** StopFg */
  stopFg?: InputMaybe<SortEnumType>;
  /** Tecategory */
  tecategory?: InputMaybe<SortEnumType>;
  /** TmpName */
  tmpName?: InputMaybe<SortEnumType>;
  /** Topic */
  topic?: InputMaybe<SortEnumType>;
  /** Type */
  type?: InputMaybe<SortEnumType>;
  /** TypeId */
  typeId?: InputMaybe<SortEnumType>;
  /** TypeValue */
  typeValue?: InputMaybe<SortEnumType>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** UseFg */
  useFg?: InputMaybe<SortEnumType>;
};

/** TempHkTematerial */
export type Club_WorldFamilyClub_TempHkTematerial = Node & {
  __typename?: 'Club_WorldFamilyClub_TempHkTematerial';
  /** CapItemCode */
  capItemCode?: Maybe<Scalars['String']['output']>;
  /** Code */
  code?: Maybe<Scalars['String']['output']>;
  /** Color */
  color?: Maybe<Scalars['String']['output']>;
  /** DisplayName */
  displayName?: Maybe<Scalars['String']['output']>;
  /** [ID] Name */
  id: Scalars['ID']['output'];
  /** InUse */
  inUse?: Maybe<Scalars['String']['output']>;
  /** IsPdf */
  isPdf?: Maybe<Scalars['Int']['output']>;
  /** Lyric */
  lyric?: Maybe<Scalars['String']['output']>;
  /** Product */
  product?: Maybe<Scalars['String']['output']>;
  /** Tecategory */
  tecategory?: Maybe<Scalars['String']['output']>;
  /** Tetype */
  tetype?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** Userid */
  userid?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempHkTematerialConnection = {
  __typename?: 'Club_WorldFamilyClub_TempHkTematerialConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempHkTematerialEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempHkTematerial>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempHkTematerialEdge = {
  __typename?: 'Club_WorldFamilyClub_TempHkTematerialEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempHkTematerial;
};

/** TempHkTematerial */
export type Club_WorldFamilyClub_TempHkTematerialFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTematerialFilterInput>>;
  /** CapItemCode */
  capItemCode?: InputMaybe<StringOperationFilterInput>;
  /** Code */
  code?: InputMaybe<StringOperationFilterInput>;
  /** Color */
  color?: InputMaybe<StringOperationFilterInput>;
  /** DisplayName */
  displayName?: InputMaybe<StringOperationFilterInput>;
  /** InUse */
  inUse?: InputMaybe<StringOperationFilterInput>;
  /** IsPdf */
  isPdf?: InputMaybe<IntOperationFilterInput>;
  /** Lyric */
  lyric?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Name */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTematerialFilterInput>>;
  /** Product */
  product?: InputMaybe<StringOperationFilterInput>;
  /** Tecategory */
  tecategory?: InputMaybe<StringOperationFilterInput>;
  /** Tetype */
  tetype?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Userid */
  userid?: InputMaybe<StringOperationFilterInput>;
};

/** TempHkTematerial */
export type Club_WorldFamilyClub_TempHkTematerialInput = {
  /** CapItemCode */
  capItemCode?: InputMaybe<Scalars['String']['input']>;
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** DisplayName */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** InUse */
  inUse?: InputMaybe<Scalars['String']['input']>;
  /** IsPdf */
  isPdf?: InputMaybe<Scalars['Int']['input']>;
  /** Lyric */
  lyric?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Product */
  product?: InputMaybe<Scalars['String']['input']>;
  /** Tecategory */
  tecategory?: InputMaybe<Scalars['String']['input']>;
  /** Tetype */
  tetype?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Userid */
  userid?: InputMaybe<Scalars['String']['input']>;
};

/** TempHkTematerial */
export type Club_WorldFamilyClub_TempHkTematerialSortInput = {
  /** CapItemCode */
  capItemCode?: InputMaybe<SortEnumType>;
  /** Code */
  code?: InputMaybe<SortEnumType>;
  /** Color */
  color?: InputMaybe<SortEnumType>;
  /** DisplayName */
  displayName?: InputMaybe<SortEnumType>;
  /** InUse */
  inUse?: InputMaybe<SortEnumType>;
  /** IsPdf */
  isPdf?: InputMaybe<SortEnumType>;
  /** Lyric */
  lyric?: InputMaybe<SortEnumType>;
  /** [ID] Name */
  name?: InputMaybe<SortEnumType>;
  /** Product */
  product?: InputMaybe<SortEnumType>;
  /** Tecategory */
  tecategory?: InputMaybe<SortEnumType>;
  /** Tetype */
  tetype?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** Userid */
  userid?: InputMaybe<SortEnumType>;
};

/** TempKrFaceCallHist */
export type Club_WorldFamilyClub_TempKrFaceCallHist = Node & {
  __typename?: 'Club_WorldFamilyClub_TempKrFaceCallHist';
  /** [NOT NULL] Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemNo */
  memNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] SeqD */
  seqD?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempKrFaceCallHistConnection = {
  __typename?: 'Club_WorldFamilyClub_TempKrFaceCallHistConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempKrFaceCallHistEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempKrFaceCallHist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempKrFaceCallHistEdge = {
  __typename?: 'Club_WorldFamilyClub_TempKrFaceCallHistEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempKrFaceCallHist;
};

/** TempKrFaceCallHist */
export type Club_WorldFamilyClub_TempKrFaceCallHistFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFaceCallHistFilterInput>>;
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFaceCallHistFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<DecimalOperationFilterInput>;
  /** [NOT NULL] SeqD */
  seqD?: InputMaybe<IntOperationFilterInput>;
};

/** TempKrFaceCallHist */
export type Club_WorldFamilyClub_TempKrFaceCallHistInput = {
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Decimal']['input'];
  /** [NOT NULL] SeqD */
  seqD?: InputMaybe<Scalars['Int']['input']>;
};

/** TempKrFaceCallHist */
export type Club_WorldFamilyClub_TempKrFaceCallHistSortInput = {
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] SeqD */
  seqD?: InputMaybe<SortEnumType>;
};

/** TempKrFcCrewM */
export type Club_WorldFamilyClub_TempKrFcCrewM = Node & {
  __typename?: 'Club_WorldFamilyClub_TempKrFcCrewM';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** CrewName */
  crewName?: Maybe<Scalars['String']['output']>;
  /** DataFrom */
  dataFrom?: Maybe<Scalars['String']['output']>;
  /** EndDt */
  endDt?: Maybe<Scalars['String']['output']>;
  /** [ID] SeqM */
  id: Scalars['ID']['output'];
  /** Organizer */
  organizer?: Maybe<Scalars['String']['output']>;
  /** Photo */
  photo?: Maybe<Scalars['String']['output']>;
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** StartDt */
  startDt?: Maybe<Scalars['String']['output']>;
  /** TopDt */
  topDt?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** UseFg */
  useFg?: Maybe<Scalars['String']['output']>;
  /** ZippyFlg */
  zippyFlg?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempKrFcCrewMConnection = {
  __typename?: 'Club_WorldFamilyClub_TempKrFcCrewMConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempKrFcCrewMEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempKrFcCrewM>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempKrFcCrewMEdge = {
  __typename?: 'Club_WorldFamilyClub_TempKrFcCrewMEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempKrFcCrewM;
};

/** TempKrFcCrewM */
export type Club_WorldFamilyClub_TempKrFcCrewMFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcCrewMFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** CrewName */
  crewName?: InputMaybe<StringOperationFilterInput>;
  /** DataFrom */
  dataFrom?: InputMaybe<StringOperationFilterInput>;
  /** EndDt */
  endDt?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcCrewMFilterInput>>;
  /** Organizer */
  organizer?: InputMaybe<StringOperationFilterInput>;
  /** Photo */
  photo?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SeqM */
  seqM?: InputMaybe<IntOperationFilterInput>;
  /** StartDt */
  startDt?: InputMaybe<StringOperationFilterInput>;
  /** TopDt */
  topDt?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** UseFg */
  useFg?: InputMaybe<StringOperationFilterInput>;
  /** ZippyFlg */
  zippyFlg?: InputMaybe<StringOperationFilterInput>;
};

/** TempKrFcCrewM */
export type Club_WorldFamilyClub_TempKrFcCrewMInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** CrewName */
  crewName?: InputMaybe<Scalars['String']['input']>;
  /** DataFrom */
  dataFrom?: InputMaybe<Scalars['String']['input']>;
  /** EndDt */
  endDt?: InputMaybe<Scalars['String']['input']>;
  /** Organizer */
  organizer?: InputMaybe<Scalars['String']['input']>;
  /** Photo */
  photo?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SeqM */
  seqM?: InputMaybe<Scalars['Int']['input']>;
  /** StartDt */
  startDt?: InputMaybe<Scalars['String']['input']>;
  /** TopDt */
  topDt?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UseFg */
  useFg?: InputMaybe<Scalars['String']['input']>;
  /** ZippyFlg */
  zippyFlg?: InputMaybe<Scalars['String']['input']>;
};

/** TempKrFcCrewM */
export type Club_WorldFamilyClub_TempKrFcCrewMSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** CrewName */
  crewName?: InputMaybe<SortEnumType>;
  /** DataFrom */
  dataFrom?: InputMaybe<SortEnumType>;
  /** EndDt */
  endDt?: InputMaybe<SortEnumType>;
  /** Organizer */
  organizer?: InputMaybe<SortEnumType>;
  /** Photo */
  photo?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** [ID] SeqM */
  seqM?: InputMaybe<SortEnumType>;
  /** StartDt */
  startDt?: InputMaybe<SortEnumType>;
  /** TopDt */
  topDt?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** UseFg */
  useFg?: InputMaybe<SortEnumType>;
  /** ZippyFlg */
  zippyFlg?: InputMaybe<SortEnumType>;
};

/** TempKrFcsongCrew */
export type Club_WorldFamilyClub_TempKrFcsongCrew = Node & {
  __typename?: 'Club_WorldFamilyClub_TempKrFcsongCrew';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** Christmas */
  christmas?: Maybe<Scalars['String']['output']>;
  /** CrewName */
  crewName?: Maybe<Scalars['String']['output']>;
  /** EndDt */
  endDt?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** Organizer */
  organizer?: Maybe<Scalars['String']['output']>;
  /** Pumpkin */
  pumpkin?: Maybe<Scalars['String']['output']>;
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] SeqM */
  seqM?: Maybe<Scalars['Int']['output']>;
  /** Sno */
  sno?: Maybe<Scalars['String']['output']>;
  /** StartDt */
  startDt?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** Url */
  url?: Maybe<Scalars['String']['output']>;
  /** UrlAnd */
  urlAnd?: Maybe<Scalars['String']['output']>;
  /** UrlOld */
  urlOld?: Maybe<Scalars['String']['output']>;
  /** UseFg */
  useFg?: Maybe<Scalars['String']['output']>;
  /** VideoLink */
  videoLink?: Maybe<Scalars['String']['output']>;
  /** VideoNo */
  videoNo?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempKrFcsongCrewConnection = {
  __typename?: 'Club_WorldFamilyClub_TempKrFcsongCrewConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempKrFcsongCrewEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempKrFcsongCrew>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempKrFcsongCrewEdge = {
  __typename?: 'Club_WorldFamilyClub_TempKrFcsongCrewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempKrFcsongCrew;
};

/** TempKrFcsongCrew */
export type Club_WorldFamilyClub_TempKrFcsongCrewFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcsongCrewFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Christmas */
  christmas?: InputMaybe<StringOperationFilterInput>;
  /** CrewName */
  crewName?: InputMaybe<StringOperationFilterInput>;
  /** EndDt */
  endDt?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcsongCrewFilterInput>>;
  /** Organizer */
  organizer?: InputMaybe<StringOperationFilterInput>;
  /** Pumpkin */
  pumpkin?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] SeqM */
  seqM?: InputMaybe<IntOperationFilterInput>;
  /** Sno */
  sno?: InputMaybe<StringOperationFilterInput>;
  /** StartDt */
  startDt?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Url */
  url?: InputMaybe<StringOperationFilterInput>;
  /** UrlAnd */
  urlAnd?: InputMaybe<StringOperationFilterInput>;
  /** UrlOld */
  urlOld?: InputMaybe<StringOperationFilterInput>;
  /** UseFg */
  useFg?: InputMaybe<StringOperationFilterInput>;
  /** VideoLink */
  videoLink?: InputMaybe<StringOperationFilterInput>;
  /** VideoNo */
  videoNo?: InputMaybe<StringOperationFilterInput>;
};

/** TempKrFcsongCrew */
export type Club_WorldFamilyClub_TempKrFcsongCrewInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Christmas */
  christmas?: InputMaybe<Scalars['String']['input']>;
  /** CrewName */
  crewName?: InputMaybe<Scalars['String']['input']>;
  /** EndDt */
  endDt?: InputMaybe<Scalars['String']['input']>;
  /** Organizer */
  organizer?: InputMaybe<Scalars['String']['input']>;
  /** Pumpkin */
  pumpkin?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] SeqM */
  seqM?: InputMaybe<Scalars['Int']['input']>;
  /** Sno */
  sno?: InputMaybe<Scalars['String']['input']>;
  /** StartDt */
  startDt?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Url */
  url?: InputMaybe<Scalars['String']['input']>;
  /** UrlAnd */
  urlAnd?: InputMaybe<Scalars['String']['input']>;
  /** UrlOld */
  urlOld?: InputMaybe<Scalars['String']['input']>;
  /** UseFg */
  useFg?: InputMaybe<Scalars['String']['input']>;
  /** VideoLink */
  videoLink?: InputMaybe<Scalars['String']['input']>;
  /** VideoNo */
  videoNo?: InputMaybe<Scalars['String']['input']>;
};

/** TempKrFcsongCrew */
export type Club_WorldFamilyClub_TempKrFcsongCrewSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** Christmas */
  christmas?: InputMaybe<SortEnumType>;
  /** CrewName */
  crewName?: InputMaybe<SortEnumType>;
  /** EndDt */
  endDt?: InputMaybe<SortEnumType>;
  /** Organizer */
  organizer?: InputMaybe<SortEnumType>;
  /** Pumpkin */
  pumpkin?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] SeqM */
  seqM?: InputMaybe<SortEnumType>;
  /** Sno */
  sno?: InputMaybe<SortEnumType>;
  /** StartDt */
  startDt?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** Url */
  url?: InputMaybe<SortEnumType>;
  /** UrlAnd */
  urlAnd?: InputMaybe<SortEnumType>;
  /** UrlOld */
  urlOld?: InputMaybe<SortEnumType>;
  /** UseFg */
  useFg?: InputMaybe<SortEnumType>;
  /** VideoLink */
  videoLink?: InputMaybe<SortEnumType>;
  /** VideoNo */
  videoNo?: InputMaybe<SortEnumType>;
};

/** TempKrMyFavorite */
export type Club_WorldFamilyClub_TempKrMyFavorite = Node & {
  __typename?: 'Club_WorldFamilyClub_TempKrMyFavorite';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** FavoriteType */
  favoriteType?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** LinkNo */
  linkNo?: Maybe<Scalars['String']['output']>;
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempKrMyFavoriteConnection = {
  __typename?: 'Club_WorldFamilyClub_TempKrMyFavoriteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempKrMyFavoriteEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempKrMyFavorite>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempKrMyFavoriteEdge = {
  __typename?: 'Club_WorldFamilyClub_TempKrMyFavoriteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempKrMyFavorite;
};

/** TempKrMyFavorite */
export type Club_WorldFamilyClub_TempKrMyFavoriteFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempKrMyFavoriteFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** FavoriteType */
  favoriteType?: InputMaybe<StringOperationFilterInput>;
  /** LinkNo */
  linkNo?: InputMaybe<StringOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempKrMyFavoriteFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
};

/** TempKrMyFavorite */
export type Club_WorldFamilyClub_TempKrMyFavoriteInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** FavoriteType */
  favoriteType?: InputMaybe<Scalars['String']['input']>;
  /** LinkNo */
  linkNo?: InputMaybe<Scalars['String']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
};

/** TempKrMyFavorite */
export type Club_WorldFamilyClub_TempKrMyFavoriteSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** FavoriteType */
  favoriteType?: InputMaybe<SortEnumType>;
  /** LinkNo */
  linkNo?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
};

/** TempKrTeSong */
export type Club_WorldFamilyClub_TempKrTeSong = Node & {
  __typename?: 'Club_WorldFamilyClub_TempKrTeSong';
  /** CdNo */
  cdNo?: Maybe<Scalars['String']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** Color */
  color?: Maybe<Scalars['String']['output']>;
  /** Content */
  content?: Maybe<Scalars['String']['output']>;
  /** ContentMain */
  contentMain?: Maybe<Scalars['String']['output']>;
  /** ContentNew */
  contentNew?: Maybe<Scalars['String']['output']>;
  /** [ID] Sno */
  id: Scalars['ID']['output'];
  /** Mp3File */
  mp3File?: Maybe<Scalars['String']['output']>;
  /** Organizer */
  organizer?: Maybe<Scalars['String']['output']>;
  /** Product */
  product?: Maybe<Scalars['String']['output']>;
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Seq */
  seq?: Maybe<Scalars['Int']['output']>;
  /** Sname */
  sname?: Maybe<Scalars['String']['output']>;
  /** Sno1 */
  sno1?: Maybe<Scalars['String']['output']>;
  /** StopFg */
  stopFg?: Maybe<Scalars['String']['output']>;
  /** Tecategory */
  tecategory?: Maybe<Scalars['String']['output']>;
  /** Topic */
  topic?: Maybe<Scalars['String']['output']>;
  /** Type */
  type?: Maybe<Scalars['String']['output']>;
  /** TypeId */
  typeId?: Maybe<Scalars['String']['output']>;
  /** TypeValue */
  typeValue?: Maybe<Scalars['String']['output']>;
  /** TypeValue1 */
  typeValue1?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** UseFg */
  useFg?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempKrTeSongConnection = {
  __typename?: 'Club_WorldFamilyClub_TempKrTeSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempKrTeSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempKrTeSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempKrTeSongEdge = {
  __typename?: 'Club_WorldFamilyClub_TempKrTeSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempKrTeSong;
};

/** TempKrTeSong */
export type Club_WorldFamilyClub_TempKrTeSongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempKrTeSongFilterInput>>;
  /** CdNo */
  cdNo?: InputMaybe<StringOperationFilterInput>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Color */
  color?: InputMaybe<StringOperationFilterInput>;
  /** Content */
  content?: InputMaybe<StringOperationFilterInput>;
  /** ContentMain */
  contentMain?: InputMaybe<StringOperationFilterInput>;
  /** ContentNew */
  contentNew?: InputMaybe<StringOperationFilterInput>;
  /** Mp3File */
  mp3File?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempKrTeSongFilterInput>>;
  /** Organizer */
  organizer?: InputMaybe<StringOperationFilterInput>;
  /** Product */
  product?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** Sname */
  sname?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Sno */
  sno?: InputMaybe<StringOperationFilterInput>;
  /** Sno1 */
  sno1?: InputMaybe<StringOperationFilterInput>;
  /** StopFg */
  stopFg?: InputMaybe<StringOperationFilterInput>;
  /** Tecategory */
  tecategory?: InputMaybe<StringOperationFilterInput>;
  /** Topic */
  topic?: InputMaybe<StringOperationFilterInput>;
  /** Type */
  type?: InputMaybe<StringOperationFilterInput>;
  /** TypeId */
  typeId?: InputMaybe<StringOperationFilterInput>;
  /** TypeValue */
  typeValue?: InputMaybe<StringOperationFilterInput>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** UseFg */
  useFg?: InputMaybe<BooleanOperationFilterInput>;
};

/** TempKrTeSong */
export type Club_WorldFamilyClub_TempKrTeSongInput = {
  /** CdNo */
  cdNo?: InputMaybe<Scalars['String']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Content */
  content?: InputMaybe<Scalars['String']['input']>;
  /** ContentMain */
  contentMain?: InputMaybe<Scalars['String']['input']>;
  /** ContentNew */
  contentNew?: InputMaybe<Scalars['String']['input']>;
  /** Mp3File */
  mp3File?: InputMaybe<Scalars['String']['input']>;
  /** Organizer */
  organizer?: InputMaybe<Scalars['String']['input']>;
  /** Product */
  product?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** Sname */
  sname?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Sno */
  sno?: InputMaybe<Scalars['String']['input']>;
  /** Sno1 */
  sno1?: InputMaybe<Scalars['String']['input']>;
  /** StopFg */
  stopFg?: InputMaybe<Scalars['String']['input']>;
  /** Tecategory */
  tecategory?: InputMaybe<Scalars['String']['input']>;
  /** Topic */
  topic?: InputMaybe<Scalars['String']['input']>;
  /** Type */
  type?: InputMaybe<Scalars['String']['input']>;
  /** TypeId */
  typeId?: InputMaybe<Scalars['String']['input']>;
  /** TypeValue */
  typeValue?: InputMaybe<Scalars['String']['input']>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UseFg */
  useFg?: InputMaybe<Scalars['Boolean']['input']>;
};

/** TempKrTeSong */
export type Club_WorldFamilyClub_TempKrTeSongSortInput = {
  /** CdNo */
  cdNo?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** Color */
  color?: InputMaybe<SortEnumType>;
  /** Content */
  content?: InputMaybe<SortEnumType>;
  /** ContentMain */
  contentMain?: InputMaybe<SortEnumType>;
  /** ContentNew */
  contentNew?: InputMaybe<SortEnumType>;
  /** Mp3File */
  mp3File?: InputMaybe<SortEnumType>;
  /** Organizer */
  organizer?: InputMaybe<SortEnumType>;
  /** Product */
  product?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Sname */
  sname?: InputMaybe<SortEnumType>;
  /** [ID] Sno */
  sno?: InputMaybe<SortEnumType>;
  /** Sno1 */
  sno1?: InputMaybe<SortEnumType>;
  /** StopFg */
  stopFg?: InputMaybe<SortEnumType>;
  /** Tecategory */
  tecategory?: InputMaybe<SortEnumType>;
  /** Topic */
  topic?: InputMaybe<SortEnumType>;
  /** Type */
  type?: InputMaybe<SortEnumType>;
  /** TypeId */
  typeId?: InputMaybe<SortEnumType>;
  /** TypeValue */
  typeValue?: InputMaybe<SortEnumType>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** UseFg */
  useFg?: InputMaybe<SortEnumType>;
};

/** TempTwCambridge */
export type Club_WorldFamilyClub_TempTwCambridge = Node & {
  __typename?: 'Club_WorldFamilyClub_TempTwCambridge';
  /** [NOT NULL] ChildSeq */
  childSeq?: Maybe<Scalars['Short']['output']>;
  /** [NOT NULL] Height */
  height?: Maybe<Scalars['String']['output']>;
  /** [ID] Year */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Weight */
  weight?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempTwCambridgeConnection = {
  __typename?: 'Club_WorldFamilyClub_TempTwCambridgeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempTwCambridgeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempTwCambridge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempTwCambridgeEdge = {
  __typename?: 'Club_WorldFamilyClub_TempTwCambridgeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempTwCambridge;
};

/** TempTwCambridge */
export type Club_WorldFamilyClub_TempTwCambridgeFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempTwCambridgeFilterInput>>;
  /** [NOT NULL] ChildSeq */
  childSeq?: InputMaybe<ShortOperationFilterInput>;
  /** [NOT NULL] Height */
  height?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempTwCambridgeFilterInput>>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Year */
  year?: InputMaybe<ShortOperationFilterInput>;
};

/** TempTwCambridge */
export type Club_WorldFamilyClub_TempTwCambridgeInput = {
  /** [NOT NULL] ChildSeq */
  childSeq?: InputMaybe<Scalars['Short']['input']>;
  /** [NOT NULL] Height */
  height?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Year */
  year: Scalars['Short']['input'];
};

/** TempTwCambridge */
export type Club_WorldFamilyClub_TempTwCambridgeSortInput = {
  /** [NOT NULL] ChildSeq */
  childSeq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Height */
  height?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<SortEnumType>;
  /** [ID] Year */
  year?: InputMaybe<SortEnumType>;
};

/** TempTwFaceCallHist */
export type Club_WorldFamilyClub_TempTwFaceCallHist = Node & {
  __typename?: 'Club_WorldFamilyClub_TempTwFaceCallHist';
  /** [NOT NULL] Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemNo */
  memNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] SeqD */
  seqD?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempTwFaceCallHistConnection = {
  __typename?: 'Club_WorldFamilyClub_TempTwFaceCallHistConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempTwFaceCallHistEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempTwFaceCallHist>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempTwFaceCallHistEdge = {
  __typename?: 'Club_WorldFamilyClub_TempTwFaceCallHistEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempTwFaceCallHist;
};

/** TempTwFaceCallHist */
export type Club_WorldFamilyClub_TempTwFaceCallHistFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFaceCallHistFilterInput>>;
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFaceCallHistFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<DecimalOperationFilterInput>;
  /** [NOT NULL] SeqD */
  seqD?: InputMaybe<IntOperationFilterInput>;
};

/** TempTwFaceCallHist */
export type Club_WorldFamilyClub_TempTwFaceCallHistInput = {
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq: Scalars['Decimal']['input'];
  /** [NOT NULL] SeqD */
  seqD?: InputMaybe<Scalars['Int']['input']>;
};

/** TempTwFaceCallHist */
export type Club_WorldFamilyClub_TempTwFaceCallHistSortInput = {
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] SeqD */
  seqD?: InputMaybe<SortEnumType>;
};

/** TempTwFcCrewM */
export type Club_WorldFamilyClub_TempTwFcCrewM = Node & {
  __typename?: 'Club_WorldFamilyClub_TempTwFcCrewM';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] Christmas */
  christmas?: Maybe<Scalars['Boolean']['output']>;
  /** CrewName */
  crewName?: Maybe<Scalars['String']['output']>;
  /** DataFrom */
  dataFrom?: Maybe<Scalars['String']['output']>;
  /** EndDt */
  endDt?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Halloween */
  halloween?: Maybe<Scalars['Boolean']['output']>;
  /** [ID] SeqM */
  id: Scalars['ID']['output'];
  /** Organizer */
  organizer?: Maybe<Scalars['String']['output']>;
  /** Photo */
  photo?: Maybe<Scalars['String']['output']>;
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** StartDt */
  startDt?: Maybe<Scalars['String']['output']>;
  /** TopDt */
  topDt?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** UseFg */
  useFg?: Maybe<Scalars['String']['output']>;
  /** ZippyFlg */
  zippyFlg?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempTwFcCrewMConnection = {
  __typename?: 'Club_WorldFamilyClub_TempTwFcCrewMConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempTwFcCrewMEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempTwFcCrewM>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempTwFcCrewMEdge = {
  __typename?: 'Club_WorldFamilyClub_TempTwFcCrewMEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempTwFcCrewM;
};

/** TempTwFcCrewM */
export type Club_WorldFamilyClub_TempTwFcCrewMFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcCrewMFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Christmas */
  christmas?: InputMaybe<BooleanOperationFilterInput>;
  /** CrewName */
  crewName?: InputMaybe<StringOperationFilterInput>;
  /** DataFrom */
  dataFrom?: InputMaybe<StringOperationFilterInput>;
  /** EndDt */
  endDt?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Halloween */
  halloween?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcCrewMFilterInput>>;
  /** Organizer */
  organizer?: InputMaybe<StringOperationFilterInput>;
  /** Photo */
  photo?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SeqM */
  seqM?: InputMaybe<IntOperationFilterInput>;
  /** StartDt */
  startDt?: InputMaybe<StringOperationFilterInput>;
  /** TopDt */
  topDt?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** UseFg */
  useFg?: InputMaybe<StringOperationFilterInput>;
  /** ZippyFlg */
  zippyFlg?: InputMaybe<StringOperationFilterInput>;
};

/** TempTwFcCrewM */
export type Club_WorldFamilyClub_TempTwFcCrewMInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Christmas */
  christmas?: InputMaybe<Scalars['Boolean']['input']>;
  /** CrewName */
  crewName?: InputMaybe<Scalars['String']['input']>;
  /** DataFrom */
  dataFrom?: InputMaybe<Scalars['String']['input']>;
  /** EndDt */
  endDt?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Halloween */
  halloween?: InputMaybe<Scalars['Boolean']['input']>;
  /** Organizer */
  organizer?: InputMaybe<Scalars['String']['input']>;
  /** Photo */
  photo?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SeqM */
  seqM?: InputMaybe<Scalars['Int']['input']>;
  /** StartDt */
  startDt?: InputMaybe<Scalars['String']['input']>;
  /** TopDt */
  topDt?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UseFg */
  useFg?: InputMaybe<Scalars['String']['input']>;
  /** ZippyFlg */
  zippyFlg?: InputMaybe<Scalars['String']['input']>;
};

/** TempTwFcCrewM */
export type Club_WorldFamilyClub_TempTwFcCrewMSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Christmas */
  christmas?: InputMaybe<SortEnumType>;
  /** CrewName */
  crewName?: InputMaybe<SortEnumType>;
  /** DataFrom */
  dataFrom?: InputMaybe<SortEnumType>;
  /** EndDt */
  endDt?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Halloween */
  halloween?: InputMaybe<SortEnumType>;
  /** Organizer */
  organizer?: InputMaybe<SortEnumType>;
  /** Photo */
  photo?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** [ID] SeqM */
  seqM?: InputMaybe<SortEnumType>;
  /** StartDt */
  startDt?: InputMaybe<SortEnumType>;
  /** TopDt */
  topDt?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** UseFg */
  useFg?: InputMaybe<SortEnumType>;
  /** ZippyFlg */
  zippyFlg?: InputMaybe<SortEnumType>;
};

/** TempTwFcsongCrew */
export type Club_WorldFamilyClub_TempTwFcsongCrew = Node & {
  __typename?: 'Club_WorldFamilyClub_TempTwFcsongCrew';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** CrewName */
  crewName?: Maybe<Scalars['String']['output']>;
  /** EndDt */
  endDt?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** MonthlyRecommend */
  monthlyRecommend?: Maybe<Scalars['String']['output']>;
  /** Organizer */
  organizer?: Maybe<Scalars['String']['output']>;
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] SeqM */
  seqM?: Maybe<Scalars['Int']['output']>;
  /** Sno */
  sno?: Maybe<Scalars['String']['output']>;
  /** StartDt */
  startDt?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** Url */
  url?: Maybe<Scalars['String']['output']>;
  /** UrlAnd */
  urlAnd?: Maybe<Scalars['String']['output']>;
  /** UseFg */
  useFg?: Maybe<Scalars['String']['output']>;
  /** Version */
  version?: Maybe<Scalars['String']['output']>;
  /** VideoLink */
  videoLink?: Maybe<Scalars['String']['output']>;
  /** VideoNo */
  videoNo?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempTwFcsongCrewConnection = {
  __typename?: 'Club_WorldFamilyClub_TempTwFcsongCrewConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempTwFcsongCrewEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempTwFcsongCrew>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempTwFcsongCrewEdge = {
  __typename?: 'Club_WorldFamilyClub_TempTwFcsongCrewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempTwFcsongCrew;
};

/** TempTwFcsongCrew */
export type Club_WorldFamilyClub_TempTwFcsongCrewFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongCrewFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** CrewName */
  crewName?: InputMaybe<StringOperationFilterInput>;
  /** EndDt */
  endDt?: InputMaybe<StringOperationFilterInput>;
  /** MonthlyRecommend */
  monthlyRecommend?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongCrewFilterInput>>;
  /** Organizer */
  organizer?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] SeqM */
  seqM?: InputMaybe<IntOperationFilterInput>;
  /** Sno */
  sno?: InputMaybe<StringOperationFilterInput>;
  /** StartDt */
  startDt?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Url */
  url?: InputMaybe<StringOperationFilterInput>;
  /** UrlAnd */
  urlAnd?: InputMaybe<StringOperationFilterInput>;
  /** UseFg */
  useFg?: InputMaybe<StringOperationFilterInput>;
  /** Version */
  version?: InputMaybe<StringOperationFilterInput>;
  /** VideoLink */
  videoLink?: InputMaybe<StringOperationFilterInput>;
  /** VideoNo */
  videoNo?: InputMaybe<StringOperationFilterInput>;
};

/** TempTwFcsongCrew */
export type Club_WorldFamilyClub_TempTwFcsongCrewInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** CrewName */
  crewName?: InputMaybe<Scalars['String']['input']>;
  /** EndDt */
  endDt?: InputMaybe<Scalars['String']['input']>;
  /** MonthlyRecommend */
  monthlyRecommend?: InputMaybe<Scalars['String']['input']>;
  /** Organizer */
  organizer?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] SeqM */
  seqM?: InputMaybe<Scalars['Int']['input']>;
  /** Sno */
  sno?: InputMaybe<Scalars['String']['input']>;
  /** StartDt */
  startDt?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Url */
  url?: InputMaybe<Scalars['String']['input']>;
  /** UrlAnd */
  urlAnd?: InputMaybe<Scalars['String']['input']>;
  /** UseFg */
  useFg?: InputMaybe<Scalars['String']['input']>;
  /** Version */
  version?: InputMaybe<Scalars['String']['input']>;
  /** VideoLink */
  videoLink?: InputMaybe<Scalars['String']['input']>;
  /** VideoNo */
  videoNo?: InputMaybe<Scalars['String']['input']>;
};

/** TempTwFcsongCrew */
export type Club_WorldFamilyClub_TempTwFcsongCrewSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** CrewName */
  crewName?: InputMaybe<SortEnumType>;
  /** EndDt */
  endDt?: InputMaybe<SortEnumType>;
  /** MonthlyRecommend */
  monthlyRecommend?: InputMaybe<SortEnumType>;
  /** Organizer */
  organizer?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] SeqM */
  seqM?: InputMaybe<SortEnumType>;
  /** Sno */
  sno?: InputMaybe<SortEnumType>;
  /** StartDt */
  startDt?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** Url */
  url?: InputMaybe<SortEnumType>;
  /** UrlAnd */
  urlAnd?: InputMaybe<SortEnumType>;
  /** UseFg */
  useFg?: InputMaybe<SortEnumType>;
  /** Version */
  version?: InputMaybe<SortEnumType>;
  /** VideoLink */
  videoLink?: InputMaybe<SortEnumType>;
  /** VideoNo */
  videoNo?: InputMaybe<SortEnumType>;
};

/** TempTwFcsongMonthlyRecommend */
export type Club_WorldFamilyClub_TempTwFcsongMonthlyRecommend = Node & {
  __typename?: 'Club_WorldFamilyClub_TempTwFcsongMonthlyRecommend';
  /** [NOT NULL] CreateDate */
  createDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] FcSongSeq */
  fcSongSeq?: Maybe<Scalars['Int']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Month */
  month?: Maybe<Scalars['Byte']['output']>;
  /** [NOT NULL] OrganizerCode */
  organizerCode?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ReviserCode */
  reviserCode?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] UpdateDate */
  updateDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] Year */
  year?: Maybe<Scalars['Short']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendConnection = {
  __typename?: 'Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommend>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendEdge = {
  __typename?: 'Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempTwFcsongMonthlyRecommend;
};

/** TempTwFcsongMonthlyRecommend */
export type Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendFilterInput>>;
  /** [NOT NULL] CreateDate */
  createDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] FcSongSeq */
  fcSongSeq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Month */
  month?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendFilterInput>>;
  /** [NOT NULL] OrganizerCode */
  organizerCode?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ReviserCode */
  reviserCode?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] UpdateDate */
  updateDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Year */
  year?: InputMaybe<ShortOperationFilterInput>;
};

/** TempTwFcsongMonthlyRecommend */
export type Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendInput = {
  /** [NOT NULL] CreateDate */
  createDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] FcSongSeq */
  fcSongSeq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Month */
  month?: InputMaybe<Scalars['Byte']['input']>;
  /** [NOT NULL] OrganizerCode */
  organizerCode?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ReviserCode */
  reviserCode?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] UpdateDate */
  updateDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Year */
  year?: InputMaybe<Scalars['Short']['input']>;
};

/** TempTwFcsongMonthlyRecommend */
export type Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendSortInput = {
  /** [NOT NULL] CreateDate */
  createDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] FcSongSeq */
  fcSongSeq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Month */
  month?: InputMaybe<SortEnumType>;
  /** [NOT NULL] OrganizerCode */
  organizerCode?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ReviserCode */
  reviserCode?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] UpdateDate */
  updateDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Year */
  year?: InputMaybe<SortEnumType>;
};

/** TempTwMyFavorite */
export type Club_WorldFamilyClub_TempTwMyFavorite = Node & {
  __typename?: 'Club_WorldFamilyClub_TempTwMyFavorite';
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** FavoriteType */
  favoriteType?: Maybe<Scalars['String']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** LinkNo */
  linkNo?: Maybe<Scalars['String']['output']>;
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempTwMyFavoriteConnection = {
  __typename?: 'Club_WorldFamilyClub_TempTwMyFavoriteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempTwMyFavoriteEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempTwMyFavorite>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempTwMyFavoriteEdge = {
  __typename?: 'Club_WorldFamilyClub_TempTwMyFavoriteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempTwMyFavorite;
};

/** TempTwMyFavorite */
export type Club_WorldFamilyClub_TempTwMyFavoriteFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempTwMyFavoriteFilterInput>>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** FavoriteType */
  favoriteType?: InputMaybe<StringOperationFilterInput>;
  /** LinkNo */
  linkNo?: InputMaybe<StringOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempTwMyFavoriteFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
};

/** TempTwMyFavorite */
export type Club_WorldFamilyClub_TempTwMyFavoriteInput = {
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** FavoriteType */
  favoriteType?: InputMaybe<Scalars['String']['input']>;
  /** LinkNo */
  linkNo?: InputMaybe<Scalars['String']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
};

/** TempTwMyFavorite */
export type Club_WorldFamilyClub_TempTwMyFavoriteSortInput = {
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** FavoriteType */
  favoriteType?: InputMaybe<SortEnumType>;
  /** LinkNo */
  linkNo?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
};

/** TempTwTeSong */
export type Club_WorldFamilyClub_TempTwTeSong = Node & {
  __typename?: 'Club_WorldFamilyClub_TempTwTeSong';
  /** CapDataNo */
  capDataNo?: Maybe<Scalars['String']['output']>;
  /** CapItemNo */
  capItemNo?: Maybe<Scalars['String']['output']>;
  /** Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** Color */
  color?: Maybe<Scalars['String']['output']>;
  /** Content */
  content?: Maybe<Scalars['String']['output']>;
  /** ContentNew */
  contentNew?: Maybe<Scalars['String']['output']>;
  /** Ctifg */
  ctifg?: Maybe<Scalars['Boolean']['output']>;
  /** [ID] Sno */
  id: Scalars['ID']['output'];
  /** Mp3File */
  mp3File?: Maybe<Scalars['String']['output']>;
  /** Organizer */
  organizer?: Maybe<Scalars['String']['output']>;
  /** Product */
  product?: Maybe<Scalars['String']['output']>;
  /** Reviser */
  reviser?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Seq */
  seq?: Maybe<Scalars['Int']['output']>;
  /** Sname */
  sname?: Maybe<Scalars['String']['output']>;
  /** StopFg */
  stopFg?: Maybe<Scalars['String']['output']>;
  /** Tecategory */
  tecategory?: Maybe<Scalars['String']['output']>;
  /** Topic */
  topic?: Maybe<Scalars['String']['output']>;
  /** Type */
  type?: Maybe<Scalars['String']['output']>;
  /** TypeId */
  typeId?: Maybe<Scalars['String']['output']>;
  /** TypeValue */
  typeValue?: Maybe<Scalars['String']['output']>;
  /** TypeValue1 */
  typeValue1?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** UseFg */
  useFg?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempTwTeSongConnection = {
  __typename?: 'Club_WorldFamilyClub_TempTwTeSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempTwTeSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempTwTeSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempTwTeSongEdge = {
  __typename?: 'Club_WorldFamilyClub_TempTwTeSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempTwTeSong;
};

/** TempTwTeSong */
export type Club_WorldFamilyClub_TempTwTeSongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempTwTeSongFilterInput>>;
  /** CapDataNo */
  capDataNo?: InputMaybe<StringOperationFilterInput>;
  /** CapItemNo */
  capItemNo?: InputMaybe<StringOperationFilterInput>;
  /** Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** Color */
  color?: InputMaybe<StringOperationFilterInput>;
  /** Content */
  content?: InputMaybe<StringOperationFilterInput>;
  /** ContentNew */
  contentNew?: InputMaybe<StringOperationFilterInput>;
  /** Ctifg */
  ctifg?: InputMaybe<BooleanOperationFilterInput>;
  /** Mp3File */
  mp3File?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempTwTeSongFilterInput>>;
  /** Organizer */
  organizer?: InputMaybe<StringOperationFilterInput>;
  /** Product */
  product?: InputMaybe<StringOperationFilterInput>;
  /** Reviser */
  reviser?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** Sname */
  sname?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Sno */
  sno?: InputMaybe<StringOperationFilterInput>;
  /** StopFg */
  stopFg?: InputMaybe<StringOperationFilterInput>;
  /** Tecategory */
  tecategory?: InputMaybe<StringOperationFilterInput>;
  /** Topic */
  topic?: InputMaybe<StringOperationFilterInput>;
  /** Type */
  type?: InputMaybe<StringOperationFilterInput>;
  /** TypeId */
  typeId?: InputMaybe<StringOperationFilterInput>;
  /** TypeValue */
  typeValue?: InputMaybe<StringOperationFilterInput>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<StringOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** UseFg */
  useFg?: InputMaybe<BooleanOperationFilterInput>;
};

/** TempTwTeSong */
export type Club_WorldFamilyClub_TempTwTeSongInput = {
  /** CapDataNo */
  capDataNo?: InputMaybe<Scalars['String']['input']>;
  /** CapItemNo */
  capItemNo?: InputMaybe<Scalars['String']['input']>;
  /** Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Content */
  content?: InputMaybe<Scalars['String']['input']>;
  /** ContentNew */
  contentNew?: InputMaybe<Scalars['String']['input']>;
  /** Ctifg */
  ctifg?: InputMaybe<Scalars['Boolean']['input']>;
  /** Mp3File */
  mp3File?: InputMaybe<Scalars['String']['input']>;
  /** Organizer */
  organizer?: InputMaybe<Scalars['String']['input']>;
  /** Product */
  product?: InputMaybe<Scalars['String']['input']>;
  /** Reviser */
  reviser?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** Sname */
  sname?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Sno */
  sno?: InputMaybe<Scalars['String']['input']>;
  /** StopFg */
  stopFg?: InputMaybe<Scalars['String']['input']>;
  /** Tecategory */
  tecategory?: InputMaybe<Scalars['String']['input']>;
  /** Topic */
  topic?: InputMaybe<Scalars['String']['input']>;
  /** Type */
  type?: InputMaybe<Scalars['String']['input']>;
  /** TypeId */
  typeId?: InputMaybe<Scalars['String']['input']>;
  /** TypeValue */
  typeValue?: InputMaybe<Scalars['String']['input']>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<Scalars['String']['input']>;
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** UseFg */
  useFg?: InputMaybe<Scalars['Boolean']['input']>;
};

/** TempTwTeSong */
export type Club_WorldFamilyClub_TempTwTeSongSortInput = {
  /** CapDataNo */
  capDataNo?: InputMaybe<SortEnumType>;
  /** CapItemNo */
  capItemNo?: InputMaybe<SortEnumType>;
  /** Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** Color */
  color?: InputMaybe<SortEnumType>;
  /** Content */
  content?: InputMaybe<SortEnumType>;
  /** ContentNew */
  contentNew?: InputMaybe<SortEnumType>;
  /** Ctifg */
  ctifg?: InputMaybe<SortEnumType>;
  /** Mp3File */
  mp3File?: InputMaybe<SortEnumType>;
  /** Organizer */
  organizer?: InputMaybe<SortEnumType>;
  /** Product */
  product?: InputMaybe<SortEnumType>;
  /** Reviser */
  reviser?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** Sname */
  sname?: InputMaybe<SortEnumType>;
  /** [ID] Sno */
  sno?: InputMaybe<SortEnumType>;
  /** StopFg */
  stopFg?: InputMaybe<SortEnumType>;
  /** Tecategory */
  tecategory?: InputMaybe<SortEnumType>;
  /** Topic */
  topic?: InputMaybe<SortEnumType>;
  /** Type */
  type?: InputMaybe<SortEnumType>;
  /** TypeId */
  typeId?: InputMaybe<SortEnumType>;
  /** TypeValue */
  typeValue?: InputMaybe<SortEnumType>;
  /** TypeValue1 */
  typeValue1?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** UseFg */
  useFg?: InputMaybe<SortEnumType>;
};

/** TempWfcGraC */
export type Club_WorldFamilyClub_TempWfcGraC = Node & {
  __typename?: 'Club_WorldFamilyClub_TempWfcGraC';
  /** [NOT NULL] Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** ChildEnglishName */
  childEnglishName?: Maybe<Scalars['String']['output']>;
  /** ChildName */
  childName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildNo */
  childNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] GiftNo */
  giftNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Height */
  height?: Maybe<Scalars['String']['output']>;
  /** [ID] SeqNo */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemNo */
  memNo?: Maybe<Scalars['String']['output']>;
  /** Org */
  org?: Maybe<Scalars['String']['output']>;
  /** PassDate */
  passDate?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ProdYear */
  prodYear?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RegAge */
  regAge?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RegAge1 */
  regAge1?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RegDate */
  regDate?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RegType */
  regType?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Remark */
  remark?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Result */
  result?: Maybe<Scalars['String']['output']>;
  /** Rev */
  rev?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] Weight */
  weight?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempWfcGraCConnection = {
  __typename?: 'Club_WorldFamilyClub_TempWfcGraCConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempWfcGraCEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempWfcGraC>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempWfcGraCEdge = {
  __typename?: 'Club_WorldFamilyClub_TempWfcGraCEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempWfcGraC;
};

/** TempWfcGraC */
export type Club_WorldFamilyClub_TempWfcGraCFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCFilterInput>>;
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** ChildName */
  childName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] GiftNo */
  giftNo?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Height */
  height?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCFilterInput>>;
  /** Org */
  org?: InputMaybe<StringOperationFilterInput>;
  /** PassDate */
  passDate?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ProdYear */
  prodYear?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RegAge */
  regAge?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RegAge1 */
  regAge1?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RegDate */
  regDate?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RegType */
  regType?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Remark */
  remark?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Result */
  result?: InputMaybe<StringOperationFilterInput>;
  /** Rev */
  rev?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<DecimalOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<StringOperationFilterInput>;
};

/** TempWfcGraC */
export type Club_WorldFamilyClub_TempWfcGraCInput = {
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** ChildName */
  childName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] GiftNo */
  giftNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Height */
  height?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<Scalars['String']['input']>;
  /** Org */
  org?: InputMaybe<Scalars['String']['input']>;
  /** PassDate */
  passDate?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ProdYear */
  prodYear?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RegAge */
  regAge?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RegAge1 */
  regAge1?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RegDate */
  regDate?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RegType */
  regType?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Remark */
  remark?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Result */
  result?: InputMaybe<Scalars['String']['input']>;
  /** Rev */
  rev?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SeqNo */
  seqNo: Scalars['Decimal']['input'];
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<Scalars['String']['input']>;
};

/** TempWfcGraCOrganized */
export type Club_WorldFamilyClub_TempWfcGraCOrganized = Node & {
  __typename?: 'Club_WorldFamilyClub_TempWfcGraCOrganized';
  /** [NOT NULL] Cdt */
  cdt?: Maybe<Scalars['DateTime']['output']>;
  /** ChildEnglishName */
  childEnglishName?: Maybe<Scalars['String']['output']>;
  /** ChildName */
  childName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ChildNo */
  childNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] GiftNo */
  giftNo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Height */
  height?: Maybe<Scalars['String']['output']>;
  /** [ID] SeqNo */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemNo */
  memNo?: Maybe<Scalars['String']['output']>;
  /** Org */
  org?: Maybe<Scalars['String']['output']>;
  /** PassDate */
  passDate?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ProdYear */
  prodYear?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RegAge */
  regAge?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RegAge1 */
  regAge1?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RegDate */
  regDate?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] RegType */
  regType?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Remark */
  remark?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Result */
  result?: Maybe<Scalars['String']['output']>;
  /** Rev */
  rev?: Maybe<Scalars['String']['output']>;
  /** Udt */
  udt?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] Weight */
  weight?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TempWfcGraCOrganizedConnection = {
  __typename?: 'Club_WorldFamilyClub_TempWfcGraCOrganizedConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganizedEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganized>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TempWfcGraCOrganizedEdge = {
  __typename?: 'Club_WorldFamilyClub_TempWfcGraCOrganizedEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TempWfcGraCOrganized;
};

/** TempWfcGraCOrganized */
export type Club_WorldFamilyClub_TempWfcGraCOrganizedFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganizedFilterInput>>;
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<DateTimeOperationFilterInput>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<StringOperationFilterInput>;
  /** ChildName */
  childName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] GiftNo */
  giftNo?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Height */
  height?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganizedFilterInput>>;
  /** Org */
  org?: InputMaybe<StringOperationFilterInput>;
  /** PassDate */
  passDate?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ProdYear */
  prodYear?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RegAge */
  regAge?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RegAge1 */
  regAge1?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RegDate */
  regDate?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] RegType */
  regType?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Remark */
  remark?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Result */
  result?: InputMaybe<StringOperationFilterInput>;
  /** Rev */
  rev?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<DecimalOperationFilterInput>;
  /** Udt */
  udt?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<StringOperationFilterInput>;
};

/** TempWfcGraCOrganized */
export type Club_WorldFamilyClub_TempWfcGraCOrganizedInput = {
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<Scalars['DateTime']['input']>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<Scalars['String']['input']>;
  /** ChildName */
  childName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] GiftNo */
  giftNo?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Height */
  height?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<Scalars['String']['input']>;
  /** Org */
  org?: InputMaybe<Scalars['String']['input']>;
  /** PassDate */
  passDate?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ProdYear */
  prodYear?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RegAge */
  regAge?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RegAge1 */
  regAge1?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RegDate */
  regDate?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] RegType */
  regType?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Remark */
  remark?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Result */
  result?: InputMaybe<Scalars['String']['input']>;
  /** Rev */
  rev?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SeqNo */
  seqNo: Scalars['Decimal']['input'];
  /** Udt */
  udt?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<Scalars['String']['input']>;
};

/** TempWfcGraCOrganized */
export type Club_WorldFamilyClub_TempWfcGraCOrganizedSortInput = {
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<SortEnumType>;
  /** ChildName */
  childName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] GiftNo */
  giftNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Height */
  height?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<SortEnumType>;
  /** Org */
  org?: InputMaybe<SortEnumType>;
  /** PassDate */
  passDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ProdYear */
  prodYear?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RegAge */
  regAge?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RegAge1 */
  regAge1?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RegDate */
  regDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RegType */
  regType?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Remark */
  remark?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Result */
  result?: InputMaybe<SortEnumType>;
  /** Rev */
  rev?: InputMaybe<SortEnumType>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<SortEnumType>;
};

/** TempWfcGraC */
export type Club_WorldFamilyClub_TempWfcGraCSortInput = {
  /** [NOT NULL] Cdt */
  cdt?: InputMaybe<SortEnumType>;
  /** ChildEnglishName */
  childEnglishName?: InputMaybe<SortEnumType>;
  /** ChildName */
  childName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ChildNo */
  childNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] GiftNo */
  giftNo?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Height */
  height?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemNo */
  memNo?: InputMaybe<SortEnumType>;
  /** Org */
  org?: InputMaybe<SortEnumType>;
  /** PassDate */
  passDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ProdYear */
  prodYear?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RegAge */
  regAge?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RegAge1 */
  regAge1?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RegDate */
  regDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] RegType */
  regType?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Remark */
  remark?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Result */
  result?: InputMaybe<SortEnumType>;
  /** Rev */
  rev?: InputMaybe<SortEnumType>;
  /** [ID] SeqNo */
  seqNo?: InputMaybe<SortEnumType>;
  /** Udt */
  udt?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Weight */
  weight?: InputMaybe<SortEnumType>;
};

/** TestUserAccount */
export type Club_WorldFamilyClub_TestUserAccount = Node & {
  __typename?: 'Club_WorldFamilyClub_TestUserAccount';
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** [ID] ProjectName */
  id: Scalars['ID']['output'];
  /** MobilePhone */
  mobilePhone?: Maybe<Scalars['String']['output']>;
  /** SmsCode */
  smsCode?: Maybe<Scalars['String']['output']>;
  /** UserId */
  userId?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TestUserAccountConnection = {
  __typename?: 'Club_WorldFamilyClub_TestUserAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TestUserAccountEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TestUserAccount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TestUserAccountEdge = {
  __typename?: 'Club_WorldFamilyClub_TestUserAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TestUserAccount;
};

/** TestUserAccount */
export type Club_WorldFamilyClub_TestUserAccountFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TestUserAccountFilterInput>>;
  /** Email */
  email?: InputMaybe<StringOperationFilterInput>;
  /** MobilePhone */
  mobilePhone?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TestUserAccountFilterInput>>;
  /** [ID] ProjectName */
  projectName?: InputMaybe<StringOperationFilterInput>;
  /** SmsCode */
  smsCode?: InputMaybe<StringOperationFilterInput>;
  /** UserId */
  userId?: InputMaybe<StringOperationFilterInput>;
};

/** TestUserAccount */
export type Club_WorldFamilyClub_TestUserAccountInput = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** MobilePhone */
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  /** [ID] ProjectName */
  projectName?: InputMaybe<Scalars['String']['input']>;
  /** SmsCode */
  smsCode?: InputMaybe<Scalars['String']['input']>;
  /** UserId */
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** TestUserAccount */
export type Club_WorldFamilyClub_TestUserAccountSortInput = {
  /** Email */
  email?: InputMaybe<SortEnumType>;
  /** MobilePhone */
  mobilePhone?: InputMaybe<SortEnumType>;
  /** [ID] ProjectName */
  projectName?: InputMaybe<SortEnumType>;
  /** SmsCode */
  smsCode?: InputMaybe<SortEnumType>;
  /** UserId */
  userId?: InputMaybe<SortEnumType>;
};

/** TestWater */
export type Club_WorldFamilyClub_TestWater = Node & {
  __typename?: 'Club_WorldFamilyClub_TestWater';
  /** A */
  a?: Maybe<Scalars['String']['output']>;
  /** B */
  b?: Maybe<Scalars['String']['output']>;
  /** Create */
  create?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Id */
  id: Scalars['ID']['output'];
  /** [COLLECTION] TestWaterSubs */
  testWaterSubs?: Maybe<Array<Maybe<Club_WorldFamilyClub_TestWaterSub>>>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TestWaterConnection = {
  __typename?: 'Club_WorldFamilyClub_TestWaterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TestWaterEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TestWater>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TestWaterEdge = {
  __typename?: 'Club_WorldFamilyClub_TestWaterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TestWater;
};

/** TestWater */
export type Club_WorldFamilyClub_TestWaterFilterInput = {
  /** A */
  a?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterFilterInput>>;
  /** B */
  b?: InputMaybe<StringOperationFilterInput>;
  /** Create */
  create?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] Id */
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterFilterInput>>;
  /** [COLLECTION] TestWaterSubs */
  testWaterSubs?: InputMaybe<ListFilterInputTypeOfClub_WorldFamilyClub_TestWaterSubFilterInput>;
};

/** TestWater */
export type Club_WorldFamilyClub_TestWaterInput = {
  /** A */
  a?: InputMaybe<Scalars['String']['input']>;
  /** B */
  b?: InputMaybe<Scalars['String']['input']>;
  /** Create */
  create?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] Id */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** [COLLECTION] TestWaterSubs */
  testWaterSubs?: InputMaybe<Array<InputMaybe<Club_WorldFamilyClub_TestWaterSubInput>>>;
};

/** TestWater */
export type Club_WorldFamilyClub_TestWaterSortInput = {
  /** A */
  a?: InputMaybe<SortEnumType>;
  /** B */
  b?: InputMaybe<SortEnumType>;
  /** Create */
  create?: InputMaybe<SortEnumType>;
  /** [ID] Id */
  id?: InputMaybe<SortEnumType>;
};

/** TestWaterSub */
export type Club_WorldFamilyClub_TestWaterSub = Node & {
  __typename?: 'Club_WorldFamilyClub_TestWaterSub';
  /** [ID] SubId */
  id: Scalars['ID']['output'];
  /** [NAVIGATION] IdNavigation */
  idNavigation?: Maybe<Club_WorldFamilyClub_TestWater>;
  /** SubA */
  subA?: Maybe<Scalars['String']['output']>;
  /** SubB */
  subB?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_TestWaterSubConnection = {
  __typename?: 'Club_WorldFamilyClub_TestWaterSubConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_TestWaterSubEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_TestWaterSub>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_TestWaterSubEdge = {
  __typename?: 'Club_WorldFamilyClub_TestWaterSubEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_TestWaterSub;
};

/** TestWaterSub */
export type Club_WorldFamilyClub_TestWaterSubFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterSubFilterInput>>;
  /** [NOT NULL] Id */
  id?: InputMaybe<UuidOperationFilterInput>;
  /** [NAVIGATION] IdNavigation */
  idNavigation?: InputMaybe<Club_WorldFamilyClub_TestWaterFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterSubFilterInput>>;
  /** SubA */
  subA?: InputMaybe<StringOperationFilterInput>;
  /** SubB */
  subB?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SubId */
  subId?: InputMaybe<UuidOperationFilterInput>;
};

/** TestWaterSub */
export type Club_WorldFamilyClub_TestWaterSubInput = {
  /** [NOT NULL] Id */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** [NAVIGATION] IdNavigation */
  idNavigation?: InputMaybe<Club_WorldFamilyClub_TestWaterInput>;
  /** SubA */
  subA?: InputMaybe<Scalars['String']['input']>;
  /** SubB */
  subB?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SubId */
  subId?: InputMaybe<Scalars['UUID']['input']>;
};

/** TestWaterSub */
export type Club_WorldFamilyClub_TestWaterSubSortInput = {
  /** [NOT NULL] Id */
  id?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] IdNavigation */
  idNavigation?: InputMaybe<Club_WorldFamilyClub_TestWaterSortInput>;
  /** SubA */
  subA?: InputMaybe<SortEnumType>;
  /** SubB */
  subB?: InputMaybe<SortEnumType>;
  /** [ID] SubId */
  subId?: InputMaybe<SortEnumType>;
};

/** Tev2MonthlySong */
export type Club_WorldFamilyClub_Tev2MonthlySong = Node & {
  __typename?: 'Club_WorldFamilyClub_Tev2MonthlySong';
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** [NOT NULL] IntroductionBanner */
  introductionBanner?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Month */
  month?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] Tev2Banner */
  tev2Banner?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Tev2SongId */
  tev2SongId?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] VimeoUrl */
  vimeoUrl?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Year */
  year?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_Tev2MonthlySongConnection = {
  __typename?: 'Club_WorldFamilyClub_Tev2MonthlySongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_Tev2MonthlySongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_Tev2MonthlySong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_Tev2MonthlySongEdge = {
  __typename?: 'Club_WorldFamilyClub_Tev2MonthlySongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_Tev2MonthlySong;
};

/** Tev2MonthlySong */
export type Club_WorldFamilyClub_Tev2MonthlySongFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_Tev2MonthlySongFilterInput>>;
  /** [NOT NULL] IntroductionBanner */
  introductionBanner?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Month */
  month?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_Tev2MonthlySongFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Tev2Banner */
  tev2Banner?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Tev2SongId */
  tev2SongId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] VimeoUrl */
  vimeoUrl?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Year */
  year?: InputMaybe<IntOperationFilterInput>;
};

/** Tev2MonthlySong */
export type Club_WorldFamilyClub_Tev2MonthlySongInput = {
  /** [NOT NULL] IntroductionBanner */
  introductionBanner?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Month */
  month?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Tev2Banner */
  tev2Banner?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Tev2SongId */
  tev2SongId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] VimeoUrl */
  vimeoUrl?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Year */
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Tev2MonthlySong */
export type Club_WorldFamilyClub_Tev2MonthlySongSortInput = {
  /** [NOT NULL] IntroductionBanner */
  introductionBanner?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Month */
  month?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Tev2Banner */
  tev2Banner?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Tev2SongId */
  tev2SongId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] VimeoUrl */
  vimeoUrl?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Year */
  year?: InputMaybe<SortEnumType>;
};

/** User */
export type Club_WorldFamilyClub_User = Node & {
  __typename?: 'Club_WorldFamilyClub_User';
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** [ID] UserId */
  id: Scalars['ID']['output'];
  /** UserName */
  userName?: Maybe<Scalars['String']['output']>;
  /** UtcCdt */
  utcCdt?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_UserConnection = {
  __typename?: 'Club_WorldFamilyClub_UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_UserEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_UserEdge = {
  __typename?: 'Club_WorldFamilyClub_UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_User;
};

/** User */
export type Club_WorldFamilyClub_UserFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_UserFilterInput>>;
  /** Email */
  email?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_UserFilterInput>>;
  /** [ID] UserId */
  userId?: InputMaybe<StringOperationFilterInput>;
  /** UserName */
  userName?: InputMaybe<StringOperationFilterInput>;
  /** UtcCdt */
  utcCdt?: InputMaybe<DateTimeOperationFilterInput>;
};

/** User */
export type Club_WorldFamilyClub_UserInput = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** [ID] UserId */
  userId?: InputMaybe<Scalars['String']['input']>;
  /** UserName */
  userName?: InputMaybe<Scalars['String']['input']>;
  /** UtcCdt */
  utcCdt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** User */
export type Club_WorldFamilyClub_UserSortInput = {
  /** Email */
  email?: InputMaybe<SortEnumType>;
  /** [ID] UserId */
  userId?: InputMaybe<SortEnumType>;
  /** UserName */
  userName?: InputMaybe<SortEnumType>;
  /** UtcCdt */
  utcCdt?: InputMaybe<SortEnumType>;
};

/** VideoForTrialmode */
export type Club_WorldFamilyClub_VideoForTrialmode = Node & {
  __typename?: 'Club_WorldFamilyClub_VideoForTrialmode';
  /** [ID] VideoForTrialmodeId */
  id: Scalars['ID']['output'];
  /** ImageUrl */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] VideoId */
  videoId?: Maybe<Scalars['Int']['output']>;
  /** VideoTitle */
  videoTitle?: Maybe<Scalars['String']['output']>;
  /** VimeoId */
  vimeoId?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Club_WorldFamilyClub_VideoForTrialmodeConnection = {
  __typename?: 'Club_WorldFamilyClub_VideoForTrialmodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Club_WorldFamilyClub_VideoForTrialmodeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Club_WorldFamilyClub_VideoForTrialmode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Club_WorldFamilyClub_VideoForTrialmodeEdge = {
  __typename?: 'Club_WorldFamilyClub_VideoForTrialmodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Club_WorldFamilyClub_VideoForTrialmode;
};

/** VideoForTrialmode */
export type Club_WorldFamilyClub_VideoForTrialmodeFilterInput = {
  and?: InputMaybe<Array<Club_WorldFamilyClub_VideoForTrialmodeFilterInput>>;
  /** ImageUrl */
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Club_WorldFamilyClub_VideoForTrialmodeFilterInput>>;
  /** Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
  /** [ID] VideoForTrialmodeId */
  videoForTrialmodeId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] VideoId */
  videoId?: InputMaybe<IntOperationFilterInput>;
  /** VideoTitle */
  videoTitle?: InputMaybe<StringOperationFilterInput>;
  /** VimeoId */
  vimeoId?: InputMaybe<StringOperationFilterInput>;
};

/** VideoForTrialmode */
export type Club_WorldFamilyClub_VideoForTrialmodeInput = {
  /** ImageUrl */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
  /** [ID] VideoForTrialmodeId */
  videoForTrialmodeId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] VideoId */
  videoId?: InputMaybe<Scalars['Int']['input']>;
  /** VideoTitle */
  videoTitle?: InputMaybe<Scalars['String']['input']>;
  /** VimeoId */
  vimeoId?: InputMaybe<Scalars['String']['input']>;
};

/** VideoForTrialmode */
export type Club_WorldFamilyClub_VideoForTrialmodeSortInput = {
  /** ImageUrl */
  imageUrl?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
  /** [ID] VideoForTrialmodeId */
  videoForTrialmodeId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] VideoId */
  videoId?: InputMaybe<SortEnumType>;
  /** VideoTitle */
  videoTitle?: InputMaybe<SortEnumType>;
  /** VimeoId */
  vimeoId?: InputMaybe<SortEnumType>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DefaultServiceBannerInfo = {
  __typename?: 'DefaultServiceBannerInfo';
  image: Scalars['String']['output'];
  market: Scalars['String']['output'];
  publishDate: Scalars['String']['output'];
  seq: Scalars['Int']['output'];
  service: Scalars['String']['output'];
  stopDate: Scalars['String']['output'];
  theme: Scalars['String']['output'];
};

export type DefaultServiceBannerInfoFilterInput = {
  and?: InputMaybe<Array<DefaultServiceBannerInfoFilterInput>>;
  image?: InputMaybe<StringOperationFilterInput>;
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DefaultServiceBannerInfoFilterInput>>;
  publishDate?: InputMaybe<StringOperationFilterInput>;
  seq?: InputMaybe<IntOperationFilterInput>;
  service?: InputMaybe<StringOperationFilterInput>;
  stopDate?: InputMaybe<StringOperationFilterInput>;
  theme?: InputMaybe<StringOperationFilterInput>;
};

export type DefaultServiceBannerInfoSortInput = {
  image?: InputMaybe<SortEnumType>;
  market?: InputMaybe<SortEnumType>;
  publishDate?: InputMaybe<SortEnumType>;
  seq?: InputMaybe<SortEnumType>;
  service?: InputMaybe<SortEnumType>;
  stopDate?: InputMaybe<SortEnumType>;
  theme?: InputMaybe<SortEnumType>;
};

export type DefaultServiceBannerParameterInput = {
  image: Scalars['String']['input'];
  seq: Scalars['Int']['input'];
};

export enum Error {
  AddFailed = 'ADD_FAILED',
  DeleteFailed = 'DELETE_FAILED',
  Sented = 'SENTED',
  UpdateFailed = 'UPDATE_FAILED'
}

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_DweProductSongFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_DweProductSongFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_DweProductSongFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_DweProductSongFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_DweProductSongToolFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_DweProductSongToolFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_DweProductSongToolFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_DweProductSongToolFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallHistFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_FaceCallHistFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_FaceCallHistFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_FaceCallHistFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongAndPerformerFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_FaceCallSongPublicFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_GraduationApplicationEvaluationFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_GraduationApplicationFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_GraduationApplicationSubTaskFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_GraduationEvaluationFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_GraduationSubApplicationFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_GraphqlAccessFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_GraphqlAccessFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_GraphqlAccessFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_GraphqlAccessFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_MyFavoriteFaceCallFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput>;
};

export type ListFilterInputTypeOfClub_WorldFamilyClub_TestWaterSubFilterInput = {
  all?: InputMaybe<Club_WorldFamilyClub_TestWaterSubFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Club_WorldFamilyClub_TestWaterSubFilterInput>;
  some?: InputMaybe<Club_WorldFamilyClub_TestWaterSubFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_AbFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_AbFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_AbFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_AbFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_AfProgramFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_AfProgramFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_AfProgramFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_AfProgramFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_AfUserMarketFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_AfUserMarketFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_AfUserMarketFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_AfUserMarketFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_AfUserProgramFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_AfUserProgramFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_AfUserProgramFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_AfUserProgramFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_AfUserServiceFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_AfUserServiceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_AfUserServiceFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_AfUserServiceFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_GraphqlAccessFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_GraphqlAccessFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_GraphqlAccessFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_GraphqlAccessFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_TepsDailyHourFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_TepsDailyHourFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_TepsDailyHourFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_TepsDailyHourFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_TepsProjectFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_TepsProjectFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_TepsProjectFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_TepsProjectFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_TepsProjectTypeFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_TepsProjectTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_TepsProjectTypeFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_TepsProjectTypeFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_TepsProjectionFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_TepsProjectionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_TepsProjectionFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_TepsProjectionFilterInput>;
};

export type ListFilterInputTypeOfWf_AdminFirst_TepsUserProjectFilterInput = {
  all?: InputMaybe<Wf_AdminFirst_TepsUserProjectFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_AdminFirst_TepsUserProjectFilterInput>;
  some?: InputMaybe<Wf_AdminFirst_TepsUserProjectFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_CallToActionContentFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_CallToActionContentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_CallToActionContentFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_CallToActionContentFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_CallToActionFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_CallToActionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_CallToActionFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_CallToActionFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_GraphqlAccessFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_GraphqlAccessFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_GraphqlAccessFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_GraphqlAccessFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_JourneyCardFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_JourneyCardFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_JourneyCardFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_JourneyCardFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_NotificationFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_NotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_NotificationFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_NotificationFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_NotificationToSegmentationFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_NotificationToSegmentationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_NotificationToSegmentationFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_NotificationToSegmentationFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_ProductSongAndMarketFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_ProductSongAndMarketFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_ProductSongAndMarketFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_ProductSongAndMarketFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_ProductSongFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_ProductSongFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_ProductSongFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_ProductSongFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_ProductVideoAndMarketFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_ProductVideoAndMarketFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_ProductVideoAndMarketFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_ProductVideoAndMarketFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_ProductVideoFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_ProductVideoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_ProductVideoFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_ProductVideoFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_TrialTokenFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_TrialTokenFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_TrialTokenFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_TrialTokenFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_TrialUserNotificationFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_TrialUserNotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_TrialUserNotificationFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_TrialUserNotificationFilterInput>;
};

export type ListFilterInputTypeOfWf_TrialModeLeads_TrialUserSegmentationLogFilterInput = {
  all?: InputMaybe<Wf_TrialModeLeads_TrialUserSegmentationLogFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Wf_TrialModeLeads_TrialUserSegmentationLogFilterInput>;
  some?: InputMaybe<Wf_TrialModeLeads_TrialUserSegmentationLogFilterInput>;
};

export type ListIntOperationFilterInput = {
  all?: InputMaybe<IntOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<IntOperationFilterInput>;
  some?: InputMaybe<IntOperationFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export enum Market {
  HongKong = 'HONG_KONG',
  Korea = 'KOREA',
  Taiwan = 'TAIWAN'
}

export type Mutation = {
  __typename?: 'Mutation';
  addSalesNotification: Payload;
  /** Add Scheduled Service Banner */
  addScheduledServiceBanner: Payload;
  /** Add TEV2 Monthly Song */
  addTev2MonthlySong: Payload;
  addTrialModeNotification: Payload;
  afAddNotification: Payload;
  afRemoveNotification: Payload;
  afUpdateChildGraduationOfHongKong: Payload;
  afUpdateChildGraduationOfKorea: Payload;
  afUpdateChildGraduationOfTaiwan: Payload;
  afUpdateGraduationChild: Payload;
  afUpload: UploadPayload;
  club_worldFamilyClub: Club_WorldFamilyClub_Mutation_Payload;
  /** Delete Scheduled Service Banner */
  deleteScheduledServiceBanner: Payload;
  /** Delete TEV2 Monthly Song */
  delteTev2MonthlySong: Payload;
  removeGraduationApplication: Payload;
  removeSalesNotification: Payload;
  removeTrialModeNotification: Payload;
  /** Update Default Service Banner */
  updateDefaultServiceBanner: Payload;
  updateSales188Release: Payload;
  /** Update Scheduled Service Banner */
  updateScheduledServiceBanner: Payload;
  /** Update TEV2 Monthly Song */
  updateTev2MonthlySong: Payload;
  wf_adminFirst: Wf_AdminFirst_Mutation_Payload;
  wf_trialModeLeads: Wf_TrialModeLeads_Mutation_Payload;
};


export type MutationAddSalesNotificationArgs = {
  sales188Notification: Sales188NotificationInput;
  testEmployeeCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationAddScheduledServiceBannerArgs = {
  parameter: ScheduledServiceBannerParameterInput;
};


export type MutationAddTev2MonthlySongArgs = {
  parameter: Tev2MonthlySongInfoInput;
};


export type MutationAddTrialModeNotificationArgs = {
  members?: InputMaybe<Array<TrialModeMemberInput>>;
  notification: TrialModeNotificationInput;
  segmentations?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationAfAddNotificationArgs = {
  isAllMembers?: Scalars['Boolean']['input'];
  members?: InputMaybe<Array<AfMemberInput>>;
  notification: AfNotificationInput;
};


export type MutationAfRemoveNotificationArgs = {
  notification: AfNotificationInput;
};


export type MutationAfUpdateChildGraduationOfHongKongArgs = {
  graduationOfHongKong: AfUpdateChildGraduationOfHongKongInput;
};


export type MutationAfUpdateChildGraduationOfKoreaArgs = {
  graduationOfKorea: AfUpdateChildGraduationOfKoreaInput;
};


export type MutationAfUpdateChildGraduationOfTaiwanArgs = {
  graduationOfTaiwan: AfUpdateChildGraduationOfTaiwanInput;
};


export type MutationAfUpdateGraduationChildArgs = {
  childEnglishName?: InputMaybe<Scalars['String']['input']>;
  childHeight?: InputMaybe<Scalars['Int']['input']>;
  childPhotoFilename?: InputMaybe<Scalars['String']['input']>;
  childWeight?: InputMaybe<Scalars['Int']['input']>;
  childWillingEnglishSpeech?: InputMaybe<Scalars['Boolean']['input']>;
  parentWillingChineseSpeech?: InputMaybe<Scalars['Boolean']['input']>;
  seq: Scalars['Int']['input'];
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  updater?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAfUploadArgs = {
  blobs: Array<BlobOfAfBlobPathInput>;
};


export type MutationClub_WorldFamilyClubArgs = {
  add?: InputMaybe<Club_WorldFamilyClub_Mutation_AddInput>;
  addRange?: InputMaybe<Club_WorldFamilyClub_Mutation_AddRangeInput>;
  remove?: InputMaybe<Club_WorldFamilyClub_Mutation_RemoveInput>;
  removeRange?: InputMaybe<Club_WorldFamilyClub_Mutation_RemoveRangeInput>;
  update?: InputMaybe<Club_WorldFamilyClub_Mutation_UpdateInput>;
  updateRange?: InputMaybe<Club_WorldFamilyClub_Mutation_UpdateRangeInput>;
};


export type MutationDeleteScheduledServiceBannerArgs = {
  seqs: Array<Scalars['Int']['input']>;
};


export type MutationDelteTev2MonthlySongArgs = {
  seq: Scalars['Int']['input'];
};


export type MutationRemoveGraduationApplicationArgs = {
  applicationId: Scalars['Int']['input'];
};


export type MutationRemoveSalesNotificationArgs = {
  notificationId: Scalars['Int']['input'];
};


export type MutationRemoveTrialModeNotificationArgs = {
  modeNotification: TrialModeNotificationInput;
};


export type MutationUpdateDefaultServiceBannerArgs = {
  parameter: DefaultServiceBannerParameterInput;
};


export type MutationUpdateSales188ReleaseArgs = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  appstore?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  releaseTime?: InputMaybe<Scalars['DateTime']['input']>;
  seq: Scalars['Short']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateScheduledServiceBannerArgs = {
  parameter: ScheduledServiceBannerParameterInput;
};


export type MutationUpdateTev2MonthlySongArgs = {
  parameter: Tev2MonthlySongInfoInput;
};


export type MutationWf_AdminFirstArgs = {
  add?: InputMaybe<Wf_AdminFirst_Mutation_AddInput>;
  addRange?: InputMaybe<Wf_AdminFirst_Mutation_AddRangeInput>;
  remove?: InputMaybe<Wf_AdminFirst_Mutation_RemoveInput>;
  removeRange?: InputMaybe<Wf_AdminFirst_Mutation_RemoveRangeInput>;
  update?: InputMaybe<Wf_AdminFirst_Mutation_UpdateInput>;
  updateRange?: InputMaybe<Wf_AdminFirst_Mutation_UpdateRangeInput>;
};


export type MutationWf_TrialModeLeadsArgs = {
  add?: InputMaybe<Wf_TrialModeLeads_Mutation_AddInput>;
  addRange?: InputMaybe<Wf_TrialModeLeads_Mutation_AddRangeInput>;
  remove?: InputMaybe<Wf_TrialModeLeads_Mutation_RemoveInput>;
  removeRange?: InputMaybe<Wf_TrialModeLeads_Mutation_RemoveRangeInput>;
  update?: InputMaybe<Wf_TrialModeLeads_Mutation_UpdateInput>;
  updateRange?: InputMaybe<Wf_TrialModeLeads_Mutation_UpdateRangeInput>;
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID']['output'];
};

export type NullableOfMarketOperationFilterInput = {
  eq?: InputMaybe<Market>;
  in?: InputMaybe<Array<InputMaybe<Market>>>;
  neq?: InputMaybe<Market>;
  nin?: InputMaybe<Array<InputMaybe<Market>>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Payload = {
  __typename?: 'Payload';
  count: Scalars['Int']['output'];
  error?: Maybe<Error>;
  query: Query;
};

export type Query = {
  __typename?: 'Query';
  afBlobContainerSignature: Array<BlobContainerSignatureOfAfBlobPath>;
  afChildGraduationApplication: Array<Club_WorldFamilyClub_ChildGraduationApplication>;
  afFaceCallPerformer: Array<Club_WorldFamilyClub_FaceCallPerformer>;
  afFaceCallSong: Array<Club_WorldFamilyClub_FaceCallSong>;
  afGraduationApplication: Array<Club_WorldFamilyClub_GraduationApplication>;
  afGraduationChild: Array<AfGraduationChild>;
  afMember?: Maybe<AfMemberConnection>;
  afTeSong: Array<Club_WorldFamilyClub_TeSong>;
  club_worldFamilyClub_appAuth?: Maybe<Club_WorldFamilyClub_AppAuthConnection>;
  club_worldFamilyClub_appBanner?: Maybe<Club_WorldFamilyClub_AppBannerConnection>;
  club_worldFamilyClub_appDigitalMagazine?: Maybe<Club_WorldFamilyClub_AppDigitalMagazineConnection>;
  club_worldFamilyClub_appMemberNotification?: Maybe<Club_WorldFamilyClub_AppMemberNotificationConnection>;
  club_worldFamilyClub_appNotification?: Maybe<Club_WorldFamilyClub_AppNotificationConnection>;
  club_worldFamilyClub_appNotificationMember?: Maybe<Club_WorldFamilyClub_AppNotificationMemberConnection>;
  club_worldFamilyClub_appNotificationType?: Maybe<Club_WorldFamilyClub_AppNotificationTypeConnection>;
  club_worldFamilyClub_appNotificationTypeMarket?: Maybe<Club_WorldFamilyClub_AppNotificationTypeMarketConnection>;
  club_worldFamilyClub_appRegionEmergency?: Maybe<Club_WorldFamilyClub_AppRegionEmergencyConnection>;
  club_worldFamilyClub_appRelease?: Maybe<Club_WorldFamilyClub_AppReleaseConnection>;
  club_worldFamilyClub_appServiceRegionFestivalImage?: Maybe<Club_WorldFamilyClub_AppServiceRegionFestivalImageConnection>;
  club_worldFamilyClub_appServiceRegionImage?: Maybe<Club_WorldFamilyClub_AppServiceRegionImageConnection>;
  club_worldFamilyClub_appSurvey?: Maybe<Club_WorldFamilyClub_AppSurveyConnection>;
  club_worldFamilyClub_appSurveyMember?: Maybe<Club_WorldFamilyClub_AppSurveyMemberConnection>;
  club_worldFamilyClub_appSurveyQa?: Maybe<Club_WorldFamilyClub_AppSurveyQaConnection>;
  club_worldFamilyClub_appSurveyReminder?: Maybe<Club_WorldFamilyClub_AppSurveyReminderConnection>;
  club_worldFamilyClub_azureFunction?: Maybe<Club_WorldFamilyClub_AzureFunctionConnection>;
  club_worldFamilyClub_broadcastPromotion?: Maybe<Club_WorldFamilyClub_BroadcastPromotionConnection>;
  club_worldFamilyClub_childGraduationApplication?: Maybe<Club_WorldFamilyClub_ChildGraduationApplicationConnection>;
  club_worldFamilyClub_dweProductPackage?: Maybe<Club_WorldFamilyClub_DweProductPackageConnection>;
  club_worldFamilyClub_dweProductSong?: Maybe<Club_WorldFamilyClub_DweProductSongConnection>;
  club_worldFamilyClub_dweProductSongTool?: Maybe<Club_WorldFamilyClub_DweProductSongToolConnection>;
  club_worldFamilyClub_dweProductTool?: Maybe<Club_WorldFamilyClub_DweProductToolConnection>;
  club_worldFamilyClub_eventBanner?: Maybe<Club_WorldFamilyClub_EventBannerConnection>;
  club_worldFamilyClub_eventBannerRegion?: Maybe<Club_WorldFamilyClub_EventBannerRegionConnection>;
  club_worldFamilyClub_eventCheckInAuth?: Maybe<Club_WorldFamilyClub_EventCheckInAuthConnection>;
  club_worldFamilyClub_eventNotificationLog?: Maybe<Club_WorldFamilyClub_EventNotificationLogConnection>;
  club_worldFamilyClub_eventSeat?: Maybe<Club_WorldFamilyClub_EventSeatConnection>;
  club_worldFamilyClub_eventType?: Maybe<Club_WorldFamilyClub_EventTypeConnection>;
  club_worldFamilyClub_faceCallAndTelephoneEnglish?: Maybe<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishConnection>;
  club_worldFamilyClub_faceCallForTrialmode?: Maybe<Club_WorldFamilyClub_FaceCallForTrialmodeConnection>;
  club_worldFamilyClub_faceCallHist?: Maybe<Club_WorldFamilyClub_FaceCallHistConnection>;
  club_worldFamilyClub_faceCallHistCount?: Maybe<Club_WorldFamilyClub_FaceCallHistCountConnection>;
  club_worldFamilyClub_faceCallMonthlyRecommend?: Maybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendConnection>;
  club_worldFamilyClub_faceCallPerformer?: Maybe<Club_WorldFamilyClub_FaceCallPerformerConnection>;
  club_worldFamilyClub_faceCallSeasonHoliday?: Maybe<Club_WorldFamilyClub_FaceCallSeasonHolidayConnection>;
  club_worldFamilyClub_faceCallSong?: Maybe<Club_WorldFamilyClub_FaceCallSongConnection>;
  club_worldFamilyClub_faceCallSongAndPerformer?: Maybe<Club_WorldFamilyClub_FaceCallSongAndPerformerConnection>;
  club_worldFamilyClub_faceCallSongPublic?: Maybe<Club_WorldFamilyClub_FaceCallSongPublicConnection>;
  club_worldFamilyClub_goGoLiveDesktopQrcode?: Maybe<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeConnection>;
  club_worldFamilyClub_goGoLiveOpenDate?: Maybe<Club_WorldFamilyClub_GoGoLiveOpenDateConnection>;
  club_worldFamilyClub_goGoLivePromotion?: Maybe<Club_WorldFamilyClub_GoGoLivePromotionConnection>;
  club_worldFamilyClub_graduationApplication?: Maybe<Club_WorldFamilyClub_GraduationApplicationConnection>;
  club_worldFamilyClub_graduationApplicationEvaluation?: Maybe<Club_WorldFamilyClub_GraduationApplicationEvaluationConnection>;
  club_worldFamilyClub_graduationApplicationSubTask?: Maybe<Club_WorldFamilyClub_GraduationApplicationSubTaskConnection>;
  club_worldFamilyClub_graduationApplicationTask?: Maybe<Club_WorldFamilyClub_GraduationApplicationTaskConnection>;
  club_worldFamilyClub_graduationChild?: Maybe<Club_WorldFamilyClub_GraduationChildConnection>;
  club_worldFamilyClub_graduationChildSetting?: Maybe<Club_WorldFamilyClub_GraduationChildSettingConnection>;
  club_worldFamilyClub_graduationEvaluation?: Maybe<Club_WorldFamilyClub_GraduationEvaluationConnection>;
  club_worldFamilyClub_graduationNotification?: Maybe<Club_WorldFamilyClub_GraduationNotificationConnection>;
  club_worldFamilyClub_graduationSubApplication?: Maybe<Club_WorldFamilyClub_GraduationSubApplicationConnection>;
  club_worldFamilyClub_graduationSubTaskMarketDescription?: Maybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionConnection>;
  club_worldFamilyClub_graduationTaskMarketDescription?: Maybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionConnection>;
  club_worldFamilyClub_graphqlAccess?: Maybe<Club_WorldFamilyClub_GraphqlAccessConnection>;
  club_worldFamilyClub_graphqlSchema?: Maybe<Club_WorldFamilyClub_GraphqlSchemaConnection>;
  club_worldFamilyClub_memberMessageSetting?: Maybe<Club_WorldFamilyClub_MemberMessageSettingConnection>;
  club_worldFamilyClub_myFavoriteFaceCall?: Maybe<Club_WorldFamilyClub_MyFavoriteFaceCallConnection>;
  club_worldFamilyClub_myFavoriteFaceCallSong?: Maybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongConnection>;
  club_worldFamilyClub_notification?: Maybe<Club_WorldFamilyClub_NotificationConnection>;
  club_worldFamilyClub_notificationIcon?: Maybe<Club_WorldFamilyClub_NotificationIconConnection>;
  club_worldFamilyClub_oneTimeToken?: Maybe<Club_WorldFamilyClub_OneTimeTokenConnection>;
  club_worldFamilyClub_onlineEventLog?: Maybe<Club_WorldFamilyClub_OnlineEventLogConnection>;
  club_worldFamilyClub_onlineShow?: Maybe<Club_WorldFamilyClub_OnlineShowConnection>;
  club_worldFamilyClub_parameter?: Maybe<Club_WorldFamilyClub_ParameterConnection>;
  club_worldFamilyClub_regionBanner?: Maybe<Club_WorldFamilyClub_RegionBannerConnection>;
  club_worldFamilyClub_searchKeyword?: Maybe<Club_WorldFamilyClub_SearchKeywordConnection>;
  club_worldFamilyClub_service?: Maybe<Club_WorldFamilyClub_ServiceConnection>;
  club_worldFamilyClub_serviceBannerDefault?: Maybe<Club_WorldFamilyClub_ServiceBannerDefaultConnection>;
  club_worldFamilyClub_serviceBannerScheduled?: Maybe<Club_WorldFamilyClub_ServiceBannerScheduledConnection>;
  club_worldFamilyClub_serviceSearch?: Maybe<Club_WorldFamilyClub_ServiceSearchConnection>;
  club_worldFamilyClub_smsCertification?: Maybe<Club_WorldFamilyClub_SmsCertificationConnection>;
  club_worldFamilyClub_smsVerificationCode?: Maybe<Club_WorldFamilyClub_SmsVerificationCodeConnection>;
  club_worldFamilyClub_surveyTypeForm?: Maybe<Club_WorldFamilyClub_SurveyTypeFormConnection>;
  club_worldFamilyClub_surveyTypeFormList?: Maybe<Club_WorldFamilyClub_SurveyTypeFormListConnection>;
  club_worldFamilyClub_teSong?: Maybe<Club_WorldFamilyClub_TeSongConnection>;
  club_worldFamilyClub_temp20240408Graduation?: Maybe<Club_WorldFamilyClub_Temp20240408GraduationConnection>;
  club_worldFamilyClub_temp20240408Graduation1?: Maybe<Club_WorldFamilyClub_Temp20240408Graduation1Connection>;
  club_worldFamilyClub_tempFaceCall?: Maybe<Club_WorldFamilyClub_TempFaceCallConnection>;
  club_worldFamilyClub_tempFaceCallHk?: Maybe<Club_WorldFamilyClub_TempFaceCallHkConnection>;
  club_worldFamilyClub_tempFaceCallKr?: Maybe<Club_WorldFamilyClub_TempFaceCallKrConnection>;
  club_worldFamilyClub_tempHkAppMyFavorite?: Maybe<Club_WorldFamilyClub_TempHkAppMyFavoriteConnection>;
  club_worldFamilyClub_tempHkClubappsFacecallCrew?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallCrewConnection>;
  club_worldFamilyClub_tempHkClubappsFacecallHistory?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryConnection>;
  club_worldFamilyClub_tempHkClubappsFacecallSong?: Maybe<Club_WorldFamilyClub_TempHkClubappsFacecallSongConnection>;
  club_worldFamilyClub_tempHkDmemChild?: Maybe<Club_WorldFamilyClub_TempHkDmemChildConnection>;
  club_worldFamilyClub_tempHkTeSong?: Maybe<Club_WorldFamilyClub_TempHkTeSongConnection>;
  club_worldFamilyClub_tempHkTematerial?: Maybe<Club_WorldFamilyClub_TempHkTematerialConnection>;
  club_worldFamilyClub_tempKrFaceCallHist?: Maybe<Club_WorldFamilyClub_TempKrFaceCallHistConnection>;
  club_worldFamilyClub_tempKrFcCrewM?: Maybe<Club_WorldFamilyClub_TempKrFcCrewMConnection>;
  club_worldFamilyClub_tempKrFcsongCrew?: Maybe<Club_WorldFamilyClub_TempKrFcsongCrewConnection>;
  club_worldFamilyClub_tempKrMyFavorite?: Maybe<Club_WorldFamilyClub_TempKrMyFavoriteConnection>;
  club_worldFamilyClub_tempKrTeSong?: Maybe<Club_WorldFamilyClub_TempKrTeSongConnection>;
  club_worldFamilyClub_tempTwCambridge?: Maybe<Club_WorldFamilyClub_TempTwCambridgeConnection>;
  club_worldFamilyClub_tempTwFaceCallHist?: Maybe<Club_WorldFamilyClub_TempTwFaceCallHistConnection>;
  club_worldFamilyClub_tempTwFcCrewM?: Maybe<Club_WorldFamilyClub_TempTwFcCrewMConnection>;
  club_worldFamilyClub_tempTwFcsongCrew?: Maybe<Club_WorldFamilyClub_TempTwFcsongCrewConnection>;
  club_worldFamilyClub_tempTwFcsongMonthlyRecommend?: Maybe<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendConnection>;
  club_worldFamilyClub_tempTwMyFavorite?: Maybe<Club_WorldFamilyClub_TempTwMyFavoriteConnection>;
  club_worldFamilyClub_tempTwTeSong?: Maybe<Club_WorldFamilyClub_TempTwTeSongConnection>;
  club_worldFamilyClub_tempWfcGraC?: Maybe<Club_WorldFamilyClub_TempWfcGraCConnection>;
  club_worldFamilyClub_tempWfcGraCOrganized?: Maybe<Club_WorldFamilyClub_TempWfcGraCOrganizedConnection>;
  club_worldFamilyClub_testUserAccount?: Maybe<Club_WorldFamilyClub_TestUserAccountConnection>;
  club_worldFamilyClub_testWater?: Maybe<Club_WorldFamilyClub_TestWaterConnection>;
  club_worldFamilyClub_testWaterSub?: Maybe<Club_WorldFamilyClub_TestWaterSubConnection>;
  club_worldFamilyClub_tev2MonthlySong?: Maybe<Club_WorldFamilyClub_Tev2MonthlySongConnection>;
  club_worldFamilyClub_user?: Maybe<Club_WorldFamilyClub_UserConnection>;
  club_worldFamilyClub_videoForTrialmode?: Maybe<Club_WorldFamilyClub_VideoForTrialmodeConnection>;
  /** Get Default Service Banner List */
  defaultServiceBannerList: Array<DefaultServiceBannerInfo>;
  /** Get Market List of Default Banner */
  marketListOfDefaultBanner: Array<BannerMarketInfo>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  sales188Notification: Array<Sales188Notification>;
  sales188Release: Array<Sales188Release>;
  /** Get Scheduled Service Banner List */
  scheduledServiceBannerList: Array<ScheduledServiceBannerInfo>;
  /** Get Service List of Default Banner */
  serviceListOfDefaultBanner: Array<BannerServiceInfo>;
  /** Get TEV2 Monthly Song By seq */
  tev2MonthlySongInfo: Array<Tev2MonthlySongInfo>;
  /** Get TEV2 Monthly Song List */
  tev2MonthlySongList: Array<Tev2MonthlySongInfo>;
  trialCampaignChannel: Array<TrialCampaignChannel>;
  trialCampaignChannelUrl: Array<TrialCampaignChannelUrl>;
  trialModeLeadsNotification: Array<Wf_TrialModeLeads_Notification>;
  wf_adminFirst_a?: Maybe<Wf_AdminFirst_AConnection>;
  wf_adminFirst_ab?: Maybe<Wf_AdminFirst_AbConnection>;
  wf_adminFirst_afNotification?: Maybe<Wf_AdminFirst_AfNotificationConnection>;
  wf_adminFirst_afNotificationMember?: Maybe<Wf_AdminFirst_AfNotificationMemberConnection>;
  wf_adminFirst_afNotificationServiceType?: Maybe<Wf_AdminFirst_AfNotificationServiceTypeConnection>;
  wf_adminFirst_afProgram?: Maybe<Wf_AdminFirst_AfProgramConnection>;
  wf_adminFirst_afService?: Maybe<Wf_AdminFirst_AfServiceConnection>;
  wf_adminFirst_afUser?: Maybe<Wf_AdminFirst_AfUserConnection>;
  wf_adminFirst_afUserMarket?: Maybe<Wf_AdminFirst_AfUserMarketConnection>;
  wf_adminFirst_afUserProgram?: Maybe<Wf_AdminFirst_AfUserProgramConnection>;
  wf_adminFirst_afUserService?: Maybe<Wf_AdminFirst_AfUserServiceConnection>;
  wf_adminFirst_graphqlAccess?: Maybe<Wf_AdminFirst_GraphqlAccessConnection>;
  wf_adminFirst_graphqlSchema?: Maybe<Wf_AdminFirst_GraphqlSchemaConnection>;
  wf_adminFirst_teamSetting?: Maybe<Wf_AdminFirst_TeamSettingConnection>;
  wf_adminFirst_tempFaceCall?: Maybe<Wf_AdminFirst_TempFaceCallConnection>;
  wf_adminFirst_tepsDailyHour?: Maybe<Wf_AdminFirst_TepsDailyHourConnection>;
  wf_adminFirst_tepsDailyHourLocationType?: Maybe<Wf_AdminFirst_TepsDailyHourLocationTypeConnection>;
  wf_adminFirst_tepsDailyHourType?: Maybe<Wf_AdminFirst_TepsDailyHourTypeConnection>;
  wf_adminFirst_tepsProject?: Maybe<Wf_AdminFirst_TepsProjectConnection>;
  wf_adminFirst_tepsProjectAllocatedType?: Maybe<Wf_AdminFirst_TepsProjectAllocatedTypeConnection>;
  wf_adminFirst_tepsProjectType?: Maybe<Wf_AdminFirst_TepsProjectTypeConnection>;
  wf_adminFirst_tepsProjection?: Maybe<Wf_AdminFirst_TepsProjectionConnection>;
  wf_adminFirst_tepsUser?: Maybe<Wf_AdminFirst_TepsUserConnection>;
  wf_adminFirst_tepsUserProject?: Maybe<Wf_AdminFirst_TepsUserProjectConnection>;
  wf_trialModeLeads_autoMail?: Maybe<Wf_TrialModeLeads_AutoMailConnection>;
  wf_trialModeLeads_callToAction?: Maybe<Wf_TrialModeLeads_CallToActionConnection>;
  wf_trialModeLeads_callToActionContent?: Maybe<Wf_TrialModeLeads_CallToActionContentConnection>;
  wf_trialModeLeads_callToActionExperienceType?: Maybe<Wf_TrialModeLeads_CallToActionExperienceTypeConnection>;
  wf_trialModeLeads_designImage?: Maybe<Wf_TrialModeLeads_DesignImageConnection>;
  wf_trialModeLeads_featuredEvent?: Maybe<Wf_TrialModeLeads_FeaturedEventConnection>;
  wf_trialModeLeads_graphqlAccess?: Maybe<Wf_TrialModeLeads_GraphqlAccessConnection>;
  wf_trialModeLeads_graphqlSchema?: Maybe<Wf_TrialModeLeads_GraphqlSchemaConnection>;
  wf_trialModeLeads_installation?: Maybe<Wf_TrialModeLeads_InstallationConnection>;
  wf_trialModeLeads_installationLog?: Maybe<Wf_TrialModeLeads_InstallationLogConnection>;
  wf_trialModeLeads_journeyCard?: Maybe<Wf_TrialModeLeads_JourneyCardConnection>;
  wf_trialModeLeads_journeyExperienceType?: Maybe<Wf_TrialModeLeads_JourneyExperienceTypeConnection>;
  wf_trialModeLeads_notification?: Maybe<Wf_TrialModeLeads_NotificationConnection>;
  wf_trialModeLeads_notificationMember?: Maybe<Wf_TrialModeLeads_NotificationMemberConnection>;
  wf_trialModeLeads_notificationToSegmentation?: Maybe<Wf_TrialModeLeads_NotificationToSegmentationConnection>;
  wf_trialModeLeads_notificationType?: Maybe<Wf_TrialModeLeads_NotificationTypeConnection>;
  wf_trialModeLeads_parentTip?: Maybe<Wf_TrialModeLeads_ParentTipConnection>;
  wf_trialModeLeads_productCategory?: Maybe<Wf_TrialModeLeads_ProductCategoryConnection>;
  wf_trialModeLeads_productSong?: Maybe<Wf_TrialModeLeads_ProductSongConnection>;
  wf_trialModeLeads_productSongAndMarket?: Maybe<Wf_TrialModeLeads_ProductSongAndMarketConnection>;
  wf_trialModeLeads_productVideo?: Maybe<Wf_TrialModeLeads_ProductVideoConnection>;
  wf_trialModeLeads_productVideoAndMarket?: Maybe<Wf_TrialModeLeads_ProductVideoAndMarketConnection>;
  wf_trialModeLeads_regularEvent?: Maybe<Wf_TrialModeLeads_RegularEventConnection>;
  wf_trialModeLeads_tip?: Maybe<Wf_TrialModeLeads_TipConnection>;
  wf_trialModeLeads_trialCampaignUrl?: Maybe<Wf_TrialModeLeads_TrialCampaignUrlConnection>;
  wf_trialModeLeads_trialMailLog?: Maybe<Wf_TrialModeLeads_TrialMailLogConnection>;
  wf_trialModeLeads_trialMailSetting?: Maybe<Wf_TrialModeLeads_TrialMailSettingConnection>;
  wf_trialModeLeads_trialMessageSetting?: Maybe<Wf_TrialModeLeads_TrialMessageSettingConnection>;
  wf_trialModeLeads_trialOneTimeToken?: Maybe<Wf_TrialModeLeads_TrialOneTimeTokenConnection>;
  wf_trialModeLeads_trialPushType?: Maybe<Wf_TrialModeLeads_TrialPushTypeConnection>;
  wf_trialModeLeads_trialSegmentation?: Maybe<Wf_TrialModeLeads_TrialSegmentationConnection>;
  wf_trialModeLeads_trialSetting?: Maybe<Wf_TrialModeLeads_TrialSettingConnection>;
  wf_trialModeLeads_trialToken?: Maybe<Wf_TrialModeLeads_TrialTokenConnection>;
  wf_trialModeLeads_trialTrackingLog?: Maybe<Wf_TrialModeLeads_TrialTrackingLogConnection>;
  wf_trialModeLeads_trialUser?: Maybe<Wf_TrialModeLeads_TrialUserConnection>;
  wf_trialModeLeads_trialUserMessageLog?: Maybe<Wf_TrialModeLeads_TrialUserMessageLogConnection>;
  wf_trialModeLeads_trialUserNotification?: Maybe<Wf_TrialModeLeads_TrialUserNotificationConnection>;
  wf_trialModeLeads_trialUserSegmentationLog?: Maybe<Wf_TrialModeLeads_TrialUserSegmentationLogConnection>;
};


export type QueryAfChildGraduationApplicationArgs = {
  filter?: InputMaybe<Club_WorldFamilyClub_ChildGraduationApplicationFilterInput>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_ChildGraduationApplicationSortInput>>;
};


export type QueryAfFaceCallPerformerArgs = {
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallPerformerFilterInput>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallPerformerSortInput>>;
};


export type QueryAfFaceCallSongArgs = {
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongSortInput>>;
};


export type QueryAfGraduationApplicationArgs = {
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationFilterInput>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationSortInput>>;
};


export type QueryAfGraduationChildArgs = {
  filter?: InputMaybe<AfGraduationChildFilterInput>;
  graduationYear: Scalars['Int']['input'];
  isSubmittedFromApp: Scalars['Boolean']['input'];
  market?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<AfGraduationChildSortInput>>;
  seq?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAfMemberArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AfMemberFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  market: Market;
  order?: InputMaybe<Array<AfMemberSortInput>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAfTeSongArgs = {
  filter?: InputMaybe<Club_WorldFamilyClub_TeSongFilterInput>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TeSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppAuthArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppAuthFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppAuthSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppBannerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppBannerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppBannerSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppDigitalMagazineArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppDigitalMagazineFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppDigitalMagazineSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppMemberNotificationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppMemberNotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppMemberNotificationSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppNotificationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppNotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppNotificationMemberArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppNotificationMemberFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationMemberSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppNotificationTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppNotificationTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppNotificationTypeMarketArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppNotificationTypeMarketFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppNotificationTypeMarketSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppRegionEmergencyArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppRegionEmergencyFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppRegionEmergencySortInput>>;
};


export type QueryClub_WorldFamilyClub_AppReleaseArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppReleaseFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppReleaseSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppServiceRegionFestivalImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppServiceRegionFestivalImageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionFestivalImageSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppServiceRegionImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppServiceRegionImageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppServiceRegionImageSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppSurveyArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppSurveyFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveySortInput>>;
};


export type QueryClub_WorldFamilyClub_AppSurveyMemberArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppSurveyMemberFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyMemberSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppSurveyQaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppSurveyQaFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyQaSortInput>>;
};


export type QueryClub_WorldFamilyClub_AppSurveyReminderArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AppSurveyReminderFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AppSurveyReminderSortInput>>;
};


export type QueryClub_WorldFamilyClub_AzureFunctionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_AzureFunctionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_AzureFunctionSortInput>>;
};


export type QueryClub_WorldFamilyClub_BroadcastPromotionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_BroadcastPromotionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_BroadcastPromotionSortInput>>;
};


export type QueryClub_WorldFamilyClub_ChildGraduationApplicationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_ChildGraduationApplicationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_ChildGraduationApplicationSortInput>>;
};


export type QueryClub_WorldFamilyClub_DweProductPackageArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_DweProductPackageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_DweProductPackageSortInput>>;
};


export type QueryClub_WorldFamilyClub_DweProductSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_DweProductSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_DweProductSongToolArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_DweProductSongToolFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_DweProductSongToolSortInput>>;
};


export type QueryClub_WorldFamilyClub_DweProductToolArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_DweProductToolFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_DweProductToolSortInput>>;
};


export type QueryClub_WorldFamilyClub_EventBannerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_EventBannerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerSortInput>>;
};


export type QueryClub_WorldFamilyClub_EventBannerRegionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_EventBannerRegionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_EventBannerRegionSortInput>>;
};


export type QueryClub_WorldFamilyClub_EventCheckInAuthArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_EventCheckInAuthFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_EventCheckInAuthSortInput>>;
};


export type QueryClub_WorldFamilyClub_EventNotificationLogArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_EventNotificationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_EventNotificationLogSortInput>>;
};


export type QueryClub_WorldFamilyClub_EventSeatArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_EventSeatFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_EventSeatSortInput>>;
};


export type QueryClub_WorldFamilyClub_EventTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_EventTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_EventTypeSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallAndTelephoneEnglishArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallAndTelephoneEnglishSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallForTrialmodeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallForTrialmodeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallForTrialmodeSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallHistArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallHistFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallHistCountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallHistCountFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallHistCountSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallMonthlyRecommendArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallMonthlyRecommendFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallMonthlyRecommendSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallPerformerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallPerformerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallPerformerSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallSeasonHolidayArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallSeasonHolidayFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSeasonHolidaySortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallSongAndPerformerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallSongAndPerformerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongAndPerformerSortInput>>;
};


export type QueryClub_WorldFamilyClub_FaceCallSongPublicArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_FaceCallSongPublicFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_FaceCallSongPublicSortInput>>;
};


export type QueryClub_WorldFamilyClub_GoGoLiveDesktopQrcodeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveDesktopQrcodeSortInput>>;
};


export type QueryClub_WorldFamilyClub_GoGoLiveOpenDateArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GoGoLiveOpenDateFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLiveOpenDateSortInput>>;
};


export type QueryClub_WorldFamilyClub_GoGoLivePromotionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GoGoLivePromotionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GoGoLivePromotionSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationApplicationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationApplicationEvaluationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationEvaluationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationEvaluationSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationApplicationSubTaskArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationSubTaskFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationSubTaskSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationApplicationTaskArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationApplicationTaskFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationApplicationTaskSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationChildArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationChildFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationChildSettingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationChildSettingFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationChildSettingSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationEvaluationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationEvaluationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationEvaluationSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationNotificationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationNotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationNotificationSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationSubApplicationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationSubApplicationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubApplicationSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationSubTaskMarketDescriptionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationSubTaskMarketDescriptionSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraduationTaskMarketDescriptionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraduationTaskMarketDescriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraduationTaskMarketDescriptionSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraphqlAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraphqlAccessFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlAccessSortInput>>;
};


export type QueryClub_WorldFamilyClub_GraphqlSchemaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_GraphqlSchemaFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_GraphqlSchemaSortInput>>;
};


export type QueryClub_WorldFamilyClub_MemberMessageSettingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_MemberMessageSettingFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_MemberMessageSettingSortInput>>;
};


export type QueryClub_WorldFamilyClub_MyFavoriteFaceCallArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSortInput>>;
};


export type QueryClub_WorldFamilyClub_MyFavoriteFaceCallSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_MyFavoriteFaceCallSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_MyFavoriteFaceCallSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_NotificationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_NotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_NotificationSortInput>>;
};


export type QueryClub_WorldFamilyClub_NotificationIconArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_NotificationIconFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_NotificationIconSortInput>>;
};


export type QueryClub_WorldFamilyClub_OneTimeTokenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_OneTimeTokenFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_OneTimeTokenSortInput>>;
};


export type QueryClub_WorldFamilyClub_OnlineEventLogArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_OnlineEventLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_OnlineEventLogSortInput>>;
};


export type QueryClub_WorldFamilyClub_OnlineShowArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_OnlineShowFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_OnlineShowSortInput>>;
};


export type QueryClub_WorldFamilyClub_ParameterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_ParameterFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_ParameterSortInput>>;
};


export type QueryClub_WorldFamilyClub_RegionBannerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_RegionBannerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_RegionBannerSortInput>>;
};


export type QueryClub_WorldFamilyClub_SearchKeywordArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_SearchKeywordFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_SearchKeywordSortInput>>;
};


export type QueryClub_WorldFamilyClub_ServiceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_ServiceFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_ServiceSortInput>>;
};


export type QueryClub_WorldFamilyClub_ServiceBannerDefaultArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_ServiceBannerDefaultFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerDefaultSortInput>>;
};


export type QueryClub_WorldFamilyClub_ServiceBannerScheduledArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_ServiceBannerScheduledFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_ServiceBannerScheduledSortInput>>;
};


export type QueryClub_WorldFamilyClub_ServiceSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_ServiceSearchFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_ServiceSearchSortInput>>;
};


export type QueryClub_WorldFamilyClub_SmsCertificationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_SmsCertificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_SmsCertificationSortInput>>;
};


export type QueryClub_WorldFamilyClub_SmsVerificationCodeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_SmsVerificationCodeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_SmsVerificationCodeSortInput>>;
};


export type QueryClub_WorldFamilyClub_SurveyTypeFormArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_SurveyTypeFormFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormSortInput>>;
};


export type QueryClub_WorldFamilyClub_SurveyTypeFormListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_SurveyTypeFormListFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_SurveyTypeFormListSortInput>>;
};


export type QueryClub_WorldFamilyClub_TeSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TeSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TeSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_Temp20240408GraduationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_Temp20240408GraduationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408GraduationSortInput>>;
};


export type QueryClub_WorldFamilyClub_Temp20240408Graduation1Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_Temp20240408Graduation1FilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_Temp20240408Graduation1SortInput>>;
};


export type QueryClub_WorldFamilyClub_TempFaceCallArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempFaceCallFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempFaceCallHkArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempFaceCallHkFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallHkSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempFaceCallKrArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempFaceCallKrFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempFaceCallKrSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempHkAppMyFavoriteArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempHkAppMyFavoriteFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempHkAppMyFavoriteSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempHkClubappsFacecallCrewArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallCrewFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallCrewSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempHkClubappsFacecallHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallHistoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallHistorySortInput>>;
};


export type QueryClub_WorldFamilyClub_TempHkClubappsFacecallSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempHkClubappsFacecallSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempHkClubappsFacecallSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempHkDmemChildArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempHkDmemChildFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempHkDmemChildSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempHkTeSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempHkTeSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTeSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempHkTematerialArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempHkTematerialFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempHkTematerialSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempKrFaceCallHistArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempKrFaceCallHistFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFaceCallHistSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempKrFcCrewMArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempKrFcCrewMFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcCrewMSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempKrFcsongCrewArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempKrFcsongCrewFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempKrFcsongCrewSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempKrMyFavoriteArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempKrMyFavoriteFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempKrMyFavoriteSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempKrTeSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempKrTeSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempKrTeSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempTwCambridgeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempTwCambridgeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempTwCambridgeSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempTwFaceCallHistArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempTwFaceCallHistFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFaceCallHistSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempTwFcCrewMArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempTwFcCrewMFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcCrewMSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempTwFcsongCrewArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempTwFcsongCrewFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongCrewSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempTwFcsongMonthlyRecommendArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempTwFcsongMonthlyRecommendSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempTwMyFavoriteArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempTwMyFavoriteFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempTwMyFavoriteSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempTwTeSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempTwTeSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempTwTeSongSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempWfcGraCArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempWfcGraCFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCSortInput>>;
};


export type QueryClub_WorldFamilyClub_TempWfcGraCOrganizedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TempWfcGraCOrganizedFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TempWfcGraCOrganizedSortInput>>;
};


export type QueryClub_WorldFamilyClub_TestUserAccountArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TestUserAccountFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TestUserAccountSortInput>>;
};


export type QueryClub_WorldFamilyClub_TestWaterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TestWaterFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterSortInput>>;
};


export type QueryClub_WorldFamilyClub_TestWaterSubArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_TestWaterSubFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_TestWaterSubSortInput>>;
};


export type QueryClub_WorldFamilyClub_Tev2MonthlySongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_Tev2MonthlySongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_Tev2MonthlySongSortInput>>;
};


export type QueryClub_WorldFamilyClub_UserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_UserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_UserSortInput>>;
};


export type QueryClub_WorldFamilyClub_VideoForTrialmodeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Club_WorldFamilyClub_VideoForTrialmodeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Club_WorldFamilyClub_VideoForTrialmodeSortInput>>;
};


export type QueryDefaultServiceBannerListArgs = {
  filter?: InputMaybe<DefaultServiceBannerInfoFilterInput>;
  order?: InputMaybe<Array<DefaultServiceBannerInfoSortInput>>;
};


export type QueryMarketListOfDefaultBannerArgs = {
  filter?: InputMaybe<BannerMarketInfoFilterInput>;
  order?: InputMaybe<Array<BannerMarketInfoSortInput>>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QuerySales188NotificationArgs = {
  filter?: InputMaybe<Sales188NotificationFilterInput>;
  order?: InputMaybe<Array<Sales188NotificationSortInput>>;
};


export type QuerySales188ReleaseArgs = {
  filter?: InputMaybe<Sales188ReleaseFilterInput>;
  order?: InputMaybe<Array<Sales188ReleaseSortInput>>;
};


export type QueryScheduledServiceBannerListArgs = {
  filter?: InputMaybe<ScheduledServiceBannerInfoFilterInput>;
  order?: InputMaybe<Array<ScheduledServiceBannerInfoSortInput>>;
};


export type QueryServiceListOfDefaultBannerArgs = {
  filter?: InputMaybe<BannerServiceInfoFilterInput>;
  order?: InputMaybe<Array<BannerServiceInfoSortInput>>;
};


export type QueryTev2MonthlySongInfoArgs = {
  filter?: InputMaybe<Tev2MonthlySongInfoFilterInput>;
  order?: InputMaybe<Array<Tev2MonthlySongInfoSortInput>>;
  seq: Scalars['Int']['input'];
};


export type QueryTev2MonthlySongListArgs = {
  filter?: InputMaybe<Tev2MonthlySongInfoFilterInput>;
  order?: InputMaybe<Array<Tev2MonthlySongInfoSortInput>>;
};


export type QueryTrialCampaignChannelArgs = {
  filter?: InputMaybe<TrialCampaignChannelFilterInput>;
  order?: InputMaybe<Array<TrialCampaignChannelSortInput>>;
};


export type QueryTrialCampaignChannelUrlArgs = {
  channel: Scalars['String']['input'];
  device: Scalars['String']['input'];
  filter?: InputMaybe<TrialCampaignChannelUrlFilterInput>;
  market: Scalars['String']['input'];
  order?: InputMaybe<Array<TrialCampaignChannelUrlSortInput>>;
};


export type QueryTrialModeLeadsNotificationArgs = {
  filter?: InputMaybe<Wf_TrialModeLeads_NotificationFilterInput>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_NotificationSortInput>>;
};


export type QueryWf_AdminFirst_AArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_ASortInput>>;
};


export type QueryWf_AdminFirst_AbArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AbFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AbSortInput>>;
};


export type QueryWf_AdminFirst_AfNotificationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfNotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfNotificationSortInput>>;
};


export type QueryWf_AdminFirst_AfNotificationMemberArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfNotificationMemberFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfNotificationMemberSortInput>>;
};


export type QueryWf_AdminFirst_AfNotificationServiceTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfNotificationServiceTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfNotificationServiceTypeSortInput>>;
};


export type QueryWf_AdminFirst_AfProgramArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfProgramFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfProgramSortInput>>;
};


export type QueryWf_AdminFirst_AfServiceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfServiceFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfServiceSortInput>>;
};


export type QueryWf_AdminFirst_AfUserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfUserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfUserSortInput>>;
};


export type QueryWf_AdminFirst_AfUserMarketArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfUserMarketFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfUserMarketSortInput>>;
};


export type QueryWf_AdminFirst_AfUserProgramArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfUserProgramFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfUserProgramSortInput>>;
};


export type QueryWf_AdminFirst_AfUserServiceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_AfUserServiceFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_AfUserServiceSortInput>>;
};


export type QueryWf_AdminFirst_GraphqlAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_GraphqlAccessFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_GraphqlAccessSortInput>>;
};


export type QueryWf_AdminFirst_GraphqlSchemaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_GraphqlSchemaFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_GraphqlSchemaSortInput>>;
};


export type QueryWf_AdminFirst_TeamSettingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TeamSettingFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TeamSettingSortInput>>;
};


export type QueryWf_AdminFirst_TempFaceCallArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TempFaceCallFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TempFaceCallSortInput>>;
};


export type QueryWf_AdminFirst_TepsDailyHourArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsDailyHourFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourSortInput>>;
};


export type QueryWf_AdminFirst_TepsDailyHourLocationTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsDailyHourLocationTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourLocationTypeSortInput>>;
};


export type QueryWf_AdminFirst_TepsDailyHourTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsDailyHourTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourTypeSortInput>>;
};


export type QueryWf_AdminFirst_TepsProjectArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsProjectFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsProjectSortInput>>;
};


export type QueryWf_AdminFirst_TepsProjectAllocatedTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsProjectAllocatedTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsProjectAllocatedTypeSortInput>>;
};


export type QueryWf_AdminFirst_TepsProjectTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsProjectTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsProjectTypeSortInput>>;
};


export type QueryWf_AdminFirst_TepsProjectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsProjectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsProjectionSortInput>>;
};


export type QueryWf_AdminFirst_TepsUserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsUserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsUserSortInput>>;
};


export type QueryWf_AdminFirst_TepsUserProjectArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_AdminFirst_TepsUserProjectFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_AdminFirst_TepsUserProjectSortInput>>;
};


export type QueryWf_TrialModeLeads_AutoMailArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_AutoMailFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_AutoMailSortInput>>;
};


export type QueryWf_TrialModeLeads_CallToActionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_CallToActionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionSortInput>>;
};


export type QueryWf_TrialModeLeads_CallToActionContentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_CallToActionContentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionContentSortInput>>;
};


export type QueryWf_TrialModeLeads_CallToActionExperienceTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_CallToActionExperienceTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionExperienceTypeSortInput>>;
};


export type QueryWf_TrialModeLeads_DesignImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_DesignImageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_DesignImageSortInput>>;
};


export type QueryWf_TrialModeLeads_FeaturedEventArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_FeaturedEventFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_FeaturedEventSortInput>>;
};


export type QueryWf_TrialModeLeads_GraphqlAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_GraphqlAccessFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlAccessSortInput>>;
};


export type QueryWf_TrialModeLeads_GraphqlSchemaArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_GraphqlSchemaFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlSchemaSortInput>>;
};


export type QueryWf_TrialModeLeads_InstallationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_InstallationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_InstallationSortInput>>;
};


export type QueryWf_TrialModeLeads_InstallationLogArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_InstallationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_InstallationLogSortInput>>;
};


export type QueryWf_TrialModeLeads_JourneyCardArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_JourneyCardFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_JourneyCardSortInput>>;
};


export type QueryWf_TrialModeLeads_JourneyExperienceTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_JourneyExperienceTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_JourneyExperienceTypeSortInput>>;
};


export type QueryWf_TrialModeLeads_NotificationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_NotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_NotificationSortInput>>;
};


export type QueryWf_TrialModeLeads_NotificationMemberArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_NotificationMemberFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_NotificationMemberSortInput>>;
};


export type QueryWf_TrialModeLeads_NotificationToSegmentationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_NotificationToSegmentationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_NotificationToSegmentationSortInput>>;
};


export type QueryWf_TrialModeLeads_NotificationTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_NotificationTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_NotificationTypeSortInput>>;
};


export type QueryWf_TrialModeLeads_ParentTipArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_ParentTipFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_ParentTipSortInput>>;
};


export type QueryWf_TrialModeLeads_ProductCategoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_ProductCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_ProductCategorySortInput>>;
};


export type QueryWf_TrialModeLeads_ProductSongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_ProductSongFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongSortInput>>;
};


export type QueryWf_TrialModeLeads_ProductSongAndMarketArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_ProductSongAndMarketFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongAndMarketSortInput>>;
};


export type QueryWf_TrialModeLeads_ProductVideoArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_ProductVideoFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoSortInput>>;
};


export type QueryWf_TrialModeLeads_ProductVideoAndMarketArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_ProductVideoAndMarketFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoAndMarketSortInput>>;
};


export type QueryWf_TrialModeLeads_RegularEventArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_RegularEventFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_RegularEventSortInput>>;
};


export type QueryWf_TrialModeLeads_TipArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TipFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TipSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialCampaignUrlArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialCampaignUrlFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialCampaignUrlSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialMailLogArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialMailLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailLogSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialMailSettingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialMailSettingFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailSettingSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialMessageSettingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialMessageSettingFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialMessageSettingSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialOneTimeTokenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialOneTimeTokenFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialOneTimeTokenSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialPushTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialPushTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialPushTypeSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialSegmentationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialSegmentationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialSegmentationSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialSettingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialSettingFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialSettingSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialTokenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialTokenFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialTokenSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialTrackingLogArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialTrackingLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialTrackingLogSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialUserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialUserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialUserMessageLogArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialUserMessageLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserMessageLogSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialUserNotificationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialUserNotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserNotificationSortInput>>;
};


export type QueryWf_TrialModeLeads_TrialUserSegmentationLogArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Wf_TrialModeLeads_TrialUserSegmentationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLogSortInput>>;
};

export type Sales188Notification = {
  __typename?: 'Sales188Notification';
  createdTime: Scalars['DateTime']['output'];
  creator?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  jobStatus?: Maybe<Scalars['String']['output']>;
  market?: Maybe<Scalars['String']['output']>;
  mutationOperation?: Maybe<Scalars['String']['output']>;
  notificationId?: Maybe<Scalars['Int']['output']>;
  removeNotificationId?: Maybe<Scalars['Int']['output']>;
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  sendingDepartment?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  templateMessage?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Sales188NotificationFilterInput = {
  and?: InputMaybe<Array<Sales188NotificationFilterInput>>;
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  creator?: InputMaybe<StringOperationFilterInput>;
  error?: InputMaybe<StringOperationFilterInput>;
  jobStatus?: InputMaybe<StringOperationFilterInput>;
  market?: InputMaybe<StringOperationFilterInput>;
  mutationOperation?: InputMaybe<StringOperationFilterInput>;
  notificationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Sales188NotificationFilterInput>>;
  removeNotificationId?: InputMaybe<IntOperationFilterInput>;
  scheduleTime?: InputMaybe<DateTimeOperationFilterInput>;
  sendingDepartment?: InputMaybe<StringOperationFilterInput>;
  target?: InputMaybe<StringOperationFilterInput>;
  templateMessage?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type Sales188NotificationInput = {
  createdTime: Scalars['DateTime']['input'];
  creator?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  jobStatus?: InputMaybe<Scalars['String']['input']>;
  market?: InputMaybe<Scalars['String']['input']>;
  mutationOperation?: InputMaybe<Scalars['String']['input']>;
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  removeNotificationId?: InputMaybe<Scalars['Int']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  sendingDepartment?: InputMaybe<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['String']['input']>;
  templateMessage?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Sales188NotificationSortInput = {
  createdTime?: InputMaybe<SortEnumType>;
  creator?: InputMaybe<SortEnumType>;
  error?: InputMaybe<SortEnumType>;
  jobStatus?: InputMaybe<SortEnumType>;
  market?: InputMaybe<SortEnumType>;
  mutationOperation?: InputMaybe<SortEnumType>;
  notificationId?: InputMaybe<SortEnumType>;
  removeNotificationId?: InputMaybe<SortEnumType>;
  scheduleTime?: InputMaybe<SortEnumType>;
  sendingDepartment?: InputMaybe<SortEnumType>;
  target?: InputMaybe<SortEnumType>;
  templateMessage?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export type Sales188Release = {
  __typename?: 'Sales188Release';
  appVersion?: Maybe<Scalars['String']['output']>;
  appstore?: Maybe<Scalars['String']['output']>;
  osVersion?: Maybe<Scalars['String']['output']>;
  releaseTime: Scalars['DateTime']['output'];
  seq: Scalars['Short']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type Sales188ReleaseFilterInput = {
  and?: InputMaybe<Array<Sales188ReleaseFilterInput>>;
  appVersion?: InputMaybe<StringOperationFilterInput>;
  appstore?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Sales188ReleaseFilterInput>>;
  osVersion?: InputMaybe<StringOperationFilterInput>;
  releaseTime?: InputMaybe<DateTimeOperationFilterInput>;
  seq?: InputMaybe<ShortOperationFilterInput>;
  version?: InputMaybe<StringOperationFilterInput>;
};

export type Sales188ReleaseSortInput = {
  appVersion?: InputMaybe<SortEnumType>;
  appstore?: InputMaybe<SortEnumType>;
  osVersion?: InputMaybe<SortEnumType>;
  releaseTime?: InputMaybe<SortEnumType>;
  seq?: InputMaybe<SortEnumType>;
  version?: InputMaybe<SortEnumType>;
};

export type ScheduledServiceBannerInfo = {
  __typename?: 'ScheduledServiceBannerInfo';
  image: Scalars['String']['output'];
  markets: Array<Scalars['String']['output']>;
  /** yyyy-MM-dd */
  publishDate?: Maybe<Scalars['String']['output']>;
  seqs: Array<Scalars['Int']['output']>;
  service: Scalars['String']['output'];
  /** In use / Not in use */
  status: Scalars['String']['output'];
  /** yyyy-MM-dd */
  stopDate?: Maybe<Scalars['String']['output']>;
};

export type ScheduledServiceBannerInfoFilterInput = {
  and?: InputMaybe<Array<ScheduledServiceBannerInfoFilterInput>>;
  image?: InputMaybe<StringOperationFilterInput>;
  markets?: InputMaybe<ListStringOperationFilterInput>;
  or?: InputMaybe<Array<ScheduledServiceBannerInfoFilterInput>>;
  /** yyyy-MM-dd */
  publishDate?: InputMaybe<StringOperationFilterInput>;
  seqs?: InputMaybe<ListIntOperationFilterInput>;
  service?: InputMaybe<StringOperationFilterInput>;
  /** In use / Not in use */
  status?: InputMaybe<StringOperationFilterInput>;
  /** yyyy-MM-dd */
  stopDate?: InputMaybe<StringOperationFilterInput>;
};

export type ScheduledServiceBannerInfoSortInput = {
  image?: InputMaybe<SortEnumType>;
  /** yyyy-MM-dd */
  publishDate?: InputMaybe<SortEnumType>;
  service?: InputMaybe<SortEnumType>;
  /** In use / Not in use */
  status?: InputMaybe<SortEnumType>;
  /** yyyy-MM-dd */
  stopDate?: InputMaybe<SortEnumType>;
};

export type ScheduledServiceBannerParameterInput = {
  image: Scalars['String']['input'];
  markets: Array<Scalars['String']['input']>;
  /** yyyy-MM-dd */
  publishDate?: InputMaybe<Scalars['String']['input']>;
  seqs?: InputMaybe<Array<Scalars['Int']['input']>>;
  service: Scalars['String']['input'];
  /** yyyy-MM-dd */
  stopDate?: InputMaybe<Scalars['String']['input']>;
};

export type ShortOperationFilterInput = {
  eq?: InputMaybe<Scalars['Short']['input']>;
  gt?: InputMaybe<Scalars['Short']['input']>;
  gte?: InputMaybe<Scalars['Short']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Short']['input']>>>;
  lt?: InputMaybe<Scalars['Short']['input']>;
  lte?: InputMaybe<Scalars['Short']['input']>;
  neq?: InputMaybe<Scalars['Short']['input']>;
  ngt?: InputMaybe<Scalars['Short']['input']>;
  ngte?: InputMaybe<Scalars['Short']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Short']['input']>>>;
  nlt?: InputMaybe<Scalars['Short']['input']>;
  nlte?: InputMaybe<Scalars['Short']['input']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Tev2MonthlySongInfo = {
  __typename?: 'Tev2MonthlySongInfo';
  introductionBanner: Scalars['String']['output'];
  month: Scalars['Int']['output'];
  seq?: Maybe<Scalars['Int']['output']>;
  tev2Banner: Scalars['String']['output'];
  tev2SongId: Scalars['String']['output'];
  vimeoId: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type Tev2MonthlySongInfoFilterInput = {
  and?: InputMaybe<Array<Tev2MonthlySongInfoFilterInput>>;
  introductionBanner?: InputMaybe<StringOperationFilterInput>;
  month?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Tev2MonthlySongInfoFilterInput>>;
  seq?: InputMaybe<IntOperationFilterInput>;
  tev2Banner?: InputMaybe<StringOperationFilterInput>;
  tev2SongId?: InputMaybe<StringOperationFilterInput>;
  vimeoId?: InputMaybe<StringOperationFilterInput>;
  year?: InputMaybe<IntOperationFilterInput>;
};

export type Tev2MonthlySongInfoInput = {
  introductionBanner: Scalars['String']['input'];
  month: Scalars['Int']['input'];
  seq?: InputMaybe<Scalars['Int']['input']>;
  tev2Banner: Scalars['String']['input'];
  tev2SongId: Scalars['String']['input'];
  vimeoId: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type Tev2MonthlySongInfoSortInput = {
  introductionBanner?: InputMaybe<SortEnumType>;
  month?: InputMaybe<SortEnumType>;
  seq?: InputMaybe<SortEnumType>;
  tev2Banner?: InputMaybe<SortEnumType>;
  tev2SongId?: InputMaybe<SortEnumType>;
  vimeoId?: InputMaybe<SortEnumType>;
  year?: InputMaybe<SortEnumType>;
};

export type TrialCampaignChannel = {
  __typename?: 'TrialCampaignChannel';
  channel?: Maybe<Scalars['String']['output']>;
};

export type TrialCampaignChannelFilterInput = {
  and?: InputMaybe<Array<TrialCampaignChannelFilterInput>>;
  channel?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TrialCampaignChannelFilterInput>>;
};

export type TrialCampaignChannelSortInput = {
  channel?: InputMaybe<SortEnumType>;
};

export type TrialCampaignChannelUrl = {
  __typename?: 'TrialCampaignChannelUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type TrialCampaignChannelUrlFilterInput = {
  and?: InputMaybe<Array<TrialCampaignChannelUrlFilterInput>>;
  or?: InputMaybe<Array<TrialCampaignChannelUrlFilterInput>>;
  url?: InputMaybe<StringOperationFilterInput>;
};

export type TrialCampaignChannelUrlSortInput = {
  url?: InputMaybe<SortEnumType>;
};

export type TrialModeMemberInput = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type TrialModeNotificationInput = {
  actionButton?: InputMaybe<Scalars['String']['input']>;
  buttonLinksTo?: InputMaybe<Scalars['String']['input']>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  jobStatus?: InputMaybe<Scalars['String']['input']>;
  market?: InputMaybe<Scalars['String']['input']>;
  mutationOperation?: InputMaybe<Scalars['String']['input']>;
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  notificationType?: InputMaybe<TrialModeNotificationTypeInput>;
  notificationTypeId?: InputMaybe<Scalars['String']['input']>;
  removeNotificationId?: InputMaybe<Scalars['Int']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  segmentation?: InputMaybe<Scalars['String']['input']>;
  templateMessage?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TrialModeNotificationTypeInput = {
  id: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  notificationName?: InputMaybe<Scalars['String']['input']>;
};

export type UploadPayload = {
  __typename?: 'UploadPayload';
  count: Scalars['Int']['output'];
  query: Query;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

/** A */
export type Wf_AdminFirst_A = Node & {
  __typename?: 'WF_AdminFirst_A';
  /** A1 */
  a1?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] Abs */
  abs?: Maybe<Array<Maybe<Wf_AdminFirst_Ab>>>;
  /** [ID] AId */
  id: Scalars['ID']['output'];
  /** Text */
  text?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AConnection = {
  __typename?: 'WF_AdminFirst_AConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_A>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AEdge = {
  __typename?: 'WF_AdminFirst_AEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_A;
};

/** A */
export type Wf_AdminFirst_AFilterInput = {
  /** A1 */
  a1?: InputMaybe<StringOperationFilterInput>;
  /** [ID] AId */
  aId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] Abs */
  abs?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_AbFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AFilterInput>>;
  or?: InputMaybe<Array<Wf_AdminFirst_AFilterInput>>;
  /** Text */
  text?: InputMaybe<StringOperationFilterInput>;
};

/** A */
export type Wf_AdminFirst_AInput = {
  /** A1 */
  a1?: InputMaybe<Scalars['String']['input']>;
  /** [ID] AId */
  aId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] Abs */
  abs?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_AbInput>>>;
  /** Text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** A */
export type Wf_AdminFirst_ASortInput = {
  /** A1 */
  a1?: InputMaybe<SortEnumType>;
  /** [ID] AId */
  aId?: InputMaybe<SortEnumType>;
  /** Text */
  text?: InputMaybe<SortEnumType>;
};

/** Ab */
export type Wf_AdminFirst_Ab = Node & {
  __typename?: 'WF_AdminFirst_Ab';
  /** [NOT NULL] AId */
  aId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] AIdNavigation */
  aIdNavigation?: Maybe<Wf_AdminFirst_A>;
  /** [ID] AbId */
  id: Scalars['ID']['output'];
  /** Text */
  text?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AbConnection = {
  __typename?: 'WF_AdminFirst_AbConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AbEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_Ab>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AbEdge = {
  __typename?: 'WF_AdminFirst_AbEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_Ab;
};

/** Ab */
export type Wf_AdminFirst_AbFilterInput = {
  /** [NOT NULL] AId */
  aId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] AIdNavigation */
  aIdNavigation?: InputMaybe<Wf_AdminFirst_AFilterInput>;
  /** [ID] AbId */
  abId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AbFilterInput>>;
  or?: InputMaybe<Array<Wf_AdminFirst_AbFilterInput>>;
  /** Text */
  text?: InputMaybe<StringOperationFilterInput>;
};

/** Ab */
export type Wf_AdminFirst_AbInput = {
  /** [NOT NULL] AId */
  aId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] AIdNavigation */
  aIdNavigation?: InputMaybe<Wf_AdminFirst_AInput>;
  /** [ID] AbId */
  abId?: InputMaybe<Scalars['Int']['input']>;
  /** Text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Ab */
export type Wf_AdminFirst_AbSortInput = {
  /** [NOT NULL] AId */
  aId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] AIdNavigation */
  aIdNavigation?: InputMaybe<Wf_AdminFirst_ASortInput>;
  /** [ID] AbId */
  abId?: InputMaybe<SortEnumType>;
  /** Text */
  text?: InputMaybe<SortEnumType>;
};

/** AfNotification */
export type Wf_AdminFirst_AfNotification = Node & {
  __typename?: 'WF_AdminFirst_AfNotification';
  /** ActionButton */
  actionButton?: Maybe<Scalars['String']['output']>;
  /** AfNotificationServiceTypeId */
  afNotificationServiceTypeId?: Maybe<Scalars['String']['output']>;
  /** ButtonLinksTo */
  buttonLinksTo?: Maybe<Scalars['String']['output']>;
  /** ButtonText */
  buttonText?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** Error */
  error?: Maybe<Scalars['String']['output']>;
  /** [ID] AfNotificationId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** IsAllMembers */
  isAllMembers?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] IsRegular */
  isRegular?: Maybe<Scalars['Boolean']['output']>;
  /** JobStatus */
  jobStatus?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** MutationOperation */
  mutationOperation?: Maybe<Scalars['String']['output']>;
  /** RemoveAfNotificationId */
  removeAfNotificationId?: Maybe<Scalars['Int']['output']>;
  /** ScheduleTime */
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  /** TemplateMessage */
  templateMessage?: Maybe<Scalars['String']['output']>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfNotificationConnection = {
  __typename?: 'WF_AdminFirst_AfNotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfNotificationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfNotification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfNotificationEdge = {
  __typename?: 'WF_AdminFirst_AfNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfNotification;
};

/** AfNotification */
export type Wf_AdminFirst_AfNotificationFilterInput = {
  /** ActionButton */
  actionButton?: InputMaybe<StringOperationFilterInput>;
  /** [ID] AfNotificationId */
  afNotificationId?: InputMaybe<IntOperationFilterInput>;
  /** AfNotificationServiceTypeId */
  afNotificationServiceTypeId?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfNotificationFilterInput>>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<StringOperationFilterInput>;
  /** ButtonText */
  buttonText?: InputMaybe<StringOperationFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** Error */
  error?: InputMaybe<StringOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  /** IsAllMembers */
  isAllMembers?: InputMaybe<BooleanOperationFilterInput>;
  /** [NOT NULL] IsRegular */
  isRegular?: InputMaybe<BooleanOperationFilterInput>;
  /** JobStatus */
  jobStatus?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfNotificationFilterInput>>;
  /** RemoveAfNotificationId */
  removeAfNotificationId?: InputMaybe<IntOperationFilterInput>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<StringOperationFilterInput>;
  /** Title */
  title?: InputMaybe<StringOperationFilterInput>;
};

/** AfNotification */
export type Wf_AdminFirst_AfNotificationInput = {
  /** ActionButton */
  actionButton?: InputMaybe<Scalars['String']['input']>;
  /** [ID] AfNotificationId */
  afNotificationId?: InputMaybe<Scalars['Int']['input']>;
  /** AfNotificationServiceTypeId */
  afNotificationServiceTypeId?: InputMaybe<Scalars['String']['input']>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<Scalars['String']['input']>;
  /** ButtonText */
  buttonText?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** Error */
  error?: InputMaybe<Scalars['String']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** IsAllMembers */
  isAllMembers?: InputMaybe<Scalars['Boolean']['input']>;
  /** [NOT NULL] IsRegular */
  isRegular?: InputMaybe<Scalars['Boolean']['input']>;
  /** JobStatus */
  jobStatus?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<Scalars['String']['input']>;
  /** RemoveAfNotificationId */
  removeAfNotificationId?: InputMaybe<Scalars['Int']['input']>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<Scalars['String']['input']>;
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** AfNotificationMember */
export type Wf_AdminFirst_AfNotificationMember = Node & {
  __typename?: 'WF_AdminFirst_AfNotificationMember';
  /** [NOT NULL] AfNotificationId */
  afNotificationId?: Maybe<Scalars['Int']['output']>;
  /** [ID] AfNotificationMemberId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] MemberNumber */
  memberNumber?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfNotificationMemberConnection = {
  __typename?: 'WF_AdminFirst_AfNotificationMemberConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfNotificationMemberEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfNotificationMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfNotificationMemberEdge = {
  __typename?: 'WF_AdminFirst_AfNotificationMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfNotificationMember;
};

/** AfNotificationMember */
export type Wf_AdminFirst_AfNotificationMemberFilterInput = {
  /** [NOT NULL] AfNotificationId */
  afNotificationId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] AfNotificationMemberId */
  afNotificationMemberId?: InputMaybe<LongOperationFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfNotificationMemberFilterInput>>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfNotificationMemberFilterInput>>;
};

/** AfNotificationMember */
export type Wf_AdminFirst_AfNotificationMemberInput = {
  /** [NOT NULL] AfNotificationId */
  afNotificationId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] AfNotificationMemberId */
  afNotificationMemberId?: InputMaybe<Scalars['Long']['input']>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
};

/** AfNotificationMember */
export type Wf_AdminFirst_AfNotificationMemberSortInput = {
  /** [NOT NULL] AfNotificationId */
  afNotificationId?: InputMaybe<SortEnumType>;
  /** [ID] AfNotificationMemberId */
  afNotificationMemberId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] MemberNumber */
  memberNumber?: InputMaybe<SortEnumType>;
};

/** AfNotificationServiceType */
export type Wf_AdminFirst_AfNotificationServiceType = Node & {
  __typename?: 'WF_AdminFirst_AfNotificationServiceType';
  /** [ID] AfNotificationServiceTypeId */
  id: Scalars['ID']['output'];
  /** IsPublic */
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  /** ServiceTypeName */
  serviceTypeName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfNotificationServiceTypeConnection = {
  __typename?: 'WF_AdminFirst_AfNotificationServiceTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfNotificationServiceTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfNotificationServiceType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfNotificationServiceTypeEdge = {
  __typename?: 'WF_AdminFirst_AfNotificationServiceTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfNotificationServiceType;
};

/** AfNotificationServiceType */
export type Wf_AdminFirst_AfNotificationServiceTypeFilterInput = {
  /** [ID] AfNotificationServiceTypeId */
  afNotificationServiceTypeId?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfNotificationServiceTypeFilterInput>>;
  /** IsPublic */
  isPublic?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfNotificationServiceTypeFilterInput>>;
  /** ServiceTypeName */
  serviceTypeName?: InputMaybe<StringOperationFilterInput>;
};

/** AfNotificationServiceType */
export type Wf_AdminFirst_AfNotificationServiceTypeInput = {
  /** [ID] AfNotificationServiceTypeId */
  afNotificationServiceTypeId?: InputMaybe<Scalars['String']['input']>;
  /** IsPublic */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** ServiceTypeName */
  serviceTypeName?: InputMaybe<Scalars['String']['input']>;
};

/** AfNotificationServiceType */
export type Wf_AdminFirst_AfNotificationServiceTypeSortInput = {
  /** [ID] AfNotificationServiceTypeId */
  afNotificationServiceTypeId?: InputMaybe<SortEnumType>;
  /** IsPublic */
  isPublic?: InputMaybe<SortEnumType>;
  /** ServiceTypeName */
  serviceTypeName?: InputMaybe<SortEnumType>;
};

/** AfNotification */
export type Wf_AdminFirst_AfNotificationSortInput = {
  /** ActionButton */
  actionButton?: InputMaybe<SortEnumType>;
  /** [ID] AfNotificationId */
  afNotificationId?: InputMaybe<SortEnumType>;
  /** AfNotificationServiceTypeId */
  afNotificationServiceTypeId?: InputMaybe<SortEnumType>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<SortEnumType>;
  /** ButtonText */
  buttonText?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** Error */
  error?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** IsAllMembers */
  isAllMembers?: InputMaybe<SortEnumType>;
  /** [NOT NULL] IsRegular */
  isRegular?: InputMaybe<SortEnumType>;
  /** JobStatus */
  jobStatus?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<SortEnumType>;
  /** RemoveAfNotificationId */
  removeAfNotificationId?: InputMaybe<SortEnumType>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<SortEnumType>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<SortEnumType>;
  /** Title */
  title?: InputMaybe<SortEnumType>;
};

/** AfProgram */
export type Wf_AdminFirst_AfProgram = Node & {
  __typename?: 'WF_AdminFirst_AfProgram';
  /** [NAVIGATION] AfService */
  afService?: Maybe<Wf_AdminFirst_AfService>;
  /** [NOT NULL] AfServiceId */
  afServiceId?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] AfUserPrograms */
  afUserPrograms?: Maybe<Array<Maybe<Wf_AdminFirst_AfUserProgram>>>;
  /** [ID] AfProgramId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] IsUnavailable */
  isUnavailable?: Maybe<Scalars['Boolean']['output']>;
  /** ProgramName */
  programName?: Maybe<Scalars['String']['output']>;
  /** RouteName */
  routeName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfProgramConnection = {
  __typename?: 'WF_AdminFirst_AfProgramConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfProgramEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfProgram>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfProgramEdge = {
  __typename?: 'WF_AdminFirst_AfProgramEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfProgram;
};

/** AfProgram */
export type Wf_AdminFirst_AfProgramFilterInput = {
  /** [ID] AfProgramId */
  afProgramId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] AfService */
  afService?: InputMaybe<Wf_AdminFirst_AfServiceFilterInput>;
  /** [NOT NULL] AfServiceId */
  afServiceId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] AfUserPrograms */
  afUserPrograms?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_AfUserProgramFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfProgramFilterInput>>;
  /** [NOT NULL] IsUnavailable */
  isUnavailable?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfProgramFilterInput>>;
  /** ProgramName */
  programName?: InputMaybe<StringOperationFilterInput>;
  /** RouteName */
  routeName?: InputMaybe<StringOperationFilterInput>;
};

/** AfProgram */
export type Wf_AdminFirst_AfProgramInput = {
  /** [ID] AfProgramId */
  afProgramId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] AfService */
  afService?: InputMaybe<Wf_AdminFirst_AfServiceInput>;
  /** [NOT NULL] AfServiceId */
  afServiceId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] AfUserPrograms */
  afUserPrograms?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_AfUserProgramInput>>>;
  /** [NOT NULL] IsUnavailable */
  isUnavailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** ProgramName */
  programName?: InputMaybe<Scalars['String']['input']>;
  /** RouteName */
  routeName?: InputMaybe<Scalars['String']['input']>;
};

/** AfProgram */
export type Wf_AdminFirst_AfProgramSortInput = {
  /** [ID] AfProgramId */
  afProgramId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] AfService */
  afService?: InputMaybe<Wf_AdminFirst_AfServiceSortInput>;
  /** [NOT NULL] AfServiceId */
  afServiceId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] IsUnavailable */
  isUnavailable?: InputMaybe<SortEnumType>;
  /** ProgramName */
  programName?: InputMaybe<SortEnumType>;
  /** RouteName */
  routeName?: InputMaybe<SortEnumType>;
};

/** AfService */
export type Wf_AdminFirst_AfService = Node & {
  __typename?: 'WF_AdminFirst_AfService';
  /** [COLLECTION] AfPrograms */
  afPrograms?: Maybe<Array<Maybe<Wf_AdminFirst_AfProgram>>>;
  /** [COLLECTION] AfUserServices */
  afUserServices?: Maybe<Array<Maybe<Wf_AdminFirst_AfUserService>>>;
  /** [ID] AfServiceId */
  id: Scalars['ID']['output'];
  /** RouteName */
  routeName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] ServiceName */
  serviceName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfServiceConnection = {
  __typename?: 'WF_AdminFirst_AfServiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfServiceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfService>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfServiceEdge = {
  __typename?: 'WF_AdminFirst_AfServiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfService;
};

/** AfService */
export type Wf_AdminFirst_AfServiceFilterInput = {
  /** [COLLECTION] AfPrograms */
  afPrograms?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_AfProgramFilterInput>;
  /** [ID] AfServiceId */
  afServiceId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] AfUserServices */
  afUserServices?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_AfUserServiceFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfServiceFilterInput>>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfServiceFilterInput>>;
  /** RouteName */
  routeName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] ServiceName */
  serviceName?: InputMaybe<StringOperationFilterInput>;
};

/** AfService */
export type Wf_AdminFirst_AfServiceInput = {
  /** [COLLECTION] AfPrograms */
  afPrograms?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_AfProgramInput>>>;
  /** [ID] AfServiceId */
  afServiceId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] AfUserServices */
  afUserServices?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_AfUserServiceInput>>>;
  /** RouteName */
  routeName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] ServiceName */
  serviceName?: InputMaybe<Scalars['String']['input']>;
};

/** AfService */
export type Wf_AdminFirst_AfServiceSortInput = {
  /** [ID] AfServiceId */
  afServiceId?: InputMaybe<SortEnumType>;
  /** RouteName */
  routeName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ServiceName */
  serviceName?: InputMaybe<SortEnumType>;
};

/** AfUser */
export type Wf_AdminFirst_AfUser = Node & {
  __typename?: 'WF_AdminFirst_AfUser';
  /** [COLLECTION] AfUserMarkets */
  afUserMarkets?: Maybe<Array<Maybe<Wf_AdminFirst_AfUserMarket>>>;
  /** [COLLECTION] AfUserPrograms */
  afUserPrograms?: Maybe<Array<Maybe<Wf_AdminFirst_AfUserProgram>>>;
  /** [COLLECTION] AfUserServices */
  afUserServices?: Maybe<Array<Maybe<Wf_AdminFirst_AfUserService>>>;
  /** AvatarUrl */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** [ID] AfUserId */
  id: Scalars['ID']['output'];
  /** IsAdministrator */
  isAdministrator?: Maybe<Scalars['Boolean']['output']>;
  /** StopDate */
  stopDate?: Maybe<Scalars['DateTime']['output']>;
  /** [NOT NULL] UserEmail */
  userEmail?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] UserName */
  userName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfUserConnection = {
  __typename?: 'WF_AdminFirst_AfUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfUserEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfUserEdge = {
  __typename?: 'WF_AdminFirst_AfUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfUser;
};

/** AfUser */
export type Wf_AdminFirst_AfUserFilterInput = {
  /** [ID] AfUserId */
  afUserId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] AfUserMarkets */
  afUserMarkets?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_AfUserMarketFilterInput>;
  /** [COLLECTION] AfUserPrograms */
  afUserPrograms?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_AfUserProgramFilterInput>;
  /** [COLLECTION] AfUserServices */
  afUserServices?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_AfUserServiceFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfUserFilterInput>>;
  /** AvatarUrl */
  avatarUrl?: InputMaybe<StringOperationFilterInput>;
  /** IsAdministrator */
  isAdministrator?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfUserFilterInput>>;
  /** StopDate */
  stopDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [NOT NULL] UserEmail */
  userEmail?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] UserName */
  userName?: InputMaybe<StringOperationFilterInput>;
};

/** AfUser */
export type Wf_AdminFirst_AfUserInput = {
  /** [ID] AfUserId */
  afUserId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] AfUserMarkets */
  afUserMarkets?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_AfUserMarketInput>>>;
  /** [COLLECTION] AfUserPrograms */
  afUserPrograms?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_AfUserProgramInput>>>;
  /** [COLLECTION] AfUserServices */
  afUserServices?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_AfUserServiceInput>>>;
  /** AvatarUrl */
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  /** IsAdministrator */
  isAdministrator?: InputMaybe<Scalars['Boolean']['input']>;
  /** StopDate */
  stopDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [NOT NULL] UserEmail */
  userEmail?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] UserName */
  userName?: InputMaybe<Scalars['String']['input']>;
};

/** AfUserMarket */
export type Wf_AdminFirst_AfUserMarket = Node & {
  __typename?: 'WF_AdminFirst_AfUserMarket';
  /** [NAVIGATION] AfUser */
  afUser?: Maybe<Wf_AdminFirst_AfUser>;
  /** [NOT NULL] AfUserId */
  afUserId?: Maybe<Scalars['Int']['output']>;
  /** [ID] AfUserMarketId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfUserMarketConnection = {
  __typename?: 'WF_AdminFirst_AfUserMarketConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfUserMarketEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfUserMarket>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfUserMarketEdge = {
  __typename?: 'WF_AdminFirst_AfUserMarketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfUserMarket;
};

/** AfUserMarket */
export type Wf_AdminFirst_AfUserMarketFilterInput = {
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserFilterInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] AfUserMarketId */
  afUserMarketId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfUserMarketFilterInput>>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfUserMarketFilterInput>>;
};

/** AfUserMarket */
export type Wf_AdminFirst_AfUserMarketInput = {
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] AfUserMarketId */
  afUserMarketId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
};

/** AfUserMarket */
export type Wf_AdminFirst_AfUserMarketSortInput = {
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserSortInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<SortEnumType>;
  /** [ID] AfUserMarketId */
  afUserMarketId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
};

/** AfUserProgram */
export type Wf_AdminFirst_AfUserProgram = Node & {
  __typename?: 'WF_AdminFirst_AfUserProgram';
  /** [NAVIGATION] AfProgram */
  afProgram?: Maybe<Wf_AdminFirst_AfProgram>;
  /** [NOT NULL] AfProgramId */
  afProgramId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] AfUser */
  afUser?: Maybe<Wf_AdminFirst_AfUser>;
  /** [NOT NULL] AfUserId */
  afUserId?: Maybe<Scalars['Int']['output']>;
  /** [ID] AfUserProgramId */
  id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfUserProgramConnection = {
  __typename?: 'WF_AdminFirst_AfUserProgramConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfUserProgramEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfUserProgram>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfUserProgramEdge = {
  __typename?: 'WF_AdminFirst_AfUserProgramEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfUserProgram;
};

/** AfUserProgram */
export type Wf_AdminFirst_AfUserProgramFilterInput = {
  /** [NAVIGATION] AfProgram */
  afProgram?: InputMaybe<Wf_AdminFirst_AfProgramFilterInput>;
  /** [NOT NULL] AfProgramId */
  afProgramId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserFilterInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] AfUserProgramId */
  afUserProgramId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfUserProgramFilterInput>>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfUserProgramFilterInput>>;
};

/** AfUserProgram */
export type Wf_AdminFirst_AfUserProgramInput = {
  /** [NAVIGATION] AfProgram */
  afProgram?: InputMaybe<Wf_AdminFirst_AfProgramInput>;
  /** [NOT NULL] AfProgramId */
  afProgramId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] AfUserProgramId */
  afUserProgramId?: InputMaybe<Scalars['Int']['input']>;
};

/** AfUserProgram */
export type Wf_AdminFirst_AfUserProgramSortInput = {
  /** [NAVIGATION] AfProgram */
  afProgram?: InputMaybe<Wf_AdminFirst_AfProgramSortInput>;
  /** [NOT NULL] AfProgramId */
  afProgramId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserSortInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<SortEnumType>;
  /** [ID] AfUserProgramId */
  afUserProgramId?: InputMaybe<SortEnumType>;
};

/** AfUserService */
export type Wf_AdminFirst_AfUserService = Node & {
  __typename?: 'WF_AdminFirst_AfUserService';
  /** [NAVIGATION] AfService */
  afService?: Maybe<Wf_AdminFirst_AfService>;
  /** [NOT NULL] AfServiceId */
  afServiceId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] AfUser */
  afUser?: Maybe<Wf_AdminFirst_AfUser>;
  /** [NOT NULL] AfUserId */
  afUserId?: Maybe<Scalars['Int']['output']>;
  /** [ID] AfUserServiceId */
  id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type Wf_AdminFirst_AfUserServiceConnection = {
  __typename?: 'WF_AdminFirst_AfUserServiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_AfUserServiceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_AfUserService>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_AfUserServiceEdge = {
  __typename?: 'WF_AdminFirst_AfUserServiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_AfUserService;
};

/** AfUserService */
export type Wf_AdminFirst_AfUserServiceFilterInput = {
  /** [NAVIGATION] AfService */
  afService?: InputMaybe<Wf_AdminFirst_AfServiceFilterInput>;
  /** [NOT NULL] AfServiceId */
  afServiceId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserFilterInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] AfUserServiceId */
  afUserServiceId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_AfUserServiceFilterInput>>;
  or?: InputMaybe<Array<Wf_AdminFirst_AfUserServiceFilterInput>>;
};

/** AfUserService */
export type Wf_AdminFirst_AfUserServiceInput = {
  /** [NAVIGATION] AfService */
  afService?: InputMaybe<Wf_AdminFirst_AfServiceInput>;
  /** [NOT NULL] AfServiceId */
  afServiceId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] AfUserServiceId */
  afUserServiceId?: InputMaybe<Scalars['Int']['input']>;
};

/** AfUserService */
export type Wf_AdminFirst_AfUserServiceSortInput = {
  /** [NAVIGATION] AfService */
  afService?: InputMaybe<Wf_AdminFirst_AfServiceSortInput>;
  /** [NOT NULL] AfServiceId */
  afServiceId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] AfUser */
  afUser?: InputMaybe<Wf_AdminFirst_AfUserSortInput>;
  /** [NOT NULL] AfUserId */
  afUserId?: InputMaybe<SortEnumType>;
  /** [ID] AfUserServiceId */
  afUserServiceId?: InputMaybe<SortEnumType>;
};

/** AfUser */
export type Wf_AdminFirst_AfUserSortInput = {
  /** [ID] AfUserId */
  afUserId?: InputMaybe<SortEnumType>;
  /** AvatarUrl */
  avatarUrl?: InputMaybe<SortEnumType>;
  /** IsAdministrator */
  isAdministrator?: InputMaybe<SortEnumType>;
  /** StopDate */
  stopDate?: InputMaybe<SortEnumType>;
  /** [NOT NULL] UserEmail */
  userEmail?: InputMaybe<SortEnumType>;
  /** [NOT NULL] UserName */
  userName?: InputMaybe<SortEnumType>;
};

/** GraphqlAccess */
export type Wf_AdminFirst_GraphqlAccess = Node & {
  __typename?: 'WF_AdminFirst_GraphqlAccess';
  /** AccessSchema */
  accessSchema?: Maybe<Scalars['String']['output']>;
  /** AccessSchemaHash */
  accessSchemaHash?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Database */
  database?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: Maybe<Wf_AdminFirst_GraphqlSchema>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: Maybe<Scalars['UUID']['output']>;
  /** [ID] GraphqlAccessId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] ProjectId */
  projectId?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Server */
  server?: Maybe<Scalars['String']['output']>;
  /** UpdateTime */
  updateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_GraphqlAccessConnection = {
  __typename?: 'WF_AdminFirst_GraphqlAccessConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_GraphqlAccessEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_GraphqlAccess>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_GraphqlAccessEdge = {
  __typename?: 'WF_AdminFirst_GraphqlAccessEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_GraphqlAccess;
};

/** GraphqlAccess */
export type Wf_AdminFirst_GraphqlAccessFilterInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<StringOperationFilterInput>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Wf_AdminFirst_GraphqlAccessFilterInput>>;
  /** [NOT NULL] Database */
  database?: InputMaybe<StringOperationFilterInput>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<UuidOperationFilterInput>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Wf_AdminFirst_GraphqlSchemaFilterInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_GraphqlAccessFilterInput>>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Server */
  server?: InputMaybe<StringOperationFilterInput>;
  /** UpdateTime */
  updateTime?: InputMaybe<DateTimeOperationFilterInput>;
};

/** GraphqlAccess */
export type Wf_AdminFirst_GraphqlAccessInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<Scalars['String']['input']>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Database */
  database?: InputMaybe<Scalars['String']['input']>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<Scalars['UUID']['input']>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Wf_AdminFirst_GraphqlSchemaInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<Scalars['UUID']['input']>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Server */
  server?: InputMaybe<Scalars['String']['input']>;
  /** UpdateTime */
  updateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** GraphqlAccess */
export type Wf_AdminFirst_GraphqlAccessSortInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<SortEnumType>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Database */
  database?: InputMaybe<SortEnumType>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Wf_AdminFirst_GraphqlSchemaSortInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Server */
  server?: InputMaybe<SortEnumType>;
  /** UpdateTime */
  updateTime?: InputMaybe<SortEnumType>;
};

/** GraphqlSchema */
export type Wf_AdminFirst_GraphqlSchema = Node & {
  __typename?: 'WF_AdminFirst_GraphqlSchema';
  /** [NOT NULL] Database */
  database?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: Maybe<Array<Maybe<Wf_AdminFirst_GraphqlAccess>>>;
  /** [ID] GraphqlSchemaId */
  id: Scalars['ID']['output'];
  /** Schema */
  schema?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Server */
  server?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_GraphqlSchemaConnection = {
  __typename?: 'WF_AdminFirst_GraphqlSchemaConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_GraphqlSchemaEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_GraphqlSchema>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_GraphqlSchemaEdge = {
  __typename?: 'WF_AdminFirst_GraphqlSchemaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_GraphqlSchema;
};

/** GraphqlSchema */
export type Wf_AdminFirst_GraphqlSchemaFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_GraphqlSchemaFilterInput>>;
  /** [NOT NULL] Database */
  database?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_GraphqlAccessFilterInput>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_GraphqlSchemaFilterInput>>;
  /** Schema */
  schema?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Server */
  server?: InputMaybe<StringOperationFilterInput>;
};

/** GraphqlSchema */
export type Wf_AdminFirst_GraphqlSchemaInput = {
  /** [NOT NULL] Database */
  database?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_GraphqlAccessInput>>>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<Scalars['UUID']['input']>;
  /** Schema */
  schema?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Server */
  server?: InputMaybe<Scalars['String']['input']>;
};

/** GraphqlSchema */
export type Wf_AdminFirst_GraphqlSchemaSortInput = {
  /** [NOT NULL] Database */
  database?: InputMaybe<SortEnumType>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<SortEnumType>;
  /** Schema */
  schema?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Server */
  server?: InputMaybe<SortEnumType>;
};

export type Wf_AdminFirst_Mutation_Add = {
  __typename?: 'WF_AdminFirst_Mutation_Add';
  a?: Maybe<Wf_AdminFirst_A>;
  ab?: Maybe<Wf_AdminFirst_Ab>;
  afNotification?: Maybe<Wf_AdminFirst_AfNotification>;
  afNotificationMember?: Maybe<Wf_AdminFirst_AfNotificationMember>;
  afNotificationServiceType?: Maybe<Wf_AdminFirst_AfNotificationServiceType>;
  afProgram?: Maybe<Wf_AdminFirst_AfProgram>;
  afService?: Maybe<Wf_AdminFirst_AfService>;
  afUser?: Maybe<Wf_AdminFirst_AfUser>;
  afUserMarket?: Maybe<Wf_AdminFirst_AfUserMarket>;
  afUserProgram?: Maybe<Wf_AdminFirst_AfUserProgram>;
  afUserService?: Maybe<Wf_AdminFirst_AfUserService>;
  graphqlAccess?: Maybe<Wf_AdminFirst_GraphqlAccess>;
  graphqlSchema?: Maybe<Wf_AdminFirst_GraphqlSchema>;
  teamSetting?: Maybe<Wf_AdminFirst_TeamSetting>;
  tempFaceCall?: Maybe<Wf_AdminFirst_TempFaceCall>;
  tepsDailyHour?: Maybe<Wf_AdminFirst_TepsDailyHour>;
  tepsDailyHourLocationType?: Maybe<Wf_AdminFirst_TepsDailyHourLocationType>;
  tepsDailyHourType?: Maybe<Wf_AdminFirst_TepsDailyHourType>;
  tepsProject?: Maybe<Wf_AdminFirst_TepsProject>;
  tepsProjectAllocatedType?: Maybe<Wf_AdminFirst_TepsProjectAllocatedType>;
  tepsProjectType?: Maybe<Wf_AdminFirst_TepsProjectType>;
  tepsProjection?: Maybe<Wf_AdminFirst_TepsProjection>;
  tepsUser?: Maybe<Wf_AdminFirst_TepsUser>;
  tepsUserProject?: Maybe<Wf_AdminFirst_TepsUserProject>;
};

export type Wf_AdminFirst_Mutation_AddInput = {
  a?: InputMaybe<Wf_AdminFirst_AInput>;
  ab?: InputMaybe<Wf_AdminFirst_AbInput>;
  afNotification?: InputMaybe<Wf_AdminFirst_AfNotificationInput>;
  afNotificationMember?: InputMaybe<Wf_AdminFirst_AfNotificationMemberInput>;
  afNotificationServiceType?: InputMaybe<Wf_AdminFirst_AfNotificationServiceTypeInput>;
  afProgram?: InputMaybe<Wf_AdminFirst_AfProgramInput>;
  afService?: InputMaybe<Wf_AdminFirst_AfServiceInput>;
  afUser?: InputMaybe<Wf_AdminFirst_AfUserInput>;
  afUserMarket?: InputMaybe<Wf_AdminFirst_AfUserMarketInput>;
  afUserProgram?: InputMaybe<Wf_AdminFirst_AfUserProgramInput>;
  afUserService?: InputMaybe<Wf_AdminFirst_AfUserServiceInput>;
  graphqlAccess?: InputMaybe<Wf_AdminFirst_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Wf_AdminFirst_GraphqlSchemaInput>;
  teamSetting?: InputMaybe<Wf_AdminFirst_TeamSettingInput>;
  tempFaceCall?: InputMaybe<Wf_AdminFirst_TempFaceCallInput>;
  tepsDailyHour?: InputMaybe<Wf_AdminFirst_TepsDailyHourInput>;
  tepsDailyHourLocationType?: InputMaybe<Wf_AdminFirst_TepsDailyHourLocationTypeInput>;
  tepsDailyHourType?: InputMaybe<Wf_AdminFirst_TepsDailyHourTypeInput>;
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectInput>;
  tepsProjectAllocatedType?: InputMaybe<Wf_AdminFirst_TepsProjectAllocatedTypeInput>;
  tepsProjectType?: InputMaybe<Wf_AdminFirst_TepsProjectTypeInput>;
  tepsProjection?: InputMaybe<Wf_AdminFirst_TepsProjectionInput>;
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserInput>;
  tepsUserProject?: InputMaybe<Wf_AdminFirst_TepsUserProjectInput>;
};

export type Wf_AdminFirst_Mutation_AddRange = {
  __typename?: 'WF_AdminFirst_Mutation_AddRange';
  abs?: Maybe<Array<Wf_AdminFirst_Ab>>;
  afNotificationMembers?: Maybe<Array<Wf_AdminFirst_AfNotificationMember>>;
  afNotificationServiceTypes?: Maybe<Array<Wf_AdminFirst_AfNotificationServiceType>>;
  afNotifications?: Maybe<Array<Wf_AdminFirst_AfNotification>>;
  afPrograms?: Maybe<Array<Wf_AdminFirst_AfProgram>>;
  afServices?: Maybe<Array<Wf_AdminFirst_AfService>>;
  afUserMarkets?: Maybe<Array<Wf_AdminFirst_AfUserMarket>>;
  afUserPrograms?: Maybe<Array<Wf_AdminFirst_AfUserProgram>>;
  afUserServices?: Maybe<Array<Wf_AdminFirst_AfUserService>>;
  afUsers?: Maybe<Array<Wf_AdminFirst_AfUser>>;
  as?: Maybe<Array<Wf_AdminFirst_A>>;
  graphqlAccesses?: Maybe<Array<Wf_AdminFirst_GraphqlAccess>>;
  graphqlSchemas?: Maybe<Array<Wf_AdminFirst_GraphqlSchema>>;
  teamSettings?: Maybe<Array<Wf_AdminFirst_TeamSetting>>;
  tempFaceCalls?: Maybe<Array<Wf_AdminFirst_TempFaceCall>>;
  tepsDailyHourLocationTypes?: Maybe<Array<Wf_AdminFirst_TepsDailyHourLocationType>>;
  tepsDailyHourTypes?: Maybe<Array<Wf_AdminFirst_TepsDailyHourType>>;
  tepsDailyHours?: Maybe<Array<Wf_AdminFirst_TepsDailyHour>>;
  tepsProjectAllocatedTypes?: Maybe<Array<Wf_AdminFirst_TepsProjectAllocatedType>>;
  tepsProjectTypes?: Maybe<Array<Wf_AdminFirst_TepsProjectType>>;
  tepsProjections?: Maybe<Array<Wf_AdminFirst_TepsProjection>>;
  tepsProjects?: Maybe<Array<Wf_AdminFirst_TepsProject>>;
  tepsUserProjects?: Maybe<Array<Wf_AdminFirst_TepsUserProject>>;
  tepsUsers?: Maybe<Array<Wf_AdminFirst_TepsUser>>;
};

export type Wf_AdminFirst_Mutation_AddRangeInput = {
  abs?: InputMaybe<Array<Wf_AdminFirst_AbInput>>;
  afNotificationMembers?: InputMaybe<Array<Wf_AdminFirst_AfNotificationMemberInput>>;
  afNotificationServiceTypes?: InputMaybe<Array<Wf_AdminFirst_AfNotificationServiceTypeInput>>;
  afNotifications?: InputMaybe<Array<Wf_AdminFirst_AfNotificationInput>>;
  afPrograms?: InputMaybe<Array<Wf_AdminFirst_AfProgramInput>>;
  afServices?: InputMaybe<Array<Wf_AdminFirst_AfServiceInput>>;
  afUserMarkets?: InputMaybe<Array<Wf_AdminFirst_AfUserMarketInput>>;
  afUserPrograms?: InputMaybe<Array<Wf_AdminFirst_AfUserProgramInput>>;
  afUserServices?: InputMaybe<Array<Wf_AdminFirst_AfUserServiceInput>>;
  afUsers?: InputMaybe<Array<Wf_AdminFirst_AfUserInput>>;
  as?: InputMaybe<Array<Wf_AdminFirst_AInput>>;
  graphqlAccesses?: InputMaybe<Array<Wf_AdminFirst_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Wf_AdminFirst_GraphqlSchemaInput>>;
  teamSettings?: InputMaybe<Array<Wf_AdminFirst_TeamSettingInput>>;
  tempFaceCalls?: InputMaybe<Array<Wf_AdminFirst_TempFaceCallInput>>;
  tepsDailyHourLocationTypes?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourLocationTypeInput>>;
  tepsDailyHourTypes?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourTypeInput>>;
  tepsDailyHours?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourInput>>;
  tepsProjectAllocatedTypes?: InputMaybe<Array<Wf_AdminFirst_TepsProjectAllocatedTypeInput>>;
  tepsProjectTypes?: InputMaybe<Array<Wf_AdminFirst_TepsProjectTypeInput>>;
  tepsProjections?: InputMaybe<Array<Wf_AdminFirst_TepsProjectionInput>>;
  tepsProjects?: InputMaybe<Array<Wf_AdminFirst_TepsProjectInput>>;
  tepsUserProjects?: InputMaybe<Array<Wf_AdminFirst_TepsUserProjectInput>>;
  tepsUsers?: InputMaybe<Array<Wf_AdminFirst_TepsUserInput>>;
};

export type Wf_AdminFirst_Mutation_Payload = {
  __typename?: 'WF_AdminFirst_Mutation_Payload';
  addContext?: Maybe<Wf_AdminFirst_Mutation_Add>;
  addRangeContext?: Maybe<Wf_AdminFirst_Mutation_AddRange>;
  count: Scalars['Int']['output'];
  query: Query;
  updateContext?: Maybe<Wf_AdminFirst_Mutation_Update>;
  updateRangeContext?: Maybe<Wf_AdminFirst_Mutation_UpdateRange>;
};

export type Wf_AdminFirst_Mutation_RemoveInput = {
  a?: InputMaybe<Wf_AdminFirst_AInput>;
  ab?: InputMaybe<Wf_AdminFirst_AbInput>;
  afNotification?: InputMaybe<Wf_AdminFirst_AfNotificationInput>;
  afNotificationMember?: InputMaybe<Wf_AdminFirst_AfNotificationMemberInput>;
  afNotificationServiceType?: InputMaybe<Wf_AdminFirst_AfNotificationServiceTypeInput>;
  afProgram?: InputMaybe<Wf_AdminFirst_AfProgramInput>;
  afService?: InputMaybe<Wf_AdminFirst_AfServiceInput>;
  afUser?: InputMaybe<Wf_AdminFirst_AfUserInput>;
  afUserMarket?: InputMaybe<Wf_AdminFirst_AfUserMarketInput>;
  afUserProgram?: InputMaybe<Wf_AdminFirst_AfUserProgramInput>;
  afUserService?: InputMaybe<Wf_AdminFirst_AfUserServiceInput>;
  graphqlAccess?: InputMaybe<Wf_AdminFirst_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Wf_AdminFirst_GraphqlSchemaInput>;
  teamSetting?: InputMaybe<Wf_AdminFirst_TeamSettingInput>;
  tempFaceCall?: InputMaybe<Wf_AdminFirst_TempFaceCallInput>;
  tepsDailyHour?: InputMaybe<Wf_AdminFirst_TepsDailyHourInput>;
  tepsDailyHourLocationType?: InputMaybe<Wf_AdminFirst_TepsDailyHourLocationTypeInput>;
  tepsDailyHourType?: InputMaybe<Wf_AdminFirst_TepsDailyHourTypeInput>;
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectInput>;
  tepsProjectAllocatedType?: InputMaybe<Wf_AdminFirst_TepsProjectAllocatedTypeInput>;
  tepsProjectType?: InputMaybe<Wf_AdminFirst_TepsProjectTypeInput>;
  tepsProjection?: InputMaybe<Wf_AdminFirst_TepsProjectionInput>;
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserInput>;
  tepsUserProject?: InputMaybe<Wf_AdminFirst_TepsUserProjectInput>;
};

export type Wf_AdminFirst_Mutation_RemoveRangeInput = {
  abs?: InputMaybe<Array<Wf_AdminFirst_AbInput>>;
  afNotificationMembers?: InputMaybe<Array<Wf_AdminFirst_AfNotificationMemberInput>>;
  afNotificationServiceTypes?: InputMaybe<Array<Wf_AdminFirst_AfNotificationServiceTypeInput>>;
  afNotifications?: InputMaybe<Array<Wf_AdminFirst_AfNotificationInput>>;
  afPrograms?: InputMaybe<Array<Wf_AdminFirst_AfProgramInput>>;
  afServices?: InputMaybe<Array<Wf_AdminFirst_AfServiceInput>>;
  afUserMarkets?: InputMaybe<Array<Wf_AdminFirst_AfUserMarketInput>>;
  afUserPrograms?: InputMaybe<Array<Wf_AdminFirst_AfUserProgramInput>>;
  afUserServices?: InputMaybe<Array<Wf_AdminFirst_AfUserServiceInput>>;
  afUsers?: InputMaybe<Array<Wf_AdminFirst_AfUserInput>>;
  as?: InputMaybe<Array<Wf_AdminFirst_AInput>>;
  graphqlAccesses?: InputMaybe<Array<Wf_AdminFirst_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Wf_AdminFirst_GraphqlSchemaInput>>;
  teamSettings?: InputMaybe<Array<Wf_AdminFirst_TeamSettingInput>>;
  tempFaceCalls?: InputMaybe<Array<Wf_AdminFirst_TempFaceCallInput>>;
  tepsDailyHourLocationTypes?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourLocationTypeInput>>;
  tepsDailyHourTypes?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourTypeInput>>;
  tepsDailyHours?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourInput>>;
  tepsProjectAllocatedTypes?: InputMaybe<Array<Wf_AdminFirst_TepsProjectAllocatedTypeInput>>;
  tepsProjectTypes?: InputMaybe<Array<Wf_AdminFirst_TepsProjectTypeInput>>;
  tepsProjections?: InputMaybe<Array<Wf_AdminFirst_TepsProjectionInput>>;
  tepsProjects?: InputMaybe<Array<Wf_AdminFirst_TepsProjectInput>>;
  tepsUserProjects?: InputMaybe<Array<Wf_AdminFirst_TepsUserProjectInput>>;
  tepsUsers?: InputMaybe<Array<Wf_AdminFirst_TepsUserInput>>;
};

export type Wf_AdminFirst_Mutation_Update = {
  __typename?: 'WF_AdminFirst_Mutation_Update';
  a?: Maybe<Wf_AdminFirst_A>;
  ab?: Maybe<Wf_AdminFirst_Ab>;
  afNotification?: Maybe<Wf_AdminFirst_AfNotification>;
  afNotificationMember?: Maybe<Wf_AdminFirst_AfNotificationMember>;
  afNotificationServiceType?: Maybe<Wf_AdminFirst_AfNotificationServiceType>;
  afProgram?: Maybe<Wf_AdminFirst_AfProgram>;
  afService?: Maybe<Wf_AdminFirst_AfService>;
  afUser?: Maybe<Wf_AdminFirst_AfUser>;
  afUserMarket?: Maybe<Wf_AdminFirst_AfUserMarket>;
  afUserProgram?: Maybe<Wf_AdminFirst_AfUserProgram>;
  afUserService?: Maybe<Wf_AdminFirst_AfUserService>;
  graphqlAccess?: Maybe<Wf_AdminFirst_GraphqlAccess>;
  graphqlSchema?: Maybe<Wf_AdminFirst_GraphqlSchema>;
  teamSetting?: Maybe<Wf_AdminFirst_TeamSetting>;
  tempFaceCall?: Maybe<Wf_AdminFirst_TempFaceCall>;
  tepsDailyHour?: Maybe<Wf_AdminFirst_TepsDailyHour>;
  tepsDailyHourLocationType?: Maybe<Wf_AdminFirst_TepsDailyHourLocationType>;
  tepsDailyHourType?: Maybe<Wf_AdminFirst_TepsDailyHourType>;
  tepsProject?: Maybe<Wf_AdminFirst_TepsProject>;
  tepsProjectAllocatedType?: Maybe<Wf_AdminFirst_TepsProjectAllocatedType>;
  tepsProjectType?: Maybe<Wf_AdminFirst_TepsProjectType>;
  tepsProjection?: Maybe<Wf_AdminFirst_TepsProjection>;
  tepsUser?: Maybe<Wf_AdminFirst_TepsUser>;
  tepsUserProject?: Maybe<Wf_AdminFirst_TepsUserProject>;
};

export type Wf_AdminFirst_Mutation_UpdateInput = {
  a?: InputMaybe<Wf_AdminFirst_AInput>;
  ab?: InputMaybe<Wf_AdminFirst_AbInput>;
  afNotification?: InputMaybe<Wf_AdminFirst_AfNotificationInput>;
  afNotificationMember?: InputMaybe<Wf_AdminFirst_AfNotificationMemberInput>;
  afNotificationServiceType?: InputMaybe<Wf_AdminFirst_AfNotificationServiceTypeInput>;
  afProgram?: InputMaybe<Wf_AdminFirst_AfProgramInput>;
  afService?: InputMaybe<Wf_AdminFirst_AfServiceInput>;
  afUser?: InputMaybe<Wf_AdminFirst_AfUserInput>;
  afUserMarket?: InputMaybe<Wf_AdminFirst_AfUserMarketInput>;
  afUserProgram?: InputMaybe<Wf_AdminFirst_AfUserProgramInput>;
  afUserService?: InputMaybe<Wf_AdminFirst_AfUserServiceInput>;
  graphqlAccess?: InputMaybe<Wf_AdminFirst_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Wf_AdminFirst_GraphqlSchemaInput>;
  teamSetting?: InputMaybe<Wf_AdminFirst_TeamSettingInput>;
  tempFaceCall?: InputMaybe<Wf_AdminFirst_TempFaceCallInput>;
  tepsDailyHour?: InputMaybe<Wf_AdminFirst_TepsDailyHourInput>;
  tepsDailyHourLocationType?: InputMaybe<Wf_AdminFirst_TepsDailyHourLocationTypeInput>;
  tepsDailyHourType?: InputMaybe<Wf_AdminFirst_TepsDailyHourTypeInput>;
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectInput>;
  tepsProjectAllocatedType?: InputMaybe<Wf_AdminFirst_TepsProjectAllocatedTypeInput>;
  tepsProjectType?: InputMaybe<Wf_AdminFirst_TepsProjectTypeInput>;
  tepsProjection?: InputMaybe<Wf_AdminFirst_TepsProjectionInput>;
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserInput>;
  tepsUserProject?: InputMaybe<Wf_AdminFirst_TepsUserProjectInput>;
};

export type Wf_AdminFirst_Mutation_UpdateRange = {
  __typename?: 'WF_AdminFirst_Mutation_UpdateRange';
  abs?: Maybe<Array<Wf_AdminFirst_Ab>>;
  afNotificationMembers?: Maybe<Array<Wf_AdminFirst_AfNotificationMember>>;
  afNotificationServiceTypes?: Maybe<Array<Wf_AdminFirst_AfNotificationServiceType>>;
  afNotifications?: Maybe<Array<Wf_AdminFirst_AfNotification>>;
  afPrograms?: Maybe<Array<Wf_AdminFirst_AfProgram>>;
  afServices?: Maybe<Array<Wf_AdminFirst_AfService>>;
  afUserMarkets?: Maybe<Array<Wf_AdminFirst_AfUserMarket>>;
  afUserPrograms?: Maybe<Array<Wf_AdminFirst_AfUserProgram>>;
  afUserServices?: Maybe<Array<Wf_AdminFirst_AfUserService>>;
  afUsers?: Maybe<Array<Wf_AdminFirst_AfUser>>;
  as?: Maybe<Array<Wf_AdminFirst_A>>;
  graphqlAccesses?: Maybe<Array<Wf_AdminFirst_GraphqlAccess>>;
  graphqlSchemas?: Maybe<Array<Wf_AdminFirst_GraphqlSchema>>;
  teamSettings?: Maybe<Array<Wf_AdminFirst_TeamSetting>>;
  tempFaceCalls?: Maybe<Array<Wf_AdminFirst_TempFaceCall>>;
  tepsDailyHourLocationTypes?: Maybe<Array<Wf_AdminFirst_TepsDailyHourLocationType>>;
  tepsDailyHourTypes?: Maybe<Array<Wf_AdminFirst_TepsDailyHourType>>;
  tepsDailyHours?: Maybe<Array<Wf_AdminFirst_TepsDailyHour>>;
  tepsProjectAllocatedTypes?: Maybe<Array<Wf_AdminFirst_TepsProjectAllocatedType>>;
  tepsProjectTypes?: Maybe<Array<Wf_AdminFirst_TepsProjectType>>;
  tepsProjections?: Maybe<Array<Wf_AdminFirst_TepsProjection>>;
  tepsProjects?: Maybe<Array<Wf_AdminFirst_TepsProject>>;
  tepsUserProjects?: Maybe<Array<Wf_AdminFirst_TepsUserProject>>;
  tepsUsers?: Maybe<Array<Wf_AdminFirst_TepsUser>>;
};

export type Wf_AdminFirst_Mutation_UpdateRangeInput = {
  abs?: InputMaybe<Array<Wf_AdminFirst_AbInput>>;
  afNotificationMembers?: InputMaybe<Array<Wf_AdminFirst_AfNotificationMemberInput>>;
  afNotificationServiceTypes?: InputMaybe<Array<Wf_AdminFirst_AfNotificationServiceTypeInput>>;
  afNotifications?: InputMaybe<Array<Wf_AdminFirst_AfNotificationInput>>;
  afPrograms?: InputMaybe<Array<Wf_AdminFirst_AfProgramInput>>;
  afServices?: InputMaybe<Array<Wf_AdminFirst_AfServiceInput>>;
  afUserMarkets?: InputMaybe<Array<Wf_AdminFirst_AfUserMarketInput>>;
  afUserPrograms?: InputMaybe<Array<Wf_AdminFirst_AfUserProgramInput>>;
  afUserServices?: InputMaybe<Array<Wf_AdminFirst_AfUserServiceInput>>;
  afUsers?: InputMaybe<Array<Wf_AdminFirst_AfUserInput>>;
  as?: InputMaybe<Array<Wf_AdminFirst_AInput>>;
  graphqlAccesses?: InputMaybe<Array<Wf_AdminFirst_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Wf_AdminFirst_GraphqlSchemaInput>>;
  teamSettings?: InputMaybe<Array<Wf_AdminFirst_TeamSettingInput>>;
  tempFaceCalls?: InputMaybe<Array<Wf_AdminFirst_TempFaceCallInput>>;
  tepsDailyHourLocationTypes?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourLocationTypeInput>>;
  tepsDailyHourTypes?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourTypeInput>>;
  tepsDailyHours?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourInput>>;
  tepsProjectAllocatedTypes?: InputMaybe<Array<Wf_AdminFirst_TepsProjectAllocatedTypeInput>>;
  tepsProjectTypes?: InputMaybe<Array<Wf_AdminFirst_TepsProjectTypeInput>>;
  tepsProjections?: InputMaybe<Array<Wf_AdminFirst_TepsProjectionInput>>;
  tepsProjects?: InputMaybe<Array<Wf_AdminFirst_TepsProjectInput>>;
  tepsUserProjects?: InputMaybe<Array<Wf_AdminFirst_TepsUserProjectInput>>;
  tepsUsers?: InputMaybe<Array<Wf_AdminFirst_TepsUserInput>>;
};

/** TeamSetting */
export type Wf_AdminFirst_TeamSetting = Node & {
  __typename?: 'WF_AdminFirst_TeamSetting';
  /** ChannelId */
  channelId?: Maybe<Scalars['String']['output']>;
  /** [ID] TeamSettingId */
  id: Scalars['ID']['output'];
  /** IncomingWebhookUrl */
  incomingWebhookUrl?: Maybe<Scalars['String']['output']>;
  /** TeamId */
  teamId?: Maybe<Scalars['String']['output']>;
  /** TeamName */
  teamName?: Maybe<Scalars['String']['output']>;
  /** TeamType */
  teamType?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TeamSettingConnection = {
  __typename?: 'WF_AdminFirst_TeamSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TeamSettingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TeamSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TeamSettingEdge = {
  __typename?: 'WF_AdminFirst_TeamSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TeamSetting;
};

/** TeamSetting */
export type Wf_AdminFirst_TeamSettingFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TeamSettingFilterInput>>;
  /** ChannelId */
  channelId?: InputMaybe<StringOperationFilterInput>;
  /** IncomingWebhookUrl */
  incomingWebhookUrl?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TeamSettingFilterInput>>;
  /** TeamId */
  teamId?: InputMaybe<StringOperationFilterInput>;
  /** TeamName */
  teamName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TeamSettingId */
  teamSettingId?: InputMaybe<IntOperationFilterInput>;
  /** TeamType */
  teamType?: InputMaybe<StringOperationFilterInput>;
};

/** TeamSetting */
export type Wf_AdminFirst_TeamSettingInput = {
  /** ChannelId */
  channelId?: InputMaybe<Scalars['String']['input']>;
  /** IncomingWebhookUrl */
  incomingWebhookUrl?: InputMaybe<Scalars['String']['input']>;
  /** TeamId */
  teamId?: InputMaybe<Scalars['String']['input']>;
  /** TeamName */
  teamName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TeamSettingId */
  teamSettingId?: InputMaybe<Scalars['Int']['input']>;
  /** TeamType */
  teamType?: InputMaybe<Scalars['String']['input']>;
};

/** TeamSetting */
export type Wf_AdminFirst_TeamSettingSortInput = {
  /** ChannelId */
  channelId?: InputMaybe<SortEnumType>;
  /** IncomingWebhookUrl */
  incomingWebhookUrl?: InputMaybe<SortEnumType>;
  /** TeamId */
  teamId?: InputMaybe<SortEnumType>;
  /** TeamName */
  teamName?: InputMaybe<SortEnumType>;
  /** [ID] TeamSettingId */
  teamSettingId?: InputMaybe<SortEnumType>;
  /** TeamType */
  teamType?: InputMaybe<SortEnumType>;
};

/** TempFaceCall */
export type Wf_AdminFirst_TempFaceCall = Node & {
  __typename?: 'WF_AdminFirst_TempFaceCall';
  /** CreatedDate */
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** PerformerName */
  performerName?: Maybe<Scalars['String']['output']>;
  /** SongName */
  songName?: Maybe<Scalars['String']['output']>;
  /** Theme */
  theme?: Maybe<Scalars['String']['output']>;
  /** VimeoIdJp */
  vimeoIdJp?: Maybe<Scalars['String']['output']>;
  /** VimeoIdTw */
  vimeoIdTw?: Maybe<Scalars['String']['output']>;
  /** VimeoLink */
  vimeoLink?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TempFaceCallConnection = {
  __typename?: 'WF_AdminFirst_TempFaceCallConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TempFaceCallEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TempFaceCall>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TempFaceCallEdge = {
  __typename?: 'WF_AdminFirst_TempFaceCallEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TempFaceCall;
};

/** TempFaceCall */
export type Wf_AdminFirst_TempFaceCallFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TempFaceCallFilterInput>>;
  /** CreatedDate */
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TempFaceCallFilterInput>>;
  /** PerformerName */
  performerName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
  /** SongName */
  songName?: InputMaybe<StringOperationFilterInput>;
  /** Theme */
  theme?: InputMaybe<StringOperationFilterInput>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<StringOperationFilterInput>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<StringOperationFilterInput>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<StringOperationFilterInput>;
};

/** TempFaceCall */
export type Wf_AdminFirst_TempFaceCallInput = {
  /** CreatedDate */
  createdDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** PerformerName */
  performerName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
  /** SongName */
  songName?: InputMaybe<Scalars['String']['input']>;
  /** Theme */
  theme?: InputMaybe<Scalars['String']['input']>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<Scalars['String']['input']>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<Scalars['String']['input']>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<Scalars['String']['input']>;
};

/** TempFaceCall */
export type Wf_AdminFirst_TempFaceCallSortInput = {
  /** CreatedDate */
  createdDate?: InputMaybe<SortEnumType>;
  /** PerformerName */
  performerName?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
  /** SongName */
  songName?: InputMaybe<SortEnumType>;
  /** Theme */
  theme?: InputMaybe<SortEnumType>;
  /** VimeoIdJp */
  vimeoIdJp?: InputMaybe<SortEnumType>;
  /** VimeoIdTw */
  vimeoIdTw?: InputMaybe<SortEnumType>;
  /** VimeoLink */
  vimeoLink?: InputMaybe<SortEnumType>;
};

/** TepsDailyHour */
export type Wf_AdminFirst_TepsDailyHour = Node & {
  __typename?: 'WF_AdminFirst_TepsDailyHour';
  /** Date */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Hours */
  hours?: Maybe<Scalars['Decimal']['output']>;
  /** [ID] TepsDailyHourId */
  id: Scalars['ID']['output'];
  /** [NAVIGATION] TepsDailyHourLocationType */
  tepsDailyHourLocationType?: Maybe<Wf_AdminFirst_TepsDailyHourLocationType>;
  /** TepsDailyHourLocationTypeId */
  tepsDailyHourLocationTypeId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] TepsDailyHourType */
  tepsDailyHourType?: Maybe<Wf_AdminFirst_TepsDailyHourType>;
  /** TepsDailyHourTypeId */
  tepsDailyHourTypeId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: Maybe<Wf_AdminFirst_TepsProject>;
  /** TepsProjectId */
  tepsProjectId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: Maybe<Wf_AdminFirst_TepsUser>;
  /** TepsUserId */
  tepsUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsDailyHourConnection = {
  __typename?: 'WF_AdminFirst_TepsDailyHourConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsDailyHourEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsDailyHour>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsDailyHourEdge = {
  __typename?: 'WF_AdminFirst_TepsDailyHourEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsDailyHour;
};

/** TepsDailyHour */
export type Wf_AdminFirst_TepsDailyHourFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourFilterInput>>;
  /** Date */
  date?: InputMaybe<DateTimeOperationFilterInput>;
  /** Description */
  description?: InputMaybe<StringOperationFilterInput>;
  /** Hours */
  hours?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourFilterInput>>;
  /** [ID] TepsDailyHourId */
  tepsDailyHourId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] TepsDailyHourLocationType */
  tepsDailyHourLocationType?: InputMaybe<Wf_AdminFirst_TepsDailyHourLocationTypeFilterInput>;
  /** TepsDailyHourLocationTypeId */
  tepsDailyHourLocationTypeId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] TepsDailyHourType */
  tepsDailyHourType?: InputMaybe<Wf_AdminFirst_TepsDailyHourTypeFilterInput>;
  /** TepsDailyHourTypeId */
  tepsDailyHourTypeId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectFilterInput>;
  /** TepsProjectId */
  tepsProjectId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserFilterInput>;
  /** TepsUserId */
  tepsUserId?: InputMaybe<IntOperationFilterInput>;
};

/** TepsDailyHour */
export type Wf_AdminFirst_TepsDailyHourInput = {
  /** Date */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Hours */
  hours?: InputMaybe<Scalars['Decimal']['input']>;
  /** [ID] TepsDailyHourId */
  tepsDailyHourId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] TepsDailyHourLocationType */
  tepsDailyHourLocationType?: InputMaybe<Wf_AdminFirst_TepsDailyHourLocationTypeInput>;
  /** TepsDailyHourLocationTypeId */
  tepsDailyHourLocationTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] TepsDailyHourType */
  tepsDailyHourType?: InputMaybe<Wf_AdminFirst_TepsDailyHourTypeInput>;
  /** TepsDailyHourTypeId */
  tepsDailyHourTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectInput>;
  /** TepsProjectId */
  tepsProjectId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserInput>;
  /** TepsUserId */
  tepsUserId?: InputMaybe<Scalars['Int']['input']>;
};

/** TepsDailyHourLocationType */
export type Wf_AdminFirst_TepsDailyHourLocationType = Node & {
  __typename?: 'WF_AdminFirst_TepsDailyHourLocationType';
  /** [ID] TepsDailyHourLocationTypeId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Name */
  name?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: Maybe<Array<Maybe<Wf_AdminFirst_TepsDailyHour>>>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsDailyHourLocationTypeConnection = {
  __typename?: 'WF_AdminFirst_TepsDailyHourLocationTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsDailyHourLocationTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsDailyHourLocationType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsDailyHourLocationTypeEdge = {
  __typename?: 'WF_AdminFirst_TepsDailyHourLocationTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsDailyHourLocationType;
};

/** TepsDailyHourLocationType */
export type Wf_AdminFirst_TepsDailyHourLocationTypeFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourLocationTypeFilterInput>>;
  /** [NOT NULL] Name */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourLocationTypeFilterInput>>;
  /** [ID] TepsDailyHourLocationTypeId */
  tepsDailyHourLocationTypeId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsDailyHourFilterInput>;
};

/** TepsDailyHourLocationType */
export type Wf_AdminFirst_TepsDailyHourLocationTypeInput = {
  /** [NOT NULL] Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TepsDailyHourLocationTypeId */
  tepsDailyHourLocationTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsDailyHourInput>>>;
};

/** TepsDailyHourLocationType */
export type Wf_AdminFirst_TepsDailyHourLocationTypeSortInput = {
  /** [NOT NULL] Name */
  name?: InputMaybe<SortEnumType>;
  /** [ID] TepsDailyHourLocationTypeId */
  tepsDailyHourLocationTypeId?: InputMaybe<SortEnumType>;
};

/** TepsDailyHour */
export type Wf_AdminFirst_TepsDailyHourSortInput = {
  /** Date */
  date?: InputMaybe<SortEnumType>;
  /** Description */
  description?: InputMaybe<SortEnumType>;
  /** Hours */
  hours?: InputMaybe<SortEnumType>;
  /** [ID] TepsDailyHourId */
  tepsDailyHourId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TepsDailyHourLocationType */
  tepsDailyHourLocationType?: InputMaybe<Wf_AdminFirst_TepsDailyHourLocationTypeSortInput>;
  /** TepsDailyHourLocationTypeId */
  tepsDailyHourLocationTypeId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TepsDailyHourType */
  tepsDailyHourType?: InputMaybe<Wf_AdminFirst_TepsDailyHourTypeSortInput>;
  /** TepsDailyHourTypeId */
  tepsDailyHourTypeId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectSortInput>;
  /** TepsProjectId */
  tepsProjectId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserSortInput>;
  /** TepsUserId */
  tepsUserId?: InputMaybe<SortEnumType>;
};

/** TepsDailyHourType */
export type Wf_AdminFirst_TepsDailyHourType = Node & {
  __typename?: 'WF_AdminFirst_TepsDailyHourType';
  /** [ID] TepsDailyHourTypeId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Name */
  name?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: Maybe<Array<Maybe<Wf_AdminFirst_TepsDailyHour>>>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsDailyHourTypeConnection = {
  __typename?: 'WF_AdminFirst_TepsDailyHourTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsDailyHourTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsDailyHourType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsDailyHourTypeEdge = {
  __typename?: 'WF_AdminFirst_TepsDailyHourTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsDailyHourType;
};

/** TepsDailyHourType */
export type Wf_AdminFirst_TepsDailyHourTypeFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourTypeFilterInput>>;
  /** [NOT NULL] Name */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsDailyHourTypeFilterInput>>;
  /** [ID] TepsDailyHourTypeId */
  tepsDailyHourTypeId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsDailyHourFilterInput>;
};

/** TepsDailyHourType */
export type Wf_AdminFirst_TepsDailyHourTypeInput = {
  /** [NOT NULL] Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TepsDailyHourTypeId */
  tepsDailyHourTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsDailyHourInput>>>;
};

/** TepsDailyHourType */
export type Wf_AdminFirst_TepsDailyHourTypeSortInput = {
  /** [NOT NULL] Name */
  name?: InputMaybe<SortEnumType>;
  /** [ID] TepsDailyHourTypeId */
  tepsDailyHourTypeId?: InputMaybe<SortEnumType>;
};

/** TepsProject */
export type Wf_AdminFirst_TepsProject = Node & {
  __typename?: 'WF_AdminFirst_TepsProject';
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** EndDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] TepsProjectId */
  id: Scalars['ID']['output'];
  /** JiraProjectLink */
  jiraProjectLink?: Maybe<Scalars['String']['output']>;
  /** Legacy */
  legacy?: Maybe<Scalars['Boolean']['output']>;
  /** Task */
  task?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: Maybe<Array<Maybe<Wf_AdminFirst_TepsDailyHour>>>;
  /** TepsProjectTypeId */
  tepsProjectTypeId?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] TepsProjectTypes */
  tepsProjectTypes?: Maybe<Array<Maybe<Wf_AdminFirst_TepsProjectType>>>;
  /** [COLLECTION] TepsProjections */
  tepsProjections?: Maybe<Array<Maybe<Wf_AdminFirst_TepsProjection>>>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: Maybe<Wf_AdminFirst_TepsUser>;
  /** TepsUserId */
  tepsUserId?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] TepsUserProjects */
  tepsUserProjects?: Maybe<Array<Maybe<Wf_AdminFirst_TepsUserProject>>>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
  /** Wfi */
  wfi?: Maybe<Scalars['Boolean']['output']>;
};

/** TepsProjectAllocatedType */
export type Wf_AdminFirst_TepsProjectAllocatedType = Node & {
  __typename?: 'WF_AdminFirst_TepsProjectAllocatedType';
  /** [ID] TepsProjectAllocatedTypeId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Name */
  name?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] TepsProjectTypes */
  tepsProjectTypes?: Maybe<Array<Maybe<Wf_AdminFirst_TepsProjectType>>>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsProjectAllocatedTypeConnection = {
  __typename?: 'WF_AdminFirst_TepsProjectAllocatedTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsProjectAllocatedTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsProjectAllocatedType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsProjectAllocatedTypeEdge = {
  __typename?: 'WF_AdminFirst_TepsProjectAllocatedTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsProjectAllocatedType;
};

/** TepsProjectAllocatedType */
export type Wf_AdminFirst_TepsProjectAllocatedTypeFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsProjectAllocatedTypeFilterInput>>;
  /** [NOT NULL] Name */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsProjectAllocatedTypeFilterInput>>;
  /** [ID] TepsProjectAllocatedTypeId */
  tepsProjectAllocatedTypeId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] TepsProjectTypes */
  tepsProjectTypes?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsProjectTypeFilterInput>;
};

/** TepsProjectAllocatedType */
export type Wf_AdminFirst_TepsProjectAllocatedTypeInput = {
  /** [NOT NULL] Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TepsProjectAllocatedTypeId */
  tepsProjectAllocatedTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] TepsProjectTypes */
  tepsProjectTypes?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsProjectTypeInput>>>;
};

/** TepsProjectAllocatedType */
export type Wf_AdminFirst_TepsProjectAllocatedTypeSortInput = {
  /** [NOT NULL] Name */
  name?: InputMaybe<SortEnumType>;
  /** [ID] TepsProjectAllocatedTypeId */
  tepsProjectAllocatedTypeId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsProjectConnection = {
  __typename?: 'WF_AdminFirst_TepsProjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsProjectEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsProject>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsProjectEdge = {
  __typename?: 'WF_AdminFirst_TepsProjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsProject;
};

/** TepsProject */
export type Wf_AdminFirst_TepsProjectFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsProjectFilterInput>>;
  /** Description */
  description?: InputMaybe<StringOperationFilterInput>;
  /** EndDate */
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** JiraProjectLink */
  jiraProjectLink?: InputMaybe<StringOperationFilterInput>;
  /** Legacy */
  legacy?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsProjectFilterInput>>;
  /** Task */
  task?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsDailyHourFilterInput>;
  /** [ID] TepsProjectId */
  tepsProjectId?: InputMaybe<IntOperationFilterInput>;
  /** TepsProjectTypeId */
  tepsProjectTypeId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] TepsProjectTypes */
  tepsProjectTypes?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsProjectTypeFilterInput>;
  /** [COLLECTION] TepsProjections */
  tepsProjections?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsProjectionFilterInput>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserFilterInput>;
  /** TepsUserId */
  tepsUserId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] TepsUserProjects */
  tepsUserProjects?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsUserProjectFilterInput>;
  /** Title */
  title?: InputMaybe<StringOperationFilterInput>;
  /** Wfi */
  wfi?: InputMaybe<BooleanOperationFilterInput>;
};

/** TepsProject */
export type Wf_AdminFirst_TepsProjectInput = {
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** EndDate */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** JiraProjectLink */
  jiraProjectLink?: InputMaybe<Scalars['String']['input']>;
  /** Legacy */
  legacy?: InputMaybe<Scalars['Boolean']['input']>;
  /** Task */
  task?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsDailyHourInput>>>;
  /** [ID] TepsProjectId */
  tepsProjectId?: InputMaybe<Scalars['Int']['input']>;
  /** TepsProjectTypeId */
  tepsProjectTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] TepsProjectTypes */
  tepsProjectTypes?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsProjectTypeInput>>>;
  /** [COLLECTION] TepsProjections */
  tepsProjections?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsProjectionInput>>>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserInput>;
  /** TepsUserId */
  tepsUserId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] TepsUserProjects */
  tepsUserProjects?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsUserProjectInput>>>;
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Wfi */
  wfi?: InputMaybe<Scalars['Boolean']['input']>;
};

/** TepsProject */
export type Wf_AdminFirst_TepsProjectSortInput = {
  /** Description */
  description?: InputMaybe<SortEnumType>;
  /** EndDate */
  endDate?: InputMaybe<SortEnumType>;
  /** JiraProjectLink */
  jiraProjectLink?: InputMaybe<SortEnumType>;
  /** Legacy */
  legacy?: InputMaybe<SortEnumType>;
  /** Task */
  task?: InputMaybe<SortEnumType>;
  /** [ID] TepsProjectId */
  tepsProjectId?: InputMaybe<SortEnumType>;
  /** TepsProjectTypeId */
  tepsProjectTypeId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserSortInput>;
  /** TepsUserId */
  tepsUserId?: InputMaybe<SortEnumType>;
  /** Title */
  title?: InputMaybe<SortEnumType>;
  /** Wfi */
  wfi?: InputMaybe<SortEnumType>;
};

/** TepsProjectType */
export type Wf_AdminFirst_TepsProjectType = Node & {
  __typename?: 'WF_AdminFirst_TepsProjectType';
  /** [ID] TepsProjectTypeId */
  id: Scalars['ID']['output'];
  /** [NAVIGATION] TepsProject */
  tepsProject?: Maybe<Wf_AdminFirst_TepsProject>;
  /** [NAVIGATION] TepsProjectAllocatedType */
  tepsProjectAllocatedType?: Maybe<Wf_AdminFirst_TepsProjectAllocatedType>;
  /** [NOT NULL] TepsProjectAllocatedTypeId */
  tepsProjectAllocatedTypeId?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] TepsProjectId */
  tepsProjectId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsProjectTypeConnection = {
  __typename?: 'WF_AdminFirst_TepsProjectTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsProjectTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsProjectType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsProjectTypeEdge = {
  __typename?: 'WF_AdminFirst_TepsProjectTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsProjectType;
};

/** TepsProjectType */
export type Wf_AdminFirst_TepsProjectTypeFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsProjectTypeFilterInput>>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsProjectTypeFilterInput>>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectFilterInput>;
  /** [NAVIGATION] TepsProjectAllocatedType */
  tepsProjectAllocatedType?: InputMaybe<Wf_AdminFirst_TepsProjectAllocatedTypeFilterInput>;
  /** [NOT NULL] TepsProjectAllocatedTypeId */
  tepsProjectAllocatedTypeId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] TepsProjectId */
  tepsProjectId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] TepsProjectTypeId */
  tepsProjectTypeId?: InputMaybe<LongOperationFilterInput>;
};

/** TepsProjectType */
export type Wf_AdminFirst_TepsProjectTypeInput = {
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectInput>;
  /** [NAVIGATION] TepsProjectAllocatedType */
  tepsProjectAllocatedType?: InputMaybe<Wf_AdminFirst_TepsProjectAllocatedTypeInput>;
  /** [NOT NULL] TepsProjectAllocatedTypeId */
  tepsProjectAllocatedTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] TepsProjectId */
  tepsProjectId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] TepsProjectTypeId */
  tepsProjectTypeId?: InputMaybe<Scalars['Long']['input']>;
};

/** TepsProjectType */
export type Wf_AdminFirst_TepsProjectTypeSortInput = {
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectSortInput>;
  /** [NAVIGATION] TepsProjectAllocatedType */
  tepsProjectAllocatedType?: InputMaybe<Wf_AdminFirst_TepsProjectAllocatedTypeSortInput>;
  /** [NOT NULL] TepsProjectAllocatedTypeId */
  tepsProjectAllocatedTypeId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TepsProjectId */
  tepsProjectId?: InputMaybe<SortEnumType>;
  /** [ID] TepsProjectTypeId */
  tepsProjectTypeId?: InputMaybe<SortEnumType>;
};

/** TepsProjection */
export type Wf_AdminFirst_TepsProjection = Node & {
  __typename?: 'WF_AdminFirst_TepsProjection';
  /** DesignManQuarter */
  designManQuarter?: Maybe<Scalars['Short']['output']>;
  /** DeveloperManQuarter */
  developerManQuarter?: Maybe<Scalars['Short']['output']>;
  /** Developing */
  developing?: Maybe<Scalars['Boolean']['output']>;
  /** [ID] TepsProjectionsId */
  id: Scalars['ID']['output'];
  /** Maintaining */
  maintaining?: Maybe<Scalars['Boolean']['output']>;
  /** Quarter */
  quarter?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: Maybe<Wf_AdminFirst_TepsProject>;
  /** TepsProjectId */
  tepsProjectId?: Maybe<Scalars['Int']['output']>;
  /** VietnamManQuarter */
  vietnamManQuarter?: Maybe<Scalars['Short']['output']>;
  /** Years */
  years?: Maybe<Scalars['Short']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsProjectionConnection = {
  __typename?: 'WF_AdminFirst_TepsProjectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsProjectionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsProjection>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsProjectionEdge = {
  __typename?: 'WF_AdminFirst_TepsProjectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsProjection;
};

/** TepsProjection */
export type Wf_AdminFirst_TepsProjectionFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsProjectionFilterInput>>;
  /** DesignManQuarter */
  designManQuarter?: InputMaybe<ShortOperationFilterInput>;
  /** DeveloperManQuarter */
  developerManQuarter?: InputMaybe<ShortOperationFilterInput>;
  /** Developing */
  developing?: InputMaybe<BooleanOperationFilterInput>;
  /** Maintaining */
  maintaining?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsProjectionFilterInput>>;
  /** Quarter */
  quarter?: InputMaybe<StringOperationFilterInput>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectFilterInput>;
  /** TepsProjectId */
  tepsProjectId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] TepsProjectionsId */
  tepsProjectionsId?: InputMaybe<IntOperationFilterInput>;
  /** VietnamManQuarter */
  vietnamManQuarter?: InputMaybe<ShortOperationFilterInput>;
  /** Years */
  years?: InputMaybe<ShortOperationFilterInput>;
};

/** TepsProjection */
export type Wf_AdminFirst_TepsProjectionInput = {
  /** DesignManQuarter */
  designManQuarter?: InputMaybe<Scalars['Short']['input']>;
  /** DeveloperManQuarter */
  developerManQuarter?: InputMaybe<Scalars['Short']['input']>;
  /** Developing */
  developing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Maintaining */
  maintaining?: InputMaybe<Scalars['Boolean']['input']>;
  /** Quarter */
  quarter?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectInput>;
  /** TepsProjectId */
  tepsProjectId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] TepsProjectionsId */
  tepsProjectionsId?: InputMaybe<Scalars['Int']['input']>;
  /** VietnamManQuarter */
  vietnamManQuarter?: InputMaybe<Scalars['Short']['input']>;
  /** Years */
  years?: InputMaybe<Scalars['Short']['input']>;
};

/** TepsProjection */
export type Wf_AdminFirst_TepsProjectionSortInput = {
  /** DesignManQuarter */
  designManQuarter?: InputMaybe<SortEnumType>;
  /** DeveloperManQuarter */
  developerManQuarter?: InputMaybe<SortEnumType>;
  /** Developing */
  developing?: InputMaybe<SortEnumType>;
  /** Maintaining */
  maintaining?: InputMaybe<SortEnumType>;
  /** Quarter */
  quarter?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectSortInput>;
  /** TepsProjectId */
  tepsProjectId?: InputMaybe<SortEnumType>;
  /** [ID] TepsProjectionsId */
  tepsProjectionsId?: InputMaybe<SortEnumType>;
  /** VietnamManQuarter */
  vietnamManQuarter?: InputMaybe<SortEnumType>;
  /** Years */
  years?: InputMaybe<SortEnumType>;
};

/** TepsUser */
export type Wf_AdminFirst_TepsUser = Node & {
  __typename?: 'WF_AdminFirst_TepsUser';
  /** Blocked */
  blocked?: Maybe<Scalars['Boolean']['output']>;
  /** DisplayName */
  displayName?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Email */
  email?: Maybe<Scalars['String']['output']>;
  /** EmpCode */
  empCode?: Maybe<Scalars['String']['output']>;
  /** [ID] TepsUserId */
  id: Scalars['ID']['output'];
  /** JiraId */
  jiraId?: Maybe<Scalars['String']['output']>;
  /** JoinDate */
  joinDate?: Maybe<Scalars['DateTime']['output']>;
  /** Leader */
  leader?: Maybe<Scalars['Boolean']['output']>;
  /** PhotoUrl */
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** Role */
  role?: Maybe<Scalars['String']['output']>;
  /** TeamId */
  teamId?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: Maybe<Array<Maybe<Wf_AdminFirst_TepsDailyHour>>>;
  /** [COLLECTION] TepsProjects */
  tepsProjects?: Maybe<Array<Maybe<Wf_AdminFirst_TepsProject>>>;
  /** [COLLECTION] TepsUserProjects */
  tepsUserProjects?: Maybe<Array<Maybe<Wf_AdminFirst_TepsUserProject>>>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsUserConnection = {
  __typename?: 'WF_AdminFirst_TepsUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsUserEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsUserEdge = {
  __typename?: 'WF_AdminFirst_TepsUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsUser;
};

/** TepsUser */
export type Wf_AdminFirst_TepsUserFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsUserFilterInput>>;
  /** Blocked */
  blocked?: InputMaybe<BooleanOperationFilterInput>;
  /** DisplayName */
  displayName?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Email */
  email?: InputMaybe<StringOperationFilterInput>;
  /** EmpCode */
  empCode?: InputMaybe<StringOperationFilterInput>;
  /** JiraId */
  jiraId?: InputMaybe<StringOperationFilterInput>;
  /** JoinDate */
  joinDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Leader */
  leader?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsUserFilterInput>>;
  /** PhotoUrl */
  photoUrl?: InputMaybe<StringOperationFilterInput>;
  /** Role */
  role?: InputMaybe<StringOperationFilterInput>;
  /** TeamId */
  teamId?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsDailyHourFilterInput>;
  /** [COLLECTION] TepsProjects */
  tepsProjects?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsProjectFilterInput>;
  /** [ID] TepsUserId */
  tepsUserId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] TepsUserProjects */
  tepsUserProjects?: InputMaybe<ListFilterInputTypeOfWf_AdminFirst_TepsUserProjectFilterInput>;
};

/** TepsUser */
export type Wf_AdminFirst_TepsUserInput = {
  /** Blocked */
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  /** DisplayName */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** EmpCode */
  empCode?: InputMaybe<Scalars['String']['input']>;
  /** JiraId */
  jiraId?: InputMaybe<Scalars['String']['input']>;
  /** JoinDate */
  joinDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Leader */
  leader?: InputMaybe<Scalars['Boolean']['input']>;
  /** PhotoUrl */
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Role */
  role?: InputMaybe<Scalars['String']['input']>;
  /** TeamId */
  teamId?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] TepsDailyHours */
  tepsDailyHours?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsDailyHourInput>>>;
  /** [COLLECTION] TepsProjects */
  tepsProjects?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsProjectInput>>>;
  /** [ID] TepsUserId */
  tepsUserId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] TepsUserProjects */
  tepsUserProjects?: InputMaybe<Array<InputMaybe<Wf_AdminFirst_TepsUserProjectInput>>>;
};

/** TepsUserProject */
export type Wf_AdminFirst_TepsUserProject = Node & {
  __typename?: 'WF_AdminFirst_TepsUserProject';
  /** [ID] TepsUserProjectId */
  id: Scalars['ID']['output'];
  /** [NAVIGATION] TepsProject */
  tepsProject?: Maybe<Wf_AdminFirst_TepsProject>;
  /** [NOT NULL] TepsProjectId */
  tepsProjectId?: Maybe<Scalars['Int']['output']>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: Maybe<Wf_AdminFirst_TepsUser>;
  /** [NOT NULL] TepsUserId */
  tepsUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_AdminFirst_TepsUserProjectConnection = {
  __typename?: 'WF_AdminFirst_TepsUserProjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_AdminFirst_TepsUserProjectEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_AdminFirst_TepsUserProject>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_AdminFirst_TepsUserProjectEdge = {
  __typename?: 'WF_AdminFirst_TepsUserProjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_AdminFirst_TepsUserProject;
};

/** TepsUserProject */
export type Wf_AdminFirst_TepsUserProjectFilterInput = {
  and?: InputMaybe<Array<Wf_AdminFirst_TepsUserProjectFilterInput>>;
  or?: InputMaybe<Array<Wf_AdminFirst_TepsUserProjectFilterInput>>;
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectFilterInput>;
  /** [NOT NULL] TepsProjectId */
  tepsProjectId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserFilterInput>;
  /** [NOT NULL] TepsUserId */
  tepsUserId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] TepsUserProjectId */
  tepsUserProjectId?: InputMaybe<IntOperationFilterInput>;
};

/** TepsUserProject */
export type Wf_AdminFirst_TepsUserProjectInput = {
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectInput>;
  /** [NOT NULL] TepsProjectId */
  tepsProjectId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserInput>;
  /** [NOT NULL] TepsUserId */
  tepsUserId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] TepsUserProjectId */
  tepsUserProjectId?: InputMaybe<Scalars['Int']['input']>;
};

/** TepsUserProject */
export type Wf_AdminFirst_TepsUserProjectSortInput = {
  /** [NAVIGATION] TepsProject */
  tepsProject?: InputMaybe<Wf_AdminFirst_TepsProjectSortInput>;
  /** [NOT NULL] TepsProjectId */
  tepsProjectId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TepsUser */
  tepsUser?: InputMaybe<Wf_AdminFirst_TepsUserSortInput>;
  /** [NOT NULL] TepsUserId */
  tepsUserId?: InputMaybe<SortEnumType>;
  /** [ID] TepsUserProjectId */
  tepsUserProjectId?: InputMaybe<SortEnumType>;
};

/** TepsUser */
export type Wf_AdminFirst_TepsUserSortInput = {
  /** Blocked */
  blocked?: InputMaybe<SortEnumType>;
  /** DisplayName */
  displayName?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Email */
  email?: InputMaybe<SortEnumType>;
  /** EmpCode */
  empCode?: InputMaybe<SortEnumType>;
  /** JiraId */
  jiraId?: InputMaybe<SortEnumType>;
  /** JoinDate */
  joinDate?: InputMaybe<SortEnumType>;
  /** Leader */
  leader?: InputMaybe<SortEnumType>;
  /** PhotoUrl */
  photoUrl?: InputMaybe<SortEnumType>;
  /** Role */
  role?: InputMaybe<SortEnumType>;
  /** TeamId */
  teamId?: InputMaybe<SortEnumType>;
  /** [ID] TepsUserId */
  tepsUserId?: InputMaybe<SortEnumType>;
};

/** AutoMail */
export type Wf_TrialModeLeads_AutoMail = Node & {
  __typename?: 'WF_TrialModeLeads_AutoMail';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] MailId */
  id: Scalars['ID']['output'];
  /** MailBcc */
  mailBcc?: Maybe<Scalars['String']['output']>;
  /** MailCc */
  mailCc?: Maybe<Scalars['String']['output']>;
  /** MailContent */
  mailContent?: Maybe<Scalars['String']['output']>;
  /** MailStatus */
  mailStatus?: Maybe<Scalars['String']['output']>;
  /** MailSubject */
  mailSubject?: Maybe<Scalars['String']['output']>;
  /** MailTo */
  mailTo?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** SenderAddress */
  senderAddress?: Maybe<Scalars['String']['output']>;
  /** SenderName */
  senderName?: Maybe<Scalars['String']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_AutoMailConnection = {
  __typename?: 'WF_TrialModeLeads_AutoMailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_AutoMailEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_AutoMail>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_AutoMailEdge = {
  __typename?: 'WF_TrialModeLeads_AutoMailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_AutoMail;
};

/** AutoMail */
export type Wf_TrialModeLeads_AutoMailFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_AutoMailFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** MailBcc */
  mailBcc?: InputMaybe<StringOperationFilterInput>;
  /** MailCc */
  mailCc?: InputMaybe<StringOperationFilterInput>;
  /** MailContent */
  mailContent?: InputMaybe<StringOperationFilterInput>;
  /** [ID] MailId */
  mailId?: InputMaybe<IntOperationFilterInput>;
  /** MailStatus */
  mailStatus?: InputMaybe<StringOperationFilterInput>;
  /** MailSubject */
  mailSubject?: InputMaybe<StringOperationFilterInput>;
  /** MailTo */
  mailTo?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_AutoMailFilterInput>>;
  /** SenderAddress */
  senderAddress?: InputMaybe<StringOperationFilterInput>;
  /** SenderName */
  senderName?: InputMaybe<StringOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
};

/** AutoMail */
export type Wf_TrialModeLeads_AutoMailInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** MailBcc */
  mailBcc?: InputMaybe<Scalars['String']['input']>;
  /** MailCc */
  mailCc?: InputMaybe<Scalars['String']['input']>;
  /** MailContent */
  mailContent?: InputMaybe<Scalars['String']['input']>;
  /** [ID] MailId */
  mailId?: InputMaybe<Scalars['Int']['input']>;
  /** MailStatus */
  mailStatus?: InputMaybe<Scalars['String']['input']>;
  /** MailSubject */
  mailSubject?: InputMaybe<Scalars['String']['input']>;
  /** MailTo */
  mailTo?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** SenderAddress */
  senderAddress?: InputMaybe<Scalars['String']['input']>;
  /** SenderName */
  senderName?: InputMaybe<Scalars['String']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AutoMail */
export type Wf_TrialModeLeads_AutoMailSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** MailBcc */
  mailBcc?: InputMaybe<SortEnumType>;
  /** MailCc */
  mailCc?: InputMaybe<SortEnumType>;
  /** MailContent */
  mailContent?: InputMaybe<SortEnumType>;
  /** [ID] MailId */
  mailId?: InputMaybe<SortEnumType>;
  /** MailStatus */
  mailStatus?: InputMaybe<SortEnumType>;
  /** MailSubject */
  mailSubject?: InputMaybe<SortEnumType>;
  /** MailTo */
  mailTo?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** SenderAddress */
  senderAddress?: InputMaybe<SortEnumType>;
  /** SenderName */
  senderName?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
};

/** CallToAction */
export type Wf_TrialModeLeads_CallToAction = Node & {
  __typename?: 'WF_TrialModeLeads_CallToAction';
  /** ButtonLinksTo */
  buttonLinksTo?: Maybe<Scalars['String']['output']>;
  /** ButtonText */
  buttonText?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] CallToActionContents */
  callToActionContents?: Maybe<Array<Maybe<Wf_TrialModeLeads_CallToActionContent>>>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] CtaExperienceType */
  ctaExperienceType?: Maybe<Wf_TrialModeLeads_CallToActionExperienceType>;
  /** [NOT NULL] CtaExperienceTypeId */
  ctaExperienceTypeId?: Maybe<Scalars['Int']['output']>;
  /** [ID] CtaId */
  id: Scalars['ID']['output'];
  /** ImageCharacter */
  imageCharacter?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** PhotoFileName */
  photoFileName?: Maybe<Scalars['String']['output']>;
  /** Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
  /** Template */
  template?: Maybe<Scalars['String']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** VideoUrl */
  videoUrl?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_CallToActionConnection = {
  __typename?: 'WF_TrialModeLeads_CallToActionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_CallToActionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_CallToAction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** CallToActionContent */
export type Wf_TrialModeLeads_CallToActionContent = Node & {
  __typename?: 'WF_TrialModeLeads_CallToActionContent';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] Cta */
  cta?: Maybe<Wf_TrialModeLeads_CallToAction>;
  /** CtaContent */
  ctaContent?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] CtaId */
  ctaId?: Maybe<Scalars['Int']['output']>;
  /** CtaTitle */
  ctaTitle?: Maybe<Scalars['String']['output']>;
  /** [ID] CtaContentId */
  id: Scalars['ID']['output'];
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_CallToActionContentConnection = {
  __typename?: 'WF_TrialModeLeads_CallToActionContentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_CallToActionContentEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_CallToActionContent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_CallToActionContentEdge = {
  __typename?: 'WF_TrialModeLeads_CallToActionContentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_CallToActionContent;
};

/** CallToActionContent */
export type Wf_TrialModeLeads_CallToActionContentFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionContentFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** [NAVIGATION] Cta */
  cta?: InputMaybe<Wf_TrialModeLeads_CallToActionFilterInput>;
  /** CtaContent */
  ctaContent?: InputMaybe<StringOperationFilterInput>;
  /** [ID] CtaContentId */
  ctaContentId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] CtaId */
  ctaId?: InputMaybe<IntOperationFilterInput>;
  /** CtaTitle */
  ctaTitle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionContentFilterInput>>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
};

/** CallToActionContent */
export type Wf_TrialModeLeads_CallToActionContentInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] Cta */
  cta?: InputMaybe<Wf_TrialModeLeads_CallToActionInput>;
  /** CtaContent */
  ctaContent?: InputMaybe<Scalars['String']['input']>;
  /** [ID] CtaContentId */
  ctaContentId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] CtaId */
  ctaId?: InputMaybe<Scalars['Int']['input']>;
  /** CtaTitle */
  ctaTitle?: InputMaybe<Scalars['String']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
};

/** CallToActionContent */
export type Wf_TrialModeLeads_CallToActionContentSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] Cta */
  cta?: InputMaybe<Wf_TrialModeLeads_CallToActionSortInput>;
  /** CtaContent */
  ctaContent?: InputMaybe<SortEnumType>;
  /** [ID] CtaContentId */
  ctaContentId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] CtaId */
  ctaId?: InputMaybe<SortEnumType>;
  /** CtaTitle */
  ctaTitle?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_CallToActionEdge = {
  __typename?: 'WF_TrialModeLeads_CallToActionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_CallToAction;
};

/** CallToActionExperienceType */
export type Wf_TrialModeLeads_CallToActionExperienceType = Node & {
  __typename?: 'WF_TrialModeLeads_CallToActionExperienceType';
  /** [COLLECTION] CallToActions */
  callToActions?: Maybe<Array<Maybe<Wf_TrialModeLeads_CallToAction>>>;
  /** ExperienceDescription */
  experienceDescription?: Maybe<Scalars['String']['output']>;
  /** ExperienceName */
  experienceName?: Maybe<Scalars['String']['output']>;
  /** [ID] CtaExperienceTypeId */
  id: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_CallToActionExperienceTypeConnection = {
  __typename?: 'WF_TrialModeLeads_CallToActionExperienceTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_CallToActionExperienceTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_CallToActionExperienceType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_CallToActionExperienceTypeEdge = {
  __typename?: 'WF_TrialModeLeads_CallToActionExperienceTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_CallToActionExperienceType;
};

/** CallToActionExperienceType */
export type Wf_TrialModeLeads_CallToActionExperienceTypeFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionExperienceTypeFilterInput>>;
  /** [COLLECTION] CallToActions */
  callToActions?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_CallToActionFilterInput>;
  /** [ID] CtaExperienceTypeId */
  ctaExperienceTypeId?: InputMaybe<IntOperationFilterInput>;
  /** ExperienceDescription */
  experienceDescription?: InputMaybe<StringOperationFilterInput>;
  /** ExperienceName */
  experienceName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionExperienceTypeFilterInput>>;
};

/** CallToActionExperienceType */
export type Wf_TrialModeLeads_CallToActionExperienceTypeInput = {
  /** [COLLECTION] CallToActions */
  callToActions?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_CallToActionInput>>>;
  /** [ID] CtaExperienceTypeId */
  ctaExperienceTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** ExperienceDescription */
  experienceDescription?: InputMaybe<Scalars['String']['input']>;
  /** ExperienceName */
  experienceName?: InputMaybe<Scalars['String']['input']>;
};

/** CallToActionExperienceType */
export type Wf_TrialModeLeads_CallToActionExperienceTypeSortInput = {
  /** [ID] CtaExperienceTypeId */
  ctaExperienceTypeId?: InputMaybe<SortEnumType>;
  /** ExperienceDescription */
  experienceDescription?: InputMaybe<SortEnumType>;
  /** ExperienceName */
  experienceName?: InputMaybe<SortEnumType>;
};

/** CallToAction */
export type Wf_TrialModeLeads_CallToActionFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionFilterInput>>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<StringOperationFilterInput>;
  /** ButtonText */
  buttonText?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] CallToActionContents */
  callToActionContents?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_CallToActionContentFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** [NAVIGATION] CtaExperienceType */
  ctaExperienceType?: InputMaybe<Wf_TrialModeLeads_CallToActionExperienceTypeFilterInput>;
  /** [NOT NULL] CtaExperienceTypeId */
  ctaExperienceTypeId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] CtaId */
  ctaId?: InputMaybe<IntOperationFilterInput>;
  /** ImageCharacter */
  imageCharacter?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionFilterInput>>;
  /** PhotoFileName */
  photoFileName?: InputMaybe<StringOperationFilterInput>;
  /** Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
  /** Template */
  template?: InputMaybe<StringOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** VideoUrl */
  videoUrl?: InputMaybe<StringOperationFilterInput>;
};

/** CallToAction */
export type Wf_TrialModeLeads_CallToActionInput = {
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<Scalars['String']['input']>;
  /** ButtonText */
  buttonText?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] CallToActionContents */
  callToActionContents?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_CallToActionContentInput>>>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] CtaExperienceType */
  ctaExperienceType?: InputMaybe<Wf_TrialModeLeads_CallToActionExperienceTypeInput>;
  /** [NOT NULL] CtaExperienceTypeId */
  ctaExperienceTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] CtaId */
  ctaId?: InputMaybe<Scalars['Int']['input']>;
  /** ImageCharacter */
  imageCharacter?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** PhotoFileName */
  photoFileName?: InputMaybe<Scalars['String']['input']>;
  /** Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
  /** Template */
  template?: InputMaybe<Scalars['String']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** VideoUrl */
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

/** CallToAction */
export type Wf_TrialModeLeads_CallToActionSortInput = {
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<SortEnumType>;
  /** ButtonText */
  buttonText?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] CtaExperienceType */
  ctaExperienceType?: InputMaybe<Wf_TrialModeLeads_CallToActionExperienceTypeSortInput>;
  /** [NOT NULL] CtaExperienceTypeId */
  ctaExperienceTypeId?: InputMaybe<SortEnumType>;
  /** [ID] CtaId */
  ctaId?: InputMaybe<SortEnumType>;
  /** ImageCharacter */
  imageCharacter?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** PhotoFileName */
  photoFileName?: InputMaybe<SortEnumType>;
  /** Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
  /** Template */
  template?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
  /** VideoUrl */
  videoUrl?: InputMaybe<SortEnumType>;
};

/** DesignImage */
export type Wf_TrialModeLeads_DesignImage = Node & {
  __typename?: 'WF_TrialModeLeads_DesignImage';
  /** [ID] DesignImageId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** ImageType */
  imageType?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_DesignImageConnection = {
  __typename?: 'WF_TrialModeLeads_DesignImageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_DesignImageEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_DesignImage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_DesignImageEdge = {
  __typename?: 'WF_TrialModeLeads_DesignImageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_DesignImage;
};

/** DesignImage */
export type Wf_TrialModeLeads_DesignImageFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_DesignImageFilterInput>>;
  /** [ID] DesignImageId */
  designImageId?: InputMaybe<IntOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  /** ImageType */
  imageType?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_DesignImageFilterInput>>;
};

/** DesignImage */
export type Wf_TrialModeLeads_DesignImageInput = {
  /** [ID] DesignImageId */
  designImageId?: InputMaybe<Scalars['Int']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** ImageType */
  imageType?: InputMaybe<Scalars['String']['input']>;
};

/** DesignImage */
export type Wf_TrialModeLeads_DesignImageSortInput = {
  /** [ID] DesignImageId */
  designImageId?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** ImageType */
  imageType?: InputMaybe<SortEnumType>;
};

/** FeaturedEvent */
export type Wf_TrialModeLeads_FeaturedEvent = Node & {
  __typename?: 'WF_TrialModeLeads_FeaturedEvent';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** EndDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] FeaturedEventId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** NavigationUrl */
  navigationUrl?: Maybe<Scalars['String']['output']>;
  /** SortId */
  sortId?: Maybe<Scalars['Int']['output']>;
  /** StartDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_FeaturedEventConnection = {
  __typename?: 'WF_TrialModeLeads_FeaturedEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_FeaturedEventEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_FeaturedEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_FeaturedEventEdge = {
  __typename?: 'WF_TrialModeLeads_FeaturedEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_FeaturedEvent;
};

/** FeaturedEvent */
export type Wf_TrialModeLeads_FeaturedEventFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_FeaturedEventFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** EndDate */
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] FeaturedEventId */
  featuredEventId?: InputMaybe<IntOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** NavigationUrl */
  navigationUrl?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_FeaturedEventFilterInput>>;
  /** SortId */
  sortId?: InputMaybe<IntOperationFilterInput>;
  /** StartDate */
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
};

/** FeaturedEvent */
export type Wf_TrialModeLeads_FeaturedEventInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** EndDate */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] FeaturedEventId */
  featuredEventId?: InputMaybe<Scalars['Int']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** NavigationUrl */
  navigationUrl?: InputMaybe<Scalars['String']['input']>;
  /** SortId */
  sortId?: InputMaybe<Scalars['Int']['input']>;
  /** StartDate */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
};

/** FeaturedEvent */
export type Wf_TrialModeLeads_FeaturedEventSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** EndDate */
  endDate?: InputMaybe<SortEnumType>;
  /** [ID] FeaturedEventId */
  featuredEventId?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** NavigationUrl */
  navigationUrl?: InputMaybe<SortEnumType>;
  /** SortId */
  sortId?: InputMaybe<SortEnumType>;
  /** StartDate */
  startDate?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
};

/** GraphqlAccess */
export type Wf_TrialModeLeads_GraphqlAccess = Node & {
  __typename?: 'WF_TrialModeLeads_GraphqlAccess';
  /** AccessSchema */
  accessSchema?: Maybe<Scalars['String']['output']>;
  /** AccessSchemaHash */
  accessSchemaHash?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Database */
  database?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: Maybe<Wf_TrialModeLeads_GraphqlSchema>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: Maybe<Scalars['UUID']['output']>;
  /** [ID] GraphqlAccessId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] ProjectId */
  projectId?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Server */
  server?: Maybe<Scalars['String']['output']>;
  /** UpdateTime */
  updateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_GraphqlAccessConnection = {
  __typename?: 'WF_TrialModeLeads_GraphqlAccessConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_GraphqlAccessEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_GraphqlAccess>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_GraphqlAccessEdge = {
  __typename?: 'WF_TrialModeLeads_GraphqlAccessEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_GraphqlAccess;
};

/** GraphqlAccess */
export type Wf_TrialModeLeads_GraphqlAccessFilterInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<StringOperationFilterInput>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlAccessFilterInput>>;
  /** [NOT NULL] Database */
  database?: InputMaybe<StringOperationFilterInput>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<UuidOperationFilterInput>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Wf_TrialModeLeads_GraphqlSchemaFilterInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlAccessFilterInput>>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Server */
  server?: InputMaybe<StringOperationFilterInput>;
  /** UpdateTime */
  updateTime?: InputMaybe<DateTimeOperationFilterInput>;
};

/** GraphqlAccess */
export type Wf_TrialModeLeads_GraphqlAccessInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<Scalars['String']['input']>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Database */
  database?: InputMaybe<Scalars['String']['input']>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<Scalars['UUID']['input']>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Wf_TrialModeLeads_GraphqlSchemaInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<Scalars['UUID']['input']>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Server */
  server?: InputMaybe<Scalars['String']['input']>;
  /** UpdateTime */
  updateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** GraphqlAccess */
export type Wf_TrialModeLeads_GraphqlAccessSortInput = {
  /** AccessSchema */
  accessSchema?: InputMaybe<SortEnumType>;
  /** AccessSchemaHash */
  accessSchemaHash?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Database */
  database?: InputMaybe<SortEnumType>;
  /** [ID] GraphqlAccessId */
  graphqlAccessId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] GraphqlSchema */
  graphqlSchema?: InputMaybe<Wf_TrialModeLeads_GraphqlSchemaSortInput>;
  /** [NOT NULL] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ProjectId */
  projectId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Server */
  server?: InputMaybe<SortEnumType>;
  /** UpdateTime */
  updateTime?: InputMaybe<SortEnumType>;
};

/** GraphqlSchema */
export type Wf_TrialModeLeads_GraphqlSchema = Node & {
  __typename?: 'WF_TrialModeLeads_GraphqlSchema';
  /** [NOT NULL] Database */
  database?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: Maybe<Array<Maybe<Wf_TrialModeLeads_GraphqlAccess>>>;
  /** [ID] GraphqlSchemaId */
  id: Scalars['ID']['output'];
  /** Schema */
  schema?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Server */
  server?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_GraphqlSchemaConnection = {
  __typename?: 'WF_TrialModeLeads_GraphqlSchemaConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_GraphqlSchemaEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_GraphqlSchema>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_GraphqlSchemaEdge = {
  __typename?: 'WF_TrialModeLeads_GraphqlSchemaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_GraphqlSchema;
};

/** GraphqlSchema */
export type Wf_TrialModeLeads_GraphqlSchemaFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlSchemaFilterInput>>;
  /** [NOT NULL] Database */
  database?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_GraphqlAccessFilterInput>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlSchemaFilterInput>>;
  /** Schema */
  schema?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] Server */
  server?: InputMaybe<StringOperationFilterInput>;
};

/** GraphqlSchema */
export type Wf_TrialModeLeads_GraphqlSchemaInput = {
  /** [NOT NULL] Database */
  database?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] GraphqlAccesses */
  graphqlAccesses?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_GraphqlAccessInput>>>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<Scalars['UUID']['input']>;
  /** Schema */
  schema?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Server */
  server?: InputMaybe<Scalars['String']['input']>;
};

/** GraphqlSchema */
export type Wf_TrialModeLeads_GraphqlSchemaSortInput = {
  /** [NOT NULL] Database */
  database?: InputMaybe<SortEnumType>;
  /** [ID] GraphqlSchemaId */
  graphqlSchemaId?: InputMaybe<SortEnumType>;
  /** Schema */
  schema?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Server */
  server?: InputMaybe<SortEnumType>;
};

/** Installation */
export type Wf_TrialModeLeads_Installation = Node & {
  __typename?: 'WF_TrialModeLeads_Installation';
  /** [ID] Seq */
  id: Scalars['ID']['output'];
  /** InstallDate */
  installDate?: Maybe<Scalars['DateTime']['output']>;
  /** InstallationCampaignIds */
  installationCampaignIds?: Maybe<Scalars['String']['output']>;
  /** Numbers */
  numbers?: Maybe<Scalars['Short']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_InstallationConnection = {
  __typename?: 'WF_TrialModeLeads_InstallationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_InstallationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_Installation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_InstallationEdge = {
  __typename?: 'WF_TrialModeLeads_InstallationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_Installation;
};

/** Installation */
export type Wf_TrialModeLeads_InstallationFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_InstallationFilterInput>>;
  /** InstallDate */
  installDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** InstallationCampaignIds */
  installationCampaignIds?: InputMaybe<StringOperationFilterInput>;
  /** Numbers */
  numbers?: InputMaybe<ShortOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_InstallationFilterInput>>;
  /** [ID] Seq */
  seq?: InputMaybe<IntOperationFilterInput>;
};

/** Installation */
export type Wf_TrialModeLeads_InstallationInput = {
  /** InstallDate */
  installDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** InstallationCampaignIds */
  installationCampaignIds?: InputMaybe<Scalars['String']['input']>;
  /** Numbers */
  numbers?: InputMaybe<Scalars['Short']['input']>;
  /** [ID] Seq */
  seq?: InputMaybe<Scalars['Int']['input']>;
};

/** InstallationLog */
export type Wf_TrialModeLeads_InstallationLog = Node & {
  __typename?: 'WF_TrialModeLeads_InstallationLog';
  /** CreationDate */
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  /** DeviceType */
  deviceType?: Maybe<Scalars['String']['output']>;
  /** [ID] InstallationLogId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** SourceCode */
  sourceCode?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_InstallationLogConnection = {
  __typename?: 'WF_TrialModeLeads_InstallationLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_InstallationLogEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_InstallationLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_InstallationLogEdge = {
  __typename?: 'WF_TrialModeLeads_InstallationLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_InstallationLog;
};

/** InstallationLog */
export type Wf_TrialModeLeads_InstallationLogFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_InstallationLogFilterInput>>;
  /** CreationDate */
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** DeviceType */
  deviceType?: InputMaybe<StringOperationFilterInput>;
  /** [ID] InstallationLogId */
  installationLogId?: InputMaybe<IntOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_InstallationLogFilterInput>>;
  /** SourceCode */
  sourceCode?: InputMaybe<StringOperationFilterInput>;
};

/** InstallationLog */
export type Wf_TrialModeLeads_InstallationLogInput = {
  /** CreationDate */
  creationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** DeviceType */
  deviceType?: InputMaybe<Scalars['String']['input']>;
  /** [ID] InstallationLogId */
  installationLogId?: InputMaybe<Scalars['Int']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** SourceCode */
  sourceCode?: InputMaybe<Scalars['String']['input']>;
};

/** InstallationLog */
export type Wf_TrialModeLeads_InstallationLogSortInput = {
  /** CreationDate */
  creationDate?: InputMaybe<SortEnumType>;
  /** DeviceType */
  deviceType?: InputMaybe<SortEnumType>;
  /** [ID] InstallationLogId */
  installationLogId?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** SourceCode */
  sourceCode?: InputMaybe<SortEnumType>;
};

/** Installation */
export type Wf_TrialModeLeads_InstallationSortInput = {
  /** InstallDate */
  installDate?: InputMaybe<SortEnumType>;
  /** InstallationCampaignIds */
  installationCampaignIds?: InputMaybe<SortEnumType>;
  /** Numbers */
  numbers?: InputMaybe<SortEnumType>;
  /** [ID] Seq */
  seq?: InputMaybe<SortEnumType>;
};

/** JourneyCard */
export type Wf_TrialModeLeads_JourneyCard = Node & {
  __typename?: 'WF_TrialModeLeads_JourneyCard';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** ExperienceCharacter */
  experienceCharacter?: Maybe<Scalars['String']['output']>;
  /** ExperienceNavigationUrl */
  experienceNavigationUrl?: Maybe<Scalars['String']['output']>;
  /** ExperienceNavigationWording */
  experienceNavigationWording?: Maybe<Scalars['String']['output']>;
  /** ExperiencePromotionWording */
  experiencePromotionWording?: Maybe<Scalars['String']['output']>;
  /** Greeting */
  greeting?: Maybe<Scalars['String']['output']>;
  /** [ID] JourneyCardId */
  id: Scalars['ID']['output'];
  /** IsDisplayVideo */
  isDisplayVideo?: Maybe<Scalars['Boolean']['output']>;
  /** [NAVIGATION] JourneyExperienceType */
  journeyExperienceType?: Maybe<Wf_TrialModeLeads_JourneyExperienceType>;
  /** [NOT NULL] JourneyExperienceTypeId */
  journeyExperienceTypeId?: Maybe<Scalars['Int']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** VideoUrl */
  videoUrl?: Maybe<Scalars['String']['output']>;
  /** VideoWording */
  videoWording?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_JourneyCardConnection = {
  __typename?: 'WF_TrialModeLeads_JourneyCardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_JourneyCardEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_JourneyCard>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_JourneyCardEdge = {
  __typename?: 'WF_TrialModeLeads_JourneyCardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_JourneyCard;
};

/** JourneyCard */
export type Wf_TrialModeLeads_JourneyCardFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_JourneyCardFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** ExperienceCharacter */
  experienceCharacter?: InputMaybe<StringOperationFilterInput>;
  /** ExperienceNavigationUrl */
  experienceNavigationUrl?: InputMaybe<StringOperationFilterInput>;
  /** ExperienceNavigationWording */
  experienceNavigationWording?: InputMaybe<StringOperationFilterInput>;
  /** ExperiencePromotionWording */
  experiencePromotionWording?: InputMaybe<StringOperationFilterInput>;
  /** Greeting */
  greeting?: InputMaybe<StringOperationFilterInput>;
  /** IsDisplayVideo */
  isDisplayVideo?: InputMaybe<BooleanOperationFilterInput>;
  /** [ID] JourneyCardId */
  journeyCardId?: InputMaybe<IntOperationFilterInput>;
  /** [NAVIGATION] JourneyExperienceType */
  journeyExperienceType?: InputMaybe<Wf_TrialModeLeads_JourneyExperienceTypeFilterInput>;
  /** [NOT NULL] JourneyExperienceTypeId */
  journeyExperienceTypeId?: InputMaybe<IntOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_JourneyCardFilterInput>>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** VideoUrl */
  videoUrl?: InputMaybe<StringOperationFilterInput>;
  /** VideoWording */
  videoWording?: InputMaybe<StringOperationFilterInput>;
};

/** JourneyCard */
export type Wf_TrialModeLeads_JourneyCardInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** ExperienceCharacter */
  experienceCharacter?: InputMaybe<Scalars['String']['input']>;
  /** ExperienceNavigationUrl */
  experienceNavigationUrl?: InputMaybe<Scalars['String']['input']>;
  /** ExperienceNavigationWording */
  experienceNavigationWording?: InputMaybe<Scalars['String']['input']>;
  /** ExperiencePromotionWording */
  experiencePromotionWording?: InputMaybe<Scalars['String']['input']>;
  /** Greeting */
  greeting?: InputMaybe<Scalars['String']['input']>;
  /** IsDisplayVideo */
  isDisplayVideo?: InputMaybe<Scalars['Boolean']['input']>;
  /** [ID] JourneyCardId */
  journeyCardId?: InputMaybe<Scalars['Int']['input']>;
  /** [NAVIGATION] JourneyExperienceType */
  journeyExperienceType?: InputMaybe<Wf_TrialModeLeads_JourneyExperienceTypeInput>;
  /** [NOT NULL] JourneyExperienceTypeId */
  journeyExperienceTypeId?: InputMaybe<Scalars['Int']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** VideoUrl */
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  /** VideoWording */
  videoWording?: InputMaybe<Scalars['String']['input']>;
};

/** JourneyCard */
export type Wf_TrialModeLeads_JourneyCardSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** ExperienceCharacter */
  experienceCharacter?: InputMaybe<SortEnumType>;
  /** ExperienceNavigationUrl */
  experienceNavigationUrl?: InputMaybe<SortEnumType>;
  /** ExperienceNavigationWording */
  experienceNavigationWording?: InputMaybe<SortEnumType>;
  /** ExperiencePromotionWording */
  experiencePromotionWording?: InputMaybe<SortEnumType>;
  /** Greeting */
  greeting?: InputMaybe<SortEnumType>;
  /** IsDisplayVideo */
  isDisplayVideo?: InputMaybe<SortEnumType>;
  /** [ID] JourneyCardId */
  journeyCardId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] JourneyExperienceType */
  journeyExperienceType?: InputMaybe<Wf_TrialModeLeads_JourneyExperienceTypeSortInput>;
  /** [NOT NULL] JourneyExperienceTypeId */
  journeyExperienceTypeId?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
  /** VideoUrl */
  videoUrl?: InputMaybe<SortEnumType>;
  /** VideoWording */
  videoWording?: InputMaybe<SortEnumType>;
};

/** JourneyExperienceType */
export type Wf_TrialModeLeads_JourneyExperienceType = Node & {
  __typename?: 'WF_TrialModeLeads_JourneyExperienceType';
  /** ExperienceDescription */
  experienceDescription?: Maybe<Scalars['String']['output']>;
  /** ExperienceName */
  experienceName?: Maybe<Scalars['String']['output']>;
  /** [ID] JourneyExperienceTypeId */
  id: Scalars['ID']['output'];
  /** [COLLECTION] JourneyCards */
  journeyCards?: Maybe<Array<Maybe<Wf_TrialModeLeads_JourneyCard>>>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_JourneyExperienceTypeConnection = {
  __typename?: 'WF_TrialModeLeads_JourneyExperienceTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_JourneyExperienceTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_JourneyExperienceType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_JourneyExperienceTypeEdge = {
  __typename?: 'WF_TrialModeLeads_JourneyExperienceTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_JourneyExperienceType;
};

/** JourneyExperienceType */
export type Wf_TrialModeLeads_JourneyExperienceTypeFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_JourneyExperienceTypeFilterInput>>;
  /** ExperienceDescription */
  experienceDescription?: InputMaybe<StringOperationFilterInput>;
  /** ExperienceName */
  experienceName?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] JourneyCards */
  journeyCards?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_JourneyCardFilterInput>;
  /** [ID] JourneyExperienceTypeId */
  journeyExperienceTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_JourneyExperienceTypeFilterInput>>;
};

/** JourneyExperienceType */
export type Wf_TrialModeLeads_JourneyExperienceTypeInput = {
  /** ExperienceDescription */
  experienceDescription?: InputMaybe<Scalars['String']['input']>;
  /** ExperienceName */
  experienceName?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] JourneyCards */
  journeyCards?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_JourneyCardInput>>>;
  /** [ID] JourneyExperienceTypeId */
  journeyExperienceTypeId?: InputMaybe<Scalars['Int']['input']>;
};

/** JourneyExperienceType */
export type Wf_TrialModeLeads_JourneyExperienceTypeSortInput = {
  /** ExperienceDescription */
  experienceDescription?: InputMaybe<SortEnumType>;
  /** ExperienceName */
  experienceName?: InputMaybe<SortEnumType>;
  /** [ID] JourneyExperienceTypeId */
  journeyExperienceTypeId?: InputMaybe<SortEnumType>;
};

export type Wf_TrialModeLeads_Mutation_Add = {
  __typename?: 'WF_TrialModeLeads_Mutation_Add';
  autoMail?: Maybe<Wf_TrialModeLeads_AutoMail>;
  callToAction?: Maybe<Wf_TrialModeLeads_CallToAction>;
  callToActionContent?: Maybe<Wf_TrialModeLeads_CallToActionContent>;
  callToActionExperienceType?: Maybe<Wf_TrialModeLeads_CallToActionExperienceType>;
  designImage?: Maybe<Wf_TrialModeLeads_DesignImage>;
  featuredEvent?: Maybe<Wf_TrialModeLeads_FeaturedEvent>;
  graphqlAccess?: Maybe<Wf_TrialModeLeads_GraphqlAccess>;
  graphqlSchema?: Maybe<Wf_TrialModeLeads_GraphqlSchema>;
  installation?: Maybe<Wf_TrialModeLeads_Installation>;
  installationLog?: Maybe<Wf_TrialModeLeads_InstallationLog>;
  journeyCard?: Maybe<Wf_TrialModeLeads_JourneyCard>;
  journeyExperienceType?: Maybe<Wf_TrialModeLeads_JourneyExperienceType>;
  notification?: Maybe<Wf_TrialModeLeads_Notification>;
  notificationMember?: Maybe<Wf_TrialModeLeads_NotificationMember>;
  notificationToSegmentation?: Maybe<Wf_TrialModeLeads_NotificationToSegmentation>;
  notificationType?: Maybe<Wf_TrialModeLeads_NotificationType>;
  parentTip?: Maybe<Wf_TrialModeLeads_ParentTip>;
  productCategory?: Maybe<Wf_TrialModeLeads_ProductCategory>;
  productSong?: Maybe<Wf_TrialModeLeads_ProductSong>;
  productSongAndMarket?: Maybe<Wf_TrialModeLeads_ProductSongAndMarket>;
  productVideo?: Maybe<Wf_TrialModeLeads_ProductVideo>;
  productVideoAndMarket?: Maybe<Wf_TrialModeLeads_ProductVideoAndMarket>;
  regularEvent?: Maybe<Wf_TrialModeLeads_RegularEvent>;
  tip?: Maybe<Wf_TrialModeLeads_Tip>;
  trialCampaignUrl?: Maybe<Wf_TrialModeLeads_TrialCampaignUrl>;
  trialMailLog?: Maybe<Wf_TrialModeLeads_TrialMailLog>;
  trialMailSetting?: Maybe<Wf_TrialModeLeads_TrialMailSetting>;
  trialMessageSetting?: Maybe<Wf_TrialModeLeads_TrialMessageSetting>;
  trialOneTimeToken?: Maybe<Wf_TrialModeLeads_TrialOneTimeToken>;
  trialPushType?: Maybe<Wf_TrialModeLeads_TrialPushType>;
  trialSegmentation?: Maybe<Wf_TrialModeLeads_TrialSegmentation>;
  trialSetting?: Maybe<Wf_TrialModeLeads_TrialSetting>;
  trialToken?: Maybe<Wf_TrialModeLeads_TrialToken>;
  trialTrackingLog?: Maybe<Wf_TrialModeLeads_TrialTrackingLog>;
  trialUser?: Maybe<Wf_TrialModeLeads_TrialUser>;
  trialUserMessageLog?: Maybe<Wf_TrialModeLeads_TrialUserMessageLog>;
  trialUserNotification?: Maybe<Wf_TrialModeLeads_TrialUserNotification>;
  trialUserSegmentationLog?: Maybe<Wf_TrialModeLeads_TrialUserSegmentationLog>;
};

export type Wf_TrialModeLeads_Mutation_AddInput = {
  autoMail?: InputMaybe<Wf_TrialModeLeads_AutoMailInput>;
  callToAction?: InputMaybe<Wf_TrialModeLeads_CallToActionInput>;
  callToActionContent?: InputMaybe<Wf_TrialModeLeads_CallToActionContentInput>;
  callToActionExperienceType?: InputMaybe<Wf_TrialModeLeads_CallToActionExperienceTypeInput>;
  designImage?: InputMaybe<Wf_TrialModeLeads_DesignImageInput>;
  featuredEvent?: InputMaybe<Wf_TrialModeLeads_FeaturedEventInput>;
  graphqlAccess?: InputMaybe<Wf_TrialModeLeads_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Wf_TrialModeLeads_GraphqlSchemaInput>;
  installation?: InputMaybe<Wf_TrialModeLeads_InstallationInput>;
  installationLog?: InputMaybe<Wf_TrialModeLeads_InstallationLogInput>;
  journeyCard?: InputMaybe<Wf_TrialModeLeads_JourneyCardInput>;
  journeyExperienceType?: InputMaybe<Wf_TrialModeLeads_JourneyExperienceTypeInput>;
  notification?: InputMaybe<Wf_TrialModeLeads_NotificationInput>;
  notificationMember?: InputMaybe<Wf_TrialModeLeads_NotificationMemberInput>;
  notificationToSegmentation?: InputMaybe<Wf_TrialModeLeads_NotificationToSegmentationInput>;
  notificationType?: InputMaybe<Wf_TrialModeLeads_NotificationTypeInput>;
  parentTip?: InputMaybe<Wf_TrialModeLeads_ParentTipInput>;
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategoryInput>;
  productSong?: InputMaybe<Wf_TrialModeLeads_ProductSongInput>;
  productSongAndMarket?: InputMaybe<Wf_TrialModeLeads_ProductSongAndMarketInput>;
  productVideo?: InputMaybe<Wf_TrialModeLeads_ProductVideoInput>;
  productVideoAndMarket?: InputMaybe<Wf_TrialModeLeads_ProductVideoAndMarketInput>;
  regularEvent?: InputMaybe<Wf_TrialModeLeads_RegularEventInput>;
  tip?: InputMaybe<Wf_TrialModeLeads_TipInput>;
  trialCampaignUrl?: InputMaybe<Wf_TrialModeLeads_TrialCampaignUrlInput>;
  trialMailLog?: InputMaybe<Wf_TrialModeLeads_TrialMailLogInput>;
  trialMailSetting?: InputMaybe<Wf_TrialModeLeads_TrialMailSettingInput>;
  trialMessageSetting?: InputMaybe<Wf_TrialModeLeads_TrialMessageSettingInput>;
  trialOneTimeToken?: InputMaybe<Wf_TrialModeLeads_TrialOneTimeTokenInput>;
  trialPushType?: InputMaybe<Wf_TrialModeLeads_TrialPushTypeInput>;
  trialSegmentation?: InputMaybe<Wf_TrialModeLeads_TrialSegmentationInput>;
  trialSetting?: InputMaybe<Wf_TrialModeLeads_TrialSettingInput>;
  trialToken?: InputMaybe<Wf_TrialModeLeads_TrialTokenInput>;
  trialTrackingLog?: InputMaybe<Wf_TrialModeLeads_TrialTrackingLogInput>;
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserInput>;
  trialUserMessageLog?: InputMaybe<Wf_TrialModeLeads_TrialUserMessageLogInput>;
  trialUserNotification?: InputMaybe<Wf_TrialModeLeads_TrialUserNotificationInput>;
  trialUserSegmentationLog?: InputMaybe<Wf_TrialModeLeads_TrialUserSegmentationLogInput>;
};

export type Wf_TrialModeLeads_Mutation_AddRange = {
  __typename?: 'WF_TrialModeLeads_Mutation_AddRange';
  autoMails?: Maybe<Array<Wf_TrialModeLeads_AutoMail>>;
  callToActionContents?: Maybe<Array<Wf_TrialModeLeads_CallToActionContent>>;
  callToActionExperienceTypes?: Maybe<Array<Wf_TrialModeLeads_CallToActionExperienceType>>;
  callToActions?: Maybe<Array<Wf_TrialModeLeads_CallToAction>>;
  designImages?: Maybe<Array<Wf_TrialModeLeads_DesignImage>>;
  featuredEvents?: Maybe<Array<Wf_TrialModeLeads_FeaturedEvent>>;
  graphqlAccesses?: Maybe<Array<Wf_TrialModeLeads_GraphqlAccess>>;
  graphqlSchemas?: Maybe<Array<Wf_TrialModeLeads_GraphqlSchema>>;
  installationLogs?: Maybe<Array<Wf_TrialModeLeads_InstallationLog>>;
  installations?: Maybe<Array<Wf_TrialModeLeads_Installation>>;
  journeyCards?: Maybe<Array<Wf_TrialModeLeads_JourneyCard>>;
  journeyExperienceTypes?: Maybe<Array<Wf_TrialModeLeads_JourneyExperienceType>>;
  notificationMembers?: Maybe<Array<Wf_TrialModeLeads_NotificationMember>>;
  notificationToSegmentations?: Maybe<Array<Wf_TrialModeLeads_NotificationToSegmentation>>;
  notificationTypes?: Maybe<Array<Wf_TrialModeLeads_NotificationType>>;
  notifications?: Maybe<Array<Wf_TrialModeLeads_Notification>>;
  parentTips?: Maybe<Array<Wf_TrialModeLeads_ParentTip>>;
  productCategories?: Maybe<Array<Wf_TrialModeLeads_ProductCategory>>;
  productSongAndMarkets?: Maybe<Array<Wf_TrialModeLeads_ProductSongAndMarket>>;
  productSongs?: Maybe<Array<Wf_TrialModeLeads_ProductSong>>;
  productVideoAndMarkets?: Maybe<Array<Wf_TrialModeLeads_ProductVideoAndMarket>>;
  productVideos?: Maybe<Array<Wf_TrialModeLeads_ProductVideo>>;
  regularEvents?: Maybe<Array<Wf_TrialModeLeads_RegularEvent>>;
  tips?: Maybe<Array<Wf_TrialModeLeads_Tip>>;
  trialCampaignUrls?: Maybe<Array<Wf_TrialModeLeads_TrialCampaignUrl>>;
  trialMailLogs?: Maybe<Array<Wf_TrialModeLeads_TrialMailLog>>;
  trialMailSettings?: Maybe<Array<Wf_TrialModeLeads_TrialMailSetting>>;
  trialMessageSettings?: Maybe<Array<Wf_TrialModeLeads_TrialMessageSetting>>;
  trialOneTimeTokens?: Maybe<Array<Wf_TrialModeLeads_TrialOneTimeToken>>;
  trialPushTypes?: Maybe<Array<Wf_TrialModeLeads_TrialPushType>>;
  trialSegmentations?: Maybe<Array<Wf_TrialModeLeads_TrialSegmentation>>;
  trialSettings?: Maybe<Array<Wf_TrialModeLeads_TrialSetting>>;
  trialTokens?: Maybe<Array<Wf_TrialModeLeads_TrialToken>>;
  trialTrackingLogs?: Maybe<Array<Wf_TrialModeLeads_TrialTrackingLog>>;
  trialUserMessageLogs?: Maybe<Array<Wf_TrialModeLeads_TrialUserMessageLog>>;
  trialUserNotifications?: Maybe<Array<Wf_TrialModeLeads_TrialUserNotification>>;
  trialUserSegmentationLogs?: Maybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLog>>;
  trialUsers?: Maybe<Array<Wf_TrialModeLeads_TrialUser>>;
};

export type Wf_TrialModeLeads_Mutation_AddRangeInput = {
  autoMails?: InputMaybe<Array<Wf_TrialModeLeads_AutoMailInput>>;
  callToActionContents?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionContentInput>>;
  callToActionExperienceTypes?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionExperienceTypeInput>>;
  callToActions?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionInput>>;
  designImages?: InputMaybe<Array<Wf_TrialModeLeads_DesignImageInput>>;
  featuredEvents?: InputMaybe<Array<Wf_TrialModeLeads_FeaturedEventInput>>;
  graphqlAccesses?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlSchemaInput>>;
  installationLogs?: InputMaybe<Array<Wf_TrialModeLeads_InstallationLogInput>>;
  installations?: InputMaybe<Array<Wf_TrialModeLeads_InstallationInput>>;
  journeyCards?: InputMaybe<Array<Wf_TrialModeLeads_JourneyCardInput>>;
  journeyExperienceTypes?: InputMaybe<Array<Wf_TrialModeLeads_JourneyExperienceTypeInput>>;
  notificationMembers?: InputMaybe<Array<Wf_TrialModeLeads_NotificationMemberInput>>;
  notificationToSegmentations?: InputMaybe<Array<Wf_TrialModeLeads_NotificationToSegmentationInput>>;
  notificationTypes?: InputMaybe<Array<Wf_TrialModeLeads_NotificationTypeInput>>;
  notifications?: InputMaybe<Array<Wf_TrialModeLeads_NotificationInput>>;
  parentTips?: InputMaybe<Array<Wf_TrialModeLeads_ParentTipInput>>;
  productCategories?: InputMaybe<Array<Wf_TrialModeLeads_ProductCategoryInput>>;
  productSongAndMarkets?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongAndMarketInput>>;
  productSongs?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongInput>>;
  productVideoAndMarkets?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoAndMarketInput>>;
  productVideos?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoInput>>;
  regularEvents?: InputMaybe<Array<Wf_TrialModeLeads_RegularEventInput>>;
  tips?: InputMaybe<Array<Wf_TrialModeLeads_TipInput>>;
  trialCampaignUrls?: InputMaybe<Array<Wf_TrialModeLeads_TrialCampaignUrlInput>>;
  trialMailLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailLogInput>>;
  trialMailSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailSettingInput>>;
  trialMessageSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialMessageSettingInput>>;
  trialOneTimeTokens?: InputMaybe<Array<Wf_TrialModeLeads_TrialOneTimeTokenInput>>;
  trialPushTypes?: InputMaybe<Array<Wf_TrialModeLeads_TrialPushTypeInput>>;
  trialSegmentations?: InputMaybe<Array<Wf_TrialModeLeads_TrialSegmentationInput>>;
  trialSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialSettingInput>>;
  trialTokens?: InputMaybe<Array<Wf_TrialModeLeads_TrialTokenInput>>;
  trialTrackingLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialTrackingLogInput>>;
  trialUserMessageLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserMessageLogInput>>;
  trialUserNotifications?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserNotificationInput>>;
  trialUserSegmentationLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLogInput>>;
  trialUsers?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserInput>>;
};

export type Wf_TrialModeLeads_Mutation_Payload = {
  __typename?: 'WF_TrialModeLeads_Mutation_Payload';
  addContext?: Maybe<Wf_TrialModeLeads_Mutation_Add>;
  addRangeContext?: Maybe<Wf_TrialModeLeads_Mutation_AddRange>;
  count: Scalars['Int']['output'];
  query: Query;
  updateContext?: Maybe<Wf_TrialModeLeads_Mutation_Update>;
  updateRangeContext?: Maybe<Wf_TrialModeLeads_Mutation_UpdateRange>;
};

export type Wf_TrialModeLeads_Mutation_RemoveInput = {
  autoMail?: InputMaybe<Wf_TrialModeLeads_AutoMailInput>;
  callToAction?: InputMaybe<Wf_TrialModeLeads_CallToActionInput>;
  callToActionContent?: InputMaybe<Wf_TrialModeLeads_CallToActionContentInput>;
  callToActionExperienceType?: InputMaybe<Wf_TrialModeLeads_CallToActionExperienceTypeInput>;
  designImage?: InputMaybe<Wf_TrialModeLeads_DesignImageInput>;
  featuredEvent?: InputMaybe<Wf_TrialModeLeads_FeaturedEventInput>;
  graphqlAccess?: InputMaybe<Wf_TrialModeLeads_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Wf_TrialModeLeads_GraphqlSchemaInput>;
  installation?: InputMaybe<Wf_TrialModeLeads_InstallationInput>;
  installationLog?: InputMaybe<Wf_TrialModeLeads_InstallationLogInput>;
  journeyCard?: InputMaybe<Wf_TrialModeLeads_JourneyCardInput>;
  journeyExperienceType?: InputMaybe<Wf_TrialModeLeads_JourneyExperienceTypeInput>;
  notification?: InputMaybe<Wf_TrialModeLeads_NotificationInput>;
  notificationMember?: InputMaybe<Wf_TrialModeLeads_NotificationMemberInput>;
  notificationToSegmentation?: InputMaybe<Wf_TrialModeLeads_NotificationToSegmentationInput>;
  notificationType?: InputMaybe<Wf_TrialModeLeads_NotificationTypeInput>;
  parentTip?: InputMaybe<Wf_TrialModeLeads_ParentTipInput>;
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategoryInput>;
  productSong?: InputMaybe<Wf_TrialModeLeads_ProductSongInput>;
  productSongAndMarket?: InputMaybe<Wf_TrialModeLeads_ProductSongAndMarketInput>;
  productVideo?: InputMaybe<Wf_TrialModeLeads_ProductVideoInput>;
  productVideoAndMarket?: InputMaybe<Wf_TrialModeLeads_ProductVideoAndMarketInput>;
  regularEvent?: InputMaybe<Wf_TrialModeLeads_RegularEventInput>;
  tip?: InputMaybe<Wf_TrialModeLeads_TipInput>;
  trialCampaignUrl?: InputMaybe<Wf_TrialModeLeads_TrialCampaignUrlInput>;
  trialMailLog?: InputMaybe<Wf_TrialModeLeads_TrialMailLogInput>;
  trialMailSetting?: InputMaybe<Wf_TrialModeLeads_TrialMailSettingInput>;
  trialMessageSetting?: InputMaybe<Wf_TrialModeLeads_TrialMessageSettingInput>;
  trialOneTimeToken?: InputMaybe<Wf_TrialModeLeads_TrialOneTimeTokenInput>;
  trialPushType?: InputMaybe<Wf_TrialModeLeads_TrialPushTypeInput>;
  trialSegmentation?: InputMaybe<Wf_TrialModeLeads_TrialSegmentationInput>;
  trialSetting?: InputMaybe<Wf_TrialModeLeads_TrialSettingInput>;
  trialToken?: InputMaybe<Wf_TrialModeLeads_TrialTokenInput>;
  trialTrackingLog?: InputMaybe<Wf_TrialModeLeads_TrialTrackingLogInput>;
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserInput>;
  trialUserMessageLog?: InputMaybe<Wf_TrialModeLeads_TrialUserMessageLogInput>;
  trialUserNotification?: InputMaybe<Wf_TrialModeLeads_TrialUserNotificationInput>;
  trialUserSegmentationLog?: InputMaybe<Wf_TrialModeLeads_TrialUserSegmentationLogInput>;
};

export type Wf_TrialModeLeads_Mutation_RemoveRangeInput = {
  autoMails?: InputMaybe<Array<Wf_TrialModeLeads_AutoMailInput>>;
  callToActionContents?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionContentInput>>;
  callToActionExperienceTypes?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionExperienceTypeInput>>;
  callToActions?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionInput>>;
  designImages?: InputMaybe<Array<Wf_TrialModeLeads_DesignImageInput>>;
  featuredEvents?: InputMaybe<Array<Wf_TrialModeLeads_FeaturedEventInput>>;
  graphqlAccesses?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlSchemaInput>>;
  installationLogs?: InputMaybe<Array<Wf_TrialModeLeads_InstallationLogInput>>;
  installations?: InputMaybe<Array<Wf_TrialModeLeads_InstallationInput>>;
  journeyCards?: InputMaybe<Array<Wf_TrialModeLeads_JourneyCardInput>>;
  journeyExperienceTypes?: InputMaybe<Array<Wf_TrialModeLeads_JourneyExperienceTypeInput>>;
  notificationMembers?: InputMaybe<Array<Wf_TrialModeLeads_NotificationMemberInput>>;
  notificationToSegmentations?: InputMaybe<Array<Wf_TrialModeLeads_NotificationToSegmentationInput>>;
  notificationTypes?: InputMaybe<Array<Wf_TrialModeLeads_NotificationTypeInput>>;
  notifications?: InputMaybe<Array<Wf_TrialModeLeads_NotificationInput>>;
  parentTips?: InputMaybe<Array<Wf_TrialModeLeads_ParentTipInput>>;
  productCategories?: InputMaybe<Array<Wf_TrialModeLeads_ProductCategoryInput>>;
  productSongAndMarkets?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongAndMarketInput>>;
  productSongs?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongInput>>;
  productVideoAndMarkets?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoAndMarketInput>>;
  productVideos?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoInput>>;
  regularEvents?: InputMaybe<Array<Wf_TrialModeLeads_RegularEventInput>>;
  tips?: InputMaybe<Array<Wf_TrialModeLeads_TipInput>>;
  trialCampaignUrls?: InputMaybe<Array<Wf_TrialModeLeads_TrialCampaignUrlInput>>;
  trialMailLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailLogInput>>;
  trialMailSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailSettingInput>>;
  trialMessageSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialMessageSettingInput>>;
  trialOneTimeTokens?: InputMaybe<Array<Wf_TrialModeLeads_TrialOneTimeTokenInput>>;
  trialPushTypes?: InputMaybe<Array<Wf_TrialModeLeads_TrialPushTypeInput>>;
  trialSegmentations?: InputMaybe<Array<Wf_TrialModeLeads_TrialSegmentationInput>>;
  trialSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialSettingInput>>;
  trialTokens?: InputMaybe<Array<Wf_TrialModeLeads_TrialTokenInput>>;
  trialTrackingLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialTrackingLogInput>>;
  trialUserMessageLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserMessageLogInput>>;
  trialUserNotifications?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserNotificationInput>>;
  trialUserSegmentationLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLogInput>>;
  trialUsers?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserInput>>;
};

export type Wf_TrialModeLeads_Mutation_Update = {
  __typename?: 'WF_TrialModeLeads_Mutation_Update';
  autoMail?: Maybe<Wf_TrialModeLeads_AutoMail>;
  callToAction?: Maybe<Wf_TrialModeLeads_CallToAction>;
  callToActionContent?: Maybe<Wf_TrialModeLeads_CallToActionContent>;
  callToActionExperienceType?: Maybe<Wf_TrialModeLeads_CallToActionExperienceType>;
  designImage?: Maybe<Wf_TrialModeLeads_DesignImage>;
  featuredEvent?: Maybe<Wf_TrialModeLeads_FeaturedEvent>;
  graphqlAccess?: Maybe<Wf_TrialModeLeads_GraphqlAccess>;
  graphqlSchema?: Maybe<Wf_TrialModeLeads_GraphqlSchema>;
  installation?: Maybe<Wf_TrialModeLeads_Installation>;
  installationLog?: Maybe<Wf_TrialModeLeads_InstallationLog>;
  journeyCard?: Maybe<Wf_TrialModeLeads_JourneyCard>;
  journeyExperienceType?: Maybe<Wf_TrialModeLeads_JourneyExperienceType>;
  notification?: Maybe<Wf_TrialModeLeads_Notification>;
  notificationMember?: Maybe<Wf_TrialModeLeads_NotificationMember>;
  notificationToSegmentation?: Maybe<Wf_TrialModeLeads_NotificationToSegmentation>;
  notificationType?: Maybe<Wf_TrialModeLeads_NotificationType>;
  parentTip?: Maybe<Wf_TrialModeLeads_ParentTip>;
  productCategory?: Maybe<Wf_TrialModeLeads_ProductCategory>;
  productSong?: Maybe<Wf_TrialModeLeads_ProductSong>;
  productSongAndMarket?: Maybe<Wf_TrialModeLeads_ProductSongAndMarket>;
  productVideo?: Maybe<Wf_TrialModeLeads_ProductVideo>;
  productVideoAndMarket?: Maybe<Wf_TrialModeLeads_ProductVideoAndMarket>;
  regularEvent?: Maybe<Wf_TrialModeLeads_RegularEvent>;
  tip?: Maybe<Wf_TrialModeLeads_Tip>;
  trialCampaignUrl?: Maybe<Wf_TrialModeLeads_TrialCampaignUrl>;
  trialMailLog?: Maybe<Wf_TrialModeLeads_TrialMailLog>;
  trialMailSetting?: Maybe<Wf_TrialModeLeads_TrialMailSetting>;
  trialMessageSetting?: Maybe<Wf_TrialModeLeads_TrialMessageSetting>;
  trialOneTimeToken?: Maybe<Wf_TrialModeLeads_TrialOneTimeToken>;
  trialPushType?: Maybe<Wf_TrialModeLeads_TrialPushType>;
  trialSegmentation?: Maybe<Wf_TrialModeLeads_TrialSegmentation>;
  trialSetting?: Maybe<Wf_TrialModeLeads_TrialSetting>;
  trialToken?: Maybe<Wf_TrialModeLeads_TrialToken>;
  trialTrackingLog?: Maybe<Wf_TrialModeLeads_TrialTrackingLog>;
  trialUser?: Maybe<Wf_TrialModeLeads_TrialUser>;
  trialUserMessageLog?: Maybe<Wf_TrialModeLeads_TrialUserMessageLog>;
  trialUserNotification?: Maybe<Wf_TrialModeLeads_TrialUserNotification>;
  trialUserSegmentationLog?: Maybe<Wf_TrialModeLeads_TrialUserSegmentationLog>;
};

export type Wf_TrialModeLeads_Mutation_UpdateInput = {
  autoMail?: InputMaybe<Wf_TrialModeLeads_AutoMailInput>;
  callToAction?: InputMaybe<Wf_TrialModeLeads_CallToActionInput>;
  callToActionContent?: InputMaybe<Wf_TrialModeLeads_CallToActionContentInput>;
  callToActionExperienceType?: InputMaybe<Wf_TrialModeLeads_CallToActionExperienceTypeInput>;
  designImage?: InputMaybe<Wf_TrialModeLeads_DesignImageInput>;
  featuredEvent?: InputMaybe<Wf_TrialModeLeads_FeaturedEventInput>;
  graphqlAccess?: InputMaybe<Wf_TrialModeLeads_GraphqlAccessInput>;
  graphqlSchema?: InputMaybe<Wf_TrialModeLeads_GraphqlSchemaInput>;
  installation?: InputMaybe<Wf_TrialModeLeads_InstallationInput>;
  installationLog?: InputMaybe<Wf_TrialModeLeads_InstallationLogInput>;
  journeyCard?: InputMaybe<Wf_TrialModeLeads_JourneyCardInput>;
  journeyExperienceType?: InputMaybe<Wf_TrialModeLeads_JourneyExperienceTypeInput>;
  notification?: InputMaybe<Wf_TrialModeLeads_NotificationInput>;
  notificationMember?: InputMaybe<Wf_TrialModeLeads_NotificationMemberInput>;
  notificationToSegmentation?: InputMaybe<Wf_TrialModeLeads_NotificationToSegmentationInput>;
  notificationType?: InputMaybe<Wf_TrialModeLeads_NotificationTypeInput>;
  parentTip?: InputMaybe<Wf_TrialModeLeads_ParentTipInput>;
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategoryInput>;
  productSong?: InputMaybe<Wf_TrialModeLeads_ProductSongInput>;
  productSongAndMarket?: InputMaybe<Wf_TrialModeLeads_ProductSongAndMarketInput>;
  productVideo?: InputMaybe<Wf_TrialModeLeads_ProductVideoInput>;
  productVideoAndMarket?: InputMaybe<Wf_TrialModeLeads_ProductVideoAndMarketInput>;
  regularEvent?: InputMaybe<Wf_TrialModeLeads_RegularEventInput>;
  tip?: InputMaybe<Wf_TrialModeLeads_TipInput>;
  trialCampaignUrl?: InputMaybe<Wf_TrialModeLeads_TrialCampaignUrlInput>;
  trialMailLog?: InputMaybe<Wf_TrialModeLeads_TrialMailLogInput>;
  trialMailSetting?: InputMaybe<Wf_TrialModeLeads_TrialMailSettingInput>;
  trialMessageSetting?: InputMaybe<Wf_TrialModeLeads_TrialMessageSettingInput>;
  trialOneTimeToken?: InputMaybe<Wf_TrialModeLeads_TrialOneTimeTokenInput>;
  trialPushType?: InputMaybe<Wf_TrialModeLeads_TrialPushTypeInput>;
  trialSegmentation?: InputMaybe<Wf_TrialModeLeads_TrialSegmentationInput>;
  trialSetting?: InputMaybe<Wf_TrialModeLeads_TrialSettingInput>;
  trialToken?: InputMaybe<Wf_TrialModeLeads_TrialTokenInput>;
  trialTrackingLog?: InputMaybe<Wf_TrialModeLeads_TrialTrackingLogInput>;
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserInput>;
  trialUserMessageLog?: InputMaybe<Wf_TrialModeLeads_TrialUserMessageLogInput>;
  trialUserNotification?: InputMaybe<Wf_TrialModeLeads_TrialUserNotificationInput>;
  trialUserSegmentationLog?: InputMaybe<Wf_TrialModeLeads_TrialUserSegmentationLogInput>;
};

export type Wf_TrialModeLeads_Mutation_UpdateRange = {
  __typename?: 'WF_TrialModeLeads_Mutation_UpdateRange';
  autoMails?: Maybe<Array<Wf_TrialModeLeads_AutoMail>>;
  callToActionContents?: Maybe<Array<Wf_TrialModeLeads_CallToActionContent>>;
  callToActionExperienceTypes?: Maybe<Array<Wf_TrialModeLeads_CallToActionExperienceType>>;
  callToActions?: Maybe<Array<Wf_TrialModeLeads_CallToAction>>;
  designImages?: Maybe<Array<Wf_TrialModeLeads_DesignImage>>;
  featuredEvents?: Maybe<Array<Wf_TrialModeLeads_FeaturedEvent>>;
  graphqlAccesses?: Maybe<Array<Wf_TrialModeLeads_GraphqlAccess>>;
  graphqlSchemas?: Maybe<Array<Wf_TrialModeLeads_GraphqlSchema>>;
  installationLogs?: Maybe<Array<Wf_TrialModeLeads_InstallationLog>>;
  installations?: Maybe<Array<Wf_TrialModeLeads_Installation>>;
  journeyCards?: Maybe<Array<Wf_TrialModeLeads_JourneyCard>>;
  journeyExperienceTypes?: Maybe<Array<Wf_TrialModeLeads_JourneyExperienceType>>;
  notificationMembers?: Maybe<Array<Wf_TrialModeLeads_NotificationMember>>;
  notificationToSegmentations?: Maybe<Array<Wf_TrialModeLeads_NotificationToSegmentation>>;
  notificationTypes?: Maybe<Array<Wf_TrialModeLeads_NotificationType>>;
  notifications?: Maybe<Array<Wf_TrialModeLeads_Notification>>;
  parentTips?: Maybe<Array<Wf_TrialModeLeads_ParentTip>>;
  productCategories?: Maybe<Array<Wf_TrialModeLeads_ProductCategory>>;
  productSongAndMarkets?: Maybe<Array<Wf_TrialModeLeads_ProductSongAndMarket>>;
  productSongs?: Maybe<Array<Wf_TrialModeLeads_ProductSong>>;
  productVideoAndMarkets?: Maybe<Array<Wf_TrialModeLeads_ProductVideoAndMarket>>;
  productVideos?: Maybe<Array<Wf_TrialModeLeads_ProductVideo>>;
  regularEvents?: Maybe<Array<Wf_TrialModeLeads_RegularEvent>>;
  tips?: Maybe<Array<Wf_TrialModeLeads_Tip>>;
  trialCampaignUrls?: Maybe<Array<Wf_TrialModeLeads_TrialCampaignUrl>>;
  trialMailLogs?: Maybe<Array<Wf_TrialModeLeads_TrialMailLog>>;
  trialMailSettings?: Maybe<Array<Wf_TrialModeLeads_TrialMailSetting>>;
  trialMessageSettings?: Maybe<Array<Wf_TrialModeLeads_TrialMessageSetting>>;
  trialOneTimeTokens?: Maybe<Array<Wf_TrialModeLeads_TrialOneTimeToken>>;
  trialPushTypes?: Maybe<Array<Wf_TrialModeLeads_TrialPushType>>;
  trialSegmentations?: Maybe<Array<Wf_TrialModeLeads_TrialSegmentation>>;
  trialSettings?: Maybe<Array<Wf_TrialModeLeads_TrialSetting>>;
  trialTokens?: Maybe<Array<Wf_TrialModeLeads_TrialToken>>;
  trialTrackingLogs?: Maybe<Array<Wf_TrialModeLeads_TrialTrackingLog>>;
  trialUserMessageLogs?: Maybe<Array<Wf_TrialModeLeads_TrialUserMessageLog>>;
  trialUserNotifications?: Maybe<Array<Wf_TrialModeLeads_TrialUserNotification>>;
  trialUserSegmentationLogs?: Maybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLog>>;
  trialUsers?: Maybe<Array<Wf_TrialModeLeads_TrialUser>>;
};

export type Wf_TrialModeLeads_Mutation_UpdateRangeInput = {
  autoMails?: InputMaybe<Array<Wf_TrialModeLeads_AutoMailInput>>;
  callToActionContents?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionContentInput>>;
  callToActionExperienceTypes?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionExperienceTypeInput>>;
  callToActions?: InputMaybe<Array<Wf_TrialModeLeads_CallToActionInput>>;
  designImages?: InputMaybe<Array<Wf_TrialModeLeads_DesignImageInput>>;
  featuredEvents?: InputMaybe<Array<Wf_TrialModeLeads_FeaturedEventInput>>;
  graphqlAccesses?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlAccessInput>>;
  graphqlSchemas?: InputMaybe<Array<Wf_TrialModeLeads_GraphqlSchemaInput>>;
  installationLogs?: InputMaybe<Array<Wf_TrialModeLeads_InstallationLogInput>>;
  installations?: InputMaybe<Array<Wf_TrialModeLeads_InstallationInput>>;
  journeyCards?: InputMaybe<Array<Wf_TrialModeLeads_JourneyCardInput>>;
  journeyExperienceTypes?: InputMaybe<Array<Wf_TrialModeLeads_JourneyExperienceTypeInput>>;
  notificationMembers?: InputMaybe<Array<Wf_TrialModeLeads_NotificationMemberInput>>;
  notificationToSegmentations?: InputMaybe<Array<Wf_TrialModeLeads_NotificationToSegmentationInput>>;
  notificationTypes?: InputMaybe<Array<Wf_TrialModeLeads_NotificationTypeInput>>;
  notifications?: InputMaybe<Array<Wf_TrialModeLeads_NotificationInput>>;
  parentTips?: InputMaybe<Array<Wf_TrialModeLeads_ParentTipInput>>;
  productCategories?: InputMaybe<Array<Wf_TrialModeLeads_ProductCategoryInput>>;
  productSongAndMarkets?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongAndMarketInput>>;
  productSongs?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongInput>>;
  productVideoAndMarkets?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoAndMarketInput>>;
  productVideos?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoInput>>;
  regularEvents?: InputMaybe<Array<Wf_TrialModeLeads_RegularEventInput>>;
  tips?: InputMaybe<Array<Wf_TrialModeLeads_TipInput>>;
  trialCampaignUrls?: InputMaybe<Array<Wf_TrialModeLeads_TrialCampaignUrlInput>>;
  trialMailLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailLogInput>>;
  trialMailSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailSettingInput>>;
  trialMessageSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialMessageSettingInput>>;
  trialOneTimeTokens?: InputMaybe<Array<Wf_TrialModeLeads_TrialOneTimeTokenInput>>;
  trialPushTypes?: InputMaybe<Array<Wf_TrialModeLeads_TrialPushTypeInput>>;
  trialSegmentations?: InputMaybe<Array<Wf_TrialModeLeads_TrialSegmentationInput>>;
  trialSettings?: InputMaybe<Array<Wf_TrialModeLeads_TrialSettingInput>>;
  trialTokens?: InputMaybe<Array<Wf_TrialModeLeads_TrialTokenInput>>;
  trialTrackingLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialTrackingLogInput>>;
  trialUserMessageLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserMessageLogInput>>;
  trialUserNotifications?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserNotificationInput>>;
  trialUserSegmentationLogs?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLogInput>>;
  trialUsers?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserInput>>;
};

/** Notification */
export type Wf_TrialModeLeads_Notification = Node & {
  __typename?: 'WF_TrialModeLeads_Notification';
  /** ActionButton */
  actionButton?: Maybe<Scalars['String']['output']>;
  /** ButtonLinksTo */
  buttonLinksTo?: Maybe<Scalars['String']['output']>;
  /** ButtonText */
  buttonText?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** Error */
  error?: Maybe<Scalars['String']['output']>;
  /** [ID] NotificationId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** JobStatus */
  jobStatus?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** MutationOperation */
  mutationOperation?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] NotificationToSegmentations */
  notificationToSegmentations?: Maybe<Array<Maybe<Wf_TrialModeLeads_NotificationToSegmentation>>>;
  /** [NAVIGATION] NotificationType */
  notificationType?: Maybe<Wf_TrialModeLeads_NotificationType>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: Maybe<Scalars['String']['output']>;
  /** RemoveNotificationId */
  removeNotificationId?: Maybe<Scalars['Int']['output']>;
  /** ScheduleTime */
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  /** Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
  /** TemplateMessage */
  templateMessage?: Maybe<Scalars['String']['output']>;
  /** Title */
  title?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_NotificationConnection = {
  __typename?: 'WF_TrialModeLeads_NotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_NotificationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_Notification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_NotificationEdge = {
  __typename?: 'WF_TrialModeLeads_NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_Notification;
};

/** Notification */
export type Wf_TrialModeLeads_NotificationFilterInput = {
  /** ActionButton */
  actionButton?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Wf_TrialModeLeads_NotificationFilterInput>>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<StringOperationFilterInput>;
  /** ButtonText */
  buttonText?: InputMaybe<StringOperationFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** Error */
  error?: InputMaybe<StringOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  /** JobStatus */
  jobStatus?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<StringOperationFilterInput>;
  /** [ID] NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] NotificationToSegmentations */
  notificationToSegmentations?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_NotificationToSegmentationFilterInput>;
  /** [NAVIGATION] NotificationType */
  notificationType?: InputMaybe<Wf_TrialModeLeads_NotificationTypeFilterInput>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_NotificationFilterInput>>;
  /** RemoveNotificationId */
  removeNotificationId?: InputMaybe<IntOperationFilterInput>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<StringOperationFilterInput>;
  /** Title */
  title?: InputMaybe<StringOperationFilterInput>;
};

/** Notification */
export type Wf_TrialModeLeads_NotificationInput = {
  /** ActionButton */
  actionButton?: InputMaybe<Scalars['String']['input']>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<Scalars['String']['input']>;
  /** ButtonText */
  buttonText?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** Error */
  error?: InputMaybe<Scalars['String']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** JobStatus */
  jobStatus?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<Scalars['String']['input']>;
  /** [ID] NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] NotificationToSegmentations */
  notificationToSegmentations?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_NotificationToSegmentationInput>>>;
  /** [NAVIGATION] NotificationType */
  notificationType?: InputMaybe<Wf_TrialModeLeads_NotificationTypeInput>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<Scalars['String']['input']>;
  /** RemoveNotificationId */
  removeNotificationId?: InputMaybe<Scalars['Int']['input']>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<Scalars['String']['input']>;
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** NotificationMember */
export type Wf_TrialModeLeads_NotificationMember = Node & {
  __typename?: 'WF_TrialModeLeads_NotificationMember';
  /** [ID] NotificationMemberId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] NotificationId */
  notificationId?: Maybe<Scalars['Int']['output']>;
  /** PhoneNumber */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_NotificationMemberConnection = {
  __typename?: 'WF_TrialModeLeads_NotificationMemberConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_NotificationMemberEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_NotificationMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_NotificationMemberEdge = {
  __typename?: 'WF_TrialModeLeads_NotificationMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_NotificationMember;
};

/** NotificationMember */
export type Wf_TrialModeLeads_NotificationMemberFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_NotificationMemberFilterInput>>;
  /** [NOT NULL] NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] NotificationMemberId */
  notificationMemberId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_NotificationMemberFilterInput>>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
};

/** NotificationMember */
export type Wf_TrialModeLeads_NotificationMemberInput = {
  /** [NOT NULL] NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] NotificationMemberId */
  notificationMemberId?: InputMaybe<Scalars['Long']['input']>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
};

/** NotificationMember */
export type Wf_TrialModeLeads_NotificationMemberSortInput = {
  /** [NOT NULL] NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** [ID] NotificationMemberId */
  notificationMemberId?: InputMaybe<SortEnumType>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
};

/** Notification */
export type Wf_TrialModeLeads_NotificationSortInput = {
  /** ActionButton */
  actionButton?: InputMaybe<SortEnumType>;
  /** ButtonLinksTo */
  buttonLinksTo?: InputMaybe<SortEnumType>;
  /** ButtonText */
  buttonText?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** Error */
  error?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** JobStatus */
  jobStatus?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** MutationOperation */
  mutationOperation?: InputMaybe<SortEnumType>;
  /** [ID] NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] NotificationType */
  notificationType?: InputMaybe<Wf_TrialModeLeads_NotificationTypeSortInput>;
  /** [NOT NULL] NotificationTypeId */
  notificationTypeId?: InputMaybe<SortEnumType>;
  /** RemoveNotificationId */
  removeNotificationId?: InputMaybe<SortEnumType>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<SortEnumType>;
  /** Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
  /** TemplateMessage */
  templateMessage?: InputMaybe<SortEnumType>;
  /** Title */
  title?: InputMaybe<SortEnumType>;
};

/** NotificationToSegmentation */
export type Wf_TrialModeLeads_NotificationToSegmentation = Node & {
  __typename?: 'WF_TrialModeLeads_NotificationToSegmentation';
  /** [ID] NotificationToSegmentationId */
  id: Scalars['ID']['output'];
  /** [NAVIGATION] Notification */
  notification?: Maybe<Wf_TrialModeLeads_Notification>;
  /** NotificationId */
  notificationId?: Maybe<Scalars['Int']['output']>;
  /** Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_NotificationToSegmentationConnection = {
  __typename?: 'WF_TrialModeLeads_NotificationToSegmentationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_NotificationToSegmentationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_NotificationToSegmentation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_NotificationToSegmentationEdge = {
  __typename?: 'WF_TrialModeLeads_NotificationToSegmentationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_NotificationToSegmentation;
};

/** NotificationToSegmentation */
export type Wf_TrialModeLeads_NotificationToSegmentationFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_NotificationToSegmentationFilterInput>>;
  /** [NAVIGATION] Notification */
  notification?: InputMaybe<Wf_TrialModeLeads_NotificationFilterInput>;
  /** NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] NotificationToSegmentationId */
  notificationToSegmentationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_NotificationToSegmentationFilterInput>>;
  /** Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
};

/** NotificationToSegmentation */
export type Wf_TrialModeLeads_NotificationToSegmentationInput = {
  /** [NAVIGATION] Notification */
  notification?: InputMaybe<Wf_TrialModeLeads_NotificationInput>;
  /** NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] NotificationToSegmentationId */
  notificationToSegmentationId?: InputMaybe<Scalars['Int']['input']>;
  /** Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
};

/** NotificationToSegmentation */
export type Wf_TrialModeLeads_NotificationToSegmentationSortInput = {
  /** [NAVIGATION] Notification */
  notification?: InputMaybe<Wf_TrialModeLeads_NotificationSortInput>;
  /** NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** [ID] NotificationToSegmentationId */
  notificationToSegmentationId?: InputMaybe<SortEnumType>;
  /** Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
};

/** NotificationType */
export type Wf_TrialModeLeads_NotificationType = Node & {
  __typename?: 'WF_TrialModeLeads_NotificationType';
  /** [ID] NotificationTypeId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] IsPublic */
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] NotificationName */
  notificationName?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] Notifications */
  notifications?: Maybe<Array<Maybe<Wf_TrialModeLeads_Notification>>>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_NotificationTypeConnection = {
  __typename?: 'WF_TrialModeLeads_NotificationTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_NotificationTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_NotificationType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_NotificationTypeEdge = {
  __typename?: 'WF_TrialModeLeads_NotificationTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_NotificationType;
};

/** NotificationType */
export type Wf_TrialModeLeads_NotificationTypeFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_NotificationTypeFilterInput>>;
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<BooleanOperationFilterInput>;
  /** [NOT NULL] NotificationName */
  notificationName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] NotificationTypeId */
  notificationTypeId?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] Notifications */
  notifications?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_NotificationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_NotificationTypeFilterInput>>;
};

/** NotificationType */
export type Wf_TrialModeLeads_NotificationTypeInput = {
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** [NOT NULL] NotificationName */
  notificationName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] NotificationTypeId */
  notificationTypeId?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] Notifications */
  notifications?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_NotificationInput>>>;
};

/** NotificationType */
export type Wf_TrialModeLeads_NotificationTypeSortInput = {
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<SortEnumType>;
  /** [NOT NULL] NotificationName */
  notificationName?: InputMaybe<SortEnumType>;
  /** [ID] NotificationTypeId */
  notificationTypeId?: InputMaybe<SortEnumType>;
};

/** ParentTip */
export type Wf_TrialModeLeads_ParentTip = Node & {
  __typename?: 'WF_TrialModeLeads_ParentTip';
  /** [ID] Id */
  id: Scalars['ID']['output'];
  /** [NOT NULL] TipDate */
  tipDate?: Maybe<Scalars['DateTime']['output']>;
  /** TipsEn */
  tipsEn?: Maybe<Scalars['String']['output']>;
  /** TipsHk */
  tipsHk?: Maybe<Scalars['String']['output']>;
  /** TipsKr */
  tipsKr?: Maybe<Scalars['String']['output']>;
  /** TipsTw */
  tipsTw?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_ParentTipConnection = {
  __typename?: 'WF_TrialModeLeads_ParentTipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_ParentTipEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_ParentTip>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_ParentTipEdge = {
  __typename?: 'WF_TrialModeLeads_ParentTipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_ParentTip;
};

/** ParentTip */
export type Wf_TrialModeLeads_ParentTipFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_ParentTipFilterInput>>;
  /** [ID] Id */
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_ParentTipFilterInput>>;
  /** [NOT NULL] TipDate */
  tipDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** TipsEn */
  tipsEn?: InputMaybe<StringOperationFilterInput>;
  /** TipsHk */
  tipsHk?: InputMaybe<StringOperationFilterInput>;
  /** TipsKr */
  tipsKr?: InputMaybe<StringOperationFilterInput>;
  /** TipsTw */
  tipsTw?: InputMaybe<StringOperationFilterInput>;
};

/** ParentTip */
export type Wf_TrialModeLeads_ParentTipInput = {
  /** [ID] Id */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] TipDate */
  tipDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** TipsEn */
  tipsEn?: InputMaybe<Scalars['String']['input']>;
  /** TipsHk */
  tipsHk?: InputMaybe<Scalars['String']['input']>;
  /** TipsKr */
  tipsKr?: InputMaybe<Scalars['String']['input']>;
  /** TipsTw */
  tipsTw?: InputMaybe<Scalars['String']['input']>;
};

/** ParentTip */
export type Wf_TrialModeLeads_ParentTipSortInput = {
  /** [ID] Id */
  id?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TipDate */
  tipDate?: InputMaybe<SortEnumType>;
  /** TipsEn */
  tipsEn?: InputMaybe<SortEnumType>;
  /** TipsHk */
  tipsHk?: InputMaybe<SortEnumType>;
  /** TipsKr */
  tipsKr?: InputMaybe<SortEnumType>;
  /** TipsTw */
  tipsTw?: InputMaybe<SortEnumType>;
};

/** ProductCategory */
export type Wf_TrialModeLeads_ProductCategory = Node & {
  __typename?: 'WF_TrialModeLeads_ProductCategory';
  /** Category */
  category?: Maybe<Scalars['String']['output']>;
  /** CategoryName */
  categoryName?: Maybe<Scalars['String']['output']>;
  /** CategoryThumbnail */
  categoryThumbnail?: Maybe<Scalars['String']['output']>;
  /** DwePackage */
  dwePackage?: Maybe<Scalars['String']['output']>;
  /** [ID] ProductCategoryId */
  id: Scalars['ID']['output'];
  /** [COLLECTION] ProductSongs */
  productSongs?: Maybe<Array<Maybe<Wf_TrialModeLeads_ProductSong>>>;
  /** [COLLECTION] ProductVideos */
  productVideos?: Maybe<Array<Maybe<Wf_TrialModeLeads_ProductVideo>>>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_ProductCategoryConnection = {
  __typename?: 'WF_TrialModeLeads_ProductCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_ProductCategoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_ProductCategory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_ProductCategoryEdge = {
  __typename?: 'WF_TrialModeLeads_ProductCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_ProductCategory;
};

/** ProductCategory */
export type Wf_TrialModeLeads_ProductCategoryFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_ProductCategoryFilterInput>>;
  /** Category */
  category?: InputMaybe<StringOperationFilterInput>;
  /** CategoryName */
  categoryName?: InputMaybe<StringOperationFilterInput>;
  /** CategoryThumbnail */
  categoryThumbnail?: InputMaybe<StringOperationFilterInput>;
  /** DwePackage */
  dwePackage?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_ProductCategoryFilterInput>>;
  /** [ID] ProductCategoryId */
  productCategoryId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] ProductSongs */
  productSongs?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_ProductSongFilterInput>;
  /** [COLLECTION] ProductVideos */
  productVideos?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_ProductVideoFilterInput>;
};

/** ProductCategory */
export type Wf_TrialModeLeads_ProductCategoryInput = {
  /** Category */
  category?: InputMaybe<Scalars['String']['input']>;
  /** CategoryName */
  categoryName?: InputMaybe<Scalars['String']['input']>;
  /** CategoryThumbnail */
  categoryThumbnail?: InputMaybe<Scalars['String']['input']>;
  /** DwePackage */
  dwePackage?: InputMaybe<Scalars['String']['input']>;
  /** [ID] ProductCategoryId */
  productCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] ProductSongs */
  productSongs?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_ProductSongInput>>>;
  /** [COLLECTION] ProductVideos */
  productVideos?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_ProductVideoInput>>>;
};

/** ProductCategory */
export type Wf_TrialModeLeads_ProductCategorySortInput = {
  /** Category */
  category?: InputMaybe<SortEnumType>;
  /** CategoryName */
  categoryName?: InputMaybe<SortEnumType>;
  /** CategoryThumbnail */
  categoryThumbnail?: InputMaybe<SortEnumType>;
  /** DwePackage */
  dwePackage?: InputMaybe<SortEnumType>;
  /** [ID] ProductCategoryId */
  productCategoryId?: InputMaybe<SortEnumType>;
};

/** ProductSong */
export type Wf_TrialModeLeads_ProductSong = Node & {
  __typename?: 'WF_TrialModeLeads_ProductSong';
  /** AudioFileName */
  audioFileName?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** [ID] ProductSongId */
  id: Scalars['ID']['output'];
  /** LyricsFileName */
  lyricsFileName?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] ProductCategory */
  productCategory?: Maybe<Wf_TrialModeLeads_ProductCategory>;
  /** [NOT NULL] ProductCategoryId */
  productCategoryId?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] ProductSongAndMarkets */
  productSongAndMarkets?: Maybe<Array<Maybe<Wf_TrialModeLeads_ProductSongAndMarket>>>;
  /** SongTitle */
  songTitle?: Maybe<Scalars['String']['output']>;
  /** SortId */
  sortId?: Maybe<Scalars['Int']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
};

/** ProductSongAndMarket */
export type Wf_TrialModeLeads_ProductSongAndMarket = Node & {
  __typename?: 'WF_TrialModeLeads_ProductSongAndMarket';
  /** [ID] ProductSongAndMarketId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] ProductSong */
  productSong?: Maybe<Wf_TrialModeLeads_ProductSong>;
  /** [NOT NULL] ProductSongId */
  productSongId?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_ProductSongAndMarketConnection = {
  __typename?: 'WF_TrialModeLeads_ProductSongAndMarketConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_ProductSongAndMarketEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_ProductSongAndMarket>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_ProductSongAndMarketEdge = {
  __typename?: 'WF_TrialModeLeads_ProductSongAndMarketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_ProductSongAndMarket;
};

/** ProductSongAndMarket */
export type Wf_TrialModeLeads_ProductSongAndMarketFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongAndMarketFilterInput>>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongAndMarketFilterInput>>;
  /** [NAVIGATION] ProductSong */
  productSong?: InputMaybe<Wf_TrialModeLeads_ProductSongFilterInput>;
  /** [ID] ProductSongAndMarketId */
  productSongAndMarketId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ProductSongId */
  productSongId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
};

/** ProductSongAndMarket */
export type Wf_TrialModeLeads_ProductSongAndMarketInput = {
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] ProductSong */
  productSong?: InputMaybe<Wf_TrialModeLeads_ProductSongInput>;
  /** [ID] ProductSongAndMarketId */
  productSongAndMarketId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ProductSongId */
  productSongId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
};

/** ProductSongAndMarket */
export type Wf_TrialModeLeads_ProductSongAndMarketSortInput = {
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] ProductSong */
  productSong?: InputMaybe<Wf_TrialModeLeads_ProductSongSortInput>;
  /** [ID] ProductSongAndMarketId */
  productSongAndMarketId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ProductSongId */
  productSongId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_ProductSongConnection = {
  __typename?: 'WF_TrialModeLeads_ProductSongConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_ProductSongEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_ProductSong>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_ProductSongEdge = {
  __typename?: 'WF_TrialModeLeads_ProductSongEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_ProductSong;
};

/** ProductSong */
export type Wf_TrialModeLeads_ProductSongFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongFilterInput>>;
  /** AudioFileName */
  audioFileName?: InputMaybe<StringOperationFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** LyricsFileName */
  lyricsFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_ProductSongFilterInput>>;
  /** [NAVIGATION] ProductCategory */
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategoryFilterInput>;
  /** [NOT NULL] ProductCategoryId */
  productCategoryId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] ProductSongAndMarkets */
  productSongAndMarkets?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_ProductSongAndMarketFilterInput>;
  /** [ID] ProductSongId */
  productSongId?: InputMaybe<IntOperationFilterInput>;
  /** SongTitle */
  songTitle?: InputMaybe<StringOperationFilterInput>;
  /** SortId */
  sortId?: InputMaybe<IntOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
};

/** ProductSong */
export type Wf_TrialModeLeads_ProductSongInput = {
  /** AudioFileName */
  audioFileName?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** LyricsFileName */
  lyricsFileName?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] ProductCategory */
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategoryInput>;
  /** [NOT NULL] ProductCategoryId */
  productCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] ProductSongAndMarkets */
  productSongAndMarkets?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_ProductSongAndMarketInput>>>;
  /** [ID] ProductSongId */
  productSongId?: InputMaybe<Scalars['Int']['input']>;
  /** SongTitle */
  songTitle?: InputMaybe<Scalars['String']['input']>;
  /** SortId */
  sortId?: InputMaybe<Scalars['Int']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
};

/** ProductSong */
export type Wf_TrialModeLeads_ProductSongSortInput = {
  /** AudioFileName */
  audioFileName?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** LyricsFileName */
  lyricsFileName?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] ProductCategory */
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategorySortInput>;
  /** [NOT NULL] ProductCategoryId */
  productCategoryId?: InputMaybe<SortEnumType>;
  /** [ID] ProductSongId */
  productSongId?: InputMaybe<SortEnumType>;
  /** SongTitle */
  songTitle?: InputMaybe<SortEnumType>;
  /** SortId */
  sortId?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
};

/** ProductVideo */
export type Wf_TrialModeLeads_ProductVideo = Node & {
  __typename?: 'WF_TrialModeLeads_ProductVideo';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** [ID] ProductVideoId */
  id: Scalars['ID']['output'];
  /** IntroVideoFileName */
  introVideoFileName?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] ProductCategory */
  productCategory?: Maybe<Wf_TrialModeLeads_ProductCategory>;
  /** [NOT NULL] ProductCategoryId */
  productCategoryId?: Maybe<Scalars['Int']['output']>;
  /** [COLLECTION] ProductVideoAndMarkets */
  productVideoAndMarkets?: Maybe<Array<Maybe<Wf_TrialModeLeads_ProductVideoAndMarket>>>;
  /** SortId */
  sortId?: Maybe<Scalars['Int']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
  /** VideoFileName */
  videoFileName?: Maybe<Scalars['String']['output']>;
  /** VideoThumbnail */
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  /** VideoTitle */
  videoTitle?: Maybe<Scalars['String']['output']>;
};

/** ProductVideoAndMarket */
export type Wf_TrialModeLeads_ProductVideoAndMarket = Node & {
  __typename?: 'WF_TrialModeLeads_ProductVideoAndMarket';
  /** [ID] ProductVideoAndMarketId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] Market */
  market?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] ProductVideo */
  productVideo?: Maybe<Wf_TrialModeLeads_ProductVideo>;
  /** [NOT NULL] ProductVideoId */
  productVideoId?: Maybe<Scalars['Int']['output']>;
  /** [NOT NULL] Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_ProductVideoAndMarketConnection = {
  __typename?: 'WF_TrialModeLeads_ProductVideoAndMarketConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_ProductVideoAndMarketEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_ProductVideoAndMarket>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_ProductVideoAndMarketEdge = {
  __typename?: 'WF_TrialModeLeads_ProductVideoAndMarketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_ProductVideoAndMarket;
};

/** ProductVideoAndMarket */
export type Wf_TrialModeLeads_ProductVideoAndMarketFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoAndMarketFilterInput>>;
  /** [NOT NULL] Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoAndMarketFilterInput>>;
  /** [NAVIGATION] ProductVideo */
  productVideo?: InputMaybe<Wf_TrialModeLeads_ProductVideoFilterInput>;
  /** [ID] ProductVideoAndMarketId */
  productVideoAndMarketId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] ProductVideoId */
  productVideoId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
};

/** ProductVideoAndMarket */
export type Wf_TrialModeLeads_ProductVideoAndMarketInput = {
  /** [NOT NULL] Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] ProductVideo */
  productVideo?: InputMaybe<Wf_TrialModeLeads_ProductVideoInput>;
  /** [ID] ProductVideoAndMarketId */
  productVideoAndMarketId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] ProductVideoId */
  productVideoId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
};

/** ProductVideoAndMarket */
export type Wf_TrialModeLeads_ProductVideoAndMarketSortInput = {
  /** [NOT NULL] Market */
  market?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] ProductVideo */
  productVideo?: InputMaybe<Wf_TrialModeLeads_ProductVideoSortInput>;
  /** [ID] ProductVideoAndMarketId */
  productVideoAndMarketId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] ProductVideoId */
  productVideoId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_ProductVideoConnection = {
  __typename?: 'WF_TrialModeLeads_ProductVideoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_ProductVideoEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_ProductVideo>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_ProductVideoEdge = {
  __typename?: 'WF_TrialModeLeads_ProductVideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_ProductVideo;
};

/** ProductVideo */
export type Wf_TrialModeLeads_ProductVideoFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** IntroVideoFileName */
  introVideoFileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_ProductVideoFilterInput>>;
  /** [NAVIGATION] ProductCategory */
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategoryFilterInput>;
  /** [NOT NULL] ProductCategoryId */
  productCategoryId?: InputMaybe<IntOperationFilterInput>;
  /** [COLLECTION] ProductVideoAndMarkets */
  productVideoAndMarkets?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_ProductVideoAndMarketFilterInput>;
  /** [ID] ProductVideoId */
  productVideoId?: InputMaybe<IntOperationFilterInput>;
  /** SortId */
  sortId?: InputMaybe<IntOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
  /** VideoFileName */
  videoFileName?: InputMaybe<StringOperationFilterInput>;
  /** VideoThumbnail */
  videoThumbnail?: InputMaybe<StringOperationFilterInput>;
  /** VideoTitle */
  videoTitle?: InputMaybe<StringOperationFilterInput>;
};

/** ProductVideo */
export type Wf_TrialModeLeads_ProductVideoInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** IntroVideoFileName */
  introVideoFileName?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] ProductCategory */
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategoryInput>;
  /** [NOT NULL] ProductCategoryId */
  productCategoryId?: InputMaybe<Scalars['Int']['input']>;
  /** [COLLECTION] ProductVideoAndMarkets */
  productVideoAndMarkets?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_ProductVideoAndMarketInput>>>;
  /** [ID] ProductVideoId */
  productVideoId?: InputMaybe<Scalars['Int']['input']>;
  /** SortId */
  sortId?: InputMaybe<Scalars['Int']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
  /** VideoFileName */
  videoFileName?: InputMaybe<Scalars['String']['input']>;
  /** VideoThumbnail */
  videoThumbnail?: InputMaybe<Scalars['String']['input']>;
  /** VideoTitle */
  videoTitle?: InputMaybe<Scalars['String']['input']>;
};

/** ProductVideo */
export type Wf_TrialModeLeads_ProductVideoSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** IntroVideoFileName */
  introVideoFileName?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] ProductCategory */
  productCategory?: InputMaybe<Wf_TrialModeLeads_ProductCategorySortInput>;
  /** [NOT NULL] ProductCategoryId */
  productCategoryId?: InputMaybe<SortEnumType>;
  /** [ID] ProductVideoId */
  productVideoId?: InputMaybe<SortEnumType>;
  /** SortId */
  sortId?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
  /** VideoFileName */
  videoFileName?: InputMaybe<SortEnumType>;
  /** VideoThumbnail */
  videoThumbnail?: InputMaybe<SortEnumType>;
  /** VideoTitle */
  videoTitle?: InputMaybe<SortEnumType>;
};

/** RegularEvent */
export type Wf_TrialModeLeads_RegularEvent = Node & {
  __typename?: 'WF_TrialModeLeads_RegularEvent';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** Creator */
  creator?: Maybe<Scalars['String']['output']>;
  /** EndDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** EventDescription */
  eventDescription?: Maybe<Scalars['String']['output']>;
  /** EventTitle */
  eventTitle?: Maybe<Scalars['String']['output']>;
  /** [ID] RegularEventId */
  id: Scalars['ID']['output'];
  /** ImageFileName */
  imageFileName?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** NavigationUrl */
  navigationUrl?: Maybe<Scalars['String']['output']>;
  /** SortId */
  sortId?: Maybe<Scalars['Int']['output']>;
  /** StartDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** UpdatedTime */
  updatedTime?: Maybe<Scalars['DateTime']['output']>;
  /** Updater */
  updater?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_RegularEventConnection = {
  __typename?: 'WF_TrialModeLeads_RegularEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_RegularEventEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_RegularEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_RegularEventEdge = {
  __typename?: 'WF_TrialModeLeads_RegularEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_RegularEvent;
};

/** RegularEvent */
export type Wf_TrialModeLeads_RegularEventFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_RegularEventFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Creator */
  creator?: InputMaybe<StringOperationFilterInput>;
  /** EndDate */
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** EventDescription */
  eventDescription?: InputMaybe<StringOperationFilterInput>;
  /** EventTitle */
  eventTitle?: InputMaybe<StringOperationFilterInput>;
  /** ImageFileName */
  imageFileName?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** NavigationUrl */
  navigationUrl?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_RegularEventFilterInput>>;
  /** [ID] RegularEventId */
  regularEventId?: InputMaybe<IntOperationFilterInput>;
  /** SortId */
  sortId?: InputMaybe<IntOperationFilterInput>;
  /** StartDate */
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Updater */
  updater?: InputMaybe<StringOperationFilterInput>;
};

/** RegularEvent */
export type Wf_TrialModeLeads_RegularEventInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Creator */
  creator?: InputMaybe<Scalars['String']['input']>;
  /** EndDate */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** EventDescription */
  eventDescription?: InputMaybe<Scalars['String']['input']>;
  /** EventTitle */
  eventTitle?: InputMaybe<Scalars['String']['input']>;
  /** ImageFileName */
  imageFileName?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** NavigationUrl */
  navigationUrl?: InputMaybe<Scalars['String']['input']>;
  /** [ID] RegularEventId */
  regularEventId?: InputMaybe<Scalars['Int']['input']>;
  /** SortId */
  sortId?: InputMaybe<Scalars['Int']['input']>;
  /** StartDate */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Updater */
  updater?: InputMaybe<Scalars['String']['input']>;
};

/** RegularEvent */
export type Wf_TrialModeLeads_RegularEventSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Creator */
  creator?: InputMaybe<SortEnumType>;
  /** EndDate */
  endDate?: InputMaybe<SortEnumType>;
  /** EventDescription */
  eventDescription?: InputMaybe<SortEnumType>;
  /** EventTitle */
  eventTitle?: InputMaybe<SortEnumType>;
  /** ImageFileName */
  imageFileName?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** NavigationUrl */
  navigationUrl?: InputMaybe<SortEnumType>;
  /** [ID] RegularEventId */
  regularEventId?: InputMaybe<SortEnumType>;
  /** SortId */
  sortId?: InputMaybe<SortEnumType>;
  /** StartDate */
  startDate?: InputMaybe<SortEnumType>;
  /** UpdatedTime */
  updatedTime?: InputMaybe<SortEnumType>;
  /** Updater */
  updater?: InputMaybe<SortEnumType>;
};

/** Tip */
export type Wf_TrialModeLeads_Tip = Node & {
  __typename?: 'WF_TrialModeLeads_Tip';
  /** [ID] TipsTw */
  id: Scalars['ID']['output'];
  /** TipsEn */
  tipsEn?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TipConnection = {
  __typename?: 'WF_TrialModeLeads_TipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TipEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_Tip>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TipEdge = {
  __typename?: 'WF_TrialModeLeads_TipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_Tip;
};

/** Tip */
export type Wf_TrialModeLeads_TipFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TipFilterInput>>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TipFilterInput>>;
  /** TipsEn */
  tipsEn?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TipsTw */
  tipsTw?: InputMaybe<StringOperationFilterInput>;
};

/** Tip */
export type Wf_TrialModeLeads_TipInput = {
  /** TipsEn */
  tipsEn?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TipsTw */
  tipsTw: Scalars['String']['input'];
};

/** Tip */
export type Wf_TrialModeLeads_TipSortInput = {
  /** TipsEn */
  tipsEn?: InputMaybe<SortEnumType>;
  /** [ID] TipsTw */
  tipsTw?: InputMaybe<SortEnumType>;
};

/** TrialCampaignUrl */
export type Wf_TrialModeLeads_TrialCampaignUrl = Node & {
  __typename?: 'WF_TrialModeLeads_TrialCampaignUrl';
  /** CampaignChannel */
  campaignChannel?: Maybe<Scalars['String']['output']>;
  /** CampaignUrl */
  campaignUrl?: Maybe<Scalars['String']['output']>;
  /** Device */
  device?: Maybe<Scalars['String']['output']>;
  /** [ID] CampaignId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialCampaignUrlConnection = {
  __typename?: 'WF_TrialModeLeads_TrialCampaignUrlConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialCampaignUrlEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialCampaignUrl>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialCampaignUrlEdge = {
  __typename?: 'WF_TrialModeLeads_TrialCampaignUrlEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialCampaignUrl;
};

/** TrialCampaignUrl */
export type Wf_TrialModeLeads_TrialCampaignUrlFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialCampaignUrlFilterInput>>;
  /** CampaignChannel */
  campaignChannel?: InputMaybe<StringOperationFilterInput>;
  /** [ID] CampaignId */
  campaignId?: InputMaybe<IntOperationFilterInput>;
  /** CampaignUrl */
  campaignUrl?: InputMaybe<StringOperationFilterInput>;
  /** Device */
  device?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialCampaignUrlFilterInput>>;
};

/** TrialCampaignUrl */
export type Wf_TrialModeLeads_TrialCampaignUrlInput = {
  /** CampaignChannel */
  campaignChannel?: InputMaybe<Scalars['String']['input']>;
  /** [ID] CampaignId */
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  /** CampaignUrl */
  campaignUrl?: InputMaybe<Scalars['String']['input']>;
  /** Device */
  device?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
};

/** TrialCampaignUrl */
export type Wf_TrialModeLeads_TrialCampaignUrlSortInput = {
  /** CampaignChannel */
  campaignChannel?: InputMaybe<SortEnumType>;
  /** [ID] CampaignId */
  campaignId?: InputMaybe<SortEnumType>;
  /** CampaignUrl */
  campaignUrl?: InputMaybe<SortEnumType>;
  /** Device */
  device?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
};

/** TrialMailLog */
export type Wf_TrialModeLeads_TrialMailLog = Node & {
  __typename?: 'WF_TrialModeLeads_TrialMailLog';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] TrialMailLogId */
  id: Scalars['ID']['output'];
  /** MailBcc */
  mailBcc?: Maybe<Scalars['String']['output']>;
  /** MailCc */
  mailCc?: Maybe<Scalars['String']['output']>;
  /** MailContent */
  mailContent?: Maybe<Scalars['String']['output']>;
  /** MailSubject */
  mailSubject?: Maybe<Scalars['String']['output']>;
  /** MailTo */
  mailTo?: Maybe<Scalars['String']['output']>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialMailLogConnection = {
  __typename?: 'WF_TrialModeLeads_TrialMailLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialMailLogEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialMailLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialMailLogEdge = {
  __typename?: 'WF_TrialModeLeads_TrialMailLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialMailLog;
};

/** TrialMailLog */
export type Wf_TrialModeLeads_TrialMailLogFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailLogFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** MailBcc */
  mailBcc?: InputMaybe<StringOperationFilterInput>;
  /** MailCc */
  mailCc?: InputMaybe<StringOperationFilterInput>;
  /** MailContent */
  mailContent?: InputMaybe<StringOperationFilterInput>;
  /** MailSubject */
  mailSubject?: InputMaybe<StringOperationFilterInput>;
  /** MailTo */
  mailTo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailLogFilterInput>>;
  /** [ID] TrialMailLogId */
  trialMailLogId?: InputMaybe<IntOperationFilterInput>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialMailLog */
export type Wf_TrialModeLeads_TrialMailLogInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** MailBcc */
  mailBcc?: InputMaybe<Scalars['String']['input']>;
  /** MailCc */
  mailCc?: InputMaybe<Scalars['String']['input']>;
  /** MailContent */
  mailContent?: InputMaybe<Scalars['String']['input']>;
  /** MailSubject */
  mailSubject?: InputMaybe<Scalars['String']['input']>;
  /** MailTo */
  mailTo?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TrialMailLogId */
  trialMailLogId?: InputMaybe<Scalars['Int']['input']>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialMailLog */
export type Wf_TrialModeLeads_TrialMailLogSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** MailBcc */
  mailBcc?: InputMaybe<SortEnumType>;
  /** MailCc */
  mailCc?: InputMaybe<SortEnumType>;
  /** MailContent */
  mailContent?: InputMaybe<SortEnumType>;
  /** MailSubject */
  mailSubject?: InputMaybe<SortEnumType>;
  /** MailTo */
  mailTo?: InputMaybe<SortEnumType>;
  /** [ID] TrialMailLogId */
  trialMailLogId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
};

/** TrialMailSetting */
export type Wf_TrialModeLeads_TrialMailSetting = Node & {
  __typename?: 'WF_TrialModeLeads_TrialMailSetting';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] TrialMailSettingId */
  id: Scalars['ID']['output'];
  /** MailBody */
  mailBody?: Maybe<Scalars['String']['output']>;
  /** MailSubject */
  mailSubject?: Maybe<Scalars['String']['output']>;
  /** MailTo */
  mailTo?: Maybe<Scalars['String']['output']>;
  /** MailType */
  mailType?: Maybe<Scalars['String']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** SenderAddress */
  senderAddress?: Maybe<Scalars['String']['output']>;
  /** SenderName */
  senderName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialMailSettingConnection = {
  __typename?: 'WF_TrialModeLeads_TrialMailSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialMailSettingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialMailSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialMailSettingEdge = {
  __typename?: 'WF_TrialModeLeads_TrialMailSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialMailSetting;
};

/** TrialMailSetting */
export type Wf_TrialModeLeads_TrialMailSettingFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailSettingFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** MailBody */
  mailBody?: InputMaybe<StringOperationFilterInput>;
  /** MailSubject */
  mailSubject?: InputMaybe<StringOperationFilterInput>;
  /** MailTo */
  mailTo?: InputMaybe<StringOperationFilterInput>;
  /** MailType */
  mailType?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialMailSettingFilterInput>>;
  /** SenderAddress */
  senderAddress?: InputMaybe<StringOperationFilterInput>;
  /** SenderName */
  senderName?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TrialMailSettingId */
  trialMailSettingId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialMailSetting */
export type Wf_TrialModeLeads_TrialMailSettingInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** MailBody */
  mailBody?: InputMaybe<Scalars['String']['input']>;
  /** MailSubject */
  mailSubject?: InputMaybe<Scalars['String']['input']>;
  /** MailTo */
  mailTo?: InputMaybe<Scalars['String']['input']>;
  /** MailType */
  mailType?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** SenderAddress */
  senderAddress?: InputMaybe<Scalars['String']['input']>;
  /** SenderName */
  senderName?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TrialMailSettingId */
  trialMailSettingId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialMailSetting */
export type Wf_TrialModeLeads_TrialMailSettingSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** MailBody */
  mailBody?: InputMaybe<SortEnumType>;
  /** MailSubject */
  mailSubject?: InputMaybe<SortEnumType>;
  /** MailTo */
  mailTo?: InputMaybe<SortEnumType>;
  /** MailType */
  mailType?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** SenderAddress */
  senderAddress?: InputMaybe<SortEnumType>;
  /** SenderName */
  senderName?: InputMaybe<SortEnumType>;
  /** [ID] TrialMailSettingId */
  trialMailSettingId?: InputMaybe<SortEnumType>;
};

/** TrialMessageSetting */
export type Wf_TrialModeLeads_TrialMessageSetting = Node & {
  __typename?: 'WF_TrialModeLeads_TrialMessageSetting';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] TrialMessageSettingId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** MessageType */
  messageType?: Maybe<Scalars['String']['output']>;
  /** NotificationId */
  notificationId?: Maybe<Scalars['Int']['output']>;
  /** PushMessage */
  pushMessage?: Maybe<Scalars['String']['output']>;
  /** SmsMessage */
  smsMessage?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialMessageSettingConnection = {
  __typename?: 'WF_TrialModeLeads_TrialMessageSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialMessageSettingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialMessageSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialMessageSettingEdge = {
  __typename?: 'WF_TrialModeLeads_TrialMessageSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialMessageSetting;
};

/** TrialMessageSetting */
export type Wf_TrialModeLeads_TrialMessageSettingFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialMessageSettingFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** MessageType */
  messageType?: InputMaybe<StringOperationFilterInput>;
  /** NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialMessageSettingFilterInput>>;
  /** PushMessage */
  pushMessage?: InputMaybe<StringOperationFilterInput>;
  /** SmsMessage */
  smsMessage?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TrialMessageSettingId */
  trialMessageSettingId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialMessageSetting */
export type Wf_TrialModeLeads_TrialMessageSettingInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** MessageType */
  messageType?: InputMaybe<Scalars['String']['input']>;
  /** NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** PushMessage */
  pushMessage?: InputMaybe<Scalars['String']['input']>;
  /** SmsMessage */
  smsMessage?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TrialMessageSettingId */
  trialMessageSettingId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialMessageSetting */
export type Wf_TrialModeLeads_TrialMessageSettingSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** MessageType */
  messageType?: InputMaybe<SortEnumType>;
  /** NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** PushMessage */
  pushMessage?: InputMaybe<SortEnumType>;
  /** SmsMessage */
  smsMessage?: InputMaybe<SortEnumType>;
  /** [ID] TrialMessageSettingId */
  trialMessageSettingId?: InputMaybe<SortEnumType>;
};

/** TrialOneTimeToken */
export type Wf_TrialModeLeads_TrialOneTimeToken = Node & {
  __typename?: 'WF_TrialModeLeads_TrialOneTimeToken';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** DeviceId */
  deviceId?: Maybe<Scalars['String']['output']>;
  /** [ID] TrialOneTimeTokenId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** PhoneNumber */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** TrialUserId */
  trialUserId?: Maybe<Scalars['Int']['output']>;
  /** TrialUserName */
  trialUserName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialOneTimeTokenConnection = {
  __typename?: 'WF_TrialModeLeads_TrialOneTimeTokenConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialOneTimeTokenEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialOneTimeToken>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialOneTimeTokenEdge = {
  __typename?: 'WF_TrialModeLeads_TrialOneTimeTokenEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialOneTimeToken;
};

/** TrialOneTimeToken */
export type Wf_TrialModeLeads_TrialOneTimeTokenFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialOneTimeTokenFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** DeviceId */
  deviceId?: InputMaybe<StringOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialOneTimeTokenFilterInput>>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TrialOneTimeTokenId */
  trialOneTimeTokenId?: InputMaybe<UuidOperationFilterInput>;
  /** TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
  /** TrialUserName */
  trialUserName?: InputMaybe<StringOperationFilterInput>;
};

/** TrialOneTimeToken */
export type Wf_TrialModeLeads_TrialOneTimeTokenInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** DeviceId */
  deviceId?: InputMaybe<Scalars['String']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TrialOneTimeTokenId */
  trialOneTimeTokenId?: InputMaybe<Scalars['UUID']['input']>;
  /** TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
  /** TrialUserName */
  trialUserName?: InputMaybe<Scalars['String']['input']>;
};

/** TrialOneTimeToken */
export type Wf_TrialModeLeads_TrialOneTimeTokenSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** DeviceId */
  deviceId?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** [ID] TrialOneTimeTokenId */
  trialOneTimeTokenId?: InputMaybe<SortEnumType>;
  /** TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
  /** TrialUserName */
  trialUserName?: InputMaybe<SortEnumType>;
};

/** TrialPushType */
export type Wf_TrialModeLeads_TrialPushType = Node & {
  __typename?: 'WF_TrialModeLeads_TrialPushType';
  /** [ID] PushTypeId */
  id: Scalars['ID']['output'];
  /** [NOT NULL] IsPublic */
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  /** [NOT NULL] PushTypeName */
  pushTypeName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialPushTypeConnection = {
  __typename?: 'WF_TrialModeLeads_TrialPushTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialPushTypeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialPushType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialPushTypeEdge = {
  __typename?: 'WF_TrialModeLeads_TrialPushTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialPushType;
};

/** TrialPushType */
export type Wf_TrialModeLeads_TrialPushTypeFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialPushTypeFilterInput>>;
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialPushTypeFilterInput>>;
  /** [ID] PushTypeId */
  pushTypeId?: InputMaybe<StringOperationFilterInput>;
  /** [NOT NULL] PushTypeName */
  pushTypeName?: InputMaybe<StringOperationFilterInput>;
};

/** TrialPushType */
export type Wf_TrialModeLeads_TrialPushTypeInput = {
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** [ID] PushTypeId */
  pushTypeId?: InputMaybe<Scalars['String']['input']>;
  /** [NOT NULL] PushTypeName */
  pushTypeName?: InputMaybe<Scalars['String']['input']>;
};

/** TrialPushType */
export type Wf_TrialModeLeads_TrialPushTypeSortInput = {
  /** [NOT NULL] IsPublic */
  isPublic?: InputMaybe<SortEnumType>;
  /** [ID] PushTypeId */
  pushTypeId?: InputMaybe<SortEnumType>;
  /** [NOT NULL] PushTypeName */
  pushTypeName?: InputMaybe<SortEnumType>;
};

/** TrialSegmentation */
export type Wf_TrialModeLeads_TrialSegmentation = Node & {
  __typename?: 'WF_TrialModeLeads_TrialSegmentation';
  /** [ID] SegmentationId */
  id: Scalars['ID']['output'];
  /** Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
  /** SegmentationDescription */
  segmentationDescription?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialSegmentationConnection = {
  __typename?: 'WF_TrialModeLeads_TrialSegmentationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialSegmentationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialSegmentation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialSegmentationEdge = {
  __typename?: 'WF_TrialModeLeads_TrialSegmentationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialSegmentation;
};

/** TrialSegmentation */
export type Wf_TrialModeLeads_TrialSegmentationFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialSegmentationFilterInput>>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialSegmentationFilterInput>>;
  /** Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
  /** SegmentationDescription */
  segmentationDescription?: InputMaybe<StringOperationFilterInput>;
  /** [ID] SegmentationId */
  segmentationId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialSegmentation */
export type Wf_TrialModeLeads_TrialSegmentationInput = {
  /** Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
  /** SegmentationDescription */
  segmentationDescription?: InputMaybe<Scalars['String']['input']>;
  /** [ID] SegmentationId */
  segmentationId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialSegmentation */
export type Wf_TrialModeLeads_TrialSegmentationSortInput = {
  /** Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
  /** SegmentationDescription */
  segmentationDescription?: InputMaybe<SortEnumType>;
  /** [ID] SegmentationId */
  segmentationId?: InputMaybe<SortEnumType>;
};

/** TrialSetting */
export type Wf_TrialModeLeads_TrialSetting = Node & {
  __typename?: 'WF_TrialModeLeads_TrialSetting';
  /** [ID] TrialSettingId */
  id: Scalars['ID']['output'];
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** TrialSettingKey */
  trialSettingKey?: Maybe<Scalars['String']['output']>;
  /** TrialSettingValue */
  trialSettingValue?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialSettingConnection = {
  __typename?: 'WF_TrialModeLeads_TrialSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialSettingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialSettingEdge = {
  __typename?: 'WF_TrialModeLeads_TrialSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialSetting;
};

/** TrialSetting */
export type Wf_TrialModeLeads_TrialSettingFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialSettingFilterInput>>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialSettingFilterInput>>;
  /** [ID] TrialSettingId */
  trialSettingId?: InputMaybe<IntOperationFilterInput>;
  /** TrialSettingKey */
  trialSettingKey?: InputMaybe<StringOperationFilterInput>;
  /** TrialSettingValue */
  trialSettingValue?: InputMaybe<StringOperationFilterInput>;
};

/** TrialSetting */
export type Wf_TrialModeLeads_TrialSettingInput = {
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TrialSettingId */
  trialSettingId?: InputMaybe<Scalars['Int']['input']>;
  /** TrialSettingKey */
  trialSettingKey?: InputMaybe<Scalars['String']['input']>;
  /** TrialSettingValue */
  trialSettingValue?: InputMaybe<Scalars['String']['input']>;
};

/** TrialSetting */
export type Wf_TrialModeLeads_TrialSettingSortInput = {
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** [ID] TrialSettingId */
  trialSettingId?: InputMaybe<SortEnumType>;
  /** TrialSettingKey */
  trialSettingKey?: InputMaybe<SortEnumType>;
  /** TrialSettingValue */
  trialSettingValue?: InputMaybe<SortEnumType>;
};

/** TrialToken */
export type Wf_TrialModeLeads_TrialToken = Node & {
  __typename?: 'WF_TrialModeLeads_TrialToken';
  /** AccessKey */
  accessKey?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] TrialTokenId */
  id: Scalars['ID']['output'];
  /** Token */
  token?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] TrialUser */
  trialUser?: Maybe<Wf_TrialModeLeads_TrialUser>;
  /** TrialUserId */
  trialUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialTokenConnection = {
  __typename?: 'WF_TrialModeLeads_TrialTokenConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialTokenEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialToken>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialTokenEdge = {
  __typename?: 'WF_TrialModeLeads_TrialTokenEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialToken;
};

/** TrialToken */
export type Wf_TrialModeLeads_TrialTokenFilterInput = {
  /** AccessKey */
  accessKey?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialTokenFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialTokenFilterInput>>;
  /** Token */
  token?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TrialTokenId */
  trialTokenId?: InputMaybe<StringOperationFilterInput>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserFilterInput>;
  /** TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialToken */
export type Wf_TrialModeLeads_TrialTokenInput = {
  /** AccessKey */
  accessKey?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Token */
  token?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TrialTokenId */
  trialTokenId?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserInput>;
  /** TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialToken */
export type Wf_TrialModeLeads_TrialTokenSortInput = {
  /** AccessKey */
  accessKey?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Token */
  token?: InputMaybe<SortEnumType>;
  /** [ID] TrialTokenId */
  trialTokenId?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserSortInput>;
  /** TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
};

/** TrialTrackingLog */
export type Wf_TrialModeLeads_TrialTrackingLog = Node & {
  __typename?: 'WF_TrialModeLeads_TrialTrackingLog';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] TrialTrackingLogId */
  id: Scalars['ID']['output'];
  /** Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
  /** SegmentationId */
  segmentationId?: Maybe<Scalars['Int']['output']>;
  /** TrialEventId */
  trialEventId?: Maybe<Scalars['String']['output']>;
  /** TrialPageId */
  trialPageId?: Maybe<Scalars['String']['output']>;
  /** TrialUserId */
  trialUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialTrackingLogConnection = {
  __typename?: 'WF_TrialModeLeads_TrialTrackingLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialTrackingLogEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialTrackingLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialTrackingLogEdge = {
  __typename?: 'WF_TrialModeLeads_TrialTrackingLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialTrackingLog;
};

/** TrialTrackingLog */
export type Wf_TrialModeLeads_TrialTrackingLogFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialTrackingLogFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialTrackingLogFilterInput>>;
  /** Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
  /** SegmentationId */
  segmentationId?: InputMaybe<IntOperationFilterInput>;
  /** TrialEventId */
  trialEventId?: InputMaybe<StringOperationFilterInput>;
  /** TrialPageId */
  trialPageId?: InputMaybe<StringOperationFilterInput>;
  /** [ID] TrialTrackingLogId */
  trialTrackingLogId?: InputMaybe<LongOperationFilterInput>;
  /** TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialTrackingLog */
export type Wf_TrialModeLeads_TrialTrackingLogInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
  /** SegmentationId */
  segmentationId?: InputMaybe<Scalars['Int']['input']>;
  /** TrialEventId */
  trialEventId?: InputMaybe<Scalars['String']['input']>;
  /** TrialPageId */
  trialPageId?: InputMaybe<Scalars['String']['input']>;
  /** [ID] TrialTrackingLogId */
  trialTrackingLogId?: InputMaybe<Scalars['Long']['input']>;
  /** TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialTrackingLog */
export type Wf_TrialModeLeads_TrialTrackingLogSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
  /** SegmentationId */
  segmentationId?: InputMaybe<SortEnumType>;
  /** TrialEventId */
  trialEventId?: InputMaybe<SortEnumType>;
  /** TrialPageId */
  trialPageId?: InputMaybe<SortEnumType>;
  /** [ID] TrialTrackingLogId */
  trialTrackingLogId?: InputMaybe<SortEnumType>;
  /** TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
};

/** TrialUser */
export type Wf_TrialModeLeads_TrialUser = Node & {
  __typename?: 'WF_TrialModeLeads_TrialUser';
  /** AppVersion */
  appVersion?: Maybe<Scalars['String']['output']>;
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** DeviceId */
  deviceId?: Maybe<Scalars['String']['output']>;
  /** ExpiredDate */
  expiredDate?: Maybe<Scalars['DateTime']['output']>;
  /** FirstLogingDate */
  firstLogingDate?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] TrialUserId */
  id: Scalars['ID']['output'];
  /** IsVerified */
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  /** LastLogingDate */
  lastLogingDate?: Maybe<Scalars['DateTime']['output']>;
  /** LeadsAdapterExecutionTime */
  leadsAdapterExecutionTime?: Maybe<Scalars['DateTime']['output']>;
  /** Market */
  market?: Maybe<Scalars['String']['output']>;
  /** MemberJoinDate */
  memberJoinDate?: Maybe<Scalars['DateTime']['output']>;
  /** MemberNo */
  memberNo?: Maybe<Scalars['String']['output']>;
  /** MobileBrand */
  mobileBrand?: Maybe<Scalars['String']['output']>;
  /** Osversion */
  osversion?: Maybe<Scalars['String']['output']>;
  /** PhoneNumber */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Pushtoken */
  pushtoken?: Maybe<Scalars['String']['output']>;
  /** Referral */
  referral?: Maybe<Scalars['String']['output']>;
  /** Segmentation */
  segmentation?: Maybe<Scalars['String']['output']>;
  /** SegmentationDate */
  segmentationDate?: Maybe<Scalars['DateTime']['output']>;
  /** SharedEa */
  sharedEa?: Maybe<Scalars['String']['output']>;
  /** SharedMember */
  sharedMember?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] TrialTokens */
  trialTokens?: Maybe<Array<Maybe<Wf_TrialModeLeads_TrialToken>>>;
  /** TrialUserKey */
  trialUserKey?: Maybe<Scalars['String']['output']>;
  /** TrialUserName */
  trialUserName?: Maybe<Scalars['String']['output']>;
  /** [COLLECTION] TrialUserNotifications */
  trialUserNotifications?: Maybe<Array<Maybe<Wf_TrialModeLeads_TrialUserNotification>>>;
  /** [COLLECTION] TrialUserSegmentationLogs */
  trialUserSegmentationLogs?: Maybe<Array<Maybe<Wf_TrialModeLeads_TrialUserSegmentationLog>>>;
  /** VerificationCode */
  verificationCode?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialUserConnection = {
  __typename?: 'WF_TrialModeLeads_TrialUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialUserEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialUserEdge = {
  __typename?: 'WF_TrialModeLeads_TrialUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialUser;
};

/** TrialUser */
export type Wf_TrialModeLeads_TrialUserFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserFilterInput>>;
  /** AppVersion */
  appVersion?: InputMaybe<StringOperationFilterInput>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** DeviceId */
  deviceId?: InputMaybe<StringOperationFilterInput>;
  /** ExpiredDate */
  expiredDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** FirstLogingDate */
  firstLogingDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** IsVerified */
  isVerified?: InputMaybe<BooleanOperationFilterInput>;
  /** LastLogingDate */
  lastLogingDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** LeadsAdapterExecutionTime */
  leadsAdapterExecutionTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Market */
  market?: InputMaybe<StringOperationFilterInput>;
  /** MemberJoinDate */
  memberJoinDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** MemberNo */
  memberNo?: InputMaybe<StringOperationFilterInput>;
  /** MobileBrand */
  mobileBrand?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserFilterInput>>;
  /** Osversion */
  osversion?: InputMaybe<StringOperationFilterInput>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** Pushtoken */
  pushtoken?: InputMaybe<StringOperationFilterInput>;
  /** Referral */
  referral?: InputMaybe<StringOperationFilterInput>;
  /** Segmentation */
  segmentation?: InputMaybe<StringOperationFilterInput>;
  /** SegmentationDate */
  segmentationDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** SharedEa */
  sharedEa?: InputMaybe<StringOperationFilterInput>;
  /** SharedMember */
  sharedMember?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] TrialTokens */
  trialTokens?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_TrialTokenFilterInput>;
  /** [ID] TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
  /** TrialUserKey */
  trialUserKey?: InputMaybe<StringOperationFilterInput>;
  /** TrialUserName */
  trialUserName?: InputMaybe<StringOperationFilterInput>;
  /** [COLLECTION] TrialUserNotifications */
  trialUserNotifications?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_TrialUserNotificationFilterInput>;
  /** [COLLECTION] TrialUserSegmentationLogs */
  trialUserSegmentationLogs?: InputMaybe<ListFilterInputTypeOfWf_TrialModeLeads_TrialUserSegmentationLogFilterInput>;
  /** VerificationCode */
  verificationCode?: InputMaybe<StringOperationFilterInput>;
};

/** TrialUser */
export type Wf_TrialModeLeads_TrialUserInput = {
  /** AppVersion */
  appVersion?: InputMaybe<Scalars['String']['input']>;
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** DeviceId */
  deviceId?: InputMaybe<Scalars['String']['input']>;
  /** ExpiredDate */
  expiredDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** FirstLogingDate */
  firstLogingDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** IsVerified */
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** LastLogingDate */
  lastLogingDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** LeadsAdapterExecutionTime */
  leadsAdapterExecutionTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Market */
  market?: InputMaybe<Scalars['String']['input']>;
  /** MemberJoinDate */
  memberJoinDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** MemberNo */
  memberNo?: InputMaybe<Scalars['String']['input']>;
  /** MobileBrand */
  mobileBrand?: InputMaybe<Scalars['String']['input']>;
  /** Osversion */
  osversion?: InputMaybe<Scalars['String']['input']>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Pushtoken */
  pushtoken?: InputMaybe<Scalars['String']['input']>;
  /** Referral */
  referral?: InputMaybe<Scalars['String']['input']>;
  /** Segmentation */
  segmentation?: InputMaybe<Scalars['String']['input']>;
  /** SegmentationDate */
  segmentationDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** SharedEa */
  sharedEa?: InputMaybe<Scalars['String']['input']>;
  /** SharedMember */
  sharedMember?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] TrialTokens */
  trialTokens?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_TrialTokenInput>>>;
  /** [ID] TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
  /** TrialUserKey */
  trialUserKey?: InputMaybe<Scalars['String']['input']>;
  /** TrialUserName */
  trialUserName?: InputMaybe<Scalars['String']['input']>;
  /** [COLLECTION] TrialUserNotifications */
  trialUserNotifications?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_TrialUserNotificationInput>>>;
  /** [COLLECTION] TrialUserSegmentationLogs */
  trialUserSegmentationLogs?: InputMaybe<Array<InputMaybe<Wf_TrialModeLeads_TrialUserSegmentationLogInput>>>;
  /** VerificationCode */
  verificationCode?: InputMaybe<Scalars['String']['input']>;
};

/** TrialUserMessageLog */
export type Wf_TrialModeLeads_TrialUserMessageLog = Node & {
  __typename?: 'WF_TrialModeLeads_TrialUserMessageLog';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] LogId */
  id: Scalars['ID']['output'];
  /** MessageType */
  messageType?: Maybe<Scalars['String']['output']>;
  /** TrialUserId */
  trialUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialUserMessageLogConnection = {
  __typename?: 'WF_TrialModeLeads_TrialUserMessageLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialUserMessageLogEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialUserMessageLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialUserMessageLogEdge = {
  __typename?: 'WF_TrialModeLeads_TrialUserMessageLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialUserMessageLog;
};

/** TrialUserMessageLog */
export type Wf_TrialModeLeads_TrialUserMessageLogFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserMessageLogFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** [ID] LogId */
  logId?: InputMaybe<IntOperationFilterInput>;
  /** MessageType */
  messageType?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserMessageLogFilterInput>>;
  /** TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialUserMessageLog */
export type Wf_TrialModeLeads_TrialUserMessageLogInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] LogId */
  logId?: InputMaybe<Scalars['Int']['input']>;
  /** MessageType */
  messageType?: InputMaybe<Scalars['String']['input']>;
  /** TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialUserMessageLog */
export type Wf_TrialModeLeads_TrialUserMessageLogSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** [ID] LogId */
  logId?: InputMaybe<SortEnumType>;
  /** MessageType */
  messageType?: InputMaybe<SortEnumType>;
  /** TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
};

/** TrialUserNotification */
export type Wf_TrialModeLeads_TrialUserNotification = Node & {
  __typename?: 'WF_TrialModeLeads_TrialUserNotification';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] TrialUserNotificationId */
  id: Scalars['ID']['output'];
  /** LinkNo */
  linkNo?: Maybe<Scalars['String']['output']>;
  /** LookedDate */
  lookedDate?: Maybe<Scalars['String']['output']>;
  /** Message */
  message?: Maybe<Scalars['String']['output']>;
  /** MessageDate */
  messageDate?: Maybe<Scalars['DateTime']['output']>;
  /** NotificationId */
  notificationId?: Maybe<Scalars['Int']['output']>;
  /** NotificationTypeId */
  notificationTypeId?: Maybe<Scalars['String']['output']>;
  /** PublicFlg */
  publicFlg?: Maybe<Scalars['Boolean']['output']>;
  /** PushFlg */
  pushFlg?: Maybe<Scalars['String']['output']>;
  /** ScheduleTime */
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  /** SendFlg */
  sendFlg?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] TrialUser */
  trialUser?: Maybe<Wf_TrialModeLeads_TrialUser>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialUserNotificationConnection = {
  __typename?: 'WF_TrialModeLeads_TrialUserNotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialUserNotificationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialUserNotification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialUserNotificationEdge = {
  __typename?: 'WF_TrialModeLeads_TrialUserNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialUserNotification;
};

/** TrialUserNotification */
export type Wf_TrialModeLeads_TrialUserNotificationFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserNotificationFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** LinkNo */
  linkNo?: InputMaybe<StringOperationFilterInput>;
  /** LookedDate */
  lookedDate?: InputMaybe<StringOperationFilterInput>;
  /** Message */
  message?: InputMaybe<StringOperationFilterInput>;
  /** MessageDate */
  messageDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** NotificationId */
  notificationId?: InputMaybe<IntOperationFilterInput>;
  /** NotificationTypeId */
  notificationTypeId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserNotificationFilterInput>>;
  /** PublicFlg */
  publicFlg?: InputMaybe<BooleanOperationFilterInput>;
  /** PushFlg */
  pushFlg?: InputMaybe<StringOperationFilterInput>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** SendFlg */
  sendFlg?: InputMaybe<StringOperationFilterInput>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserFilterInput>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
  /** [ID] TrialUserNotificationId */
  trialUserNotificationId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialUserNotification */
export type Wf_TrialModeLeads_TrialUserNotificationInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** LinkNo */
  linkNo?: InputMaybe<Scalars['String']['input']>;
  /** LookedDate */
  lookedDate?: InputMaybe<Scalars['String']['input']>;
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** MessageDate */
  messageDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** NotificationId */
  notificationId?: InputMaybe<Scalars['Int']['input']>;
  /** NotificationTypeId */
  notificationTypeId?: InputMaybe<Scalars['String']['input']>;
  /** PublicFlg */
  publicFlg?: InputMaybe<Scalars['Boolean']['input']>;
  /** PushFlg */
  pushFlg?: InputMaybe<Scalars['String']['input']>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** SendFlg */
  sendFlg?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserInput>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
  /** [ID] TrialUserNotificationId */
  trialUserNotificationId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialUserNotification */
export type Wf_TrialModeLeads_TrialUserNotificationSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** LinkNo */
  linkNo?: InputMaybe<SortEnumType>;
  /** LookedDate */
  lookedDate?: InputMaybe<SortEnumType>;
  /** Message */
  message?: InputMaybe<SortEnumType>;
  /** MessageDate */
  messageDate?: InputMaybe<SortEnumType>;
  /** NotificationId */
  notificationId?: InputMaybe<SortEnumType>;
  /** NotificationTypeId */
  notificationTypeId?: InputMaybe<SortEnumType>;
  /** PublicFlg */
  publicFlg?: InputMaybe<SortEnumType>;
  /** PushFlg */
  pushFlg?: InputMaybe<SortEnumType>;
  /** ScheduleTime */
  scheduleTime?: InputMaybe<SortEnumType>;
  /** SendFlg */
  sendFlg?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserSortInput>;
  /** [NOT NULL] TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
  /** [ID] TrialUserNotificationId */
  trialUserNotificationId?: InputMaybe<SortEnumType>;
};

/** TrialUserSegmentationLog */
export type Wf_TrialModeLeads_TrialUserSegmentationLog = Node & {
  __typename?: 'WF_TrialModeLeads_TrialUserSegmentationLog';
  /** CreatedTime */
  createdTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ID] SegmentationLogId */
  id: Scalars['ID']['output'];
  /** SegmentationNew */
  segmentationNew?: Maybe<Scalars['String']['output']>;
  /** SegmentationOld */
  segmentationOld?: Maybe<Scalars['String']['output']>;
  /** [NAVIGATION] TrialUser */
  trialUser?: Maybe<Wf_TrialModeLeads_TrialUser>;
  /** TrialUserId */
  trialUserId?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type Wf_TrialModeLeads_TrialUserSegmentationLogConnection = {
  __typename?: 'WF_TrialModeLeads_TrialUserSegmentationLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLogEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type Wf_TrialModeLeads_TrialUserSegmentationLogEdge = {
  __typename?: 'WF_TrialModeLeads_TrialUserSegmentationLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Wf_TrialModeLeads_TrialUserSegmentationLog;
};

/** TrialUserSegmentationLog */
export type Wf_TrialModeLeads_TrialUserSegmentationLogFilterInput = {
  and?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLogFilterInput>>;
  /** CreatedTime */
  createdTime?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<Wf_TrialModeLeads_TrialUserSegmentationLogFilterInput>>;
  /** [ID] SegmentationLogId */
  segmentationLogId?: InputMaybe<IntOperationFilterInput>;
  /** SegmentationNew */
  segmentationNew?: InputMaybe<StringOperationFilterInput>;
  /** SegmentationOld */
  segmentationOld?: InputMaybe<StringOperationFilterInput>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserFilterInput>;
  /** TrialUserId */
  trialUserId?: InputMaybe<IntOperationFilterInput>;
};

/** TrialUserSegmentationLog */
export type Wf_TrialModeLeads_TrialUserSegmentationLogInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** [ID] SegmentationLogId */
  segmentationLogId?: InputMaybe<Scalars['Int']['input']>;
  /** SegmentationNew */
  segmentationNew?: InputMaybe<Scalars['String']['input']>;
  /** SegmentationOld */
  segmentationOld?: InputMaybe<Scalars['String']['input']>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserInput>;
  /** TrialUserId */
  trialUserId?: InputMaybe<Scalars['Int']['input']>;
};

/** TrialUserSegmentationLog */
export type Wf_TrialModeLeads_TrialUserSegmentationLogSortInput = {
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** [ID] SegmentationLogId */
  segmentationLogId?: InputMaybe<SortEnumType>;
  /** SegmentationNew */
  segmentationNew?: InputMaybe<SortEnumType>;
  /** SegmentationOld */
  segmentationOld?: InputMaybe<SortEnumType>;
  /** [NAVIGATION] TrialUser */
  trialUser?: InputMaybe<Wf_TrialModeLeads_TrialUserSortInput>;
  /** TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
};

/** TrialUser */
export type Wf_TrialModeLeads_TrialUserSortInput = {
  /** AppVersion */
  appVersion?: InputMaybe<SortEnumType>;
  /** CreatedTime */
  createdTime?: InputMaybe<SortEnumType>;
  /** DeviceId */
  deviceId?: InputMaybe<SortEnumType>;
  /** ExpiredDate */
  expiredDate?: InputMaybe<SortEnumType>;
  /** FirstLogingDate */
  firstLogingDate?: InputMaybe<SortEnumType>;
  /** IsVerified */
  isVerified?: InputMaybe<SortEnumType>;
  /** LastLogingDate */
  lastLogingDate?: InputMaybe<SortEnumType>;
  /** LeadsAdapterExecutionTime */
  leadsAdapterExecutionTime?: InputMaybe<SortEnumType>;
  /** Market */
  market?: InputMaybe<SortEnumType>;
  /** MemberJoinDate */
  memberJoinDate?: InputMaybe<SortEnumType>;
  /** MemberNo */
  memberNo?: InputMaybe<SortEnumType>;
  /** MobileBrand */
  mobileBrand?: InputMaybe<SortEnumType>;
  /** Osversion */
  osversion?: InputMaybe<SortEnumType>;
  /** PhoneNumber */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** Pushtoken */
  pushtoken?: InputMaybe<SortEnumType>;
  /** Referral */
  referral?: InputMaybe<SortEnumType>;
  /** Segmentation */
  segmentation?: InputMaybe<SortEnumType>;
  /** SegmentationDate */
  segmentationDate?: InputMaybe<SortEnumType>;
  /** SharedEa */
  sharedEa?: InputMaybe<SortEnumType>;
  /** SharedMember */
  sharedMember?: InputMaybe<SortEnumType>;
  /** [ID] TrialUserId */
  trialUserId?: InputMaybe<SortEnumType>;
  /** TrialUserKey */
  trialUserKey?: InputMaybe<SortEnumType>;
  /** TrialUserName */
  trialUserName?: InputMaybe<SortEnumType>;
  /** VerificationCode */
  verificationCode?: InputMaybe<SortEnumType>;
};
